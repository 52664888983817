import { DefaultSagaParams } from '../sagas';

export interface SendLoanAdvanceInfoService {
  sendLoanAdvanceInfo: (id: string) => Promise<void>;
}

export const getSendLoanAdvanceInfoService = ({
  apiClient
}: DefaultSagaParams): SendLoanAdvanceInfoService => ({
  sendLoanAdvanceInfo: async id => {
    try {
      await apiClient.post(`protected/applications/${id}/loan-advance-info`);
    } catch (err) {
      throw new Error(`Failed to send Loan Advance Info: ${err}`);
    }
  }
});
