import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  extractRetailerFromResponse,
  IsPhaseThreeEnabledForRetailer
} from '../../sagas/retailer/saga';
import { RetailerByApplicationIdAction } from 'store/retailer/reducer';
import { getType, isOfType } from 'typesafe-actions';
import { RetailerService } from '../../services/retailer';
import { fetchRetailerByApplicationId } from '../../store/retailer/actions';
import { RetailerConfig } from 'hitachi-retail-core/build/api/retailerConfig';

interface GetRetailerSagaParams {
  retailerService: RetailerService;
}

export const getRetailerByApplicationIdSaga = ({
  retailerService
}: GetRetailerSagaParams) =>
  function*(action: RetailerByApplicationIdAction) {
    if (isOfType(getType(fetchRetailerByApplicationId.request), action)) {
      const { id } = action.payload;

      try {
        const response: RetailerConfig &
          IsPhaseThreeEnabledForRetailer = yield call(
          retailerService.getRetailerByApplicationId,
          id
        );
        const retailer = extractRetailerFromResponse(response);

        yield put(fetchRetailerByApplicationId.success(retailer));
      } catch (error) {
        yield put(fetchRetailerByApplicationId.failure(error));
      }
    }
  };

interface GetRetailerSagaByApplicationIdWatcher {
  retailerService: RetailerService;
}

export const getFetchRetailerByApplicationIdRequestSagaWatcher = ({
  retailerService
}: GetRetailerSagaByApplicationIdWatcher) =>
  function*() {
    yield takeEvery(
      getType(fetchRetailerByApplicationId.request),
      getRetailerByApplicationIdSaga({ retailerService })
    );
  };

export const getRetailerByApplicationIdSagaWatcher = ({
  retailerService
}: GetRetailerSagaByApplicationIdWatcher) =>
  function*() {
    yield all([
      fork(
        getFetchRetailerByApplicationIdRequestSagaWatcher({ retailerService })
      )
    ]);
  };
