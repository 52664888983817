import {
  diffBetweenMonthYear,
  monthsToMonthsYears,
  nowToMonthYear
} from 'hitachi-retail-core/build/utils';
import { pluraliseString } from './formatters';

export const yearMonthDiffString = (year: number, month: number): string => {
  const monthDiffResult = diffBetweenMonthYear(
    {
      year,
      month
    },
    nowToMonthYear()
  );
  const { years, months } = monthsToMonthsYears(monthDiffResult);
  if (years === 0 && months === 0) {
    return 'Less than one month';
  }

  const stringParts = [];
  if (years > 0) {
    stringParts.push(`${years} ${pluraliseString('year', years)}`);
  }
  if (months > 0) {
    stringParts.push(`${months} ${pluraliseString('month', months)}`);
  }
  return stringParts.join(', ');
};

export const isTwoDigitValue = (value: string) => {
  return Boolean(value && value.match(/\d{2}/));
};
