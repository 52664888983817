import React from 'react';
import { createStandardAction } from 'typesafe-actions';
import * as app from './constants';

export const NotificationType = {
  DANGER: 'danger',
  SUCCESS: 'success',
  WARNING: 'warning'
};

export interface UINotification {
  message?: string;
  type?: string;
}

export interface UIPrompt {
  title?: string;
  message?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export interface UIModal {
  body?: React.ReactElement<any>;
  onClose?: () => void;
}

export const enablePageBlock = createStandardAction(app.ENABLE_PAGE_BLOCK)();

export const disablePageBlock = createStandardAction(app.DISABLE_PAGE_BLOCK)();

export const showNotification = createStandardAction(app.SHOW_NOTIFICATION)<
  UINotification
>();

export const closeNotification = createStandardAction(app.CLOSE_NOTIFICATION)();

export const showPrompt = createStandardAction(app.SHOW_PROMPT)<UIPrompt>();

export const closePrompt = createStandardAction(app.CLOSE_PROMPT)();

export const showModal = createStandardAction(app.SHOW_MODAL)<UIModal>();

export const closeModal = createStandardAction(app.CLOSE_MODAL)();
