import { Box, Flex, InputWrapper, RadioGroup, TextField } from 'compass-design';
import {
  Field,
  FieldProps,
  Form,
  FormikProps,
  validateYupSchema,
  yupToFormErrors,
  Formik
} from 'formik';
import { maritalStatus, title } from 'hitachi-retail-core/build/enums';
import {
  personalDetailsSchema as basePersonalDetailsSchema,
  spouseEmploymentStatusRequired
} from 'hitachi-retail-core/build/schemas/applicantDetails';
import React, { RefObject, useRef } from 'react';
import * as yup from 'yup';
import { Step, StepComponentProps } from '../MultiStepForm';
import { DetailsCaptureValues } from '../schema';
import {
  enumToRadioOptions,
  CombineDateInput,
  combineDate,
  padDate
} from '../helpers';
import { labelPropsFromMeta } from 'utils/labelPropsFromMeta';
import { getNavigationButtons } from '../navigationButtons';
import { getTone } from 'utils/getTone';
import { isTwoDigitValue } from 'utils/dates';

const personalDetailsSchema = yup.object({
  personalDetails: basePersonalDetailsSchema
});

const validate = async (values: DetailsCaptureValues) => {
  try {
    await validateYupSchema<DetailsCaptureValues>(
      values,
      personalDetailsSchema
    );
  } catch (err) {
    return yupToFormErrors(err);
  }

  return {};
};

export const PersonalDetails: React.FC<StepComponentProps<
  DetailsCaptureValues
>> = ({
  initialValues,
  returnUrl,
  supplierOrderReference,
  applicationId,
  multiStepSubmit
}) => {
  const monthInputRef: RefObject<HTMLInputElement> = useRef(null);
  const yearInputRef: RefObject<HTMLInputElement> = useRef(null);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, formikBag) => {
        multiStepSubmit(values, formikBag);
      }}
      validate={values => validate(values as any)}>
      {(formikBag: FormikProps<DetailsCaptureValues>) => (
        <Form>
          <Field name={`personalDetails.title`}>
            {({ field, meta }: FieldProps<string, DetailsCaptureValues>) => (
              <Box mb={4}>
                <InputWrapper
                  id='personalDetails.title'
                  label='Title'
                  error={meta.error}
                  {...labelPropsFromMeta({ meta }, 'label')}>
                  <RadioGroup
                    {...field}
                    name='personalDetails.title'
                    options={enumToRadioOptions(
                      title,
                      field.value,
                      'personalDetails.title'
                    )}
                    onChange={formikBag.handleChange}
                  />
                </InputWrapper>
              </Box>
            )}
          </Field>

          <Field name={`personalDetails.firstName`}>
            {({ field, meta }: FieldProps<string, DetailsCaptureValues>) => (
              <Box mt={2}>
                <InputWrapper
                  id='personalDetails.firstName'
                  label='First name'
                  error={meta.error}
                  {...labelPropsFromMeta({ meta }, 'label')}>
                  <TextField
                    {...labelPropsFromMeta({ meta }, 'input')}
                    tone={getTone(meta)}
                    type='text'
                    {...field}
                    id='personalDetails.firstName'
                  />
                </InputWrapper>
              </Box>
            )}
          </Field>

          <Field name={`personalDetails.lastName`}>
            {({ field, meta }: FieldProps<string, DetailsCaptureValues>) => (
              <Box mt={4}>
                <InputWrapper
                  id='personalDetails.lastName'
                  label='Last name'
                  error={meta.error}
                  {...labelPropsFromMeta({ meta }, 'label')}>
                  <TextField
                    {...labelPropsFromMeta({ meta }, 'input')}
                    type='text'
                    tone={getTone(meta)}
                    {...field}
                    id='personalDetails.lastName'
                  />
                </InputWrapper>
              </Box>
            )}
          </Field>

          <Field name={`personalDetails.dateOfBirth`}>
            {({
              field,
              meta,
              form
            }: FieldProps<string, DetailsCaptureValues>) => {
              const { value, ...fieldRest } = field;
              const [year, month, day] = value.split('-');
              const current = { year, month, day };
              const getOnChangeHandler = (
                name: keyof CombineDateInput,
                nextInput?: React.RefObject<HTMLInputElement>
              ) => (e: React.ChangeEvent<HTMLInputElement>) => {
                form.setFieldValue(
                  'personalDetails.dateOfBirth',
                  combineDate({ ...current, [name]: e.target.value })
                );
                if (
                  isTwoDigitValue(e.target.value) &&
                  nextInput &&
                  nextInput.current
                ) {
                  nextInput.current.focus();
                }
              };

              const getOnBlurHandler = (name: keyof CombineDateInput) => (
                e: React.ChangeEvent<HTMLInputElement>
              ) => {
                form.setFieldValue(
                  'personalDetails.dateOfBirth',
                  padDate({ ...current, [name]: e.target.value })
                );
              };

              const novunaSx = { width: ['100%', null, null, '97px'] };

              return (
                <Box mt={4}>
                  <InputWrapper
                    id='personalDetails.dateOfBirth'
                    label='Date of birth'
                    error={meta.error}
                    tone={getTone(meta)}
                    {...labelPropsFromMeta({ meta }, 'label')}>
                    <Flex>
                      <Box mr={2} sx={{ ...novunaSx }}>
                        <TextField
                          {...labelPropsFromMeta({ meta }, 'input')}
                          {...fieldRest}
                          type='text'
                          id='personalDetails.dateOfBirth.day'
                          name='date of birth: day'
                          value={day || ''}
                          onChange={getOnChangeHandler('day', monthInputRef)}
                          onBlur={getOnBlurHandler('day')}
                          maxLength={2}
                          placeholder='DD'
                          tone={getTone(meta)}
                          data-test-id='dateOfBirthDay'
                        />
                      </Box>
                      <Box mr={2} sx={{ ...novunaSx }}>
                        <TextField
                          {...labelPropsFromMeta({ meta }, 'input')}
                          {...fieldRest}
                          type='text'
                          id='personalDetails.dateOfBirth.month'
                          name='date of birth: month'
                          value={month || ''}
                          onChange={getOnChangeHandler('month', yearInputRef)}
                          onBlur={getOnBlurHandler('month')}
                          maxLength={2}
                          placeholder='MM'
                          tone={getTone(meta)}
                          data-test-id='dateOfBirthMonth'
                          ref={monthInputRef}
                        />
                      </Box>
                      <Box sx={{ ...novunaSx }}>
                        <TextField
                          {...labelPropsFromMeta({ meta }, 'input')}
                          {...fieldRest}
                          type='text'
                          id='personalDetails.dateOfBirth.year'
                          name='date of birth: year'
                          value={year || ''}
                          onChange={getOnChangeHandler('year')}
                          maxLength={4}
                          placeholder='YYYY'
                          tone={getTone(meta)}
                          data-test-id='dateOfBirthYear'
                          ref={yearInputRef}
                        />
                      </Box>
                    </Flex>
                  </InputWrapper>
                </Box>
              );
            }}
          </Field>

          <Field name={`personalDetails.maritalStatus`}>
            {({
              field,
              meta,
              form
            }: FieldProps<string, DetailsCaptureValues>) => {
              const handleChange = (e: React.ChangeEvent<any>) => {
                formikBag.handleChange(e);
                if (
                  !spouseEmploymentStatusRequired(
                    formikBag.values.financialDetails?.employmentStatus,
                    e.target.value
                  )
                ) {
                  form.setFieldValue(
                    'financialDetails.spouseEmploymentStatus',
                    ''
                  );
                }
              };
              return (
                <Box mt={4}>
                  <InputWrapper
                    id='personalDetails.maritalStatus'
                    label='Marital status'
                    error={meta.error}
                    {...labelPropsFromMeta({ meta }, 'label')}>
                    <RadioGroup
                      name='personalDetails.maritalStatus'
                      options={enumToRadioOptions(
                        maritalStatus,
                        field.value,
                        'personalDetails.maritalStatus'
                      )}
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </Box>
              );
            }}
          </Field>
          {getNavigationButtons({
            formikBag,
            applicationId,
            returnUrl,
            supplierOrderReference
          })}
        </Form>
      )}
    </Formik>
  );
};

export const personalDetailsStep: Step<DetailsCaptureValues> = {
  component: PersonalDetails,
  title: 'Personal details',
  description: 'Tell us about yourself'
};
