import { validFinanceApplication } from 'hitachi-retail-core/build/test/financeApplicationTestData';
import { BankAccountCheckService } from 'services/bankCheck';

const getMockBankAccountCheckService = (): BankAccountCheckService => ({
  validateBankDetails: async ({ sortCode, accountNumber }) => {
    console.log(
      'Mock invoked: bankCheckService.validateBankDetails with sortcode/acc no:',
      sortCode,
      accountNumber
    );
    return {
      valid: true
    };
  },
  updateBankDetails: async ({ id }) => {
    console.log(
      'Mock invoked: bankCheckService.updateBankDetails with id:',
      id
    );
    if (id === 'FAILBANKCHECK') {
      return {
        valid: false,
        error: 'Unable to validate customer bank account details'
      };
    }
    return {
      valid: true,
      bankDetails: validFinanceApplication.bankAccountDetails
    };
  }
});

export default getMockBankAccountCheckService;
