import { AxiosInstance } from 'axios';
import { spawn } from 'redux-saga/effects';
import {
  getApplicantRootSaga,
  MailOrderSagaParams
} from '../applicantApp/store/sagas';
import { getSendApplicationSagaWatcher } from '../endAndSend/sagas/saga';
import { AddressLookupService } from '../services/addressLookupService';
import { ApplicationsService } from '../services/applicationsService';
import { BankAccountCheckService } from '../services/bankCheck';
import { ConfigService } from '../services/config';
import { DecisionService } from '../services/decision';
import { DocumentDownloadConfirmationService } from '../services/documentDownloadConfirmationService';
import { ProductService } from '../services/product';
import { RetailerService } from '../services/retailer';
import { RetailerConfigService } from '../services/retailerConfig';
import { SendLoanAdvanceInfoService } from '../services/sendLoanAdvanceInfoService';
import { UserService } from '../services/user';
import { getAddressByPostcodeSagaWatcher } from './address/saga';
import { getDownloadAgreementSagaWatcher } from './agreement/saga';
import { getApplicationReferralNotesSagaWatcher } from './applicationReferralNotes/saga';
import { getBankAccountCheckSagaWatcher } from './bankCheck/saga';
import {
  getConfigSagaWatcher,
  getRetailerConfigSagaWatcher
} from './config/saga';
import {
  getConfirmForgotPasswordSagaWatcher,
  getSendPasswordResetCodeSagaWatcher
} from './forgotPassword/saga';
import { createNewApplicationSagaWatcher } from './createNewApplication/saga';
import { getCustomerSatisfactionSagaWatcher } from './customerSatisfaction/saga';
import { getDecisionSagaWatcher } from './decision/saga';
import { getDownloadDeclinedEmailByIdSagaWatcher } from './declinedEmail/saga';
import { getDirectAppUserSagaWatcher } from './directAppUser/saga';
import { getDocumentDownloadConfirmationSagaWatcher } from './documentDownload/saga';
import { resendEsatNoteEmailSagaWatcher } from './esatEmail/saga';
import { getActiveApplicationSagaWatcher } from './fetchActiveApplication/saga';
import { getFetchPreContractDownloadUrlSagaWatcher } from './preContractCreditInfo/saga';
import { getPreviousApplicationByIdSagaWatcher } from './previousApplicationById/saga';
import { getPreviousApplicationsSagaWatcher } from './previousApplications/saga';
import { getProductSagaWatcher } from './product/saga';
import { resumeApplicationSagaWatcher } from './resumeApplication/saga';
import { getRetailerSagaWatcher } from './retailer/saga';
import {
  getSaveApplicationSaga,
  getSaveApplicationSagaWatcher
} from './saveApplication/saga';
import {
  getSendLoanAdvanceInfoSaga,
  getSendLoanAdvanceInfoSagaWatcher
} from './sendLoanAdvanceInformation/saga';
import { getRetailerByApplicationIdSagaWatcher } from './retailerById/saga';
import { getSignApplicationSagaWatcher } from './signApplication/saga';
import { getSigningRedirectSagaWatcher } from './signingRedirects/saga';
import { getUpdateApplicationWatcher } from './updateApplication/saga';
import { getUserPolicySagaWatcher, getUserSagaWatcher } from './user/saga';
import {
  getCreateUserSagaWatcher,
  getLookupUserSagaWatcher,
  getSelectedUserSagaWatcher,
  getUpdateUserSagaWatcher
} from './users/saga';
import {
  getPgUsersSagaWatcher,
  getCreateEnhancedUserSagaWatcher,
  getUpdateEnhancedUserSagaWatcher
} from './enhancedUsers/saga';
import { UsersService } from '../services/users';
import { GoodsListService } from 'services/goodsList/goodsListService';
import { getGoodsListSagaWatcher } from './goodsList/saga';
import { amendApplicationSagaWatcher } from './amendApplication/saga';
import { getAuditHistorySagaWatcher } from './auditHistory/saga';

export interface DefaultSagaParams {
  apiClient: AxiosInstance;
}

export interface DefaultConfigParams {
  configService: ConfigService;
}

export interface ApplicationSagaParams {
  applicationsService: ApplicationsService;
  addressLookupService: AddressLookupService;
  mailOrderAddressLookupService: AddressLookupService;
  bankAccountCheckService: BankAccountCheckService;
  decisionService: DecisionService;
  mailOrderDecisionService: DecisionService;
  userService: UserService;
  retailerService: RetailerService;
  productService: ProductService;
  retailerConfigService: RetailerConfigService;
  sendLoanAdvanceInfoService: SendLoanAdvanceInfoService;
  documentDownloadConfirmationService: DocumentDownloadConfirmationService;
  usersService: UsersService;
  goodsListService: GoodsListService;
}

type RootSagaParams = DefaultSagaParams &
  DefaultConfigParams &
  MailOrderSagaParams &
  ApplicationSagaParams & {
    isMailOrderApp: boolean;
  };

export const getRootSaga = ({
  apiClient,
  applicationsService,
  mailOrderApplicationService,
  configService,
  applicantConfigService,
  isMailOrderApp,
  sendPrecontractInfoService,
  sendLoanAdvanceInfoService,
  authService,
  addressLookupService,
  mailOrderAddressLookupService,
  bankAccountCheckService,
  decisionService,
  mailOrderDecisionService,
  userService,
  retailerService,
  productService,
  retailerConfigService,
  goodsListService,
  usersService
}: RootSagaParams) =>
  function* rootSaga() {
    yield spawn(getRetailerSagaWatcher({ retailerService }));
    yield spawn(getRetailerByApplicationIdSagaWatcher({ retailerService }));
    yield spawn(
      getUpdateApplicationWatcher({ applicationsService, decisionService })
    );
    yield spawn(getUserPolicySagaWatcher({ userService }));
    yield spawn(getUserSagaWatcher({ userService }));
    yield spawn(getDirectAppUserSagaWatcher({ apiClient }));
    yield spawn(getConfigSagaWatcher({ configService }));
    yield spawn(getProductSagaWatcher({ productService }));
    yield spawn(getDownloadAgreementSagaWatcher({ apiClient }));
    yield spawn(getDownloadDeclinedEmailByIdSagaWatcher({ apiClient }));
    yield spawn(resendEsatNoteEmailSagaWatcher({ apiClient }));
    yield spawn(getSigningRedirectSagaWatcher());
    yield spawn(getDocumentDownloadConfirmationSagaWatcher({ apiClient }));

    // Mail Order
    if (isMailOrderApp) {
      yield spawn(
        getApplicantRootSaga({
          apiClient,
          applicantConfigService,
          mailOrderApplicationService,
          applicationsService,
          sendPrecontractInfoService,
          authService,
          addressLookupService: mailOrderAddressLookupService,
          decisionService: mailOrderDecisionService
        })
      );
      yield spawn(
        getBankAccountCheckSagaWatcher({
          bankAccountCheckService,
          applicationsService: mailOrderApplicationService
        })
      );
    } else {
      const saveApplicationSaga = getSaveApplicationSaga({
        applicationsService
      });
      yield spawn(
        getSendApplicationSagaWatcher({
          apiClient,
          applicationsService,
          saveApplicationSaga
        })
      );

      yield spawn(getAddressByPostcodeSagaWatcher({ addressLookupService }));
      yield spawn(getRetailerConfigSagaWatcher({ retailerConfigService }));
      yield spawn(getSaveApplicationSagaWatcher({ applicationsService }));
      yield spawn(getActiveApplicationSagaWatcher({ applicationsService }));
      yield spawn(
        getDecisionSagaWatcher({
          decisionService,
          applicationsService
        })
      );
      yield spawn(amendApplicationSagaWatcher);
      yield spawn(resumeApplicationSagaWatcher);
      yield spawn(
        getPreviousApplicationByIdSagaWatcher({ applicationsService })
      );
      yield spawn(getPreviousApplicationsSagaWatcher({ applicationsService }));
      yield spawn(getApplicationReferralNotesSagaWatcher({ apiClient }));
      yield spawn(getAuditHistorySagaWatcher({ apiClient }));
      yield spawn(getSignApplicationSagaWatcher({ applicationsService }));
      yield spawn(createNewApplicationSagaWatcher);
      yield spawn(getCustomerSatisfactionSagaWatcher({ apiClient }));
      yield spawn(getSendPasswordResetCodeSagaWatcher({ apiClient }));
      yield spawn(getConfirmForgotPasswordSagaWatcher({ apiClient }));
      yield spawn(
        getBankAccountCheckSagaWatcher({
          bankAccountCheckService,
          applicationsService
        })
      );

      const sendLoanAdvanceInfoSaga = getSendLoanAdvanceInfoSaga({
        sendLoanAdvanceInfoService
      });
      yield spawn(
        getSendLoanAdvanceInfoSagaWatcher({ sendLoanAdvanceInfoSaga })
      );
      yield spawn(getFetchPreContractDownloadUrlSagaWatcher({ apiClient }));
      yield spawn(getCreateUserSagaWatcher({ usersService }));
      yield spawn(getLookupUserSagaWatcher({ usersService }));
      yield spawn(getSelectedUserSagaWatcher({ usersService }));
      yield spawn(getUpdateUserSagaWatcher({ usersService }));
      yield spawn(getPgUsersSagaWatcher({ usersService }));
      yield spawn(getCreateEnhancedUserSagaWatcher({ usersService }));
      yield spawn(getUpdateEnhancedUserSagaWatcher({ usersService }));

      yield spawn(getGoodsListSagaWatcher({ goodsListService }));
    }
  };
