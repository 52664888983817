import { DefaultSagaParams } from '../sagas';

interface DirectAppUserLoginParams {
  directAppLoginToken: string;
}

export interface SessionCheckResult {
  success: boolean;
  data?: string;
}

export type DirectAppUserLogin = (
  params: DirectAppUserLoginParams
) => Promise<unknown>;

export interface DirectAppUserService {
  directApplogin: DirectAppUserLogin;
}

export const getDirectAppUserService = ({
  apiClient
}: DefaultSagaParams): DirectAppUserService => ({
  directApplogin: async ({ directAppLoginToken }) => {
    try {
      return await apiClient.post('direct-apps', {
        directAppLoginToken
      });
    } catch (error) {
      if (error.response) {
        const response = error.response;
        if (response.status >= 400 && response.status < 500) {
          throw Error(response.data.message);
        }
      }
      throw Error(
        'Direct apps CreditMaster3 login error. Please try again later.'
      );
    }
  }
});
