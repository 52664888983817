import { Box, Button, ReactModal, SelectField, Text } from 'compass-design';
import {
  CancellationReason,
  CancellationReasonOptions
} from 'hitachi-retail-core/build/enums/applicationCancellation';
import React, { useCallback, useState } from 'react';

export interface CancelApplicationConfirmationProps {
  cancellationReasonOptions?: CancellationReasonOptions;
  onConfirm: (cancellationReason?: CancellationReason) => void;
  onClose: () => void;
}

type SelectedCancellationReason = CancellationReason | undefined;

export const CancelApplicationConfirmation: React.FunctionComponent<CancelApplicationConfirmationProps> = ({
  cancellationReasonOptions,
  onClose,
  onConfirm
}) => {
  const [selectedCancellationReason, setSelectedCancellationReason] = useState<
    SelectedCancellationReason
  >(undefined);

  const handleConfirm = useCallback(() => {
    onConfirm(selectedCancellationReason);
    onClose();
  }, [selectedCancellationReason, onClose, onConfirm]);

  return (
    <ReactModal
      title='Cancel application'
      size='medium'
      isOpen={true}
      setIsOpen={onClose}>
      <Text mb={2}>
        Are you sure you would like to cancel this application?
      </Text>
      {cancellationReasonOptions && (
        <Box mt={4}>
          <Text mb={2} sx={{ fontWeight: 'bold' }}>
            Reason for cancellation
          </Text>
          <SelectField
            defaultValue=''
            onChange={({ target: { value } }) =>
              setSelectedCancellationReason(value as CancellationReason)
            }>
            <option value='' disabled>
              Please select
            </option>
            {Array.from(cancellationReasonOptions).map(([reason, label]) => (
              <option
                key={reason}
                value={reason}
                selected={reason === selectedCancellationReason}>
                {label}
              </option>
            ))}
          </SelectField>
        </Box>
      )}
      <Box mt={5}>
        <Button
          variant='secondaryDestructive'
          disabled={Boolean(
            cancellationReasonOptions && !selectedCancellationReason
          )}
          onClick={handleConfirm}
          data-test-id='compass-cancellation-confirmation-confirm'>
          Confirm
        </Button>
      </Box>
    </ReactModal>
  );
};

export default CancelApplicationConfirmation;
