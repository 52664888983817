import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import React, { ReactNode } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import FeatureToggleComponent, {
  FeatureToggleProps
} from '../../components/meta/FeatureToggle';
import { RootState } from '../../store';

export interface FeatureToggleOwnProps {
  feature: CompassFeature;
  fallbackComponent?: ReactNode;
}

export const mapStateToProps: MapStateToProps<
  FeatureToggleProps,
  FeatureToggleOwnProps,
  RootState
> = (
  { config: { enabledFeatures, status } },
  { feature, fallbackComponent }
) => {
  const featureEnabled = enabledFeatures.has(feature);

  return {
    featureIsEnabled: Boolean(featureEnabled),
    fallbackComponent,
    configStatus: status
  };
};

const Connected = connect(mapStateToProps)(FeatureToggleComponent);

/**
 * Conditionally enable/disable the display of the child component based on feature config.
 */
const FeatureToggle: React.FunctionComponent<FeatureToggleOwnProps> = props => (
  <Connected {...props} />
);

export default FeatureToggle;
