import { createAsyncAction } from 'typesafe-actions';
import * as app from './constants';

interface FetchDeclinedEmailByIdPayload {
  id: string;
}
export const fetchDeclinedLetterById = createAsyncAction(
  app.FETCH_DECLINED_EMAIL_BY_ID,
  app.FETCH_DECLINED_EMAIL_BY_ID_SUCCESS,
  app.FETCH_DECLINED_EMAIL_BY_ID_FAILURE
)<FetchDeclinedEmailByIdPayload, string, Error>();
