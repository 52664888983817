import { Box, Flex, NovunaCM3Footer, NovunaCM3Header } from 'compass-design';
import {
  CompassFeature,
  FeatureConfig
} from 'hitachi-retail-core/build/features/features';
import React from 'react';
import { Route, Switch, useHistory } from 'react-router';
import ApplicationError from 'components/ApplicationError';
import NoMatch from 'components/meta/NoMatch';
import Timeout from 'components/Timeout';
import ProtectedRoute from 'containers/meta/ProtectedRoute';
import ModalPlaceholder from 'containers/ModalPlaceholder';
import Prompt from 'containers/Prompt';
import { routes } from 'routes';
import Notification from 'retailerApp/components/Notification';
import ProposingOnBehalfOfBanner from '../../components/ProposingOnBehalfOfBanner';
import FinanceApplication from '../FinanceApplication';
import Search from '../Search/Search';
import Users from '../Users';
import EnhancedUsers from '../EnhancedUsers';
import PromptPasswordUpdate from 'components/PromptPasswordUpdate';
import ManagePassword from '../Profile/ManagePassword/ManagePassword';

export interface LoggedInAppPropsFromState {
  enabledFeatures: FeatureConfig;
  showUsers: boolean;
  showEnhancedUsers: boolean;
  loggedIn?: boolean;
  username?: string;
  promptPasswordUpdate?: boolean;
}

export interface LoggedInAppPropsFromDispatch {
  logoutUser: () => void;
  createNewApplication: () => void;
}

export type LoggedInAppProps = LoggedInAppPropsFromState &
  LoggedInAppPropsFromDispatch;

const LoggedInApp = ({
  enabledFeatures,
  loggedIn = false,
  username,
  promptPasswordUpdate,
  showUsers,
  showEnhancedUsers,
  logoutUser,
  createNewApplication
}: LoggedInAppProps) => {
  const history = useHistory();

  return (
    <Flex sx={{ flexDirection: 'column', minHeight: '100vh' }}>
      {loggedIn && (
        <>
          <NovunaCM3Header
            username={username ?? 'Unknown user'}
            logoutUser={logoutUser}
            createNewApplication={createNewApplication}
            searchLink={routes.search.index}
            usersLink={
              enabledFeatures.has(CompassFeature.CM3_USER_MANAGEMENT) &&
              showUsers
                ? routes.users.index
                : undefined
            }
            managePw={
              enabledFeatures.has(CompassFeature.STRONGER_PASSWORD_POLICY)
                ? () => {
                    return history.push(routes.profile.managePassword);
                  }
                : undefined
            }
            accessibilityBanner
          />
          <ProtectedRoute
            path={routes.apply.index}
            component={ProposingOnBehalfOfBanner}
            requiredFeature={CompassFeature.GLOBAL_USER_PROFILES}
            disableLoadingText
          />
        </>
      )}
      <Box sx={{ flexGrow: 1 }}>
        {promptPasswordUpdate && <PromptPasswordUpdate />}

        <Notification />
        <Prompt />
        <ModalPlaceholder />

        <Switch>
          <ProtectedRoute
            path={routes.apply.index}
            component={FinanceApplication}
          />
          <ProtectedRoute path={routes.search.index} component={Search} />
          <ProtectedRoute
            path={routes.profile.managePassword}
            component={ManagePassword}
            requiredFeature={CompassFeature.STRONGER_PASSWORD_POLICY}
          />
          <ProtectedRoute
            path={routes.users.index}
            component={showEnhancedUsers ? EnhancedUsers : Users}
            key={showEnhancedUsers ? 'EnhancedUsers' : 'Users'}
            requiredFeature={CompassFeature.CM3_USER_MANAGEMENT}
          />
          <ProtectedRoute path={routes.timeout} component={Timeout} />
          <ProtectedRoute
            path={routes.applicationError}
            component={ApplicationError}
          />
          <Route component={NoMatch} />
        </Switch>
      </Box>

      {loggedIn && <NovunaCM3Footer />}
    </Flex>
  );
};

export default LoggedInApp;
