import {
  Box,
  Layout,
  Message,
  NovunaHeading,
  NovunaPBFFooter,
  NovunaPBFHeader,
  NovunaSingleColumnLayout,
  NovunaSpinner,
  Text
} from 'compass-design';
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';

interface CustomerSatisfiedRouteParams {
  token: string;
}

export interface CustomerSatisfiedPropsFromState {
  feedbackComplete: boolean;
  feedbackSent: boolean;
  feedbackError: boolean;
  processing: boolean;
  feedbackAlreadySubmitted: boolean;
  retailerName: string;
  applicationId?: string;
}

export interface CustomerSatisfiedPropsFromDispatch {
  submitFeedback: (token: string) => void;
}

export type CustomerSatisfiedProps = CustomerSatisfiedPropsFromState &
  CustomerSatisfiedPropsFromDispatch &
  RouteComponentProps<CustomerSatisfiedRouteParams>;

const CustomerSatisfied: React.FunctionComponent<CustomerSatisfiedProps> = ({
  feedbackComplete,
  feedbackSent,
  feedbackError,
  feedbackAlreadySubmitted,
  processing,
  submitFeedback,
  match: {
    params: { token }
  },
  retailerName,
  applicationId
}) => {
  useEffect(() => {
    if (!feedbackSent) {
      submitFeedback(token);
    }
  }, [token, feedbackSent, submitFeedback]);

  let MainPage;

  if (processing) {
    MainPage = (
      <>
        <NovunaHeading as='h1' mb={3}>
          Please wait&hellip;
        </NovunaHeading>
        <NovunaSpinner />
      </>
    );
  } else if (feedbackComplete || feedbackAlreadySubmitted) {
    MainPage = (
      <>
        {feedbackComplete && (
          <>
            <NovunaHeading as='h1' mb={3}>
              Thank you
            </NovunaHeading>
            {applicationId && (
              <Text mb={3} sx={{ fontWeight: 'bold' }}>
                {applicationId}
              </Text>
            )}
            <Text>
              Thanks for providing feedback on your purchase
              {retailerName && ` from ${retailerName}`}.
            </Text>

            <NovunaHeading as='h2' mt={4} mb={3}>
              What happens next?
            </NovunaHeading>

            <NovunaHeading as='h3'>Payments</NovunaHeading>
            <Text mt={2} mb={3} sx={{ fontSize: '1' }}>
              We will forward payment to the Retailer and your payments will
              commence as detailed in the Credit Agreement, which you previously
              signed.
            </Text>

            <NovunaHeading as='h3'>Manage online</NovunaHeading>
            <Text mt={2} mb={4} sx={{ fontSize: '1' }}>
              We will contact you to let you know how to access your loan
              information online.
            </Text>

            <Text>You may now close this window.</Text>
          </>
        )}
        {feedbackAlreadySubmitted && (
          <>
            <NovunaHeading as='h1' mb={3}>
              Feedback provided
            </NovunaHeading>
            <Text>Feedback has already been provided on this purchase.</Text>
            <br />
            <Text>
              If you have further feedback please contact your retailer.
            </Text>
          </>
        )}
      </>
    );
  } else if (feedbackError && !feedbackAlreadySubmitted) {
    MainPage = (
      <>
        <NovunaHeading as='h1' mb={3}>
          Something went wrong
        </NovunaHeading>
        <Message variant='error'>
          <NovunaHeading as='h3' mb={1}>
            Please try again
          </NovunaHeading>
          <Text>Unfortunately we had a problem submitting your feedback.</Text>
        </Message>
      </>
    );
  }

  return (
    <>
      <NovunaPBFHeader />
      <Layout>
        <NovunaSingleColumnLayout>
          <Box sx={{ maxWidth: '620px' }} mx='auto'>
            {MainPage}
          </Box>
        </NovunaSingleColumnLayout>
      </Layout>
      <NovunaPBFFooter />
    </>
  );
};

export default CustomerSatisfied;
