import { Text } from 'compass-design';
import React from 'react';
import {
  UserPolicyHookAttribute,
  useUserPolicy
} from 'retailerApp/hooks/useUserPolicy';

export type AuthorizeProps = React.PropsWithChildren<{
  allow: UserPolicyHookAttribute;
  fallback?: React.ReactNode;
}>;

export const Authorize: React.FunctionComponent<AuthorizeProps> = ({
  allow: attribute,
  children,
  fallback = null
}) => {
  const userPolicy = useUserPolicy();
  if (userPolicy.can(attribute)) {
    return <>{children}</>;
  } else if (fallback) {
    return <>{fallback}</>;
  }
  return (
    <Text mt={4} sx={{ fontSize: 1 }}>
      You are not authorised to view this page.
    </Text>
  );
};

export default Authorize;
