import { FieldMetaProps } from 'formik';

export const getTone = (meta: FieldMetaProps<string>) => {
  const { error, touched, initialValue } = meta;

  if (error) {
    return 'negative';
  } else if ((!touched && initialValue) || touched) {
    return 'positive';
  }

  return 'neutral';
};
