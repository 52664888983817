import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { resetStore, ResetStoreAction } from '../actions';
import { fetchAgreementBySigningId, fetchAgreementUrl } from './actions';

export interface State {
  error: boolean;
  fetching: boolean;
  credentialsError: boolean;
  agreementUrl?: string;
  pdfUrl?: string;
  pdfBlob?: Blob;
}

export type FetchAgreementUrlAction = ActionType<typeof fetchAgreementUrl>;

export type FetchAgreementBySigningIdAction = ActionType<
  typeof fetchAgreementBySigningId
>;

type Action =
  | FetchAgreementUrlAction
  | FetchAgreementBySigningIdAction
  | ResetStoreAction;

export const initialState: State = {
  error: false,
  fetching: false,
  credentialsError: false
};

export const reducer: Reducer<State, Action> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchAgreementUrl.request):
      return {
        ...initialState,
        fetching: true
      };

    case getType(fetchAgreementUrl.success):
      return {
        ...state,
        agreementUrl: action.payload.url,
        fetching: false
      };

    case getType(fetchAgreementUrl.failure):
      return {
        ...state,
        error: true,
        fetching: false
      };

    case getType(fetchAgreementBySigningId.request): {
      /* Note: do not clear pdfUrl, it will be revoked in fetchAgreementBySigningId.success */
      return {
        ...state,
        error: false,
        fetching: true,
        credentialsError: false
      };
    }

    case getType(fetchAgreementBySigningId.success): {
      const pdfBlob = new Blob([action.payload], { type: 'application/pdf' });
      if (state.pdfUrl) {
        window.URL.revokeObjectURL(state.pdfUrl);
      }
      const pdfUrl = window.URL.createObjectURL(pdfBlob);
      return {
        ...state,
        pdfUrl,
        pdfBlob,
        fetching: false,
        credentialsError: false
      };
    }

    case getType(fetchAgreementBySigningId.failure): {
      if (action.payload.credentialsWrong) {
        return {
          ...state,
          error: false,
          fetching: false,
          credentialsError: true
        };
      } else {
        return {
          ...state,
          error: true,
          fetching: false,
          credentialsError: false
        };
      }
    }

    case getType(resetStore):
      return initialState;

    default: {
      return state;
    }
  }
};
