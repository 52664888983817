import { RadioGroupOption } from 'compass-design';
import { FormEnum } from 'hitachi-retail-core/build/enums';
import { padDatePart } from 'components/form/widgets/DateWidget';

export const enumToRadioOptions = (
  { enum: enumValues, enumNames }: FormEnum,
  fieldValue: string,
  inputIdPrefix?: string
): RadioGroupOption[] =>
  enumValues.map((value, index) => ({
    value,
    label: enumNames?.[index] ?? value,
    checked: value === fieldValue,
    inputId: [inputIdPrefix, value.replace(' ', '').toLowerCase()]
      .filter(v => v)
      .join('.')
  }));

export interface CombineDateInput {
  year: string;
  month: string;
  day: string;
}

export const combineDate = ({ year, month, day }: CombineDateInput): string =>
  [year, month, day].join('-');

export const padDate = ({ year, month, day }: CombineDateInput): string => {
  let paddedDay = day;
  let paddedMonth = month;

  // Want empty day/month fields if values not yet supplied i.e. are empty strings.
  // `padDatePart` returns "00" if given an empty string
  if (paddedDay) {
    paddedDay = padDatePart(day) || '';
  }
  if (paddedMonth) {
    paddedMonth = padDatePart(month) || '';
  }

  return combineDate({
    year,
    month: paddedMonth,
    day: paddedDay
  });
};

export const parseNumericInput = (input: string): number | string => {
  let value: string | number = input;
  const parsed = parseInt(value, 10);
  if (!Number.isNaN(parsed)) {
    value = parsed;
  }

  return value;
};
