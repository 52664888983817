import { getApplicationModificationStatus } from 'hitachi-retail-core/build/application/modification';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import isSoftSearchEnabled from 'utils/isSoftSearchEnabled/isSoftSearchEnabled';
import { AsyncStatus } from '../applicantApp/store/AsyncStatus';
import ApplicantDetails, {
  ApplicantDetailsPropsFromDispatch,
  ApplicantDetailsPropsFromState
} from '../components/applicantDetails/ApplicantDetails';
import { JsonFormData } from '../components/form/types';
import { RootState } from '../store';
import {
  bankAccountCheck,
  updateActiveApplication
} from '../store/application/actions';
import { selectBankAccountCheckStatus } from '../store/application/selectors';
import { fetchDecision } from '../store/decision/actions';
import { fetchActiveApplication } from '../store/previousApplicationDetail/actions';
import {
  selectMailOrderEnabled,
  selectNonMandatoryEmailEnabled
} from '../store/retailer/selectors';
import { saveApplication } from '../store/save/actions';

export const mapStateToProps = (
  state: RootState
): ApplicantDetailsPropsFromState => {
  const {
    application: { activeApplication, errorMessage },
    config: { enabledFeatures },
    decision: { processing: decisionProcessing }
  } = state;

  const applicationModificationStatus = getApplicationModificationStatus(
    activeApplication.status,
    enabledFeatures
  );

  const bankCheckStatus = selectBankAccountCheckStatus(state);
  const validDetails = state.application.successfulBankCheck;
  const processing = Boolean(
    bankCheckStatus === AsyncStatus.Loading || decisionProcessing
  );
  const mailorderEnabled = selectMailOrderEnabled(state);
  const nonMandatoryEmailEnabled = selectNonMandatoryEmailEnabled(state);

  return {
    application: activeApplication,
    validDetails,
    applicationModificationStatus,
    errorMessage,
    processing,
    mailorderEnabled,
    nonMandatoryEmailEnabled,
    enabledFeatures,
    softSearchEnabled: isSoftSearchEnabled({ state })
  };
};

export const mapDispatchToProps = (
  dispatch: Dispatch
): ApplicantDetailsPropsFromDispatch => ({
  onBankAccountCheck: (formData: JsonFormData) => {
    dispatch(
      updateActiveApplication({ document: formData, replaceValues: true })
    );
    dispatch(bankAccountCheck.request());
  },
  onSectionSubmit: (formData: JsonFormData) => {
    dispatch(
      updateActiveApplication({ document: formData, replaceValues: true })
    );
    dispatch(saveApplication.request());
  },
  onCompleteSubmit: (formData: JsonFormData, softSearch: boolean) => {
    dispatch(
      updateActiveApplication({ document: formData, replaceValues: true })
    );

    dispatch(fetchDecision.request({ softSearch }));
  },
  onLoadApplication: (applicationId: string) => {
    dispatch(fetchActiveApplication.request({ id: applicationId }));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantDetails);
