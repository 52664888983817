import { Flex, LoanAttribute, Text } from 'compass-design';
import { Decimal } from 'hitachi-retail-core/build/utils/decimal';
import React, { ReactNode } from 'react';
import { formatCurrency, formatPercent } from 'utils/formatters';

interface GetTermTextProps {
  minimumTerm: number;
  currentTerm: number;
}

interface AffordabilityOfferRadioButtonTextProps {
  title: string;
  text: string;
}

export const AffordabilityOfferRadioButtonText = ({
  title,
  text
}: AffordabilityOfferRadioButtonTextProps) => (
  <Flex>
    <Text mr={2} sx={{ fontSize: 2 }}>
      {title}
    </Text>
    <Text mt='auto' mb='auto' sx={{ fontSize: 1, color: 'monochrome.3' }}>
      {text}
    </Text>
  </Flex>
);

export const getIncreaseTermText = ({
  minimumTerm,
  currentTerm
}: GetTermTextProps) =>
  `Increase the term from ${currentTerm} to ${minimumTerm} months.`;

export const getIncreaseTermOptionText = ({
  minimumTerm,
  currentTerm
}: GetTermTextProps) => `From ${currentTerm} to ${minimumTerm} months`;

export const getIncreaseTermOfferTextForRadioButton = ({
  minimumTerm,
  currentTerm
}: GetTermTextProps): ReactNode => (
  <AffordabilityOfferRadioButtonText
    title='Increase term'
    text={`From ${currentTerm} to ${minimumTerm} months.`}
    data-test-id='increase-term'
  />
);

export const getReduceTermText = ({
  minimumTerm,
  currentTerm
}: GetTermTextProps) =>
  `Reduce the term from ${currentTerm} to ${minimumTerm} months.`;

export const getReduceTermOptionText = ({
  minimumTerm,
  currentTerm
}: GetTermTextProps) => `From ${currentTerm} to ${minimumTerm} months`;

export const getReduceTermOfferTextForRadioButton = ({
  minimumTerm,
  currentTerm
}: GetTermTextProps): ReactNode => (
  <AffordabilityOfferRadioButtonText
    title='Reduce term'
    text={`From ${currentTerm} to ${minimumTerm} months.`}
    data-test-id='reduce-term'
  />
);

interface GetIncreaseDepositTextProps {
  currentDeposit: Decimal;
  minimumDeposit: Decimal;
}

export const getIncreaseDepositText = ({
  currentDeposit,
  minimumDeposit
}: GetIncreaseDepositTextProps) =>
  `Increase the deposit from ${formatCurrency(
    currentDeposit
  )} to ${formatCurrency(minimumDeposit)}, cash or debit card accepted`;

export const getIncreaseDepositOptionText = ({
  currentDeposit,
  minimumDeposit
}: GetIncreaseDepositTextProps) =>
  `From ${formatCurrency(currentDeposit)} to ${formatCurrency(minimumDeposit)}`;

export const getIncreaseDepositOfferTextForRadioButton = ({
  currentDeposit,
  minimumDeposit
}: GetIncreaseDepositTextProps): ReactNode => (
  <AffordabilityOfferRadioButtonText
    title='Increase deposit'
    text={`From ${formatCurrency(currentDeposit)} to ${formatCurrency(
      minimumDeposit
    )}, cash or debit card accepted`}
    data-test-id='increase-deposit'
  />
);

interface GetLoanTextProps {
  maximumGoodsAmount: string | Decimal;
}

export const getReduceLoanOfferTextForRadioButton = ({
  maximumGoodsAmount
}: GetLoanTextProps): ReactNode => (
  <AffordabilityOfferRadioButtonText
    title='Reduce total cost'
    text={`Purchase goods up to ${formatCurrency(maximumGoodsAmount)}`}
    data-test-id='reduce-total-cost'
  />
);

export const getIncreaseLoanOfferTextForRadioButton = ({
  maximumGoodsAmount
}: GetLoanTextProps): ReactNode => (
  <AffordabilityOfferRadioButtonText
    title='Increase total cost'
    text={`Purchase goods up to ${formatCurrency(maximumGoodsAmount)}`}
    data-test-id='increase-total-cost'
  />
);

export interface LoanComparisonCardPreStringValues {
  totalCost: Decimal;
  deposit: Decimal;
  amountBorrowed: Decimal;
  repaidOver: number;
  interestRate: Decimal;
  representativeAPR: Decimal;
  interestPayable: Decimal;
  totalRepayable: Decimal;
  totalAmountPayable: Decimal;
  monthlyRepayment: Decimal;
  updatedItem: LoanAttribute;
  isSoftSearch: boolean;
  hideApplicationText?: boolean;
  isDesktopView?: boolean;
}

export const convertLoanComparisonCardProps = ({
  totalCost,
  deposit,
  amountBorrowed,
  repaidOver,
  interestRate,
  representativeAPR,
  interestPayable,
  totalRepayable,
  totalAmountPayable,
  monthlyRepayment,
  updatedItem,
  isSoftSearch,
  hideApplicationText,
  isDesktopView
}: LoanComparisonCardPreStringValues): {
  totalCost: string;
  deposit: string;
  amountBorrowed: string;
  repaidOver: string;
  interestRate: string;
  representativeAPR: string;
  interestPayable: string;
  totalRepayable: string;
  totalAmountPayable: string;
  monthlyRepayment: string;
  updatedItem?: LoanAttribute;
  isSoftSearch: boolean;
  buttonOnClick?: () => void;
  hideApplicationText?: boolean;
  isDesktopView?: boolean;
  interestPayableUpdated?: boolean;
} => ({
  amountBorrowed: formatCurrency(amountBorrowed),
  deposit: formatCurrency(deposit),
  interestPayable: formatCurrency(interestPayable),
  interestRate: formatPercent(interestRate) || '0%',
  monthlyRepayment: formatCurrency(monthlyRepayment),
  repaidOver: `${repaidOver} Months`,
  representativeAPR: formatPercent(representativeAPR) || '0%',
  totalCost: formatCurrency(totalCost),
  // TODO: Update this when introducing Interest Bearing financial products
  totalRepayable: formatCurrency(totalRepayable),
  totalAmountPayable: formatCurrency(totalAmountPayable),
  isSoftSearch,
  updatedItem,
  hideApplicationText,
  isDesktopView,
  interestPayableUpdated: representativeAPR > new Decimal(0)
});
