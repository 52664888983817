import React from 'react';
import { Box, Message, NovunaHeading, Text } from 'compass-design';
import { PurpleMail } from 'smallMessageSvg';

const MailOrderPBFReferralOpenBankingCard = () => (
  <Box mt={6} data-test-id='application-status-card'>
    <Message variant='info' restrictedWidth={false}>
      <NovunaHeading as='h3' mb={1}>
        Referred
      </NovunaHeading>
      <Text mb={2}>
        The Underwriting Team are looking at your application, but we need some
        additional information from you before we can make a decision.
      </Text>
      <Text mb={2}>
        You will be contacted once we have this information and your application
        has been reviewed.
      </Text>
    </Message>
    <Message
      variant='info'
      mt={4}
      style={{ alignItems: 'center' }}
      icon={
        <PurpleMail
          width={32}
          height={32}
          viewBox='0 0 64 64'
          style={{ minHeight: '100%' }}
        />
      }>
      <Box as='span'>
        <NovunaHeading as='h4' mb={1}>
          Take action to complete your application!
        </NovunaHeading>
        <Text>
          We will shortly send you an email explaining how you can easily
          provide the additional information we need. This might take a few
          minutes. Please check your inbox.
        </Text>
      </Box>
    </Message>
  </Box>
);

export default MailOrderPBFReferralOpenBankingCard;
