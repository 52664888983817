import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';
import { PgUser } from 'hitachi-retail-core/build/services/pgUsers/pgUsersService';
import { UserRole } from 'hitachi-retail-core/build/userPolicy';
import { UserRoleLabel } from 'hitachi-retail-core/build/userPolicy/role';

export type Status = 'All users' | 'Active users' | 'Deactivated users';

export const trim = (text: string | null) => text?.slice(0, 12);

export const coerceDateToString = (date: Date | string) => {
  if (typeof date !== 'string') {
    return date.toISOString();
  }
  return date;
};

export const partialMatch = (searchTerm: string, subject = '') => {
  const normalize = (value: string) =>
    value
      .trim()
      .split(' ')
      .join('')
      .toUpperCase();
  return normalize(subject).includes(normalize(searchTerm));
};

interface FilterUsers {
  usersList: PgUser[];
  selectedUserStatus: Status;
  showOnlyHeadOfficeUsers: boolean;
  searchTerm: string;
}

export const filterUsers = ({
  usersList,
  selectedUserStatus,
  showOnlyHeadOfficeUsers,
  searchTerm
}: FilterUsers): PgUser[] => {
  const shouldBeEnabled = selectedUserStatus === 'Active users';

  return usersList.filter(user => {
    if (selectedUserStatus !== 'All users') {
      if (shouldBeEnabled !== user.enabled) {
        return false;
      }
    }

    if (showOnlyHeadOfficeUsers && user.role !== UserRole.HeadOfficeUser) {
      return false;
    }

    if (
      searchTerm.length &&
      !partialMatch(searchTerm, user.defaultBranch) &&
      !partialMatch(searchTerm, user.role) &&
      !partialMatch(searchTerm, user.lastName ?? '')
    ) {
      return false;
    }

    return true;
  });
};

interface FilterBranches {
  branches?: Retailer[];
  searchTerm?: string;
}

export const filterBranches = ({ branches, searchTerm }: FilterBranches) => {
  if (!branches || !searchTerm || searchTerm.length < 2) {
    return [];
  }

  return branches.filter(branch => {
    if (
      !partialMatch(searchTerm, branch.supplierNumber) &&
      !partialMatch(searchTerm, branch.name) &&
      !partialMatch(searchTerm, branch.retailerNormalisedName) &&
      !partialMatch(searchTerm, branch.address.town)
    ) {
      return false;
    }

    return true;
  });
};

interface GetPageTitle extends SelectedRetailers {
  userRoles: UserRole[];
}

export const getPageTitle = ({
  userRoles,
  selectedBranch,
  selectedBroker,
  selectedHeadOffice
}: GetPageTitle) => {
  if (selectedBranch) {
    return 'Branch users';
  }

  if (selectedHeadOffice) {
    return selectedHeadOffice.hasChildBranches
      ? 'Head office and branch users'
      : 'Head office users';
  }

  if (selectedBroker) {
    return userRoles.includes(UserRole.GroupUser)
      ? 'Group users'
      : 'Broker users';
  }

  return 'Branch users';
};

interface SelectedRetailers {
  selectedBroker?: Retailer;
  selectedHeadOffice?: Retailer;
  selectedBranch?: Retailer;
}

export const getRetailer = ({
  selectedBranch,
  selectedBroker,
  selectedHeadOffice
}: SelectedRetailers) => {
  if (selectedBranch) {
    return selectedBranch;
  }

  if (selectedHeadOffice) {
    return selectedHeadOffice;
  }

  return selectedBroker;
};

interface GetDefaultUserRole extends SelectedRetailers {
  userRoles: UserRole[];
}

export const getDefaultUserRole = ({
  userRoles,
  selectedBranch,
  selectedBroker,
  selectedHeadOffice
}: GetDefaultUserRole) => {
  if (
    selectedBranch ||
    (selectedHeadOffice && !selectedHeadOffice.hasChildBranches)
  ) {
    return UserRole.BranchUser;
  }

  if (selectedHeadOffice) {
    return UserRole.HeadOfficeUser;
  }

  if (selectedBroker) {
    return userRoles.includes(UserRole.BrokerUser)
      ? UserRole.BrokerUser
      : UserRole.GroupUser;
  }

  return UserRole.BranchUser;
};

interface GetAdvancedRadioOptionsForUser extends SelectedRetailers {
  userRoles: UserRole[];
  isEditingBranchUser: boolean;
}

export const getAdvancedRadioOptionsForUser = ({
  userRoles,
  selectedBranch,
  selectedBroker,
  selectedHeadOffice,
  isEditingBranchUser
}: GetAdvancedRadioOptionsForUser) => {
  const defaultRoles = [
    {
      id: UserRole.BranchManager,
      inputId: 'branchManager',
      title: UserRoleLabel.branch_manager.label,
      text: UserRoleLabel.branch_manager.description,
      value: UserRole.BranchManager
    },
    {
      id: UserRole.BranchAdmin,
      inputId: 'branchAdmin',
      title: UserRoleLabel.branch_admin.label,
      text: UserRoleLabel.branch_admin.description,
      value: UserRole.BranchAdmin
    },
    {
      id: UserRole.BranchUser,
      inputId: 'branchUser',
      title: UserRoleLabel.branch_user.label,
      text: UserRoleLabel.branch_user.description,
      value: UserRole.BranchUser
    }
  ];

  if (
    selectedBranch ||
    (selectedHeadOffice && !selectedHeadOffice.hasChildBranches) ||
    isEditingBranchUser
  ) {
    return defaultRoles;
  }

  if (selectedHeadOffice) {
    return [
      {
        id: UserRole.HeadOfficeUser,
        title: UserRoleLabel.head_office_user.label,
        text: UserRoleLabel.head_office_user.description,
        value: UserRole.HeadOfficeUser
      }
    ];
  }

  if (selectedBroker) {
    return userRoles.includes(UserRole.BrokerUser)
      ? [
          {
            id: UserRole.BrokerUser,
            title: UserRoleLabel.broker_user.label,
            text: UserRoleLabel.broker_user.description,
            value: UserRole.BrokerUser
          }
        ]
      : [
          {
            id: UserRole.GroupUser,
            title: UserRoleLabel.group_user.label,
            text: UserRoleLabel.group_user.description,
            value: UserRole.GroupUser
          }
        ];
  }

  return defaultRoles;
};
