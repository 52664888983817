import { NovunaHeading, SoftSearchCardWithExpiry } from 'compass-design';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import { IncompleteFinanceApplication } from 'hitachi-retail-core/build/schemas/financeApplication';
import { TailoringOptionSelected } from 'hitachi-retail-core/build/services/tailoring/types';
import { DateTime } from 'luxon';
import React from 'react';
import IncreaseLoan, {
  ReduceTerm
} from 'retailerApp/components/DecisionAcceptPicker/DecisionAffordabilityAccept/DecisionAffordabilityAccept';
import DecisionDeclinePicker from 'retailerApp/components/DecisionDeclinePicker';
import DecisionLoading from 'retailerApp/components/DecisionLoading';
import { AffordabilityAcceptProps } from 'retailerApp/utils/loanDetails/getAffordabilityAccept';
import { AffordabilityDeclineProps } from 'retailerApp/utils/loanDetails/getAffordabilityDecline';
import { LoanDetails } from 'retailerApp/utils/loanDetails/getLoanDetails';
import DecisionError, {
  setDecisionError
} from '../ApplicationDecision/components/DecisionError/DecisionError';
import SoftAcceptOrRefer from './components/SoftAcceptOrRefer';
import SoftReferTimeout from './components/SoftReferTimeout';

const declinedPdfName = 'declined.pdf';

export interface ApplicationSoftDecisionPropsFromState {
  decisionApplicationStatus?: ApplicationStatus;
  decisionErrorMessage?: string;
  loanDetails?: LoanDetails;
  affordabilityDecline?: Pick<
    AffordabilityDeclineProps,
    | 'loanDetails'
    | 'maxInstalmentAmount'
    | 'preSetTailoringOption'
    | 'radioOptions'
  >;
  affordabilityAccept?: Pick<
    AffordabilityAcceptProps,
    'loanDetails' | 'preSetTailoringOption' | 'radioOptions'
  >;
  applicationId: string;
  pdfUrl?: string;
  pdfBlob?: Blob;
  softSearchExpiryDate: string | null;
  document: IncompleteFinanceApplication;
  isLoanAmend: boolean;
}

export interface ApplicationSoftDecisionPropsFromDispatch {
  onDownloadDeclinedLetter: (id: string) => void;
  onProceedToDecision: ({
    document,
    tailoringOptionSelected
  }: {
    document: IncompleteFinanceApplication;
    tailoringOptionSelected?: TailoringOptionSelected;
  }) => void;
  onApplicationAbort: () => void;
  onDecisionRetry: () => void;
}

export type ApplicationSoftDecisionProps = ApplicationSoftDecisionPropsFromState &
  ApplicationSoftDecisionPropsFromDispatch;

class ApplicationSoftDecision extends React.Component<
  ApplicationSoftDecisionProps
> {
  public fileDownloadLink: React.RefObject<HTMLAnchorElement>;

  constructor(props: ApplicationSoftDecisionProps) {
    super(props);
    this.fileDownloadLink = React.createRef<HTMLAnchorElement>();
  }

  public componentDidUpdate(prevProps: ApplicationSoftDecisionPropsFromState) {
    if (this.props.pdfUrl !== prevProps.pdfUrl) {
      if (this.fileDownloadLink.current) {
        if (navigator.msSaveOrOpenBlob) {
          // For Internet Explorer
          navigator.msSaveOrOpenBlob(this.props.pdfBlob, declinedPdfName);
        } else {
          this.fileDownloadLink.current.click();
        }
      }
    }
  }

  public render() {
    const {
      affordabilityDecline,
      affordabilityAccept,
      decisionApplicationStatus,
      loanDetails,
      applicationId,
      pdfUrl,
      onProceedToDecision,
      onDownloadDeclinedLetter,
      softSearchExpiryDate,
      document,
      isLoanAmend,
      decisionErrorMessage,
      onApplicationAbort,
      onDecisionRetry
    } = this.props;

    const affordabilityDeclineProps = affordabilityDecline
      ? {
          ...affordabilityDecline,
          onConfirmTailoringUpdate: onProceedToDecision
        }
      : undefined;

    const affordabilityAcceptProps = affordabilityAccept
      ? {
          ...affordabilityAccept,
          onConfirmTailoringUpdate: onProceedToDecision,
          onAcceptOriginalOffer: onProceedToDecision
        }
      : undefined;

    const deferralPeriod = document.loanRepayment?.deferralPeriod ?? 0;

    const softSearchExpiry = softSearchExpiryDate
      ? DateTime.fromISO(softSearchExpiryDate).toFormat('d MMMM y')
      : '';

    const heading = (
      <NovunaHeading as='h1' mb={3}>
        Soft search check
      </NovunaHeading>
    );

    if (decisionErrorMessage) {
      return (
        <DecisionError
          onApplicationAbort={onApplicationAbort}
          onDecisionRetry={onDecisionRetry}
          decisionError={setDecisionError(decisionErrorMessage)}
        />
      );
    }

    switch (decisionApplicationStatus) {
      case ApplicationStatus.decision_refer:
        return (
          <>
            {heading}
            <SoftReferTimeout />
          </>
        );
      case ApplicationStatus.decision_quotation_accept:
        if (!loanDetails) {
          return null;
        }
        if (loanDetails.increaseLoanOffer && loanDetails.originalOffer) {
          return (
            <>
              {heading}
              <IncreaseLoan
                loanDetails={loanDetails}
                goodsDescription={loanDetails.goodsDescription}
                editLoanProps={loanDetails.increaseLoanOffer}
                softSearchExpiry={softSearchExpiry}
                affordabilityAcceptProps={affordabilityAcceptProps!}
                deferralPeriod={loanDetails.deferralPeriod}
                isIncreaseLoan
              />
            </>
          );
        }
        if (loanDetails.reduceTermOffer && loanDetails.originalOffer) {
          return (
            <>
              {heading}
              <ReduceTerm
                affordabilityAcceptProps={affordabilityAcceptProps!}
                softSearchExpiry={softSearchExpiry}
              />
            </>
          );
        }
        if (loanDetails.originalOffer) {
          return (
            <>
              {heading}
              <SoftAcceptOrRefer
                originalOffer={loanDetails.originalOffer}
                applicationId={applicationId}
                deferralPeriod={deferralPeriod}
                onClickProceedToDecision={() =>
                  onProceedToDecision({ document })
                }
              />
              <SoftSearchCardWithExpiry softSearchExpiry={softSearchExpiry} />
            </>
          );
        }

        return null;
      case ApplicationStatus.decision_quotation_refer:
        if (!loanDetails || !loanDetails.originalOffer) {
          return null;
        }
        return (
          <>
            {heading}
            <SoftAcceptOrRefer
              originalOffer={loanDetails.originalOffer}
              applicationId={applicationId}
              deferralPeriod={deferralPeriod}
              isRefer
              onClickProceedToDecision={() => onProceedToDecision({ document })}
            />
            <SoftSearchCardWithExpiry softSearchExpiry={softSearchExpiry} />
          </>
        );
      case ApplicationStatus.decision_quotation_decline:
        return (
          <>
            {heading}
            <DecisionDeclinePicker
              isSoftSearch
              affordabilityDecline={affordabilityDeclineProps}
              downloadLetter={{
                applicationId,
                fileDownloadLink: this.fileDownloadLink,
                pdfUrl,
                declinedPdfName,
                onDownloadDeclinedLetter
              }}
              softSearchExpiry={softSearchExpiry}
              isLoanAmend={isLoanAmend}
            />
          </>
        );
      case undefined:
        return <DecisionLoading />;
      default:
        return <></>;
    }
  }
}

export default ApplicationSoftDecision;
