import { DefaultSagaParams } from '../sagas';

export interface DeclinedLetterService {
  getDeclinedLetter: (id: string) => Promise<string>;
}

export const getDeclinedLetterService = ({
  apiClient
}: DefaultSagaParams): DeclinedLetterService => ({
  getDeclinedLetter: async id => {
    try {
      const { data } = await apiClient.get(
        `protected/applications/${id}/declined`,
        {
          headers: { Accept: 'application/pdf' },
          responseType: 'arraybuffer'
        }
      );

      return data;
    } catch (err) {
      throw new Error('Cannot download declined letter');
    }
  }
});
