import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';

interface FilterRetailers {
  retailers: Retailer[];
  searchCriteria: string;
}

const partialMatch = (query: string, subject = '') => {
  const normalize = (value: string) =>
    value
      .trim()
      .split(' ')
      .join('')
      .toUpperCase();
  return normalize(subject).includes(normalize(query));
};

const filterRetailers = ({
  retailers,
  searchCriteria
}: FilterRetailers): Retailer[] =>
  retailers.reduce<Retailer[]>((result, retailer) => {
    if (
      partialMatch(searchCriteria, retailer.supplierNumber) ||
      partialMatch(searchCriteria, retailer.name)
    ) {
      result.push(retailer);
    }
    return result;
  }, []);

export default filterRetailers;
