import UseOfBankDetailsStatement from 'components/applicantDetails/components/useOfBankDetailsStatement/UseOfBankDetailsStatement';
import { UiSchema } from 'react-jsonschema-form';
import React from 'react';

const initialUiSchema: UiSchema = {
  customerPresent: {
    'ui:title': 'Is the customer present?',
    'ui:field': 'CustomerPresent',
    'ui:displaySectionTitle': false,
    'ui:displaySectionCompleteIcon': false
  },
  personalDetails: {
    title: {
      'ui:autofocus': true,
      'ui:widget': 'radio' // Novuna only
    },
    maritalStatus: {
      'ui:widget': 'radio' // Novuna only
    }
  },
  contactDetails: {
    emailAddress: {
      'ui:autofocus': true,
      'ui:widget': 'email'
    },
    phoneNumber: {
      'ui:shortLength': true
    }
  },
  mainAddressDetails: {
    accommodationStatus: {
      'ui:autofocus': true,
      'ui:widget': 'radio' // Novuna only
    },
    mainAddress: {
      'ui:field': 'MultiAddress',
      'ui:monthsAddressesRequired': 36,
      'ui:maxAddressCount': 3
    }
  },
  deliveryDetails: {
    goodsDelivered: {
      'ui:autofocus': true,
      'ui:widget': 'radio' // Novuna only
    },
    deliveryAddress: {
      'ui:field': 'AddressFinder',
      'ui:postcodeLabel': 'Delivery address postcode'
    },
    movingToAddress: {
      'ui:widget': 'radio' // Novuna only
    }
  },
  identityDetails: {
    proofOfIdentity: {
      'ui:autofocus': true,
      'ui:widget': 'radio' // Novuna only
    }
  },
  financialDetails: {
    employmentStatus: {
      'ui:autofocus': true,
      'ui:widget': 'radio' // Novuna only
    },
    spouseEmploymentStatus: {
      'ui:widget': 'hidden'
    },
    incomeValue: {
      'ui:shortLength': true
    },
    financialDependants: {
      'ui:widget': 'radio' // Novuna only
    }
  },
  bankAccountDetails: {
    name: {
      'ui:autofocus': true
    },
    sortCode: {
      'ui:shortLength': true
    },
    accountNumber: {
      'ui:shortLength': true,
      // Ensure this remains on the field displayed last
      'ui:help': <UseOfBankDetailsStatement />
    },
    branchName: {
      'ui:widget': 'hidden'
    },
    bankName: {
      'ui:widget': 'hidden'
    },
    addressLine1: {
      'ui:widget': 'hidden'
    },
    addressLine2: {
      'ui:widget': 'hidden'
    },
    addressLine3: {
      'ui:widget': 'hidden'
    },
    town: {
      'ui:widget': 'hidden'
    },
    county: {
      'ui:widget': 'hidden'
    },
    postCode: {
      'ui:widget': 'hidden'
    }
  }
};

export default initialUiSchema;
