import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import { UserPolicyAttribute } from 'hitachi-retail-core/build/userPolicy';
import { useSelector } from 'store';
import { selectEnabledFeatures } from 'store/config/selectors';
import { selectUserPolicy } from 'store/user/selectors';

export type UserPolicyHook = ReturnType<typeof useUserPolicy>;

export type UserPolicyHookAttribute =
  | UserPolicyAttribute
  | UserPolicyAttribute[];

export const useUserPolicy = () => {
  const enabledFeatures = useSelector(selectEnabledFeatures);
  const featureEnabled = enabledFeatures.has(
    CompassFeature.CM3_USER_MANAGEMENT
  );

  const userPolicy = useSelector(selectUserPolicy);
  const canCheck = featureEnabled && userPolicy !== undefined;

  const allowed = (attributes: UserPolicyHookAttribute) => {
    let allowed = false;
    if (Array.isArray(attributes)) {
      allowed = attributes.some(attribute => userPolicy?.[attribute] === true);
    } else {
      allowed = userPolicy?.[attributes] === true;
    }
    return allowed;
  };

  return {
    can: (attribute: UserPolicyHookAttribute) => canCheck && allowed(attribute)
  };
};
