import { call, put, takeEvery } from 'redux-saga/effects';
import { getType, isOfType } from 'typesafe-actions';
import {
  PreContractCreditInfoService,
  getPreContractCreditInfoService
} from '../../services/preContractCreditInfoService';
import { fetchPreContractDownloadUrl } from '../../store/preContractCreditInfo/actions';
import { FetchPreContractDownloadUrlAction } from '../../store/preContractCreditInfo/reducer';
import { DefaultSagaParams } from '../index';

interface FetchPreContractDownloadUrlProps {
  preContractCreditInfoService: PreContractCreditInfoService;
}

export const getFetchPreContractDownloadUrlSaga = ({
  preContractCreditInfoService
}: FetchPreContractDownloadUrlProps) =>
  function*(action: FetchPreContractDownloadUrlAction) {
    if (isOfType(getType(fetchPreContractDownloadUrl.request), action)) {
      const { applicationId, forceDownload } = action.payload;
      try {
        const response = yield call(
          preContractCreditInfoService.fetchPreContractDownloadUrl,
          {
            applicationId,
            forceDownload
          }
        );
        const { url } = response;
        yield put(fetchPreContractDownloadUrl.success(url));
      } catch (err) {
        yield put(fetchPreContractDownloadUrl.failure(err));
      }
    }
  };

export const getFetchPreContractDownloadUrlSagaWatcher = ({
  apiClient
}: DefaultSagaParams) =>
  function*() {
    const preContractCreditInfoService = getPreContractCreditInfoService({
      apiClient
    });

    yield takeEvery(
      getType(fetchPreContractDownloadUrl.request),
      getFetchPreContractDownloadUrlSaga({ preContractCreditInfoService })
    );
  };
