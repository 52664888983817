import { AxiosError } from 'axios';
import { DefaultSagaParams } from '../sagas';
import {
  ConfirmForgotPasswordPayload,
  SendPasswordResetCodePayload
} from '../store/user/actions';

export interface PasswordService {
  sendPasswordResetCode: (
    payload: SendPasswordResetCodePayload
  ) => Promise<void>;
  confirmForgotPassword: (
    payload: ConfirmForgotPasswordPayload
  ) => Promise<void>;
}

export const getPasswordService = ({
  apiClient
}: DefaultSagaParams): PasswordService => ({
  sendPasswordResetCode: async payload => {
    try {
      await apiClient.post('auth/forgotPassword', payload);
    } catch (err) {
      throw new Error('Failed to send password reset link');
    }
  },
  confirmForgotPassword: async payload => {
    try {
      await apiClient.post('auth/confirmForgotPassword', payload);
    } catch (err) {
      const error = err as AxiosError;
      if (error.response && error.response.data && error.response.data.errors) {
        throw new Error(error.response.data.errors[0]);
      } else {
        throw new Error('Failed to reset password');
      }
    }
  }
});
