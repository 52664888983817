import {
  Button,
  Message,
  NovunaHeading,
  NovunaTick,
  Text
} from 'compass-design';
import ButtonGroup from 'components/common/ButtonGroup';
import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import React from 'react';
import { AdequateExplanation } from '../../../../../components/AdequateExplanation';
import { KeyLoanFeatures } from '../../../../../components/KeyLoanFeatures';
import EndAndSend from '../../../../../endAndSend';

export interface DecisionAcceptProps {
  regulatedApplication?: boolean;
  productIsDeferred?: boolean;
  productEarlySettlementFee?: boolean;
  onRegulatedContinue: () => void;
  onUnRegulatedContinue: () => void;
}

export const DecisionAccept: React.FC<{
  decisionAcceptProps: DecisionAcceptProps;
}> = ({
  decisionAcceptProps: {
    regulatedApplication,
    productIsDeferred,
    productEarlySettlementFee,
    onRegulatedContinue,
    onUnRegulatedContinue
  }
}) => (
  <div data-test-id='compass-application-decision-accepted'>
    <Message variant='success' icon={<NovunaTick />}>
      <NovunaHeading as='h3' id='decision-accept-heading' mb={1}>
        Accepted
      </NovunaHeading>
      <Text id='decision-accept-text'>
        Great news, your application has been approved!
      </Text>
    </Message>

    {regulatedApplication ? (
      <AdequateExplanation
        productIsDeferred={productIsDeferred}
        productEarlySettlementFee={productEarlySettlementFee}
      />
    ) : (
      <KeyLoanFeatures />
    )}

    <ButtonGroup mt={5}>
      <Button
        id='decision-accept-submit-button'
        isJumbo
        onClick={
          regulatedApplication ? onRegulatedContinue : onUnRegulatedContinue
        }
        data-test-id={`compass-application-decision-${
          regulatedApplication ? 'regulated' : 'unregulated'
        }-continue`}>
        Continue
      </Button>
      <EndAndSend
        id='decision-accept-sendtocustomer-button'
        disabled={false}
        enabledBy={CompassFeature.MAIL_ORDER_SEND_DECISION_ACCEPT}
        mailOrderEnabled
      />
    </ButtonGroup>
  </div>
);

export default DecisionAccept;
