import React, { CSSProperties } from 'react';
import { Button, useIsModalOpen, Text, ReactModal } from 'compass-design';

export type Status =
  | 'accepted'
  | 'declined'
  | 'referred'
  | 'soft_search_accepted'
  | 'soft_search_declined'
  | 'soft_search_referred'
  | 'abandoned'
  | 'error';

export interface RetailerRedirectButtonProps {
  baseUrl?: string;
  applicationId: string;
  status: Status;
  supplierOrderReference?: string;
  buttonText: string;
  buttonSx?: CSSProperties;
  modalHeading?: string;
  modalButtonText?: string;
  novunaDisplay?: boolean;
}

export interface RetailerRedirectButtonWithModalProps
  extends RetailerRedirectButtonProps {
  modalText: string;
  noSpacing?: boolean;
}

export interface CancelApplicationButtonProps {
  baseUrl?: string;
  applicationId: string;
  supplierOrderReference?: string;
}

export const generateWsRedirectUrl = (
  baseUrl: string,
  applicationId: string,
  status: Status,
  supplierOrderReference?: string
) => {
  const url = new URL(baseUrl);
  url.searchParams.append('applicationId', applicationId);
  url.searchParams.append('status', status);
  if (supplierOrderReference) {
    url.searchParams.append('supplierOrderReference', supplierOrderReference);
  }

  return url;
};

export const RetailerRedirectButton: React.FC<RetailerRedirectButtonProps> = ({
  baseUrl,
  applicationId,
  status,
  buttonText,
  supplierOrderReference,
  novunaDisplay
}) => {
  if (!baseUrl || !novunaDisplay) {
    return null;
  }

  const url = generateWsRedirectUrl(
    baseUrl,
    applicationId,
    status,
    supplierOrderReference
  );

  return (
    <Button
      isJumbo
      onClick={() => {
        window.location.href = url.href;
      }}
      data-test-id='pbf-retailer-redirect'>
      {buttonText}
    </Button>
  );
};

export const BackToStoreTextButton: React.FC<RetailerRedirectButtonWithModalProps> = ({
  baseUrl,
  status,
  applicationId,
  supplierOrderReference,
  buttonText,
  modalText,
  modalHeading,
  modalButtonText,
  novunaDisplay
}) => {
  const [modalIsOpen, setModalIsOpen] = useIsModalOpen();

  if (!baseUrl || !novunaDisplay) {
    return null;
  }

  const url = generateWsRedirectUrl(
    baseUrl,
    applicationId,
    status,
    supplierOrderReference
  );

  return (
    <>
      <Text
        data-test-id='pbf-back-to-store'
        onClick={() => {
          setModalIsOpen(true);
        }}
        sx={{
          color: 'secondaryPurple',
          textDecoration: 'underline',
          cursor: 'pointer'
        }}>
        {buttonText}
      </Text>
      <ReactModal
        data-test-id='back-to-store-modal'
        title={modalHeading}
        size='small-medium'
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}>
        <Text>{modalText}</Text>
        <Button
          isJumbo
          mt={3}
          onClick={() => {
            window.location.href = url.href;
          }}
          data-test-id='pbf-abandon-application'>
          {modalButtonText ?? 'Abandon application'}
        </Button>
      </ReactModal>
    </>
  );
};

export const CancelApplicationTextButton: React.FC<CancelApplicationButtonProps> = ({
  baseUrl,
  applicationId,
  supplierOrderReference
}) => {
  return (
    <BackToStoreTextButton
      novunaDisplay
      baseUrl={baseUrl}
      status='abandoned'
      applicationId={applicationId}
      supplierOrderReference={supplierOrderReference}
      buttonText='Abandon application'
      modalText={
        'This application has already been approved and will still appear on your credit history if you choose to abandon it.'
      }
    />
  );
};
