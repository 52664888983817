import { DefaultSagaParams } from 'sagas';

interface DocumentDownloadServiceParams extends DefaultSagaParams {
  baseUrl: string;
}

export interface DocumentDownloadUrlResponse {
  url: string;
}

export interface DocumentDownloadService {
  fetchPrecontractDownloadUrl: (
    id: string
  ) => Promise<DocumentDownloadUrlResponse>;
  fetchDraftDownloadUrl: (id: string) => Promise<DocumentDownloadUrlResponse>;
}

export const getDocumentDownloadService = ({
  baseUrl,
  apiClient
}: DocumentDownloadServiceParams): DocumentDownloadService => ({
  fetchPrecontractDownloadUrl: async id => {
    try {
      const response = await apiClient.post(
        `${baseUrl}/${id}/download/pre-contract-info`
      );
      return response.data;
    } catch (err) {
      throw new Error(`Failed to send Loan Advance Info: ${err}`);
    }
  },
  fetchDraftDownloadUrl: async id => {
    try {
      const response = await apiClient.post(
        `mo/application/${id}/download/draft`
      );
      return response.data;
    } catch (err) {
      throw new Error(`Failed to send Application Draft: ${err}`);
    }
  }
});
