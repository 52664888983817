import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import {
  applicantTokenExchange,
  ApplicantTokenExchangeRequest
} from '../../store/applicantAuth/actions';
import {
  selectApplicantAccessToken,
  selectCodeVerifier
} from '../../store/applicantAuth/reducer';

export interface UseTokenExchangeInput {
  code: string | string[];
  authState?: string | string[];
}

export const useTokenExchange = ({
  code,
  authState
}: UseTokenExchangeInput) => {
  const dispatch = useDispatch();

  const token = useSelector(selectApplicantAccessToken);
  const codeVerifier = useSelector(selectCodeVerifier);

  return useCallback(() => {
    if (typeof code !== 'string' || token) {
      return;
    }

    const tokenExchangeInput: ApplicantTokenExchangeRequest = {
      authorizationCode: code,
      codeVerifier
    };

    if (typeof authState === 'string') {
      tokenExchangeInput.authState = authState;
    }

    dispatch(applicantTokenExchange.request(tokenExchangeInput));
  }, [dispatch, authState, codeVerifier, code, token]);
};
