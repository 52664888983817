import { GoodsListItem } from 'hitachi-retail-core';

interface FormatGoodsList {
  goodsList: GoodsListItem[];
  goodsDescription: string;
}

const formatGoodsList = ({ goodsList, goodsDescription }: FormatGoodsList) => {
  if (goodsList.length > 0) {
    const isInList =
      goodsList.filter(listItem => listItem.value === goodsDescription)
        .length === 1;

    if (!isInList && goodsDescription) {
      goodsList.unshift({ id: '0', value: goodsDescription });
    }
  }

  return goodsList;
};

export default formatGoodsList;
