import { JSONSchema6 } from 'json-schema';
import { UiSchema } from 'react-jsonschema-form';
import { IncompleteFinanceApplication } from 'hitachi-retail-core/build/schemas/financeApplication';
import { deleteObsoleteFormData } from '../../../form/schema';
import deliveryDetailsSubmit from './deliveryDetailsSubmit';

const formSectionSubmitFunctions = {
  deliveryDetails: deliveryDetailsSubmit
};

const onFormSectionSubmit = (
  sectionKey: string,
  formData: IncompleteFinanceApplication,
  formSchema: JSONSchema6,
  uiSchema: UiSchema
) => {
  deleteObsoleteFormData(sectionKey, formData, formSchema);

  Object.keys(formSectionSubmitFunctions).forEach(funcSectionKey => {
    if (typeof formSectionSubmitFunctions[funcSectionKey] === 'function') {
      formSectionSubmitFunctions[funcSectionKey](formData, uiSchema);
    }
  });
};

export default onFormSectionSubmit;
