import {
  accommodationStatus,
  customerPresent,
  deliveryAddressTypeValue,
  employmentStatus,
  financialDependants,
  goodsDelivered,
  maritalStatus,
  movingToAddress,
  proofOfIdentity,
  title
} from 'hitachi-retail-core/build/enums';
import { JSONSchema6 } from 'json-schema';

export interface ApplicantDetailsFormSchemaOverrides {
  goodsDeliveredEnumOverride?: any;
  goodsDeliveredEnumNamesOverride?: any;
}

export const applicantDetailsFormSchema = ({
  goodsDeliveredEnumOverride,
  goodsDeliveredEnumNamesOverride
}: ApplicantDetailsFormSchemaOverrides): JSONSchema6 =>
  ({
    type: 'object',
    required: [
      'customerPresent',
      'personalDetails',
      'contactDetails',
      'mainAddressDetails',
      'deliveryDetails',
      'identityDetails',
      'financialDetails',
      'bankAccountDetails'
    ],
    properties: {
      customerPresent: {
        title: 'Customer presence',
        type: 'boolean',
        default: true,
        enum: customerPresent.enum,
        enumNames: customerPresent.enumNames
      },
      personalDetails: {
        type: 'object',
        title: 'Personal details',
        required: [
          'title',
          'firstName',
          'lastName',
          'maritalStatus',
          'dateOfBirth'
        ],
        properties: {
          title: {
            title: 'Title',
            type: 'string',
            enum: title.enum,
            enumNames: title.enumNames
          },
          firstName: {
            title: 'First name',
            type: 'string'
          },
          lastName: {
            title: 'Last name',
            type: 'string'
          },
          maritalStatus: {
            title: 'Marital status',
            type: 'string',
            enum: maritalStatus.enum,
            enumNames: maritalStatus.enumNames
          },
          dateOfBirth: {
            title: 'Date of birth',
            type: 'string',
            format: 'date'
          }
        }
      },
      contactDetails: {
        type: 'object',
        title: 'Contact details',
        required: ['emailAddress', 'phoneNumber'],
        properties: {
          emailAddress: {
            title: 'Email address',
            type: 'string'
          },
          phoneNumber: {
            title: 'Phone number',
            type: 'string',
            description: 'Mobile or landline'
          }
        }
      },
      mainAddressDetails: {
        type: 'object',
        title: 'Home address',
        required: ['accommodationStatus', 'mainAddress'],
        properties: {
          accommodationStatus: {
            title: 'Residential status',
            type: 'string',
            enum: accommodationStatus.enum,
            enumNames: accommodationStatus.enumNames
          },
          mainAddress: {
            title: '',
            type: 'array',
            items: {
              type: 'object',
              required: ['address', 'fromYear', 'fromMonth'],
              properties: {
                address: {
                  $ref: '#/definitions/addressFinder'
                },
                fromYear: {
                  type: 'integer'
                },
                fromMonth: {
                  type: 'integer'
                }
              }
            }
          }
        }
      },
      deliveryDetails: {
        type: 'object',
        title: 'Delivery',
        required: ['goodsDelivered'],
        properties: {
          goodsDelivered: {
            title: 'Is delivery required?',
            type: 'string',
            enum: goodsDeliveredEnumOverride || goodsDelivered.enum,
            enumNames:
              goodsDeliveredEnumNamesOverride || goodsDelivered.enumNames,
            default: 'NO'
          }
        },
        dependencies: {
          goodsDelivered: {
            oneOf: [
              {
                properties: {
                  goodsDelivered: {
                    enum: ['NO', deliveryAddressTypeValue.CurrentAddress]
                  }
                }
              },
              {
                required: ['deliveryAddress', 'movingToAddress'],
                properties: {
                  goodsDelivered: {
                    enum: [deliveryAddressTypeValue.OtherAddress]
                  },
                  deliveryAddress: {
                    title: 'Delivery address',
                    $ref: '#/definitions/addressFinder'
                  },
                  movingToAddress: {
                    title: 'Is the customer moving to this delivery address?',
                    type: 'string',
                    enum: movingToAddress.enum,
                    enumNames: movingToAddress.enumNames
                  }
                }
              }
            ]
          }
        }
      },
      identityDetails: {
        type: 'object',
        title: 'Proof of identity',
        required: ['proofOfIdentity'],
        properties: {
          proofOfIdentity: {
            title: 'Proof of identity',
            type: 'string',
            enum: proofOfIdentity.enum,
            enumNames: proofOfIdentity.enumNames
          }
        }
      },
      financialDetails: {
        type: 'object',
        title: 'Income and dependents',
        required: [
          'employmentStatus',
          'spouseEmploymentStatus',
          'incomeValue',
          'financialDependants'
        ],
        properties: {
          employmentStatus: {
            title: 'Employment status',
            type: 'string',
            enum: employmentStatus.enum,
            enumNames: employmentStatus.enumNames
          },
          spouseEmploymentStatus: {
            title: 'Spouse employment status',
            type: 'string',
            enum: employmentStatus.enum,
            enumNames: employmentStatus.enumNames
          },
          incomeValue: {
            title: 'Annual income',
            type: 'number',
            description:
              'The amount you earn in one year, before tax and deductions'
          },
          financialDependants: {
            title: 'Financial dependents under the age of 18',
            type: 'string',
            enum: financialDependants.enum,
            enumNames: financialDependants.enumNames
          }
        }
      },
      bankAccountDetails: {
        title: 'Bank account details',
        type: 'object',
        required: ['name', 'sortCode', 'accountNumber'],
        properties: {
          name: {
            title: "Account holder's name",
            type: 'string',
            description: 'Joint accounts can be used'
          },
          sortCode: {
            title: 'Sort code',
            type: 'string'
          },
          accountNumber: {
            title: 'Account number',
            type: 'string'
          },
          branchName: {
            type: 'string'
          },
          bankName: {
            type: 'string'
          },
          addressLine1: {
            type: 'string'
          },
          addressLine2: {
            type: 'string'
          },
          addressLine3: {
            type: 'string'
          },
          town: {
            type: 'string'
          },
          county: {
            type: 'string'
          },
          postCode: {
            type: 'string'
          }
        }
      }
    },
    definitions: {
      addressFinder: {
        type: 'object',
        title: '',
        required: [
          'flatName',
          'houseName',
          'houseNumber',
          'street',
          'town',
          'postCode'
        ],
        properties: {
          flatName: {
            title: 'Flat name/number',
            type: 'string'
          },
          houseName: {
            title: 'House name',
            type: 'string'
          },
          houseNumber: {
            title: 'House number',
            type: 'string'
          },
          street: {
            title: 'Street',
            type: 'string'
          },
          town: {
            title: 'Town/City',
            type: 'string'
          },
          county: {
            title: 'County',
            type: 'string'
          },
          postCode: {
            title: 'Postcode',
            type: 'string'
          }
        }
      }
    }
  } as JSONSchema6);
