import { RadioGroup } from 'compass-design';
import { processSchemaValue } from 'form/helpers';
import React, { ChangeEvent } from 'react';
import { WidgetProps } from 'react-jsonschema-form';

const RadioWidget: React.FunctionComponent<WidgetProps> = ({
  id,
  options,
  schema,
  value,
  disabled,
  onChange
}) => (
  <RadioGroup
    name={id}
    options={(options.enumOptions ?? []).map(enumOption => ({
      value: String(enumOption.value),
      label: enumOption.label,
      checked: value === enumOption.value,
      inputId: `${id}_${String(enumOption.value).toLowerCase()}`
    }))}
    disabled={disabled}
    onChange={(event: ChangeEvent<HTMLInputElement>) => {
      const target = event.target as HTMLButtonElement;
      const processedValue = processSchemaValue(schema, target.value);
      if (value === processedValue) {
        return;
      }
      onChange(processedValue);
    }}
  />
);

export default RadioWidget;
