import { validateYupSchema, yupToFormErrors } from 'formik';
import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';
import { createUserSchema } from 'hitachi-retail-core/build/schemas/users/createUser';
import { PgUser } from 'hitachi-retail-core/build/services/pgUsers/pgUsersService';
import { UserRole } from 'hitachi-retail-core/build/userPolicy';
import { UserRoleLabel } from 'hitachi-retail-core/build/userPolicy/role';

interface UserData {
  retailerName: string;
  givenName: string;
  familyName: string;
  email: string;
  username: string;
  defaultBranch: string;
  enabled: boolean;
}

export interface UserApiData extends UserData {
  groups: UserRole[];
}

export interface UserFormData extends UserData {
  groups: UserRole;
}

interface GetInitialValues {
  isCreate: boolean;
  defaultRole: UserRole;
  retailer: Retailer;
  userToEdit?: PgUser;
}

export const getInitialUserValues = ({
  isCreate,
  defaultRole,
  retailer,
  userToEdit
}: GetInitialValues) => {
  if (!isCreate && userToEdit) {
    return {
      enabled: userToEdit.enabled ?? true,
      givenName: userToEdit.firstName ?? '',
      familyName: userToEdit.lastName ?? '',
      email: userToEdit.email ?? '',
      username: userToEdit.username ?? '',
      groups: userToEdit.role ?? defaultRole,
      retailerName: userToEdit.retailerName || '',
      defaultBranch: userToEdit.defaultBranch
    };
  }

  return {
    enabled: true,
    givenName: '',
    familyName: '',
    email: '',
    username: '',
    groups: defaultRole,
    retailerName: retailer.retailerNormalisedName || '',
    defaultBranch: retailer.supplierNumber
  };
};

export const validateUser = async (
  values: UserFormData,
  handleClearingNotifications?: () => void
) => {
  if (handleClearingNotifications) {
    handleClearingNotifications();
  }

  try {
    await validateYupSchema<UserApiData>(
      { ...values, groups: [values.groups] },
      createUserSchema
    );
  } catch (err) {
    return yupToFormErrors(err);
  }
  return {};
};

export const getAdvancedRadioOptionsForUser = (
  manageHeadOfficeUsers: boolean
) => {
  if (manageHeadOfficeUsers) {
    return [
      {
        id: UserRole.HeadOfficeUser,
        title: UserRoleLabel.head_office_user.label,
        text: UserRoleLabel.head_office_user.description,
        value: UserRole.HeadOfficeUser
      }
    ];
  }

  return [
    {
      id: UserRole.BranchManager,
      title: UserRoleLabel.branch_manager.label,
      text: UserRoleLabel.branch_manager.description,
      value: UserRole.BranchManager
    },
    {
      id: UserRole.BranchAdmin,
      title: UserRoleLabel.branch_admin.label,
      text: UserRoleLabel.branch_admin.description,
      value: UserRole.BranchAdmin
    },
    {
      id: UserRole.BranchUser,
      title: UserRoleLabel.branch_user.label,
      text: UserRoleLabel.branch_user.description,
      value: UserRole.BranchUser
    }
  ];
};
