import { LoanDetails } from '../getLoanDetails';
import getRadioOptions, { RadioOption } from '../getRadioOptions';
import { PbfRadioOption } from 'compass-design';
import { TailoringOption } from 'compass-design/lib/components/TailoringOptions/TailoringOptions';
import { IncompleteFinanceApplication } from 'hitachi-retail-core/build/schemas/financeApplication';
import { TailoringOptionSelected } from 'hitachi-retail-core/build/services/tailoring/types';
import getPbfRadioOptions from '../getPbfRadioOptions';

interface GetAffordabilityAccept {
  tailoringFeatureAffordabilityAccept: boolean;
  tailoringIncreaseLoanEnabled?: boolean;
  tailoringIbcIncreaseLoanEnabled?: boolean;
  tailoringReduceTermEnabled?: boolean;
  tailoringIbcReduceTermEnabled?: boolean;
  loanDetails?: LoanDetails;
  isPbfOrMo?: boolean;
}

export type AcceptOfferFunction = ({
  document,
  tailoringOptionSelected
}: {
  document: IncompleteFinanceApplication;
  tailoringOptionSelected?: TailoringOptionSelected;
}) => void;

export interface AffordabilityAcceptProps {
  loanDetails: LoanDetails;
  preSetTailoringOption: TailoringOption;
  radioOptions?: RadioOption[] | PbfRadioOption[];
  onConfirmTailoringUpdate: AcceptOfferFunction;
  onAcceptOriginalOffer: AcceptOfferFunction;
}

const getAffordabilityAccept = ({
  tailoringFeatureAffordabilityAccept,
  tailoringIncreaseLoanEnabled,
  tailoringIbcIncreaseLoanEnabled,
  tailoringReduceTermEnabled,
  tailoringIbcReduceTermEnabled,
  loanDetails,
  isPbfOrMo
}: GetAffordabilityAccept):
  | Pick<
      AffordabilityAcceptProps,
      'loanDetails' | 'preSetTailoringOption' | 'radioOptions'
    >
  | undefined => {
  const tailoringOn =
    tailoringIncreaseLoanEnabled ||
    tailoringIbcIncreaseLoanEnabled ||
    tailoringReduceTermEnabled ||
    tailoringIbcReduceTermEnabled;

  if (!tailoringFeatureAffordabilityAccept || !tailoringOn || !loanDetails) {
    return;
  }

  const tailoringOptions = [];

  if (loanDetails.increaseLoanOffer && !isPbfOrMo)
    tailoringOptions.push('increaseLoan');
  if (loanDetails.reduceTermOffer) tailoringOptions.push('reduceTerm');

  const radioOptions = !isPbfOrMo
    ? getRadioOptions({ loanDetails })
    : getPbfRadioOptions({ loanDetails });

  if (radioOptions.length === 0) {
    return;
  }

  return {
    loanDetails: loanDetails!,
    preSetTailoringOption: tailoringOptions[0] as TailoringOption,
    radioOptions
  };
};

export default getAffordabilityAccept;
