import {
  Box,
  Button,
  Input,
  InputWrapper,
  NovunaHeading,
  NovunaSingleColumnLayout,
  NovunaTextButton
} from 'compass-design';
import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';
import { UserRole } from 'hitachi-retail-core/build/userPolicy';
import React, { useState } from 'react';
import filterRetailers from 'retailerApp/utils/loanDetails/filterRetailers';
import BranchList from './BranchList';

interface BranchSelectorProps {
  branches: Retailer[];
  headOfficeRetailer: Retailer;
  selectBranch: (selectedBranch?: Retailer) => void;
  userRoles: UserRole[];
}

const BranchSelector: React.FC<BranchSelectorProps> = ({
  branches,
  headOfficeRetailer,
  selectBranch,
  userRoles
}) => {
  const [searchDescription, setSearchDescription] = useState('');
  const [filteredBranches, setFilteredBranches] = useState<false | Retailer[]>(
    false
  );
  const [selectedBranch, setSelectedBranch] = useState<Retailer | undefined>(
    undefined
  );

  const showHeadOfficeLink = !userRoles?.some(
    (role: string) =>
      role === UserRole.BrokerUser || role === UserRole.GroupUser
  );

  const onSubmit = () => {
    setSelectedBranch(undefined);
    setFilteredBranches(
      filterRetailers({
        retailers: branches,
        searchCriteria: searchDescription
      })
    );
  };

  return (
    <NovunaSingleColumnLayout>
      <Box sx={{ maxWidth: '640px' }}>
        <NovunaHeading as='h1' mb={3}>
          Select Branch
        </NovunaHeading>
        <InputWrapper
          id='query'
          label='Find a branch'
          description='Enter trading name or supplier number'>
          <Input
            id='query'
            type='text'
            value={searchDescription}
            placeholder='Enter search'
            mr={3}
            autoComplete='off'
            onChange={({ target: { value } }) => {
              setSearchDescription(value);
            }}
            onKeyDown={({ key }) => {
              if (key === 'Enter') {
                onSubmit();
              }
            }}
          />
        </InputWrapper>
        <Button
          isJumbo
          mt={4}
          disabled={searchDescription === ''}
          onClick={onSubmit}>
          Search
        </Button>
        <Box mt={5}>
          <NovunaTextButton
            data-test-id='select-head-office-button'
            mt={4}
            onClick={() => selectBranch(headOfficeRetailer)}>
            {showHeadOfficeLink &&
              `Or continue as Head Office (${headOfficeRetailer.name} ${headOfficeRetailer.supplierNumber})`}
          </NovunaTextButton>
        </Box>
        {filteredBranches && (
          <BranchList
            branches={filteredBranches}
            selectedBranch={selectedBranch}
            setSelectedBranch={setSelectedBranch}
            selectBranch={selectBranch}
          />
        )}
      </Box>
    </NovunaSingleColumnLayout>
  );
};

export default BranchSelector;
