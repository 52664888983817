import { NovunaSingleColumnLayout } from 'compass-design';
import NoMatch from 'components/meta/NoMatch';
import PreviousApplications from 'containers/search/PreviousApplications';
import React from 'react';
import { Route, Switch } from 'react-router';
import ApplicationDetail from 'retailerApp/pages/ApplicationDetail';
import { routes } from 'routes';

const Search = () => (
  <NovunaSingleColumnLayout>
    <Switch>
      <Route
        path={routes.search.applicationById}
        component={ApplicationDetail}
      />
      <Route path={routes.search.index} component={PreviousApplications} />
      <Route component={NoMatch} />
    </Switch>
  </NovunaSingleColumnLayout>
);

export default Search;
