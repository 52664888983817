import { Box, ScreenReaderLabel } from 'compass-design';
import React from 'react';
import { WidgetProps } from 'react-jsonschema-form';
import { parseIso } from 'ts-date';
import { isTwoDigitValue } from '../../../utils/dates';
import { padString } from '../../../utils/formatters';
import BaseInput from './BaseInput';

export interface DateObject {
  year: string | undefined;
  month: string | undefined;
  day: string | undefined;
}

export type DateWidgetProps = WidgetProps;

export interface DateWidgetState {
  dateValue: DateObject;
}

const datePart = {
  Day: 'day',
  Month: 'month',
  Year: 'year'
};

export const padDatePart = (value?: number | string) =>
  padString(value, 2, '0');

export const parseDateString = (dateString: string): DateObject => {
  const validDate = parseIso(dateString + 'T00:00:00.000Z');

  if (validDate !== null) {
    return {
      year: String(validDate.getUTCFullYear()),
      month: padDatePart(validDate.getUTCMonth() + 1),
      day: padDatePart(validDate.getUTCDate())
    };
  }

  return {
    year: undefined,
    month: undefined,
    day: undefined
  };
};

export const toDateString = (dateValue: DateObject): string | undefined => {
  const { year, month, day } = dateValue;

  if (
    typeof year === 'undefined' ||
    typeof month === 'undefined' ||
    typeof day === 'undefined'
  ) {
    return;
  }

  return [year, padDatePart(month), padDatePart(day)].join('-');
};

class DateWidget extends React.Component<DateWidgetProps, DateWidgetState> {
  public state = {
    dateValue: parseDateString(this.props.value)
  };

  public monthInputRef = React.createRef<HTMLInputElement>();

  public yearInputRef = React.createRef<HTMLInputElement>();

  public updateDateValue = (partName: string, partValue: string) => {
    const { dateValue } = this.state;
    return {
      ...dateValue,
      [partName]: partValue
    };
  };

  public handleValueChange = (partName: string, partValue: string) => {
    if (isTwoDigitValue(partValue)) {
      if (partName === datePart.Day && this.monthInputRef.current) {
        this.monthInputRef.current.focus();
      } else if (partName === datePart.Month && this.yearInputRef.current) {
        this.yearInputRef.current.focus();
      }
    }

    const dateValue = this.updateDateValue(partName, partValue);
    this.setState({
      dateValue
    });

    this.props.onChange(toDateString(dateValue));
  };

  public handleInputBlur = (partName: string) => {
    const partValue = this.state.dateValue[partName];
    const paddedValue = padDatePart(partValue);
    if (typeof paddedValue !== 'undefined') {
      this.setState({
        dateValue: this.updateDateValue(partName, paddedValue)
      });
    }
  };

  public render() {
    const { id, className, ...widgetProps } = this.props;
    const { dateValue } = this.state;

    return (
      <span data-test-id={id}>
        <Box mr={2} sx={{ display: 'inline-block', width: '97px' }}>
          <BaseInput
            {...widgetProps}
            key={datePart.Day}
            id={`${id}_${datePart.Day}`}
            className={className}
            maxlength={2}
            placeholder='DD'
            value={dateValue.day}
            testId={`${id}_${datePart.Day}`}
            onChange={(value: string) =>
              this.handleValueChange(datePart.Day, value)
            }
            onBlur={() => this.handleInputBlur(datePart.Day)}
          />
        </Box>
        <Box mr={2} sx={{ display: 'inline-block', width: '97px' }}>
          <ScreenReaderLabel id={`${id}_${datePart.Month}`} label='month' />
          <BaseInput
            {...widgetProps}
            key={datePart.Month}
            id={`${id}_${datePart.Month}`}
            inputRef={this.monthInputRef}
            className={className}
            maxlength={2}
            placeholder='MM'
            value={dateValue.month}
            testId={`${id}_${datePart.Month}`}
            onChange={(value: string) =>
              this.handleValueChange(datePart.Month, value)
            }
            onBlur={() => this.handleInputBlur(datePart.Month)}
          />
        </Box>
        <Box sx={{ display: 'inline-block', width: '97px' }}>
          <ScreenReaderLabel id={`${id}_${datePart.Year}`} label='year' />
          <BaseInput
            {...widgetProps}
            key={datePart.Year}
            id={`${id}_${datePart.Year}`}
            inputRef={this.yearInputRef}
            className={className}
            maxlength={4}
            placeholder='YYYY'
            value={dateValue.year}
            testId={`${id}_${datePart.Year}`}
            onChange={(value: string) =>
              this.handleValueChange(datePart.Year, value)
            }
          />
        </Box>
      </span>
    );
  }
}

export default DateWidget;
