import {
  LoanTailoring,
  TailoringOptionSelected
} from 'hitachi-retail-core/build/services/tailoring/types';
import { createAsyncAction } from 'typesafe-actions';
import * as app from './constants';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import { ProductConfig } from 'hitachi-retail-core/build/api/productConfig';

interface DecisionRequestPayload {
  id?: string;
  signUponAccept?: boolean; // Mail Order: allow to redirect automatically to signing
  softSearch?: boolean;
  tailoringOptionSelected?: TailoringOptionSelected;
  notes?: string;
  isLoanAmend?: boolean;
}

interface DecisionResponse {
  decisionResponse: ApplicationStatus;
  maxInstalmentAmount: string | null;
  softSearchExpiryDate?: string | null;
  tailoringExpiryDate?: string | null;
  loanTailoring?: LoanTailoring;
  productConfig?: ProductConfig;
}

export const fetchDecision = createAsyncAction(
  app.FETCH_DECISION_REQUEST,
  app.FETCH_DECISION_SUCCESS,
  app.FETCH_DECISION_FAILURE
)<DecisionRequestPayload | void, DecisionResponse, Error>();
