import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import * as app from './constants';
import { User } from 'hitachi-retail-core/build/services/users/usersService';
import { PgUser } from 'hitachi-retail-core/build/services/pgUsers/pgUsersService';
import { UserApiData, UserFormData } from 'retailerApp/utils/editingUsers';
import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';

export const fetchEnhancedUsersAction = createAsyncAction(
  app.FETCH_ENHANCED_USERS_REQUEST,
  app.FETCH_ENHANCED_USERS_SUCCESS,
  app.FETCH_ENHANCED_USERS_FAILURE
)<string, PgUser[], Error>();

export const createEnhancedUserAction = createAsyncAction(
  app.CREATE_ENHANCED_USER_REQUEST,
  app.CREATE_ENHANCED_USER_SUCCESS,
  app.CREATE_ENHANCED_USER_FAILURE
)<UserApiData, string, Error>();

export const selectedEnhancedUserAction = createAsyncAction(
  app.SELECTED_ENHANCED_USER_REQUEST,
  app.SELECTED_ENHANCED_USER_SUCCESS,
  app.SELECTED_ENHANCED_USER_FAILURE
)<User, UserFormData, Error>();

export const updateEnhancedUserAction = createAsyncAction(
  app.UPDATE_ENHANCED_USER_REQUEST,
  app.UPDATE_ENHANCED_USER_SUCCESS,
  app.UPDATE_ENHANCED_USER_FAILURE
)<UserApiData, string, Error>();

export const resetEnhancedUser = createStandardAction(
  app.RESET_ENHANCED_USER
)();

interface SetEditUser {
  isEditing: boolean;
  isCreate?: boolean;
}

export const setEditUser = createStandardAction(app.SET_EDIT_USER)<
  SetEditUser
>();

export const setSelectedEnhancedUser = createStandardAction(
  app.SET_SELECTED_ENHANCED_USER
)<PgUser>();

export const setSelectedEnhancedBroker = createStandardAction(
  app.SET_SELECTED_ENHANCED_BROKER
)<Retailer | undefined>();

export const setSelectedEnhancedHeadOffice = createStandardAction(
  app.SET_SELECTED_ENHANCED_HEAD_OFFICE
)<Retailer | undefined>();

export const setSelectedEnhancedBranch = createStandardAction(
  app.SET_SELECTED_ENHANCED_BRANCH
)<Retailer | undefined>();

export const setDisableViewBranchWarning = createStandardAction(
  app.SET_DISABLE_VIEW_BRANCH_WARNING
)<boolean>();

export const setBranchSelectorModalIsOpen = createStandardAction(
  app.SET_BRANCH_SELECTOR_MODAL_IS_OPEN
)<boolean>();

export const setBranchSelectorSearchTerm = createStandardAction(
  app.SET_BRANCH_SELECTOR_SEARCH_TERM
)<string>();

export const setShowBranchSelectorPage = createStandardAction(
  app.SET_SHOW_BRANCH_SELECTION_PAGE
)<boolean>();
