import { ReferralNote } from 'hitachi-retail-core/build/schemas/referralNote';
import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { getType, isOfType } from 'typesafe-actions';
import {
  ApplicationsService,
  getApplicationsService
} from '../../services/applicationsService';
import {
  fetchApplicationReferralNotes,
  submitApplicationReferralNote
} from '../../store/previousApplicationDetail/actions';
import {
  FetchApplicationReferralNotesAction,
  SubmitApplicationReferralNoteAction
} from '../../store/previousApplicationDetail/reducer';
import { NotificationType, showNotification } from '../../store/ui/actions';
import { DefaultSagaParams } from '../index';

interface ApplicationReferralNotesSagaProps {
  applicationsService: ApplicationsService;
}

export const getApplicationReferralNotesSaga = ({
  applicationsService
}: ApplicationReferralNotesSagaProps) =>
  function*(action: FetchApplicationReferralNotesAction) {
    if (isOfType(getType(fetchApplicationReferralNotes.request), action)) {
      const { id } = action.payload;

      try {
        const response: ReferralNote[] = yield call(
          applicationsService.getReferralNotes,
          id
        );

        yield put(fetchApplicationReferralNotes.success(response));
      } catch (err) {
        yield put(fetchApplicationReferralNotes.failure(err));
        yield put(
          showNotification({
            message: err.message,
            type: NotificationType.DANGER
          })
        );
      }
    }
  };

export const getSubmitApplicationReferralNoteSaga = ({
  applicationsService
}: ApplicationReferralNotesSagaProps) =>
  function*(action: SubmitApplicationReferralNoteAction) {
    if (isOfType(getType(submitApplicationReferralNote.request), action)) {
      const { id, note } = action.payload;

      try {
        yield call(applicationsService.submitReferralNote, { id, note });

        yield put(submitApplicationReferralNote.success());

        // Refresh referral notes
        yield put(fetchApplicationReferralNotes.request({ id }));
      } catch (err) {
        yield put(submitApplicationReferralNote.failure(err));
      }
    }
  };

export const getApplicationReferralNotesSagaWatcher = ({
  apiClient
}: DefaultSagaParams) =>
  function*() {
    const applicationsService = getApplicationsService({ apiClient });

    yield takeEvery(
      getType(fetchApplicationReferralNotes.request),
      getApplicationReferralNotesSaga({ applicationsService })
    );

    yield takeLeading(
      getType(submitApplicationReferralNote.request),
      getSubmitApplicationReferralNoteSaga({ applicationsService })
    );
  };
