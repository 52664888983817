import React from 'react';
import {
  Box,
  InputWrapper,
  TextField,
  Button,
  Text,
  RadioGroup,
  InlineCheckbox,
  ReadOnlyWrapper,
  Flex
} from 'compass-design';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import { labelPropsFromMeta } from 'utils/labelPropsFromMeta';
import { UserRole } from 'hitachi-retail-core/build/userPolicy';
import { UserRoleLabel } from 'hitachi-retail-core/build/userPolicy/role';
import {
  UserApiData,
  UserFormData,
  validateUser
} from 'retailerApp/utils/editingUsers';

const radioOptionLabel = (type: string, description: string) => (
  <Text>
    {type}
    <Text sx={{ fontSize: 0, maxWidth: '250px', width: '100%' }}>
      {description}
    </Text>
  </Text>
);

const getRadioOptions = (manageHeadOfficeUsers: boolean) => {
  if (manageHeadOfficeUsers) {
    return [
      {
        label: radioOptionLabel(
          UserRoleLabel.head_office_user.label,
          UserRoleLabel.head_office_user.description
        ),
        value: UserRole.HeadOfficeUser
      }
    ];
  }

  return [
    {
      label: radioOptionLabel(
        UserRoleLabel.branch_user.label,
        UserRoleLabel.branch_user.description
      ),
      value: UserRole.BranchUser
    },
    {
      label: radioOptionLabel(
        UserRoleLabel.branch_admin.label,
        UserRoleLabel.branch_admin.description
      ),
      value: UserRole.BranchAdmin
    },
    {
      label: radioOptionLabel(
        UserRoleLabel.branch_manager.label,
        UserRoleLabel.branch_manager.description
      ),
      value: UserRole.BranchManager
    }
  ];
};

export interface UserFormProps {
  isCreate: boolean;
  retailer: {
    branchId: string;
    name: string;
    normalisedName?: string;
  };
  initialValues: UserFormData;
  trimmedUsername?: string;
  manageHeadOfficeUsers: boolean;
  loading: boolean;
  handleClearingNotifications: () => void;
  handleOnSubmit: (values: UserApiData) => void;
}

const UserForm = ({
  isCreate,
  retailer,
  initialValues,
  trimmedUsername,
  manageHeadOfficeUsers,
  loading,
  handleOnSubmit,
  handleClearingNotifications
}: UserFormProps): JSX.Element => (
  <Box mt={5}>
    <Formik
      initialValues={{ ...initialValues, isCreate }}
      enableReinitialize
      onSubmit={({ isCreate, ...values }) => {
        handleOnSubmit({
          ...values,
          retailerName: retailer.normalisedName || '',
          defaultBranch: retailer.branchId,
          groups: [values.groups]
        });
      }}
      validate={values =>
        validateUser(
          {
            ...values,
            retailerName: retailer.normalisedName || '',
            defaultBranch: retailer.branchId
          } as any,
          handleClearingNotifications
        )
      }>
      {(formikBag: FormikProps<UserFormData>) => (
        <Form key='edit-user' name='edit-user'>
          {!isCreate && (
            <Field name='enabled' type='checkbox'>
              {({ field, meta }: FieldProps<string, UserFormData>) => (
                <Box mt={5}>
                  <InputWrapper
                    id='enabled'
                    label='Account active'
                    error={meta.error}
                    {...labelPropsFromMeta({ meta }, 'label')}>
                    <InlineCheckbox
                      {...labelPropsFromMeta({ meta }, 'input')}
                      {...field}
                      id='enabled'
                    />
                  </InputWrapper>
                </Box>
              )}
            </Field>
          )}
          <Box mt={5}>
            <ReadOnlyWrapper label='Retailer name'>
              <Flex data-test-id='retailer-name'>
                <Box
                  sx={{
                    flex: '0 1 auto',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>
                  {retailer.name}
                </Box>
                <Box pl={1} sx={{ flex: '0 0 auto' }}>
                  ({retailer.branchId})
                </Box>
              </Flex>
            </ReadOnlyWrapper>
          </Box>
          <Field name='givenName'>
            {({ field, meta }: FieldProps<string, UserFormData>) => (
              <Box mt={5}>
                <InputWrapper
                  id='givenName'
                  label='First name'
                  error={meta.error}
                  {...labelPropsFromMeta({ meta }, 'label')}>
                  <TextField
                    {...labelPropsFromMeta({ meta }, 'input')}
                    type='text'
                    {...field}
                    id='givenName'
                  />
                </InputWrapper>
              </Box>
            )}
          </Field>
          <Field name='familyName'>
            {({ field, meta }: FieldProps<string, UserFormData>) => (
              <Box mt={5}>
                <InputWrapper
                  id='familyName'
                  label='Last name'
                  error={meta.error}
                  {...labelPropsFromMeta({ meta }, 'label')}>
                  <TextField
                    {...labelPropsFromMeta({ meta }, 'input')}
                    type='text'
                    {...field}
                    id='familyName'
                  />
                </InputWrapper>
              </Box>
            )}
          </Field>
          <Field name='email'>
            {({ field, meta }: FieldProps<string, UserFormData>) => (
              <Box mt={5}>
                <InputWrapper
                  id='email'
                  label='Email'
                  error={meta.error}
                  {...labelPropsFromMeta({ meta }, 'label')}>
                  <TextField
                    {...labelPropsFromMeta({ meta }, 'input')}
                    type='text'
                    {...field}
                    id='email'
                  />
                </InputWrapper>
              </Box>
            )}
          </Field>
          <Field name='username'>
            {({ field, meta }: FieldProps<string, UserFormData>) => (
              <Box mt={5}>
                <Box mt={5}>
                  {!isCreate && (
                    <ReadOnlyWrapper label='Username'>
                      {trimmedUsername ?? field.value}
                    </ReadOnlyWrapper>
                  )}

                  {isCreate && (
                    <InputWrapper
                      id='username'
                      label='Username'
                      error={meta.error}
                      {...labelPropsFromMeta({ meta }, 'label')}>
                      <TextField
                        {...labelPropsFromMeta({ meta }, 'input')}
                        type='text'
                        {...field}
                        id='username'
                      />
                    </InputWrapper>
                  )}
                </Box>
              </Box>
            )}
          </Field>
          <Field name='groups'>
            {({ field, meta }: FieldProps<string, UserFormData>) => (
              <Box mt={5}>
                <InputWrapper
                  id='groups'
                  label='Role'
                  error={meta.error}
                  {...labelPropsFromMeta({ meta }, 'label')}>
                  <RadioGroup
                    name='groups'
                    options={getRadioOptions(manageHeadOfficeUsers).map(
                      ({ label, value }) => ({
                        labelSx: { alignItems: 'normal' },
                        label,
                        checked: value === field.value,
                        value
                      })
                    )}
                    onChange={formikBag.handleChange}
                  />
                </InputWrapper>
              </Box>
            )}
          </Field>

          <Button
            isJumbo
            type='submit'
            mt={6}
            data-test-id='continue-button'
            disabled={loading}>
            {isCreate ? 'Create' : 'Update'}
          </Button>
        </Form>
      )}
    </Formik>
  </Box>
);

export default UserForm;
