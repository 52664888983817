import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import { FeatureConfig } from 'hitachi-retail-core/build/features/features';
import { IncompleteFinanceApplication } from 'hitachi-retail-core/build/schemas/financeApplication';
import { connect } from 'react-redux';
import Progress, {
  ProgressPropsFromState
} from '../../components/progress/Progress';
import { getPageDetails, getPageRouteIndex } from '../../config/pageTitles';
import { RoutePredicate, routes } from '../../routes';
import { RootState } from '../../store';

interface ProgressProps {
  path: string;
}

export interface ShouldEnableSoftSearch {
  softSearchEnabledForRetailer?: boolean;
  decisionApplicationStatus: ApplicationStatus;
}

export const shouldShowApplicationCheck = ({
  softSearchEnabledForRetailer,
  decisionApplicationStatus
}: ShouldEnableSoftSearch): boolean => {
  const statuses = [
    ApplicationStatus.decision_quotation_accept,
    ApplicationStatus.decision_quotation_decline,
    ApplicationStatus.decision_quotation_refer
  ];

  if (softSearchEnabledForRetailer) {
    statuses.push(ApplicationStatus.open);
  }

  return statuses.includes(decisionApplicationStatus);
};

const shouldExcludeRoute = ({
  route,
  isRegulated,
  softSearchEnabled,
  isLoanAmendFlow
}: {
  route: string;
  isRegulated: boolean;
  softSearchEnabled?: boolean;
  isLoanAmendFlow?: boolean;
}) => {
  const discardRoute = false;

  switch (route) {
    case routes.apply.softSearch:
      return !softSearchEnabled;
    case routes.apply.preContractCreditInformation:
      return !isRegulated || discardRoute;
    case routes.apply.loanAdvanceInformation:
      return isRegulated || discardRoute;
    case routes.apply.quote:
    case routes.apply.privacyPolicy:
    case routes.apply.details:
      return isLoanAmendFlow || discardRoute;
    case routes.apply.loanAmend:
      return !isLoanAmendFlow || discardRoute;
    default:
      return discardRoute;
  }
};

export const getProgressPage = (
  path: string,
  enabledFeatures: FeatureConfig,
  document: IncompleteFinanceApplication,
  status: ApplicationStatus,
  softSearchEnabledForRetailer?: boolean,
  isLoanAmend?: boolean
) => {
  const { product } = document;
  const isRegulated = product?.regulated === true;
  const isLoanAmendFlow = isLoanAmend;
  const softSearchEnabled = shouldShowApplicationCheck({
    softSearchEnabledForRetailer,
    decisionApplicationStatus: status
  });

  const excludedRoutePredicate: RoutePredicate = route =>
    shouldExcludeRoute({
      route,
      isRegulated,
      softSearchEnabled,
      isLoanAmendFlow
    });

  const pageIndex = getPageRouteIndex(
    path,
    enabledFeatures,
    excludedRoutePredicate
  );

  return {
    excludedRoutePredicate,
    pageIndex
  };
};

export const mapStateToProps = (
  {
    application: {
      activeApplication: { document, status }
    },
    config: { enabledFeatures },
    retailer: {
      retailer: { softSearchEnabled: softSearchEnabledForRetailer }
    },
    loanAmend: { isLoanAmend }
  }: RootState,
  { path }: ProgressProps
): ProgressPropsFromState => {
  const { excludedRoutePredicate, pageIndex } = getProgressPage(
    path,
    enabledFeatures,
    document,
    status,
    softSearchEnabledForRetailer,
    isLoanAmend
  );

  return {
    pageConfig: getPageDetails(enabledFeatures, excludedRoutePredicate),
    page: pageIndex
  };
};

export default connect(mapStateToProps)(Progress);
