import { Button, NovunaHeading, NovunaSpinner, Text } from 'compass-design';
import PageBlock from 'components/PageBlock';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { createNewApplication } from 'store/application/actions';
import { signApplication } from 'store/sign/actions';
import { State as SignState } from '../../../store/sign/reducer';

export interface SigningHandoffProps {
  canStartSigning?: () => boolean;
}

const SigningHandoff: React.FunctionComponent<SigningHandoffProps> = ({
  canStartSigning
}) => {
  const dispatch = useDispatch();

  const { processing, errorMessage } = useSelector<SignState>(
    state => state.sign
  );
  const signingError = Boolean(errorMessage);

  return (
    <>
      {processing || signingError ? (
        <PageBlock>
          {processing && (
            <div data-test-id='compass-application-signing-pending'>
              <NovunaHeading as='h2' mb={2}>
                Preparing signing&hellip;
              </NovunaHeading>
              <NovunaSpinner />
            </div>
          )}
          {signingError && (
            <div data-test-id='compass-application-signing-error'>
              <NovunaHeading as='h2' mb={2}>
                Something went wrong
              </NovunaHeading>
              <Text mb={5}>
                We were unable to connect to the application signing service,
                please try again or alternatively contact the Retail Helpline on
                0344 375 5515.
              </Text>
              <Button
                mr={4}
                isJumbo
                onClick={() => dispatch(signApplication.request())}
                data-test-id='compass-application-signing-retry'>
                Try again
              </Button>
              <Button
                isJumbo
                variant='secondaryDestructive'
                onClick={() => dispatch(createNewApplication())}
                data-test-id='compass-application-signing-abort'>
                Exit application
              </Button>
            </div>
          )}
        </PageBlock>
      ) : (
        <Button
          isJumbo
          data-test-id='compass-application-signing-start'
          onClick={() => {
            let startSigning = true;
            if (canStartSigning) {
              startSigning = canStartSigning();
            }

            if (startSigning) {
              dispatch(signApplication.request());
            }
          }}>
          Proceed with e-Signature
        </Button>
      )}
    </>
  );
};

export default SigningHandoff;
