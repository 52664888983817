import React from 'react';
import { Box, Text } from 'compass-design';
import { useSelector } from 'react-redux';
import {
  selectSelectedEnhancedBranch,
  selectSelectedEnhancedBroker,
  selectSelectedEnhancedHeadOffice
} from 'store/enhancedUsers/selectors';
import { selectUsername } from 'store/user/reducer';

const HeaderBanner = () => {
  const username = useSelector(selectUsername);
  const selectedBroker = useSelector(selectSelectedEnhancedBroker);
  const selectedHeadOffice = useSelector(selectSelectedEnhancedHeadOffice);
  const selectedBranch = useSelector(selectSelectedEnhancedBranch);

  let headRetailer;

  if (selectedBroker) {
    headRetailer = selectedBroker;
  } else if (selectedHeadOffice) {
    headRetailer = selectedHeadOffice;
  }

  let editingBranch;

  if (selectedBranch) {
    editingBranch = selectedBranch;
  } else if (selectedHeadOffice) {
    editingBranch = selectedHeadOffice;
  }

  if (!headRetailer || !editingBranch) {
    return <></>;
  }

  return (
    <Box sx={{ backgroundColor: '#a4beea', textAlign: 'center' }}>
      <Box sx={{ maxWidth: '1236px' }} px={[3, null, 6]} mx='auto'>
        <Text
          py={
            2
          }>{`${username} of ${headRetailer.name} managing users for ${editingBranch.name}`}</Text>
      </Box>
    </Box>
  );
};

export default HeaderBanner;
