import {
  Box,
  NovunaResponsiveContainer,
  TertiaryLink,
  TertiaryLinkDirection,
  Text
} from 'compass-design';
import { push } from 'connected-react-router';
import { FormikHelpers, FormikProps } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { generateApplicationPath, routes } from 'routes';
import {
  selectApplicationReturnUrl,
  selectApplicationSupplierOrderReference
} from 'store/application/selectors';
import { selectCurrentStepIndex } from 'applicantApp/store/changeFormStep/reducer';
import { DetailsCaptureValues } from '../schema';
import cloneDeep from 'lodash/cloneDeep';
import { AddressWithDate, normalisePostcode } from 'hitachi-retail-core';
import { DateTime } from 'luxon';
import { emptyAddress } from '../../index';
import PageTitle from 'applicantApp/components/PageTitle';
import NovunaPageLayout from 'applicantApp/components/NovunaPageLayout';
import { bankAccountCheck } from 'store/application/actions';

interface StepMetadata {
  title?: string;
  description?: string | React.FC;
}

export interface Step<T> extends StepMetadata {
  stepIndex?: number;
  component: any;
  shouldDisplay?: (values: T) => boolean;
  next?: Step<T>;
  previous?: Step<T>;
  addressIndex?: number;
}

export interface StepComponentProps<T> {
  initialValues: DetailsCaptureValues;
  applicationId: string;
  returnUrl?: string;
  supplierOrderReference?: string;
  addressIndex?: number;
  multiStepSubmit: (values: T, formikBag: FormikHelpers<T>) => void;
}

const getSteps = <T,>(steps: Step<T>[]): Step<T>[] => {
  // Convert to a doubly-linked list. Set the previous based on the next for all steps.
  for (let i = 0; i < steps.length; i++) {
    const current = steps[i];

    const previous = i === 0 ? undefined : steps[i - 1];
    const next = i === steps.length - 1 ? undefined : steps[i + 1];

    current.stepIndex = i;
    current.previous = previous;
    current.next = next;
  }

  return steps;
};

export interface MultiStepFormProps<T> {
  steps: Step<T>[];
  initialValues: T;
  onSave: (formData: DetailsCaptureValues) => void;
  onFormComplete: () => void;
  gotoFormStep: (stepIndex: number) => void;
  applicationId: string;
}

export const lessThan3Years = ({
  fromMonth,
  fromYear
}: AddressWithDate): boolean => {
  const fromDate = DateTime.fromObject({ month: fromMonth, year: fromYear });

  return (
    DateTime.local()
      .diff(fromDate)
      .as('years') < 3
  );
};

const cleanUpAddress = (address: AddressWithDate): AddressWithDate => {
  // Postcode must be correctly formatted to avoid validation error at decision
  const formattedPostcode = normalisePostcode(address.address.postCode);

  const output = {
    ...address,
    address: {
      ...address.address,
      postCode: formattedPostcode
    }
  };

  ['town', 'houseNumber', 'houseName', 'county', 'street', 'flatName'].forEach(
    a => {
      if (
        (output.address[a] && !output.address?.[a].replace(/\s/g, '').length) ||
        output.address?.[a] === ''
      ) {
        delete output.address[a];
      }
    }
  );

  return output;
};

export const cleanUpFormValues = (values: DetailsCaptureValues) => {
  const valuesCloned: DetailsCaptureValues = cloneDeep(values);
  const { financialDetails, mainAddressDetails } = values || {};

  // Strip out empty spouse employment status, as this causes errors later when submitting to decision
  if (financialDetails?.spouseEmploymentStatus === '') {
    valuesCloned.financialDetails.spouseEmploymentStatus = undefined;
  }

  if (mainAddressDetails && mainAddressDetails.mainAddress.length > 0) {
    // Reset the list of addresses and always include the main address
    valuesCloned.mainAddressDetails.mainAddress = [
      cleanUpAddress(mainAddressDetails.mainAddress[0])
    ];

    for (let i = 1; i <= mainAddressDetails.mainAddress.length; i++) {
      const address = mainAddressDetails.mainAddress[i];
      const previousValidAddress =
        valuesCloned.mainAddressDetails.mainAddress[i - 1];

      if (
        // Using postcode as a proxy for address existing as postcode is always going to be present in an address
        !previousValidAddress?.address?.postCode ||
        !address?.address?.postCode
      ) {
        continue;
      }
      // Include the address only if the previous one doesn't meet the 3 years occupancy history criteria
      if (lessThan3Years(previousValidAddress)) {
        valuesCloned.mainAddressDetails.mainAddress.push(
          cleanUpAddress(address)
        );
      }
    }
  }
  return valuesCloned;
};

export const cleanUpPreLastStep = (values: DetailsCaptureValues) => {
  // Leave only the addresses up to the required 3 years of occupancy history
  const { mainAddressDetails } = values;
  const clonedValues = cleanUpFormValues(values);

  // Ensure three addresses are present to enable correct validation on previous address details entry
  if (mainAddressDetails && mainAddressDetails.mainAddress.length > 0) {
    while (clonedValues.mainAddressDetails.mainAddress.length < 3) {
      clonedValues.mainAddressDetails.mainAddress.push(emptyAddress());
    }
  }
  return clonedValues;
};

// Based on https://github.com/jaredpalmer/formik/issues/867#issuecomment-490554601
export const MultiStepForm = <T,>({
  steps: baseSteps,
  initialValues,
  onFormComplete,
  onSave,
  gotoFormStep,
  applicationId
}: MultiStepFormProps<T>) => {
  const steps = getSteps(baseSteps);
  const currentStepIndex = useSelector(selectCurrentStepIndex);
  const returnUrl = useSelector(selectApplicationReturnUrl);
  const supplierOrderReference = useSelector(
    selectApplicationSupplierOrderReference
  );

  const dispatch = useDispatch();

  if (steps.length === 0) {
    return <>No form steps found</>;
  }

  const currentStep = steps[currentStepIndex];
  const {
    component: CurrentStep,
    title,
    description,
    addressIndex
  } = currentStep;
  const isLastStep = () => !currentStep.next;

  const handleNext = (current: Step<T>, values: T) => {
    const { next } = current;
    if (next) {
      if (
        next.shouldDisplay === undefined ||
        (next.shouldDisplay && next.shouldDisplay(values))
      ) {
        gotoFormStep(next.stepIndex || 0);
      } else {
        handleNext(next, values);
      }
    }
  };

  const getPrevious = (current: Step<T>, values: T): Step<T> | undefined => {
    const { previous } = current;
    if (!previous) {
      return;
    }
    if (
      previous.shouldDisplay === undefined ||
      (previous.shouldDisplay && previous.shouldDisplay(values))
    ) {
      return previous;
    } else {
      return getPrevious(previous, values);
    }
  };

  const handleSubmit = (values: T, formikBag: FormikProps<T>) => {
    const { setSubmitting } = formikBag;
    setSubmitting(false);

    if (!isLastStep()) {
      const cleanedUpValues = cleanUpPreLastStep(
        (values as any) as DetailsCaptureValues
      );
      onSave(cleanedUpValues);
      // It is critical for 'address journey' to pass values after the business logic is applied
      handleNext(currentStep, (cleanedUpValues as any) as T);
      return;
    } else {
      // Perform a bank check that saves the response
      const finalCleanedValues = cleanUpFormValues(
        (values as any) as DetailsCaptureValues
      );
      onSave(finalCleanedValues);
      dispatch(bankAccountCheck.request());
    }

    const finalCleanedValues = cleanUpFormValues(
      (values as any) as DetailsCaptureValues
    );
    onSave(finalCleanedValues);
    onFormComplete();
  };

  const Description = () => {
    const spacing = { mb: 4 };

    if (typeof description === 'function') {
      const DescriptionFC = description;

      return (
        <Box sx={{ ...spacing }}>
          <DescriptionFC />
        </Box>
      );
    }
    return <Text sx={{ ...spacing, fontSize: 2 }}>{description}</Text>;
  };

  const backLink = () => {
    const previous = getPrevious(currentStep, initialValues);
    if (previous) {
      const onClick = () => {
        gotoFormStep(previous.stepIndex || 0);
      };

      return (
        <TertiaryLink
          data-test-id='pbf-previous-step'
          direction={TertiaryLinkDirection.BACKWARDS}
          text={previous.title ?? 'Previous'}
          onClick={onClick}
        />
      );
    } else {
      const onClick = () => {
        dispatch(
          push(
            generateApplicationPath(routes.mailOrder.application.quote, {
              id: applicationId
            })
          )
        );
      };

      return (
        <TertiaryLink
          data-test-id='pbf-previous-step'
          direction={TertiaryLinkDirection.BACKWARDS}
          text='Quote Summary'
          onClick={onClick}
        />
      );
    }
  };

  return (
    <Box mt={3}>
      <NovunaResponsiveContainer>{}</NovunaResponsiveContainer>

      <NovunaPageLayout pageTop={backLink()} backToStoreStatus='abandoned'>
        <PageTitle
          dataTestId={(title || '').replace(' ', '') + '-page-heading'}>
          {title}
        </PageTitle>
        <Description />
        <CurrentStep
          initialValues={initialValues as any}
          returnUrl={returnUrl}
          supplierOrderReference={supplierOrderReference}
          applicationId={applicationId}
          multiStepSubmit={handleSubmit}
          addressIndex={addressIndex}
        />
      </NovunaPageLayout>
    </Box>
  );
};
