import { JSONSchema6 } from 'json-schema';
import { resolveSchema } from 'react-jsonschema-form/lib/utils';

export const getSchemaSectionProperties = <T extends object>(
  sectionKey: string,
  schema: JSONSchema6,
  formData?: T
): JSONSchema6 | undefined => {
  if (!schema.properties) {
    return;
  }

  const sectionSchema = schema.properties[sectionKey] as JSONSchema6;
  const sectionData = formData && formData[sectionKey];
  const resolvedSchema = resolveSchema(
    sectionSchema,
    schema.definitions,
    sectionData
  );
  return resolvedSchema.properties as JSONSchema6;
};

export const getSchemaDisplayValue = <T extends object>(
  propertyKey: string,
  sectionKey: string,
  schema: JSONSchema6,
  formData: T
): string => {
  let displayValue = '';

  const schemaSectionProperties = getSchemaSectionProperties(
    sectionKey,
    schema,
    formData
  );

  let propertyFormData;
  let propertySchemaType;
  let resolvedPropertySchema;
  // We have nested properties within a top-level schema section
  if (schemaSectionProperties) {
    const propertySchema =
      schemaSectionProperties && schemaSectionProperties[propertyKey];
    propertySchemaType = propertySchema && (propertySchema.type as string);
    propertyFormData = getSchemaPropertyFormData(
      propertyKey,
      sectionKey,
      formData
    );
    resolvedPropertySchema =
      propertySchema &&
      resolveSchema(propertySchema, schema.definitions, propertyFormData);
  }
  // We may have a top-level property
  else if (schema.properties) {
    const propertySchema = schema.properties[sectionKey] as JSONSchema6;
    propertySchemaType = propertySchema && (propertySchema.type as string);
    propertyFormData = formData[propertyKey];
    resolvedPropertySchema =
      propertySchema &&
      resolveSchema(propertySchema, schema.definitions, propertyFormData);
  }

  if (!(propertyFormData === undefined || propertyFormData === null)) {
    switch (propertySchemaType) {
      case 'boolean':
      case 'number':
      case 'string':
        displayValue = String(propertyFormData);
        if (resolvedPropertySchema && resolvedPropertySchema.enum) {
          displayValue = getSchemaEnumDisplayValue(
            displayValue,
            resolvedPropertySchema
          );
        }
        break;
      default:
        break;
    }
  }
  return displayValue;
};

export const getSchemaPropertyFormData = <T extends object>(
  propertyKey: string,
  sectionKey: string,
  formData: T
) => {
  const sectionData = formData[sectionKey];
  return sectionData && sectionData[propertyKey];
};

export const getSchemaEnumDisplayValue = (
  enumValue: string,
  propertySchema: JSONSchema6
): string => {
  if (!propertySchema.enum) {
    return enumValue;
  }

  const enumIndex = propertySchema.enum.indexOf(
    // Need to convert to boolean to find in array of booleans
    propertySchema.type === 'boolean' ? enumValue === 'true' : enumValue
  );
  const enumNames = (propertySchema as any).enumNames;

  if (typeof enumIndex !== 'undefined' && enumNames && enumNames[enumIndex]) {
    return enumNames[enumIndex];
  }
  return enumValue;
};

/**
 * Deletes any section form data properties that are not present in the resolved schema.
 *
 * @param sectionKey The key of the form data section and schema section
 * @param formData Form data values
 * @param schema JSON schema containing section properties
 */
export const deleteObsoleteFormData = <T extends object>(
  sectionKey: string,
  formData: T,
  schema: JSONSchema6
): void => {
  if (sectionKey in formData) {
    const schemaSectionProperties =
      getSchemaSectionProperties(sectionKey, schema, formData) || {};
    Object.keys(formData[sectionKey]).forEach(formDataKey => {
      if (!(formDataKey in schemaSectionProperties)) {
        delete formData[sectionKey][formDataKey];
      }
    });
  }
};
