import { Decimal } from 'hitachi-retail-core/build/utils/decimal';
import { ProductConfig } from 'hitachi-retail-core/build/api/productConfig';
import React from 'react';
import { useSelector } from 'react-redux';
import AmendLoan from 'retailerApp/components/AmendLoan';
import { getLoanDetails } from 'retailerApp/utils/loanDetails/getLoanDetails';
import { RootState } from 'store';
import { selectFinancialProduct } from 'hitachi-retail-core/build/selectors';
import { getMaxAllowedLoanAmount } from 'hitachi-retail-core/build/finance/tailoring/getIncreaseLoanDetails/getIncreaseLoanDetails';
import { Text } from 'compass-design';
import { DecisionRevision } from 'hitachi-retail-core';
import { getOrderedRevisions } from 'hitachi-retail-core/build/utils/applicant';

const selectEnabledFeatures = (state: RootState) =>
  state.config.enabledFeatures;

const selectApplication = (state: RootState) => state.application;

const selectApplicationRevisions = (state: RootState) =>
  state.previousApplicationDetail.previousApplication?.revisions;

const selectProductConfig = (state: RootState) =>
  state.application.activeApplication.document.product?.productConfig;

const getOriginalLoanFromRevisions = (
  revisions: DecisionRevision[]
): DecisionRevision => {
  const orderedRevisions = getOrderedRevisions(revisions);
  const originalLoanFromRevisions = orderedRevisions[0];

  return originalLoanFromRevisions;
};

const ApplicationAmend = () => {
  const enabledFeatures = useSelector(selectEnabledFeatures);
  const application = useSelector(selectApplication);
  const productConfig = useSelector(selectProductConfig);
  const productType = selectFinancialProduct(
    application.activeApplication.document
  );
  const revisions = useSelector(selectApplicationRevisions);
  const maxInstalmentAmount = application.activeApplication.maxInstalmentAmount;

  const { document } = application.activeApplication;

  const loanDetails = getLoanDetails({
    document: document,
    isSoftSearch: false,
    enabledFeatures
  });

  if (
    !loanDetails?.originalOffer ||
    !application ||
    !productConfig ||
    !maxInstalmentAmount ||
    !productType ||
    !revisions
  ) {
    return <Text>Something has gone wrong</Text>;
  }

  const originalLoanFromRevisions = getOriginalLoanFromRevisions(revisions);
  const orderedRevisions = getOrderedRevisions(revisions);
  const currentLoanAmountFromRevisions = new Decimal(
    orderedRevisions[orderedRevisions.length - 1].loanAmount
  );

  const getMaxAllowedLoanAmountProps = {
    maxLoanAmountForProduct: productConfig.maxLoanAmount as Decimal,
    previousSelectedLoanAmount: new Decimal(
      originalLoanFromRevisions.loanAmount
    ),
    currentLoanAmountFromRevisions,
    maxInstalmentAmount,
    term: loanDetails?.originalOffer?.repaidOver as number,
    deferralPeriod: document.loanRepayment?.deferralPeriod,
    productConfig: productConfig as ProductConfig,
    productType,
    roundDownToTheNearestTen: false
  };

  const maximumLoanAmount = getMaxAllowedLoanAmount(
    getMaxAllowedLoanAmountProps
  );

  const loanParameters = {
    ...loanDetails.loanParameters,
    maximumGoodsAmount: new Decimal(maximumLoanAmount).plus(
      loanDetails?.originalOffer?.deposit
    ),
    maximumLoanAmount,
    originalLoanAmount: new Decimal(originalLoanFromRevisions.loanAmount),
    originalDeposit: new Decimal(originalLoanFromRevisions.deposit)
  };

  return (
    <>
      <AmendLoan
        loanDetails={loanDetails}
        productConfig={productConfig as ProductConfig}
        loanParameters={loanParameters}
      />
    </>
  );
};

export default ApplicationAmend;
