import { call, put, takeEvery } from 'redux-saga/effects';
import { getType, isOfType } from 'typesafe-actions';
import { ApplicationsService } from '../../services/applicationsService';
import {
  fetchActiveApplication,
  PreviousApplicationDetail
} from '../../store/previousApplicationDetail/actions';
import { FetchActiveApplicationAction } from '../../store/save/reducer';
import { NotificationType, showNotification } from '../../store/ui/actions';

interface GetFetchActiveApplicationSaga {
  applicationsService: Pick<ApplicationsService, 'getById'>;
}

export const getActiveApplicationSaga = ({
  applicationsService
}: GetFetchActiveApplicationSaga) =>
  function*(action: FetchActiveApplicationAction) {
    if (isOfType(getType(fetchActiveApplication.request), action)) {
      const { id } = action.payload;
      try {
        const response: PreviousApplicationDetail = yield call(
          applicationsService.getById,
          { id, isMoPbf: false }
        );
        yield put(fetchActiveApplication.success(response));
      } catch (err) {
        yield put(fetchActiveApplication.failure(err));
        yield put(
          showNotification({
            message: err.message,
            type: NotificationType.DANGER
          })
        );
      }
    }
  };

export const getActiveApplicationSagaWatcher = ({
  applicationsService
}: GetFetchActiveApplicationSaga) =>
  function*() {
    yield takeEvery(
      getType(fetchActiveApplication.request),
      getActiveApplicationSaga({ applicationsService })
    );
  };
