import { Formik, Form, Field, FieldProps } from 'formik';
import { validationMessages } from 'hitachi-retail-core/build/application/messages';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import * as yup from 'yup';
import {
  Box,
  Button,
  NovunaHeading,
  Message,
  Text,
  InputWrapper,
  TextField,
  TertiaryLink,
  TertiaryLinkDirection
} from 'compass-design';
import { LoggedOutWrapper } from 'retailerApp/components/LoggedOutWrapper';
import { routes } from 'routes';
import { useHistory } from 'react-router';

export interface ResetPasswordFormValues {
  retailerName: string;
  username: string;
}

export interface ResetPasswordFormPropsFromState {
  retailerName: string;
  errorMessage?: string;
  processing: boolean;
  success: boolean;
}

export interface ResetPasswordFormPropsFromDispatch {
  onSubmit: (values: ResetPasswordFormValues) => void;
}

export type ResetPasswordFormProps = ResetPasswordFormPropsFromState &
  ResetPasswordFormPropsFromDispatch;

export const ResetPasswordForm: React.FunctionComponent<ResetPasswordFormProps> = ({
  retailerName,
  processing,
  success,
  errorMessage,
  onSubmit
}) => {
  const history = useHistory();

  const initialValues: ResetPasswordFormValues = {
    retailerName,
    username: ''
  };

  return (
    <>
      <Helmet>
        <title>Reset Password - CreditMaster3</title>
      </Helmet>

      <LoggedOutWrapper
        backLink={
          <TertiaryLink
            direction={TertiaryLinkDirection.BACKWARDS}
            onClick={() => history.push(routes.login)}
            text='Sign in'
          />
        }>
        {success && (
          <Box mb={4}>
            <Message variant='success'>
              <NovunaHeading as='h3' mb={1}>
                Email sent
              </NovunaHeading>
              <Text>Please check your inbox for a password reset link.</Text>
            </Message>
          </Box>
        )}
        {!success && errorMessage && (
          <Box mb={4}>
            <Message variant='error'>
              <NovunaHeading as='h3' mb={1}>
                Something went wrong
              </NovunaHeading>
              <Text>Please check your details and try again.</Text>
            </Message>
          </Box>
        )}

        <NovunaHeading
          data-test-id='compass-reset-password-title'
          as='h1'
          mb={3}>
          Forgot password?
        </NovunaHeading>
        <Text mb={4}>
          Enter your username to receive an email containing your password reset
          link.
        </Text>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={values => onSubmit(values)}
          enableReinitialize>
          <Form noValidate>
            {!retailerName && (
              <Box mb={4}>
                <Field name='retailerName' data-test-id='retailer-name-field'>
                  {({
                    field,
                    meta
                  }: FieldProps<string, ResetPasswordFormValues>) => (
                    <InputWrapper
                      id='reset-password-retailer'
                      label='Retailer name'
                      error={meta.error}
                      tone={
                        meta.touched && meta.error ? 'negative' : 'neutral'
                      }>
                      <TextField
                        id='reset-password-retailer'
                        type='text'
                        autoCapitalize='none'
                        data-test-id='compass-reset-password-retailer-control'
                        tone={
                          meta.touched && meta.error ? 'negative' : 'neutral'
                        }
                        {...field}
                      />
                    </InputWrapper>
                  )}
                </Field>
              </Box>
            )}

            <Field name='username' data-test-id='username-field'>
              {({
                field,
                meta
              }: FieldProps<string, ResetPasswordFormValues>) => (
                <InputWrapper
                  id='reset-password-username'
                  label='Username'
                  error={meta.error}
                  tone={meta.touched && meta.error ? 'negative' : 'neutral'}>
                  <TextField
                    id='reset-password-username'
                    type='text'
                    autoCapitalize='none'
                    data-test-id='compass-reset-password-username-control'
                    tone={meta.touched && meta.error ? 'negative' : 'neutral'}
                    {...field}
                  />
                </InputWrapper>
              )}
            </Field>

            <Button
              mt={5}
              isJumbo
              type='submit'
              disabled={processing}
              data-test-id='compass-reset-password-submit'>
              {processing ? 'Sending email\u2026' : 'Reset password'}
            </Button>
          </Form>
        </Formik>
      </LoggedOutWrapper>
    </>
  );
};

const validationSchema = yup.object<ResetPasswordFormValues>({
  retailerName: yup.string().required(validationMessages.REQUIRED_FIELD),
  username: yup.string().required(validationMessages.REQUIRED_FIELD)
});
