import moment from 'moment';
import { createSelector } from 'reselect';
import { ReferralNote } from 'hitachi-retail-core/build/schemas/referralNote';
import { State } from '../../store/previousApplicationDetail/reducer';

const formatDateTime = (dateTime: string) =>
  moment(dateTime).format('DD MMM YYYY, HH:mm');

const referralNotesSelector = ({ referralNotes }: State) => referralNotes;

export const selectReferralNotes = createSelector(
  referralNotesSelector,
  referralNotes =>
    referralNotes?.map(
      (n): ReferralNote => ({
        username: selectReferralNoteUsername(n),
        message: n.message,
        createdDateTime: formatDateTime(n.createdDateTime)
      })
    )
);

const selectReferralNoteUsername = ({ username }: ReferralNote) =>
  username === 'UNDERWRITING' ? 'Underwriting Team' : username;

const customerFeedbackSelector = ({ customerFeedback }: State) =>
  customerFeedback;

export const selectCustomerFeedback = createSelector(
  customerFeedbackSelector,
  customerFeedback =>
    customerFeedback
      ? {
          notes: customerFeedback?.notes.map(({ type, message, created }) => ({
            type,
            message,
            created: formatDateTime(created)
          }))
        }
      : undefined
);
