import { DefaultSagaParams } from '../../sagas';

export interface SendPrecontractInfoService {
  sendPrecontractInfo: (id: string) => Promise<void>;
}

export const getSendPrecontractInfoService = ({
  apiClient
}: DefaultSagaParams): SendPrecontractInfoService => ({
  sendPrecontractInfo: async id => {
    try {
      await apiClient.post(`/mo/application/${id}/email/loan`);
    } catch (err) {
      throw new Error(`Failed to send Loan Advance Info: ${err}`);
    }
  }
});
