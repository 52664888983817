import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import * as app from './constants';
import { ApplicationHistory } from 'hitachi-retail-core/build/schemas/financeApplication';

// Omit attributes that will be transformed to string in the response
export type PreviousApplication = Omit<
  ApplicationHistory,
  'created' | 'loanAmount' | 'updated'
> & {
  created: string;
  updated: string;
  loanAmount?: string;
};

export interface FetchPreviousApplicationsResponse {
  applications: PreviousApplication[];
}

export interface FetchSearchPreviousApplicationsResponse
  extends FetchPreviousApplicationsResponse {
  total_count: number;
}

export interface SearchApplicationsActionProps {
  term: string;
  offset: number;
  limit: number;
}

export const fetchPreviousApplications = createAsyncAction(
  app.FETCH_PREVIOUS_APPLICATIONS_REQUEST,
  app.FETCH_PREVIOUS_APPLICATIONS_SUCCESS,
  app.FETCH_PREVIOUS_APPLICATIONS_FAILURE
)<void, FetchPreviousApplicationsResponse, Error>();

export const searchApplicationsAction = createAsyncAction(
  app.SEARCH_APPLICATIONS_REQUEST,
  app.SEARCH_APPLICATIONS_SUCCESS,
  app.SEARCH_APPLICATIONS_FAILURE
)<
  SearchApplicationsActionProps,
  FetchSearchPreviousApplicationsResponse,
  Error
>();

export const resetSearchApplicationsAction = createStandardAction(
  app.RESET_SEARCH_APPLICATIONS
)();
