import { GoodsListItem } from 'hitachi-retail-core';
import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { resetStore, ResetStoreAction } from '../actions';
import { getGoodsList, resetFetchedGoodsList } from './actions';

export type GoodsListAction = ActionType<typeof getGoodsList>;

export type ResetFetchedGoodsListAction = ActionType<
  typeof resetFetchedGoodsList
>;

export interface State {
  processing: boolean;
  fetched: boolean;
  error: boolean;
  goodsList: GoodsListItem[];
}

export const initialState: State = {
  processing: false,
  fetched: false,
  error: false,
  goodsList: []
};

export const reducer: Reducer<
  State,
  GoodsListAction | ResetFetchedGoodsListAction | ResetStoreAction
> = (state = initialState, action) => {
  switch (action.type) {
    case getType(getGoodsList.request): {
      return {
        ...state,
        processing: true,
        error: false
      };
    }

    case getType(getGoodsList.failure): {
      // Either namespace or index
      return {
        ...state,
        error: true,
        processing: false,
        fetched: true
      };
    }

    case getType(getGoodsList.success): {
      return {
        ...state,
        processing: false,
        goodsList: action.payload.goodsList,
        fetched: true
      };
    }

    case getType(resetFetchedGoodsList):
    case getType(resetStore): {
      return initialState;
    }

    default:
      return state;
  }
};
