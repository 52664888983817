import React from 'react';
import { ReadOnlyAddress } from 'components/common/ReadOnlyAddress';
import AddressInput from '../AddressInput';
import { AddressWrapperProps } from '../AddressWrapper/AddressWrapper';
import {
  ReadOnlyWrapper,
  TertiaryLink,
  TertiaryLinkDirection,
  Tone
} from 'compass-design';

export type AddressInputWrapperProps = Pick<
  AddressWrapperProps,
  'values' | 'addressIndex'
> & {
  manualEntryMode: boolean;
  setEditAddress: (value: boolean) => void;
  setManualEntry: (value: boolean) => void;
  formNamespace: string;
  addressValid?: boolean;
};

const AddressInputWrapper: React.FC<AddressInputWrapperProps> = props => {
  const {
    addressIndex,
    values,
    manualEntryMode,
    setManualEntry,
    addressValid
  } = props;
  const address = values?.mainAddressDetails?.mainAddress?.[addressIndex];

  let tone: Tone = 'neutral';
  if (addressValid !== undefined) {
    tone = addressValid ? 'positive' : 'negative';
  }

  if (
    address &&
    (addressValid || addressValid === undefined) &&
    !manualEntryMode
  ) {
    return (
      <ReadOnlyWrapper
        label='Address'
        tone={tone}
        action={
          <TertiaryLink
            direction={TertiaryLinkDirection.FORWARDS}
            text='Edit'
            dataTestId={`edit-address-button-${addressIndex}`}
            onClick={() => setManualEntry(true)}
          />
        }>
        <ReadOnlyAddress address={address.address} />
      </ReadOnlyWrapper>
    );
  }

  return <AddressInput {...props} />;
};

export default AddressInputWrapper;
