import { ProductConfig } from 'hitachi-retail-core/build/api/productConfig';
import { ServiceTypeIdentifier } from 'hitachi-retail-core/build/application/financeProduct';
import { getMinDeposit } from 'hitachi-retail-core/build/finance';
import { IncompleteFinanceApplication } from 'hitachi-retail-core/build/schemas/financeApplication';
import {
  selectDeposit,
  selectGoodsAmount,
  selectInstalments,
  selectLoanAmount
} from 'hitachi-retail-core/build/selectors';
import { parseDecimal } from 'hitachi-retail-core/build/utils/decimal';
import { JSONSchema6 } from 'json-schema';

export interface ProductOption {
  serviceType: ServiceTypeIdentifier;
  label?: string;
  apr?: string;
  instalments: number[];
  deferralPeriod: number[];
  productConfigs: ProductConfig[];
  selectable: boolean;
  selected: boolean;
}

export const getSelectedProductOption = (productOptions: ProductOption[]) =>
  productOptions.find(productOption => productOption.selected);

export const createProductOptions = (
  productConfigs: ProductConfig[],
  applicationDocument: IncompleteFinanceApplication
) =>
  productConfigs.reduce<ProductOption[]>(
    (productOptions, currentProductConfig) => {
      const { product: productValues } = applicationDocument;
      const { apr, name, serviceType } = currentProductConfig;
      const selectable = matchingLoanParameters(
        currentProductConfig,
        applicationDocument
      );

      const aprAsDecimal = parseDecimal(apr);
      const productValuesAprAsDecimal = parseDecimal(productValues?.apr);

      const aprMatchesProductValuesApr =
        productValuesAprAsDecimal && aprAsDecimal
          ? productValuesAprAsDecimal.equals(aprAsDecimal)
          : false;

      let existingOption = productOptions.find(productOption => {
        const optionAprAsDecimal = parseDecimal(productOption.apr);

        const aprMatchesOptionApr =
          optionAprAsDecimal && aprAsDecimal
            ? optionAprAsDecimal.equals(aprAsDecimal)
            : false;

        return productOption.serviceType === serviceType && aprMatchesOptionApr;
      });
      if (!existingOption) {
        existingOption = {
          serviceType,
          apr,
          label: name,
          instalments: [],
          deferralPeriod: [],
          productConfigs: [],
          selectable,
          selected:
            productValues?.serviceType === serviceType &&
            aprMatchesProductValuesApr
        };

        productOptions.push(existingOption);
      }

      // Always add product config
      existingOption.productConfigs.push(currentProductConfig);

      // Prevent change to false if option has previously been set to true
      if (existingOption.selectable !== true) {
        existingOption.selectable = selectable;
      }

      const sortAndDedupe = (subject: number[]) =>
        subject
          .sort((a, b) => a - b)
          .filter((value, index, array) => array.indexOf(value) === index);

      if (selectable) {
        existingOption.instalments = sortAndDedupe([
          ...existingOption.instalments,
          ...currentProductConfig.instalments
        ]);

        if (currentProductConfig.deferralPeriod) {
          existingOption.deferralPeriod = sortAndDedupe([
            ...existingOption.deferralPeriod,
            ...currentProductConfig.deferralPeriod
          ]);
        }
      }

      return productOptions;
    },
    []
  );

export const getSelectedProductConfig = (
  productConfigs: ProductConfig[],
  applicationDocument: IncompleteFinanceApplication
) =>
  productConfigs.find(productConfig => {
    const { product: productValues } = applicationDocument;

    const serviceTypeMatch =
      productValues?.serviceType === productConfig.serviceType;

    const productValuesApr = parseDecimal(productValues?.apr);
    const productConfigApr = parseDecimal(productConfig.apr);
    const aprMatch =
      productValuesApr && productConfigApr
        ? productValuesApr.equals(productConfigApr)
        : false;

    const instalments = selectInstalments(applicationDocument);
    const instalmentsMatch =
      instalments && productConfig.instalments.includes(instalments);

    const loanParametersMatch = matchingLoanParameters(
      productConfig,
      applicationDocument
    );

    return (
      serviceTypeMatch && aprMatch && instalmentsMatch && loanParametersMatch
    );
  });

const matchingLoanParameters = (
  productConfig: ProductConfig,
  applicationDocument: IncompleteFinanceApplication
) => {
  const goodsAmount = selectGoodsAmount(applicationDocument);
  const deposit = selectDeposit(applicationDocument);
  const loanAmount = selectLoanAmount(applicationDocument);

  let validMinGoodsAmount = false;
  if (goodsAmount) {
    validMinGoodsAmount = goodsAmount.greaterThanOrEqualTo(
      productConfig.minGoodsAmount
    );
  }

  let validMinLoanAmount = false;
  let validMaxLoanAmount = false;
  if (loanAmount) {
    validMinLoanAmount = loanAmount.greaterThanOrEqualTo(
      productConfig.minLoanAmount
    );
    validMaxLoanAmount = loanAmount.lessThanOrEqualTo(
      productConfig.maxLoanAmount
    );
  }

  let validDeposit = false;
  const minDepositPercentage = parseDecimal(productConfig.minDepositPercentage);
  const minDepositAmount = parseDecimal(productConfig.minDepositAmount);
  if (goodsAmount && deposit && minDepositPercentage && minDepositAmount) {
    const minDeposit = getMinDeposit({
      goodsAmount,
      minDepositPercentage,
      minDepositAmount
    });
    validDeposit = deposit.greaterThanOrEqualTo(minDeposit);
  }

  return (
    validMinGoodsAmount &&
    validMinLoanAmount &&
    validMaxLoanAmount &&
    validDeposit
  );
};

export const quoteSchema = (
  deferralPeriodOptions: number[] = []
): JSONSchema6 => ({
  type: 'object',
  required: [
    'goodsDescription',
    'orderReference',
    'loanParameters',
    'product',
    'loanRepayment'
  ],
  properties: {
    goodsDescription: {
      title: 'Goods description',
      type: 'string'
    },
    orderReference: {
      title: 'Order reference',
      type: 'string'
    },
    loanParameters: {
      type: 'object',
      title: '',
      required: ['goodsAmount', 'deposit'],
      properties: {
        goodsAmount: {
          title: 'Total cost of goods',
          type: 'string'
        },
        deposit: {
          title: 'Deposit',
          type: 'string'
        },
        creditCardDeposit: {
          type: 'boolean'
        }
      }
    },
    product: {
      type: 'object',
      title: '',
      required: ['product'],
      properties: {
        product: {
          title: 'Finance product',
          type: 'string'
        }
      }
    },
    loanRepayment: {
      type: 'object',
      title: '',
      required: ['deferralPeriod', 'instalments'],
      properties: {
        deferralPeriod: {
          title: 'Defer repayments for',
          type: 'integer',
          default: null,
          enum: deferralPeriodOptions
        },
        instalments: {
          title: 'Repayment options',
          type: 'integer'
        }
      }
    }
  }
});
