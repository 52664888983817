import React from 'react';
import { Box, Message, NovunaHeading, Text } from 'compass-design';
import { PurpleMail } from 'smallMessageSvg';

const F2FReferralIDVTCard = ({
  sendTextMessage
}: {
  sendTextMessage: boolean;
}) => (
  <Box mt={6} data-test-id='referral-status-card'>
    <Message variant='info'>
      <NovunaHeading as='h3' mb={1}>
        Referred
      </NovunaHeading>
      <Text mb={2}>
        Your application has been referred to the Underwriting Team, but we need
        some additional information from the customer before we can make a
        decision.
      </Text>
    </Message>
    <Message
      variant='info'
      mt={4}
      style={{ alignItems: 'center' }}
      icon={
        <PurpleMail
          width={32}
          height={32}
          viewBox='0 0 64 64'
          style={{ minHeight: '100%' }}
        />
      }>
      <Text as='span'>
        <NovunaHeading as='h4' mb={1} style={{ display: 'inline' }}>
          Action Required!
        </NovunaHeading>
      </Text>
      <Text mb={2}>
        We have sent the customer{' '}
        {sendTextMessage ? `a text message` : `an email`} so that they can
        verify their identity and complete the application. Please ask them to
        check their {sendTextMessage ? `messages` : `inbox`}.
      </Text>
    </Message>
  </Box>
);

export default F2FReferralIDVTCard;
