import React from 'react';
import { Box, Button, NovunaHeading, Text } from 'compass-design';
import { useDispatch, useSelector } from 'react-redux';
import { selectSuccessMessage } from 'store/enhancedUsers/selectors';
import { setEditUser } from 'store/enhancedUsers/actions';
import { BranchSelectorButton } from '../BranchSelectorModal';

interface EnhancedUsersHeaderProps {
  pageTitle: string;
  retailerName: string;
}

const EnhancedUsersHeader = ({
  pageTitle,
  retailerName
}: EnhancedUsersHeaderProps) => {
  const dispatch = useDispatch();
  const successMessage = useSelector(selectSuccessMessage);

  return (
    <>
      {successMessage && (
        <Box
          mb={3}
          p={2}
          sx={{
            textAlign: 'center',
            border: '1px solid',
            borderColor: '#88cfa3',
            backgroundColor: 'rgba(215, 255, 215, 0.6)',
            borderRadius: 'panel'
          }}>
          <Text>{successMessage}</Text>
        </Box>
      )}

      <NovunaHeading as='h3' mb={2}>
        Manage Users
      </NovunaHeading>

      <NovunaHeading as='h2' mb={5}>
        {retailerName}
      </NovunaHeading>

      <Box
        sx={{
          display: ['block', null, 'flex'],
          justifyContent: 'space-between'
        }}
        mb={5}>
        <NovunaHeading as='h1'>{pageTitle}</NovunaHeading>

        <Box mt={[4, null, 0]} sx={{ display: ['block', null, null, 'flex'] }}>
          <BranchSelectorButton />
          <Button
            variant='primary'
            isJumbo
            onClick={() =>
              dispatch(setEditUser({ isEditing: true, isCreate: true }))
            }>
            New user
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default EnhancedUsersHeader;
