import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  EditDepositProps,
  LoanAttribute,
  Message,
  NovunaHeading,
  Text
} from 'compass-design';
import {
  LoanDetails,
  LoanParameters
} from 'retailerApp/utils/loanDetails/getLoanDetails';
import { Helmet } from 'react-helmet-async';
import { formatCurrency } from 'hitachi-retail-core/build/utils/currency';
import { getExpiryDate, getIsTailoringExpired } from 'utils/expiryDate';
import { AmendLoanHeader } from '../AmendLoanHeader/AmendLoanHeader';
import { Decimal } from 'hitachi-retail-core/build/utils/decimal';
import { formatDecimalPercent } from 'utils/formatters';
import { RootState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { ProductConfig } from 'hitachi-retail-core/build/api/productConfig';
import getEditLoanOffer from 'retailerApp/utils/loanDetails/getEditLoanOffer';
import MixingDeck, {
  NewOfferComparisonCardProps
} from '../MixingDeck/MixingDeck';
import { userInputDelay } from '../EditLoanCardState/EditLoanCardState';
import isNumber from 'utils/isNumber';
import checkForErrors from '../EditLoanCardState/checkForErrors';
import { updateActiveApplication } from 'store/application/actions';
import {
  selectGoodsList,
  selectHasFetchedGoodsList,
  selectProcessing
} from 'store/goodsList/selectors';
import { push } from 'connected-react-router';
import { routes } from 'routes';
import { TailoringOptionSelected } from 'hitachi-retail-core/build/services/tailoring/types';
import { fetchDecision } from 'store/decision/actions';
import useSetDeposit from 'retailerApp/hooks/useSetDeposit';
import useSetTotalCost from 'retailerApp/hooks/useSetTotalCost';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import GoodsDetailsModal, {
  OnContinueParams
} from '../GoodsDetailsModal/GoodsDetailsModal';
import useSetGoodsDetails from 'retailerApp/hooks/useSetGoodsDetails';
import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import { selectEnabledFeatures } from 'store/config/selectors';
import { getGoodsList } from 'store/goodsList/actions';
import {
  selectRetailer,
  selectProposingOnBehalfOf
} from 'store/retailer/selectors';
import formatGoodsList from 'utils/formatGoodsList';
import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';
import { IsPhaseThreeEnabledForRetailer } from 'sagas/retailer/saga';

interface AmendLoanProps {
  loanDetails: LoanDetails;
  productConfig: ProductConfig;
  loanParameters: LoanParameters & {
    maximumGoodsAmount: Decimal;
    maximumLoanAmount: Decimal;
    originalLoanAmount: Decimal;
    originalDeposit: Decimal;
  };
}

const selectApplication = (state: RootState) => state.application;
const selectIsLoanAmend = (state: RootState) => state.loanAmend.isLoanAmend;
const selectPreviousApplicationDetail = (state: RootState) =>
  state.previousApplicationDetail;

const AmendLoan = ({
  loanDetails,
  productConfig,
  loanParameters
}: AmendLoanProps) => {
  const dispatch = useDispatch();
  const application = useSelector(selectApplication);
  const retailer: Retailer & IsPhaseThreeEnabledForRetailer = useSelector(
    selectRetailer
  );
  const isLoanAmend = useSelector(selectIsLoanAmend);
  const previousApplicationDetail = useSelector(
    selectPreviousApplicationDetail
  );
  const processing = useSelector(selectProcessing);
  const fetched = useSelector(selectHasFetchedGoodsList);
  const { supplierNumber } = useSelector(selectRetailer);
  const enabledFeatures = useSelector(selectEnabledFeatures);
  const proposingOnBehalfOf = useSelector(selectProposingOnBehalfOf);
  const { activeApplication } = application;
  const { document, applicationExpiryDate, status } = activeApplication;
  const { previousApplication } = previousApplicationDetail;
  const isPhase3App =
    retailer.isPhaseThreeEnabledForRetailer &&
    !!previousApplication?.latestDecision;
  const isIncreaseLoan = false;
  let canLoanAmendUp = false;
  let goodsList = useSelector(selectGoodsList);

  useEffect(() => {
    if (
      !processing &&
      !fetched &&
      enabledFeatures.has(CompassFeature.GOODS_LIST)
    ) {
      const id = proposingOnBehalfOf?.supplierNumber ?? supplierNumber;
      dispatch(getGoodsList.request({ id }));
    }
  }, [
    supplierNumber,
    proposingOnBehalfOf,
    processing,
    fetched,
    enabledFeatures,
    dispatch
  ]);

  if (applicationExpiryDate) {
    canLoanAmendUp = !getIsTailoringExpired({
      tailoringExpiryDate: applicationExpiryDate
    });
  }

  const originalDeposit = loanDetails?.originalOffer?.deposit
    ? loanDetails?.originalOffer?.deposit.toFixed(2)
    : '';

  const signedRoute = previousApplication
    ? routes.search.getApplicationById(previousApplication.id)
    : routes.search.index;

  const {
    deposit,
    depositUpdated,
    setDepositDebounce,
    setDeposit,
    setDepositUpdated
  } = useSetDeposit(new Decimal(originalDeposit), userInputDelay);

  const {
    totalCost,
    setTotalCost,
    setTotalCostDebounce,
    totalCostUpdated,
    setTotalCostUpdated
  } = useSetTotalCost(userInputDelay);

  const {
    setGoodsDetails,
    goodsDescription,
    orderReference,
    goodsDescriptionError,
    orderReferenceError,
    resetState
  } = useSetGoodsDetails(loanDetails);

  goodsList = formatGoodsList({ goodsList, goodsDescription });

  const [depositInput, setDepositInput] = useState(
    loanDetails.originalOffer?.deposit.toFixed(2)
  );
  const [isEditingDeposit, setIsEditingDeposit] = useState(false);
  const [showPlaceholders, setShowPlaceholders] = useState(true);

  const [showGoodsDescriptionModal, setShowGoodsDescriptionModal] = useState(
    false
  );

  const resetModal = () => {
    setShowGoodsDescriptionModal(!showGoodsDescriptionModal);
    resetState(loanDetails);
  };

  const editDeposit: EditDepositProps = {
    minDepositPercentage:
      ((productConfig.minDepositPercentage.toString as unknown) as string) ??
      '',
    editing: isEditingDeposit,
    setEditing: setIsEditingDeposit,
    value: depositInput as any,
    errorMessage: deposit.error ? deposit.error : undefined,
    onChange: (depositValue: string) => {
      if (isNumber(depositValue) || depositValue === '') {
        setDepositInput(depositValue);
        setDepositDebounce(depositValue);
      }
    }
  };

  const createNewOffer = (
    loanDetails: LoanDetails | undefined,
    newTotalCost: string
  ): NewOfferComparisonCardProps => {
    return {
      offer: getEditLoanOffer({
        totalCost: new Decimal(newTotalCost.length > 0 ? newTotalCost : 0),
        deposit: new Decimal(deposit.value.length > 0 ? deposit.value : 0),
        interestRate: new Decimal(
          loanDetails?.originalOffer?.interestRate
            ? loanDetails?.originalOffer?.interestRate
            : 0
        ),
        repaidOver: loanDetails?.originalOffer?.repaidOver
          ? loanDetails?.originalOffer?.repaidOver
          : 0,
        isSoftSearch: false,
        updatedItem: LoanAttribute.increaseTotalCost,
        deferralPeriod: document.loanRepayment?.deferralPeriod
      }),
      offerText: '',
      optionText: ''
    };
  };

  const newOffer = createNewOffer(loanDetails, totalCost.value);

  const maximumTotalCost = new Decimal(loanParameters.maximumLoanAmount).plus(
    deposit.value ? deposit.value : new Decimal(0)
  );

  const goodsDetailsModalProps = {
    goodsDescription: goodsDescription,
    orderReference: orderReference,
    isGoodsDetailsModalOpen: showGoodsDescriptionModal,
    setIsGoodsDetailsModalOpen: resetModal,
    setGoodsDetails: setGoodsDetails,
    goodsDescriptionError: goodsDescriptionError,
    orderReferenceError: orderReferenceError,
    disableContinueButton: !!(goodsDescriptionError || orderReferenceError),
    isSoftSearch: false,
    goodsList: goodsList
  };

  const clickNewOffer = useCallback(
    (newOffer, goodsDescription, orderReference) => {
      dispatch(
        updateActiveApplication({
          document: {
            ...document,
            loanParameters: {
              ...document.loanParameters,
              ...(isPhase3App
                ? {
                    // FX-950 - Use intermittent field during a loan amend so that the
                    //          application can continue to be valid if decision fails
                    pendingDeposit: newOffer.offer.deposit.toFixed(2),
                    pendingGoodsAmount: newOffer.offer.totalCost.toFixed(2)
                  }
                : {
                    deposit: newOffer.offer.deposit.toFixed(2),
                    goodsAmount: newOffer.offer.totalCost.toFixed(2)
                  })
            },
            goodsDescription,
            orderReference
          },
          replaceValues: true
        })
      );
      dispatch(fetchDecision.request({ isLoanAmend: true }));
      dispatch(push(routes.apply.decision));
    },
    [dispatch, document, isPhase3App]
  );

  const clickOriginalOffer = useCallback(() => {
    if (status !== ApplicationStatus.signed) {
      dispatch(
        updateActiveApplication({
          document: {
            ...document,
            loanTailoring: {
              ...document.loanTailoring,
              optionSelected: TailoringOptionSelected.rejected
            }
          },
          replaceValues: true
        })
      );
      dispatch(fetchDecision.request());
    }
    dispatch(
      push(
        status === ApplicationStatus.signed
          ? signedRoute
          : routes.apply.decision
      )
    );
  }, [dispatch, document, status, signedRoute]);

  if (!canLoanAmendUp) {
    loanParameters.maximumLoanAmount = loanDetails.originalOffer
      ?.amountBorrowed as Decimal;
  }

  useEffect(() => {
    if (totalCostUpdated || depositUpdated) {
      const editingDeposit = depositUpdated;
      setTotalCostUpdated(false);
      setDepositUpdated(false);
      //need to pass in if loanAmend past 30 days for validation checks
      const totalCostHasError = checkForErrors({
        loanParameters,
        totalCost,
        deposit,
        editingDeposit,
        setTotalCost,
        setDeposit,
        isIncreaseLoan,
        isLoanAmend,
        productConfig
      });

      setShowPlaceholders(totalCostHasError);
    }
  }, [
    totalCostUpdated,
    depositUpdated,
    totalCost,
    deposit,
    loanParameters,
    isIncreaseLoan,
    setDeposit,
    setDepositUpdated,
    setTotalCost,
    setTotalCostUpdated,
    isLoanAmend,
    productConfig
  ]);

  return (
    <>
      <Helmet>
        <title>Amend Loan - CreditMaster3</title>
      </Helmet>
      <NovunaHeading as='h1' mb={3} data-test-id='amend-loan-page-heading'>
        Amend Loan
      </NovunaHeading>
      <Box mb={4}>
        <Text mt={2} mb={0} sx={{ fontSize: [1, 2, 2] }}>
          The loan can be amended without further credit checks. Increases to
          the loan can not exceed the amount below.
        </Text>
        {canLoanAmendUp ? (
          <Text mt={2} mb={0} sx={{ fontSize: [1, 2, 2], fontWeight: 'bold' }}>
            This is valid until {getExpiryDate(applicationExpiryDate!)}.
          </Text>
        ) : null}
      </Box>
      <NovunaHeading as='h2' mb={3}>
        Update your application
      </NovunaHeading>
      <Message variant='info' mb={4}>
        <NovunaHeading as='h3' mb={1}>
          {canLoanAmendUp ? `Increase or decrease` : `Decrease`} the loan amount
        </NovunaHeading>
        <Text>
          Amend the total cost up to{' '}
          {formatCurrency(
            canLoanAmendUp
              ? maximumTotalCost
              : loanDetails.originalOffer?.totalCost.toFixed(2)
          )}{' '}
          by borrowing up to{' '}
          {formatCurrency(
            canLoanAmendUp
              ? loanParameters.maximumLoanAmount.toString()
              : loanDetails.originalOffer?.amountBorrowed.toString()
          )}{' '}
          with a{' '}
          {formatDecimalPercent(productConfig.minDepositPercentage as Decimal)}{' '}
          deposit.
        </Text>
        <AmendLoanHeader
          totalCost={totalCost}
          setTotalCost={setTotalCostDebounce}
        />
      </Message>

      <MixingDeck
        originalOffer={loanDetails.originalOffer!}
        newOffer={newOffer}
        buttonOnClick={() => setShowGoodsDescriptionModal(true)}
        showOfferText={true}
        showPlaceholders={showPlaceholders}
        buttonOriginalOfferOnClick={clickOriginalOffer}
        editDeposit={editDeposit}
        depositError={deposit.error ? deposit.error : undefined}
      />

      {goodsDetailsModalProps && (
        <GoodsDetailsModal
          {...goodsDetailsModalProps}
          onContinue={({
            goodsDescription,
            orderReference
          }: OnContinueParams) =>
            clickNewOffer(newOffer, goodsDescription, orderReference)
          }
        />
      )}
    </>
  );
};

export default AmendLoan;
