import { call, takeLeading } from 'redux-saga/effects';
import { getType, isOfType } from 'typesafe-actions';
import {
  DocumentDownloadConfirmationService,
  getDocumentDownloadConfirmationService
} from '../../services/documentDownloadConfirmationService';
import {
  documentDownloadConfirmation,
  DocumentDownloadConfirmationAction
} from '../../store/documentDownloadConfirmation/actions';
import { DefaultSagaParams } from '../index';

interface GetDocumentDownloadConfirmationSagaParams {
  documentDownloadConfirmationService: DocumentDownloadConfirmationService;
}

export const getDocumentDownloadConfirmationSaga = ({
  documentDownloadConfirmationService
}: GetDocumentDownloadConfirmationSagaParams) =>
  function*(action: DocumentDownloadConfirmationAction) {
    if (isOfType(getType(documentDownloadConfirmation.request), action)) {
      try {
        yield call(
          documentDownloadConfirmationService.sendConfirmation,
          action.payload
        );
      } catch (err) {
        // Service-side logging only, no user-facing error
      }
    }
  };

export const getDocumentDownloadConfirmationSagaWatcher = ({
  apiClient
}: DefaultSagaParams) =>
  function*() {
    const documentDownloadConfirmationService = getDocumentDownloadConfirmationService(
      {
        apiClient
      }
    );

    yield takeLeading(
      getType(documentDownloadConfirmation.request),
      getDocumentDownloadConfirmationSaga({
        documentDownloadConfirmationService
      })
    );
  };
