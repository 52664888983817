import { AuthScreen, Text } from 'compass-design';
import querystring from 'querystring';
import React, { memo, useEffect } from 'react';
import { useSelector } from 'store';
import { RouteChildrenProps } from 'react-router';
import { selectTokenExchangeState } from '../../store/applicantAuth/reducer';
import { AsyncStatus } from 'applicantApp/store/AsyncStatus';
import { useTokenExchange } from '../../components/hooks/useTokenExchange';

const parseQueryString = (query: string) =>
  querystring.parse(query.replace(/^\?/, ''));

export interface AuthCallbackPropsFromState {
  token?: string;
  codeVerifier?: string;
  tokenExchangeState: AsyncStatus;
}

type AuthCallbackProps = RouteChildrenProps;

const AuthCallbackBase: React.FunctionComponent<AuthCallbackProps> = ({
  location
}) => {
  const { code, state: authState } = parseQueryString(location.search);

  const tokenExchangeState = useSelector(selectTokenExchangeState);

  const tokenExchange = useTokenExchange({ code, authState });

  useEffect(() => {
    tokenExchange();
  }, [tokenExchange]);

  return (() => {
    switch (tokenExchangeState) {
      case AsyncStatus.Failure:
        return (
          <Text>Failed to exchange authorization token, please try again</Text>
        );
      default:
        return <AuthScreen loading />;
    }
  })();
};

export default memo(AuthCallbackBase);
