import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';
import React from 'react';
import { Box, Text } from 'theme-ui';

interface HeadOfficeUserBannerProps {
  username: string;
  selectedBranch: Retailer;
  headOfficeRetailer: Retailer;
}

const HeadOfficeUserBanner: React.FC<HeadOfficeUserBannerProps> = ({
  headOfficeRetailer,
  selectedBranch,
  username
}) => (
  <Box
    py={2}
    mt='-16px'
    sx={{
      fontSize: 2,
      backgroundColor: 'rgba(187, 212, 255, 0.24)',
      textAlign: 'center',
      border: '1px solid #a4beea',
      borderRadius: '5px'
    }}>
    <Text>
      <b>{username}</b> of{' '}
      <b>
        {headOfficeRetailer.name}
        {headOfficeRetailer.supplierNumber}
      </b>{' '}
      managing users for{' '}
      <b>
        {selectedBranch.name} ({selectedBranch.supplierNumber})
      </b>
    </Text>
  </Box>
);

export default HeadOfficeUserBanner;
