import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import { ApplicationDetail } from 'components/form/types';
import { routes } from 'routes';
import { fetchActiveApplication } from '../../../store/previousApplicationDetail/actions';
import { ApplicationIdRouteProps } from 'applicantApp/components/ApplicationRouteProps/ApplicationRouteProps';
import ResumeApplication from 'applicantApp/components/ResumeApplication';
import RedirectToJourney from 'applicantApp/components/RedirectToJourney';
import { AsyncStatus } from 'applicantApp/store/AsyncStatus';
import { connectApplicantApplication } from './ApplicantApplicationContainer';
import { ApplicantDetailsCapture } from 'applicantApp/pages/Details';
import { DetailsCaptureValues } from 'applicantApp/pages/Details/components/schema';
import KeyLoanFeatures from 'applicantApp/pages/KeyLoanFeatures';
import LoanAdvanceInformation from 'applicantApp/pages/LoanAdvanceInformation';
import PostSign from 'applicantApp/pages/PostSign';
import DecisionOutcome from 'applicantApp/pages/DecisionOutcome';
import ApplicationReview from 'applicantApp/components/ApplicationReview';
import AppStatusRestrictedRoute from './components/AppStatusRestrictedRoute';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import {
  selectIsWebServicesJourney,
  selectShowKeyLoanFeaturesWSApps,
  selectShouldCollectDeposit
} from '../../../store/application/selectors';
import OnlineDeposit from '../OnlineDeposit';
import { FeatureConfig } from 'hitachi-retail-core/build/features/features';
import { AcceptOfferFunction } from 'retailerApp/utils/loanDetails/getAffordabilityAccept';
import SoftSearchOutcome from '../SoftSearchOutcome';

export interface ApplicantApplicationPropsFromDispatch {
  completeSigning: () => void;
  completeKeyLoanFeatures: () => void;
  completeLoanAdvanceInformation: (
    applicationStatus?: ApplicationStatus
  ) => void;
  startLoanAdvanceInformation: () => void;
  onSave: (formData: DetailsCaptureValues) => void;
  onDetailsCaptureComplete: () => void;
  gotoFormStep: (stepIndex: number) => void;
  onSummaryComplete: () => void;
  onConfirmTailoringUpdate: AcceptOfferFunction;
  onAcceptOriginalOffer: AcceptOfferFunction;
}

export interface ApplicantApplicationPropsFromState {
  activeApplication: ApplicationDetail;
  retailer: Retailer;
  id: string;
  decisionPollInterval: number | string;
  fetchApplicationStatus: AsyncStatus;
  enabledFeatures: FeatureConfig;
  decisionErrorMessage?: string;
  tailoringExpiryDate?: string | null;
  isDecisionLoading: boolean;
}

export type ApplicantApplicationProps = ApplicantApplicationPropsFromState &
  ApplicantApplicationPropsFromDispatch;

const useFetchApplication = ({ id }: { id?: string }) => {
  const dispatch = useDispatch();

  return useCallback(() => {
    if (id) {
      dispatch(fetchActiveApplication.request({ id }));
    }
  }, [dispatch, id]);
};

export const ApplicantApplication: React.FunctionComponent<ApplicationIdRouteProps> = ({
  match
}) => {
  const fetchApplication = useFetchApplication({ id: match?.params.id });

  useEffect(() => {
    fetchApplication();
  }, [fetchApplication]);

  const isWebServicesJourney = useSelector(selectIsWebServicesJourney);
  const isShowKeyLoanFeaturesForWsApps = useSelector(
    selectShowKeyLoanFeaturesWSApps
  );
  const isShowKeyLoanFeatures = isWebServicesJourney
    ? isShowKeyLoanFeaturesForWsApps
    : true;
  const isOnlineAndHasDeposit = useSelector(selectShouldCollectDeposit);

  return (
    <Switch>
      {isOnlineAndHasDeposit && (
        <AppStatusRestrictedRoute
          path={routes.mailOrder.application.onlineDeposit}
          allowedStatuses={[
            ApplicationStatus.decision_accept,
            ApplicationStatus.deposit_attempted,
            ApplicationStatus.deposit_unsuccessful,
            ApplicationStatus.deposit_paid
          ]}
          component={connectApplicantApplication(OnlineDeposit)}
        />
      )}

      {isShowKeyLoanFeatures && (
        <AppStatusRestrictedRoute
          path={routes.mailOrder.application.keyLoanFeatures}
          allowedStatuses={[
            isOnlineAndHasDeposit
              ? ApplicationStatus.deposit_paid
              : ApplicationStatus.decision_accept
          ]}
          component={connectApplicantApplication(KeyLoanFeatures)}
        />
      )}

      <AppStatusRestrictedRoute
        path={routes.mailOrder.application.loanInformation}
        allowedStatuses={[
          ApplicationStatus.decision_accept,
          ApplicationStatus.deposit_paid
        ]}
        component={connectApplicantApplication(LoanAdvanceInformation)}
      />

      <AppStatusRestrictedRoute
        path={routes.mailOrder.application.postSign}
        allowedStatuses={[
          ApplicationStatus.decision_accept,
          ApplicationStatus.signed,
          ApplicationStatus.cooling_off,
          ApplicationStatus.payout_pending,
          ApplicationStatus.ready_for_payout,
          ApplicationStatus.paid,
          ApplicationStatus.pending_esat_note,
          ApplicationStatus.customer_has_goods,
          ApplicationStatus.deposit_paid
        ]}
        component={connectApplicantApplication(PostSign)}
      />

      <AppStatusRestrictedRoute
        component={connectApplicantApplication(ResumeApplication)}
        allowedStatuses={[ApplicationStatus.open]}
        path={routes.mailOrder.application.quote}
      />

      <AppStatusRestrictedRoute
        path={routes.mailOrder.application.details}
        allowedStatuses={[ApplicationStatus.open]}
        component={connectApplicantApplication(ApplicantDetailsCapture)}
      />

      <AppStatusRestrictedRoute
        path={routes.mailOrder.application.summary}
        allowedStatuses={[ApplicationStatus.open]}
        component={connectApplicantApplication(ApplicationReview)}
      />

      <AppStatusRestrictedRoute
        path={routes.mailOrder.application.softSearchOutcome}
        allowedStatuses={[
          ApplicationStatus.decision_quotation_accept,
          ApplicationStatus.decision_quotation_refer,
          ApplicationStatus.decision_quotation_decline,
          ApplicationStatus.open
        ]}
        component={connectApplicantApplication(SoftSearchOutcome)}
      />

      <AppStatusRestrictedRoute
        path={routes.mailOrder.application.decisionOutcome}
        allowedStatuses={[
          ApplicationStatus.open,
          ApplicationStatus.decision_accept,
          ApplicationStatus.decision_decline,
          ApplicationStatus.decision_refer,
          ApplicationStatus.deposit_paid,
          ApplicationStatus.deposit_attempted
        ]}
        component={connectApplicantApplication(DecisionOutcome)}
      />

      {/* Fall back to the redirect if we're not on an explicit path */}
      <Route component={RedirectToJourney} />
    </Switch>
  );
};
