import { AddNote, Box, Button, Text } from 'compass-design';
import React from 'react';
import Notification from 'retailerApp/components/Notification/Notification';
import { UnsavedReferralNote } from 'services/applicationsService';
import { AsyncStatus } from 'store/AsyncStatus';
import NoteModal from '../NoteModal';
import Notes from '../Notes';
import { Note } from '../Notes/Notes';

interface SummaryApplicationNotesProps {
  isDecisionReferred: boolean;
  referralNoteSubmissionStatus?: AsyncStatus;
  noteModalOpen: boolean;
  applicationId: string;
  referralNotes?: Note[];
  resetApplicationReferralNoteSubmissionStatus: () => void;
  setNoteModalOpen: (state: boolean) => void;
  submitNote: (applicationId: string, note: UnsavedReferralNote) => void;
}

const SummaryApplicationNotes = ({
  isDecisionReferred,
  referralNoteSubmissionStatus,
  noteModalOpen,
  applicationId,
  referralNotes,
  setNoteModalOpen,
  resetApplicationReferralNoteSubmissionStatus,
  submitNote
}: SummaryApplicationNotesProps) => (
  <>
    {referralNoteSubmissionStatus === AsyncStatus.Success && (
      <Notification
        message='Application note submitted successfully'
        type='success'
        onClose={resetApplicationReferralNoteSubmissionStatus}
      />
    )}
    <Box
      mt={2}
      sx={{
        display: ['block', null, null, 'flex'],
        justifyContent: 'space-between',
        alignItems: 'flex-end'
      }}>
      <Text sx={{ maxWidth: '620px' }}>
        If the application is referred then any information requests from the
        Underwriting Team will appear here, if there is any information you
        would like to provide to the Underwriting Team then you can add a note.
      </Text>
      {isDecisionReferred && (
        <Box mt={[3, null, null, 0]}>
          <Button
            isJumbo
            variant='secondary'
            onClick={() => {
              resetApplicationReferralNoteSubmissionStatus();
              setNoteModalOpen(true);
            }}
            data-test-id='compass-application-add-note'>
            Add note
            <AddNote />
          </Button>
        </Box>
      )}
    </Box>
    <NoteModal
      modalOpen={noteModalOpen}
      toggleModalOpen={setNoteModalOpen}
      submissionStatus={referralNoteSubmissionStatus}
      submitNote={note => submitNote(applicationId, note)}
    />
    <Box mt={4}>
      <Notes
        notes={referralNotes}
        data-test-id='application-summary-application-notes-table'
      />
    </Box>
  </>
);

export default SummaryApplicationNotes;
