import { NovunaSingleColumnLayout } from 'compass-design';
import React from 'react';
import BackBreadcrumb from '../BackBreadcrumb';
import EnhancedUsersHeader from '../EnhancedUsersHeader';
import HeaderBanner from '../HeaderBanner';
import UsersFilters from '../UsersFilters';
import { UsersFiltersProps } from '../UsersFilters/UsersFilters';
import UsersTable from '../UsersTable';
import { UsersTableProps } from '../UsersTable/UsersTable';

interface EnhancedUserPageProps {
  pageTitle: string;
  retailerName: string;
  filters: UsersFiltersProps;
  usersTable: UsersTableProps;
}

const EnhancedUserPage = ({
  pageTitle,
  retailerName,
  filters,
  usersTable
}: EnhancedUserPageProps) => (
  <>
    <HeaderBanner />
    <NovunaSingleColumnLayout pageTop={<BackBreadcrumb />}>
      <EnhancedUsersHeader pageTitle={pageTitle} retailerName={retailerName} />
      <UsersFilters {...filters} />
      {!!usersTable.showTable && <UsersTable {...usersTable} />}
    </NovunaSingleColumnLayout>
  </>
);

export default EnhancedUserPage;
