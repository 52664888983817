import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import { DateTime } from 'luxon';

const checkIsCurrentlyToday = (softSearchExpiryDate: string) => {
  const now = new Date(Date.now());
  const current = new Date(softSearchExpiryDate);
  return (
    now.getFullYear() === current.getFullYear() &&
    now.getMonth() === current.getMonth() &&
    now.getDay() === current.getDay()
  );
};

const checkDate = (date: string): boolean => {
  if (checkIsCurrentlyToday(date)) {
    return false;
  }
  const formattedDate = DateTime.fromISO(date);
  const daysBetween = formattedDate.diffNow('days');
  return daysBetween.days < 0;
};

interface GetIsSoftSearchExpired {
  softSearchExpiryDate?: string;
  applicationStatus?: ApplicationStatus;
}

export const getIsSoftSearchExpired = ({
  softSearchExpiryDate,
  applicationStatus
}: GetIsSoftSearchExpired): boolean => {
  if (!softSearchExpiryDate) {
    return false;
  }
  if (
    applicationStatus === ApplicationStatus.decision_quotation_accept ||
    applicationStatus === ApplicationStatus.decision_quotation_refer ||
    applicationStatus === ApplicationStatus.decision_quotation_decline
  ) {
    return checkDate(softSearchExpiryDate);
  }
  return false;
};

interface GetIsTailoringExpired {
  tailoringExpiryDate?: string | null;
}

export const getIsTailoringExpired = ({
  tailoringExpiryDate
}: GetIsTailoringExpired): boolean => {
  if (!tailoringExpiryDate) {
    return true;
  }

  return checkDate(tailoringExpiryDate);
};

const getDate = (day: string) => {
  if (day === '1' || day === '21' || day === '31') {
    return `${day}st`;
  }
  if (day === '2' || day === '22') {
    return `${day}nd`;
  }
  if (day === '3' || day === '23') {
    return `${day}rd`;
  }
  return `${day}th`;
};

const formatDate = (date: string) => {
  const formattedDate = date.split(' ');
  formattedDate[0] = getDate(formattedDate[0]);
  return formattedDate.join(' ');
};

export const getExpiryDate = (date: string): string => {
  return formatDate(DateTime.fromISO(date).toFormat('d MMMM y'));
};
