import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'store';
import { User } from 'hitachi-retail-core/build/services/users/usersService';
import {
  setSelectedBranch,
  createUserAction,
  selectedUserAction,
  updateUserAction,
  resetUser
} from 'store/users/actions';
import { closeNotification } from 'store/ui/actions';
import formatCognitoUsername from 'utils/formatCognitoUsername';
import Users, { UsersPropsFromDispatch, UsersPropsFromState } from './Users';

export const mapStateToProps = ({
  user: { userRoles, username },
  retailer: { branches, retailer },
  users: {
    loading,
    selectedBranch,
    editUser: { user }
  },
  ui: { notification }
}: RootState): UsersPropsFromState => {
  const trimmedUsername =
    user && 'username' in user
      ? formatCognitoUsername(user.username)
      : undefined;

  return {
    loading,
    userRoles: userRoles ?? [],
    branches,
    selectedBranch,
    retailer,
    trimmedUsername,
    userToEdit: user,
    loggedInUsername: username ?? '',
    hasNotification: Boolean(notification?.message)
  };
};

export const mapDispatchToProps = (
  dispatch: Dispatch
): UsersPropsFromDispatch => ({
  selectBranch: selectedBranch => {
    dispatch(setSelectedBranch({ selectedBranch }));
    if (!selectedBranch) {
      dispatch(closeNotification());
    }
  },
  createUser: values => {
    dispatch(createUserAction.request(values));
  },
  resetUser: () => {
    dispatch(resetUser());
  },
  handleUserSelected: (user: User) => {
    dispatch(selectedUserAction.request(user));
  },
  updateUser: userFormData => {
    dispatch(updateUserAction.request(userFormData));
  },
  clearNotifications: hasNotifications => {
    if (hasNotifications) {
      dispatch(closeNotification());
    }
  }
});

export const mergeProps = (
  state: UsersPropsFromState,
  dispatch: UsersPropsFromDispatch
) => ({
  ...state,
  ...dispatch,
  handleClearingNotifications: () => {
    dispatch.clearNotifications(state.hasNotification);
  }
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Users);
