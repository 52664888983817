import { alpha } from '@theme-ui/color';
import { Box, Container } from 'compass-design';
import React from 'react';
import { useSelector } from 'store';
import {
  selectProposingOnBehalfOf,
  selectRetailer
} from 'store/retailer/selectors';

const ProposingOnBehalfOfBanner: React.FC = () => {
  const currentRetailer = useSelector(selectRetailer);
  const proposingOnBehalfOf = useSelector(selectProposingOnBehalfOf);

  return proposingOnBehalfOf ? (
    <Box
      sx={{
        backgroundColor: alpha('secondaryLightBlue', 0.24),
        borderTop: '1px solid',
        borderBottom: '1px solid',
        borderColor: 'secondaryLightBlue'
      }}>
      <Container
        py={2}
        sx={{
          textAlign: 'center'
        }}>
        {currentRetailer.name} ({currentRetailer.supplierNumber}) proposing on
        behalf of {proposingOnBehalfOf.name} (
        {proposingOnBehalfOf.supplierNumber})
      </Container>
    </Box>
  ) : null;
};

export default ProposingOnBehalfOfBanner;
