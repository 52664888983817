import {
  Box,
  EditDepositProps,
  Link,
  Message,
  NovunaHeading,
  SoftSearchCardWithExpiry,
  TailoringOptions,
  Text
} from 'compass-design';
import { TailoringOption } from 'compass-design/lib/components/TailoringOptions/TailoringOptions';
import { TailoringOptionSelected } from 'hitachi-retail-core/build/services/tailoring/types';
import { isRegulatedApplication } from 'hitachi-retail-core/build/application';
import React, { useState } from 'react';
import { AffordabilityAcceptProps } from 'retailerApp/utils/loanDetails/getAffordabilityAccept';
import { RadioOption } from 'retailerApp/utils/loanDetails/getRadioOptions';
import { routes } from 'routes';
import { useSelector } from 'store';
import { selectApplicationDocument } from 'store/application/selectors';
import { getExpiryDate } from 'utils/expiryDate';
import CreditSearchMessage from '../../CreditSearchMessage';
import EditLoanCardState, {
  EditLoanState
} from '../../EditLoanCardState/EditLoanCardState';
import EditLoanHeader from '../../EditLoanCardState/EditLoanHeader';
import GoodsDetailsModal, {
  OnContinueParams
} from '../../GoodsDetailsModal/GoodsDetailsModal';
import MixingDeck, {
  NewOfferComparisonCardProps
} from '../../MixingDeck/MixingDeck';
import { selectInterestFreeCreditUnregulatedEndDate } from 'store/config/selectors';

export interface DecisionAffordabilityAcceptProps {
  editDeposit?: EditDepositProps;
  softSearchExpiry?: string;
  tailoringExpiryDate?: string;
  affordabilityAcceptProps: AffordabilityAcceptProps;
}

interface ExpiryDateTextProps {
  isSoftSearch: boolean;
  tailoringExpiryDate?: string;
  softSearchExpiry?: string;
}

const getExpiryDateText = ({
  isSoftSearch,
  tailoringExpiryDate,
  softSearchExpiry
}: ExpiryDateTextProps) => {
  if (isSoftSearch && softSearchExpiry) {
    return `expires on ${softSearchExpiry}.`;
  }
  if (tailoringExpiryDate) {
    return `expires on ${getExpiryDate(tailoringExpiryDate)}.`;
  }
  return 'is valid for 30 days.';
};

const IncreaseLoan = ({
  editLoanOffer,
  editLoanState,
  goodsDetailsModalProps,
  editDeposit,
  softSearchExpiry,
  tailoringExpiryDate,
  affordabilityAcceptProps
}: DecisionAffordabilityAcceptProps & EditLoanState) => {
  const {
    loanDetails,
    preSetTailoringOption,
    onConfirmTailoringUpdate,
    onAcceptOriginalOffer
  } = affordabilityAcceptProps;
  const [tailoringOption, setTailoringOption] = useState<TailoringOption>(
    preSetTailoringOption
  );
  const document = useSelector(selectApplicationDocument);
  const interestFreeCreditUnregulatedEndDate = useSelector(
    selectInterestFreeCreditUnregulatedEndDate
  );

  const radioOptions: RadioOption[] =
    (affordabilityAcceptProps.radioOptions as RadioOption[]) ?? [];

  let newOffer: NewOfferComparisonCardProps;
  let selectTailoringOptionRadio: RadioOption | undefined;

  switch (tailoringOption) {
    case 'increaseLoan':
      newOffer = editLoanOffer!;
      selectTailoringOptionRadio = radioOptions.find(
        option => option.tailoringOption === 'increaseLoan'
      );
      if (newOffer.offer.isSoftSearch === undefined) {
        newOffer.offer.isSoftSearch = loanDetails.isSoftSearch;
      }
      break;
    case 'reduceTerm':
      newOffer = loanDetails.reduceTermOffer!;
      selectTailoringOptionRadio = radioOptions.find(
        option => option.tailoringOption === 'reduceTerm'
      );
      break;
  }

  if (
    !!loanDetails.increaseLoanOffer &&
    (!goodsDetailsModalProps || !editLoanOffer || !editLoanState)
  ) {
    return null;
  }

  const { isSoftSearch } = loanDetails;

  let editLoanHeader;
  if (
    tailoringOption === 'increaseLoan' &&
    editLoanState &&
    loanDetails.increaseLoanOffer
  ) {
    editLoanHeader = (
      <EditLoanHeader
        {...editLoanState}
        {...loanDetails.increaseLoanOffer}
        isIncreaseLoan={true}
        minDepositPercentageForProduct={
          loanDetails.loanParameters.minDepositPercentageForProduct
        }
      />
    );
  }

  return (
    <>
      <Box mb={4}>
        <Text mt={2} mb={isSoftSearch ? 4 : 0} sx={{ fontSize: [1, 2, 2] }}>
          The application {isSoftSearch ? `would be` : `has been`} accepted and
          our checks show that the customer has other options that they may want
          to consider.
        </Text>
        {isSoftSearch && <CreditSearchMessage />}
      </Box>

      <NovunaHeading as='h2' mb={3}>
        Update your application
      </NovunaHeading>

      {radioOptions.length > 1 && (
        <TailoringOptions
          data-test-id='tailoring-options'
          radioOptions={
            radioOptions.map(({ tailoringOption, title }) => ({
              tailoringOption,
              text: title
            })) as any[]
          }
          selectedTailoringOption={tailoringOption}
          setTailoringOption={setTailoringOption}
          offerText={newOffer!.optionText}
        />
      )}
      {selectTailoringOptionRadio ? (
        <Message variant='info' mb={4}>
          <NovunaHeading as='h3' mb={1}>
            {selectTailoringOptionRadio.message.title}
          </NovunaHeading>
          {tailoringOption === 'reduceTerm' && (
            <Text>{selectTailoringOptionRadio.message.text}</Text>
          )}
          {tailoringOption === 'increaseLoan' &&
            editLoanState &&
            loanDetails.increaseLoanOffer &&
            editLoanHeader}
        </Message>
      ) : (
        <>{editLoanHeader}</>
      )}
      <MixingDeck
        originalOffer={loanDetails.originalOffer!}
        newOffer={newOffer!}
        buttonOnClick={
          tailoringOption === 'increaseLoan' && goodsDetailsModalProps
            ? () => goodsDetailsModalProps.setIsGoodsDetailsModalOpen(true)
            : () =>
                onConfirmTailoringUpdate({
                  document: {
                    ...document,
                    loanRepayment: {
                      ...document.loanRepayment,
                      instalments: newOffer.offer.repaidOver
                    },
                    loanParameters: {
                      ...document.loanParameters,
                      deposit: newOffer.offer.deposit.toFixed(2),
                      goodsAmount: newOffer.offer.totalCost.toFixed(2)
                    },
                    product: {
                      ...document.product,
                      regulated: isRegulatedApplication(
                        {
                          ...document,
                          loanRepayment: {
                            ...document.loanRepayment,
                            instalments: newOffer.offer.repaidOver
                          }
                        },
                        {
                          interestFreeCreditUnregulatedEndDate
                        }
                      )
                    }
                  },
                  tailoringOptionSelected: TailoringOptionSelected.reduceTerm
                })
        }
        showOfferText={!radioOptions}
        showPlaceholders={
          tailoringOption === 'increaseLoan' &&
          !!editLoanState!.showPlaceholders
        }
        {...(tailoringOption === 'increaseLoan' &&
          editDeposit && { editDeposit })}
        buttonOriginalOfferOnClick={() =>
          onAcceptOriginalOffer({
            document: {
              ...document,
              loanTailoring: {
                ...document.loanTailoring,
                optionSelected: TailoringOptionSelected.rejected
              }
            },
            tailoringOptionSelected: TailoringOptionSelected.rejected
          })
        }
      />
      {goodsDetailsModalProps && (
        <GoodsDetailsModal
          {...goodsDetailsModalProps}
          onContinue={({
            goodsDescription,
            orderReference
          }: OnContinueParams) =>
            onConfirmTailoringUpdate({
              document: {
                ...document,
                loanParameters: {
                  ...document.loanParameters,
                  deposit: editLoanOffer!.offer.deposit.toFixed(2),
                  goodsAmount: editLoanOffer!.offer.totalCost.toFixed(2)
                },
                goodsDescription,
                orderReference
              },
              tailoringOptionSelected: TailoringOptionSelected.increaseLoan
            })
          }
        />
      )}
      {softSearchExpiry && (
        <Box mt={6}>
          <SoftSearchCardWithExpiry softSearchExpiry={softSearchExpiry} />
        </Box>
      )}
      <Box mt={6}>
        <Text mb={2} sx={{ fontSize: 2, fontWeight: 'bold' }}>
          Need a little time to think about it?
        </Text>
        <Text sx={{ fontSize: 2 }}>
          This offer{' '}
          {getExpiryDateText({
            isSoftSearch,
            tailoringExpiryDate,
            softSearchExpiry
          })}
          <Text>
            <Link href={routes.search.index}>Save your progress now</Link> and
            resume the application later.
          </Text>
        </Text>
      </Box>
    </>
  );
};

export { IncreaseLoan as ReduceTerm };
export default EditLoanCardState(IncreaseLoan);
