import NovunaPageLayout from 'applicantApp/components/NovunaPageLayout';
import { Button, NovunaHeading, Text } from 'compass-design';
import React from 'react';
import { ConvertSoftSearchToDecisionProps } from '../../SoftSearchOutcome';
import { SoftSearchMessageOutcome } from '../shared';

const SoftSearchRefer: React.FC<ConvertSoftSearchToDecisionProps> = ({
  convertSoftSearchOutcomeToDecisionFunction
}) => {
  return (
    <NovunaPageLayout
      childrenContainerStyles={{ height: '100%' }}
      backToStoreStatus='soft_search_referred'>
      <SoftSearchMessageOutcome />

      <NovunaHeading
        as='h1'
        data-test-id='mo-softsearch-refer-page-heading'
        mt={4}
        mb={3}>
        Soft search check
      </NovunaHeading>

      <Text>
        Your application must be submitted for a full credit search and reviewed
        in detail before a decision can be made.
      </Text>

      <Text mb={3} mt={3} sx={{ fontWeight: 'bold' }}>
        A full credit search will be performed when you apply, leaving a
        footprint on your credit file that is visible to other lenders.
      </Text>

      <Button
        mt={2}
        isJumbo
        onClick={convertSoftSearchOutcomeToDecisionFunction}
        data-test-id='mo-softsearch-decision-refer-button'>
        Apply now
      </Button>
    </NovunaPageLayout>
  );
};

export default SoftSearchRefer;
