import { createSelector } from 'reselect';
import { RootState } from '..';

const selectAgreement = (state: RootState) => state.agreement;

export const selectAgreementUrl = createSelector(
  selectAgreement,
  agreement => agreement.agreementUrl
);

export const selectAgreementError = createSelector(
  selectAgreement,
  agreement => agreement.error
);
