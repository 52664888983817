import { DefaultSagaParams } from '../sagas';
import { GetRetailerConfigSuccessPayload } from '../store/retailerConfig/actions';

export interface RetailerConfigService {
  getConfig: () => Promise<GetRetailerConfigSuccessPayload>;
}

export const getRetailerConfigService = ({
  apiClient
}: DefaultSagaParams): RetailerConfigService => ({
  getConfig: async (): Promise<GetRetailerConfigSuccessPayload> => {
    const response = await apiClient.get<GetRetailerConfigSuccessPayload>(
      'config'
    );
    return { ...response.data, currentDomain: window.location.hostname };
  }
});
