import { Decimal } from 'hitachi-retail-core/build/utils/decimal';
import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { UserInputWithError } from 'retailerApp/components/EditLoanCardState/EditLoanCardState';

const useSetDeposit = (originalDeposit: Decimal, userInputDelay: number) => {
  const [deposit, setDeposit] = useState<UserInputWithError>({
    value: originalDeposit.toFixed(2),
    error: false
  });

  const [depositUpdated, setDepositUpdated] = useState(false);

  const setDepositDebounce = useCallback(
    debounce((depositValue: string) => {
      setDeposit({
        ...deposit,
        value: depositValue
      });
      setDepositUpdated(true);
    }, userInputDelay),
    []
  );

  return {
    deposit,
    setDepositDebounce,
    depositUpdated,
    setDepositUpdated,
    setDeposit
  };
};

export default useSetDeposit;
