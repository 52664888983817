import {
  Box,
  Button,
  Input,
  Label,
  NovunaHeading,
  Pagination,
  Search as SearchIcon,
  SectionError,
  Table,
  TableRow,
  TertiaryLink,
  TertiaryLinkDirection,
  Text
} from 'compass-design';
import { Field, FieldProps, Form, Formik } from 'formik';
import { Location } from 'history';
import { validationMessages } from 'hitachi-retail-core';
import { formatCurrency } from 'hitachi-retail-core/build/utils/currency';
import { formatStatus } from 'hitachi-retail-core/build/utils/formatStatus';
import React, { useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { routes } from '../../routes';
import {
  PreviousApplication,
  SearchApplicationsActionProps
} from '../../store/previousApplications/actions';
import { formatDate } from '../../utils/formatters';
import { getTruncatedOrderRef } from './getTruncatedOrderRef';
import { initialSearchState, searchReducer } from './hooks/reducers';
import PaginateState, {
  PaginateStateProps,
  getNumberOfPages
} from 'retailerApp/components/Paginate/Paginate';

export interface ApplicationSearchValues {
  query: string;
}

export interface PreviousApplicationsPropsFromState {
  applications: PreviousApplication[];
  totalCount?: number;
  isLoading: boolean;
  location: Location;
  hasNotification: boolean;
}

export interface PreviousApplicationsPropsFromDispatch {
  loadApplications: () => void;
  searchApplications: ({
    term,
    limit,
    offset
  }: SearchApplicationsActionProps) => void;
  resetApplicationStore: () => void;
  clearNotifications: (hasNotifications: boolean) => void;
}

export type PreviousApplicationsProps = PreviousApplicationsPropsFromState &
  PreviousApplicationsPropsFromDispatch &
  PaginateStateProps;

export const NUMBER_OF_APPS_PER_PAGE = 10000;

const PreviousApplications: React.FunctionComponent<PreviousApplicationsProps> = ({
  isLoading,
  applications,
  totalCount: receivedTotalCount,
  loadApplications,
  searchApplications,
  resetApplicationStore,
  location,
  hasNotification,
  clearNotifications,
  currentPage,
  selectPage
}) => {
  const [searchState, searchDispatch] = useReducer(
    searchReducer,
    initialSearchState
  );

  const { query, loading, searchActive } = searchState;
  const totalCount = receivedTotalCount ?? applications.length;
  const totalNumberOfPages = getNumberOfPages({
    totalNumberOfItems: totalCount,
    numberOfItemsPerPage: NUMBER_OF_APPS_PER_PAGE
  });

  useEffect(() => {
    resetApplicationStore();
    if (hasNotification) clearNotifications(true);
    searchDispatch({
      type: 'CLEAR_SEARCH'
    });
  }, [
    loadApplications,
    hasNotification,
    clearNotifications,
    resetApplicationStore,
    location
  ]);

  useEffect(() => {
    if (searchActive && loading) {
      if (query.trim().length === 0) return;

      // Send network request to search for applications
      selectPage(1);
      searchApplications({
        term: query,
        offset: 0,
        limit: NUMBER_OF_APPS_PER_PAGE
      });
      searchDispatch({
        type: 'PERFORMED_SEARCH'
      });
    }
  }, [loading, query, searchActive, selectPage, searchApplications]);

  useEffect(() => {
    if (!searchActive) return;

    // Move browser window to top of table
    window.scrollTo({ top: 0 });
  }, [currentPage, searchActive]);

  useEffect(() => {
    // No longer searching?
    if (searchActive) return;
    loadApplications();
  }, [searchActive, loadApplications]);

  const Paginator = () =>
    searchActive ? (
      <Box mt={5}>
        <Pagination
          currentPage={currentPage}
          selectPage={(selectedPage: number) => {
            selectPage(selectedPage);
            searchApplications({
              term: query,
              offset: (selectedPage - 1) * NUMBER_OF_APPS_PER_PAGE,
              limit: NUMBER_OF_APPS_PER_PAGE
            });
          }}
          totalNumberOfPages={totalNumberOfPages}
        />
      </Box>
    ) : (
      <></>
    );

  return (
    <>
      <Helmet>
        <title>Applications - CreditMaster3</title>
      </Helmet>
      {searchActive && (
        <Box mb={2}>
          <TertiaryLink
            direction={TertiaryLinkDirection.BACKWARDS}
            text='Back to full list'
            onClick={() => {
              searchDispatch({
                type: 'CLEAR_SEARCH'
              });
            }}
          />
        </Box>
      )}
      <NovunaHeading as='h1' id='applications-heading'>
        Applications
      </NovunaHeading>
      {searchActive && (
        <NovunaHeading as='h4' id='applications-count-heading'>
          Showing {totalCount} applications found with search filters
        </NovunaHeading>
      )}

      {isLoading ? (
        <Text mt={4} id='applications-loading'>
          Loading applications&hellip;
        </Text>
      ) : (
        <>
          <ApplicationSearch
            query={query}
            loading={loading}
            onQueryChange={q =>
              searchDispatch({
                type: 'UPDATE_QUERY',
                query: q
              })
            }
            onSearch={() =>
              searchDispatch({
                type: 'PERFORM_SEARCH'
              })
            }
          />
          {applications.length > 0 ? (
            <>
              <Paginator />
              <ApplicationsTable applications={applications} />
              <Paginator />
            </>
          ) : (
            <Text mt={4} id='applications-zeroresults'>
              No applications found.
            </Text>
          )}
        </>
      )}
    </>
  );
};

interface ApplicationSearchProps {
  query: string;
  loading: boolean;
  onQueryChange: (query: string) => void;
  onSearch: (query: string) => void;
}

const ApplicationSearch = ({
  query,
  loading,
  onQueryChange,
  onSearch
}: ApplicationSearchProps) => (
  <Box mt={4}>
    <Formik
      initialValues={{ query }}
      onSubmit={() => onSearch(query)}
      validationSchema={validationSchema}>
      <Form noValidate>
        <Field name='query'>
          {({
            field,
            meta,
            form: { setFieldValue }
          }: FieldProps<string, ApplicationSearchValues>) => (
            <div>
              <Label
                htmlFor='query'
                sx={{
                  width: 'auto',
                  fontSize: 3
                }}>
                Search applications
              </Label>
              {meta.touched && meta.error && (
                <SectionError className='compass-field-error-message'>
                  {meta.error}
                </SectionError>
              )}
              <Box
                className={
                  meta.touched && meta.error
                    ? 'compass-field-error-message'
                    : undefined
                }>
                <Input
                  id='query'
                  type='text'
                  mr={3}
                  sx={{
                    display: 'inline-block',
                    maxWidth: '290px',
                    verticalAlign: 'middle'
                  }}
                  {...field}
                  value={query}
                  onChange={({ target: { value } }) => {
                    onQueryChange(value);
                    setFieldValue('query', value);
                  }}
                  data-test-id='compass-search-app-field'
                />
                <Button
                  mt={[2, null, null, 0]}
                  id='applications-search-button'
                  isJumbo
                  variant='primary'
                  disabled={loading}
                  type='submit'>
                  <SearchIcon />
                  Search
                </Button>
              </Box>
            </div>
          )}
        </Field>
        <Text mt={1}>
          Enter an app ID, status, supplier number, order reference, last name
          or postcode
        </Text>
      </Form>
    </Formik>
  </Box>
);

interface ApplicationsTableProps {
  applications: PreviousApplication[];
}

// eslint-disable-next-line react/display-name
export const ApplicationsTable = React.memo(
  ({ applications }: ApplicationsTableProps) => (
    <Box sx={{ width: '100%', overflowX: 'auto' }}>
      <Table
        mt={5}
        sx={{
          minWidth: '1000px'
        }}
        columnNames={[
          'Application ID',
          'Order ref',
          'Supplier',
          'Name',
          'Postcode',
          'D.O.B',
          'Amount',
          'Status',
          'Updated'
        ]}
        data-test-id='compass-previous-applications-list'>
        {applications.map(
          ({
            id,
            branchId,
            orderReference,
            retailerName,
            applicantName,
            postcode,
            dateOfBirth,
            loanAmount,
            status,
            updated
          }: PreviousApplication) => {
            const baseTestId = `compass-previous-applications-${id}`;

            return (
              <TableRow key={id} data-test-id={baseTestId}>
                <Text data-test-id={`${baseTestId}-id`}>
                  <Link
                    data-test-id={`${baseTestId}-id-link`}
                    to={routes.search.getApplicationById(id)}>
                    {id}
                  </Link>
                </Text>
                <Text data-test-id={`${baseTestId}-order-ref`}>
                  {getTruncatedOrderRef(orderReference) ?? '-'}
                </Text>
                <Text data-test-id={`${baseTestId}-retailer`}>
                  {retailerName && (
                    <>
                      {retailerName} <br />
                    </>
                  )}
                  {branchId}
                </Text>
                <Text data-test-id={`${baseTestId}-name`}>{applicantName}</Text>
                <Text data-test-id={`${baseTestId}-postcode`}>
                  {postcode ?? 'Not entered'}
                </Text>
                <Text data-test-id={`${baseTestId}-date-of-birth`}>
                  {dateOfBirth
                    ? formatDate({ value: dateOfBirth })
                    : 'Not entered'}
                </Text>
                <Text data-test-id={`${baseTestId}-loan-amount`}>
                  {formatCurrency(loanAmount)}
                </Text>
                <Text data-test-id={`${baseTestId}-status`}>
                  {formatStatus(status)}
                </Text>
                <Text data-test-id={`${baseTestId}-updated`}>
                  {formatDate({ value: updated, showTime: true })}
                </Text>
              </TableRow>
            );
          }
        )}
      </Table>
    </Box>
  )
);

const validationSchema = yup.object<ApplicationSearchValues>({
  query: yup
    .string()
    .required(validationMessages.REQUIRED_FIELD)
    .min(2, 'Enter at least 2 characters.')
});

export default PaginateState(PreviousApplications);
