export const APPLICANT_TOKEN_EXCHANGE_REQUEST =
  'APPLICANT_TOKEN_EXCHANGE_REQUEST';
export const APPLICANT_TOKEN_EXCHANGE_SUCCESS =
  'APPLICANT_TOKEN_EXCHANGE_SUCCESS';
export const APPLICANT_TOKEN_EXCHANGE_FAILURE =
  'APPLICANT_TOKEN_EXCHANGE_FAILURE';

export const APPLICANT_AUTHORIZATION_REQUEST =
  'APPLICANT_AUTHORIZATION_REQUEST';
export const APPLICANT_AUTHORIZATION_SUCCESS =
  'APPLICANT_AUTHORIZATION_SUCCESS';
export const APPLICANT_AUTHORIZATION_FAILURE =
  'APPLICANT_AUTHORIZATION_FAILURE';

export const SAVE_PRE_AUTH_PATH = 'SAVE_PRE_AUTH_URL';
export const SAVE_PRE_AUTH_APPLICATION_ID = 'SAVE_PRE_AUTH_APPLICATION_ID';

export const SAVE_CODE_VERIFIER = 'SAVE_CODE_VERIFIER';
