import { RootState } from '..';

export const selectEnhancedUsers = (state: RootState) => state.enhancedUsers;

export const selectEditUser = ({ enhancedUsers: { editUser } }: RootState) =>
  editUser;

export const selectSuccessMessage = ({
  enhancedUsers: { editUser }
}: RootState) => editUser.successMessage;

export const selectSelectedEnhancedUser = ({
  enhancedUsers: { editUser }
}: RootState) => editUser.selectedUser;

export const selectSelectedEnhancedBroker = ({
  enhancedUsers: { selectedBroker }
}: RootState) => selectedBroker;

export const selectSelectedEnhancedHeadOffice = ({
  enhancedUsers: { selectedHeadOffice }
}: RootState) => selectedHeadOffice;

export const selectSelectedEnhancedBranch = ({
  enhancedUsers: { selectedBranch }
}: RootState) => selectedBranch;

export const selectDisableViewBranchWarning = ({
  enhancedUsers: { disableViewBranchWarning }
}: RootState) => disableViewBranchWarning;

export const selectBranchSelectorModalIsOpen = ({
  enhancedUsers: { branchSelectorModalIsOpen }
}: RootState) => branchSelectorModalIsOpen;

export const selectBranchSelectorSearchTerm = ({
  enhancedUsers: { branchSelectorSearchTerm }
}: RootState) => branchSelectorSearchTerm;

export const selectShowBranchSelectionPage = ({
  enhancedUsers: { showBranchSelectionPage }
}: RootState) => showBranchSelectionPage;
