import { all, call, fork, put, take } from 'redux-saga/effects';
import { getType, isOfType } from 'typesafe-actions';
import {
  getDirectAppUserService,
  DirectAppUserService
} from '../../services/directAppUser';
import { fetchRetailer } from '../../store/retailer/actions';
import { directAppUserLogin } from '../../store/user/actions';
import { DirectAppUserLoginAction } from '../../store/user/reducer';
import { DefaultSagaParams } from '../index';

interface DirectAppUserSagaFlowParams {
  directAppUserService: DirectAppUserService;
}

export const getDirectAppLoginFlow = ({
  directAppUserService
}: DirectAppUserSagaFlowParams) =>
  function*() {
    while (true) {
      const action: DirectAppUserLoginAction = yield take(
        getType(directAppUserLogin.request)
      );
      if (isOfType(getType(directAppUserLogin.request), action)) {
        try {
          yield call(directAppUserService.directApplogin, action.payload);
          yield put(
            directAppUserLogin.success({
              username: `Anonymous`
            })
          );
          yield put(fetchRetailer.request());
        } catch (error) {
          yield put(directAppUserLogin.failure(error));
        }
      } else {
        yield put(
          directAppUserLogin.failure(new Error('Direct Apps Login failed'))
        );
      }
    }
  };

export const getDirectAppUserSagaWatcher = ({ apiClient }: DefaultSagaParams) =>
  function*() {
    const directAppUserService = getDirectAppUserService({ apiClient });

    yield all([fork(getDirectAppLoginFlow({ directAppUserService }))]);
  };
