import { Reducer } from 'redux';
import { ResetStoreAction } from 'store';
import { resetStore } from 'store/actions';
import { getType } from 'typesafe-actions';
import {
  setLoanAmend,
  resetLoanAmend,
  SetLoanAmendAction,
  ResetLoanAmendAction
} from './actions';

export interface State {
  isLoanAmend: boolean;
}

export type Action =
  | SetLoanAmendAction
  | ResetLoanAmendAction
  | ResetStoreAction;

export const initialState: State = { isLoanAmend: false };

export const reducer: Reducer<State, Action> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(setLoanAmend): {
      return {
        ...state,
        isLoanAmend: true
      };
    }

    case getType(resetLoanAmend):
    case getType(resetStore): {
      return initialState;
    }

    default:
      return state;
  }
};
