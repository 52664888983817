// PurpleMail.js
import React, { CSSProperties } from 'react';

interface PurpleMailProps {
  width?: number;
  height?: number;
  viewBox?: string;
  style?: CSSProperties;
}

export const PurpleMail: React.FC<PurpleMailProps> = ({
  width = 64,
  height = 64,
  viewBox = '0 0 64 64',
  style
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    style={style}
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M58.153 11.93a5.763 5.763 0 0 1 5.756 5.756v28.628a5.762 5.762 0 0 1-5.756 5.756H5.845A5.762 5.762 0 0 1 .09 46.314V17.686a5.762 5.762 0 0 1 5.755-5.756h52.308zm0 1.92H5.845a3.84 3.84 0 0 0-3.835 3.836v28.628a3.84 3.84 0 0 0 3.835 3.836h52.308a3.84 3.84 0 0 0 3.836-3.836V17.686a3.84 3.84 0 0 0-3.836-3.836zM25.268 32.532a.961.961 0 0 1-.24 1.336L5.866 47.185a.957.957 0 0 1-1.336-.24.96.96 0 0 1 .24-1.337l19.16-13.317a.96.96 0 0 1 1.337.24zm14.799-.24 19.16 13.316a.96.96 0 0 1-1.096 1.577l-19.16-13.317a.96.96 0 0 1 1.096-1.577zm18.965-15.224a.96.96 0 0 1-.286 1.327l-26.15 16.85a.958.958 0 0 1-1.038.002L5.254 18.397a.96.96 0 0 1 1.037-1.617l25.784 16.517 25.63-16.516a.96.96 0 0 1 1.327.287z'
      fill='#7D3CFF'
      fillRule='evenodd'
    />
  </svg>
);
