import { ActionType, createAsyncAction } from 'typesafe-actions';

export const SEND_LOAN_ADVANCE_INFO_REQUEST = 'SEND_LOAN_ADVANCE_INFO_REQUEST';
export const SEND_LOAN_ADVANCE_INFO_SUCCESS = 'SEND_LOAN_ADVANCE_INFO_SUCCESS';
export const SEND_LOAN_ADVANCE_INFO_FAILURE = 'SEND_LOAN_ADVANCE_INFO_FAILURE';

interface SendLoanAdvanceInfoRequestPayload {
  id: string;
}

export const sendLoanAdvanceInfo = createAsyncAction(
  SEND_LOAN_ADVANCE_INFO_REQUEST,
  SEND_LOAN_ADVANCE_INFO_SUCCESS,
  SEND_LOAN_ADVANCE_INFO_FAILURE
)<SendLoanAdvanceInfoRequestPayload, void, Error>();

export type SendLoanAdvanceInfoAction = ActionType<typeof sendLoanAdvanceInfo>;
