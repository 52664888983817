import React, { useEffect } from 'react';
import { State } from '../../../store/signingRedirects/reducer';

export interface SigningCompleteLandingPropsFromState {
  signingRedirects: State;
}

export interface RedirectToSigningCompleteJourneyArguments {
  signingRedirects: State;
}

export interface SigningCompleteLandingPropsFromDispatch {
  signingRedirect: (arg0: RedirectToSigningCompleteJourneyArguments) => void;
}

export type SigningCompleteLandingProps = SigningCompleteLandingPropsFromState &
  SigningCompleteLandingPropsFromDispatch;

const SigningCompleteLanding: React.FC<SigningCompleteLandingProps> = ({
  signingRedirects,
  signingRedirect
}) => {
  useEffect(() => {
    signingRedirect({
      signingRedirects
    });
  }, [signingRedirect, signingRedirects]);
  return null;
};

export default SigningCompleteLanding;
