import classNames from 'classnames';
import {
  Box,
  Button,
  Flex,
  NovunaHeading,
  SoftSearchCard,
  TertiaryLink,
  TertiaryLinkDirection,
  Text
} from 'compass-design';
import initialUiSchema from 'components/applicantDetails/schema/initialUiSchema';
import { Decimal } from 'hitachi-retail-core/build/utils/decimal';
import {
  ApplicantDetails as ApplicantDetailsInterface,
  applicantDetailsSchema
} from 'hitachi-retail-core';
import {
  ApplicationModificationStatus,
  getConditionalModifiableFieldsForRetailJourney
} from 'hitachi-retail-core/build/application/modification';
import { deliveryAddressTypeValue } from 'hitachi-retail-core/build/enums';
import {
  CompassFeature,
  FeatureConfig
} from 'hitachi-retail-core/build/features/features';
import {
  applicantDetailsSchemaConfig,
  createApplicantDetailsValidationContext
} from 'hitachi-retail-core/build/schemas/applicantDetails';
import { IncompleteFinanceApplication } from 'hitachi-retail-core/build/schemas/financeApplication';
import { JSONSchema6 } from 'json-schema';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormValidation, UiSchema } from 'react-jsonschema-form';
import { RouteComponentProps } from 'react-router';
import EmailConfirmationModal from 'retailerApp/pages/ApplicationDetail/components/EmailConfirmationModal';
import IncomeValidationModal from 'retailerApp/pages/ApplicationDetail/components/IncomeValidationModal';
import { Schema } from 'yup';
import { shouldShowApplicationCheck } from '../../containers/progress/Progress';
import { createErrorHandler, validateFormDataHelper } from '../../form/helpers';
import { ApplicationDetail, JsonFormData } from '../form/types';
import NextSteps from './components/nextSteps/NextSteps';
import SectionContent from './components/sectionContent/SectionContent';
import {
  evaluateFormSections,
  getApplicantDetailsFormSchema,
  isSectionEditable,
  onFormDataUpdate,
  onFormSectionSubmit
} from './utils';
import { CompletedSectionMap } from './utils/evaluateFormSections';

export interface ApplicantDetailsPropsFromState {
  application: ApplicationDetail;
  validDetails?: boolean;
  applicationModificationStatus: ApplicationModificationStatus;
  errorMessage?: string;
  processing?: boolean;
  mailorderEnabled: boolean;
  nonMandatoryEmailEnabled: boolean;
  enabledFeatures: FeatureConfig;
  softSearchEnabled: boolean;
}

export interface ApplicantDetailsPropsFromDispatch {
  onBankAccountCheck: (formData: JsonFormData) => void;
  onSectionSubmit: (formData: JsonFormData) => void;
  onCompleteSubmit: (formData: JsonFormData, softSearch: boolean) => void;
  onLoadApplication: (applicationId: string) => void;
}

export interface ApplicantDetailsState {
  formData: IncompleteFinanceApplication;
  formSchema: JSONSchema6;
  uiSchema: UiSchema;
  schemaSections: string[];
  activeSectionKey: string | null;
  completedSections: CompletedSectionMap;
  allSectionsComplete: boolean;
  sectionErrors?: SectionErrors;
  hasConfirmedIncomeValue: boolean;
  hasConfirmedEmail: boolean;
  requireIncomeValueCheck: boolean;
  requireEmailConfirmationCheck: boolean;
}

export interface RouteParams {
  applicationId?: string;
}

export type ApplicantDetailsRouteProps = RouteComponentProps<RouteParams>;

export type ApplicantDetailsProps = ApplicantDetailsPropsFromState &
  ApplicantDetailsPropsFromDispatch &
  ApplicantDetailsRouteProps;

export type ValidationRetailerParams = Pick<
  ApplicantDetailsProps,
  'nonMandatoryEmailEnabled'
>;

export interface SectionErrors {
  [key: string]: string;
}

export interface SchemaConfig {
  [key: string]: Schema<any>;
}

export interface SchemaSectionVars {
  sectionKey: string;
  sectionSchema: JSONSchema6;
  sectionUiSchema: UiSchema;
  sectionComplete: boolean;
  sectionEditable: boolean;
  sectionActive: boolean;
}

class ApplicantDetails extends React.Component<
  ApplicantDetailsProps,
  ApplicantDetailsState
> {
  public static defaultProps = {
    processing: false
  };

  constructor(props: ApplicantDetailsProps) {
    super(props);
    const {
      application,
      mailorderEnabled,
      nonMandatoryEmailEnabled,
      enabledFeatures
    } = props;
    const { document: applicationDocument } = application;

    let formSchema;
    const displayCustomerPresent =
      mailorderEnabled && enabledFeatures.has(CompassFeature.MO_194);
    if (displayCustomerPresent) {
      formSchema = getApplicantDetailsFormSchema(
        applicationDocument.customerPresent
      );
    } else {
      formSchema = getApplicantDetailsFormSchema(true);
    }

    const schemaSections = Object.keys(formSchema.properties || {});
    const uiSchema = { ...initialUiSchema };

    onFormDataUpdate(applicationDocument, uiSchema);

    if (displayCustomerPresent) {
      delete uiSchema.customerPresent['ui:hidden'];
    } else {
      uiSchema.customerPresent['ui:hidden'] = true;
    }

    const {
      completedSections,
      activeSectionKey,
      allSectionsComplete
    } = evaluateFormSections({
      formData: applicationDocument,
      schemaSections,
      schemaConfig: applicantDetailsSchemaConfig,
      sectionErrors: {},
      uiSchema,
      validationRetailerParams: { nonMandatoryEmailEnabled }
    });

    this.state = {
      formData: applicationDocument,
      formSchema,
      uiSchema,
      schemaSections,
      activeSectionKey,
      completedSections,
      allSectionsComplete,
      hasConfirmedIncomeValue: false,
      hasConfirmedEmail: false,
      requireIncomeValueCheck: false,
      requireEmailConfirmationCheck: false
    };
  }

  public componentDidMount() {
    const applicationId = this.props.match.params.applicationId;
    if (applicationId && !this.props.application.document.personalDetails) {
      this.props.onLoadApplication(applicationId);
    }
    if (
      this.props.application.document.bankAccountDetails &&
      this.props.application.status === 'open'
    ) {
      this.props.onBankAccountCheck(this.state.formData as JsonFormData);
      if (!this.props.validDetails) {
        this.setState({
          activeSectionKey: 'bankAccountDetails',
          allSectionsComplete: false
        });
      }
    }
  }

  public componentDidUpdate(prevProps: ApplicantDetailsProps) {
    const {
      schemaSections,
      activeSectionKey,
      sectionErrors = {},
      uiSchema
    } = this.state;
    const {
      application,
      errorMessage,
      processing,
      mailorderEnabled,
      nonMandatoryEmailEnabled,
      enabledFeatures
    } = this.props;
    const { document: applicationDocument } = application;
    const stoppedProcessing = prevProps.processing && !processing;
    const applicationLoaded =
      isEmpty(prevProps.application.document) && !isEmpty(application.document);
    // If page refreshed component may appear before redux store populated.
    if (prevProps.mailorderEnabled !== mailorderEnabled) {
      if (mailorderEnabled && enabledFeatures.has(CompassFeature.MO_194)) {
        this.setState({ sectionErrors });
        delete uiSchema.customerPresent['ui:hidden'];
      } else {
        uiSchema.customerPresent['ui:hidden'] = true;
      }
    }
    if (stoppedProcessing) {
      if (activeSectionKey) {
        if (errorMessage) {
          sectionErrors[activeSectionKey] = errorMessage;
          this.setState({ sectionErrors });
          return;
        }

        // Clear any previous section errors
        delete sectionErrors[activeSectionKey];
      }

      // Re-evaluate form sections as any previous errors will now be resolved
      const {
        activeSectionKey: nextSectionKey,
        completedSections,
        allSectionsComplete
      } = evaluateFormSections({
        formData: applicationDocument,
        schemaSections,
        schemaConfig: applicantDetailsSchemaConfig,
        sectionErrors,
        uiSchema,
        validationRetailerParams: { nonMandatoryEmailEnabled }
      });

      this.setState({
        formData: applicationDocument,
        activeSectionKey: nextSectionKey,
        completedSections,
        allSectionsComplete,
        sectionErrors
      });
    } else if (applicationLoaded) {
      const {
        activeSectionKey,
        completedSections,
        allSectionsComplete
      } = evaluateFormSections({
        formData: applicationDocument,
        schemaSections,
        schemaConfig: applicantDetailsSchemaConfig,
        uiSchema,
        validationRetailerParams: { nonMandatoryEmailEnabled }
      });

      this.setState({
        formData: applicationDocument,
        activeSectionKey,
        completedSections,
        allSectionsComplete
      });
    }
  }

  public validateFormSection = (
    formData: ApplicantDetailsInterface,
    errors: FormValidation
  ): FormValidation => {
    const { nonMandatoryEmailEnabled } = this.props;

    const allErrors = { ...errors };
    validateFormDataHelper(
      formData,
      allErrors,
      applicantDetailsSchema,
      createApplicantDetailsValidationContext({
        applicantDetails: formData,
        nonMandatoryEmailAddress: nonMandatoryEmailEnabled
      })
    );
    const { activeSectionKey } = this.state;
    const sectionErrors = createErrorHandler();
    sectionErrors[activeSectionKey as string] =
      allErrors[activeSectionKey as string];
    return sectionErrors;
  };

  public handleEditSection = (sectionKey: string) => {
    const { schemaSections } = this.state;

    if (schemaSections.includes(sectionKey)) {
      this.setState({
        activeSectionKey: sectionKey,
        allSectionsComplete: false
      });
    }
  };

  public handleFormDataUpdate = (formData: JsonFormData) => {
    const { uiSchema } = this.state;
    const updatedUiSchema = cloneDeep(uiSchema);

    onFormDataUpdate(formData, updatedUiSchema);

    const state = {
      formData,
      uiSchema: updatedUiSchema
    };

    // A customer not being present restricts delivery options to currentAddress.
    const { mailorderEnabled, enabledFeatures } = this.props;
    if (mailorderEnabled && enabledFeatures.has(CompassFeature.MO_194)) {
      const formApplication = formData as IncompleteFinanceApplication;
      const { customerPresent } = formApplication;

      if (!customerPresent) {
        formApplication.deliveryDetails = {
          goodsDelivered: deliveryAddressTypeValue.CurrentAddress
        };
      }
      const formSchema = getApplicantDetailsFormSchema(customerPresent);
      this.setState({ ...state, formSchema });
    } else {
      this.setState(state);
    }
  };

  public updateIncomeValue = (confirmedIncomeValue: number) => {
    const formData = cloneDeep(this.state.formData);

    const { financialDetails } = formData;

    if (financialDetails) {
      financialDetails.incomeValue = confirmedIncomeValue;
    }

    this.setState(
      { requireIncomeValueCheck: false, hasConfirmedIncomeValue: true },
      () => {
        this.handleFormSectionSubmit(formData as JsonFormData);
      }
    );
  };

  public confirmEmail = () => {
    const formData = cloneDeep(this.state.formData);

    const { contactDetails } = formData;

    if (!contactDetails) {
      return;
    }

    this.setState(
      { requireEmailConfirmationCheck: false, hasConfirmedEmail: true },
      () => {
        this.handleFormSectionSubmit(formData as JsonFormData);
      }
    );
  };

  public handleFormSectionSubmit = (formData: JsonFormData) => {
    const { nonMandatoryEmailEnabled } = this.props;

    const {
      formSchema,
      schemaSections,
      uiSchema,
      hasConfirmedIncomeValue,
      hasConfirmedEmail
    } = this.state;

    // activeSectionKey will be a string at this point
    const sectionKey = this.state.activeSectionKey!;

    const { ...sectionErrors } = this.state.sectionErrors ?? {};

    if (sectionErrors[sectionKey]) {
      delete sectionErrors[sectionKey];
    }

    const updatedUiSchema = cloneDeep(uiSchema);
    onFormSectionSubmit(sectionKey, formData, formSchema, updatedUiSchema);

    if (
      sectionKey === 'financialDetails' &&
      incomeValueIsLessThanThreshold(formData, hasConfirmedIncomeValue)
    ) {
      this.setState({ requireIncomeValueCheck: true });
      return;
    } else if (sectionKey === 'bankAccountDetails') {
      this.props.onBankAccountCheck(formData);
      return;
    } else if (
      sectionKey === 'contactDetails' &&
      hasConfirmedEmail === false &&
      !nonMandatoryEmailEnabled
    ) {
      this.setState({ requireEmailConfirmationCheck: true });
      return;
    }

    const {
      activeSectionKey: nextSectionKey,
      completedSections,
      allSectionsComplete
    } = evaluateFormSections({
      formData,
      schemaSections,
      schemaConfig: applicantDetailsSchemaConfig,
      sectionErrors,
      uiSchema: this.state.uiSchema,
      validationRetailerParams: { nonMandatoryEmailEnabled }
    });

    this.setState({
      formData,
      uiSchema: updatedUiSchema,
      activeSectionKey: nextSectionKey,
      completedSections,
      allSectionsComplete,
      sectionErrors,
      hasConfirmedEmail: false
    });

    this.props.onSectionSubmit(formData);
  };

  public handleFormCompleteSubmit = (softSearch: boolean) => {
    const { allSectionsComplete, formData } = this.state;
    if (allSectionsComplete) {
      this.props.onCompleteSubmit(formData as JsonFormData, softSearch);
    }
  };

  public render() {
    const {
      formData,
      formSchema,
      uiSchema,
      activeSectionKey,
      schemaSections,
      completedSections,
      allSectionsComplete,
      requireIncomeValueCheck,
      requireEmailConfirmationCheck,
      sectionErrors = {}
    } = this.state;
    const {
      application,
      applicationModificationStatus,
      processing,
      softSearchEnabled
    } = this.props;
    const schemaProperties = formSchema.properties || {};

    const modifiableFields = getConditionalModifiableFieldsForRetailJourney(
      application.status
    );

    const softSearch = shouldShowApplicationCheck({
      softSearchEnabledForRetailer: softSearchEnabled,
      decisionApplicationStatus: application.status
    });

    const getSchemaSectionVars = (sectionKey: string): SchemaSectionVars => {
      const sectionSchema = schemaProperties[sectionKey] as JSONSchema6;
      const sectionUiSchema = uiSchema[sectionKey];

      const sectionComplete = completedSections[sectionKey] === true;
      const sectionEditable = isSectionEditable(
        sectionKey,
        modifiableFields,
        applicationModificationStatus
      );
      const sectionActive = sectionEditable && activeSectionKey === sectionKey;

      return {
        sectionKey,
        sectionSchema,
        sectionUiSchema,
        sectionComplete,
        sectionEditable,
        sectionActive
      };
    };

    return (
      <>
        <Helmet>
          <title>Customer details - CreditMaster3</title>
        </Helmet>
        <NovunaHeading as='h1' id='applicant-heading' mb={3}>
          Customer details
        </NovunaHeading>
        {schemaSections.map(currentSectionKey => {
          const {
            sectionSchema,
            sectionUiSchema,
            sectionComplete,
            sectionEditable,
            sectionActive
          } = getSchemaSectionVars(currentSectionKey);

          if (sectionUiSchema['ui:hidden'] === true) {
            return null;
          }

          const sectionContentComp = (
            <SectionContent
              sectionKey={currentSectionKey}
              sectionSchema={sectionSchema}
              sectionActive={sectionActive}
              sectionComplete={sectionComplete}
              sectionErrors={sectionErrors}
              formData={formData}
              formSchema={formSchema}
              uiSchema={uiSchema}
              processing={processing === true}
              validateFormSection={this.validateFormSection}
              handleFormDataUpdate={this.handleFormDataUpdate}
              handleFormSectionSubmit={this.handleFormSectionSubmit}
            />
          );

          return (
            <Box
              key={currentSectionKey}
              mb={5}
              className={classNames(['compass-form-section'], {
                'compass-form-section-active': sectionActive,
                'compass-form-section-complete': sectionComplete
              })}
              data-test-id={`compass-applicant-details-${currentSectionKey}`}>
              {sectionSchema.title && (
                <>
                  {sectionUiSchema['ui:displaySectionTitle'] !== false &&
                    sectionActive && (
                      <NovunaHeading
                        as='h2'
                        id='applicant-section-heading-active'
                        mb={2}>
                        {sectionSchema.title}
                      </NovunaHeading>
                    )}
                  {sectionEditable && !sectionActive && sectionComplete && (
                    <Flex
                      sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}>
                      <Text
                        id={`applicant-section-heading-${currentSectionKey}`}
                        sx={{
                          fontWeight: 'bold'
                        }}>
                        {sectionUiSchema['ui:title'] ?? sectionSchema.title}
                      </Text>
                      <TertiaryLink
                        id={`applicant-section-edit-${currentSectionKey}`}
                        text='Edit'
                        direction={TertiaryLinkDirection.FORWARDS}
                        onClick={() =>
                          this.handleEditSection(currentSectionKey)
                        }
                      />
                    </Flex>
                  )}
                </>
              )}
              {sectionContentComp}
            </Box>
          );
        })}
        <IncomeValidationModal
          modalOpen={requireIncomeValueCheck}
          toggleModalOpen={() =>
            this.setState({ requireIncomeValueCheck: false })
          }
          originalIncomeValue={getIncomeValue(formData)}
          onSubmit={this.updateIncomeValue}
        />
        <EmailConfirmationModal
          modalOpen={requireEmailConfirmationCheck}
          toggleModalOpen={() =>
            this.setState({ requireEmailConfirmationCheck: false })
          }
          onSubmit={this.confirmEmail}
          originalEmail={formData.contactDetails?.emailAddress}
        />
        {!allSectionsComplete &&
          applicationModificationStatus !==
            ApplicationModificationStatus.READ_ONLY && (
            <NextSteps
              activeSectionKey={activeSectionKey}
              schemaSections={schemaSections}
              getSchemaSectionVars={getSchemaSectionVars}
            />
          )}
        {allSectionsComplete &&
          applicationModificationStatus !==
            ApplicationModificationStatus.READ_ONLY && (
            <div>
              {softSearch && (
                <Box mb={5}>
                  <SoftSearchCard
                    text='The application will now be checked for approval without leaving a footprint on the customer&rsquo;s credit file.'
                    displayIcon={false}
                  />
                </Box>
              )}
              <Button
                id='applicant-application-submit-button'
                isJumbo
                disabled={processing}
                onClick={() => this.handleFormCompleteSubmit(softSearch)}
                data-test-id='compass-applicant-details-form-submit'>
                {softSearch ? 'Check for approval' : 'Submit application'}
              </Button>
            </div>
          )}
      </>
    );
  }
}

const incomeValueIsLessThanThreshold = (
  formData: JsonFormData,
  hasConfirmedIncomeValue: boolean
) => {
  if (hasConfirmedIncomeValue) {
    return false;
  }

  const incomeValue = new Decimal(getIncomeValue(formData));

  return incomeValue.lessThan(7500);
};

const getIncomeValue = (applicationDocument: IncompleteFinanceApplication) => {
  const { financialDetails } = applicationDocument;
  return financialDetails?.incomeValue ?? 0;
};

export default ApplicantDetails;
