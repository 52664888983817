const maxLength = 12;

export const getTruncatedOrderRef = (orderReference: string | undefined) => {
  if (orderReference && orderReference.length > 12) {
    return `${orderReference.substring(
      0,
      maxLength / 2
    )}...${orderReference.substring(orderReference.length - maxLength / 2)}`;
  }
  return orderReference;
};
