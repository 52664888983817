import {
  Box,
  Button,
  Flex,
  Link,
  TertiaryLink,
  TertiaryLinkDirection
} from 'compass-design';
import { Decimal } from 'hitachi-retail-core/build/utils/decimal';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import React, { RefObject, useState } from 'react';
import { useUserPolicy } from 'retailerApp/hooks/useUserPolicy';
import PartialSettlementsModal from '../PartialSettlementsModal';
import RequestPaymentModal from '../RequestPaymentModal';
import { selectApplicationSigned } from 'store/application/selectors';
import { useSelector } from '../../../../../store';
import { differenceInDays } from 'date-fns';
import PrintAgreementExpiredMessage from '../SummaryPrintAgreement/SummaryPrintAgreement';

export interface ApplicationActionsProps {
  id: string;
  status: ApplicationStatus;
  canResume: boolean;
  canAmend?: boolean;
  canView: boolean;
  canRequestPayment: boolean;
  canCancel: boolean;
  canDownloadSignedAgreement: boolean;
  canPartiallySettle: boolean;
  signedAgreementUrl?: string;
  forceDisplayCancel: boolean;
  canDownloadDeclineLetter: boolean;
  declinedLetterUrl?: string;
  declinedLetterDownloadLink: RefObject<HTMLAnchorElement>;
  loading: boolean;
  loanAmount?: Decimal;
  esatNoteEnabled?: boolean;
  amendApplication: () => void;
  resumeApplication: () => void;
  cancelApplication: () => void;
  requestPartialSettlement: (
    id: string,
    partialSettlementAmount: string
  ) => void;
  requestPayment: (id: string) => void;
  onCreditAgreementDownload: (id: string) => void;
  downloadDeclineLetter: (id: string) => void;
}

const ApplicationActions: React.FC<ApplicationActionsProps> = props => {
  const {
    id,
    status,
    canResume,
    canAmend,
    canView,
    canDownloadSignedAgreement,
    signedAgreementUrl,
    forceDisplayCancel,
    declinedLetterUrl,
    declinedLetterDownloadLink,
    canDownloadDeclineLetter,
    loading,
    amendApplication,
    resumeApplication,
    cancelApplication,
    requestPartialSettlement,
    requestPayment,
    onCreditAgreementDownload,
    downloadDeclineLetter,
    loanAmount,
    esatNoteEnabled
  } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const [requestPaymentModalOpen, setRequestPaymentModalOpen] = useState(false);

  const submitPartialSettlementRequest = (partialSettlementAmount: string) => {
    requestPartialSettlement(id, partialSettlementAmount);
    setModalOpen(false);
  };

  const submitRequestPayment = () => {
    requestPayment(id);
    setRequestPaymentModalOpen(false);
  };

  const userPolicy = useUserPolicy();

  const canCancel = props.canCancel && userPolicy.can('application:cancel');

  const canRequestPayment =
    props.canRequestPayment && userPolicy.can('application:payout');

  const canPartiallySettle =
    props.canPartiallySettle && userPolicy.can('application:partialSettlement');
  const signedAt = useSelector(selectApplicationSigned);

  let isPrintAgreementVisible = true;

  if (signedAt) {
    const signedDate = new Date(signedAt);
    const currentDate = new Date();
    const daysSinceSigned = differenceInDays(currentDate, signedDate);
    isPrintAgreementVisible = daysSinceSigned <= 120;
  }

  return (
    <>
      <Flex sx={{ gap: 4, alignItems: 'center' }}>
        {canResume && status !== ApplicationStatus.signed && (
          <TertiaryLink
            id='application-actions-resume'
            text='Resume'
            direction={TertiaryLinkDirection.FORWARDS}
            onClick={() => resumeApplication()}
            data-test-id='compass-resume-application'
          />
        )}
        {canAmend && canResume && (
          <TertiaryLink
            id='application-actions-amend'
            text='Amend'
            direction={TertiaryLinkDirection.FORWARDS}
            onClick={() => amendApplication()}
            data-test-id='compass-amend-application'
          />
        )}
        {canView && (
          <TertiaryLink
            id='application-actions-view'
            text='View'
            direction={TertiaryLinkDirection.FORWARDS}
            onClick={() => resumeApplication()}
            data-test-id='compass-view-application'
          />
        )}
        {canDownloadSignedAgreement &&
          signedAgreementUrl &&
          isPrintAgreementVisible && (
            <TertiaryLink
              id='application-actions-downloadagreement'
              text='Print credit agreement'
              direction={TertiaryLinkDirection.FORWARDS}
              onClick={() => {
                window.open(signedAgreementUrl, '_blank');
                onCreditAgreementDownload(id);
              }}
              data-test-id='compass-download-signed-agreement'
            />
          )}
        {canRequestPayment && (
          <TertiaryLink
            id='application-actions-requestpayment'
            text='Request payment'
            direction={TertiaryLinkDirection.FORWARDS}
            onClick={() => setRequestPaymentModalOpen(true)}
            data-test-id='compass-request-payment'
          />
        )}

        {canPartiallySettle && loanAmount && (
          <TertiaryLink
            id='application-partial-settle'
            text='Request partial settlement'
            direction={TertiaryLinkDirection.FORWARDS}
            onClick={() => setModalOpen(true)}
            data-test-id='compass-request-partial-settlement'
          />
        )}

        {canDownloadDeclineLetter && (
          <>
            <TertiaryLink
              id='application-actions-downloaddeclineletter'
              text='Download letter'
              direction={TertiaryLinkDirection.FORWARDS}
              onClick={() => downloadDeclineLetter(id)}
              data-test-id='compass-download-decline-letter'
            />
            <Link
              sx={{ display: 'none' }}
              data-test-id='compass-download-declined-letter'
              ref={declinedLetterDownloadLink}
              href={declinedLetterUrl}
              download='declined.pdf'>
              download
            </Link>
          </>
        )}
        {(forceDisplayCancel || canCancel) && (
          <Box sx={{ marginLeft: 'auto' }}>
            <Button
              id='application-actions-cancel'
              variant='secondaryDestructive'
              disabled={loading || !canCancel}
              onClick={() => cancelApplication()}
              data-test-id='compass-cancel-application'>
              Cancel application
            </Button>
          </Box>
        )}
      </Flex>
      {!isPrintAgreementVisible && <PrintAgreementExpiredMessage />}
      {loanAmount && (
        <PartialSettlementsModal
          modalOpen={modalOpen}
          toggleModalOpen={() => setModalOpen(false)}
          partialSettlementModalAction={submitPartialSettlementRequest}
          loanAmount={loanAmount}
        />
      )}
      <RequestPaymentModal
        modalOpen={requestPaymentModalOpen}
        toggleModalOpen={() => setRequestPaymentModalOpen(false)}
        onSubmit={submitRequestPayment}
        esatNoteEnabled={esatNoteEnabled}
      />
    </>
  );
};

export default ApplicationActions;
