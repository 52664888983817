import { Box, NovunaCross } from 'compass-design';
import React, { FormEvent, useCallback, useEffect, useRef } from 'react';
import { IconButton } from 'theme-ui';

export interface ModalPlaceholderPropsFromState {
  body?: React.ReactElement<any>;
  onCloseFromAction?: () => void;
}

export interface ModalPlaceholderPropsFromDispatch {
  onClose: () => void;
}

export type ModalPlaceholderProps = ModalPlaceholderPropsFromState &
  ModalPlaceholderPropsFromDispatch;

export const ModalPlaceholder: React.FC<ModalPlaceholderProps> = props => {
  const wrapper = useRef<HTMLDivElement>(null);
  const { onClose, onCloseFromAction } = props;

  const handleClick = useCallback(
    (e: Event) => {
      const target = e.target as HTMLElement;

      if (
        wrapper &&
        wrapper.current &&
        wrapper.current.className === target.className
      ) {
        return onCloseFromAction ? onCloseFromAction() : onClose();
      }
    },
    [onClose, onCloseFromAction]
  );

  const handleCloseModalClick = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      return onCloseFromAction ? onCloseFromAction() : onClose();
    },
    [onClose, onCloseFromAction]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);

    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    };
  }, [handleClick]);

  if (!props.body) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10,
        overflowY: 'auto',
        transition: 'opacity 0.15s linear',
        backgroundColor: 'rgba(0,0,0,0.3)'
      }}
      ref={wrapper}>
      <Box
        sx={{
          position: 'relative',
          width: '606px',
          marginX: 'auto',
          marginTop: 6,
          padding: 6,
          borderRadius: 'panel',
          backgroundColor: 'white'
        }}
        data-test-id='compass-modal-dialog'>
        <IconButton
          aria-label='close modal'
          sx={{
            position: 'absolute',
            top: '8px',
            right: '8px',
            width: '48px',
            height: '48px',
            padding: '12px',
            cursor: 'pointer'
          }}
          data-test-id='compass-modal-close'
          onClick={handleCloseModalClick}>
          <NovunaCross />
        </IconButton>
        {props.body}
      </Box>
    </Box>
  );
};
