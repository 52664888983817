import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../store';
import Config, {
  ConfigPropsFromDispatch,
  ConfigPropsFromState
} from '../../components/common/Config';
import { fetchConfig } from '../../store/config/actions';

export const mapStateToProps = ({
  config: { status }
}: RootState): ConfigPropsFromState => {
  return {
    configStatus: status
  };
};

export const mapDispatchToProps = (
  dispatch: Dispatch
): ConfigPropsFromDispatch => ({
  fetchConfig: () => {
    dispatch(fetchConfig.request());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Config);
