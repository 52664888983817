import React, { memo, useEffect } from 'react';
import { useSelector } from 'store';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../../routes';
import ApplicantApplication from '../ApplicantApplication';
import { selectApplicantAccessToken } from '../../store/applicantAuth/reducer';
import { ApplicationIdRouteProps } from 'applicantApp//components/ApplicationRouteProps/ApplicationRouteProps';
import { useAuthorization } from 'applicantApp/components/hooks/useAuthorization';
import Loading from 'applicantApp/components/Loading';

export type ApplicantLoggedInAppProps = ApplicationIdRouteProps;

const ApplicantLoggedInAppBase: React.FunctionComponent<ApplicationIdRouteProps> = ({
  match
}) => {
  const id = match?.params.id;
  const isLoggedIn = !!useSelector(selectApplicantAccessToken);

  const authorization = useAuthorization({ id });

  useEffect(() => {
    if (isLoggedIn) {
      return;
    }

    authorization();
  }, [id, isLoggedIn, authorization]);

  if (!isLoggedIn) {
    return <Loading />;
  }

  return (
    <div data-test-id='compass-applicant-app-logged-in-routes'>
      <Switch>
        <Route
          path={routes.mailOrder.application.index}
          component={ApplicantApplication}
        />
      </Switch>
    </div>
  );
};

export default memo(ApplicantLoggedInAppBase);
