import { LoanAttribute } from 'compass-design';
import { Decimal } from 'hitachi-retail-core/build/utils/decimal';
import {
  getReduceTermOptionText,
  getReduceTermText,
  LoanComparisonCardPreStringValues
} from 'retailerApp/components/MixingDeck/getMixingDeckData';
import { NewOfferComparisonCardProps } from 'retailerApp/components/MixingDeck/MixingDeck';
import { EditTermOffer } from 'hitachi-retail-core/build/services/tailoring/types';
import { DeepPartial } from 'hitachi-retail-core/build/utils/deepPartial';
import {
  getInterestPayable,
  getTotalAmountPayable
} from 'hitachi-retail-core/build/finance';

export interface GetReduceTermDetailsParams {
  originalOffer: Omit<LoanComparisonCardPreStringValues, 'updatedItem'>;
  reduceTermOffer: DeepPartial<EditTermOffer>;
}

const getReduceTermDetails = ({
  originalOffer,
  reduceTermOffer
}: GetReduceTermDetailsParams): NewOfferComparisonCardProps | undefined => {
  const { offer, offered } = reduceTermOffer;

  if (!offer || !offered) {
    return;
  }

  const { minimumTerm, monthlyRepayment, totalRepaymentAmount } = offer;

  if (!minimumTerm || !monthlyRepayment || !totalRepaymentAmount) {
    return;
  }

  const totalAmountPayable = getTotalAmountPayable({
    instalments: minimumTerm!,
    deposit: originalOffer.deposit,
    repaymentAmount: new Decimal(monthlyRepayment!)
  });

  const interestPayable = getInterestPayable({
    goodsAmount: originalOffer.totalCost,
    totalAmountPayable: totalAmountPayable
  });

  return {
    offer: {
      ...originalOffer,
      repaidOver: minimumTerm,
      monthlyRepayment: new Decimal(monthlyRepayment),
      totalRepayable: new Decimal(totalRepaymentAmount),
      totalAmountPayable,
      updatedItem: LoanAttribute.reduceRepaidOver,
      interestPayable
    },
    offerText: getReduceTermText({
      minimumTerm: minimumTerm,
      currentTerm: originalOffer.repaidOver
    }),
    optionText: getReduceTermOptionText({
      minimumTerm: minimumTerm,
      currentTerm: originalOffer.repaidOver
    })
  };
};

export default getReduceTermDetails;
