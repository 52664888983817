import {
  Button,
  Container,
  Text,
  InlineCheckbox,
  ReactModal
} from 'compass-design';
import React, { useState, useCallback } from 'react';

export const TailoringConfirmationModal = ({
  show,
  onConfirm,
  onSetIsOpen
}: {
  show: boolean;
  onConfirm: () => void;
  onSetIsOpen: (isOpen: boolean) => void;
}) => {
  const [isChecked, setChecked] = useState(false);
  const onCheckboxChange = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) =>
      setChecked(target.checked),
    []
  );

  return (
    <ReactModal
      title='Updated application'
      size='medium'
      isOpen={show}
      setIsOpen={onSetIsOpen}>
      <Container>
        <Text mb={4}>
          Please take a moment to confirm that you are happy for the details of
          your application to be updated.
        </Text>
        <InlineCheckbox
          checked={isChecked}
          onChange={onCheckboxChange}
          data-test-id='mo-confirm-update-application-checkbox'>
          <Text sx={{ fontSize: '20px', lineHeight: '24px' }}>
            I wish to proceed with the updated application
          </Text>
        </InlineCheckbox>
        <Button
          mt={4}
          isJumbo
          data-test-id='mo-confirm-update-application-button'
          onClick={onConfirm}
          disabled={!isChecked}>
          Continue
        </Button>
      </Container>
    </ReactModal>
  );
};
