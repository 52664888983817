import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { Dispatch, Store } from 'redux';
import { selectApplicantAccessToken } from '../applicantApp/store/applicantAuth/reducer';
import { getF2fClient, getMailOrderClient } from './apiHelpers';

export interface GetApiClientParams {
  isMailOrderApp: boolean;
  store: Store;
  dispatch?: Dispatch;
}

export interface GetApplicantAppApiClientParams {
  store: Store;
}

export const requestAttachAccessTokenFromTheStore = ({
  request,
  store
}: {
  request: AxiosRequestConfig;
} & GetApplicantAppApiClientParams): AxiosRequestConfig => {
  const state = store.getState();
  const accessToken = selectApplicantAccessToken(state);

  if (accessToken) {
    if (!request.headers) {
      request.headers = {};
    }

    request.headers.Authorization = `Bearer ${accessToken}`;
  }

  return request;
};

export const getApiClient = ({
  isMailOrderApp,
  store,
  dispatch = store.dispatch
}: GetApiClientParams): AxiosInstance => {
  const apiClient = axios.create({
    baseURL: '/api/',
    timeout: 30000
  });

  if (isMailOrderApp) {
    return getMailOrderClient({ dispatch, apiClient, store });
  }

  return getF2fClient({ dispatch, apiClient });
};
