import { IncompleteFinanceApplication } from 'hitachi-retail-core/build/schemas/financeApplication';
import { UiSchema } from 'react-jsonschema-form';
import financialDetailsUpdate from './financialDetailsUpdate';

const formDataUpdateFunctions = {
  financialDetails: financialDetailsUpdate
};

const onFormDataUpdate = (
  formData: IncompleteFinanceApplication,
  uiSchema: UiSchema
) =>
  Object.keys(formDataUpdateFunctions).forEach(sectionKey => {
    if (typeof formDataUpdateFunctions[sectionKey] === 'function') {
      formDataUpdateFunctions[sectionKey](formData, uiSchema);
    }
  });

export default onFormDataUpdate;
