import { ApplicationSnapshotService } from 'services/applicationSnapshotService';

const getMockApplicationSnapshotService = (): ApplicationSnapshotService => ({
  createSnapshot: async (...args: any[]) => {
    console.log('Mock invoked: createSnapshot with args:', ...args);
  },
  revertApplication: async (...args: any[]) => {
    console.log('Mock invoked: revertApplication with args:', ...args);
  }
});

export default getMockApplicationSnapshotService;
