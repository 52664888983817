import { AxiosInstance } from 'axios';

export interface DepositPaidResponse {
  status: 'Paid';
}

export interface DepositFailedResponse {
  status: 'Failure';
  retryPayment: boolean;
}

export interface DepositErrorResponse {
  status: 'Error';
}

export type DepositCompleteResponse =
  | DepositPaidResponse
  | DepositErrorResponse
  | DepositFailedResponse;

export const getOnlineDepositsService = (apiClient: AxiosInstance) => {
  return {
    initiateDeposit: async (applicationId: string): Promise<string> => {
      // We need to send this information to our backend as it cannot know what the origin of this application is
      // Unless we can be sure the Origin header is set?
      const hostOrigin = new URL(window.location.href).origin;

      // We should receive a URL from our backend that we can use
      const response = await apiClient.post(
        `/mo/application/${applicationId}/deposit`,
        {
          hostOrigin
        }
      );

      if (response.data?.hppUrl) {
        return response.data.hppUrl;
      }

      throw new Error('Did not receive a payment URL from service');
    },

    depositPaymentComplete: async (
      applicationId: string,
      event?: any
    ): Promise<DepositCompleteResponse> => {
      const response = await apiClient.post(
        `/mo/application/${applicationId}/deposit/complete`,
        event
      );

      return {
        status: response.data.status,
        retryPayment: response.data.retryPayment
      };
    }
  };
};

export type OnlineDepositsService = ReturnType<typeof getOnlineDepositsService>;
