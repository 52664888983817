import { Box, Button, NovunaHeading, Text } from 'compass-design';
import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';
import React from 'react';
import BranchLocationGenerator from './BranchLocationGenerator';

interface BranchListProps {
  branches: Retailer[];
  selectedBranch?: Retailer;
  setSelectedBranch: React.Dispatch<React.SetStateAction<Retailer | undefined>>;
  selectBranch: (selectedBranch?: Retailer) => void;
}

const BranchList: React.FC<BranchListProps> = ({
  branches,
  selectedBranch,
  setSelectedBranch,
  selectBranch
}) => {
  const sortedBranches = branches.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  );

  if (branches.length === 0) {
    return (
      <NovunaHeading as='h2' mt={5}>
        No results found
      </NovunaHeading>
    );
  }

  const gridColumns = '1fr 1fr 1fr 1fr';

  return (
    <Box>
      <Text mt={6} sx={{ fontSize: 1, color: 'monochrome.3' }}>
        Showing {branches.length} result{branches.length > 1 && 's'}
      </Text>
      <Box
        mt={4}
        sx={{
          display: 'grid',
          gridTemplateColumns: gridColumns,
          borderBottom: '1px solid black'
        }}>
        <Text>Supplier number</Text>
        <Text>Trading name</Text>
        <Text>Location</Text>
      </Box>
      {sortedBranches.map(retailer => {
        const isSelected =
          selectedBranch?.supplierNumber === retailer.supplierNumber;
        return (
          <Box
            key={retailer.supplierNumber}
            data-test-id={`branch-row-${retailer.supplierNumber}`}
            onClick={() => setSelectedBranch(retailer)}
            p={2}
            sx={{
              display: 'grid',
              gridTemplateColumns: gridColumns,
              backgroundColor: isSelected
                ? 'rgba(133, 110, 145, 0.1)'
                : undefined,
              borderBottom: '2px solid',
              borderBottomColor: 'monochrome.5',
              alignItems: 'start',
              fontSize: '2',
              cursor: 'pointer',
              '&:hover': {
                bg: 'rgba(133, 110, 145, 0.1)'
              }
            }}>
            <Text>{retailer.supplierNumber}</Text>
            <Text>{retailer.name}</Text>
            <Text data-test-id={`location-${retailer.supplierNumber}`}>
              {BranchLocationGenerator(retailer.address)}
            </Text>
            {retailer.isHeadOffice && (
              <Text
                data-test-id={`head-office-flag-${retailer.supplierNumber}`}
                sx={{
                  fontSize: 0,
                  fontWeight: 'bold',
                  textAlign: 'right',
                  paddingTop: '0.5rem'
                }}>
                Head office
              </Text>
            )}
          </Box>
        );
      })}
      {branches.length > 0 && (
        <Button
          isJumbo
          mt={6}
          onClick={() => selectBranch(selectedBranch)}
          disabled={!selectedBranch}>
          Continue
        </Button>
      )}
    </Box>
  );
};

export default BranchList;
