import { NovunaTwoColumnLayout } from 'compass-design';
import { QuoteSummary } from 'components/QuoteSummary';
import React from 'react';
import { Status } from '../ExternalRedirectButtons/ExternalRedirectButtons';

const NovunaPageLayout: React.FC<{
  pageTop?: React.ReactNode;
  backToStoreStatus?: Status;
  childrenContainerStyles?: { [cssProperty: string]: string | number };
}> = ({
  children,
  pageTop,
  backToStoreStatus,
  childrenContainerStyles = {}
}) => (
  <NovunaTwoColumnLayout
    childrenContainerStyles={childrenContainerStyles}
    pageTop={pageTop}
    sidebar={<QuoteSummary backToStoreStatus={backToStoreStatus} />}>
    {children}
  </NovunaTwoColumnLayout>
);

export default NovunaPageLayout;
