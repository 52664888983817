import React from 'react';
import { Text } from 'compass-design';

export const creditSearchMessageText =
  'A full credit search will be performed upon applying leaving a footprint on the customer’s credit file.';

const CreditSearchMessage = () => (
  <Text mb={4} sx={{ fontSize: 2, fontWeight: 'bold' }}>
    {creditSearchMessageText}
  </Text>
);

export default CreditSearchMessage;
