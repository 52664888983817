import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { RootState } from '../../../store';
import { ResetStoreAction, resetStore } from '../../../store/actions';
import { sendPrecontractInfo } from './actions';

export interface State {
  emailSentAutomatically: boolean;
}

export type SendPrecontractInfoAction =
  | ActionType<typeof sendPrecontractInfo>
  | ResetStoreAction;

const initialState: State = { emailSentAutomatically: false };

export const reducer: Reducer<State, SendPrecontractInfoAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(sendPrecontractInfo.success): {
      return { ...state, emailSentAutomatically: true };
    }

    case getType(resetStore): {
      return initialState;
    }
  }

  return state;
};

export const selectEmailSentAutomatically = (state: RootState) =>
  state.sendPrecontractInfo.emailSentAutomatically;
