import { ProductConfig } from 'hitachi-retail-core/build/api/productConfig';
import { DefaultSagaParams } from '../sagas';

export interface ProductService {
  getProductsForRetailer: (supplierNumber?: string) => Promise<ProductConfig[]>;
}

export const getProductService = ({
  apiClient
}: DefaultSagaParams): ProductService => ({
  getProductsForRetailer: async supplierNumber => {
    let response;

    try {
      let url: string;
      if (supplierNumber) {
        url = `protected/product?branchId=${supplierNumber}`;
      } else {
        url = 'protected/product';
      }
      response = await apiClient.get<ProductConfig[]>(url);
    } catch (e) {
      throw new Error(`Failed to fetch products for retailer: ${e.toString()}`);
    }

    const products = response.data;

    if (!products || !products.length) {
      throw new Error('No retailer products found in response');
    }

    return products;
  }
});
