import { Address, normalisePostcode } from 'hitachi-retail-core';

export const getBlankAddress = (): Partial<Address> => ({
  flatName: undefined,
  houseName: undefined,
  houseNumber: undefined,
  street: undefined,
  town: undefined,
  county: undefined,
  postCode: undefined
});

type Optional<T> = T | null | undefined;

export const getAddressLines = (address: Address): string[] => {
  const populated = (field: Optional<string>) => field && field.length > 0;

  const addressLine = (fields: Optional<string>[]): string =>
    fields.some(populated) ? fields.filter(populated).join(' ') : '';

  const { flatName, houseName, houseNumber, street, town, postCode } = address;
  return [
    addressLine([flatName, houseName]),
    addressLine([houseNumber, street]),
    addressLine([town]),
    postCode ? normalisePostcode(postCode) : ''
  ].filter(line => line);
};

export const isBlankAddress = (address?: Address): boolean => {
  return !address || Object.keys(address).every(property => !address[property]);
};
