import React from 'react';
import { Table, TableRow, Text } from 'compass-design';
import { useSelector } from 'react-redux';
import { selectPreviousApplicationAuditHistory } from 'store/application/selectors';
import { applicationHistoryFormatter } from 'hitachi-retail-core/build/utils/applicationHistoryFormatter';

const ApplicationHistory: React.FC = () => {
  const applicationHistory = useSelector(selectPreviousApplicationAuditHistory);

  const formattedApplicationHistoryData = applicationHistoryFormatter({
    history: applicationHistory,
    showInternalNotes: false,
    showInternalErrors: false
  });

  return (
    <>
      <Table
        columnNames={['Date', 'Event', 'User', 'Notes']}
        emptyTableMessage='No application history yet'>
        {formattedApplicationHistoryData.map(formattedData => (
          <div key={formattedData.date}>
            <Text as='h3' ml={3} mt={3}>
              {formattedData.date}
            </Text>
            {formattedData.data.map(data => (
              <div key={`history-row-${data.id}`}>
                <TableRow>
                  <Text>{data.time}</Text>
                  <Text
                    sx={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}>
                    <b>{data.eventRow1}</b>
                    {data.eventRow2}
                  </Text>
                  <Text>{data.user}</Text>
                  <Text>{data.notes}</Text>
                </TableRow>
              </div>
            ))}
          </div>
        ))}
      </Table>
    </>
  );
};

export default ApplicationHistory;
