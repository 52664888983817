import { stringify } from 'querystring';
import { DefaultSagaParams } from '../../sagas';

interface TokenExchangeInput {
  tokenEndpoint: string;
  code: string;
  codeVerifier?: string;
}
export interface OpenIDConfiguration {
  token_endpoint: string;
  authorization_endpoint: string;
}

export interface AuthService {
  tokenExchange: (input: TokenExchangeInput) => Promise<string>;
  getAuthorizationEndpoint: (baseUrl: string) => Promise<OpenIDConfiguration>;
}

export const getAuthService = ({
  apiClient
}: DefaultSagaParams): AuthService => ({
  tokenExchange: async ({
    tokenEndpoint,
    code,
    codeVerifier
  }: TokenExchangeInput) => {
    const data = {
      grant_type: 'authorization_code',
      code,
      code_verifier: codeVerifier
    };
    const result = await apiClient.post(tokenEndpoint, stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    return result.data.access_token;
  },
  getAuthorizationEndpoint: async (baseUrl: string) => {
    const config = await apiClient.get(
      baseUrl + '/.well-known/openid-configuration'
    );

    return config.data as OpenIDConfiguration;
  }
});
