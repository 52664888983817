import { Box, Button, Link, Text } from 'compass-design';
import React, { RefObject } from 'react';
import { BoxProps } from 'theme-ui';

export interface DownloadLetterProps extends BoxProps {
  applicationId: string;
  fileDownloadLink: RefObject<HTMLAnchorElement>;
  pdfUrl?: string;
  declinedPdfName: string;
  onDownloadDeclinedLetter: (applicationId: string) => void;
  isSoftSearch?: boolean;
}

const DownloadLetter = ({
  applicationId,
  fileDownloadLink,
  pdfUrl,
  declinedPdfName,
  onDownloadDeclinedLetter,
  isSoftSearch,
  ...boxProps
}: DownloadLetterProps) => (
  <Box {...boxProps}>
    {isSoftSearch ? (
      <>
        <Text>Unfortunately, this application would be declined.</Text>
        <Text mt={2}>
          A letter that gives you more information on your application and how
          we came to our decision has been sent to you by email.
        </Text>
      </>
    ) : (
      <Text>
        Further information regarding our lending decision can be found in the
        letter which you can download here.
      </Text>
    )}
    <Button
      mt={4}
      isJumbo
      onClick={() => onDownloadDeclinedLetter(applicationId)}
      data-test-id='compass-download-decline'>
      Download letter
    </Button>
    <Link
      sx={{ display: 'none' }}
      data-test-id='compass-download-declined-letter'
      ref={fileDownloadLink}
      href={pdfUrl}
      download={declinedPdfName}>
      download
    </Link>
  </Box>
);

export default DownloadLetter;
