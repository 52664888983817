import {
  Box,
  Flex,
  InputWrapper,
  List,
  ListItem,
  Message,
  NovunaHeading,
  Text,
  TextField
} from 'compass-design';
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikErrors,
  FormikProps,
  validateYupSchema,
  yupToFormErrors
} from 'formik';
import { bankAccountDetailsSchema } from 'hitachi-retail-core/build/schemas/bankDetails';
import React, { useContext } from 'react';
import * as yup from 'yup';
import { getNavigationButtons } from '../navigationButtons';
import { labelPropsFromMeta } from 'utils/labelPropsFromMeta';
import { Step, StepComponentProps } from '../MultiStepForm';
import { DetailsCaptureValues } from '../schema';
import { ContextServices, ServicesContext } from 'shared/App/App';
import { BankAccountCheckService } from 'services/bankCheck';
import { getTone } from 'utils/getTone';
import { HitachiPhoneNumber } from 'applicantApp/pages/LoanAdvanceInformation/LoanAdvanceInformation';

const bankDetailsSchema = yup.object({
  bankAccountDetails: bankAccountDetailsSchema
});

const checkBankDetails = async (
  sortCode: string,
  accountNumber: string,
  bankAccountCheckService: BankAccountCheckService
) => {
  try {
    const { valid } = await bankAccountCheckService.validateBankDetails({
      sortCode,
      accountNumber
    });

    return valid;
  } catch (e) {
    // TODO: Handle timeout better than displaying the bank check error.
    return false;
  }
};

const validate = async (
  values: DetailsCaptureValues,
  services: ContextServices
): Promise<FormikErrors<DetailsCaptureValues>> => {
  try {
    await validateYupSchema<DetailsCaptureValues>(values, bankDetailsSchema);
  } catch (err) {
    return yupToFormErrors(err);
  }
  const bankAccountCheckService = services.bankAccountCheckService;
  const { sortCode, accountNumber } = values.bankAccountDetails;

  if (
    bankAccountCheckService &&
    !(await checkBankDetails(sortCode, accountNumber, bankAccountCheckService))
  ) {
    return { bankAccountDetails: { sortCode: '', accountNumber: '' } };
  }

  return {};
};

const Description: React.FC = () => (
  <>
    <Text sx={{ fontSize: 2 }}>
      These bank details will be used by Novuna Personal Finance for our ID and
      security checks, and if your application is successful they will also be
      used for Direct Debit Payment Instruction.
    </Text>
    <List fontSize='2'>
      <ListItem>
        The bank details provided must be for a UK bank or building society that
        accepts Direct Debits
      </ListItem>
      <ListItem>You must be the account holder </ListItem>
      <ListItem>
        You must be authorised to make a new Direct Debit instruction without
        permission from additional account holders
      </ListItem>
    </List>
    <Text sx={{ fontSize: 2 }}>
      If you need any assistance you can call us on <HitachiPhoneNumber />.
    </Text>
  </>
);

const BankNotFound: React.FC = () => (
  <Box mt={4}>
    <Message variant='error'>
      <NovunaHeading as='h3' mb={1}>
        Bank not found
      </NovunaHeading>
      <Text>
        These bank details cannot be validated. Please check the details are
        correct, or try a different account.
      </Text>
    </Message>
  </Box>
);

const BankDetails: React.FC<StepComponentProps<DetailsCaptureValues>> = ({
  initialValues,
  returnUrl,
  supplierOrderReference,
  applicationId,
  multiStepSubmit
}) => {
  const services = useContext(ServicesContext);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, formikBag) => {
        multiStepSubmit(values, formikBag);
      }}
      validate={values => validate(values as any, services)}>
      {(formikBag: FormikProps<DetailsCaptureValues>) => (
        <Form>
          <Field name={`bankAccountDetails.name`}>
            {({ field, meta }: FieldProps<string, DetailsCaptureValues>) => (
              <Box mt={4}>
                <InputWrapper
                  id='bankAccountDetails.name'
                  label="Account holder's name"
                  description='Joint accounts can be used'
                  error={meta.error}
                  {...labelPropsFromMeta({ meta }, 'label')}>
                  <TextField
                    {...labelPropsFromMeta({ meta }, 'input')}
                    type='text'
                    {...field}
                    id='bankAccountDetails.name'
                    tone={getTone(meta)}
                  />
                </InputWrapper>
              </Box>
            )}
          </Field>

          {// Bank check failure sets errors on these fields to be empty.
          // Don't want error messages on the sort code & acc no fields,
          // just want them to be highlighted in red

          formikBag.errors.bankAccountDetails?.sortCode === '' &&
            formikBag.errors.bankAccountDetails?.accountNumber === '' && (
              <BankNotFound />
            )}
          <Field name={`bankAccountDetails.sortCode`}>
            {({ field, meta }: FieldProps<string, DetailsCaptureValues>) => (
              <Box mt={4}>
                <InputWrapper
                  id='bankAccountDetails.sortCode'
                  label='Sort code'
                  error={meta.error}
                  {...labelPropsFromMeta({ meta }, 'label')}>
                  <Flex>
                    <TextField
                      {...labelPropsFromMeta({ meta }, 'input')}
                      type='text'
                      {...field}
                      id='bankAccountDetails.sortCode'
                      flexSx={{ flex: '1' }}
                      short
                      tone={getTone(meta)}
                    />
                  </Flex>
                </InputWrapper>
              </Box>
            )}
          </Field>

          <Field name={`bankAccountDetails.accountNumber`}>
            {({ field, meta }: FieldProps<string, DetailsCaptureValues>) => (
              <Box mt={4}>
                <InputWrapper
                  id='bankAccountDetails.accountNumber'
                  label='Account Number'
                  error={meta.error}
                  {...labelPropsFromMeta({ meta }, 'label')}>
                  <Flex>
                    <TextField
                      {...labelPropsFromMeta({ meta }, 'input')}
                      type='text'
                      {...field}
                      id='bankAccountDetails.accountNumber'
                      flexSx={{ flex: '1' }}
                      short
                      tone={getTone(meta)}
                    />
                  </Flex>
                </InputWrapper>
              </Box>
            )}
          </Field>
          {getNavigationButtons({
            formikBag,
            applicationId,
            returnUrl,
            supplierOrderReference
          })}
        </Form>
      )}
    </Formik>
  );
};

export const bankDetailsStep: Step<DetailsCaptureValues> = {
  component: BankDetails,
  title: 'Bank details',
  description: Description
};
