import {
  BackToStoreTextButton,
  Status
} from 'applicantApp/components/ExternalRedirectButtons/ExternalRedirectButtons';
import { Flex, NovunaCM3QuoteSummary, Text } from 'compass-design';
import { getInterestPayable } from 'hitachi-retail-core/build/finance';
import {
  selectApr,
  selectDeferralPeriod,
  selectDeposit,
  selectGoodsAmount,
  selectGoodsDescription,
  selectInstalments,
  selectLoanAmount,
  selectRepaymentAmount,
  selectTotalAmountPayable
} from 'hitachi-retail-core/build/selectors';
import { formatCurrency } from 'hitachi-retail-core/build/utils/currency';
import React from 'react';
import { useSelector } from 'store';
import {
  selectActiveApplication,
  selectApplicationReturnUrl,
  selectApplicationSupplierOrderReference
} from 'store/application/selectors';
import { selectApplicationId } from 'store/save/reducer';
import { formatPercent, pluraliseString } from '../utils/formatters';

export const QuoteSummary: React.FC<{ backToStoreStatus?: Status }> = ({
  backToStoreStatus
}) => {
  const applicationId = useSelector(selectApplicationId) as string;
  const activeApplication = useSelector(selectActiveApplication);
  const applicationDocument = activeApplication.document;

  const goodsDescription = selectGoodsDescription(applicationDocument);
  const goodsAmount = selectGoodsAmount(applicationDocument);
  const instalments = selectInstalments(applicationDocument);
  const deposit = selectDeposit(applicationDocument);
  const loanAmount = selectLoanAmount(applicationDocument);
  const repaymentAmount = selectRepaymentAmount(applicationDocument);
  const deferralPeriod = selectDeferralPeriod(applicationDocument);
  const apr = selectApr(applicationDocument);
  const totalAmountPayable = selectTotalAmountPayable(applicationDocument);
  const returnUrl = useSelector(selectApplicationReturnUrl);
  const supplierOrderReference = useSelector(
    selectApplicationSupplierOrderReference
  );

  const getInstalmentsString = (instalments: number) =>
    `${instalments} ${pluraliseString('month', instalments)}`;
  const getDeferralPeriodString = (deferralPeriod: number) =>
    `${deferralPeriod} ${pluraliseString('month', deferralPeriod)}`;

  return (
    <NovunaCM3QuoteSummary
      applicationId={applicationId}
      goodsDescription={goodsDescription}
      goodsAmount={formatCurrency(goodsAmount)}
      deposit={formatCurrency(deposit)}
      loanAmount={formatCurrency(loanAmount)}
      repaymentTerm={
        instalments ? getInstalmentsString(instalments) : undefined
      }
      deferralPeriod={
        deferralPeriod ? getDeferralPeriodString(deferralPeriod) : undefined
      }
      apr={formatPercent(apr)}
      representativeAPR={apr && formatPercent(apr) + ' APR'}
      interestPayable={
        goodsAmount &&
        totalAmountPayable &&
        formatCurrency(
          getInterestPayable({
            goodsAmount,
            totalAmountPayable
          })
        )
      }
      totalAmountPayable={formatCurrency(totalAmountPayable)}
      monthlyRepayment={formatCurrency(repaymentAmount)}
      backToStoreTextButton={
        returnUrl &&
        backToStoreStatus && (
          <Flex mt={4}>
            <Text mr={1}>Not quite right?</Text>
            <BackToStoreTextButton
              novunaDisplay
              baseUrl={returnUrl}
              status={backToStoreStatus}
              applicationId={applicationId}
              supplierOrderReference={supplierOrderReference}
              buttonText='Back to store'
              modalText={
                'This application will be abandoned. Your credit history will not be affected.'
              }
            />
          </Flex>
        )
      }
    />
  );
};
