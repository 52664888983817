import { Button, NovunaHeading, Text } from 'compass-design';
import { validationMessages } from 'hitachi-retail-core';
import React from 'react';
import {
  asyncDecisionFailedErrorMessage,
  asyncDecisionInProgressErrorMessage,
  asyncDecisionTimedOutErrorMessage
} from 'services/decision';
import PageBlock from '../../../../../components/PageBlock';

export enum DecisionErrorEnum {
  okay, // so falsey value
  unableToDecision,
  wrongStatus,
  branchMismatch,
  decisionNotReturnedInTime,
  unableToDecisionNoRetry
}

export const setDecisionError = (
  errorMessage: string | undefined
): DecisionErrorEnum => {
  if (errorMessage) {
    if (errorMessage.indexOf('application status') >= 0) {
      return DecisionErrorEnum.wrongStatus;
    }
    if (errorMessage === validationMessages.BRANCH_MISMATCH) {
      return DecisionErrorEnum.branchMismatch;
    }
    if (errorMessage === asyncDecisionTimedOutErrorMessage) {
      return DecisionErrorEnum.decisionNotReturnedInTime;
    }
    if (errorMessage === asyncDecisionFailedErrorMessage) {
      return DecisionErrorEnum.unableToDecisionNoRetry;
    }
    if (errorMessage === asyncDecisionInProgressErrorMessage) {
      return DecisionErrorEnum.decisionNotReturnedInTime;
    }
    return DecisionErrorEnum.unableToDecision;
  }
  return DecisionErrorEnum.okay;
};

interface DecisionErrorProps {
  decisionError: DecisionErrorEnum;
  onApplicationAbort: () => void;
  onDecisionRetry: () => void;
}

const DecisionError = ({
  decisionError,
  onApplicationAbort,
  onDecisionRetry
}: DecisionErrorProps) => {
  const exit = (
    <Button
      isJumbo
      variant='secondaryDestructive'
      onClick={onApplicationAbort}
      data-test-id='compass-application-decision-abort'>
      Exit application
    </Button>
  );

  const decisionHeaderText =
    decisionError === DecisionErrorEnum.decisionNotReturnedInTime
      ? "Sorry, it's taking longer than usual"
      : 'Something went wrong';

  let decisionErrorPageContent: JSX.Element = <></>;
  switch (decisionError) {
    case DecisionErrorEnum.unableToDecisionNoRetry:
    case DecisionErrorEnum.unableToDecision: {
      const isUnableToDecisionError =
        decisionError === DecisionErrorEnum.unableToDecision;

      decisionErrorPageContent = (
        <>
          <Text mb={5}>
            We were unable to connect to the application decision service,
            please try again or alternatively contact the Retail Helpline on
            0344 375 5515.
          </Text>
          {isUnableToDecisionError && (
            <Button
              mr={4}
              isJumbo
              onClick={onDecisionRetry}
              data-test-id='compass-application-decision-retry'>
              Try again
            </Button>
          )}
          {exit}
        </>
      );
      break;
    }
    case DecisionErrorEnum.wrongStatus: {
      decisionErrorPageContent = (
        <>
          <Text mb={5}>
            The credit agreement has already been signed. A copy of the
            agreement has been emailed to the customer.
          </Text>
          {exit}
        </>
      );
      break;
    }
    case DecisionErrorEnum.branchMismatch: {
      decisionErrorPageContent = (
        <>
          <Text mb={5}>
            This application was created on a different branch. Please login to
            the correct branch and try again.
          </Text>
          {exit}
        </>
      );
      break;
    }
    case DecisionErrorEnum.decisionNotReturnedInTime: {
      decisionErrorPageContent = (
        <>
          <Text data-test-id='compass-async-decision-timeout-text' mb={5}>
            We are working on getting you a decision and will update the
            application as soon as we have it. We will notify the customer by
            email so they can complete their journey at home, if this is
            applicable
          </Text>
          {exit}
        </>
      );
      break;
    }
    default:
      /**
       * Having a 'break' here instead of an error due to the fact that the default case is:
       * DecisionErrorEnum.okay which will occur when an unknown error occurs.
       * If we we're to leave the error here it would cause the UI to crash
       */
      break;
  }

  return (
    <PageBlock>
      <div data-test-id='compass-application-decision-error'>
        <NovunaHeading
          data-test-id='compass-application-decision-error-heading'
          as='h2'
          mb={2}>
          {decisionHeaderText}
        </NovunaHeading>
        {decisionErrorPageContent}
      </div>
    </PageBlock>
  );
};

export default DecisionError;
