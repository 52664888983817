import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import CustomerSatisfied, {
  CustomerSatisfiedPropsFromDispatch,
  CustomerSatisfiedPropsFromState
} from '../components/CustomerSatisfied';
import { RootState } from '../store';
import { satisfactionFeedback } from '../store/customerSatisfaction/actions';

export const mapStateToProps = ({
  customerSatisfaction: {
    feedback: { complete, sent, error, alreadySubmitted },
    processing
  },
  retailer: { retailer },
  save: { applicationId }
}: RootState): CustomerSatisfiedPropsFromState => ({
  feedbackComplete: complete,
  feedbackSent: sent,
  feedbackError: error,
  processing,
  feedbackAlreadySubmitted: alreadySubmitted,
  retailerName: retailer.name,
  applicationId
});

export const mapDispatchToProps = (
  dispatch: Dispatch
): CustomerSatisfiedPropsFromDispatch => ({
  submitFeedback: token =>
    dispatch(satisfactionFeedback.request({ token, satisfied: true }))
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSatisfied);
