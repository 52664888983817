import {
  ReadOnlyWrapper,
  InputWrapper,
  SelectField,
  Box,
  TertiaryLinkDirection,
  TertiaryLink
} from 'compass-design';
import React, { useCallback, Fragment } from 'react';
import { formatAddressSingleLine } from 'utils/formatters';
import { useDispatch } from 'react-redux';
import { Address } from 'hitachi-retail-core';
import { AddressState, resetPostcodeLookup } from 'store/address/reducer';
import { AddressLookupWrapperProps } from '../AddressLookupWrapper';

export type AddressSelectProps = Pick<
  AddressLookupWrapperProps,
  'addressIndex'
> & {
  lookup: AddressState;
  postcode: string;
  onChange: (value: Address) => void;
  pafError?: string;
};

const AddressSelect: React.FC<AddressSelectProps> = ({
  addressIndex,
  lookup,
  onChange,
  postcode,
  pafError
}) => {
  const documentNamespace = `mainAddressDetails.mainAddress.${addressIndex}.address`;
  const dispatch = useDispatch();
  const onSelect = useCallback(
    e => {
      const index = e.target.value;
      const selectedAddressResult = lookup?.addressResults?.[index];

      if (!selectedAddressResult) {
        return;
      }

      onChange(selectedAddressResult);
    },
    [lookup, onChange]
  );

  if (lookup?.processing) {
    return (
      <div data-test-id='address-lookup-loading' className='input-field'>
        <p>Loading addresses</p>
      </div>
    );
  }

  if (!lookup.addressResults) {
    return null;
  }

  return (
    <Fragment>
      <ReadOnlyWrapper
        label='Postcode'
        tone={pafError ? 'negative' : 'positive'}
        action={
          <TertiaryLink
            direction={TertiaryLinkDirection.FORWARDS}
            text='Change'
            onClick={() => {
              dispatch(resetPostcodeLookup());
            }}
          />
        }>
        {postcode}
      </ReadOnlyWrapper>
      {pafError ? (
        <InputWrapper
          id='paf-error'
          error={pafError}
          tone={pafError ? 'negative' : 'positive'}
          label={''}
        />
      ) : (
        <Box mt={5}>
          <InputWrapper id='select-address' label='Select an address'>
            <SelectField
              name={`${documentNamespace}.addressSelector`}
              id={`${documentNamespace}.addressSelector`}
              data-test-id='address-lookup-selector'
              onChange={onSelect}
              defaultValue={''}>
              <option value='' disabled>
                {lookup.addressResults.length || 0} addresses found
              </option>
              {lookup.addressResults.map((address: Address, index: number) => (
                <option value={index} key={index}>
                  {formatAddressSingleLine(address)}
                </option>
              ))}
            </SelectField>
          </InputWrapper>
        </Box>
      )}
    </Fragment>
  );
};

export default AddressSelect;
