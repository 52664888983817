import { push } from 'connected-react-router';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import { selectDeferredProduct } from 'hitachi-retail-core/build/selectors';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import getAffordabilityDecline from 'retailerApp/utils/loanDetails/getAffordabilityDecline';
import { routes } from 'routes';
import { RootState } from 'store';
import {
  updateActiveApplication,
  createNewApplication
} from 'store/application/actions';
import { fetchDecision } from 'store/decision/actions';
import { fetchDeclinedLetterById } from 'store/declinedEmail/actions';
import getAffordabilityAccept from 'retailerApp/utils/loanDetails/getAffordabilityAccept';
import { getLoanDetails } from 'retailerApp/utils/loanDetails/getLoanDetails';
import { saveApplication } from 'store/save/actions';
import ApplicationDecision, {
  ApplicationDecisionPropsFromDispatch,
  ApplicationDecisionPropsFromState
} from './ApplicationDecision';
import {
  DecisionErrorEnum,
  setDecisionError
} from './components/DecisionError/DecisionError';

export const mapStateToProps = ({
  decision: {
    decisionResult,
    maxInstalmentAmount,
    errorMessage,
    tailoringExpiryDate
  },
  declinedEmail: { pdfUrl, pdfBlob },
  config: { enabledFeatures },
  save: { applicationId },
  application: {
    activeApplication: { document }
  },
  retailer: {
    retailer: {
      tailoringIncreaseLoanEnabled,
      tailoringIbcIncreaseLoanEnabled,
      tailoringReduceTermEnabled,
      tailoringIbcReduceTermEnabled
    }
  },
  loanAmend: { isLoanAmend }
}: RootState): ApplicationDecisionPropsFromState => {
  const decisionError: DecisionErrorEnum = setDecisionError(errorMessage);

  const productEarlySettlementFee =
    document.product && !!document.product.earlySettlementFee;

  const loanDetails = getLoanDetails({
    document,
    isSoftSearch: false,
    enabledFeatures
  });

  const decisionApplicationStatus = !decisionResult
    ? undefined
    : (decisionResult as ApplicationStatus);

  const affordabilityDecline = getAffordabilityDecline({
    decisionApplicationStatus,
    maxInstalmentAmount,
    enabledFeatures,
    loanDetails
  });

  const tailoringFeatureAffordabilityAccept = enabledFeatures.has(
    CompassFeature.TAILORING_AFFORDABILITY_ACCEPT_FEATURE
  );

  const affordabilityAccept = getAffordabilityAccept({
    tailoringFeatureAffordabilityAccept,
    tailoringIncreaseLoanEnabled: !!tailoringIncreaseLoanEnabled,
    tailoringIbcIncreaseLoanEnabled: !!tailoringIbcIncreaseLoanEnabled,
    tailoringReduceTermEnabled: !!tailoringReduceTermEnabled,
    tailoringIbcReduceTermEnabled: !!tailoringIbcReduceTermEnabled,
    loanDetails
  });

  return {
    decisionApplicationStatus,
    decisionError,
    affordabilityDecline,
    affordabilityAccept,
    tailoringExpiryDate: tailoringExpiryDate ?? undefined,
    pdfUrl,
    pdfBlob,
    applicationId: applicationId || '',
    regulatedApplication: document?.product?.regulated,
    productIsDeferred: selectDeferredProduct(document),
    productEarlySettlementFee,
    customerPresent: document.customerPresent === true,
    isLoanAmend
  };
};

export const mapDispatchToProps = (
  dispatch: Dispatch
): ApplicationDecisionPropsFromDispatch => ({
  onApplicationAbort: () => dispatch(createNewApplication()),
  onRegulatedContinue: () =>
    dispatch(push(routes.apply.preContractCreditInformation)),
  onUnRegulatedContinue: () =>
    dispatch(push(routes.apply.loanAdvanceInformation)),
  onDecisionRetry: () => dispatch(fetchDecision.request()),
  onDownloadDeclinedLetter: (id: string) => {
    dispatch(fetchDeclinedLetterById.request({ id }));
  },
  onConfirmTailoringUpdate: ({ document, tailoringOptionSelected }) => {
    dispatch(updateActiveApplication({ document, replaceValues: true }));
    dispatch(fetchDecision.request({ tailoringOptionSelected }));
  },
  onAcceptOriginalOffer: ({ document }) => {
    dispatch(updateActiveApplication({ document, replaceValues: true }));
    dispatch(saveApplication.request());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationDecision);
