import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../store';
import { closeNotification } from '../../../store/ui/actions';
import Notification, {
  NotificationPropsFromDispatch,
  NotificationPropsFromState
} from './Notification';

export const mapStateToProps = ({
  ui: { notification = {} }
}: RootState): NotificationPropsFromState => ({
  message: notification.message,
  type: notification.type
});

export const mapDispatchToProps = (
  dispatch: Dispatch
): NotificationPropsFromDispatch => ({
  onClose: () => dispatch(closeNotification())
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
