import { Address } from 'hitachi-retail-core';
import { createAsyncAction } from 'typesafe-actions';
import * as address from './constants';

interface AddressNamespace {
  meta: {
    namespace: string;
  };
}

interface GetAddressPayload extends AddressNamespace {
  postcode: string;
}

export interface GetAddressResult extends AddressNamespace {
  addressResults: Address[];
}

interface GetAddressError extends AddressNamespace {
  error: Error;
}

export const getAddresses = createAsyncAction(
  address.GET_ADDRESSES_REQUEST,
  address.GET_ADDRESSES_SUCCESS,
  address.GET_ADDRESSES_FAILURE
)<GetAddressPayload, GetAddressResult, GetAddressError>();
