import {
  Box,
  Button,
  Grid,
  Input,
  Label,
  NovunaHeading,
  NovunaTextButton,
  RadioOption,
  Search as SearchIcon,
  TertiaryLink,
  TertiaryLinkDirection,
  Text
} from 'compass-design';
import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';
import React, { useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';
import filterRetailers from 'retailerApp/utils/loanDetails/filterRetailers';
import { routes } from 'routes';
import { useSelector } from 'store';
import { selectEnabledFeatures } from 'store/config/selectors';
import {
  fetchBranchesForProposing,
  setProposingOnBehalfOf
} from 'store/retailer/actions';
import {
  selectBranchesForProposing,
  selectError,
  selectProcessing,
  selectProposingOnBehalfOf,
  selectRetailer,
  selectRetailerLoaded
} from 'store/retailer/selectors';
import { pluraliseString } from 'utils/formatters';

const RetailerSelection: React.FC = () => {
  const history = useHistory();

  const enabledFeatures = useSelector(selectEnabledFeatures);
  const processing = useSelector(selectProcessing);
  const fetchError = useSelector(selectError);
  const currentRetailer = useSelector(selectRetailer);
  const currentRetailerLoaded = useSelector(selectRetailerLoaded);
  const branchesForProposing = useSelector(selectBranchesForProposing);
  const proposingOnBehalfOf = useSelector(selectProposingOnBehalfOf);

  const canProposeApplications =
    currentRetailerLoaded &&
    currentRetailer.proposeApplicationsEnabled === true;

  const dispatch = useDispatch();
  useEffect(() => {
    if (canProposeApplications && branchesForProposing === undefined) {
      dispatch(fetchBranchesForProposing.request());
    }
  }, [canProposeApplications, branchesForProposing, dispatch]);

  const [searchState, searchDispatch] = useReducer(
    searchReducer,
    initialSearchState
  );

  if (processing) {
    return (
      <Text my={4} sx={{ fontSize: 2 }}>
        Loading retailers&hellip;
      </Text>
    );
  }

  if (
    (currentRetailerLoaded &&
      currentRetailer.proposeApplicationsEnabled === false) ||
    !enabledFeatures.has(CompassFeature.GLOBAL_USER_PROFILES)
  ) {
    return <Redirect to={routes.apply.quote} />;
  }

  const proceedToQuote = () => history.push(routes.apply.quote);

  const { query, searchActive, searchResult } = searchState;
  const hasResults = searchResult.length > 0;
  if (searchActive && hasResults) {
    return (
      <RetailerSearchResults
        searchResult={searchResult}
        selectedRetailer={proposingOnBehalfOf}
        onSelectRetailer={supplierNumber =>
          dispatch(setProposingOnBehalfOf({ supplierNumber }))
        }
        onContinue={proceedToQuote}
        onReturnToSearch={() => {
          dispatch(setProposingOnBehalfOf({ supplierNumber: undefined }));
          searchDispatch({
            type: 'RESET_SEARCH'
          });
        }}
      />
    );
  }

  return (
    <>
      <NovunaHeading as='h1'>Propose application</NovunaHeading>
      <Text mt={2} sx={{ fontSize: 2 }}>
        Create an application on behalf of a head office or branch.
      </Text>
      {branchesForProposing && branchesForProposing.length > 0 ? (
        <Box mt={5}>
          <form
            onSubmit={(event: React.FormEvent) => {
              event.preventDefault();
              searchDispatch({
                type: 'PERFORM_SEARCH',
                retailers: branchesForProposing
              });
            }}>
            <Label
              htmlFor='query'
              sx={{
                width: 'auto',
                fontSize: 3
              }}>
              Search
            </Label>
            <Box mt={1}>
              <Input
                id='query'
                type='text'
                value={query}
                placeholder='Find a branch or head office'
                mr={3}
                sx={{
                  display: 'inline-block',
                  maxWidth: '290px',
                  verticalAlign: 'middle'
                }}
                onChange={({ target: { value } }) =>
                  searchDispatch({
                    type: 'UPDATE_QUERY',
                    query: value
                  })
                }
              />
              <Button isJumbo type='submit' disabled={query === ''}>
                Search
                <SearchIcon />
              </Button>
            </Box>
            <Text mt={1}>Enter trading name or supplier number</Text>
          </form>
          {searchActive && !hasResults && (
            <Text mt={2} sx={{ fontSize: 1, color: 'tone.negative' }}>
              No results found. Please try your search again or alternatively
              contact the Retail Helpline on 0344 375 5515.
            </Text>
          )}
        </Box>
      ) : (
        <Text mt={4} sx={{ fontSize: 1, fontWeight: 'bold' }}>
          {fetchError ? (
            <>
              We were unable to find any head offices or branches.
              <br />
              Please refresh to try again or alternatively contact the Retail
              Helpline on 0344 375 5515.
            </>
          ) : (
            <>
              No head offices or branches found.
              <br />
              Please contact the Retail Helpline on 0344 375 5515.
            </>
          )}
        </Text>
      )}
      {currentRetailerLoaded && (
        <Box mt={4}>
          <NovunaTextButton onClick={proceedToQuote}>
            Or create app as {currentRetailer.name} (
            {currentRetailer.supplierNumber})
          </NovunaTextButton>
        </Box>
      )}
    </>
  );
};

type SearchAction =
  | {
      type: 'UPDATE_QUERY';
      query: string;
    }
  | {
      type: 'PERFORM_SEARCH';
      retailers: Retailer[];
    }
  | {
      type: 'RESET_SEARCH';
    };

type SearchState = {
  query: string;
  searchActive: boolean;
  searchResult: Retailer[];
};

const initialSearchState: SearchState = {
  query: '',
  searchActive: false,
  searchResult: []
};

const searchReducer = (
  state: SearchState,
  action: SearchAction
): SearchState => {
  switch (action.type) {
    case 'UPDATE_QUERY':
      return {
        ...state,
        query: action.query
      };

    case 'PERFORM_SEARCH': {
      const { query } = state;
      const { retailers } = action;

      if (query === '') {
        return initialSearchState;
      }

      return {
        ...state,
        searchActive: true,
        searchResult: filterRetailers({
          retailers,
          searchCriteria: query
        })
      };
    }

    case 'RESET_SEARCH':
      return {
        ...state,
        searchActive: false,
        searchResult: []
      };

    default:
      return state;
  }
};
interface RetailerSearchResultsProps {
  searchResult: Retailer[];
  selectedRetailer?: Retailer;
  onSelectRetailer: (supplierNumber: string) => void;
  onContinue: () => void;
  onReturnToSearch: () => void;
}

export const RetailerSearchResults: React.FunctionComponent<RetailerSearchResultsProps> = ({
  searchResult,
  selectedRetailer,
  onSelectRetailer,
  onContinue,
  onReturnToSearch
}) => (
  <>
    <Box mb={2}>
      <TertiaryLink
        direction={TertiaryLinkDirection.BACKWARDS}
        text='Search'
        onClick={onReturnToSearch}
      />
    </Box>
    {searchResult.length > 0 ? (
      <>
        <Text sx={{ fontWeight: 'bold' }}>
          {searchResult.length}{' '}
          {pluraliseString('match', searchResult.length, 'es')} found
        </Text>
        <Text mt={2}>
          Select a retailer to create an application on behalf of a head office
          or branch.
        </Text>
        <Box mt={4}>
          <Grid
            columns={['48px 115px 1fr']}
            gap={0}
            p={2}
            sx={{
              padding: 3,
              border: '1px solid',
              borderColor: 'tints.2',
              color: 'tints.1',
              backgroundColor: '#f4f5f6',
              textTransform: 'uppercase',
              letterSpacing: '0.5px',
              borderTopLeftRadius: 'panel',
              borderTopRightRadius: 'panel',
              fontSize: 0,
              fontWeight: 'bold'
            }}>
            <span />
            <span>Supplier</span>
            <span>Trading name</span>
          </Grid>
          {searchResult.map(retailer => {
            const isSelected =
              selectedRetailer?.supplierNumber === retailer.supplierNumber;
            return (
              <Grid
                key={retailer.supplierNumber}
                columns={['48px 115px 1fr']}
                gap={0}
                p={2}
                sx={{
                  alignItems: 'center',
                  paddingX: 3,
                  paddingY: 4,
                  border: '1px solid',
                  borderColor: 'tints.2',
                  borderTop: 'none',
                  '&:hover': {
                    backgroundColor: 'monochrome.6'
                  },
                  backgroundColor: isSelected ? 'tints.2' : undefined,
                  fontSize: '2',
                  cursor: 'pointer'
                }}
                onClick={() => onSelectRetailer(retailer.supplierNumber)}>
                <RadioOption
                  standalone
                  data-test-id={`compass-retailer-radio-${retailer.supplierNumber}`}
                  checked={isSelected}
                  readOnly
                />
                <span>{retailer.supplierNumber}</span>
                <span>{retailer.name}</span>
              </Grid>
            );
          })}
        </Box>
        <Button
          isJumbo
          mt={4}
          disabled={selectedRetailer === undefined}
          onClick={onContinue}>
          Continue
        </Button>
      </>
    ) : (
      <Text>No results found.</Text>
    )}
  </>
);

export default RetailerSelection;
