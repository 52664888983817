import { Box, Text } from 'compass-design';
import React from 'react';
import { ApplicantPersonalDetails } from 'hitachi-retail-core/build/schemas/applicantDetails';
import moment from 'moment';
import {
  maritalStatus as maritalStatusEnum,
  title as titleEnum
} from 'hitachi-retail-core/build/enums';

const ReadOnlyPersonalDetails: React.FC<ApplicantPersonalDetails> = ({
  title,
  firstName,
  lastName,
  dateOfBirth,
  maritalStatus
}) => {
  const prettyTitle = titleEnum?.enumNames?.[titleEnum.enum.indexOf(title)];
  const prettyMaritalStatus =
    maritalStatusEnum?.enumNames?.[
      maritalStatusEnum.enum.indexOf(maritalStatus)
    ];

  return (
    <Box>
      <Text
        sx={{ fontSize: 2 }}
        data-test-id='summary-review-page-contact-name'>
        {prettyTitle} {firstName} {lastName}
      </Text>

      <Text sx={{ fontSize: 2 }} data-test-id='summary-review-page-contact-dob'>
        {moment(dateOfBirth).format('DD MMMM YYYY')}
      </Text>
      <Text
        sx={{ fontSize: 2 }}
        data-test-id='summary-review-page-contact-marital-status'>
        {prettyMaritalStatus}
      </Text>
    </Box>
  );
};

export default ReadOnlyPersonalDetails;
