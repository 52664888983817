import React from 'react';
import { DescriptionFieldProps as OriginalDescriptionFieldProps } from 'react-jsonschema-form';

type DescriptionFieldProps = Partial<OriginalDescriptionFieldProps> &
  Pick<OriginalDescriptionFieldProps, 'description'>;

const DescriptionField: React.FunctionComponent<DescriptionFieldProps> = props => {
  const { id, description } = props;
  if (!description) {
    return null;
  }
  if (typeof description === 'string') {
    return (
      <p id={id} className='compass-field-description'>
        {description}
      </p>
    );
  } else {
    return (
      <div id={id} className='compass-field-description'>
        {description}
      </div>
    );
  }
};

export default DescriptionField;
