import { UserPolicy, UserRole } from 'hitachi-retail-core/build/userPolicy';
import { LoginResponseCode } from 'hitachi-retail-core/build/enums';
import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { RootState } from '..';
import {
  confirmForgotPassword,
  directAppUserLogin,
  sendPasswordResetCode,
  sessionCheck,
  userLogin,
  userLogout,
  userPolicy,
  userSetPassword,
  userSetPasswordRedirect
} from './actions';

export type DirectAppUserLoginAction = ActionType<typeof directAppUserLogin>;
export type UserLoginAction = ActionType<typeof userLogin>;
export type UserLogoutAction = ActionType<typeof userLogout>;
export type SessionCheckAction = ActionType<typeof sessionCheck>;
export type UserSetPasswordAction = ActionType<typeof userSetPassword>;
export type SendPasswordResetCodeAction = ActionType<
  typeof sendPasswordResetCode
>;
export type ConfirmForgotPasswordAction = ActionType<
  typeof confirmForgotPassword
>;
export type UserSetPasswordRedirectAction = ActionType<
  typeof userSetPasswordRedirect
>;
export type GetUserPolicyAction = ActionType<typeof userPolicy>;

type UserAction =
  | UserLoginAction
  | UserLogoutAction
  | SessionCheckAction
  | GetUserPolicyAction
  | UserSetPasswordAction
  | SendPasswordResetCodeAction
  | ConfirmForgotPasswordAction
  | UserSetPasswordRedirectAction
  | DirectAppUserLoginAction;

export interface UserState {
  loggedIn?: boolean;
  loggingIn: boolean;
  sessionCheckLoading: boolean;
  errorMessage?: string;
  successMessage?: string;
  username?: string;
  code?: string;
  retailerName?: string;
  familyName?: string;
  givenName?: string;
  session?: string;
  setPasswordProcessing: boolean;
  setPasswordSuccess: boolean;
  confirmForgotPasswordLoading: boolean;
  directAppLoggedIn?: boolean;
  userPolicy?: UserPolicy;
  passwordResetCodeProcessing: boolean;
  passwordResetCodeSuccess: boolean;
  userRoles?: UserRole[];
  promptPasswordUpdate?: boolean;
}

export const initialState: UserState = {
  loggingIn: false,
  sessionCheckLoading: false,
  setPasswordProcessing: false,
  setPasswordSuccess: false,
  confirmForgotPasswordLoading: false,
  directAppLoggedIn: false,
  passwordResetCodeProcessing: false,
  passwordResetCodeSuccess: false,
  promptPasswordUpdate: false
};

export const selectLoggedIn = ({ user: { loggedIn } }: RootState) => loggedIn;
export const selectUsername = ({ user: { username } }: RootState) => username;
export const selectPasswordPrompt = ({
  user: { promptPasswordUpdate }
}: RootState) => promptPasswordUpdate;

export const reducer: Reducer<UserState, UserAction> = (
  state = initialState,
  action
) => {
  let loggedIn;
  switch (action.type) {
    // Login
    case getType(userLogin.request): {
      const { errorMessage, successMessage, ...withoutMessage } = state;
      return {
        ...withoutMessage,
        loggingIn: true,
        setPasswordProcessing: false,
        setPasswordSuccess: false
      };
    }

    case getType(userLogin.success):
      loggedIn = true;
      if (
        action.payload.code &&
        action.payload.code === LoginResponseCode.NewPasswordChallenge
      ) {
        loggedIn = false;
      }

      // FX-869
      if (
        window &&
        window.sessionStorage &&
        action.payload?.promptPasswordUpdate
      ) {
        window.sessionStorage.setItem(
          'promptPasswordUpdate',
          action.payload?.promptPasswordUpdate ? 'true' : 'false'
        );
      }

      return {
        ...state,
        loggedIn,
        loggingIn: false,
        directAppLoggedIn: false,
        username: action.payload.username,
        retailerName: action.payload.retailerName,
        familyName: action.payload.familyName,
        givenName: action.payload.givenName,
        session: action.payload.session,
        code: action.payload.code,
        userRoles: action.payload?.userRoles ?? [],
        promptPasswordUpdate: action.payload?.promptPasswordUpdate || false
      };

    case getType(userLogin.failure):
      return {
        ...state,
        errorMessage: action.payload.message,
        loggedIn: false,
        loggingIn: false,
        directAppLoggedIn: false
      };

    case getType(userSetPassword.request):
      return {
        ...state,
        setPasswordProcessing: true,
        setPasswordSuccess: false,
        code: undefined
      };

    case getType(userSetPasswordRedirect):
      return {
        ...state,
        setPasswordProcessing: false,
        setPasswordSuccess: false,
        code: undefined,
        session: undefined
      };

    case getType(userSetPassword.success):
      // FX-869
      if (window && window.sessionStorage) {
        window.sessionStorage.removeItem('promptPasswordUpdate');
      }

      return {
        ...state,
        errorMessage: undefined,
        setPasswordProcessing: false,
        setPasswordSuccess: true,
        promptPasswordUpdate: false
      };

    case getType(userSetPassword.failure):
      return {
        ...state,
        setPasswordProcessing: false,
        setPasswordSuccess: false,
        errorMessage: action.payload.message
      };

    // Logout
    case getType(userLogout.success):
      // FX-869
      if (window && window.sessionStorage) {
        window.sessionStorage.removeItem('promptPasswordUpdate');
      }

      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        directAppLoggedIn: false,
        username: '',
        promptPasswordUpdate: false
      };

    // Session check
    case getType(sessionCheck.request):
      return { ...state, sessionCheckLoading: true };

    /* eslint-disable no-case-declarations */
    case getType(sessionCheck.success):
      // FX-869
      let promptPwUpd = false;
      if (window && window.sessionStorage) {
        const sessionStoredPrompt = window.sessionStorage.getItem(
          'promptPasswordUpdate'
        );
        promptPwUpd = sessionStoredPrompt === 'true';
      }

      return {
        ...state,
        sessionCheckLoading: false,
        promptPasswordUpdate: promptPwUpd
      };
    /* eslint-enable no-case-declarations */

    case getType(sessionCheck.failure):
      return {
        ...state,
        sessionCheckLoading: false,
        promptPasswordUpdate: false
      };

    // User policy
    case getType(userPolicy.success):
      return { ...state, userPolicy: action.payload.policy };

    case getType(userPolicy.failure):
      return { ...state, errorMessage: action.payload.message };

    // Send password reset code
    case getType(sendPasswordResetCode.request): {
      return { ...state, passwordResetCodeProcessing: true };
    }

    case getType(sendPasswordResetCode.success):
      return {
        ...state,
        passwordResetCodeProcessing: false,
        passwordResetCodeSuccess: true
      };

    case getType(sendPasswordResetCode.failure):
      return {
        ...state,
        passwordResetCodeProcessing: false,
        errorMessage: action.payload.message
      };

    // Confirm forgot password
    case getType(confirmForgotPassword.request): {
      const { errorMessage, successMessage, ...withoutMessage } = state;
      return { ...withoutMessage, confirmForgotPasswordLoading: true };
    }

    case getType(confirmForgotPassword.success):
      return {
        ...state,
        confirmForgotPasswordLoading: false,
        successMessage: 'Password successfully updated.'
      };

    case getType(confirmForgotPassword.failure):
      return {
        ...state,
        confirmForgotPasswordLoading: false,
        errorMessage: action.payload.message
      };

    case getType(directAppUserLogin.request): {
      const { errorMessage, successMessage, ...withoutMessage } = state;
      return {
        ...withoutMessage,
        loggingIn: true,
        setPasswordProcessing: false,
        setPasswordSuccess: false
      };
    }

    case getType(directAppUserLogin.success): {
      loggedIn = true;
      return {
        ...state,
        loggedIn,
        directAppLoggedIn: true,
        loggingIn: false,
        username: action.payload.username,
        retailerName: action.payload.retailerName,
        familyName: action.payload.familyName,
        givenName: action.payload.givenName,
        session: action.payload.session,
        code: action.payload.code,
        userRoles: action.payload?.userRoles ?? []
      };
    }

    case getType(directAppUserLogin.failure):
      return {
        ...state,
        errorMessage: action.payload.message,
        loggedIn: false,
        loggingIn: false,
        directAppLoggedIn: false
      };

    default:
      return state;
  }
};
