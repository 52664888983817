export const FETCH_PREVIOUS_APPLICATIONS_REQUEST =
  'FETCH_PREVIOUS_APPLICATIONS_REQUEST';
export const FETCH_PREVIOUS_APPLICATIONS_SUCCESS =
  'FETCH_PREVIOUS_APPLICATIONS_SUCCESS';
export const FETCH_PREVIOUS_APPLICATIONS_FAILURE =
  'FETCH_PREVIOUS_APPLICATIONS_FAILURE';

export const SEARCH_APPLICATIONS_REQUEST = 'SEARCH_APPLICATIONS_REQUEST';
export const SEARCH_APPLICATIONS_SUCCESS = 'SEARCH_APPLICATIONS_SUCCESS';
export const SEARCH_APPLICATIONS_FAILURE = 'SEARCH_APPLICATIONS_FAILURE';

export const RESET_SEARCH_APPLICATIONS = 'RESET_SEARCH_APPLICATIONS';
