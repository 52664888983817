import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import * as app from './constants';
import { User } from 'hitachi-retail-core/build/services/users/usersService';
import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';
import { UserApiData, UserFormData } from 'retailerApp/utils/editingUsers';

export const createUserAction = createAsyncAction(
  app.CREATE_USER_REQUEST,
  app.CREATE_USER_SUCCESS,
  app.CREATE_USER_FAILURE
)<UserApiData, void, Error>();

export const lookupUserAction = createAsyncAction(
  app.LOOKUP_USER_REQUEST,
  app.LOOKUP_USER_SUCCESS,
  app.LOOKUP_USER_FAILURE
)<string, User[], Error>();

export const selectedUserAction = createAsyncAction(
  app.SELECTED_USER_REQUEST,
  app.SELECTED_USER_SUCCESS,
  app.SELECTED_USER_FAILURE
)<User, UserFormData, Error>();

export const updateUserAction = createAsyncAction(
  app.UPDATE_USER_REQUEST,
  app.UPDATE_USER_SUCCESS,
  app.UPDATE_USER_FAILURE
)<UserApiData, void, Error>();

export const resetUser = createStandardAction(app.RESET_USER)();

export const setSelectedBranch = createStandardAction(app.SET_SELECTED_BRANCH)<{
  selectedBranch?: Retailer;
}>();
