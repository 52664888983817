import { push } from 'connected-react-router';
import { put, select, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { routes } from '../../routes';
import { RootState } from '../../store';
import {
  updateActiveApplication,
  resetActiveApplicationStore,
  amendApplication
} from '../../store/application/actions';
import { setApplicationId } from '../../store/save/actions';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import { setLoanAmend } from 'store/applicationAmend/actions';

export const selectPreviousApplicationDocument = (state: RootState) =>
  state.previousApplicationDetail.previousApplication &&
  state.previousApplicationDetail.previousApplication.document;

export const selectPreviousApplicationSoftSearchExpiry = (state: RootState) =>
  state.previousApplicationDetail.previousApplication &&
  state.previousApplicationDetail.previousApplication.softSearchExpiryDate;

export const selectPreviousApplicationStatus = (state: RootState) =>
  state.previousApplicationDetail.previousApplication &&
  state.previousApplicationDetail.previousApplication.status;

export const selectPreviousApplicationId = (state: RootState) =>
  state.previousApplicationDetail.previousApplication &&
  state.previousApplicationDetail.previousApplication.id;

export const selectPreviousApplicationApplicationExpiry = (state: RootState) =>
  state.previousApplicationDetail.previousApplication &&
  state.previousApplicationDetail.previousApplication.applicationExpiryDate;

export const selectPreviousMaxInstalmentAmount = (state: RootState) =>
  state.previousApplicationDetail.previousApplication &&
  state.previousApplicationDetail.previousApplication.maxInstalmentAmount;

export function* amendApplicationSaga() {
  const applicationDocument = yield select(selectPreviousApplicationDocument);
  const id = yield select(selectPreviousApplicationId);

  if (applicationDocument && id) {
    const softSearchExpiryDate = yield select(
      selectPreviousApplicationSoftSearchExpiry
    );
    const applicationStatus: ApplicationStatus = yield select(
      selectPreviousApplicationStatus
    );
    const applicationExpiryDate = yield select(
      selectPreviousApplicationApplicationExpiry
    );

    const maxInstalmentAmount = yield select(selectPreviousMaxInstalmentAmount);

    const loanAmendApplicationStatus =
      applicationStatus === ApplicationStatus.signed ||
      applicationStatus === ApplicationStatus.decision_accept;
    const isNonBrokered = applicationDocument.isNonBrokered;

    yield put(resetActiveApplicationStore());

    yield put(
      updateActiveApplication({
        document: applicationDocument,
        status: applicationStatus,
        softSearchExpiryDate,
        applicationExpiryDate,
        maxInstalmentAmount
      })
    );
    yield put(setApplicationId({ id }));

    if (loanAmendApplicationStatus && !isNonBrokered) {
      yield put(setLoanAmend());
      yield put(push(routes.apply.loanAmend));
    } else {
      yield put(push(routes.apply.quote));
    }
  }
}

export function* amendApplicationSagaWatcher() {
  yield takeEvery(getType(amendApplication), amendApplicationSaga);
}
