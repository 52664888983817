import { getBasePageName, pageNameSeparator } from 'applicantApp/config';
import { NovunaHeading } from 'compass-design';
import { Decimal } from 'hitachi-retail-core/build/utils/decimal';
import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import SigningHandoff from 'retailerApp/components/SigningHandoff/SigningHandoff';
import { LoanAdvanceInformation as LoanAdvanceInformationAccordions } from 'shared/components/LoanInformation';
import FeatureToggle from '../../../containers/meta/FeatureToggle';
import { formatCurrency, formatPercent } from '../../../utils/formatters';

const toSentenceCase = (input: string): string => {
  return input.charAt(0).toUpperCase() + input.substr(1).toLowerCase();
};

const pageName = 'Review loan information';
const documentName = 'Review loan information';

export interface LoanAdvanceInformationPropsFromState {
  applicationId: string;
  isDeferred: boolean;
  earlySettlementFee?: boolean;
  immediatePayout: boolean;
  agreementDuration: number;
  retailerName: string;
  apr: Decimal;
  loanAmount: Decimal;
  goodsAmount: Decimal;
  instalments: number;
  totalAmountPayable: Decimal;
  repaymentAmount: Decimal;
  goodsDescription: string;
  formattedRetailerAddress: string;
}

export interface LoanAdvanceInformationPropsFromDispatch {
  startLoanAdvanceInformation: (id: string) => void;
}

export type LoanAdvanceInformationProps = LoanAdvanceInformationPropsFromState &
  LoanAdvanceInformationPropsFromDispatch;

const LoanAdvanceInformation: React.FC<LoanAdvanceInformationProps> = ({
  applicationId,
  isDeferred,
  earlySettlementFee,
  immediatePayout,
  agreementDuration,
  retailerName,
  apr,
  loanAmount,
  goodsAmount,
  instalments,
  totalAmountPayable,
  repaymentAmount,
  goodsDescription,
  formattedRetailerAddress,
  startLoanAdvanceInformation
}) => {
  useEffect(() => {
    if (applicationId) {
      startLoanAdvanceInformation(applicationId);
    }
  }, [applicationId, startLoanAdvanceInformation]);

  return (
    <>
      <Helmet>
        <title>{[pageName, getBasePageName()].join(pageNameSeparator)}</title>
      </Helmet>
      <div data-test-id='compass-loan-advance-information'>
        <NovunaHeading
          as='h1'
          mb={3}
          data-test-id='loan-advance-information-page-heading'>
          {toSentenceCase(documentName)}
        </NovunaHeading>
        <LoanAdvanceInformationAccordions
          intermediaryName={retailerName}
          intermediaryAddress={formattedRetailerAddress}
          goodsDescription={goodsDescription}
          loanAmount={formatCurrency(loanAmount)}
          repaymentAmount={formatCurrency(repaymentAmount)}
          agreementDuration={agreementDuration}
          instalments={instalments}
          goodsAmount={formatCurrency(goodsAmount)}
          totalAmountPayable={formatCurrency(totalAmountPayable)}
          apr={formatPercent(apr) ?? ''}
          earlySettlementFee={!!earlySettlementFee}
          isDeferred={isDeferred}
          immediatePayout={immediatePayout}
        />
      </div>

      <FeatureToggle feature={CompassFeature.E_SIGNING}>
        <SigningHandoff />
      </FeatureToggle>
    </>
  );
};

export default LoanAdvanceInformation;
