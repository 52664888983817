import { ApplicationDetail } from 'components/form/types';
import { push } from 'connected-react-router';
import {
  deserializeProductConfig,
  ProductConfig
} from 'hitachi-retail-core/build/api/productConfig';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { resetFetchedGoodsList } from 'store/goodsList/actions';
import { fetchProductsForRetailer } from 'store/product/actions';
import Quote, {
  QuotePropsFromDispatch,
  QuotePropsFromState
} from '../components/Quote';
import {
  createProductOptions,
  getSelectedProductConfig,
  getSelectedProductOption,
  ProductOption
} from '../form/quote';
import { routes } from '../routes';
import { RootState } from '../store';
import {
  clearSelectedProduct,
  selectApplicationProductConfig,
  selectApplicationProductOption,
  updateActiveApplication
} from '../store/application/actions';
import {
  selectActiveApplication,
  selectCurrentApplicationDetailsFromHistory,
  selectMustReselectProduct
} from '../store/application/selectors';
import { selectEnabledFeatures } from '../store/config/selectors';
import {
  selectAllProducts,
  selectProductsFetched
} from '../store/product/selectors';
import { selectSendToCustomerAllowedOnQuote } from '../store/retailer/selectors';

export const mapStateToProps = (state: RootState): QuotePropsFromState => {
  const enabledFeatures = selectEnabledFeatures(state);
  const application = selectActiveApplication(state);
  const applicationDetailsFromHistory = selectCurrentApplicationDetailsFromHistory(
    state
  );
  const sendToCustomerAllowedOnQuote = selectSendToCustomerAllowedOnQuote(
    state
  );
  const currentProductConfigs = selectAllProducts(state);

  let productConfig: ProductConfig | undefined;
  let expiredProductConfig = false;
  let productOptions: ProductOption[] | undefined;
  let selectedProductOption: ProductOption | undefined;
  if (selectProductsFetched(state)) {
    // Get the application product config and check if it has expired
    ({
      productConfig,
      hasExpired: expiredProductConfig
    } = getApplicationProductConfig(application, currentProductConfigs));

    productOptions = getProductOptions(
      application,
      currentProductConfigs,
      expiredProductConfig,
      productConfig
    );
    selectedProductOption = getSelectedProductOption(productOptions);
  }
  const maxInstalmentAmount = application.maxInstalmentAmount;

  return {
    application,
    availableInstalments: selectedProductOption?.instalments ?? [],
    productConfig,
    expiredProductConfig,
    productOptions,
    // This is not driven by the expiredProductConfig flag as the message we
    // display to the user would disappear as soon as the expired product
    // config itself is cleared by the clearSelectedProduct action below
    mustReselectProduct: selectMustReselectProduct(state),
    mailOrderEnabled: sendToCustomerAllowedOnQuote,
    enabledFeatures,
    applicationDetailsFromHistory,
    maxInstalmentAmount,
    applicationExpiryDate: application.applicationExpiryDate
  };
};

export const mapDispatchToProps = (
  dispatch: Dispatch
): QuotePropsFromDispatch => ({
  onChange: (
    formData,
    productOptions,
    interestFreeCreditUnregulatedEndDate
  ) => {
    dispatch(
      updateActiveApplication({ document: formData, replaceValues: false })
    );

    const selectedProductOption = getSelectedProductOption(productOptions);
    if (selectedProductOption) {
      const productConfig = getSelectedProductConfig(
        selectedProductOption.productConfigs,
        formData
      );
      if (productConfig) {
        dispatch(
          selectApplicationProductConfig({
            productConfig,
            interestFreeCreditUnregulatedEndDate
          })
        );
      }
    }
  },
  getProductsForRetailer: supplierNumber => {
    dispatch(
      fetchProductsForRetailer.request({
        supplierNumber
      })
    );
  },
  onSelectProductOption: productOption =>
    dispatch(selectApplicationProductOption({ productOption })),
  onSubmit: formData => {
    dispatch(
      updateActiveApplication({ document: formData, replaceValues: false })
    );
    dispatch(push(routes.apply.privacyPolicy));
  },
  clearSelectedProduct: () => dispatch(clearSelectedProduct()),
  resetFetchedGoodsList: () => dispatch(resetFetchedGoodsList())
});

const getApplicationProductConfig = (
  application: ApplicationDetail,
  productConfigs: ProductConfig[]
): {
  productConfig?: ProductConfig;
  hasExpired: boolean;
} => {
  const { document } = application;
  const { product } = document;

  let productConfig: ProductConfig | undefined;
  let hasExpired = false;
  if (product?.productConfig) {
    productConfig = deserializeProductConfig(
      product.productConfig as ProductConfig
    );

    // Attempt to match stored product config with one from product store
    if (!getSelectedProductConfig(productConfigs, document)) {
      // Assume expired if not found
      hasExpired = true;
    }
  }

  return { productConfig, hasExpired };
};

const getProductOptions = (
  application: ApplicationDetail,
  productConfigs: ProductConfig[],
  expiredApplicationProductConfig: boolean,
  applicationProductConfig?: ProductConfig
) => {
  const { status, document } = application;

  let productOptions: ProductOption[];
  if (
    status !== ApplicationStatus.open &&
    applicationProductConfig &&
    expiredApplicationProductConfig
  ) {
    // For post-accepted applications with an expired product config, display
    // only this on the quote page
    productOptions = createProductOptions([applicationProductConfig], document);
  } else {
    productOptions = createProductOptions(productConfigs, document);
  }

  return productOptions;
};

export default connect(mapStateToProps, mapDispatchToProps)(Quote);
