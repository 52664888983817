import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  ModalPlaceholder,
  ModalPlaceholderPropsFromDispatch,
  ModalPlaceholderPropsFromState
} from '../components/ModalPlaceholder';
import { RootState } from '../store';
import { closeModal } from '../store/ui/actions';

export const mapStateToProps = ({
  ui: { modal = {} }
}: RootState): ModalPlaceholderPropsFromState => ({
  body: modal.body,
  onCloseFromAction: modal.onClose
});

export const mapDispatchToProps = (
  dispatch: Dispatch
): ModalPlaceholderPropsFromDispatch => ({
  onClose: () => dispatch(closeModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalPlaceholder);
