import { ValidationContext } from 'hitachi-retail-core/build/schemas/validation';
import { JSONSchema6 } from 'json-schema';
import get from 'lodash/get';
import {
  AjvError,
  FieldValidation,
  FormValidation
} from 'react-jsonschema-form';
import { asNumber } from 'react-jsonschema-form/lib/utils';
import { ObjectSchema, ValidationError } from 'yup';

export const processSchemaValue = (schema: JSONSchema6, value: any) => {
  const { type } = schema;
  if (value === '') {
    return undefined;
  } else if (type === 'boolean') {
    return value === 'true';
  } else if (type === 'number' || type === 'integer') {
    return asNumber(value);
  }
  return value;
};

export const hasFieldValue = <T extends object>(
  fieldName: string,
  formData: T
) => !!get(formData, fieldName);

export const getFieldError = (
  fieldName: string,
  errorSchema: FormValidation
): string => {
  if (errorSchema && fieldName in errorSchema) {
    const errors = errorSchema[fieldName].__errors;
    if (Array.isArray(errors) && errors.length > 0) {
      return errors[0];
    }
  }
  return '';
};

export const transformErrors = (errors: AjvError[]): AjvError[] => {
  return [];
};

export const createErrorHandler = (): FieldValidation => ({
  __errors: [],
  addError(message: string) {
    this.__errors.push(message);
  }
});

const fetchNestedError = (
  errorPath: string,
  errors: FormValidation
): FormValidation | null => {
  const keyIndex = errorPath.indexOf('.');

  if (keyIndex > -1) {
    let baseKey = errorPath.substring(0, keyIndex);
    const nestedKey = errorPath.substring(keyIndex + 1);
    let baseErrorHandler = errors[baseKey];

    // Check if base key is a reference to an array - e.g. mainAddress[0]
    const arrayMatches = baseKey.match(/([A-z0-9]+)\[(\d)\]/);
    if (Array.isArray(arrayMatches)) {
      baseKey = arrayMatches[1];
      const baseKeyArrayIndex = arrayMatches[2];
      baseErrorHandler = errors[baseKey][baseKeyArrayIndex];
    }

    // An error handler for a nested key might not be present if the value
    // wasn't present in the original formData sent for validation.
    // See https://iw-hitachi.atlassian.net/browse/COM-288
    if (!(nestedKey in baseErrorHandler)) {
      baseErrorHandler[nestedKey] = createErrorHandler();
    }

    return fetchNestedError(nestedKey, baseErrorHandler);
  }
  return errors[errorPath];
};

export const schemaValidate = <T extends object, U extends ValidationContext>(
  formData: T,
  schema: ObjectSchema<T>,
  validationContext?: U
) =>
  schema.isValidSync(formData, {
    strict: true,
    abortEarly: false,
    context: validationContext || {}
  });

export const validateFormDataHelper = <
  T extends object,
  U extends ValidationContext
>(
  formData: T,
  errors: FormValidation,
  schema: ObjectSchema<T>,
  validationContext?: U
) => {
  try {
    schema.validateSync(formData, {
      strict: true,
      abortEarly: false,
      context: validationContext || {}
    });
  } catch (error) {
    if (error instanceof ValidationError) {
      error.inner.forEach((innerError: ValidationError) => {
        const errorHandler = fetchNestedError(innerError.path, errors);
        if (errorHandler) {
          errorHandler.addError(innerError.message);
        }
      });
    }
  }
};
