/**
 * TODO (Phase 3):
 * After Phase 3 roll-out - remove runtime and test code regarding enabledfeatures and retailerData that has been added on this PR https://bitbucket.org/novuna/compass-ui/pull-requests/1224.
 * Example:
 * 'isPhaseThreeEnabledForRetailer'
 */
import { RetailerConfig } from 'hitachi-retail-core/build/api/retailerConfig';
import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { RetailerService } from '../../services/retailer';
import {
  fetchAllBranches,
  fetchBranchesForProposing,
  fetchRetailer
} from '../../store/retailer/actions';

interface GetRetailerSagaParams {
  retailerService: RetailerService;
}

export interface IsPhaseThreeEnabledForRetailer {
  isPhaseThreeEnabledForRetailer?: boolean;
}

export const extractRetailerFromResponse = ({
  name,
  supplierNumber,
  buildingName: houseName,
  buildingNo: houseNumber,
  streetName: street,
  county,
  postcode: postCode,
  town,
  isHeadOffice,
  hasChildBranches,
  mailorderEnabled,
  automaticSettlementEnabled,
  partialSettlementsEnabled,
  esatNoteEnabled,
  tailoringIncreaseTermEnabled,
  tailoringIncreaseDepositEnabled,
  tailoringReduceLoanEnabled,
  tailoringIncreaseLoanEnabled,
  tailoringReduceTermEnabled,
  nonMandatoryEmailEnabled,
  softSearchEnabled,
  immediatePayoutEnabled,
  proposeApplicationsEnabled,
  retailerNormalisedName,
  tailoringIbcReduceLoanEnabled,
  tailoringIbcIncreaseDepositEnabled,
  tailoringIbcIncreaseLoanEnabled,
  tailoringIbcReduceTermEnabled,
  moSoftSearchEnabled,
  pbfSoftSearchEnabled,
  isPhaseThreeEnabledForRetailer
}: RetailerConfig & IsPhaseThreeEnabledForRetailer): Retailer &
  IsPhaseThreeEnabledForRetailer => ({
  name,
  supplierNumber,
  isHeadOffice,
  address: {
    houseName,
    houseNumber,
    street,
    town,
    county,
    postCode
  },
  hasChildBranches,
  mailorderEnabled,
  automaticSettlementEnabled,
  partialSettlementsEnabled,
  esatNoteEnabled,
  tailoringIncreaseTermEnabled,
  tailoringIncreaseDepositEnabled,
  tailoringReduceLoanEnabled,
  tailoringIncreaseLoanEnabled,
  tailoringReduceTermEnabled,
  nonMandatoryEmailEnabled,
  softSearchEnabled,
  immediatePayoutEnabled,
  proposeApplicationsEnabled,
  retailerNormalisedName,
  tailoringIbcReduceLoanEnabled,
  tailoringIbcIncreaseDepositEnabled,
  tailoringIbcIncreaseLoanEnabled,
  tailoringIbcReduceTermEnabled,
  moSoftSearchEnabled,
  pbfSoftSearchEnabled,
  ...(isPhaseThreeEnabledForRetailer ? { isPhaseThreeEnabledForRetailer } : {}),
  ...(hasChildBranches !== undefined ? { hasChildBranches } : {})
});

export const getRetailerSaga = ({ retailerService }: GetRetailerSagaParams) =>
  function*() {
    try {
      const response: RetailerConfig &
        IsPhaseThreeEnabledForRetailer = yield call(
        retailerService.getRetailer
      );
      yield put(fetchRetailer.success(extractRetailerFromResponse(response)));
    } catch (error) {
      yield put(fetchRetailer.failure(error));
    }
  };

export const getAllBranchesSaga = ({
  retailerService
}: GetRetailerSagaParams) =>
  function*() {
    try {
      const response: (RetailerConfig &
        IsPhaseThreeEnabledForRetailer)[] = yield call(
        retailerService.getAllBranches
      );
      const retailers = response.map<Retailer>(branch =>
        extractRetailerFromResponse(branch)
      );

      yield put(fetchAllBranches.success(retailers));
    } catch (error) {
      yield put(fetchAllBranches.failure(error));
    }
  };

export const getBranchesForProposingSaga = ({
  retailerService
}: GetRetailerSagaParams) =>
  function*() {
    try {
      const response: (RetailerConfig &
        IsPhaseThreeEnabledForRetailer)[] = yield call(
        retailerService.getBranchesForProposing
      );
      const retailers = response.map<Retailer>(branch =>
        extractRetailerFromResponse(branch)
      );

      yield put(fetchBranchesForProposing.success(retailers));
    } catch (error) {
      yield put(fetchBranchesForProposing.failure(error));
    }
  };

interface GetRetailerSagaWatcherParams {
  retailerService: RetailerService;
}

export const getRetailerSagaWatcher = ({
  retailerService
}: GetRetailerSagaWatcherParams) =>
  function*() {
    yield takeEvery(
      getType(fetchRetailer.request),
      getRetailerSaga({ retailerService })
    );

    yield takeEvery(
      getType(fetchAllBranches.request),
      getAllBranchesSaga({ retailerService })
    );

    yield takeEvery(
      getType(fetchBranchesForProposing.request),
      getBranchesForProposingSaga({ retailerService })
    );
  };
