import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Login, {
  LoginPropsFromDispatch,
  LoginPropsFromState,
  LoginValues
} from './Login';
import { RootState } from '../../../store';
import { selectRetailerSubdomain } from '../../../store/config/selectors';
import { userLogin } from '../../../store/user/actions';

export const mapStateToProps = (state: RootState): LoginPropsFromState => {
  const {
    user: { loggedIn = true, loggingIn, code, errorMessage, successMessage },
    config: { enabledFeatures }
  } = state;
  return {
    enabledFeatures,
    errorMessage,
    successMessage,
    loggedIn,
    code,
    processing: loggingIn,
    currentRetailer: selectRetailerSubdomain(state)
  };
};

export const mapDispatchToProps = (
  dispatch: Dispatch
): LoginPropsFromDispatch => ({
  onSubmit: (params: LoginValues) => dispatch(userLogin.request(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
