import {
  Box,
  Flex,
  InputWrapper,
  TextField,
  Text,
  SectionError,
  ReadOnlyWrapper,
  RadioGroup,
  NovunaHeading
} from 'compass-design';
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikProps,
  validateYupSchema,
  yupToFormErrors
} from 'formik';
import React, { Fragment, RefObject, useRef } from 'react';
import { getNavigationButtons } from '../../navigationButtons';
import { parseNumericInput } from '../../helpers';
import { lessThan3Years, Step, StepComponentProps } from '../../MultiStepForm';
import ReadOnlyAddressWithDate from '../ReadOnlyAddressWithDate';
import { DetailsCaptureValues } from '../../schema';
import { AddressWithDate } from 'hitachi-retail-core';
import AddressWrapper from '../AddressWrapper';
import cloneDeep from 'lodash/cloneDeep';
import { createApplicantDetailsValidationContext } from 'hitachi-retail-core/build/schemas/applicantDetails';
import { ReadOnlyAddress } from 'components/common/ReadOnlyAddress';
import { enumToRadioOptions } from '../../helpers';
import { accommodationStatus } from 'hitachi-retail-core/build/enums';
import { mainAddressDetailsSchema as baseMainAddressDetailsSchema } from 'hitachi-retail-core/build/schemas/applicantDetails';
import * as yup from 'yup';
import { labelPropsFromMeta } from 'utils/labelPropsFromMeta';
import { getTone } from 'utils/getTone';
import { isTwoDigitValue } from 'utils/dates';

export const mainAddressDetailsSchema = yup.object({
  mainAddressDetails: baseMainAddressDetailsSchema
});

const nonEmptyAddress = (mainAddress: AddressWithDate): boolean =>
  !!mainAddress.address.postCode;

export const addressDetailsValidate = async (
  values: DetailsCaptureValues,
  addressIndex: number
) => {
  const addresses = values.mainAddressDetails.mainAddress;
  const valuesCloned: DetailsCaptureValues = cloneDeep(values);

  // Return the error when address entry is not filled in at all MO-409
  if (!addresses[addressIndex]) {
    return {
      [`mainAddressDetails.mainAddress[${addressIndex}]`]: 'Please enter the address'
    };
  }

  // Prevent the next addresses validation errors to stop from progressing
  valuesCloned.mainAddressDetails.mainAddress.splice(
    addressIndex + 1,
    addresses.length - 1 - addressIndex
  );

  try {
    await validateYupSchema<DetailsCaptureValues>(
      valuesCloned,
      mainAddressDetailsSchema,
      false,
      createApplicantDetailsValidationContext({
        applicantDetails: valuesCloned
      })
    );
  } catch (err) {
    const errors = yupToFormErrors<DetailsCaptureValues>(err);
    const currentAddress = cloneDeep(
      errors?.mainAddressDetails?.mainAddress?.[addressIndex]
    );

    if (currentAddress && typeof currentAddress !== 'string') {
      if (currentAddress.fromMonth || currentAddress.fromYear) {
        return {
          mainAddressDetails: {
            ...errors.mainAddressDetails,
            mainAddress: {
              [addressIndex]: {
                ...currentAddress,
                AddressSince:
                  currentAddress.fromMonth || currentAddress.fromYear
              }
            }
          }
        };
      } else {
        return {
          mainAddressDetails: {
            ...errors.mainAddressDetails,
            mainAddress: {
              [addressIndex]: {
                ...currentAddress
              }
            }
          }
        };
      }
    }

    return errors;
  }
  return {};
};

interface ExistingAddressesInput {
  mainAddresses: AddressWithDate[];
  accommodationStatus: string;
  addressIndex: number;
}

const ExistingAddresses: React.FC<ExistingAddressesInput> = ({
  mainAddresses,
  accommodationStatus,
  addressIndex
}) => (
  <Fragment>
    {mainAddresses
      .filter((_address, index) => {
        return index < addressIndex;
      })
      .map((address, index) => (
        <ReadOnlyAddressWithDate
          key={index}
          address={address}
          accommodationStatus={index === 0 ? accommodationStatus : undefined}
          previousAddress={index > 0 ? mainAddresses[index - 1] : undefined}
        />
      ))}
  </Fragment>
);

export const AddressDetails: React.FC<StepComponentProps<
  DetailsCaptureValues
>> = ({
  initialValues,
  returnUrl,
  supplierOrderReference,
  applicationId,
  addressIndex,
  multiStepSubmit
}) => {
  const whichAddress = addressIndex || 0;
  const addressEditable = initialValues.addressEditable;
  const fromYearRef: RefObject<HTMLInputElement> = useRef(null);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, formikBag) => {
        multiStepSubmit(values, formikBag);
      }}
      validate={values => addressDetailsValidate(values as any, whichAddress)}>
      {(formikBag: FormikProps<DetailsCaptureValues>) => (
        <Form
          onSubmit={e => {
            formikBag.handleSubmit(e);

            // Manually set the touched property on custom field
            formikBag.setFieldTouched(
              `mainAddressDetails.mainAddress.${addressIndex}.AddressSince`,
              true
            );
          }}>
          {whichAddress === 0 && addressEditable === false && (
            <Box data-test-id='pbf-pre-populated-address' my={5}>
              <ReadOnlyWrapper label='Current Address' tone='positive'>
                <ReadOnlyAddress
                  address={
                    initialValues.mainAddressDetails?.mainAddress?.[0].address
                  }
                />
              </ReadOnlyWrapper>
            </Box>
          )}
          {whichAddress !== 0 && (
            <ExistingAddresses
              mainAddresses={initialValues.mainAddressDetails.mainAddress}
              accommodationStatus={
                initialValues.mainAddressDetails.accommodationStatus
              }
              addressIndex={whichAddress}
            />
          )}
          {whichAddress !== 0 && (
            <NovunaHeading
              as='h2'
              data-test-id='previous-address-heading'
              mt={5}>
              Previous address
            </NovunaHeading>
          )}

          {/*
           * Error against the fieldset (eg. address) can be either a string (error message)
           *   or an object with the fieldset errors (eg. address.postCode).
           *   We want to display the fieldset level error below
           */}
          {typeof (formikBag?.errors?.mainAddressDetails?.mainAddress?.[
            whichAddress
          ] as any)?.address === 'string' && (
            <SectionError
              id={`mainAddressDetails.mainAddress.${whichAddress}.address`}
              mt={5}
              mb={3}>
              {
                (formikBag?.errors?.mainAddressDetails?.mainAddress?.[
                  whichAddress
                ] as any)?.address
              }
            </SectionError>
          )}
          {whichAddress !== 0 && (
            <Text
              mt={2}
              mb={4}
              sx={{ fontSize: 2 }}
              data-test-id='previous-address-info'>
              We may ask you for up to 2 previous addresses, depending on the
              length of time you lived at each.
            </Text>
          )}
          <Fragment>
            {whichAddress === 0 && (
              <Field name={`mainAddressDetails.accommodationStatus`}>
                {({
                  field,
                  meta
                }: FieldProps<string, DetailsCaptureValues>) => (
                  <Box>
                    <InputWrapper
                      id='mainAddressDetails.accommodationStatus'
                      label='Residential status'
                      error={meta.error}
                      {...labelPropsFromMeta({ meta }, 'label')}>
                      <RadioGroup
                        name='mainAddressDetails.accommodationStatus'
                        data-test-id='accommodation-status-radio'
                        options={enumToRadioOptions(
                          accommodationStatus,
                          field.value,
                          'mainAddressDetails.accommodationStatus'
                        )}
                        onChange={formikBag.handleChange}
                      />
                    </InputWrapper>
                  </Box>
                )}
              </Field>
            )}

            <Field
              name={`mainAddressDetails.mainAddress.${addressIndex}.AddressSince`}>
              {({
                field,
                meta,
                form
              }: FieldProps<string, DetailsCaptureValues>) => {
                const { fromMonth, fromYear } = form.values.mainAddressDetails
                  .mainAddress[whichAddress] || {
                  fromMonth: '',
                  fromYear: ''
                }; // Safe guard for initial values

                const fieldTouched = () => {
                  form.setFieldTouched(
                    `mainAddressDetails.mainAddress.${addressIndex}.AddressSince`,
                    true
                  );
                };

                return (
                  <Box mt={4}>
                    <InputWrapper
                      id={`mainAddressDetails.mainAddress.${addressIndex}.AddressSince`}
                      label='At address since'
                      description='The month and year you moved in'
                      error={meta.error}
                      {...labelPropsFromMeta({ meta }, 'label')}>
                      <Flex>
                        <Box sx={{ maxWidth: '149px' }}>
                          <TextField
                            {...labelPropsFromMeta({ meta }, 'input')}
                            {...field}
                            type='text'
                            id={`mainAddressDetails.mainAddress.${addressIndex}.fromMonth`}
                            data-test-id='fromMonth'
                            name='address since: month'
                            onChange={e => {
                              fieldTouched();
                              form.setFieldValue(
                                `mainAddressDetails.mainAddress.${addressIndex}.fromMonth`,
                                e.target.value
                              );
                              if (
                                isTwoDigitValue(e.target.value) &&
                                fromYearRef.current
                              ) {
                                fromYearRef.current.focus();
                                form.setFieldValue(
                                  `mainAddressDetails.mainAddress.${addressIndex}.fromMonth`,
                                  parseNumericInput(e.target.value)
                                );
                              }
                            }}
                            onBlur={e => {
                              fieldTouched();
                              form.setFieldValue(
                                `mainAddressDetails.mainAddress.${addressIndex}.fromMonth`,
                                parseNumericInput(e.target.value)
                              );
                            }}
                            value={fromMonth || ''}
                            tone={getTone(meta)}
                            placeholder='MM'
                          />
                        </Box>
                        <Box ml={2} sx={{ maxWidth: '149px' }}>
                          <TextField
                            {...labelPropsFromMeta({ meta }, 'input')}
                            {...field}
                            type='text'
                            id={`mainAddressDetails.mainAddress.${addressIndex}.fromYear`}
                            data-test-id='fromYear'
                            name='address since: year'
                            onChange={e => {
                              fieldTouched();

                              form.setFieldValue(
                                `mainAddressDetails.mainAddress.${addressIndex}.fromYear`,
                                parseNumericInput(e.target.value)
                              );
                            }}
                            onBlur={fieldTouched}
                            value={fromYear || ''}
                            tone={getTone(meta)}
                            placeholder='YYYY'
                            ref={fromYearRef}
                          />
                        </Box>
                      </Flex>
                    </InputWrapper>
                  </Box>
                );
              }}
            </Field>
            {(whichAddress > 0 ||
              (whichAddress === 0 && addressEditable !== false)) && (
              <AddressWrapper
                {...formikBag}
                addressIndex={whichAddress}
                handleFormikErrors={errors => formikBag.setErrors(errors)}
                handleValidate={values =>
                  addressDetailsValidate(values as any, whichAddress)
                }
              />
            )}
          </Fragment>
          {getNavigationButtons({
            formikBag,
            applicationId,
            returnUrl,
            supplierOrderReference
          })}
        </Form>
      )}
    </Formik>
  );
};

export const mainAddressDetailsStep: Step<DetailsCaptureValues> = {
  component: AddressDetails,
  title: 'Home Address',
  description:
    'This is your primary residence and the delivery address for your purchase',
  addressIndex: 0
};

export const firstPreviousAddressDetailsStep: Step<DetailsCaptureValues> = {
  component: AddressDetails,
  title: 'Home Address',
  description:
    'This is your primary residence and the delivery address for your purchase',
  addressIndex: 1,
  shouldDisplay: ({ mainAddressDetails }) =>
    lessThan3Years(mainAddressDetails.mainAddress[0])
};

export const secondPreviousAddressDetailsStep: Step<DetailsCaptureValues> = {
  component: AddressDetails,
  title: 'Home Address',
  description:
    'This is your primary residence and the delivery address for your purchase',
  addressIndex: 2,
  shouldDisplay: ({ mainAddressDetails }) =>
    mainAddressDetails.mainAddress.length > 1 &&
    nonEmptyAddress(mainAddressDetails.mainAddress[1]) &&
    lessThan3Years(mainAddressDetails.mainAddress[1])
};
