import {
  deliveryAddressTypeValue,
  otherIdentityValue
} from 'hitachi-retail-core/build/enums';
import { IncompleteFinanceApplication } from 'hitachi-retail-core/build/schemas/financeApplication';
import { UiSchema } from 'react-jsonschema-form';

const deliveryDetailsSubmit = (
  formData: IncompleteFinanceApplication,
  uiSchema: UiSchema
) => {
  const { deliveryDetails } = formData;
  if (deliveryDetails) {
    const { goodsDelivered } = deliveryDetails;
    formData.identityDetails = formData.identityDetails || {};
    const identityDetails = formData.identityDetails;
    const identityDetailsUiSchema = uiSchema.identityDetails;

    // If goods will be delivered to current address, we don't need to ask for proof of identity and it can be set to a specific value and the section hidden
    if (goodsDelivered === deliveryAddressTypeValue.CurrentAddress) {
      identityDetails.proofOfIdentity =
        otherIdentityValue.CurrentAddressDelivery;
      identityDetailsUiSchema['ui:hidden'] = true;
    }
    // Goods not delivered, but identity value is current address delivery, so must be reset and section unhidden
    else if (
      identityDetails.proofOfIdentity ===
      otherIdentityValue.CurrentAddressDelivery
    ) {
      identityDetails.proofOfIdentity = undefined;
      delete identityDetailsUiSchema['ui:hidden'];
    }
  }
};

export default deliveryDetailsSubmit;
