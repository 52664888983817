import { SectionSummaryDefinitions } from 'components/ApplicationSectionSummary';

// Definitions for displaying section summaries
const sectionSummaryDefinitions: SectionSummaryDefinitions = {
  customerPresent: [
    {
      title: 'Is the customer present?',
      properties: 'customerPresent'
    }
  ],
  personalDetails: [
    {
      title: 'Full name',
      // Array of properties will be joined with spaces
      properties: ['title', 'firstName', 'lastName']
    },
    {
      properties: 'maritalStatus'
    },
    {
      properties: 'dateOfBirth',
      format: 'date'
    }
  ],
  contactDetails: [
    {
      properties: 'emailAddress'
    },
    {
      properties: 'phoneNumber'
    }
  ],
  mainAddressDetails: [
    {
      title: 'Current address',
      properties: 'mainAddress',
      format: 'currentAddress'
    },
    {
      title: 'Time at current address',
      properties: 'mainAddress',
      format: 'timeAtCurrentAddress'
    },
    {
      properties: 'accommodationStatus'
    }
  ],
  deliveryDetails: [
    {
      properties: 'goodsDelivered'
    },
    {
      properties: 'deliveryAddress',
      format: 'address'
    },
    {
      properties: 'movingToAddress'
    }
  ],
  identityDetails: [
    {
      properties: 'proofOfIdentity'
    }
  ],
  financialDetails: [
    {
      properties: 'employmentStatus'
    },
    {
      properties: 'spouseEmploymentStatus'
    },
    {
      properties: 'incomeValue',
      format: 'currencyWhole'
    },
    {
      properties: 'financialDependants'
    }
  ],
  bankAccountDetails: [
    {
      properties: 'name'
    },
    {
      properties: 'sortCode',
      format: 'bankSortCode'
    },
    {
      properties: 'accountNumber'
    }
  ]
};

export default sectionSummaryDefinitions;
