import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { Dispatch } from 'redux';
import { applicantLogout } from '../applicantApp/store/applicantLogout/actions';
import { resetStore } from '../store/actions';
import { userLogout } from '../store/user/actions';
import {
  GetApplicantAppApiClientParams,
  requestAttachAccessTokenFromTheStore
} from './api';

interface GetAppApiClientParams {
  dispatch: Dispatch;
  apiClient: AxiosInstance;
}

export const getMailOrderClient = ({
  apiClient,
  store,
  dispatch
}: GetAppApiClientParams & GetApplicantAppApiClientParams): AxiosInstance => {
  apiClient.interceptors.request.use(request =>
    requestAttachAccessTokenFromTheStore({ request, store })
  );

  apiClient.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response.status === 401) {
        dispatch(applicantLogout());
        dispatch(resetStore());
      }
      return Promise.reject(error);
    }
  );

  return apiClient;
};

export const getF2fClient = ({
  apiClient,
  dispatch
}: GetAppApiClientParams): AxiosInstance => {
  apiClient.interceptors.response.use(
    response => response,
    async error => {
      if (error.response.status === 401) {
        const originalRequest = error.config;

        if (shouldRequestTokenRefresh(originalRequest)) {
          originalRequest._retry = true;
          try {
            await apiClient.post('auth/refreshToken');
            // eslint-disable-next-line @typescript-eslint/return-await
            return apiClient.request(originalRequest as AxiosRequestConfig);
          } catch {
            // eslint-disable-line no-empty
          }
        }
        dispatch(userLogout.request());
        return;
      }
      return Promise.reject(error);
    }
  );

  return apiClient;
};

const isARetry = (request: any) => request._retry;
const isRefreshTokenRequest = (request: any) =>
  request.url?.match(/auth\/refreshToken/);
const shouldRequestTokenRefresh = (request: any) =>
  !isARetry(request) && !isRefreshTokenRequest(request);

/**
 * Extract the error data from a failed Axios HTTP request
 *
 * @param error An error thrown by Axios
 */
export const getErrorData = (error: any): any => {
  return error && error.response && error.response.data;
};
