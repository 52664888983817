import { Box, Text } from 'compass-design';
import { AddressWithDate } from 'hitachi-retail-core';
import { accommodationStatus as accommodationStatusEnum } from 'hitachi-retail-core/build/enums';
import React from 'react';
import { DateTime } from 'luxon';
import { ReadOnlyAddress } from 'components/common/ReadOnlyAddress';
import { ApplicantMainAddressDetails } from 'hitachi-retail-core/build/schemas/applicantDetails';

const dateFromAddress = (address: AddressWithDate) => {
  const { fromMonth, fromYear } = address;
  const date = DateTime.fromObject({ month: fromMonth, year: fromYear });
  return date.toFormat('MMM yyyy');
};

const getBorderProps = (hasBorder: boolean): {} =>
  hasBorder
    ? {
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: 'monochrome.4'
      }
    : {};

const ReadOnlyAddressesSummary: React.FC<ApplicantMainAddressDetails> = ({
  mainAddress,
  accommodationStatus
}) => {
  const prettyAccommodationStatus =
    accommodationStatus &&
    accommodationStatusEnum?.enumNames?.[
      accommodationStatusEnum.enum.indexOf(accommodationStatus)
    ];

  return (
    <div>
      <Box
        data-test-id='pbf-summary-page-address-main'
        sx={{
          ...getBorderProps(!!mainAddress?.[1]),
          flexGrow: 1,
          fontSize: 2,
          paddingBottom: '12px'
        }}>
        {prettyAccommodationStatus && (
          <Text
            sx={{ fontSize: 2 }}
            data-test-id='pbf-summary-page-address-main-accommodation'>
            {prettyAccommodationStatus}
          </Text>
        )}
        <Text
          sx={{ fontSize: 2 }}
          data-test-id='pbf-summary-page-address-main-duration'>
          {dateFromAddress(mainAddress[0])} - Today
        </Text>
        <br />
        <ReadOnlyAddress
          address={mainAddress[0].address}
          data-test-id='pbf-summary-page-address-main-address'
        />
      </Box>
      {mainAddress?.[1] && (
        <Box
          data-test-id='pbf-summary-page-address-previous1'
          mt={'28px'}
          sx={{
            ...getBorderProps(!!mainAddress?.[2]),
            flexGrow: 1,
            fontSize: 2,
            paddingBottom: '12px'
          }}>
          <Text
            sx={{ fontSize: 2 }}
            data-test-id='pbf-summary-page-address-main-previous1-duration'>
            {dateFromAddress(mainAddress[1])} -{' '}
            {dateFromAddress(mainAddress[0])}
          </Text>
          <br />
          <ReadOnlyAddress
            address={mainAddress[1].address}
            data-test-id='pbf-summary-page-address-main-previous1-address'
          />
        </Box>
      )}
      {mainAddress?.[2] && (
        <Box
          data-test-id='pbf-summary-page-address-previous2'
          mt={'28px'}
          sx={{
            flexGrow: 1,
            fontSize: 2,
            paddingBottom: '12px'
          }}>
          <Text
            sx={{ fontSize: 2 }}
            data-test-id='pbf-summary-page-address-previous2-duration'>
            {dateFromAddress(mainAddress[2])} -{' '}
            {dateFromAddress(mainAddress[1])}
          </Text>
          <br />
          <ReadOnlyAddress
            address={mainAddress[2].address}
            data-test-id='pbf-summary-page-address-previous2-address'
          />
        </Box>
      )}
    </div>
  );
};

export default ReadOnlyAddressesSummary;
