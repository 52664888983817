import { Box, ReadOnlyWrapper, Text } from 'compass-design';
import { AddressWithDate } from 'hitachi-retail-core';
import { accommodationStatus as accommodationStatusEnum } from 'hitachi-retail-core/build/enums';
import React from 'react';
import { ReadOnlyAddress } from 'components/common/ReadOnlyAddress';
import { DateTime } from 'luxon';

interface ReadOnlyAddressWithDateInput {
  address: AddressWithDate;
  previousAddress?: AddressWithDate;
  accommodationStatus?: string;
}

const dateFromAddress = (address: AddressWithDate) => {
  const { fromMonth, fromYear } = address;
  const date = DateTime.fromObject({ month: fromMonth, year: fromYear });

  // MMM - month as an abbreviated localized string e.g. Aug
  // yyyy - four-digit year e.g. 2014
  return date.toFormat('MMM yyyy');
};

const ReadOnlyAddressWithDate: React.FC<ReadOnlyAddressWithDateInput> = ({
  address,
  previousAddress,
  accommodationStatus
}) => {
  const prettyAccomodationStatus =
    accommodationStatus &&
    accommodationStatusEnum?.enumNames?.[
      accommodationStatusEnum.enum.indexOf(accommodationStatus)
    ];
  const fromDate = dateFromAddress(address);
  const toDate = previousAddress ? dateFromAddress(previousAddress) : 'Today';

  return (
    <Box mb={5}>
      <ReadOnlyWrapper label='Address' tone='positive'>
        {prettyAccomodationStatus && <Text>{prettyAccomodationStatus}</Text>}
        <Text>
          {fromDate} - {toDate}
        </Text>
        <br />
        <ReadOnlyAddress address={address.address} />
      </ReadOnlyWrapper>
    </Box>
  );
};

export default ReadOnlyAddressWithDate;
