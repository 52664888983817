import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { ResetStoreAction } from '..';
import { resetStore } from '../actions';
import {
  fetchPreviousApplications,
  PreviousApplication,
  resetSearchApplicationsAction,
  searchApplicationsAction
} from './actions';

export type PreviousApplicationsAction = ActionType<
  typeof fetchPreviousApplications
>;

export type ResetSearcApplicationsAction = ActionType<
  typeof resetSearchApplicationsAction
>;

export type SearchApplicationsAction = ActionType<
  typeof searchApplicationsAction
>;

export interface State {
  errorMessage?: string;
  previousApplications?: PreviousApplication[];
  totalCount?: number;
  loading: boolean;
}

export const initialState: State = { loading: true };

export const reducer: Reducer<
  State,
  | PreviousApplicationsAction
  | ResetStoreAction
  | SearchApplicationsAction
  | ResetSearcApplicationsAction
> = (state = initialState, action) => {
  switch (action.type) {
    case getType(fetchPreviousApplications.request): {
      return { ...state, loading: true };
    }

    case getType(fetchPreviousApplications.success): {
      return {
        ...state,
        previousApplications: action.payload.applications,
        loading: false
      };
    }

    case getType(fetchPreviousApplications.failure): {
      return { ...state, errorMessage: action.payload.message, loading: false };
    }

    case getType(searchApplicationsAction.request): {
      return { ...state, loading: true };
    }

    case getType(searchApplicationsAction.success): {
      return {
        ...state,
        loading: false,
        previousApplications: action.payload.applications,
        totalCount: action.payload.total_count
      };
    }

    case getType(searchApplicationsAction.failure): {
      return {
        ...state,
        loading: false
      };
    }

    case getType(resetSearchApplicationsAction):
    case getType(resetStore): {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
