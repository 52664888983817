import { NovunaSingleColumnLayout, Text } from 'compass-design';
import React from 'react';

export const Loading: React.FC = () => (
  <NovunaSingleColumnLayout>
    <Text sx={{ fontSize: 4 }} data-test-id='compass-loading'>
      Loading&hellip;
    </Text>
  </NovunaSingleColumnLayout>
);
