import { DefaultSagaParams } from '../../sagas';

import { GoodsListItem } from 'hitachi-retail-core';

export interface GoodsListService {
  getGoodsList: (id: string) => Promise<{ goodsList: GoodsListItem[] }>;
}

export const getGoodsListService = ({
  apiClient
}: DefaultSagaParams): GoodsListService => ({
  getGoodsList: async id => {
    let response;

    try {
      response = await apiClient.get<Promise<{ goodsList: GoodsListItem[] }>>(
        `protected/retailer/${id}/goods-list`
      );
    } catch {
      throw new Error('Goods list service API call failed');
    }

    return response.data;
  }
});
