import { push } from 'connected-react-router';
import { put, select, takeEvery } from 'redux-saga/effects';
import { selectEnabledFeatures } from 'store/config/selectors';
import { getType } from 'typesafe-actions';
import { getApplicationStartRoute } from '../../routes';
import { resetStore } from '../../store/actions';
import { createNewApplication } from '../../store/application/actions';
import { fetchRetailer } from '../../store/retailer/actions';

export function* createNewApplicationSaga() {
  yield put(resetStore());
  yield put(fetchRetailer.request());

  const enabledFeatures = yield select(selectEnabledFeatures);
  yield put(push(getApplicationStartRoute(enabledFeatures)));
}

export function* createNewApplicationSagaWatcher() {
  yield takeEvery(getType(createNewApplication), createNewApplicationSaga);
}
