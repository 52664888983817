import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  APPLICANT_AUTHORIZATION_FAILURE,
  APPLICANT_AUTHORIZATION_REQUEST,
  APPLICANT_AUTHORIZATION_SUCCESS,
  APPLICANT_TOKEN_EXCHANGE_FAILURE,
  APPLICANT_TOKEN_EXCHANGE_REQUEST,
  APPLICANT_TOKEN_EXCHANGE_SUCCESS,
  SAVE_PRE_AUTH_PATH,
  SAVE_CODE_VERIFIER,
  SAVE_PRE_AUTH_APPLICATION_ID
} from './constants';

export interface ApplicantTokenExchangeRequest {
  authorizationCode: string;
  codeVerifier?: string;
  authState?: string;
}

interface ApplicantAuthorizationRequest {
  id: string;
}

interface ApplicantTokenExchangeSuccess {
  accessToken: string;
}

interface ApplicantTokenExchangeSuccess {
  accessToken: string;
}

interface SavePreAuthPath {
  preAuthPath: string | null;
}

interface SavePreAuthApplicationId {
  applicationId: string;
}

interface SaveCodeVerifier {
  codeVerifier: string;
}

export const applicantTokenExchange = createAsyncAction(
  APPLICANT_TOKEN_EXCHANGE_REQUEST,
  APPLICANT_TOKEN_EXCHANGE_SUCCESS,
  APPLICANT_TOKEN_EXCHANGE_FAILURE
)<ApplicantTokenExchangeRequest, ApplicantTokenExchangeSuccess, Error>();

export const applicantAuthorization = createAsyncAction(
  APPLICANT_AUTHORIZATION_REQUEST,
  APPLICANT_AUTHORIZATION_SUCCESS,
  APPLICANT_AUTHORIZATION_FAILURE
)<ApplicantAuthorizationRequest, void, Error>();

export const savePreAuthPath = createStandardAction(SAVE_PRE_AUTH_PATH)<
  SavePreAuthPath
>();

export const savePreAuthApplicationId = createStandardAction(
  SAVE_PRE_AUTH_APPLICATION_ID
)<SavePreAuthApplicationId>();

export const saveCodeVerifier = createStandardAction(SAVE_CODE_VERIFIER)<
  SaveCodeVerifier
>();
