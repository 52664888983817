import { FeatureConfig } from 'hitachi-retail-core/build/features/features';
import React, { ErrorInfo } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store';
import { getApplicationStartRoute } from '../../routes';

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<
  { enabledFeatures: FeatureConfig },
  ErrorBoundaryState
> {
  public static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  public componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true });
  }

  public render() {
    const { enabledFeatures } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      const email = 'customerservice@novunapersonalfinance.co.uk';
      const mailto = 'mailto:' + email;

      return (
        <div data-test-id='compass-general-error'>
          <h1>Unfortunately an error has occurred.</h1>
          <p>
            Please try again or alternatively contact our Customer Experience
            team on 0344 375 5500
            <br />
            or alternatively email <a href={mailto}>{email}</a>.
          </p>
          <br />
          <a href={getApplicationStartRoute(enabledFeatures)}>Go home</a>
        </div>
      );
    }

    return this.props.children;
  }
}

const mapStateToProps = ({
  config: { enabledFeatures }
}: RootState): { enabledFeatures: FeatureConfig } => ({
  enabledFeatures
});

export default connect(mapStateToProps, {})(ErrorBoundary);
