import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { UserInputWithError } from 'retailerApp/components/EditLoanCardState/EditLoanCardState';

const useSetTotalCost = (userInputDelay: number) => {
  const [totalCost, setTotalCost] = useState<UserInputWithError>({
    value: '',
    error: false
  });

  const [totalCostUpdated, setTotalCostUpdated] = useState(false);

  const setTotalCostDebounce = useCallback(
    debounce((totalCostInput: string) => {
      setTotalCost({ ...totalCost, value: totalCostInput });
      setTotalCostUpdated(true);
    }, userInputDelay),
    []
  );

  return {
    totalCost,
    setTotalCostDebounce,
    totalCostUpdated,
    setTotalCostUpdated,
    setTotalCost
  };
};

export default useSetTotalCost;
