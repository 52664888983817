import { GetRadioOptions } from '../getRadioOptions';
import { PbfRadioOption } from 'compass-design';

const getPbfRadioOptions = ({
  loanDetails
}: GetRadioOptions): PbfRadioOption[] => {
  const radioOptions: PbfRadioOption[] = [];

  if (loanDetails.increaseTermOffer) {
    radioOptions.push({
      tailoringOption: 'term',
      text: 'Increase term'
    });
  }

  if (loanDetails.increaseDepositOffer) {
    radioOptions.push({
      tailoringOption: 'deposit',
      text: 'Increase deposit'
    });
  }

  if (loanDetails.reduceTermOffer) {
    radioOptions.push({
      tailoringOption: 'reduceTerm',
      text: 'Reduce term'
    });
  }

  return radioOptions;
};

export default getPbfRadioOptions;
