import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import { useSelector } from 'store';
import {
  selectApplicationStatus,
  selectFetchApplicationStatus
} from 'store/application/selectors';
import RedirectToJourney from 'applicantApp/components/RedirectToJourney';
import { AsyncStatus } from 'applicantApp/store/AsyncStatus';

export type AppStatusProtectedRouteProps = RouteProps & {
  allowedStatuses: Array<ApplicationStatus>;
};

const Loading = () => (
  <div data-test-id='compass-components-route-application-loading' />
);

const ApplicationNotFound = () => (
  <>
    <div data-test-id='compass-applicant-app-error-title'>
      <h1 data-test-id='mo-apply-for-loan-page-heading'>Applicant Quote.</h1>
    </div>
    <div data-test-id='compass-applicant-app-missing'>
      The application cannot be retrieved
    </div>
  </>
);

const AppStatusRestrictedRoute: React.FC<AppStatusProtectedRouteProps> = ({
  component: Component,
  allowedStatuses,
  path,
  ...rest
}) => {
  const fetchApplicationStatus = useSelector(selectFetchApplicationStatus);
  const appStatus = useSelector(selectApplicationStatus);

  const renderComponent = (props: any) => {
    return Component && <Component {...props} />;
  };

  switch (fetchApplicationStatus) {
    case AsyncStatus.Failure:
      return <ApplicationNotFound />;
    case AsyncStatus.Success:
      return allowedStatuses.includes(appStatus) ? (
        <Route {...rest} render={renderComponent} />
      ) : (
        <Route component={RedirectToJourney} />
      );
    default:
      return <Loading />;
  }
};

export default AppStatusRestrictedRoute;
