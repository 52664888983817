import { DocumentIdentifier } from 'hitachi-retail-core/build/enums/documentDownloadConfirmation';
import { ActionType, createAsyncAction } from 'typesafe-actions';
import * as app from './constants';

export type DocumentDownloadConfirmationAction = ActionType<
  typeof documentDownloadConfirmation
>;

export interface DocumentDownloadConfirmationRequestPayload {
  applicationId: string;
  documentIdentifier: DocumentIdentifier;
}

export const documentDownloadConfirmation = createAsyncAction(
  app.DOCUMENT_DOWNLOAD_CONFIRMATION_REQUEST,
  app.DOCUMENT_DOWNLOAD_CONFIRMATION_SUCCESS,
  app.DOCUMENT_DOWNLOAD_CONFIRMATION_FAILURE
)<DocumentDownloadConfirmationRequestPayload, void, Error>();
