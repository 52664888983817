import {
  Box,
  Button,
  InputWrapper,
  Layout,
  Link,
  Message,
  NovunaHeading,
  NovunaPBFFooter,
  NovunaPBFHeader,
  NovunaSingleColumnLayout,
  Text,
  TextField
} from 'compass-design';
import { Tone } from 'compass-design/lib/components/Input/tones';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import { FieldMetaProps } from 'formik/dist/types';
import { eligibleApplicantDateOfBirth } from 'hitachi-retail-core/build/schemas/applicantDetails';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import * as yup from 'yup';
import {
  combineDate,
  CombineDateInput,
  padDate
} from 'applicantApp/pages/Details/components/helpers';

export interface PropsFromState {
  signingId: string;
  pdfUrl?: string;
  error: boolean;
  credentialsError: boolean;
  pdfBlob?: Blob;
  fetching: boolean;
}

export interface VerificationDetails {
  dateOfBirth: string;
  last4DigitsOfPhoneNumber: string;
}

export interface PropsFromDispatch {
  downloadFromServer: (
    signingId: string,
    verificationDetails: VerificationDetails
  ) => void;
}

export const verificationDetailsSchema = yup.object<VerificationDetails>({
  dateOfBirth: eligibleApplicantDateOfBirth,
  last4DigitsOfPhoneNumber: yup
    .string()
    .matches(/^\d{4}$/)
    .required()
});

const phoneErrorMsg = ({
  meta
}: FieldProps<string, VerificationDetails>): string => {
  return meta.error ? 'Enter 4 digits' : '';
};

const dateOfBirthErrorMsg = ({
  meta
}: FieldProps<string, VerificationDetails>): string => {
  if (meta.error) {
    return meta.error.startsWith('Customer is under 18')
      ? 'Customer is under 18'
      : 'Please enter a valid date';
  }
  return '';
};

const getTone = (meta: FieldMetaProps<string>): Tone => {
  if (!meta.touched) {
    return 'neutral';
  }
  return meta.error ? 'negative' : 'positive';
};

export type Props = PropsFromState & PropsFromDispatch;

class Agreement extends React.Component<Props> {
  public fileDownloadLink = React.createRef<HTMLAnchorElement>();

  public componentDidUpdate(prevProps: PropsFromState) {
    if (
      this.props.pdfUrl !== prevProps.pdfUrl &&
      this.fileDownloadLink.current
    ) {
      if (navigator.msSaveOrOpenBlob) {
        // For Internet Explorer
        navigator.msSaveOrOpenBlob(
          this.props.pdfBlob,
          `${this.props.signingId}.pdf`
        );
      } else {
        this.fileDownloadLink.current.click();
      }
    }
  }

  public handleSubmit = (verificationDetails: VerificationDetails) => {
    const { signingId } = this.props;
    if (signingId) {
      this.props.downloadFromServer(signingId, verificationDetails);
    }
  };

  public renderAgreement = (pdfUrl: string) => {
    const initialValues: VerificationDetails = {
      dateOfBirth: '--',
      last4DigitsOfPhoneNumber: ''
    };

    const downloadAgreementText = this.props.fetching
      ? 'Downloading'
      : 'Download agreement';

    return (
      <>
        <NovunaHeading as='h1' mb={3} data-test-id='agreement-header'>
          Confirm your identity
        </NovunaHeading>
        <Text mb={3}>
          Before downloading your agreement, please verify the information
          below. We recommend that you save a copy of the agreement.
        </Text>

        {this.props.credentialsError && (
          <Box mb={4}>
            <Message variant='error'>
              <NovunaHeading as='h3' mb={1}>
                Something went wrong
              </NovunaHeading>
              <Text>The phone number or date of birth are incorrect</Text>
            </Message>
          </Box>
        )}

        <Formik
          initialValues={initialValues}
          validationSchema={verificationDetailsSchema}
          validateOnChange={false}
          onSubmit={this.handleSubmit as any}>
          {(_formikBag: FormikProps<VerificationDetails>) => (
            <Form noValidate>
              <Field name='last4DigitsOfPhoneNumber'>
                {(props: FieldProps<string, VerificationDetails>) => (
                  <Box mb={3}>
                    <InputWrapper
                      id='phone-last-4-digits'
                      label='Last 4 digits of phone number'
                      description='The number you used when applying'
                      error={phoneErrorMsg(props)}
                      tone={getTone(props.meta)}>
                      <TextField
                        short
                        type='tel'
                        id='phone-last-4-digits'
                        required
                        maxLength={4}
                        {...props.field}
                        tone={getTone(props.meta)}
                        data-test-id='compass-download-agreement-last4DigitsOfPhoneNumber'
                      />
                    </InputWrapper>
                  </Box>
                )}
              </Field>
              <Field name='dateOfBirth'>
                {(props: FieldProps<string, VerificationDetails>) => {
                  const { field, form } = props;
                  const { value, ...fieldRest } = field;
                  const [year, month, day] = value.split('-');
                  const current = { year, month, day };
                  const getOnChangeHandler = (name: keyof CombineDateInput) => (
                    e: React.ChangeEvent<HTMLInputElement>
                  ) => {
                    form.setFieldValue(
                      'dateOfBirth',
                      combineDate({ ...current, [name]: e.target.value })
                    );
                  };
                  const getOnBlurHandler = (name: keyof CombineDateInput) => (
                    e: React.ChangeEvent<HTMLInputElement>
                  ) => {
                    form.setFieldValue(
                      'dateOfBirth',
                      padDate({ ...current, [name]: e.target.value })
                    );
                  };
                  return (
                    <>
                      <InputWrapper
                        id='dateOfBirth'
                        label='Date of birth'
                        error={dateOfBirthErrorMsg(props)}
                        tone={getTone(props.meta)}>
                        <Box
                          sx={{
                            maxWidth: '306px',
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr 1fr',
                            gap: 2
                          }}>
                          <TextField
                            type='text'
                            id='dateOfBirth.day'
                            data-test-id='compass-download-agreement-date-of-birth-day'
                            required
                            maxLength={2}
                            value={day}
                            {...fieldRest}
                            tone={getTone(props.meta)}
                            onChange={getOnChangeHandler('day')}
                            onBlur={getOnBlurHandler('day')}
                            placeholder='DD'
                          />
                          <TextField
                            type='text'
                            id='dateOfBirth.month'
                            data-test-id='compass-download-agreement-date-of-birth-month'
                            required
                            maxLength={2}
                            value={month}
                            {...fieldRest}
                            tone={getTone(props.meta)}
                            onChange={getOnChangeHandler('month')}
                            onBlur={getOnBlurHandler('month')}
                            placeholder='MM'
                          />
                          <TextField
                            type='text'
                            id='dateOfBirth.year'
                            data-test-id='compass-download-agreement-date-of-birth-year'
                            required
                            maxLength={4}
                            value={year}
                            {...fieldRest}
                            tone={getTone(props.meta)}
                            onChange={getOnChangeHandler('year')}
                            placeholder='YYYY'
                          />
                        </Box>
                      </InputWrapper>
                    </>
                  );
                }}
              </Field>

              <Button
                mt={5}
                isJumbo
                type='submit'
                data-test-id='compass-download-agreement'>
                {downloadAgreementText}
              </Button>

              <a
                style={{ display: 'none' }}
                data-test-id='compass-download-agreement-from-browser'
                ref={this.fileDownloadLink}
                href={pdfUrl}
                download>
                download
              </a>
            </Form>
          )}
        </Formik>
        <Box mt={6}>
          <Text>
            If you experience further issues please contact the Customer
            Experience Team on 0344 375 5500 or email{' '}
            <Link href='mailto:customerservice@novunapersonalfinance.co.uk'>
              customerservice@novunapersonalfinance.co.uk
            </Link>
          </Text>
        </Box>
      </>
    );
  };

  public renderError = () => {
    return (
      <>
        <NovunaHeading as='h1' mb={3} data-test-id='agreement-header'>
          Something went wrong
        </NovunaHeading>
        <Text mb={3}>
          Unfortunately we had a problem downloading your agreement.
        </Text>
        <Text mb={3}>Please try again.</Text>
        <Text>
          If you experience further issues please contact the Customer
          Experience Team on 0344 375 5500 or email{' '}
          <Link href='mailto:customerservice@novunapersonalfinance.co.uk'>
            customerservice@novunapersonalfinance.co.uk
          </Link>
        </Text>
      </>
    );
  };

  public render() {
    const { error, pdfUrl, signingId } = this.props;

    const content =
      error || !signingId
        ? this.renderError()
        : this.renderAgreement(pdfUrl || '');

    return (
      <>
        <NovunaPBFHeader />
        <Layout>
          <NovunaSingleColumnLayout>
            <Helmet>
              <title>Download agreement - Novuna Finance</title>
            </Helmet>
            <Box sx={{ maxWidth: '620px' }} mx='auto'>
              {content}
            </Box>
          </NovunaSingleColumnLayout>
        </Layout>
        <NovunaPBFFooter />
      </>
    );
  }
}

export default Agreement;
