/* eslint-disable no-await-in-loop,no-case-declarations */
import { DefaultSagaParams } from '../sagas';
import { DecisionClientPostResult } from 'hitachi-retail-core/build/decision/decisionClient';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import {
  LoanTailoring,
  TailoringOptionSelected
} from 'hitachi-retail-core/build/services/tailoring/types';
import { ProductConfig } from 'hitachi-retail-core/build/api/productConfig';
import { ApplicationsService } from './applicationsService';
import { AxiosError } from 'axios';
import { validationMessages } from 'hitachi-retail-core';
import { AppDomainConfig } from 'components/common/useAppDomainConfig';
import { PreviousApplicationDetail } from 'store/previousApplicationDetail/actions';
import { RetailEnv } from 'utils/envs';

export type DecisionResponse = Pick<
  DecisionClientPostResult,
  'applicationStatus' | 'decisionId' | 'maxInstalmentAmount'
> & {
  tailoringExpiryDate: string | null;
  softSearchExpiryDate: string | null;
  loanTailoring?: LoanTailoring;
  productConfig?: ProductConfig;
};

export interface AsyncDecisionResponse {
  decisionId: string;
}

export enum LatestDecisionStatus {
  pending = 'PENDING',
  error = 'ERROR',
  processed = 'PROCESSED'
}

export interface AsyncLatestDecisionResponse {
  applicationStatus: ApplicationStatus;
  latestDecisionStatus: LatestDecisionStatus;
}

export interface DecisionRequest {
  id: string;
  revertApplicationDecision?: any;
  tailoringOptionSelected?: TailoringOptionSelected;
  notes?: string;
  blackbox?: string;
  isLoanAmend?: boolean;
}

export interface DecisionService {
  getDecision: (
    payload: DecisionRequest,
    isPhaseThreeEnabled: boolean
  ) => Promise<GetDecision>;
}

export interface GetDecision {
  decisionType: ApplicationStatus;
  maxInstalmentAmount: string | null;
  softSearchExpiryDate: string | null;
  tailoringExpiryDate?: string | null;
  loanTailoring?: LoanTailoring;
  productConfig?: ProductConfig;
  applicationRehydrate?: PreviousApplicationDetail;
}

export const decisionTimedOutErrorMessage =
  'Failed to retrieve decision. Request timed out.';
export const decisionCallFailedErrorMessage =
  'Failed to retrieve decision. Request failed.';
// TODO (Phase 3): Once Phase 3 is rolled-out a pair of these errors above will be redundant.
export const asyncDecisionFailedErrorMessage =
  'Asynchronous decision was not processed in time. Request failed.';
export const asyncDecisionTimedOutErrorMessage =
  'Asynchronous decision was not processed in time. Request timed-out.';
export const asyncDecisionInProgressErrorMessage =
  'Asynchronous decision already in progress. Request failed';

export const getDecisionService = ({
  apiClient,
  applicationsService,
  isMoPbf = false,
  appDomainConfig
}: DefaultSagaParams & {
  applicationsService: ApplicationsService;
  isMoPbf?: boolean;
  appDomainConfig?: AppDomainConfig;
}): DecisionService => {
  const getDecision = async (
    decisionRequest: DecisionRequest,
    isPhaseThreeEnabled: boolean
  ): Promise<GetDecision> => {
    /**
     * TODO (Phase3): This implementation can be transfered as
     * This function calls the '/async' endpoint of the compass-service which then communicates with the new asynchronous decision API.
     * It sends the initial request and polls for application status changes to create an illusion of a synchronous journey for the UI.
     * @param {GetDecisionParams} getAsyncDecisionParams
     * @returns
     */
    const getAsyncDecision = async ({
      decisionRequest
    }: GetDecisionParams): Promise<GetDecision> => {
      const { id } = decisionRequest;

      const submitDecisionEndpoint = isMoPbf
        ? `mo/application/${id}/decision/async`
        : `protected/async/decision`;

      const decisionResponse = await apiClient.post<AsyncDecisionResponse>(
        submitDecisionEndpoint,
        decisionRequest,
        {
          validateStatus: status => {
            return status === 409 || (status >= 200 && status < 400);
          }
        }
      );

      if (decisionResponse.status === 409) {
        throw new Error(asyncDecisionInProgressErrorMessage);
      }

      if (
        decisionResponse.status !== 200 ||
        !decisionResponse?.data?.decisionId
      ) {
        throw new Error(asyncDecisionFailedErrorMessage);
      }

      const pollingStartTime = +new Date();
      const maxPollingTimeSeconds =
        appDomainConfig?.currentEnv === RetailEnv.staging ? 60 : 36; // seconds
      let elapsedPollingTimeSeconds = 0;

      do {
        // Poll against our latest decisions API
        const getLatestDecisionEndpoint = isMoPbf
          ? `mo/application/${id}/latest-decision`
          : `/protected/applications/${id}/latest-decision`;

        const { status, data: latestDecision } = await apiClient.get<
          AsyncLatestDecisionResponse
        >(getLatestDecisionEndpoint);
        if (status !== 200) {
          throw new Error(asyncDecisionFailedErrorMessage);
        }

        const { latestDecisionStatus } = latestDecision;

        switch (latestDecisionStatus) {
          case LatestDecisionStatus.error:
            throw new Error(asyncDecisionFailedErrorMessage);
          case LatestDecisionStatus.processed:
            const application = await applicationsService.getById({
              id,
              isMoPbf
            });
            return {
              decisionType: latestDecision.applicationStatus,
              maxInstalmentAmount: application.maxInstalmentAmount ?? null,
              softSearchExpiryDate: application.softSearchExpiryDate ?? null,
              tailoringExpiryDate: application.tailoringExpiryDate ?? null,
              loanTailoring: application.document
                .loanTailoring as LoanTailoring,
              productConfig: application.document.product
                ?.productConfig as ProductConfig,
              applicationRehydrate: application
            };
          default:
            break;
        }

        await waitForSeconds(2);

        elapsedPollingTimeSeconds = calculateElapsedTimeInSeconds(
          pollingStartTime
        );
      } while (elapsedPollingTimeSeconds <= maxPollingTimeSeconds);

      throw new Error(asyncDecisionTimedOutErrorMessage);
    };

    /**
     * This function calls the synchronous legacy decision of the compass-service which then communicates with the legacy decision API.
     * TODO (Phase3): REMOVE THIS AS PART OF CLEAN-UP
     * @param {GetDecisionParams} getSyncDecisionParams
     * @returns
     */
    const getSyncDecision = async ({
      decisionRequest
    }: GetDecisionParams): Promise<GetDecision> => {
      let response;
      try {
        const endpoint = isMoPbf
          ? `mo/application/${decisionRequest.id}/decision`
          : `protected/decision`;

        response = await apiClient.post<DecisionResponse>(
          endpoint,
          decisionRequest
        );
      } catch (err) {
        const axiosError = err as AxiosError;
        if (
          axiosError.response?.data.message ===
          validationMessages.BRANCH_MISMATCH
        ) {
          throw new Error(axiosError.response?.data.message);
        }

        throw new Error(
          err.code === 'ECONNABORTED'
            ? decisionTimedOutErrorMessage
            : decisionCallFailedErrorMessage
        );
      }

      const decisionType = response.data?.applicationStatus;

      if (!decisionType) {
        throw new Error('No decision type returned in decision API response');
      }

      return {
        decisionType,
        maxInstalmentAmount: response.data?.maxInstalmentAmount ?? null,
        softSearchExpiryDate: response.data?.softSearchExpiryDate ?? null,
        tailoringExpiryDate: response.data?.tailoringExpiryDate ?? null,
        loanTailoring: response.data?.loanTailoring,
        productConfig: response.data?.productConfig
      };
    };

    return isPhaseThreeEnabled
      ? getAsyncDecision({ decisionRequest })
      : getSyncDecision({ decisionRequest });
  };

  return { getDecision };
};

const waitForSeconds = async (seconds: number) =>
  new Promise(resolve => setTimeout(resolve, seconds * 1000));

const calculateElapsedTimeInSeconds = (startTime: number) =>
  (+new Date() - startTime) / 1000;

type GetDecisionParams = {
  decisionRequest: DecisionRequest;
};
