import { AppConfig } from 'hitachi-retail-core';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { ConfigService } from '../../services/config';
import { RetailerConfigService } from '../../services/retailerConfig';
import { fetchConfig } from '../../store/config/actions';
import { fetchRetailerConfig } from '../../store/retailerConfig/actions';
import { NotificationType, showNotification } from '../../store/ui/actions';
import { DefaultConfigParams } from '../index';

interface GetRetailerConfigSagaWorkerParams {
  retailerConfigService: RetailerConfigService;
}

interface GetConfigSagaWorkerParams {
  configService: ConfigService;
}

export const getRetailerConfigSaga = ({
  retailerConfigService
}: GetRetailerConfigSagaWorkerParams) =>
  function*() {
    try {
      const response = yield call(retailerConfigService.getConfig);

      yield put(fetchRetailerConfig.success(response));
    } catch (err) {
      yield put(fetchRetailerConfig.failure(err));
      yield put(
        showNotification({
          message:
            "There was a problem loading this page - please refresh the page and contact us if that doesn't work",
          type: NotificationType.DANGER
        })
      );
    }
  };

export const getConfigSaga = ({ configService }: GetConfigSagaWorkerParams) =>
  function*() {
    try {
      const response: AppConfig = yield call(configService.getConfig);

      yield put(fetchConfig.success(response));
    } catch (err) {
      yield put(fetchConfig.failure(err));
      yield put(
        showNotification({
          message:
            "There was a problem loading this page - please refresh the page and contact us if that doesn't work",
          type: NotificationType.DANGER
        })
      );
    }
  };

interface GetRetailerConfigSagaWatcher {
  retailerConfigService: RetailerConfigService;
}

export const getRetailerConfigSagaWatcher = ({
  retailerConfigService
}: GetRetailerConfigSagaWatcher) =>
  function*() {
    yield takeEvery(
      getType(fetchRetailerConfig.request),
      getRetailerConfigSaga({ retailerConfigService })
    );
  };

export const getConfigSagaWatcher = ({ configService }: DefaultConfigParams) =>
  function*() {
    yield takeEvery(
      getType(fetchConfig.request),
      getConfigSaga({ configService })
    );
  };
