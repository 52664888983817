import { List, ListItem } from 'compass-design';
import React from 'react';

const ThemedList: React.FC<{
  items: string[];
}> = ({ items }) => (
  <List fontSize='1'>
    {items.map((item, index) => (
      <ListItem key={index}>{item}</ListItem>
    ))}
  </List>
);

export default ThemedList;
