import { LoanAttribute } from 'compass-design';
import { Decimal } from 'hitachi-retail-core/build/utils/decimal';
import {
  getLoanAmount,
  getRepaymentAmount,
  getTotalAmountPayable,
  getTotalRepaymentAmount
} from 'hitachi-retail-core/build/finance';
import { LoanComparisonCardPreStringValues } from 'retailerApp/components/MixingDeck/getMixingDeckData';

export interface GetEditLoanOffer {
  totalCost: Decimal;
  deposit: Decimal;
  interestRate: Decimal;
  repaidOver: number;
  isSoftSearch: boolean;
  updatedItem: LoanAttribute;
  deferralPeriod?: number | null;
}

const getEditLoanOffer = ({
  totalCost,
  deposit,
  interestRate,
  repaidOver,
  isSoftSearch,
  updatedItem,
  deferralPeriod
}: GetEditLoanOffer): LoanComparisonCardPreStringValues => {
  const amountBorrowed = getLoanAmount({
    goodsAmount: totalCost,
    deposit
  });

  let monthlyRepayment = new Decimal(0);

  if (amountBorrowed.greaterThan(new Decimal(0))) {
    monthlyRepayment = getRepaymentAmount({
      instalments: repaidOver,
      loanAmount: amountBorrowed,
      apr: interestRate,
      deferralPeriod
    });
  }
  const totalRepayable = getTotalRepaymentAmount({
    instalments: repaidOver,
    repaymentAmount: monthlyRepayment
  });

  const totalAmountPayable = getTotalAmountPayable({
    deposit,
    instalments: repaidOver,
    repaymentAmount: monthlyRepayment
  });

  const interestPayable = interestRate.isZero()
    ? new Decimal(0)
    : totalRepayable.minus(amountBorrowed);

  return {
    amountBorrowed,
    monthlyRepayment,
    totalCost,
    deposit,
    interestRate,
    representativeAPR: interestRate,
    interestPayable,
    repaidOver,
    totalRepayable,
    totalAmountPayable,
    isSoftSearch,
    updatedItem
  };
};

export default getEditLoanOffer;
