import React, { Fragment } from 'react';
import { Text, List as CompassList, ListItem, Box } from 'compass-design';
import { SectionHeader } from './SectionHeader';
import { HitachiPhoneNumber } from 'applicantApp/pages/LoanAdvanceInformation/LoanAdvanceInformation';

export const RegulatedText: React.FC = () => (
  <Text mb={4}>
    This is some important information to provide you with an adequate
    explanation of the regulated loan you are applying for from Novuna Personal
    Finance.
  </Text>
);

interface RegulatedListProps {
  isDeferred: boolean;
  earlySettlementFee: boolean;
}

export const RegulatedList: React.FC<RegulatedListProps> = ({
  isDeferred,
  earlySettlementFee
}) => (
  <Fragment>
    <Box mb={4}>
      <SectionHeader>Purpose of the loan</SectionHeader>

      <CompassList fontSize='2'>
        <ListItem>
          The agreement is to finance the purchase of the specified goods and/or
          services from us.
        </ListItem>
      </CompassList>
    </Box>

    <Box mb={4}>
      <SectionHeader>Repayment of the loan</SectionHeader>

      <CompassList fontSize='2'>
        {isDeferred ? (
          <ListItem>
            If your application is accepted, monthly repayments will start after
            your deferral period ends.
          </ListItem>
        ) : (
          ''
        )}
        <ListItem>
          The amount of your monthly repayment can be found in the
          &lsquo;Repayments&rsquo; section in your agreement.
        </ListItem>
        <ListItem>
          The total amount payable under your agreement can be found in the
          section headed &lsquo;Description of goods/services and credit&rsquo;.
        </ListItem>
      </CompassList>
    </Box>

    {earlySettlementFee && (
      <Box mb={4}>
        <SectionHeader>Repayment during deferral period</SectionHeader>

        <CompassList fontSize='2'>
          <ListItem>
            If you pay the credit in full before your first monthly payment
            falls due no interest will be charged but you will have to pay an
            administration charge. The administration charge will be the lesser
            of the interest accrued during the deferral or £29.
          </ListItem>
        </CompassList>
      </Box>
    )}

    <Box mb={4}>
      <SectionHeader>Your repayment responsibilities</SectionHeader>

      <CompassList fontSize='2'>
        <ListItem>
          You are responsible for paying each instalment by the date due. Late
          payment or failure to repay the balance in full could result in
          adverse consequences for you. As a responsible lender, we are obliged
          to notify credit reference agencies where payments have been missed.
        </ListItem>
      </CompassList>
    </Box>

    {isDeferred && (
      <Box mb={4}>
        <SectionHeader>Deferral period</SectionHeader>

        <CompassList fontSize='2'>
          <ListItem>
            Your agreement includes a deferral period during which you do not
            need to make any repayments. During this time, interest will accrue
            at the contractual rate, however any repayments that are made will
            reduce your outstanding balance and will not attract any interest
            charges.
          </ListItem>
          <ListItem>
            If you do not repay the total amount of credit before the deferral
            period ends, then you will be charged interest as detailed within
            your agreement on the outstanding balance from the start of the
            agreement until the credit is fully repaid.
          </ListItem>
        </CompassList>
      </Box>
    )}

    <Box mb={4}>
      <SectionHeader>Consequences of missing payments</SectionHeader>

      <CompassList fontSize='2'>
        <ListItem>
          Missing payments may result in:
          <CompassList fontSize='2' nested={true}>
            <ListItem useHyphen={true}>
              the total cost of the debt growing
            </ListItem>
            <ListItem useHyphen={true}>
              you incurring default charges (these are set out in the
              &lsquo;Additional Charges&rsquo; section of your agreement)
            </ListItem>
            <ListItem useHyphen={true}>
              legal action being taken against you. You may have to pay the cost
              of such proceedings which may include our legal cost
            </ListItem>
            <ListItem useHyphen={true}>
              a negative impact on your credit score and may make obtaining
              credit more difficult in the future.
            </ListItem>
          </CompassList>
        </ListItem>
      </CompassList>
    </Box>

    <Box mb={4}>
      <SectionHeader>Withdrawing from the agreement</SectionHeader>

      <CompassList fontSize='2'>
        <ListItem>
          You have the right to withdraw from the agreement, without giving any
          reason, for a period of 14 days beginning on the day after this
          agreement starts. If you withdraw from the loan during this period the
          loan should be repaid in full within 41 days and where applicable,
          will incur interest. See your loan agreement for full details.
        </ListItem>
        <ListItem>
          If you withdraw from the credit agreement within the 14 day period the
          agreement will be treated as if it was never entered into.
        </ListItem>
      </CompassList>
    </Box>

    <Box mb={4}>
      <SectionHeader>Pre-contract information</SectionHeader>

      <CompassList fontSize='2'>
        <ListItem>
          You will be provided with some pre-contract information about your
          loan agreement which you can take away with you. You should read this
          information carefully.
        </ListItem>
      </CompassList>
    </Box>

    <Box>
      <SectionHeader>Any questions?</SectionHeader>

      <CompassList fontSize='2'>
        <ListItem>
          If you have any questions or would like any further information or
          explanation please ask us or call <HitachiPhoneNumber />.
        </ListItem>
      </CompassList>
    </Box>
  </Fragment>
);
