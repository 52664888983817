import {
  Button,
  InputWrapper,
  NovunaHeading,
  TextField,
  PasswordStrengthBar,
  Box,
  Text
} from 'compass-design';
import { Field, FieldProps, Form, Formik } from 'formik';
import { checkPasswordValidity } from 'hitachi-retail-core/build/utils/password';
import React, { useEffect, useState } from 'react';
import { validationMessages } from 'hitachi-retail-core/build/application/messages';
import { useSelector } from 'react-redux';
import { selectRetailer } from 'store/retailer/selectors';
import Notification from 'retailerApp/components/Notification/Notification';
import { getContextualPageData } from '../utils';
import { selectRetailerSubdomain } from 'store/config/selectors';
import { PasswordStrengthLabel } from 'components/PasswordStrengthLabel';

interface UpdatePasswordValues {
  retailerName: string;
  username: string;
  password: string;
  confirmPassword: string;
  currentPassword: string;
}

export interface UpdatePasswordPropsFromState {
  errorMessage?: string;
  username?: string;
  retailerName?: string;
  setPasswordProcessing: boolean;
  setPasswordSuccess: boolean;
}

export interface UpdatePasswordPropsFromDispatch {
  onSubmit: (values: UpdatePasswordValues) => void;
  onBackToLogin: () => void;
}

export type UpdatePasswordProps = UpdatePasswordPropsFromState &
  UpdatePasswordPropsFromDispatch;

export interface UpdatePasswordState {
  password: string;
  passwordError?: string;
}

export const UpdatePassword = ({
  username,
  setPasswordProcessing,
  setPasswordSuccess,
  errorMessage,
  onSubmit
}: UpdatePasswordProps) => {
  const retailer = useSelector(selectRetailer);
  const subdomain = useSelector(selectRetailerSubdomain);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [warningMessages, setWarningMessages] = useState<string | undefined>(
    ''
  );

  useEffect(() => {
    const PasswordCheck = checkPasswordValidity({
      password: password,
      inputs: getContextualPageData({ retailer, username, subdomain })
    });

    setIsPasswordValid(PasswordCheck.isValid);
    setPasswordStrength(PasswordCheck.score || 0);

    if (password.length >= 1 && password.length <= 7) {
      setWarningMessages(
        'Your password requires a minimum length of 8 characters.'
      );
      return;
    }

    if (PasswordCheck.messages) {
      const messageString = PasswordCheck.messages
        .slice(0, 2)
        .map((message: string, index: number, arr: string[]) => {
          if (index < arr.length - 1) {
            return `${message}\n`;
          } else {
            return message;
          }
        })
        .join('');
      setWarningMessages(messageString);
    }
  }, [password, retailer, username, subdomain]);

  const initialValues: UpdatePasswordValues = {
    retailerName: retailer.retailerNormalisedName ?? '',
    username: username ?? '',
    password: '',
    confirmPassword: '',
    currentPassword: ''
  };

  const passwordsMatch = password === confirmPassword;

  const pwForm = (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, formik) => {
        onSubmit(values);

        // Reset
        formik.resetForm();
        setPasswordStrength(0);
        setPassword('');
        setConfirmPassword('');
        setIsPasswordValid(false);
        setWarningMessages(undefined);
      }}
      enableReinitialize>
      <Form noValidate>
        <Field name='currentPassword'>
          {({ field, meta }: FieldProps<string, UpdatePasswordValues>) => {
            return (
              <InputWrapper id='current-password' label='Current Password'>
                <TextField
                  id='current-password'
                  type='password'
                  mb={2}
                  data-test-id='compass-current-password-control'
                  tone={meta.touched && meta.error ? 'negative' : 'neutral'}
                  {...field}
                  onChange={(e: {
                    target: { value: React.SetStateAction<string> };
                  }) => {
                    field.onChange(e);
                  }}
                />
              </InputWrapper>
            );
          }}
        </Field>
        <Field name='password'>
          {({ field, meta }: FieldProps<string, UpdatePasswordValues>) => {
            return (
              <InputWrapper
                id='set-password'
                label='Password'
                error={
                  !!warningMessages && password.length > 0
                    ? warningMessages
                    : undefined
                }
                tone={
                  !!warningMessages && password.length > 0
                    ? 'negative'
                    : 'neutral'
                }>
                <TextField
                  id='set-password'
                  type='password'
                  placeholder='Use at least 8 characters '
                  data-test-id='compass-set-password-control'
                  tone={meta.touched && meta.error ? 'negative' : 'neutral'}
                  {...field}
                  onChange={(e: {
                    target: { value: React.SetStateAction<string> };
                  }) => {
                    setPassword(e.target.value);
                    field.onChange(e);
                  }}
                />
              </InputWrapper>
            );
          }}
        </Field>
        <PasswordStrengthBar strength={passwordStrength || 0} mb={'10px'} />
        <PasswordStrengthLabel strength={passwordStrength || 0} />
        <Field name='confirmPassword'>
          {({ field, meta }: FieldProps<string, UpdatePasswordValues>) => {
            return (
              <InputWrapper
                id='confirm-password'
                label='Confirm password'
                error={
                  !passwordsMatch && confirmPassword.length > 0
                    ? validationMessages.INVALID_CONFIRMATION_PASSWORD_VALUE
                    : undefined
                }
                tone={
                  !passwordsMatch && confirmPassword.length > 0
                    ? 'negative'
                    : 'neutral'
                }>
                <TextField
                  id='confirm-password'
                  type='password'
                  data-test-id='compass-confirm-password-control'
                  tone={meta.touched && meta.error ? 'negative' : 'neutral'}
                  {...field}
                  onChange={(e: {
                    target: { value: React.SetStateAction<string> };
                  }) => {
                    setConfirmPassword(e.target.value);
                    field.onChange(e);
                  }}
                />
              </InputWrapper>
            );
          }}
        </Field>
        <Button
          mt={5}
          disabled={
            setPasswordProcessing || !isPasswordValid || !passwordsMatch
          }
          data-test-id='compass-set-password-submit'
          isJumbo>
          {setPasswordProcessing ? 'Please wait\u2026' : 'Confirm'}
        </Button>
      </Form>
    </Formik>
  );

  return (
    <>
      <NovunaHeading as='h1' mb={3}>
        Set password
      </NovunaHeading>

      {(setPasswordSuccess || errorMessage) && (
        <Box mb={3}>
          {setPasswordSuccess && (
            <Notification
              type='success'
              message='Password updated!'
              textAlign='left'
              px={[]}
              showCloseBtn={false}>
              <NovunaHeading as='h4'>Password updated</NovunaHeading>
              <Text>Your password has been successfully updated.</Text>
            </Notification>
          )}
          {errorMessage && (
            <Notification
              type='warning'
              message='Failed to update password!'
              textAlign='left'
              px={[]}
              showCloseBtn={false}>
              <NovunaHeading as='h4'>Failed to update</NovunaHeading>
              <Text>
                {errorMessage ??
                  'There was an issue when updating your password, please try again.'}
              </Text>
            </Notification>
          )}
        </Box>
      )}

      {pwForm}
    </>
  );
};

export default UpdatePassword;
