import { Box } from 'compass-design';
import { FormikProps, FormikErrors } from 'formik';
import React, { useState, useCallback, useEffect } from 'react';
import { DetailsCaptureValues } from '../../schema';
import AddressInputWrapper from '../AddressInputWrapper';
import AddressLookupWrapper from '../AddressLookupWrapper';
import { resetPostcodeLookup } from 'store/address/reducer';
import { useDispatch } from 'react-redux';
import { AddressWithDate } from 'hitachi-retail-core';

export type AddressWrapperProps = AddressWrapperOwnProps &
  Pick<
    FormikProps<DetailsCaptureValues>,
    'values' | 'setFieldValue' | 'getFieldMeta'
  >;

export interface AddressWrapperOwnProps {
  addressIndex: number;
  handleValidate: (values: DetailsCaptureValues) => Promise<any>;
  handleFormikErrors: (errors: object) => void;
}

const AddressWrapper: React.FC<AddressWrapperProps> = props => {
  const { addressIndex, values, handleValidate, handleFormikErrors } = props;
  const dispatch = useDispatch();
  const formNamespace = `mainAddressDetails.mainAddress.${addressIndex}.address`;
  const address =
    values?.mainAddressDetails?.mainAddress?.[addressIndex]?.address;
  const [manualEntryMode, setManualEntryMode] = useState(false);
  const [editAddressMode, setEditAddressMode] = useState(false);
  const [addressValid, setAddressValid] = useState<boolean | undefined>();
  let component;

  const setManualEntry = useCallback(
    (value: boolean) => {
      dispatch(resetPostcodeLookup());
      setManualEntryMode(value);
    },
    [setManualEntryMode, dispatch]
  );
  const setEditAddress = useCallback(
    (value: boolean) => {
      dispatch(resetPostcodeLookup());
      setManualEntryMode(false);
      setEditAddressMode(value);
    },
    [setEditAddressMode, setManualEntryMode, dispatch]
  );

  const handleInputConfirm = useCallback(() => {
    setManualEntryMode(false);
    setEditAddressMode(false);
  }, [setManualEntryMode, setEditAddressMode]);

  /*
   * Potential performance bottleneck but neccessary to display Postcode Lookup
   *  when the address is not present in the form.
   * Formik pre-fills the address properties with empty strings and
   *  mark them as "touched" and "valid" so we can't use them for state management
   */
  useEffect(() => {
    const validate = async () => {
      const validationResult = await handleValidate(values);
      handleFormikErrors(validationResult);
      const addressErrors: string | FormikErrors<AddressWithDate> =
        validationResult?.mainAddressDetails?.mainAddress?.[addressIndex]
          ?.address;

      // Exclude address collection errors (eg. chronological order) from address validation value
      if (addressErrors && typeof addressErrors !== 'string') {
        setAddressValid(false);
      } else {
        setAddressValid(true);
      }
    };

    validate();
  }, [
    handleValidate,
    handleFormikErrors,
    values,
    setAddressValid,
    addressIndex,
    address
  ]);

  // Prevent render before the initial validation
  if (addressValid === undefined) {
    return null;
  }

  // Checking if address is valid to prevent rendering manul input for the empty form
  if ((address && addressValid && !editAddressMode) || manualEntryMode) {
    component = (
      <AddressInputWrapper
        {...props}
        addressValid={addressValid}
        formNamespace={formNamespace}
        setEditAddress={setEditAddress}
        manualEntryMode={manualEntryMode}
        setManualEntry={setManualEntry}
      />
    );
  } else {
    component = (
      <AddressLookupWrapper
        {...props}
        formNamespace={formNamespace}
        setManualEntry={setManualEntry}
        onInputConfirm={handleInputConfirm}
      />
    );
  }

  return <Box mt={4}>{component}</Box>;
};

export default AddressWrapper;
