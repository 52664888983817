import { DirectAppUserLoginResponse } from 'hitachi-retail-core/build/api/directApp';
import { UserPolicyResponse } from 'hitachi-retail-core/build/api/userPolicy';
import { UserRole } from 'hitachi-retail-core/build/userPolicy';
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import * as user from './constants';
import { SET_PASSWORD_REDIRECT_TO_LOGIN } from './constants';

// Login
interface UserLoginPayload {
  retailerName: string;
  username: string;
  password: string;
}

interface UserLoginPayload {
  retailerName: string;
  username: string;
  password: string;
}

export interface UserSetPasswordPayload {
  retailerName: string;
  username: string;
  password: string;
  currentPassword?: string;
  session: string;
  givenName: string;
  familyName: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UserSetPasswordResponse {}

export interface UserLoginResponse {
  username: string;
  retailerName?: string;
  familyName?: string;
  givenName?: string;
  session?: string;
  code?: string;
  userRoles?: UserRole[];
  promptPasswordUpdate?: boolean;
}

export const userLogin = createAsyncAction(
  user.LOGIN_REQUEST,
  user.LOGIN_SUCCESS,
  user.LOGIN_FAILURE
)<UserLoginPayload, UserLoginResponse, Error>();

// Set password after the first successful login
export const userSetPassword = createAsyncAction(
  user.SET_PASSWORD_REQUEST,
  user.SET_PASSWORD_SUCCESS,
  user.SET_PASSWORD_FAILURE
)<UserSetPasswordPayload, UserSetPasswordResponse, Error>();

export const userSetPasswordRedirect = createStandardAction(
  SET_PASSWORD_REDIRECT_TO_LOGIN
)();

// Logout
export const userLogout = createAsyncAction(
  user.LOGOUT_REQUEST,
  user.LOGOUT_SUCCESS,
  user.LOGOUT_FAILURE
)<void, void, Error>();

// Session Check
export const sessionCheck = createAsyncAction(
  user.SESSION_CHECK_REQUEST,
  user.SESSION_CHECK_SUCCESS,
  user.SESSION_CHECK_FAILURE
)<void, void, Error>();

// Reset password
export interface SendPasswordResetCodePayload {
  retailerName: string;
  username: string;
}

export const sendPasswordResetCode = createAsyncAction(
  user.SEND_PASSWORD_RESET_CODE_REQUEST,
  user.SEND_PASSWORD_RESET_CODE_SUCCESS,
  user.SEND_PASSWORD_RESET_CODE_FAILURE
)<SendPasswordResetCodePayload, void, Error>();

// Confirm forgot password
export interface ConfirmForgotPasswordPayload {
  retailerName: string;
  username: string;
  newPassword: string;
  verificationCode: string;
}

export const confirmForgotPassword = createAsyncAction(
  user.CONFIRM_FORGOT_PASSWORD_REQUEST,
  user.CONFIRM_FORGOT_PASSWORD_SUCCESS,
  user.CONFIRM_FORGOT_PASSWORD_FAILURE
)<ConfirmForgotPasswordPayload, void, Error>();

// Direct app user Login
interface DirectAppUserLoginPayload {
  directAppLoginToken: string;
}

export const directAppUserLogin = createAsyncAction(
  user.DIRECTAPP_LOGIN_REQUEST,
  user.DIRECTAPP_LOGIN_SUCCESS,
  user.DIRECTAPP_LOGIN_FAILURE
)<DirectAppUserLoginPayload, DirectAppUserLoginResponse, Error>();

export const userPolicy = createAsyncAction(
  user.USER_POLICY_REQUEST,
  user.USER_POLICY_SUCCESS,
  user.USER_POLICY_FAILURE
)<void, UserPolicyResponse, Error>();
