import { NovunaSpinner } from 'compass-design';
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router-dom';
import ApplicationError from '../../../components/ApplicationError';
import { routes } from '../../../routes';

interface DirectAppRouteParams {
  token: string;
}

export interface DirectAppLoginPropsFromState {
  errorMessage?: string;
  directAppLoggedIn?: boolean;
  loggedIn?: boolean;
}

export interface DirectAppPropsFromDispatch {
  directAppLogin: (token: string) => void;
}

export type DirectAppLoginProps = DirectAppLoginPropsFromState &
  DirectAppPropsFromDispatch &
  RouteComponentProps<DirectAppRouteParams>;

const DirectAppLogin = ({
  errorMessage: serviceErrorMessage,
  directAppLoggedIn = false,
  directAppLogin,
  match: {
    params: { token }
  }
}: DirectAppLoginProps) => {
  useEffect(() => {
    directAppLogin(decodeURIComponent(token));
  }, [directAppLogin, token]);

  if (directAppLoggedIn) {
    const referrer = { pathname: routes.apply.quote };
    return <Redirect to={referrer} />;
  }

  if (directAppLoggedIn === false && !serviceErrorMessage) {
    return <NovunaSpinner />;
  }

  return <ApplicationError />;
};

export default DirectAppLogin;
