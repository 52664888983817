import { BankAccountCheckResponse } from 'hitachi-retail-core/build/api/bankCheck';
import { ProductConfig } from 'hitachi-retail-core/build/api/productConfig';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import { IncompleteFinanceApplication } from 'hitachi-retail-core/build/schemas/financeApplication';
import {
  ActionType,
  createAsyncAction,
  createStandardAction
} from 'typesafe-actions';
import { ProductOption } from '../../form/quote';
import * as app from './constants';

export const createNewApplication = createStandardAction(
  app.CREATE_NEW_APPLICATION
)();

interface UpdateActiveApplicationPayload {
  document?: IncompleteFinanceApplication;
  status?: ApplicationStatus;
  softSearchExpiryDate?: string;
  replaceValues?: boolean;
  applicationExpiryDate?: string;
  maxInstalmentAmount?: string;
}

export const updateActiveApplication = createStandardAction(
  app.UPDATE_ACTIVE_APPLICATION
)<UpdateActiveApplicationPayload>();

export type ResumeApplicationAction = ActionType<typeof resumeApplication>;
export const resumeApplication = createStandardAction(app.RESUME_APPLICATION)();

export type AmendApplicationAction = ActionType<typeof amendApplication>;
export const amendApplication = createStandardAction(app.AMEND_APPLICATION)();

export type ClearSelectedProductAction = ActionType<
  typeof clearSelectedProduct
>;
export const clearSelectedProduct = createStandardAction(
  app.CLEAR_SELECTED_PRODUCT
)();

interface SelectApplicationProductOptionPayload {
  productOption: ProductOption;
}

export const selectApplicationProductOption = createStandardAction(
  app.SELECT_APPLICATION_PRODUCT_OPTION
)<SelectApplicationProductOptionPayload>();

interface SelectApplicationProductConfigPayload {
  productConfig: ProductConfig;
  interestFreeCreditUnregulatedEndDate?: Date;
}

export const selectApplicationProductConfig = createStandardAction(
  app.SELECT_APPLICATION_PRODUCT_CONFIG
)<SelectApplicationProductConfigPayload>();

export const bankAccountCheck = createAsyncAction(
  app.BANK_ACCOUNT_CHECK_REQUEST,
  app.BANK_ACCOUNT_CHECK_SUCCESS,
  app.BANK_ACCOUNT_CHECK_FAILURE
)<void, BankAccountCheckResponse, Error>();

const RESET_ACTIVE_APPLICATION_STORE = 'RESET_ACTIVE_APPLICATION_STORE';
export const resetActiveApplicationStore = createStandardAction(
  RESET_ACTIVE_APPLICATION_STORE
)();

export type ResetActiveApplicationStoreAction = ActionType<
  typeof resetActiveApplicationStore
>;
