import {
  Box,
  Button,
  InputWrapper,
  Link,
  Message,
  NovunaHeading,
  Text,
  TextField
} from 'compass-design';
import { Field, FieldProps, Form, Formik } from 'formik';
import { validationMessages } from 'hitachi-retail-core';
import { LoginResponseCode } from 'hitachi-retail-core/build/enums';
import { FeatureConfig } from 'hitachi-retail-core/build/features/features';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';
import { LoggedOutWrapper } from 'retailerApp/components/LoggedOutWrapper';
import * as yup from 'yup';
import { getApplicationStartRoute, routes } from '../../../routes';

export interface LoginValues {
  retailerName: string;
  username: string;
  password: string;
}

export interface LoginPropsFromState {
  enabledFeatures: FeatureConfig;
  errorMessage?: string;
  successMessage?: string;
  loggedIn?: boolean;
  location?: {
    state: {
      referrer: {
        pathname: string;
      };
    };
  };
  processing: boolean;
  currentRetailer: string;
  code?: string;
  directAppLoggedIn?: boolean;
}

export interface LoginPropsFromDispatch {
  onSubmit: (values: LoginValues) => void;
}

export type LoginProps = LoginPropsFromState & LoginPropsFromDispatch;

const referredFromBlockedAccess = (referrer: { pathname: string }) => {
  return referrer.pathname === routes.login;
};

const resetReferrerToDefault = (
  location: any,
  enabledFeatures: FeatureConfig
) => {
  if (location && location.state) {
    location.state.referrer = {
      pathname: getApplicationStartRoute(enabledFeatures)
    };
  }
};

export const Login: React.FunctionComponent<LoginProps> = ({
  enabledFeatures,
  errorMessage: serviceErrorMessage,
  successMessage: serviceSuccessMessage,
  loggedIn = false,
  code,
  processing,
  currentRetailer,
  onSubmit,
  location
}) => {
  if (loggedIn) {
    let referrer = { pathname: getApplicationStartRoute(enabledFeatures) };
    if (location && location.state) {
      referrer = location.state.referrer;
    }

    if (!referredFromBlockedAccess(referrer)) {
      return <Redirect to={referrer} />;
    }

    resetReferrerToDefault(location, enabledFeatures);
  }

  if (code === LoginResponseCode.NewPasswordChallenge) {
    return <Redirect to={{ pathname: routes.setNewPassword }} />;
  }

  const initialValues: LoginValues = {
    retailerName: currentRetailer,
    username: '',
    password: ''
  };

  const removeSpaces = (input: string) => {
    const trimmedInput = input.trim();
    return trimmedInput;
  };

  return (
    <>
      <Helmet>
        <title>Sign in - CreditMaster3</title>
      </Helmet>
      <LoggedOutWrapper>
        {serviceErrorMessage && (
          <Message
            mb={4}
            variant='error'
            data-test-id='compass-login-service-error'>
            <NovunaHeading as='h3' mb={1}>
              Something went wrong
            </NovunaHeading>
            <Text>Please check your details and try again.</Text>
          </Message>
        )}
        {serviceSuccessMessage && (
          <Message
            mb={4}
            variant='success'
            data-test-id='compass-login-service-success'>
            <NovunaHeading as='h3' mb={1}>
              It&rsquo;s done
            </NovunaHeading>
            <Text>{serviceSuccessMessage}</Text>
          </Message>
        )}
        <NovunaHeading as='h1' mb={3}>
          Sign in
        </NovunaHeading>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={values => onSubmit(values)}
          enableReinitialize>
          <Form noValidate>
            {!currentRetailer && (
              <Field name='retailerName'>
                {({ field, meta, form }: FieldProps<string, LoginValues>) => (
                  <InputWrapper
                    id='login-retailer'
                    label='Retailer name'
                    error={meta.error}
                    tone={meta.touched && meta.error ? 'negative' : 'neutral'}>
                    <TextField
                      id='login-retailer'
                      type='text'
                      autoCapitalize='none'
                      data-test-id='compass-login-retailer-control'
                      tone={meta.touched && meta.error ? 'negative' : 'neutral'}
                      {...field}
                      value={form.values.retailerName}
                      onBlur={e => {
                        form.setFieldValue(
                          'retailerName',
                          removeSpaces(e.target.value)
                        );
                      }}
                    />
                  </InputWrapper>
                )}
              </Field>
            )}

            <Box mt={currentRetailer ? 4 : 3}>
              <Field name='username'>
                {({ field, meta, form }: FieldProps<string, LoginValues>) => (
                  <InputWrapper
                    id='login-username'
                    label='Username'
                    error={meta.error}
                    tone={meta.touched && meta.error ? 'negative' : 'neutral'}>
                    <TextField
                      id='login-username'
                      type='text'
                      autoCapitalize='none'
                      autoComplete='username'
                      data-test-id='compass-login-username-control'
                      tone={meta.touched && meta.error ? 'negative' : 'neutral'}
                      {...field}
                      value={form.values.username}
                      onBlur={e => {
                        form.setFieldValue(
                          'username',
                          removeSpaces(e.target.value)
                        );
                      }}
                    />
                  </InputWrapper>
                )}
              </Field>
            </Box>

            <Box mt={4}>
              <Field name='password'>
                {({ field, meta }: FieldProps<string, LoginValues>) => (
                  <InputWrapper
                    id='login-password'
                    label='Password'
                    error={meta.error}
                    tone={meta.touched && meta.error ? 'negative' : 'neutral'}>
                    <TextField
                      id='login-password'
                      autoComplete='current-password'
                      type='password'
                      data-test-id='compass-login-password-control'
                      tone={meta.touched && meta.error ? 'negative' : 'neutral'}
                      {...field}
                    />
                  </InputWrapper>
                )}
              </Field>
            </Box>

            <Button
              id='login-submit-button'
              mt={[5, null, 6]}
              disabled={processing}
              data-test-id='compass-login-submit'
              isJumbo>
              {processing ? 'Signing in\u2026' : 'Sign in'}
            </Button>
          </Form>
        </Formik>
        <Box mt={5}>
          <Link id='login-forgotpassword-link' href={routes.forgotPassword}>
            Forgotten password?
          </Link>
        </Box>
      </LoggedOutWrapper>
    </>
  );
};

const validationSchema = yup.object<LoginValues>({
  retailerName: yup.string().required(validationMessages.REQUIRED_FIELD),
  username: yup.string().required(validationMessages.REQUIRED_FIELD),
  password: yup.string().required(validationMessages.REQUIRED_FIELD)
});

export default Login;
