import { EditDepositProps, LoanAttribute } from 'compass-design';
import { Decimal } from 'hitachi-retail-core/build/utils/decimal';
import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import getEditLoanOffer from 'retailerApp/utils/loanDetails/getEditLoanOffer';
import isNumber from 'utils/isNumber';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectGoodsList,
  selectHasFetchedGoodsList
} from 'store/goodsList/selectors';
import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import { getGoodsList } from 'store/goodsList/actions';
import { selectEnabledFeatures } from 'store/config/selectors';
import {
  selectProcessing,
  selectProposingOnBehalfOf,
  selectRetailer
} from 'store/retailer/selectors';
import formatGoodsList from 'utils/formatGoodsList';
import {
  GoodsDetailsKey,
  GoodsDetailsModalProps,
  SetGoodsDetailsParams
} from '../GoodsDetailsModal/GoodsDetailsModal';
import { NewOfferComparisonCardProps } from '../MixingDeck/MixingDeck';
import checkForErrors from './checkForErrors';
import validateGoodsDetails from './validateGoodsDetails';
import useSetDeposit from 'retailerApp/hooks/useSetDeposit';
import useSetTotalCost from 'retailerApp/hooks/useSetTotalCost';

export const userInputDelay = 500;

export interface EditGoodsDetailsProps {
  goodsDescription: string;
  orderReference: string;
  isEditingGoodsDetails: boolean;
  editGoodsDetails: () => void;
  setGoodsDetails: Dispatch<any>;
}

export interface UserInputWithError {
  value: string;
  error: string | false;
}

export interface EditLoanCardStateProps {
  updatedItem: LoanAttribute;
  offerText: string;
  deposit: Decimal;
  interestRate: Decimal;
  repaidOver: number;
  maximumLoanAmount: Decimal;
  maximumGoodsAmount: Decimal;
  minDepositPercentage: string;
  isSoftSearch?: boolean;
}

export interface EditLoanState {
  editLoanState?: {
    totalCost: UserInputWithError;
    setTotalCost: (totalCostInput: string) => void;
    deposit: UserInputWithError;
    setDeposit: Dispatch<any>;
    editGoodsDetails: EditGoodsDetailsProps;
    showPlaceholders: boolean;
  };
  editLoanOffer?: NewOfferComparisonCardProps;
  goodsDetailsModalProps?: Omit<GoodsDetailsModalProps, 'onContinue'>;
}

const EditLoanCardState = (
  WrappedComponent: React.ComponentType<any & EditLoanCardStateProps>
) => {
  const EditLoanCardWithState = (props: any) => {
    const {
      editLoanProps,
      loanDetails,
      deferralPeriod,
      isIncreaseLoan
    } = props;

    const [depositInput, setDepositInput] = useState(
      editLoanProps.deposit.toFixed(2)
    );

    const [showPlaceholders, setShowPlaceholders] = useState(true);

    const {
      deposit,
      depositUpdated,
      setDepositDebounce,
      setDeposit,
      setDepositUpdated
    } = useSetDeposit(editLoanProps.deposit, userInputDelay);

    const {
      totalCost,
      setTotalCost,
      setTotalCostDebounce,
      totalCostUpdated,
      setTotalCostUpdated
    } = useSetTotalCost(userInputDelay);

    const maximumLoanAmount: Decimal = editLoanProps.maximumLoanAmount;

    const loanParameters = {
      ...loanDetails.loanParameters,
      maximumGoodsAmount: maximumLoanAmount.plus(
        loanDetails.originalOffer.deposit
      ),
      maximumLoanAmount,
      originalLoanAmount: loanDetails.originalOffer.amountBorrowed as Decimal,
      originalDeposit: loanDetails.originalOffer.deposit as Decimal
    };

    useEffect(() => {
      if (totalCostUpdated || depositUpdated) {
        const editingDeposit = depositUpdated;
        setTotalCostUpdated(false);
        setDepositUpdated(false);

        const totalCostHasError = checkForErrors({
          loanParameters,
          totalCost,
          deposit,
          editingDeposit,
          isIncreaseLoan,
          setTotalCost,
          setDeposit
        });

        setShowPlaceholders(totalCostHasError);
      }
    }, [
      totalCostUpdated,
      depositUpdated,
      totalCost,
      deposit,
      loanParameters,
      isIncreaseLoan,
      setDeposit,
      setDepositUpdated,
      setTotalCost,
      setTotalCostUpdated
    ]);

    // Goods details values and modal
    const [goodsDescription, setGoodsDescription] = useState(
      loanDetails.goodsDescription ?? ''
    );

    const [applicationRef, setApplicationRef] = useState(
      loanDetails.applicationRef ?? ''
    );

    const [isEditingDeposit, setIsEditingDeposit] = useState(false);

    const [isGoodsDetailsModalOpen, setIsGoodsDetailsModalOpen] = useState(
      false
    );

    const [goodsDescriptionError, setGoodsDescriptionError] = useState('');
    const [orderReferenceError, setOrderReferenceError] = useState('');

    const setGoodsDetails = ({ key, value }: SetGoodsDetailsParams) => {
      const goodsDetails = {
        goodsDescription,
        orderReference: applicationRef
      };
      if (key === GoodsDetailsKey.GOODS_DESCRIPTION) {
        setGoodsDescription(value);
        goodsDetails.goodsDescription = value;
      }
      if (key === GoodsDetailsKey.ORDER_REF) {
        setApplicationRef(value);
        goodsDetails.orderReference = value;
      }

      validateGoodsDetails({
        ...goodsDetails,
        setGoodsDescriptionError,
        setOrderReferenceError
      });
    };

    const editLoanOffer: NewOfferComparisonCardProps = {
      offer: getEditLoanOffer({
        totalCost: new Decimal(
          totalCost.value.length > 0 ? totalCost.value : 0
        ),
        deposit: new Decimal(deposit.value.length > 0 ? deposit.value : 0),
        interestRate: editLoanProps.interestRate,
        repaidOver: editLoanProps.repaidOver,
        isSoftSearch: props.isSoftSearch,
        updatedItem: editLoanProps.updatedItem,
        deferralPeriod
      }),
      offerText: editLoanProps.offerText
    };

    const { creditCardDeposit } = loanDetails;
    const editDeposit: EditDepositProps | undefined =
      creditCardDeposit && !isIncreaseLoan
        ? undefined
        : {
            minDepositPercentage: editLoanProps.minDepositPercentage,
            editing: isEditingDeposit,
            setEditing: setIsEditingDeposit,
            value: depositInput,
            errorMessage: deposit.error ? deposit.error : undefined,
            onChange: (depositValue: string) => {
              if (isNumber(depositValue) || depositValue === '') {
                setDepositInput(depositValue);
                setDepositDebounce(depositValue);
              }
            }
          };

    const dispatch = useDispatch();
    let goodsList = useSelector(selectGoodsList);
    const processing = useSelector(selectProcessing);
    const fetched = useSelector(selectHasFetchedGoodsList);
    const { supplierNumber } = useSelector(selectRetailer);
    const enabledFeatures = useSelector(selectEnabledFeatures);
    const proposingOnBehalfOf = useSelector(selectProposingOnBehalfOf);

    goodsList = formatGoodsList({ goodsList, goodsDescription });

    useEffect(() => {
      if (
        !processing &&
        !fetched &&
        enabledFeatures.has(CompassFeature.GOODS_LIST)
      ) {
        const id = proposingOnBehalfOf?.supplierNumber ?? supplierNumber;
        dispatch(getGoodsList.request({ id }));
      }
    }, [
      supplierNumber,
      proposingOnBehalfOf,
      processing,
      fetched,
      enabledFeatures,
      dispatch
    ]);

    return (
      <WrappedComponent
        {...props}
        editLoanState={{
          deposit,
          setDeposit,
          totalCost,
          setTotalCost: setTotalCostDebounce,
          showPlaceholders
        }}
        editLoanOffer={editLoanOffer}
        editDeposit={editDeposit}
        goodsDetailsModalProps={{
          goodsDescription,
          orderReference: applicationRef,
          isGoodsDetailsModalOpen,
          setIsGoodsDetailsModalOpen,
          setGoodsDetails,
          goodsDescriptionError,
          orderReferenceError,
          disableContinueButton: !!(
            goodsDescriptionError || orderReferenceError
          ),
          isSoftSearch: loanDetails.isSoftSearch,
          goodsList
        }}
      />
    );
  };
  return EditLoanCardWithState;
};

export default EditLoanCardState;
