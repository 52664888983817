import { createAsyncAction } from 'typesafe-actions';
import * as app from './constants';

export interface FetchPreContractDownloadUrlRequest {
  applicationId: string;
  forceDownload?: boolean;
}

export const fetchPreContractDownloadUrl = createAsyncAction(
  app.FETCH_PRE_CONTRACT_DOWNLOAD_URL,
  app.FETCH_PRE_CONTRACT_DOWNLOAD_URL_SUCCESS,
  app.FETCH_PRE_CONTRACT_DOWNLOAD_URL_FAILURE
)<FetchPreContractDownloadUrlRequest, string, Error>();
