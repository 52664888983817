import { createSelector } from 'reselect';
import { RootState } from '../index';

const preContractCreditInfoSelector = ({ preContractCreditInfo }: RootState) =>
  preContractCreditInfo;

export const selectPreContractCreditInfoDocumentUrl = createSelector(
  preContractCreditInfoSelector,
  ({ documentUrl }) => documentUrl
);
