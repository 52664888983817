import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import UrlPattern from 'url-pattern';
import { routes } from '../../routes';
import { Loading } from './Loading';
import redirectToLogin from './redirectToLogin';
import {
  CompassFeature,
  FeatureConfig
} from 'hitachi-retail-core/build/features/features';

export interface ProtectedRoutePropsFromDispatch {
  getLoginStatus: () => void;
}

export interface ProtectedRoutePropsFromState {
  isLoggedIn?: boolean;
  isDirectAppLoggedIn?: boolean;
  sessionCheckLoading: boolean;
  enabledFeatures: FeatureConfig;
}

export type ProtectedRouteProps = {
  disableLoadingText?: boolean;
  requiredFeature?: CompassFeature;
} & RouteProps &
  ProtectedRoutePropsFromState &
  ProtectedRoutePropsFromDispatch;

class ProtectedRoute extends React.Component<ProtectedRouteProps> {
  public componentDidMount() {
    this.props.getLoginStatus();
  }

  public render() {
    const {
      isLoggedIn,
      isDirectAppLoggedIn = false,
      component: Component,
      sessionCheckLoading,
      disableLoadingText,
      enabledFeatures,
      requiredFeature,
      ...rest
    } = this.props;

    const renderComponent = (props: any) => {
      if (sessionCheckLoading) {
        return disableLoadingText ? <></> : <Loading />;
      } else {
        if (isDirectAppLoggedIn && isDirectAppAccessBlocked(props)) {
          return redirectToLogin({
            ...props,
            location: { pathname: routes.login }
          });
        }

        if (isLoggedIn === true) {
          return Component && <Component {...props} />;
        } else if (isLoggedIn === false) {
          return redirectToLogin({
            ...props
          });
        } else {
          return null;
        }
      }
    };

    if (requiredFeature && !enabledFeatures?.has(requiredFeature)) {
      return null;
    }

    return <Route {...rest} render={renderComponent} />;
  }
}

export const isDirectAppAccessBlocked = (props: any): boolean => {
  return !Object.values(routes.directApps).some(e =>
    new UrlPattern(e).match(props.location.pathname)
  );
};

export default ProtectedRoute;
