import {
  BackToStoreTextButton,
  generateWsRedirectUrl
} from 'applicantApp/components/ExternalRedirectButtons/ExternalRedirectButtons';
import {
  Box,
  InlineCheckbox,
  LoanAttribute,
  Message,
  NovunaHeading,
  PbfRadioOption,
  TailoringOptions,
  Text
} from 'compass-design';
import { TailoringOption } from 'compass-design/lib/components/TailoringOptions/TailoringOptions';
import { scrollToTop } from 'components/meta/ScrollToTop';
import deepmerge from 'deepmerge';
import { isRegulatedApplication } from 'hitachi-retail-core/build/application';
import {
  getInterestPayable,
  getLoanAmount,
  getRepaymentAmount,
  getTotalAmountPayable,
  getTotalRepaymentAmount
} from 'hitachi-retail-core/build/finance';
import { FinanceApplication } from 'hitachi-retail-core/build/schemas/financeApplication';
import {
  selectApr,
  selectDeferralPeriod,
  selectGoodsAmount,
  selectRepaymentAmount
} from 'hitachi-retail-core/build/selectors';
import {
  EditTermDetails,
  IncreaseDepositDetails,
  isEditTerm,
  isIncreaseDeposit,
  TailoringOptionSelected
} from 'hitachi-retail-core/build/services/tailoring/types';
import { Decimal } from 'hitachi-retail-core/build/utils/decimal';
import React, { useCallback, useState } from 'react';
import { LoanComparisonCardPreStringValues } from 'retailerApp/components/MixingDeck/getMixingDeckData';
import {
  NewOfferComparisonCardProps,
  PbfMixingDeck
} from 'retailerApp/components/MixingDeck/MixingDeck';
import {
  AcceptOfferFunction,
  AffordabilityAcceptProps
} from 'retailerApp/utils/loanDetails/getAffordabilityAccept';
import { AffordabilityDeclineProps } from 'retailerApp/utils/loanDetails/getAffordabilityDecline';
import { LoanDetails } from 'retailerApp/utils/loanDetails/getLoanDetails';
import { formatCurrency } from 'utils/formatters';
import { TailoringConfirmationModal } from './TailoringConfirmationModal';

type TailoringDetails = EditTermDetails | IncreaseDepositDetails;

export type PbfTailoringProps = {
  originalApplication: FinanceApplication;
  maxInstalmentAmount: string;
  affordabilityTailoring: AffordabilityDeclineProps | AffordabilityAcceptProps;
  applicationId: string;
  onAcceptOriginalOffer?: AcceptOfferFunction;
  isSoftSearch?: boolean;
  hideApplicationText?: boolean;
};

const getLoanInstalments = (
  application: FinanceApplication,
  tailoringDetails?: TailoringDetails
) => {
  if (isEditTerm(tailoringDetails)) {
    return tailoringDetails.minimumTerm;
  }

  return application.loanRepayment.instalments;
};

const getMonthlyRepayment = (
  application: FinanceApplication,
  loanAmount: Decimal,
  instalments: number,
  apr: Decimal,
  tailoringDetails?: TailoringDetails
) => {
  if (tailoringDetails) {
    return new Decimal(tailoringDetails.monthlyRepayment);
  }

  const deferralPeriod = selectDeferralPeriod(application);

  return getRepaymentAmount({
    instalments,
    loanAmount,
    apr,
    deferralPeriod
  });
};

const getTotalRepayable = (
  instalments: number,
  repaymentAmount: Decimal,
  tailoringDetails?: TailoringDetails
) => {
  return tailoringDetails
    ? new Decimal(tailoringDetails.totalRepaymentAmount)
    : getTotalRepaymentAmount({ instalments, repaymentAmount });
};

const getDeposit = (
  application: FinanceApplication,
  tailoringDetails?: TailoringDetails
) => {
  if (isIncreaseDeposit(tailoringDetails)) {
    return new Decimal(tailoringDetails.minimumDeposit);
  }
  return new Decimal(application.loanParameters.deposit);
};

const transformFinanceApplicationToMixingDeckProps = (
  application: FinanceApplication,
  updatedLoanAttribute: LoanAttribute,
  tailoringDetails?: EditTermDetails | IncreaseDepositDetails
) => {
  const goodsAmount = selectGoodsAmount(application)!;
  const apr = selectApr(application) ?? new Decimal(0);

  const deposit = getDeposit(application, tailoringDetails);
  const loanAmount = getLoanAmount({ deposit, goodsAmount });
  const instalments = getLoanInstalments(application, tailoringDetails);
  const monthlyRepayment = getMonthlyRepayment(
    application,
    loanAmount,
    instalments,
    apr,
    tailoringDetails
  );
  const totalRepayable = getTotalRepayable(
    instalments,
    monthlyRepayment,
    tailoringDetails
  );
  const totalAmountPayable = getTotalAmountPayable({
    instalments,
    deposit,
    repaymentAmount: monthlyRepayment
  });

  const interestPayable = getInterestPayable({
    totalAmountPayable,
    goodsAmount
  });

  const isDesktopView = window.innerWidth > 768;

  const loanComparisonProps: LoanComparisonCardPreStringValues = {
    totalCost: goodsAmount,
    deposit,
    amountBorrowed: loanAmount,
    repaidOver: instalments,
    interestRate: apr,
    representativeAPR: apr,
    interestPayable,
    totalRepayable,
    totalAmountPayable,
    monthlyRepayment,
    updatedItem: updatedLoanAttribute,
    isSoftSearch: false,
    hideApplicationText: true,
    isDesktopView
  };
  return loanComparisonProps;
};

const getTailoredApplication = (
  originalApplication: FinanceApplication,
  newOfferOptions: NewOfferComparisonCardProps,
  tailoringOptionSelected?: TailoringOptionSelected
): FinanceApplication => {
  const updates = {
    loanRepayment: {
      instalments: newOfferOptions.offer.repaidOver
    },
    loanParameters: {
      ...originalApplication.loanParameters,
      deposit: newOfferOptions.offer.deposit.toFixed(2)
    },
    loanTailoring: {
      optionSelected: tailoringOptionSelected
    }
  };
  const updatedApplication = deepmerge<FinanceApplication>(
    originalApplication,
    updates
  );

  const regulatedUpdate = {
    product: {
      regulated: isRegulatedApplication(updatedApplication)
    }
  };

  return deepmerge(updatedApplication, regulatedUpdate);
};

const getNewOffer = (
  tailoringOption: TailoringOption,
  loanDetails: LoanDetails
): {
  newOffer: NewOfferComparisonCardProps;
  updatedLoanAttribute: LoanAttribute;
} => {
  switch (tailoringOption) {
    case 'term':
      return {
        newOffer: loanDetails.increaseTermOffer!,
        updatedLoanAttribute: LoanAttribute.repaidOver
      };
    case 'deposit':
      return {
        newOffer: loanDetails.increaseDepositOffer!,
        updatedLoanAttribute: LoanAttribute.deposit
      };
    default:
      return {
        newOffer: loanDetails.reduceTermOffer!,
        updatedLoanAttribute: LoanAttribute.reduceRepaidOver
      };
  }
};

const canAcceptOriginalOffer = (
  originalApplication: FinanceApplication,
  onAcceptOriginalOffer?: AcceptOfferFunction
) => {
  return onAcceptOriginalOffer
    ? () =>
        onAcceptOriginalOffer({
          document: {
            ...originalApplication,
            loanTailoring: {
              ...originalApplication.loanTailoring,
              optionSelected: TailoringOptionSelected.rejected
            }
          },
          tailoringOptionSelected: TailoringOptionSelected.rejected
        })
    : undefined;
};

const PbfTailoringOptions: React.FunctionComponent<PbfTailoringProps> = ({
  isSoftSearch = false,
  originalApplication,
  maxInstalmentAmount,
  applicationId,
  affordabilityTailoring,
  onAcceptOriginalOffer,
  hideApplicationText = true
}) => {
  const [tailoringOption, setTailoringOption] = useState<TailoringOption>(
    affordabilityTailoring.preSetTailoringOption
  );
  const [isConfirmModalVisible, setConfirmModalVisible] = useState(false);
  const [confirmPayByDebitCard, setConfirmPayByDebitCard] = useState(false);
  const openModal = useCallback(() => setConfirmModalVisible(true), []);

  const originalMonthlyRepayment = selectRepaymentAmount(originalApplication);
  const newOriginalMonthlyRepayment = originalMonthlyRepayment
    ? formatCurrency(originalMonthlyRepayment)
    : originalMonthlyRepayment;
  const newMaxInstalment = formatCurrency(maxInstalmentAmount);

  const { loanDetails } = affordabilityTailoring;

  const { newOffer, updatedLoanAttribute } = getNewOffer(
    tailoringOption,
    loanDetails
  );

  const loanComparisonPropsOriginal = transformFinanceApplicationToMixingDeckProps(
    originalApplication,
    updatedLoanAttribute
  );

  const loanComparisonPropsTailored = transformFinanceApplicationToMixingDeckProps(
    getTailoredApplication(originalApplication, newOffer),
    updatedLoanAttribute
  );
  const originalDeposit = loanComparisonPropsOriginal.deposit.toFixed(2);

  let tailoringOptionSelected: TailoringOptionSelected;
  let offerText;
  let isAffordabilityDecline = true;
  switch (tailoringOption) {
    case 'term':
      tailoringOptionSelected = TailoringOptionSelected.increaseTerm;
      offerText = (
        <Box mb={4}>
          <Message variant='info'>
            <NovunaHeading
              as='h3'
              mb={1}
              data-test-id='increase-term-info-text'>
              Increase term from {loanComparisonPropsOriginal.repaidOver} to{' '}
              {loanComparisonPropsTailored.repaidOver} months
            </NovunaHeading>
            <Text>
              This is the next term available that reduces monthly payments to{' '}
              {newMaxInstalment} or lower.
            </Text>
          </Message>
        </Box>
      );
      break;
    case 'deposit':
      tailoringOptionSelected = TailoringOptionSelected.increaseDeposit;
      offerText = (
        <Box mb={4}>
          <Message variant='info'>
            <NovunaHeading
              as='h3'
              mb={1}
              data-test-id='increase-deposit-info-text'>
              Increase deposit from £{originalDeposit} to £
              {loanComparisonPropsTailored.deposit.toFixed(2)}
            </NovunaHeading>
            <Text mb={3}>
              You may increase the deposit amount if you choose to pay by debit
              card.
            </Text>
            <Text mb={2} sx={{ fontWeight: 'bold' }}>
              Deposit payment
            </Text>
            <InlineCheckbox
              data-test-id='paid-by-dc-checkbox'
              checked={confirmPayByDebitCard}
              onClick={() => {
                setConfirmPayByDebitCard(!confirmPayByDebitCard);
              }}>
              Deposit will be paid by debit card
            </InlineCheckbox>
          </Message>
        </Box>
      );
      break;
    case 'reduceTerm':
      isAffordabilityDecline = false;
      tailoringOptionSelected = TailoringOptionSelected.reduceTerm;
      offerText = (
        <Box mb={4}>
          <Message variant='info'>
            <NovunaHeading as='h3' mb={1} data-test-id='reduce-term-info-text'>
              Reduce term from {loanComparisonPropsOriginal.repaidOver} to{' '}
              {loanComparisonPropsTailored.repaidOver} months
            </NovunaHeading>
            <Text>
              This will increase the monthly payments to{' '}
              {formatCurrency(loanComparisonPropsTailored.monthlyRepayment)}.
            </Text>
          </Message>
        </Box>
      );
      break;
  }

  const redirectToStoreUrl = originalApplication.returnUrl
    ? generateWsRedirectUrl(
        originalApplication.returnUrl,
        applicationId,
        'declined',
        originalApplication.orderReference
      )?.toString()
    : undefined;

  const disabledByDebitCard =
    !confirmPayByDebitCard && tailoringOption === 'deposit';

  return (
    <>
      {isAffordabilityDecline ? (
        <>
          <Text my={3} data-test-id='pbf-tailoring-decline-info'>
            Unfortunately, we will not be able to approve this application with
            monthly repayments of {newOriginalMonthlyRepayment}. The application
            will be accepted if you reduce repayments to {newMaxInstalment} or
            lower.
          </Text>
          {isSoftSearch && (
            <Text sx={{ fontWeight: 'bold' }} mb={3}>
              A full credit search will be performed when you apply, leaving a
              footprint on your credit file that is visible to other lenders.
            </Text>
          )}
        </>
      ) : (
        <>
          <Text mb={3} data-test-id='pbf-tailoring-accept-info'>
            Your application has been accepted but based on our checks, we can
            also offer you a shorter repayment term. Select either the Original
            or Updated details to continue.
          </Text>
          {isSoftSearch && (
            <Text my={4} sx={{ fontWeight: 'bold' }}>
              A full credit search will be performed when you apply, leaving a
              footprint on your credit file that is visible to other lenders.
            </Text>
          )}
        </>
      )}

      <NovunaHeading as='h2' mb={3}>
        Update your application
      </NovunaHeading>

      {affordabilityTailoring.radioOptions &&
        affordabilityTailoring.radioOptions.length > 1 && (
          <TailoringOptions
            data-test-id='pbf-tailoring-options'
            radioOptions={
              affordabilityTailoring.radioOptions as PbfRadioOption[]
            }
            selectedTailoringOption={tailoringOption}
            setTailoringOption={setTailoringOption}
            offerText={newOffer.optionText}
          />
        )}

      {offerText && offerText}

      <PbfMixingDeck
        isSoftSearch={isSoftSearch}
        data-test-id={'pbf-tailoring-mixing-deck'}
        originalOffer={loanComparisonPropsOriginal}
        newOffer={{ offer: loanComparisonPropsTailored, offerText: '' }}
        showOfferText={true}
        showPlaceholders={false}
        buttonOnClick={openModal}
        disabled={disabledByDebitCard}
        buttonOriginalOfferOnClick={canAcceptOriginalOffer(
          originalApplication,
          onAcceptOriginalOffer
        )}
        hideApplicationText={hideApplicationText}
      />

      {isAffordabilityDecline && (
        <>
          <Box my={4}>
            <Message
              data-test-id='pbf-tailoring-important-info'
              variant='warning'
              mb={3}>
              <NovunaHeading as='h3' mb={2}>
                Important information
              </NovunaHeading>
              <Text mb={2}>
                This offer expires when you leave the page. If you choose not to
                proceed now, you won’t be able to return to the application
                later.
              </Text>
              {redirectToStoreUrl && (
                <BackToStoreTextButton
                  novunaDisplay
                  baseUrl={originalApplication.returnUrl}
                  status='abandoned'
                  applicationId={applicationId}
                  supplierOrderReference={originalApplication.orderReference}
                  buttonText='Reject this offer &amp; return to store'
                  modalHeading={'Are you sure?'}
                  modalText={
                    'If you reject this offer, you won’t be able to return to the application later.'
                  }
                  modalButtonText={'Return to store'}
                />
              )}
            </Message>
          </Box>
          <Text data-test-id='tailoring-decline-letter-info'>
            A letter that gives you more information on how we came to our
            decision has been sent to you by email.
          </Text>
        </>
      )}
      <TailoringConfirmationModal
        data-test-id='mo-tailoring-confirmation-modal'
        show={isConfirmModalVisible}
        onConfirm={() => {
          affordabilityTailoring.onConfirmTailoringUpdate({
            document: getTailoredApplication(
              originalApplication,
              newOffer,
              tailoringOptionSelected
            ),
            tailoringOptionSelected
          });
          scrollToTop();
        }}
        onSetIsOpen={setConfirmModalVisible}
      />
    </>
  );
};

export default PbfTailoringOptions;
