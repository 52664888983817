import { replace } from 'connected-react-router';
import { put, takeEvery, spawn } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { applicantLogout } from './actions';
import { routes } from '../../../routes';

export function* applicantLogoutSaga() {
  yield put(replace(routes.mailOrder.timeout));
}

export function* applicantLogoutWatcher() {
  yield takeEvery(getType(applicantLogout), applicantLogoutSaga);
}

export const getApplicantLogoutWatcher = () =>
  function*() {
    yield spawn(applicantLogoutWatcher);
  };
