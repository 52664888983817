import React from 'react';
import { RequestedDecision } from 'hitachi-retail-core/build/database/types';
import { DecisionEventType } from 'compass-shared-services/build/decision/enums';
import { BusinessErrorEvent } from './BusinessErrorEvent';
import { FatalErrorEvent } from './FatalErrorEvent';
import { SystemErrorEvent } from './SystemErrorEvent';

interface RenderDecisionEventMessageProps {
  latestDecision?: RequestedDecision;
}

export const renderDecisionEventMessage = ({
  latestDecision
}: RenderDecisionEventMessageProps): JSX.Element => {
  switch (latestDecision?.processedEvent) {
    case DecisionEventType.SystemError:
      return <SystemErrorEvent />;
    case DecisionEventType.BusinessError:
      return <BusinessErrorEvent />;
    case DecisionEventType.FatalError:
      return <FatalErrorEvent />;
    case DecisionEventType.Decision:
    default:
      return <></>;
  }
};

export { BusinessErrorEvent, FatalErrorEvent, SystemErrorEvent };
