import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { fetchPreContractDownloadUrl } from './actions';

export interface State {
  error: boolean;
  processing: boolean;
  documentUrl?: string;
}

export type FetchPreContractDownloadUrlAction = ActionType<
  typeof fetchPreContractDownloadUrl
>;

export const initialState: State = {
  error: false,
  processing: false
};

export const reducer: Reducer<State, FetchPreContractDownloadUrlAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchPreContractDownloadUrl.request): {
      return {
        ...state,
        error: false,
        processing: true
      };
    }
    case getType(fetchPreContractDownloadUrl.success): {
      const documentUrl = action.payload;
      return {
        ...state,
        documentUrl,
        processing: false
      };
    }
    case getType(fetchPreContractDownloadUrl.failure): {
      return {
        ...state,
        error: true,
        processing: false
      };
    }
    default: {
      return state;
    }
  }
};
