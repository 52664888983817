import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { getType, isOfType } from 'typesafe-actions';
import { getGoodsList } from '../../store/goodsList/actions';
import { GoodsListAction } from '../../store/goodsList/reducer';
import { GoodsListService } from '../../services/goodsList/goodsListService';
import { GoodsListItem } from 'hitachi-retail-core';

interface GetGoodsListSagaParams {
  goodsListService: GoodsListService;
}

export const getGoodsListSaga = ({
  goodsListService
}: GetGoodsListSagaParams) =>
  function*(action: GoodsListAction) {
    if (isOfType(getType(getGoodsList.request), action)) {
      const { id } = action.payload;

      try {
        const response: { goodsList: GoodsListItem[] } = yield call(
          goodsListService.getGoodsList,
          id
        );
        yield put(getGoodsList.success(response));
      } catch (error) {
        yield put(getGoodsList.failure(error));
      }
    }
  };

interface GetGoodsListSagaWatcher {
  goodsListService: GoodsListService;
}

export const getFetchGoodsListRequestSagaWatcher = ({
  goodsListService
}: GetGoodsListSagaWatcher) =>
  function*() {
    yield takeEvery(
      getType(getGoodsList.request),
      getGoodsListSaga({ goodsListService })
    );
  };

export const getGoodsListSagaWatcher = ({
  goodsListService
}: GetGoodsListSagaWatcher) =>
  function*() {
    yield all([
      fork(getFetchGoodsListRequestSagaWatcher({ goodsListService }))
    ]);
  };
