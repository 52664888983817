import { LoanAttribute } from 'compass-design';
import { Decimal } from 'hitachi-retail-core/build/utils/decimal';
import {
  getIncreaseTermOptionText,
  getIncreaseTermText,
  LoanComparisonCardPreStringValues
} from 'retailerApp/components/MixingDeck/getMixingDeckData';
import { NewOfferComparisonCardProps } from 'retailerApp/components/MixingDeck/MixingDeck';
import { EditTermOffer } from 'hitachi-retail-core/build/services/tailoring/types';
import { DeepPartial } from 'hitachi-retail-core/build/utils/deepPartial';

export interface GetIncreaseTermDetailsParams {
  originalOffer: Omit<LoanComparisonCardPreStringValues, 'updatedItem'>;
  increaseTermOffer: DeepPartial<EditTermOffer>;
}

const getIncreaseTermDetails = ({
  originalOffer,
  increaseTermOffer
}: GetIncreaseTermDetailsParams): NewOfferComparisonCardProps | undefined => {
  const { offer, offered } = increaseTermOffer;

  if (!offer || !offered) {
    return;
  }

  const { minimumTerm, monthlyRepayment, totalRepaymentAmount } = offer;

  if (!minimumTerm || !monthlyRepayment || !totalRepaymentAmount) {
    return;
  }

  const totalRepayable = new Decimal(totalRepaymentAmount);
  const totalAmountPayable = totalRepayable.add(originalOffer.deposit);

  return {
    offer: {
      ...originalOffer,
      repaidOver: minimumTerm,
      monthlyRepayment: new Decimal(monthlyRepayment),
      totalRepayable,
      totalAmountPayable,
      updatedItem: LoanAttribute.repaidOver
    },
    offerText: getIncreaseTermText({
      minimumTerm: minimumTerm,
      currentTerm: originalOffer.repaidOver
    }),
    optionText: getIncreaseTermOptionText({
      minimumTerm: minimumTerm,
      currentTerm: originalOffer.repaidOver
    })
  };
};

export default getIncreaseTermDetails;
