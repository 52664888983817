import { connect, MapStateToProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { RootState } from '../../../store';
import RedirectToJourney, {
  RedirectToJourneyPropsFromState
} from './RedirectToJourney';
import {
  selectFetchApplicationStatus,
  selectShowKeyLoanFeaturesWSApps
} from '../../../store/application/selectors';
import { JourneyType } from 'hitachi-retail-core/build/enums';

export const mapStateToProps: MapStateToProps<
  RedirectToJourneyPropsFromState,
  RouteComponentProps<{ id: string }>,
  RootState
> = (state, { match }): RedirectToJourneyPropsFromState => {
  const {
    application: {
      activeApplication: { status, document }
    }
  } = state;

  return {
    fetchApplicationStatus: selectFetchApplicationStatus(state),
    applicationStatus: status,
    applicationId: match.params.id,
    webServicesJourney: document?.journeyType === JourneyType.WebServices,
    isShowKeyLoanFeaturesForWsApps: selectShowKeyLoanFeaturesWSApps(state)
  };
};

export default connect(mapStateToProps)(RedirectToJourney);
