import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import DirectAppLogin, {
  DirectAppLoginPropsFromState,
  DirectAppPropsFromDispatch
} from './DirectAppLogin';
import { RootState } from '../../../store';
import { directAppUserLogin } from '../../../store/user/actions';

export const mapStateToProps = (
  state: RootState
): DirectAppLoginPropsFromState => {
  const {
    user: { loggedIn = false, directAppLoggedIn = false, errorMessage }
  } = state;
  return {
    errorMessage,
    loggedIn,
    directAppLoggedIn
  };
};

export const mapDispatchToProps = (
  dispatch: Dispatch
): DirectAppPropsFromDispatch => ({
  directAppLogin: (token: string) =>
    dispatch(directAppUserLogin.request({ directAppLoginToken: token }))
});

export default connect(mapStateToProps, mapDispatchToProps)(DirectAppLogin);
