import { createAsyncAction } from 'typesafe-actions';
import {
  FETCH_CONFIG_FAILURE,
  FETCH_CONFIG_REQUEST,
  FETCH_CONFIG_SUCCESS
} from './constants';
import { AppConfig } from 'hitachi-retail-core';

export const fetchConfig = createAsyncAction(
  FETCH_CONFIG_REQUEST,
  FETCH_CONFIG_SUCCESS,
  FETCH_CONFIG_FAILURE
)<void, AppConfig, Error>();
