import { call, put, takeLeading } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { generateApplicationPath, routes } from '../../../routes';
import {
  ApplicationsService,
  decisionHasExpiredOrMismatched
} from '../../../services/applicationsService';
import { fetchDecision } from '../../../store/decision/actions';
import {
  signApplication,
  SignApplicationResponse
} from '../../../store/sign/actions';
import {
  SignApplicationAction,
  signApplicationActionFinished
} from '../../../store/sign/reducer';
import { saveSigningCompletePath } from '../../../store/signingRedirects/actions';

interface GetSignApplicationSagaParams {
  applicationsService: ApplicationsService;
}

export const generateRedirectUrl = (redirectUrl: string) => {
  const url = new URL(redirectUrl);

  url.searchParams.set('sourceUrl', window.location.href);

  return url.toString();
};

export const signApplicationRedirect = (redirectUrl: string) => {
  window.location.href = generateRedirectUrl(redirectUrl);
};

export const getSignApplicationSaga = ({
  applicationsService
}: GetSignApplicationSagaParams) =>
  function*(action: SignApplicationAction) {
    if (action.type === getType(signApplication.request) && action.payload) {
      const { id: applicationId } = action.payload;

      const signingCompletePath = generateApplicationPath(
        routes.mailOrder.application.postSign,
        {
          id: applicationId
        }
      );

      try {
        const response: SignApplicationResponse = yield call(
          applicationsService.signApplication,
          applicationId,
          true
        );

        if (response) {
          yield put(
            saveSigningCompletePath({
              signingCompletePath
            })
          );
          yield put(signApplication.success());
          yield call(signApplicationRedirect, response.redirectUrl);
        } else {
          yield put(
            signApplication.failure(
              new Error(
                'Sign application API response did not contain expected values'
              )
            )
          );
        }
      } catch (err) {
        if (decisionHasExpiredOrMismatched(err.message)) {
          yield put(
            fetchDecision.request({ id: applicationId, signUponAccept: true })
          );
          yield put(signApplicationActionFinished());
          return;
        }

        yield put(
          signApplication.failure(new Error('Sign application request failed'))
        );
      }
    }
  };

export const getSignApplicationSagaWatcher = ({
  applicationsService
}: GetSignApplicationSagaParams) =>
  function*() {
    yield takeLeading(
      getType(signApplication.request),
      getSignApplicationSaga({ applicationsService })
    );
  };
