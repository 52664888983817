import { call, put, takeEvery, select } from 'redux-saga/effects';
import { getType, isOfType } from 'typesafe-actions';
import {
  createUserAction,
  lookupUserAction,
  selectedUserAction,
  updateUserAction
} from 'store/users/actions';
import {
  CreateUserAction,
  LookupUserAction,
  SelectedUserAction,
  UpdateUserAction
} from 'store/users/reducer';
import { UsersService } from 'services/users';
import { NotificationType, showNotification } from 'store/ui/actions';
import { selectBranchNumber } from 'store/retailer/reducer';
import { UserRole } from 'hitachi-retail-core/build/userPolicy';
import { User } from 'hitachi-retail-core/build/services/users/usersService';
import formatCognitoUsername from 'utils/formatCognitoUsername';

interface UsersSagaProps {
  usersService: UsersService;
}

export const getCreateUserSaga = ({ usersService }: UsersSagaProps) =>
  function*(action: CreateUserAction) {
    if (isOfType(getType(createUserAction.request), action)) {
      const createUserData = action.payload;
      try {
        yield call(usersService.createUser, createUserData);
        yield put(createUserAction.success());
        yield put(
          showNotification({
            message: `User ${createUserData.username} created`,
            type: NotificationType.SUCCESS
          })
        );
      } catch (err) {
        yield put(createUserAction.failure(err));
        yield put(
          showNotification({
            message: err.message,
            type: NotificationType.DANGER
          })
        );
      }
    }
  };

export const getLookupUserSaga = ({ usersService }: UsersSagaProps) =>
  function*(action: LookupUserAction) {
    if (isOfType(getType(lookupUserAction.request), action)) {
      const search = action.payload;
      const branchNumber: string | undefined = yield select(selectBranchNumber);

      if (!branchNumber) {
        throw new Error('Could not find branch ID');
      }
      try {
        const result: User[] = yield call(usersService.searchUsers, {
          familyName: search,
          branch: branchNumber
        });
        yield put(lookupUserAction.success(result));
      } catch (err) {
        yield put(lookupUserAction.failure(err));
        yield put(
          showNotification({
            message: err.message,
            type: NotificationType.DANGER
          })
        );
      }
    }
  };

export const getSelectedUserSaga = ({ usersService }: UsersSagaProps) =>
  function*(action: SelectedUserAction) {
    if (isOfType(getType(selectedUserAction.request), action)) {
      // User received from Cognito.
      const {
        enabled,
        username,
        email,
        givenName,
        familyName,
        defaultBranch,
        retailerName
      } = action.payload;

      try {
        // Get the user groups (roles) from Cognito.
        const roles: UserRole[] = yield call(
          usersService.getUserRoles,
          username
        );

        // Build-up combine the data for the form.
        const userWithRoles = {
          enabled,
          retailerName,
          givenName,
          familyName,
          email,
          username,
          defaultBranch,
          groups: roles[0]
        };

        yield put(selectedUserAction.success(userWithRoles));
      } catch (err) {
        yield put(selectedUserAction.failure(err));
        yield put(
          showNotification({
            message: err.message,
            type: NotificationType.DANGER
          })
        );
      }
    }
  };

export const getUpdateUserSaga = ({ usersService }: UsersSagaProps) =>
  function*(action: UpdateUserAction) {
    if (isOfType(getType(updateUserAction.request), action)) {
      const updateUserData = action.payload;
      const username = formatCognitoUsername(updateUserData.username);
      try {
        yield call(usersService.updateUser, updateUserData);
        yield put(updateUserAction.success());
        yield put(
          showNotification({
            message: `User ${username} updated`,
            type: NotificationType.SUCCESS
          })
        );
      } catch (err) {
        yield put(updateUserAction.failure(err));
        yield put(
          showNotification({
            message: err.message,
            type: NotificationType.DANGER
          })
        );
      }
    }
  };

export const getCreateUserSagaWatcher = ({ usersService }: UsersSagaProps) =>
  function*() {
    yield takeEvery(
      getType(createUserAction.request),
      getCreateUserSaga({ usersService })
    );
  };

export const getLookupUserSagaWatcher = ({ usersService }: UsersSagaProps) =>
  function*() {
    yield takeEvery(
      getType(lookupUserAction.request),
      getLookupUserSaga({ usersService })
    );
  };

export const getSelectedUserSagaWatcher = ({ usersService }: UsersSagaProps) =>
  function*() {
    yield takeEvery(
      getType(selectedUserAction.request),
      getSelectedUserSaga({ usersService })
    );
  };

export const getUpdateUserSagaWatcher = ({ usersService }: UsersSagaProps) =>
  function*() {
    yield takeEvery(
      getType(updateUserAction.request),
      getUpdateUserSaga({ usersService })
    );
  };
