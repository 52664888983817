import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  SetNewPasswordPropsFromDispatch,
  SetNewPasswordPropsFromState
} from './SetNewPassword';
import { RootState } from '../../../store';
import {
  userSetPassword,
  userSetPasswordRedirect
} from '../../../store/user/actions';
import SetNewStrongerPassword from './SetNewStrongerPassword';

export const mapStateToProps = (
  state: RootState
): SetNewPasswordPropsFromState => {
  const {
    user: {
      errorMessage,
      username,
      retailerName,
      familyName,
      givenName,
      session,
      setPasswordProcessing,
      setPasswordSuccess
    }
  } = state;

  return {
    errorMessage,
    username,
    retailerName,
    familyName,
    givenName,
    session,
    setPasswordProcessing,
    setPasswordSuccess
  };
};

export const mapDispatchToProps = (
  dispatch: Dispatch
): SetNewPasswordPropsFromDispatch => ({
  onSubmit: ({
    retailerName,
    username,
    password,
    session,
    givenName,
    familyName
  }: {
    retailerName: string;
    username: string;
    password: string;
    session: string;
    givenName: string;
    familyName: string;
  }) =>
    dispatch(
      userSetPassword.request({
        retailerName,
        username,
        password,
        session,
        givenName,
        familyName
      })
    ),
  onBackToLogin: () => dispatch(userSetPasswordRedirect())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetNewStrongerPassword);
