import { Box, Text } from 'compass-design';
import { Address } from 'hitachi-retail-core';
import React from 'react';

const formatAddressLine = (words: string) => {
  try {
    return words
      .split(' ')
      .map(
        word => word[0].toUpperCase() + word.substring(1).toLocaleLowerCase()
      )
      .join(' ');
  } catch (e) {
    return words;
  }
};

interface ReadOnlyAddressInput {
  address: Address | undefined;
  fontSize?: number;
  fallbackText?: string;
}

const render = (children: React.ReactNode) => (
  <Box data-test-id='read-only-address'>{children}</Box>
);

export const ReadOnlyAddress: React.FC<ReadOnlyAddressInput> = ({
  address,
  fallbackText = 'N/A',
  fontSize = 2
}) => {
  if (!address) {
    return render(<Text sx={{ fontSize }}>{fallbackText}</Text>);
  }

  const {
    houseName,
    houseNumber,
    flatName,
    street,
    town,
    county,
    postCode
  } = address;

  let firstLine;
  if (houseNumber && street) {
    firstLine = `${houseNumber} ${street}`;
  } else if (street) {
    firstLine = street;
  } else if (houseNumber) {
    firstLine = houseNumber;
  }

  const house = [flatName, houseName].filter(item => item).join(' ');

  return render(
    <>
      {(flatName || houseName) && (
        <Text test-data-id='address-line-1' sx={{ fontSize }}>
          {house && formatAddressLine(house)}
        </Text>
      )}
      <Text test-data-id='address-line-2' sx={{ fontSize }}>
        {firstLine && formatAddressLine(firstLine)}
      </Text>
      <Text test-data-id='address-line-town' sx={{ fontSize }}>
        {town && formatAddressLine(town)}
      </Text>
      {county && (
        <Text test-data-id='address-line-county' sx={{ fontSize }}>
          {formatAddressLine(county)}
        </Text>
      )}
      <Text test-data-id='address-line-postcode' sx={{ fontSize }}>
        {postCode}
      </Text>
    </>
  );
};
