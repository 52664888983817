import React from 'react';
import { Box, SearchBar } from 'compass-design';
import { Field, FieldProps, Form, Formik } from 'formik';
import { validationMessages } from 'hitachi-retail-core';
import * as yup from 'yup';

interface UsersSearchValues {
  query: string;
}

interface UsersSearchProps {
  query: string;
  onSearch: (query: string) => void;
  onClear: () => void;
}

const UsersSearch = ({ query, onSearch, onClear }: UsersSearchProps) => (
  <Box>
    <Formik
      initialValues={{ query }}
      onSubmit={values => {
        onSearch(values.query);
      }}
      validationSchema={validationSchema}>
      <Form noValidate>
        <Field name='query'>
          {({
            field,
            meta,
            form: { setFieldValue }
          }: FieldProps<string, UsersSearchValues>) => (
            <>
              <SearchBar
                id='search-bar'
                buttonText={'Filter'}
                placeholder='Filter by Surname, Role or Supplier Number'
                touched={meta.touched}
                error={meta.error}
                {...field}
                query={field.value}
                onChange={({ target: { value } }) => {
                  setFieldValue('query', value);
                }}
                clearSearch={onClear}
                aria-label='Enter a Surname, Role or Supplier Number to filter results'
              />
            </>
          )}
        </Field>
      </Form>
    </Formik>
  </Box>
);

const validationSchema = yup.object<UsersSearchValues>({
  query: yup
    .string()
    .required(validationMessages.REQUIRED_FIELD)
    .min(2, 'Enter at least 2 characters.')
});

export default UsersSearch;
