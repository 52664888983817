import { useEffect, useRef } from 'react';

// Docs: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (callback: () => void, delay: number | string) => {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const tick = () => {
      if (!savedCallback.current) {
        return;
      }
      savedCallback.current();
    };

    if (delay !== null) {
      const id = setInterval(tick, Number(delay));
      return () => clearInterval(id);
    }

    return;
  }, [delay]);
};
