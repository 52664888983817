import { RootState } from 'store';

export const selectDirectAppLoggedIn = ({
  user: { directAppLoggedIn }
}: RootState) => directAppLoggedIn === true;

export const selectUserPolicy = ({ user: { userPolicy } }: RootState) =>
  userPolicy;

export const selectUserRoles = ({ user: { userRoles } }: RootState) =>
  userRoles;

export const selectUsername = ({ user: { username } }: RootState) => username;

export const selectPasswordPrompt = ({
  user: { promptPasswordUpdate }
}: RootState) => promptPasswordUpdate;
