import { DocumentIdentifier } from 'hitachi-retail-core/build/enums/documentDownloadConfirmation';
import {
  selectApr,
  selectDeferredProduct,
  selectEmailAddress,
  selectGoodsAmount,
  selectGoodsDescription,
  selectInstalments,
  selectLoanAmount,
  selectRepaymentAmount,
  selectTotalAmountPayable
} from 'hitachi-retail-core/build/selectors';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { fetchPreContractDownloadUrl } from 'store/preContractCreditInfo/actions';
import { selectRetailerForApplication } from 'store/retailer/selectors';
import PreContractCreditInformationPage, {
  PreContractCreditInformationPagePropsFromDispatch,
  PreContractCreditInformationPagePropsFromState
} from './PreContractCreditInformation';
import {
  selectAgreementDuration,
  selectApplicationDocument,
  selectApplicationProduct
} from 'store/application/selectors';
import { documentDownloadConfirmation } from 'store/documentDownloadConfirmation/actions';
import { selectPreContractCreditInfoDocumentUrl } from 'store/preContractCreditInfo/selectors';
import { selectApplicationId } from 'store/save/reducer';
import { RootState } from 'store';

export const mapStateToProps = (
  state: RootState
): PreContractCreditInformationPagePropsFromState => {
  const retailer = selectRetailerForApplication(state);
  const intermediaryName = retailer.name;
  const intermediaryAddress = retailer.address;
  const nonMandatoryEmailEnabled = retailer.nonMandatoryEmailEnabled === true;
  const immediatePayoutEnabled = retailer.immediatePayoutEnabled === true;

  const applicationId = selectApplicationId(state);
  const applicationDocument = selectApplicationDocument(state);
  const product = selectApplicationProduct(state);

  // TODO: [MO-268] Refactor this to avoid not null assertions
  const goodsDescription = selectGoodsDescription(applicationDocument)!;
  const applicantEmail = selectEmailAddress(applicationDocument);

  const shouldDownloadDocument = nonMandatoryEmailEnabled && !applicantEmail;
  const documentDownloadUrl = selectPreContractCreditInfoDocumentUrl(state);
  const goodsAmount = selectGoodsAmount(applicationDocument)!;
  const loanAmount = selectLoanAmount(applicationDocument)!;
  const repaymentAmount = selectRepaymentAmount(applicationDocument)!;
  const instalments = selectInstalments(applicationDocument)!;
  const agreementDuration = selectAgreementDuration(state)!;
  const totalAmountPayable = selectTotalAmountPayable(applicationDocument)!;
  const apr = selectApr(applicationDocument)!;
  const productIsDeferred = selectDeferredProduct(applicationDocument);
  const productEarlySettlementFee = product && !!product.earlySettlementFee;

  return {
    applicationId,
    intermediaryName,
    intermediaryAddress,
    goodsDescription,
    goodsAmount,
    agreementDuration,
    loanAmount,
    repaymentAmount,
    instalments,
    totalAmountPayable,
    apr,
    productIsDeferred,
    productEarlySettlementFee,
    shouldDownloadDocument,
    documentDownloadUrl,
    immediatePayoutEnabled
  };
};

export const mapDispatchToProps = (
  dispatch: Dispatch
): PreContractCreditInformationPagePropsFromDispatch => ({
  fetchDocumentDownloadUrl: applicationId =>
    dispatch(
      fetchPreContractDownloadUrl.request({
        applicationId,
        forceDownload: false
      })
    ),
  onDocumentDownload: applicationId =>
    dispatch(
      documentDownloadConfirmation.request({
        applicationId,
        documentIdentifier: DocumentIdentifier.PreContractCreditInformation
      })
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreContractCreditInformationPage);
