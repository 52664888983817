import {
  deserializeProductConfig,
  ProductConfig
} from 'hitachi-retail-core/build/api/productConfig';
import { createSelector } from 'reselect';
import { RootState } from '../index';

const productsSelector = ({ products: { products } }: RootState) => products;

export const selectAllProducts = createSelector(productsSelector, products =>
  products.map<ProductConfig>(deserializeProductConfig)
);

export const selectFetchingProducts = ({ products }: RootState) =>
  products.fetching;

export const selectProductsFetched = ({ products }: RootState) =>
  products.fetched;

export const selectProductsError = ({ products }: RootState) => products.error;
