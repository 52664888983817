import { Box, InputWrapper, Text, TextField } from 'compass-design';
import { Decimal } from 'hitachi-retail-core/build/utils/decimal';
import React, { useState } from 'react';
import { UserInputWithError } from 'retailerApp/components/EditLoanCardState/EditLoanCardState';
import { formatCurrency, formatPercent } from 'utils/formatters';
import isNumber from 'utils/isNumber';

interface EditLoanHeaderProps {
  maximumLoanAmount: Decimal;
  maximumGoodsAmount: Decimal;
  deposit: Decimal;
  totalCost: UserInputWithError;
  isIncreaseLoan: boolean;
  minDepositPercentageForProduct?: Decimal;
  setTotalCost: (totalCostInput: string) => void;
}

const EditLoanHeader = ({
  maximumLoanAmount,
  maximumGoodsAmount,
  deposit,
  totalCost,
  isIncreaseLoan,
  minDepositPercentageForProduct,
  setTotalCost
}: EditLoanHeaderProps) => {
  const [totalCostInput, setTotalCostInput] = useState(totalCost.value);

  return (
    <>
      {isIncreaseLoan && (
        <Text>
          Spend up to <b>{formatCurrency(maximumGoodsAmount)}</b> by borrowing
          up to {formatCurrency(maximumLoanAmount)}{' '}
          {isIncreaseLoan
            ? `with a ${formatPercent(
                minDepositPercentageForProduct
                  ? minDepositPercentageForProduct.mul(100)
                  : 0
              )} `
            : `with a ${formatCurrency(deposit)} `}
          deposit.
        </Text>
      )}
      <Box mt={3} sx={{ width: ['100%', '50%', '50%'] }}>
        <InputWrapper
          id='total-cost'
          label='Total cost'
          error={totalCost.error || undefined}
          tone={totalCost.error ? 'negative' : 'neutral'}>
          <TextField
            prefix='£'
            placeholder='Enter a new total cost'
            type='total-cost'
            id='total-cost'
            name='Total cost'
            aria-label='total cost'
            tone={totalCost.error ? 'negative' : 'neutral'}
            value={totalCostInput}
            onChange={({ target: { value } }) => {
              const newTotalCost = value;
              if (isNumber(newTotalCost) || newTotalCost === '') {
                setTotalCostInput(newTotalCost);
                setTotalCost(newTotalCost);
              }
            }}
          />
        </InputWrapper>
      </Box>
    </>
  );
};

export default EditLoanHeader;
