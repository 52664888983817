import { call, put, select, takeLeading } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { DefaultSagaParams } from '../../sagas';
import { getSaveApplicationSaga } from '../../sagas/saveApplication/saga';
import { ApplicationsService } from '../../services/applicationsService';
import { selectApplicationId } from '../../store/save/reducer';
import { sendApplication } from '../store/actions';

interface GetSendApplicationSagaParams {
  applicationsService: ApplicationsService;
  saveApplicationSaga: ReturnType<typeof getSaveApplicationSaga>;
}

export const getSendApplicationSaga = ({
  saveApplicationSaga,
  applicationsService
}: GetSendApplicationSagaParams) =>
  function*() {
    try {
      // Save application
      yield call(saveApplicationSaga);

      const id = yield select(selectApplicationId);

      if (id) {
        yield call(applicationsService.sendToCustomer, id);

        yield put(sendApplication.success());
      } else {
        yield put(
          sendApplication.failure({
            error: new Error(
              'Failed to save application, so it has not been sent to the applicant'
            )
          })
        );
      }
    } catch (error) {
      yield put(
        sendApplication.failure({
          error: new Error('Failed to send application to applicant')
        })
      );
    }
  };

type GetSendApplicationSagaWatcherInput = DefaultSagaParams &
  GetSendApplicationSagaParams;

export const getSendApplicationSagaWatcher = ({
  applicationsService
}: GetSendApplicationSagaWatcherInput) =>
  function*() {
    const saveApplicationSaga = getSaveApplicationSaga({ applicationsService });

    yield takeLeading(
      getType(sendApplication.request),
      getSendApplicationSaga({
        saveApplicationSaga,
        applicationsService
      })
    );
  };
