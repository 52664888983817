import {
  Box,
  Button,
  InputWrapper,
  Search,
  SelectField,
  TextField
} from 'compass-design';
import { Field, FieldProps, Form, Formik } from 'formik';
import { User } from 'hitachi-retail-core/build/services/users/usersService';
import React from 'react';
import { UserLookupState } from 'store/users/reducer';
import { pluraliseString } from 'utils/formatters';
import { labelPropsFromMeta } from 'utils/labelPropsFromMeta';
import * as yup from 'yup';

export interface UserLookupPropsFromDispatch {
  onSubmit: (value: string) => void;
}

export interface UserLookupPropsFromState {
  lookup: UserLookupState;
}

export type UserLookupProps = UserLookupPropsFromDispatch &
  UserLookupPropsFromState & {
    onSelectUser: (value: User) => void;
    handleClearingNotifications: () => void;
  };

interface UserLookupForm {
  search: string;
}

export const validate = async (
  values: UserLookupForm,
  handleClearingNotifications: () => void
) => {
  const userLookupSchema = yup.object<UserLookupForm>({
    search: yup
      .string()
      .required()
      .min(2)
      .trim()
  });

  try {
    handleClearingNotifications();
    await userLookupSchema.validate(values);
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      return {
        search: err.message
      };
    }
  }

  return {};
};

const UserLookup: React.FC<UserLookupProps> = ({
  lookup,
  onSubmit,
  onSelectUser,
  handleClearingNotifications
}) => {
  const loading = lookup?.loading;
  const lookupSuccessful = lookup?.success;
  const users = lookup?.result;

  const usersCount = users?.length ?? 0;
  const selectPlaceholderText = `${usersCount} ${pluraliseString(
    'user',
    usersCount
  )} found`;

  return (
    <Formik
      initialValues={{
        search: ''
      }}
      onSubmit={() => {}}
      validate={values => validate(values, handleClearingNotifications)}>
      <Form key='users-lookup' name='users-lookup'>
        <Field name='search'>
          {({ field, meta }: FieldProps<string, UserLookupForm>) => (
            <>
              <Box mt={4}>
                <InputWrapper
                  id='search'
                  label='Find user'
                  description='Search by last name'
                  error={meta.error}
                  {...labelPropsFromMeta(
                    {
                      meta
                    },
                    'label'
                  )}>
                  <div>
                    <TextField
                      {...labelPropsFromMeta(
                        {
                          meta
                        },
                        'input'
                      )}
                      type='text'
                      {...field}
                      id='search'
                      data-test-id='user-search'
                    />
                    <Box mt={4}>
                      <Button
                        isJumbo
                        data-test-id='user-search-button'
                        onClick={e => {
                          e.preventDefault();
                          handleClearingNotifications();
                          if (meta.error || !field.value) {
                            return;
                          }
                          onSubmit(field.value);
                        }}>
                        {loading ? 'Processing' : 'Find user'}
                        <Search />
                      </Button>
                    </Box>
                  </div>
                </InputWrapper>
              </Box>
              <Box mt={5}>
                {lookupSuccessful && (
                  <Box mb={6}>
                    <InputWrapper id='select-user-lookup' label='Select user'>
                      <SelectField
                        id='select-user-lookup'
                        name='select-user-lookup'
                        data-test-id='select-user-lookup'
                        onChange={event => {
                          if (!users) {
                            return;
                          }

                          const index = event.target.value;
                          const user = users[index];

                          onSelectUser(user);
                        }}
                        defaultValue=''>
                        <option value='' disabled>
                          {selectPlaceholderText}
                        </option>
                        {users &&
                          users.map((user: User, index: number) => (
                            <option value={index} key={user.username}>
                              {user.givenName} {user.familyName}
                            </option>
                          ))}
                      </SelectField>
                    </InputWrapper>
                  </Box>
                )}
              </Box>
            </>
          )}
        </Field>
      </Form>
    </Formik>
  );
};

export default UserLookup;
