import {
  Box,
  Message,
  NovunaHeading,
  NovunaSingleColumnLayout,
  Text
} from 'compass-design';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { getBasePageName, pageNameSeparator } from '../../config';

const Timeout: React.FC = () => (
  <>
    <Helmet>
      {['Session Timeout', getBasePageName()].join(pageNameSeparator)}
    </Helmet>
    <NovunaSingleColumnLayout>
      <Box mx='auto' sx={{ maxWidth: '620px' }}>
        <NovunaHeading as='h1' mb={3}>
          Your application
        </NovunaHeading>
        <Message variant='error'>
          <NovunaHeading as='h3' mb={1}>
            Application timed out
          </NovunaHeading>
          <Text>Sorry, your application timed out due to inactivity</Text>
        </Message>
      </Box>
    </NovunaSingleColumnLayout>
  </>
);

export default Timeout;
