import React, { useState } from 'react';
import { InputWrapper, ReactModal, TextField, Button } from 'compass-design';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectBranchSelectorModalIsOpen,
  selectSelectedEnhancedBroker,
  selectSelectedEnhancedHeadOffice
} from 'store/enhancedUsers/selectors';
import {
  setBranchSelectorModalIsOpen,
  setBranchSelectorSearchTerm,
  setSelectedEnhancedBranch,
  setSelectedEnhancedHeadOffice
} from 'store/enhancedUsers/actions';

export const BranchSelectorButton = () => {
  const selectedBroker = useSelector(selectSelectedEnhancedBroker);
  const selectedHeadOffice = useSelector(selectSelectedEnhancedHeadOffice);
  const dispatch = useDispatch();

  if (selectedBroker || selectedHeadOffice) {
    return (
      <Button
        mb={['16px', '0', '0', '0']}
        mr={3}
        variant='secondaryPurple'
        onClick={() => {
          dispatch(setBranchSelectorModalIsOpen(true));
        }}>
        Find a branch
      </Button>
    );
  }
  return null;
};

const BranchSelectorModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectBranchSelectorModalIsOpen);

  const [searchTerm, setSearchTerm] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const defaultErrorMessage = 'Enter at least 2 characters.';

  return (
    <ReactModal
      isOpen={!!isOpen}
      setIsOpen={() => {
        dispatch(setBranchSelectorModalIsOpen(false));
      }}
      size='medium'
      title='Find a branch or head office'>
      <InputWrapper
        id='searchTerm'
        label=''
        error={errorMessage}
        tone={errorMessage.length ? 'negative' : 'neutral'}>
        <TextField
          type='text'
          value={searchTerm}
          data-test-id='branch-search-input'
          placeholder='Enter a trading name, supplier number or location'
          id='searchTerm'
          onChange={e => {
            const value = e.target.value;
            if (errorMessage) {
              setErrorMessage(value.length < 2 ? defaultErrorMessage : '');
            }
            setSearchTerm(value);
          }}
        />
      </InputWrapper>
      <Button
        mt={5}
        isJumbo
        variant='primary'
        onClick={() => {
          const newErrorMessage =
            searchTerm.length < 2 ? defaultErrorMessage : '';

          if (!newErrorMessage) {
            dispatch(setBranchSelectorSearchTerm(searchTerm));
            dispatch(setSelectedEnhancedHeadOffice(undefined));
            dispatch(setSelectedEnhancedBranch(undefined));
            dispatch(setBranchSelectorModalIsOpen(false));
          } else {
            setErrorMessage(newErrorMessage);
          }
        }}>
        Search
      </Button>
    </ReactModal>
  );
};

export default BranchSelectorModal;
