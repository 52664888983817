import React from 'react';
import { Box, InputWrapper, TextField } from 'compass-design';
import {
  GoodsDetailsKey,
  SetGoodsDetailsParams
} from '../../GoodsDetailsModal';

type GoodsInputSectionProps = {
  title: string;
  value: string;
  inputKey: GoodsDetailsKey;
  setGoodsDetails: ({ key, value }: SetGoodsDetailsParams) => void;
  errorMessage: string;
};

const GoodsInputSection = ({
  title,
  value,
  inputKey,
  setGoodsDetails,
  errorMessage
}: GoodsInputSectionProps) => {
  const bpWidth = title === 'Order reference' ? '50%' : '100%';
  const width = ['100%', bpWidth, bpWidth];

  return (
    <Box mb={5} sx={{ width }}>
      <InputWrapper
        id={inputKey}
        label={title}
        error={errorMessage}
        tone={errorMessage ? 'negative' : 'neutral'}
        stacked={true}>
        <TextField
          mt={2}
          type='text'
          aria-labelledby={`${inputKey}_label`}
          name={inputKey}
          value={value}
          tone={errorMessage ? 'negative' : 'positive'}
          onChange={e =>
            setGoodsDetails({
              key: inputKey,
              value: e.target.value
            })
          }
        />
      </InputWrapper>
    </Box>
  );
};

export default GoodsInputSection;
