import { RootState } from '../index';

export const selectGoodsList = ({ goodsList }: RootState) =>
  goodsList.goodsList;

export const selectProcessing = ({ goodsList }: RootState) =>
  goodsList.processing;

export const selectHasFetchedGoodsList = ({ goodsList }: RootState) =>
  goodsList.fetched;
