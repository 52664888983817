import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'store';
import { lookupUserAction, resetUser } from 'store/users/actions';
import UserLookup, {
  UserLookupPropsFromDispatch,
  UserLookupPropsFromState
} from './UserLookup';

export const mapStateToProps = ({
  users: { userLookup }
}: RootState): UserLookupPropsFromState => ({
  lookup: userLookup
});

export const mapDispatchToProps = (
  dispatch: Dispatch
): UserLookupPropsFromDispatch => ({
  onSubmit: search => {
    dispatch(resetUser());
    dispatch(lookupUserAction.request(search));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(UserLookup);
