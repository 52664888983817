export type SearchAction =
  | {
      type: 'UPDATE_QUERY';
      query: string;
    }
  | {
      type: 'PERFORM_SEARCH';
    }
  | {
      type: 'PERFORMED_SEARCH';
    }
  | {
      type: 'CLEAR_SEARCH';
    };

type SearchState = {
  query: string;
  loading: boolean;
  searchActive: boolean;
};

export const initialSearchState: SearchState = {
  query: '',
  loading: false,
  searchActive: false
};

export const searchReducer = (
  state: SearchState,
  action: SearchAction
): SearchState => {
  switch (action.type) {
    case 'UPDATE_QUERY':
      return {
        ...state,
        query: action.query
      };
    case 'PERFORM_SEARCH':
      return {
        ...state,
        loading: true,
        searchActive: true
      };
    case 'PERFORMED_SEARCH':
      return {
        ...state,
        loading: false
      };
    case 'CLEAR_SEARCH':
      return initialSearchState;
    default:
      return state;
  }
};
