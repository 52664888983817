import { NovunaTwoColumnLayout } from 'compass-design';
import ApplicationComplete from 'components/ApplicationComplete';
import NoMatch from 'components/meta/NoMatch';
import { QuoteSummary } from 'components/QuoteSummary';
import ApplicantDetails from 'containers/ApplicantDetails';
import PrivacyPolicy from 'containers/PrivacyPolicy';
import Progress from 'containers/progress/Progress';
import Quote from 'containers/Quote';
import React, { ComponentType } from 'react';
import { Route, RouteProps, Switch } from 'react-router';
import ApplicationDecision from 'retailerApp/pages/ApplicationDecision';
import ApplicationSoftDecision from 'retailerApp/pages/ApplicationSoftDecision';
import LoanAdvanceInformation from 'retailerApp/pages/LoanAdvanceInformation';
import RetailerSelection from 'retailerApp/pages/RetailerSelection';
import { routes } from 'routes';
import ApplicationAmend from '../ApplicationAmend/ApplicationAmend';
import PreContractCreditInformation from '../PreContractCreditInformation';

type LayoutProps = RouteProps & {
  component: ComponentType<any>;
};

// Note: <FeatureToggle> components cannot be immediate children of <Switch>.
// Switch renders a route exclusively and placing a <FeatureToggle> inside
// means that any child placed after the FeatureToggle will never render –
// even when a match – as the FeatureToggle will always match first.
// Feature toggling should be done inside the actual component.
const FinanceApplication = () => (
  <Switch>
    <StandardLayout
      path={routes.apply.retailerSelection}
      component={RetailerSelection}
      showProgress={false}
    />
    <StandardLayout path={routes.apply.quote} component={Quote} />
    <StandardLayout
      path={routes.apply.privacyPolicy}
      component={PrivacyPolicy}
    />
    <StandardLayout
      path={routes.apply.details}
      component={ApplicantDetails}
      extendContent={false}
      showQuoteSummary
    />
    <StandardLayout
      path={routes.apply.softSearch}
      component={ApplicationSoftDecision}
    />
    <StandardLayout
      path={routes.apply.loanAmend}
      component={ApplicationAmend}
    />
    <StandardLayout
      path={routes.apply.decision}
      component={ApplicationDecision}
    />
    <StandardLayout
      path={routes.apply.preContractCreditInformation}
      component={PreContractCreditInformation}
    />
    <StandardLayout
      path={routes.apply.loanAdvanceInformation}
      component={LoanAdvanceInformation}
    />
    <StandardLayout
      path={routes.apply.complete}
      component={ApplicationComplete}
    />
    <Route component={NoMatch} />
  </Switch>
);

type StandardLayoutProps = LayoutProps & {
  extendContent?: boolean;
  showProgress?: boolean;
  showQuoteSummary?: boolean;
};

const StandardLayout = ({
  component: Component,
  extendContent = true,
  showProgress = true,
  showQuoteSummary = false,
  ...restProps
}: StandardLayoutProps) => (
  <Route
    {...restProps}
    render={matchProps => (
      <>
        <NovunaTwoColumnLayout
          pageTop={showProgress && <Progress path={window.location.pathname} />}
          sidebar={<QuoteSummary />}>
          <Component {...matchProps} />
        </NovunaTwoColumnLayout>
      </>
    )}
  />
);

export default FinanceApplication;
