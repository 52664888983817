import { FinanceApplication } from 'hitachi-retail-core';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router';
import {
  generateApplicationPath,
  MailOrderApplicationPathParams,
  routes
} from 'routes';
import { getBasePageName } from '../../config';
import { AsyncStatus } from '../../store/AsyncStatus';

export interface RedirectToJourneyPropsFromState {
  applicationStatus?: ApplicationStatus;
  fetchApplicationStatus: AsyncStatus;
  applicationId?: string;
  application?: FinanceApplication;
  webServicesJourney?: boolean;
  isShowKeyLoanFeaturesForWsApps: boolean;
}

const InvalidStatus = () => (
  <div data-test-id='compass-applicant-app-invalid-status'>Invalid Status</div>
);

const Loading = () => <div data-test-id='compass-applicant-app-loading' />;

const RedirectToJourney: React.FC<RedirectToJourneyPropsFromState> = ({
  applicationStatus,
  applicationId,
  fetchApplicationStatus,
  webServicesJourney,
  isShowKeyLoanFeaturesForWsApps
}) => {
  let defaultDecisionAcceptRoute: string;
  if (!webServicesJourney) {
    defaultDecisionAcceptRoute = routes.mailOrder.application.decisionOutcome;
  } else if (isShowKeyLoanFeaturesForWsApps) {
    defaultDecisionAcceptRoute = routes.mailOrder.application.keyLoanFeatures;
  } else {
    defaultDecisionAcceptRoute = routes.mailOrder.application.loanInformation;
  }

  const redirectMap = new Map<ApplicationStatus, string>(
    // prettier-ignore
    [
      [ApplicationStatus.open, routes.mailOrder.application.quote],
      [ApplicationStatus.decision_accept, defaultDecisionAcceptRoute],
      [ApplicationStatus.decision_decline, routes.mailOrder.application.decisionOutcome],
      [ApplicationStatus.decision_refer, routes.mailOrder.application.decisionOutcome],
      [ApplicationStatus.signed, routes.mailOrder.application.postSign],
      [ApplicationStatus.cooling_off, routes.mailOrder.application.postSign],
      [ApplicationStatus.payout_pending, routes.mailOrder.application.postSign],
      [ApplicationStatus.ready_for_payout, routes.mailOrder.application.postSign],
      [ApplicationStatus.paid, routes.mailOrder.application.postSign],
      [ApplicationStatus.pending_esat_note, routes.mailOrder.application.postSign],
      [ApplicationStatus.customer_has_goods, routes.mailOrder.application.postSign],
      [ApplicationStatus.deposit_paid, routes.mailOrder.application.onlineDeposit],
      [ApplicationStatus.deposit_attempted, routes.mailOrder.application.decisionOutcome],
      [ApplicationStatus.deposit_unsuccessful, routes.mailOrder.application.onlineDeposit],
      [ApplicationStatus.decision_quotation_accept, routes.mailOrder.application.softSearchOutcome],
      [ApplicationStatus.decision_quotation_decline, routes.mailOrder.application.softSearchOutcome],
      [ApplicationStatus.decision_quotation_refer, routes.mailOrder.application.softSearchOutcome]
    ]
  );

  let pathname: string | undefined;
  const redirectRoute = applicationStatus && redirectMap.get(applicationStatus);
  if (redirectRoute && applicationId) {
    pathname = generateApplicationPath<MailOrderApplicationPathParams>(
      redirectRoute,
      { id: applicationId }
    );
  }

  const body = ((fetchStatus: AsyncStatus) => {
    switch (fetchStatus) {
      case AsyncStatus.Loading:
        return <Loading />;
      case AsyncStatus.Success: {
        if (pathname === undefined) {
          return <InvalidStatus />;
        }

        return <Redirect to={{ pathname }} />;
      }

      default:
        return null;
    }
  })(fetchApplicationStatus);

  return (
    <>
      <Helmet>
        <title>{getBasePageName()}</title>
      </Helmet>
      {body}
    </>
  );
};

export default RedirectToJourney;
