import { Button } from 'compass-design';
import React from 'react';
import { UIModal } from '../../store/ui/actions';
import { EndAndSend } from '../containers/EndAndSend';

export interface EndAndSendButtonPropsFromDispatch {
  showModal: (config: UIModal) => void;
  closeModal: () => void;
}

export interface EndAndSendButtonOwnProps {
  disabled: boolean;
  mailOrderEnabled: boolean;
  id?: string;
}

export type EndAndSendButtonProps = EndAndSendButtonOwnProps &
  EndAndSendButtonPropsFromDispatch;

export const EndAndSendButton: React.FunctionComponent<EndAndSendButtonProps> = ({
  mailOrderEnabled,
  disabled,
  id,
  showModal,
  closeModal
}) => {
  if (!mailOrderEnabled) {
    return null;
  }

  return (
    <Button
      id={id}
      variant='secondary'
      disabled={disabled}
      onClick={event => {
        event.preventDefault();
        showModal({
          body: <EndAndSend onClose={closeModal} />,
          onClose: closeModal
        });
      }}
      data-test-id='compass-application-end-and-send'>
      Send to customer
    </Button>
  );
};
