import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';
import {
  ActionType,
  createAsyncAction,
  createStandardAction
} from 'typesafe-actions';
import * as retailer from './constants';

export interface FetchRetailerByApplicationId {
  id: string;
}

export type FetchRetailerAction = ActionType<typeof fetchRetailer>;

export const fetchRetailer = createAsyncAction(
  retailer.FETCH_RETAILER_REQUEST,
  retailer.FETCH_RETAILER_SUCCESS,
  retailer.FETCH_RETAILER_FAILURE
)<void, Retailer, Error>();

export const fetchRetailerByApplicationId = createAsyncAction(
  retailer.FETCH_RETAILER_BY_APPLICATION_ID_REQUEST,
  retailer.FETCH_RETAILER_BY_APPLICATION_ID_SUCCESS,
  retailer.FETCH_RETAILER_BY_APPLICATION_ID_FAILURE
)<FetchRetailerByApplicationId, Retailer, Error>();

export const fetchAllBranches = createAsyncAction(
  retailer.FETCH_ALL_BRANCHES_REQUEST,
  retailer.FETCH_ALL_BRANCHES_SUCCESS,
  retailer.FETCH_ALL_BRANCHES_FAILURE
)<void, Retailer[], Error>();

export const fetchBranchesForProposing = createAsyncAction(
  retailer.FETCH_BRANCHES_FOR_PROPOSING_REQUEST,
  retailer.FETCH_BRANCHES_FOR_PROPOSING_SUCCESS,
  retailer.FETCH_BRANCHES_FOR_PROPOSING_FAILURE
)<void, Retailer[], Error>();

export type SetProposingOnBehalfOfAction = ActionType<
  typeof setProposingOnBehalfOf
>;

export interface SetProposingOnBehalfOfPayload {
  supplierNumber?: string;
}

export const setProposingOnBehalfOf = createStandardAction(
  retailer.SET_PROPOSING_ON_BEHALF_OF
)<SetProposingOnBehalfOfPayload>();
