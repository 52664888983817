import { DefaultSagaParams } from '../sagas';
import { DocumentDownloadConfirmationRequestPayload } from '../store/documentDownloadConfirmation/actions';

export interface DocumentDownloadConfirmationService {
  sendConfirmation: (
    payload: DocumentDownloadConfirmationRequestPayload
  ) => Promise<void>;
}

export const getDocumentDownloadConfirmationService = ({
  apiClient
}: DefaultSagaParams): DocumentDownloadConfirmationService => ({
  sendConfirmation: async ({ applicationId, documentIdentifier }) => {
    await apiClient.post(
      `protected/applications/${applicationId}/document-download-confirmation`,
      { documentIdentifier }
    );
  }
});
