import { RadioGroup } from 'compass-design';
import { processSchemaValue } from 'form/helpers';
import React, { ChangeEvent } from 'react';
import { FieldProps } from 'react-jsonschema-form';
import { optionsList } from 'react-jsonschema-form/lib/utils';
import FieldTemplate from '../FieldTemplate';

export interface CustomerPresentPropsFromState {
  mailorderEnabled: boolean;
}

export type CustomerPresentFieldProps = FieldProps<boolean>;
export type CustomerPresentProps = CustomerPresentFieldProps &
  CustomerPresentPropsFromState;

export const CustomerPresent: React.FunctionComponent<CustomerPresentProps> = ({
  mailorderEnabled,
  formData: fieldValue,
  idSchema,
  schema,
  uiSchema,
  disabled,
  onChange
}) => {
  return mailorderEnabled ? (
    <>
      <FieldTemplate
        id={idSchema.$id}
        label={uiSchema['ui:title'] || schema.title || ''}
        required={true}
        description={uiSchema['ui:description'] || schema.description}
        rawDescription=''
        hidden={false}
        disabled={disabled}
        displayLabel={true}
        rawErrors={[]}
        help={null}
        schema={schema}>
        <RadioGroup
          name={idSchema.$id}
          options={optionsList(schema).map(enumOption => ({
            value: String(enumOption.value),
            label: enumOption.label,
            checked: fieldValue === Boolean(enumOption.value),
            inputId: `${idSchema.$id}_${enumOption.value ? 'yes' : 'no'}`
          }))}
          disabled={disabled}
          onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
            onChange(processSchemaValue(schema, value))
          }
        />
      </FieldTemplate>
    </>
  ) : null;
};

export default CustomerPresent;
