export const getBasePageName = () => 'Novuna Personal Finance';
export const pageNameSeparator = ' | ';

export const hitachiStreetAddress = '2 Apex View';
export const hitachiCity = 'Leeds';
export const hitachiPostCode = 'LS11 9BH';
export const hitachiCounty = 'West Yorkshire';
export const hitachiAddress = `${hitachiStreetAddress}, ${hitachiCity}, ${hitachiCounty} ${hitachiPostCode}`;
export const hitachiPhoneNumber = '0344 375 5500';
export const hitachiDialablePhoneNumber = '+443443755500';

export const novunaStreetAddress = hitachiStreetAddress;
export const novunaCity = hitachiCity;
export const novunaCounty = hitachiCounty;
export const novunaPostCode = hitachiPostCode;
export const novunaPhoneNumber = hitachiPhoneNumber;
export const novunaDialablePhoneNumber = hitachiDialablePhoneNumber;
