import { createSelector } from 'reselect';
import { RootState } from '../index';

export const selectEnabledFeatures = (state: RootState) =>
  state.config.enabledFeatures;

export const selectInterestFreeCreditUnregulatedEndDate = ({
  config: { interestFreeCreditUnregulatedEndDate }
}: RootState) => {
  if (interestFreeCreditUnregulatedEndDate) {
    return new Date(interestFreeCreditUnregulatedEndDate);
  }
  return;
};

const selectCurrentDomain = (state: RootState) =>
  state.retailerConfig.currentDomain;
const selectBaseDomain = (state: RootState) => state.retailerConfig.baseDomain;

export const selectRetailerSubdomain = createSelector(
  selectCurrentDomain,
  selectBaseDomain,
  (currentDomain, baseDomain) =>
    stripWww(currentDomain)
      .replace(stripWww(baseDomain), '')
      .replace(/\.+$/, '')
);

const stripWww = (domain: string) => domain.replace(/^www\./, '');
