import { AuditEventDetail } from 'hitachi-retail-core/build/eventEmitters/types';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getType, isOfType } from 'typesafe-actions';
import {
  ApplicationsService,
  getApplicationsService
} from '../../services/applicationsService';
import { fetchAuditHistory } from '../../store/previousApplicationDetail/actions';
import { FetchAuditHistoryAction } from '../../store/previousApplicationDetail/reducer';
import { NotificationType, showNotification } from '../../store/ui/actions';
import { DefaultSagaParams } from '../index';

interface AuditHistorySagaProps {
  applicationsService: ApplicationsService;
}

export const getAuditHistorySaga = ({
  applicationsService
}: AuditHistorySagaProps) =>
  function*(action: FetchAuditHistoryAction) {
    if (isOfType(getType(fetchAuditHistory.request), action)) {
      const { id, branchId } = action.payload;

      try {
        const response: AuditEventDetail[] = yield call(
          applicationsService.getAuditHistory,
          id,
          branchId
        );

        yield put(fetchAuditHistory.success(response));
      } catch (err) {
        yield put(fetchAuditHistory.failure(err));
        yield put(
          showNotification({
            message: err.message,
            type: NotificationType.DANGER
          })
        );
      }
    }
  };

export const getAuditHistorySagaWatcher = ({ apiClient }: DefaultSagaParams) =>
  function*() {
    const applicationsService = getApplicationsService({ apiClient });

    yield takeEvery(
      getType(fetchAuditHistory.request),
      getAuditHistorySaga({ applicationsService })
    );
  };
