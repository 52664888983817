import { List, ListItem, NovunaHeading, Text } from 'compass-design';
import React from 'react';

export interface AdequateExplanationPropsFromState {
  productIsDeferred?: boolean;
  productEarlySettlementFee?: boolean;
}

export type AdequateExplanationProps = AdequateExplanationPropsFromState;

export const AdequateExplanation: React.FC<AdequateExplanationProps> = ({
  productIsDeferred,
  productEarlySettlementFee
}) => (
  <>
    <NovunaHeading id='adex-heading' as='h2' mt={4}>
      Adequate Explanation
    </NovunaHeading>
    <Text mt={2}>
      This is some important information about the loan you are applying for.
    </Text>
    <List fontSize='1'>
      <ListItem>
        The agreement is to finance the purchase of the specified goods and/or
        services from us.
      </ListItem>
      {productIsDeferred && (
        <ListItem data-test-id='compass-adex-deferral1'>
          If your application is accepted, monthly repayments will start after
          your deferral period ends.
        </ListItem>
      )}
      <ListItem>
        The amount of your monthly repayment can be found in the
        &lsquo;Repayments&rsquo; section in your agreement.
      </ListItem>
      <ListItem>
        The total amount payable under your agreement can be found in the
        section headed &lsquo;Description of goods/services and credit&rsquo;.
      </ListItem>
      {productEarlySettlementFee && (
        <ListItem data-test-id='compass-adex-earlysettlementfee'>
          If you pay the credit in full before your first monthly payment falls
          due no interest will be charged but you will have to pay an
          administration charge. The administration charge will be the lesser of
          the interest accrued during the deferral or &pound;29.
        </ListItem>
      )}
      <ListItem>
        You are responsible for paying each instalment by the date due. Late
        payment or failure to repay the balance in full could result in adverse
        consequences for you. As a responsible lender, we are obliged to notify
        credit reference agencies where payments have been missed.
      </ListItem>
      {productIsDeferred && (
        <>
          <ListItem data-test-id='compass-adex-deferral2'>
            Your agreement includes a deferral period during which you do not
            need to make any repayments. During this time, interest will accrue
            at the contractual rate, however any repayments that are made will
            reduce your outstanding balance and will not attract any interest
            charges.
          </ListItem>
          <ListItem data-test-id='compass-adex-deferral3'>
            If you do not repay the total amount of credit before the deferral
            period ends, then you will be charged interest as detailed within
            your agreement on the outstanding balance from the start of the
            agreement until the credit is fully repaid.
          </ListItem>
        </>
      )}
      <ListItem>
        Missing payments may result in:
        <List fontSize='1' nested ordered>
          <ListItem>The total cost of the debt growing.</ListItem>
          <ListItem>
            You incurring default charges (these are set out in the
            &lsquo;Additional Charges&rsquo; section of your agreement.)
          </ListItem>
          <ListItem>
            Legal action being taken against you. You may have to pay the cost
            of such proceedings which may include our legal costs.
          </ListItem>
          <ListItem>
            A negative impact on your credit score and may make obtaining credit
            more difficult in the future.
          </ListItem>
        </List>
      </ListItem>
      <ListItem>
        You have the right to withdraw from the agreement, without giving any
        reason, for a period of 14 days beginning on the day after this
        agreement starts. If you withdraw from the loan during this period the
        loan should be repaid in full within 31 days and where applicable, will
        incur interest. See your loan agreement for full details.
      </ListItem>
      <ListItem>
        If you withdraw from the credit agreement within the 14 day period the
        agreement will be treated as if it was never entered into.
      </ListItem>
      <ListItem>
        You will be provided with some pre-contract information about your loan
        agreement which you can take away with you. You should read this
        information carefully.
      </ListItem>
      <ListItem>
        If you have any questions or would like any further information or
        explanation please ask us or call 0344 375 5500.
      </ListItem>
    </List>
  </>
);
