import classNames from 'classnames';
import { Box, InputWrapper, TextField } from 'compass-design';
import React from 'react';

export interface InputFieldProps {
  classNames?: string;
  fieldName: string;
  label?: string;
  required: boolean;
  inputId: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  testIdPrefix: string;
  fieldValue?: number | string;
  disabled?: boolean;
  placeholder?: string;
  fieldError?: string;
  fieldHelp?: string;
  inputType?: string;
  inputClassNames?: string;
  maxLength?: number;
  autofocus?: boolean;
  shortLength?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputField: React.FunctionComponent<InputFieldProps> = ({
  label,
  placeholder,
  disabled,
  fieldName,
  fieldValue,
  fieldError,
  fieldHelp,
  testIdPrefix,
  inputRef,
  inputId,
  inputType = 'text',
  inputClassNames,
  autofocus = false,
  maxLength,
  shortLength = false,
  onBlur,
  onFocus,
  onChange
}) => {
  const tone = fieldError ? 'negative' : 'neutral';
  return (
    <Box mb={4}>
      <InputWrapper
        id={inputId}
        label={label}
        description={fieldHelp}
        error={fieldError}
        tone={tone}>
        <TextField
          id={inputId}
          ref={inputRef}
          type={inputType}
          disabled={disabled}
          placeholder={placeholder}
          name={fieldName}
          value={fieldValue == null ? '' : fieldValue}
          autoFocus={autofocus}
          maxLength={maxLength}
          className={classNames(inputClassNames)}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          tone={tone}
          short={shortLength}
          data-test-id={testIdPrefix + '_field_' + fieldName}
        />
      </InputWrapper>
    </Box>
  );
};

export default InputField;
