import {
  Box,
  Button,
  Message,
  NovunaDocument,
  NovunaHeading,
  SectionError,
  Text
} from 'compass-design';
import { DocumentIdentifier } from 'hitachi-retail-core/build/enums/documentDownloadConfirmation';
import { selectEmailAddress } from 'hitachi-retail-core/build/selectors';
import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { useSelector } from 'store';
import { selectApplicationDocument } from 'store/application/selectors';
import { fetchActiveApplication } from 'store/previousApplicationDetail/actions';
import { fetchRetailerByApplicationId } from 'store/retailer/actions';
import { fetchAgreementUrl } from '../store/agreement/actions';
import {
  selectAgreementError,
  selectAgreementUrl
} from '../store/agreement/selectors';
import { documentDownloadConfirmation } from '../store/documentDownloadConfirmation/actions';
import {
  selectEsatNoteEnabled,
  selectRetailerForApplication
} from '../store/retailer/selectors';
import { selectDirectAppLoggedIn } from '../store/user/selectors';

export interface RouteParams {
  applicationId: string;
}

export type ApplicationCompleteProps = RouteComponentProps<RouteParams>;

const ApplicationComplete: React.FunctionComponent<ApplicationCompleteProps> = ({
  match: {
    params: { applicationId }
  }
}) => {
  const applicationRetailer = useSelector(selectRetailerForApplication);
  const retailerName = applicationRetailer.name;

  const esatNoteEnabled = useSelector(selectEsatNoteEnabled);
  const directAppLoggedIn = useSelector(selectDirectAppLoggedIn);

  const document = useSelector(selectApplicationDocument);
  const emailAddress = selectEmailAddress(document);

  const agreementUrl = useSelector(selectAgreementUrl);
  const agreementError = useSelector(selectAgreementError);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchRetailerByApplicationId.request({ id: applicationId }));
    if (!directAppLoggedIn) {
      dispatch(fetchActiveApplication.request({ id: applicationId }));
      dispatch(
        fetchAgreementUrl.request({
          applicationId,
          forceDownload: false
        })
      );
    }
  }, [applicationId, dispatch, directAppLoggedIn]);

  const onDownloadClick = () => {
    dispatch(
      documentDownloadConfirmation.request({
        applicationId,
        documentIdentifier: DocumentIdentifier.CreditAgreement
      })
    );
  };

  let downloadOrPrint = '';
  if (emailAddress) {
    downloadOrPrint +=
      'A downloadable version will be emailed to the customer shortly.';
    if (!directAppLoggedIn) {
      downloadOrPrint += ' You may also print them a copy.';
    }
  } else {
    downloadOrPrint += 'Please print a copy for the customer.';
  }

  return (
    <div className='compass-application-complete'>
      <>
        <NovunaHeading as='h1' id='complete-heading'>
          Application complete
        </NovunaHeading>
        <Message variant='info' mt={3} icon={<NovunaDocument />}>
          <NovunaHeading as='h3'>
            {esatNoteEnabled ? 'Almost done' : 'All done'}
          </NovunaHeading>
          <Text data-test-id='loan-agreement-signed-and-complete' mt={1}>
            The loan agreement is signed and complete. {downloadOrPrint}
          </Text>
          <Text mt={2}>
            <strong>{applicationId}</strong>
          </Text>
          {!directAppLoggedIn && (
            <>
              <Button
                mt={3}
                id='complete-downloadagreement-button'
                isJumbo
                {...(agreementUrl && {
                  as: 'a',
                  href: agreementUrl,
                  target: '_blank'
                })}
                onClick={onDownloadClick}
                disabled={!agreementUrl || agreementError}>
                {agreementUrl ? 'Print agreement' : 'Preparing\u2026'}
              </Button>
              {agreementError && (
                <SectionError mt={3}>
                  The download is not available, refresh the page to try again
                </SectionError>
              )}
            </>
          )}
        </Message>
      </>

      <Box mt={4}>
        <NovunaHeading as='h2' id='complete-nextsteps-heading' mb={2}>
          What happens next?
        </NovunaHeading>
        {[
          {
            heading: 'Delivery',
            text: `${retailerName} will arrange the delivery and/or installation of your purchase.`
          },
          esatNoteEnabled
            ? {
                heading: 'Are you satisfied?',
                text:
                  "We'll send you a satisfaction note by email. Please let us know that you're satisfied with your purchase."
              }
            : {},
          {
            heading: 'Payments',
            text:
              "Your Direct Debit payments will begin, we'll write to you to tell you when."
          },
          {
            heading: 'Manage online',
            text:
              "We'll contact you with instructions on how to access your loan information.",
            last: true
          }
        ]
          .filter(({ heading, text }) => heading && text)
          .map(({ heading, text, last = false }, idx) => (
            <Fragment key={idx}>
              <Box mb={last ? 0 : 4}>
                <NovunaHeading as='h3'>{heading}</NovunaHeading>
                <Text mt={2}>{text}</Text>
              </Box>
            </Fragment>
          ))}
      </Box>
    </div>
  );
};

export default ApplicationComplete;
