import { AddressWithDate, FinanceApplication } from 'hitachi-retail-core';
import { DeepPartial } from 'hitachi-retail-core/build/utils/deepPartial';
import {
  deliveryAddressTypeValue,
  otherIdentityValue
} from 'hitachi-retail-core/build/enums';
import React, { useEffect } from 'react';
import { useSelector } from 'store';
import {
  selectApplicationDocument,
  selectFetchApplicationStatus
} from 'store/application/selectors';
import { AsyncStatus } from 'applicantApp/store/AsyncStatus';
import { ApplicantApplicationProps } from 'applicantApp/pages/ApplicantApplication/ApplicantApplication';
import { mainAddressDetailsStep } from './components/Address/AddressDetails';
import { bankDetailsStep } from './components/BankDetails';
import { financialDetailsStep } from './components/FinancialDetails';
import { MultiStepForm } from './components/MultiStepForm';
import { personalDetailsStep } from './components/PersonalDetails';
import {
  firstPreviousAddressDetailsStep,
  secondPreviousAddressDetailsStep
} from './components/Address/AddressDetails';
import { DetailsCaptureValues } from './components/schema';
import { contactDetailsStep } from './components/ContactDetails';
import { selectCurrentStepIndex } from 'applicantApp/store/changeFormStep/reducer';
import { scrollToTop } from 'components/meta/ScrollToTop';

export const steps = [
  personalDetailsStep,
  contactDetailsStep,
  mainAddressDetailsStep,
  firstPreviousAddressDetailsStep,
  secondPreviousAddressDetailsStep,
  financialDetailsStep,
  bankDetailsStep
];

export const emptyAddress = () => ({
  address: {
    postCode: '',
    town: '',
    county: '',
    flatName: '',
    houseName: '',
    houseNumber: '',
    street: ''
  },
  fromMonth: '' as any,
  fromYear: '' as any
});

const emptyForm: DetailsCaptureValues = {
  personalDetails: {
    title: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    maritalStatus: '',
    middleName: ''
  },
  contactDetails: {
    emailAddress: '',
    phoneNumber: ''
  },
  financialDetails: {
    employmentStatus: '',
    financialDependants: '',
    incomeValue: '' as any,
    spouseEmploymentStatus: ''
  },
  mainAddressDetails: {
    accommodationStatus: '',
    mainAddress: [emptyAddress()]
  },
  deliveryDetails: {
    goodsDelivered: deliveryAddressTypeValue.CurrentAddress
  },
  identityDetails: {
    proofOfIdentity: otherIdentityValue.CurrentAddressDelivery
  },
  bankAccountDetails: {
    name: '',
    sortCode: '',
    accountNumber: ''
  },
  addressEditable: true
};

export const toFormValues = ({
  personalDetails,
  contactDetails,
  financialDetails,
  mainAddressDetails,
  bankAccountDetails,
  returnUrl,
  marketingConsent,
  addressEditable
}: DeepPartial<FinanceApplication>): DetailsCaptureValues => ({
  personalDetails: {
    ...emptyForm.personalDetails,
    ...personalDetails
  },
  contactDetails: {
    ...emptyForm.contactDetails,
    ...contactDetails
  },
  financialDetails: {
    ...emptyForm.financialDetails,
    ...financialDetails
  },
  deliveryDetails: {
    ...emptyForm.deliveryDetails
  },
  identityDetails: {
    ...emptyForm.identityDetails
  },
  mainAddressDetails: {
    accommodationStatus:
      mainAddressDetails?.accommodationStatus ||
      emptyForm.mainAddressDetails.accommodationStatus,
    mainAddress: (mainAddressDetails?.mainAddress?.length
      ? mainAddressDetails?.mainAddress
      : emptyForm.mainAddressDetails.mainAddress
    ).map(
      ({ address, fromMonth, fromYear }): AddressWithDate => {
        const emptyAddr = emptyForm.mainAddressDetails.mainAddress[0];
        return {
          ...emptyAddr,
          address: {
            ...emptyAddr.address,
            ...address
          },
          fromMonth: fromMonth || emptyAddr.fromMonth,
          fromYear: fromYear || emptyAddr.fromYear
        };
      }
    )
  },
  bankAccountDetails: {
    ...emptyForm.bankAccountDetails,
    ...bankAccountDetails
  },
  returnUrl: returnUrl,
  addressEditable,
  marketingConsent: returnUrl
    ? {
        emailOptIn: marketingConsent?.emailOptIn || false,
        postOptIn: marketingConsent?.postOptIn || false,
        lastUpdated: marketingConsent?.lastUpdated || new Date().toISOString()
      }
    : undefined
});

export const ApplicantDetailsCapture: React.FC<ApplicantApplicationProps> = ({
  onSave,
  onDetailsCaptureComplete,
  gotoFormStep,
  id
}) => {
  const document = useSelector(selectApplicationDocument);
  const fetchApplicationStatus = useSelector(selectFetchApplicationStatus);
  const currentStepIndex = useSelector(selectCurrentStepIndex);

  // Reset the scroll position between the steps
  useEffect(() => {
    requestAnimationFrame(scrollToTop);
  }, [currentStepIndex]);

  if (fetchApplicationStatus !== AsyncStatus.Success) {
    return null;
  }

  return (
    <MultiStepForm<DetailsCaptureValues>
      steps={steps}
      initialValues={toFormValues(document)}
      onSave={onSave}
      applicationId={id}
      onFormComplete={onDetailsCaptureComplete}
      gotoFormStep={gotoFormStep}
    />
  );
};
