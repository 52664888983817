import Axios, { AxiosInstance } from 'axios';
import { useEffect, useState } from 'react';

export interface AppDomainConfig {
  applicantDomain: string;
  retailerDomain: string;
  currentEnv: string;
}

export const useAppDomainConfig = () => {
  const [config, setConfig] = useState<AppDomainConfig | undefined>();
  const apiClient = Axios.create();

  // Get the config necessary for wrapping up the application
  useEffect(() => {
    async function fetchConfig(apiClient: AxiosInstance) {
      const { data } = await apiClient.get<AppDomainConfig>('/config.json');

      setConfig(data);
    }

    if (!config) {
      fetchConfig(apiClient);
    }
  }, [apiClient, config]);

  return config;
};
