import { SAVE_SIGNING_COMPLETE_PATH, SIGNING_REDIRECT } from './constants';
import { createStandardAction } from 'typesafe-actions';
import { State } from './reducer';

interface SaveSigningCompletePathPayload {
  signingCompletePath?: string;
}

interface SigningRedirectPayload {
  signingRedirects: State;
}

export const saveSigningCompletePath = createStandardAction(
  SAVE_SIGNING_COMPLETE_PATH
)<SaveSigningCompletePathPayload>();

export const signingRedirect = createStandardAction(SIGNING_REDIRECT)<
  SigningRedirectPayload
>();
