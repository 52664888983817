import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import CustomerDissatisfied, {
  CustomerDissatisfiedPropsFromDispatch,
  CustomerDissatisfiedPropsFromState
} from '../components/CustomerDissatisfied';
import { RootState } from '../store';
import { satisfactionFeedback } from '../store/customerSatisfaction/actions';

export const mapStateToProps = ({
  customerSatisfaction: {
    feedback: { alreadySubmitted, complete, error },
    processing
  },
  retailer: { retailer },
  save: { applicationId }
}: RootState): CustomerDissatisfiedPropsFromState => ({
  feedbackAlreadySubmitted: alreadySubmitted,
  feedbackComplete: complete,
  feedbackError: error,
  processing,
  retailerName: retailer.name,
  applicationId
});

export const mapDispatchToProps = (
  dispatch: Dispatch
): CustomerDissatisfiedPropsFromDispatch => ({
  submitFeedback: (token, feedback) =>
    dispatch(
      satisfactionFeedback.request({ token, satisfied: false, feedback })
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerDissatisfied);
