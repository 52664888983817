export const FETCH_RETAILER_REQUEST = 'FETCH_RETAILER_REQUEST';
export const FETCH_RETAILER_SUCCESS = 'FETCH_RETAILER_SUCCESS';
export const FETCH_RETAILER_FAILURE = 'FETCH_RETAILER_FAILURE';

export const FETCH_RETAILER_BY_APPLICATION_ID_REQUEST =
  'FETCH_RETAILER_BY_APPLICATION_ID_REQUEST';
export const FETCH_RETAILER_BY_APPLICATION_ID_SUCCESS =
  'FETCH_RETAILER_BY_APPLICATION_ID_SUCCESS';
export const FETCH_RETAILER_BY_APPLICATION_ID_FAILURE =
  'FETCH_RETAILER_BY_APPLICATION_ID_FAILURE';

export const FETCH_ALL_BRANCHES_REQUEST = 'FETCH_ALL_BRANCHES_REQUEST';
export const FETCH_ALL_BRANCHES_SUCCESS = 'FETCH_ALL_BRANCHES_SUCCESS';
export const FETCH_ALL_BRANCHES_FAILURE = 'FETCH_ALL_BRANCHES_FAILURE';

export const FETCH_BRANCHES_FOR_PROPOSING_REQUEST =
  'FETCH_BRANCHES_FOR_PROPOSING_REQUEST';
export const FETCH_BRANCHES_FOR_PROPOSING_SUCCESS =
  'FETCH_BRANCHES_FOR_PROPOSING_SUCCESS';
export const FETCH_BRANCHES_FOR_PROPOSING_FAILURE =
  'FETCH_BRANCHES_FOR_PROPOSING_FAILURE';

export const SET_PROPOSING_ON_BEHALF_OF = 'SET_PROPOSING_ON_BEHALF_OF';
