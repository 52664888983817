import React from 'react';
import { Table, TableRow, Text } from 'compass-design';

export interface Note {
  username: string;
  message: string;
  createdDateTime: string;
}

interface NotesProps {
  notes?: Note[];
}

export const Notes: React.FC<NotesProps> = props => (
  <Table
    columnNames={['Note', 'Added by', 'Added']}
    emptyTableMessage='No notes yet'>
    {props.notes &&
      props.notes.length &&
      props.notes.map((note, idx) => (
        <TableRow key={idx}>
          <Text>{note.message}</Text>
          <Text>{note.username}</Text>
          <Text>{note.createdDateTime}</Text>
        </TableRow>
      ))}
  </Table>
);

export default Notes;
