import { Decimal } from 'hitachi-retail-core/build/utils/decimal';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';

const baseData = {
  updated: '2020-11-05T10:56:01.744Z',
  created: '2020-11-04T10:12:42.745Z',
  softSearchExpiryDate: '2021-06-16T10:12:42.745Z',
  tailoringExpiryDate: '2021-06-16T10:12:42.745Z',
  mainAddress: {
    county: '',
    flatName: '',
    houseName: 'ENTERPRISE HOUSE',
    houseNumber: '1',
    postCode: 'LS11 9BH',
    street: 'APEX VIEW',
    town: 'LEEDS'
  }
};

const product = {
  apr: '0',
  regulated: false,
  serviceType: 'IF',
  productConfig: {
    id: 70,
    apr: '0',
    rpm: '0',
    name: 'Interest free credit',
    endDate: '2020-10-25T23:59:59.000Z',
    startDate: '2019-10-26T00:00:00.000Z',
    instalments: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    serviceType: 'IF',
    maxLoanAmount: '25000',
    minLoanAmount: '250',
    deferralPeriod: [],
    minGoodsAmount: '250',
    minDepositAmount: '0',
    earlySettlementFee: false,
    minDepositPercentage: '0'
  },
  earlySettlementFee: false
};

const document = {
  product,
  wal_buster: '20201105105601.744',
  loanRepayment: {
    instalments: 6,
    deferralPeriod: null
  },
  contactDetails: {
    phoneNumber: '07837091133',
    emailAddress: 'robert.rosebury@infinityworks.com'
  },
  loanParameters: {
    deposit: '500',
    goodsAmount: '5000',
    creditCardDeposit: true
  },
  customerPresent: true,
  deliveryDetails: {
    goodsDelivered: 'NO'
  },
  identityDetails: {
    proofOfIdentity: 'DRIVING_LICENCE'
  },
  personalDetails: {
    title: 'MR',
    lastName: 'TTTPAC',
    firstName: 'Test',
    formerName: 'TTTPAC',
    dateOfBirth: '1960-01-01',
    maritalStatus: 'SINGLE'
  },
  financialDetails: {
    incomeValue: 900,
    employmentStatus: 'EMPLOYED',
    financialDependants: '0'
  },
  goodsDescription: 'Sofa',
  paperlessConsent: true,
  bankAccountDetails: {
    name: 'Test',
    town: 'Chatham',
    county: 'Kent',
    bankName: 'NAT WEST BANK PLC',
    postCode: 'ME4 4RT',
    sortCode: '560003',
    branchName: 'ST JAMES & PICCADILLY',
    addressLine1: 'Chatham Customer Service Centre',
    addressLine2: 'Western Avenue',
    addressLine3: 'Waterside Court',
    accountNumber: '13354647',
    bankSupportsDirectDebit: true
  },
  mainAddressDetails: {
    mainAddress: [
      {
        address: {
          town: 'LEEDS',
          street: 'APEX VIEW',
          postCode: 'LS11 9BH',
          houseName: 'ENTERPRISE HOUSE',
          houseNumber: '1'
        },
        fromYear: 2000,
        fromMonth: 1
      }
    ],
    accommodationStatus: 'OWNER_OCCUPIER'
  },
  loanTailoring: {
    maxInstalmentAmount: '450'
  }
};

const retailer = {
  name: 'Mail Order Retailer',
  retailerNormalisedName: 'mailorderretailer',
  supplierNumber: '12345678',
  address: {
    houseName: 'Building',
    houseNumber: '40',
    street: 'Scott Green',
    town: 'Leeds',
    county: 'West Yorkshire',
    postCode: 'LS10 1GA'
  },
  automaticSettlementEnabled: true,
  immediatePayoutEnabled: true,
  esatNoteEnabled: true
};

export const acceptApplication = {
  id: 'ACCEPT123',
  status: ApplicationStatus.open,
  applicantName: `Mr Test TTTPAC`,
  ...baseData,
  document: {
    ...document,
    financialDetails: {
      ...document.financialDetails,
      incomeValue: 900
    }
  },
  canResume: true,
  canCancel: true,
  canView: false,
  canMarkAsDelivered: false,
  canDownloadDeclineLetter: false,
  canDownloadSignedAgreement: false,
  retailer
};

export const declineApplication = {
  id: 'DECLINE12',
  status: ApplicationStatus.open,
  applicantName: `Mr Test TTTPAC`,
  ...baseData,
  document: {
    ...document,
    financialDetails: {
      ...document.financialDetails,
      incomeValue: 500
    },
    ...product
  },
  canResume: true,
  canCancel: true,
  canView: false,
  canMarkAsDelivered: false,
  canDownloadDeclineLetter: false,
  canDownloadSignedAgreement: false,
  retailer
};

export const payoutApplication = {
  id: 'PAYOUT123',
  status: ApplicationStatus.payout_pending,
  applicantName: `Mr Test TTTPA`,
  ...baseData,
  document: {
    ...document,
    financialDetails: {
      ...document.financialDetails,
      incomeValue: 500
    },
    ...product
  },
  canResume: true,
  canCancel: true,
  canView: false,
  canMarkAsDelivered: false,
  canDownloadDeclineLetter: false,
  canDownloadSignedAgreement: false,
  retailer
};

const totalCost = '5000';
const deposit = '100';

const returnedApplication = {
  applicationStatus: ApplicationStatus.open,
  lastName: 'TTTPAC',
  totalCost,
  loanAmount: new Decimal(totalCost).minus(deposit).toString(),
  deposit,
  apr: '0',
  repaidOver: 9,
  maxInstalment: 350
};

export const defaultApplication = {
  id: 'AAGGH39C6',
  status: returnedApplication.applicationStatus,
  applicantName: `Mr Base ${returnedApplication.lastName}`,
  updated: '2020-11-05T10:56:01.744Z',
  created: '2020-11-04T10:12:42.745Z',
  softSearchExpiryDate: '2021-03-16T10:12:42.745Z',
  mainAddress: {
    county: '',
    flatName: '',
    houseName: 'ENTERPRISE HOUSE',
    houseNumber: '1',
    postCode: 'LS11 9BH',
    street: 'APEX VIEW',
    town: 'LEEDS'
  },
  document: {
    product: {
      apr: '0',
      regulated: false,
      serviceType: 'IF',
      productConfig: {
        id: 70,
        apr: '0',
        rpm: '0',
        name: 'Interest free credit',
        endDate: '2020-10-25T23:59:59.000Z',
        startDate: '2019-10-26T00:00:00.000Z',
        instalments: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        serviceType: 'IF',
        maxLoanAmount: '25000',
        minLoanAmount: '250',
        deferralPeriod: [],
        minGoodsAmount: '250',
        minDepositAmount: '0',
        earlySettlementFee: false,
        minDepositPercentage: '0'
      },
      earlySettlementFee: false
    },
    wal_buster: '20201105105601.744',
    loanRepayment: {
      instalments: returnedApplication.repaidOver,
      deferralPeriod: null
    },
    contactDetails: {
      phoneNumber: '07777777777',
      emailAddress: 'email@email.com'
    },
    loanParameters: {
      deposit: returnedApplication.deposit,
      goodsAmount: returnedApplication.totalCost,
      creditCardDeposit: false
    },
    customerPresent: true,
    deliveryDetails: {
      goodsDelivered: 'NO'
    },
    identityDetails: {
      proofOfIdentity: 'DRIVING_LICENCE'
    },
    personalDetails: {
      title: 'MR',
      lastName: 'TTTPAC',
      firstName: 'Test',
      formerName: 'TTTPAC',
      dateOfBirth: '1960-01-01',
      maritalStatus: 'SINGLE'
    },
    financialDetails: {
      incomeValue: returnedApplication.maxInstalment,
      employmentStatus: 'EMPLOYED',
      financialDependants: '0'
    },
    goodsDescription: 'Sofa',
    paperlessConsent: true,
    bankAccountDetails: {
      name: 'Test',
      town: 'Chatham',
      county: 'Kent',
      bankName: 'NAT WEST BANK PLC',
      postCode: 'ME4 4RT',
      sortCode: '560003',
      branchName: 'ST JAMES & PICCADILLY',
      addressLine1: 'Chatham Customer Service Centre',
      addressLine2: 'Western Avenue',
      addressLine3: 'Waterside Court',
      accountNumber: '13354647',
      bankSupportsDirectDebit: true
    },
    mainAddressDetails: {
      mainAddress: [
        {
          address: {
            town: 'LEEDS',
            street: 'APEX VIEW',
            postCode: 'LS11 9BH',
            houseName: 'ENTERPRISE HOUSE',
            houseNumber: '1'
          },
          fromYear: 2000,
          fromMonth: 1
        }
      ],
      accommodationStatus: 'OWNER_OCCUPIER'
    }
  },
  canResume: true,
  canCancel: true,
  canView: false,
  canMarkAsDelivered: false,
  canDownloadDeclineLetter: false,
  canDownloadSignedAgreement: false,
  // revertibleVersion,
  retailer: {
    name: 'Mail Order Retailer',
    retailerNormalisedName: 'mailorderretailer',
    supplierNumber: '12345678',
    address: {
      houseName: 'Building',
      houseNumber: '40',
      street: 'Scott Green',
      town: 'Leeds',
      county: 'West Yorkshire',
      postCode: 'LS10 1GA'
    },
    automaticSettlementEnabled: false,
    immediatePayoutEnabled: false,
    esatNoteEnabled: false
  }
};
