import {
  Button,
  InputWrapper,
  Message,
  NovunaHeading,
  TertiaryLink,
  TertiaryLinkDirection,
  Text,
  TextField,
  PasswordStrengthBar,
  Link,
  InfoOutline
} from 'compass-design';
import { Field, FieldProps, Form, Formik } from 'formik';
import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import { checkPasswordValidity } from 'hitachi-retail-core/build/utils/password';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router';
import { LoggedOutWrapper } from 'retailerApp/components/LoggedOutWrapper';
import FeatureToggle from '../../../containers/meta/FeatureToggle';
import { routes } from '../../../routes';
import { validationMessages } from 'hitachi-retail-core/build/application/messages';
import { useSelector } from 'react-redux';
import { selectRetailer } from 'store/retailer/selectors';
import { getContextualPageData } from '../Profile/ManagePassword/utils';
import { selectRetailerSubdomain } from 'store/config/selectors';
import { StrongerPasswordGuidance } from 'components/StrongerPasswordGuidance';
import { PasswordStrengthLabel } from 'components/PasswordStrengthLabel';

interface SetNewPasswordValues {
  retailerName: string;
  username: string;
  password: string;
  confirmPassword: string;
  session: string;
  givenName: string;
  familyName: string;
}

export interface SetNewPasswordPropsFromState {
  errorMessage?: string;
  username?: string;
  retailerName?: string;
  familyName?: string;
  givenName?: string;
  code?: string;
  session?: string;
  setPasswordProcessing: boolean;
  setPasswordSuccess: boolean;
}

export interface SetNewPasswordPropsFromDispatch {
  onSubmit: (values: SetNewPasswordValues) => void;
  onBackToLogin: () => void;
}

export type SetNewPasswordProps = SetNewPasswordPropsFromState &
  SetNewPasswordPropsFromDispatch;

export interface SetNewPasswordState {
  password: string;
  passwordError?: string;
}

export const SetNewPassword = ({
  username,
  retailerName,
  familyName,
  givenName,
  session,
  errorMessage: serviceErrorMessage,
  setPasswordProcessing,
  setPasswordSuccess,
  onSubmit,
  onBackToLogin
}: SetNewPasswordProps) => {
  const retailer = useSelector(selectRetailer);
  const subdomain = useSelector(selectRetailerSubdomain);
  const [guidanceModalOpen, setGuidanceModalOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [warningMessages, setWarningMessages] = useState<string | undefined>(
    ''
  );

  useEffect(() => {
    const PasswordCheck = checkPasswordValidity({
      password: password,
      inputs: getContextualPageData({ retailer, username, subdomain })
    });

    setIsPasswordValid(PasswordCheck.isValid);
    setPasswordStrength(PasswordCheck.score || 0);

    if (password.length >= 1 && password.length <= 7) {
      setWarningMessages(
        'Your password requires a minimum length of 8 characters.'
      );
      return;
    }

    if (PasswordCheck.messages) {
      const messageString = PasswordCheck.messages
        .slice(0, 2)
        .map((message: string, index: number, arr: string[]) => {
          if (index < arr.length - 1) {
            return `${message}\n`;
          } else {
            return message;
          }
        })
        .join('');
      setWarningMessages(messageString);
    }
  }, [password, retailer, username, subdomain]);

  if (!session) {
    return <Redirect to={{ pathname: routes.login }} />;
  }

  if (!setPasswordProcessing && setPasswordSuccess) {
    return <Redirect to={{ pathname: routes.index }} />;
  }

  const initialValues: SetNewPasswordValues = {
    retailerName: retailerName ?? '',
    username: username ?? '',
    password: '',
    confirmPassword: '',
    familyName: familyName ?? '',
    givenName: givenName ?? '',
    session: session ?? ''
  };

  const passwordsMatch = password === confirmPassword;

  const pwForm = (
    <Formik
      initialValues={initialValues}
      onSubmit={values => onSubmit(values)}
      enableReinitialize>
      <Form noValidate>
        <Field name='password'>
          {({ field, meta }: FieldProps<string, SetNewPasswordValues>) => {
            return (
              <InputWrapper
                id='set-password'
                label={
                  ((
                    <>
                      Password
                      <Link
                        href='#'
                        sx={{
                          ml: '0.5em',
                          lineHeight: '1.2em',
                          '>svg': { verticalAlign: 'middle' }
                        }}
                        onClick={() => setGuidanceModalOpen(true)}>
                        <InfoOutline
                          size={16}
                          sx={{ margin: '0', padding: '0' }}
                        />
                      </Link>
                    </>
                  ) as unknown) as string
                }
                error={
                  !!warningMessages && password.length > 0
                    ? warningMessages
                    : undefined
                }
                tone={
                  !!warningMessages && password.length > 0
                    ? 'negative'
                    : 'neutral'
                }>
                <TextField
                  id='set-password'
                  type='password'
                  placeholder='Use at least 8 characters '
                  data-test-id='compass-set-password-control'
                  tone={meta.touched && meta.error ? 'negative' : 'neutral'}
                  {...field}
                  onChange={(e: {
                    target: { value: React.SetStateAction<string> };
                  }) => {
                    setPassword(e.target.value);
                    field.onChange(e);
                  }}
                />
              </InputWrapper>
            );
          }}
        </Field>
        <PasswordStrengthBar strength={passwordStrength || 0} mb={'10px'} />
        <PasswordStrengthLabel strength={passwordStrength || 0} />
        <Field name='confirmPassword'>
          {({ field, meta }: FieldProps<string, SetNewPasswordValues>) => {
            return (
              <InputWrapper
                id='confirm-password'
                label='Confirm password'
                error={
                  !passwordsMatch && confirmPassword.length > 0
                    ? validationMessages.INVALID_CONFIRMATION_PASSWORD_VALUE
                    : undefined
                }
                tone={
                  !passwordsMatch && confirmPassword.length > 0
                    ? 'negative'
                    : 'neutral'
                }>
                <TextField
                  id='confirm-password'
                  type='password'
                  data-test-id='compass-confirm-password-control'
                  tone={meta.touched && meta.error ? 'negative' : 'neutral'}
                  {...field}
                  onChange={(e: {
                    target: { value: React.SetStateAction<string> };
                  }) => {
                    setConfirmPassword(e.target.value);
                    field.onChange(e);
                  }}
                />
              </InputWrapper>
            );
          }}
        </Field>
        <Button
          mt={5}
          disabled={
            setPasswordProcessing || !isPasswordValid || !passwordsMatch
          }
          data-test-id='compass-set-password-submit'
          isJumbo>
          {setPasswordProcessing ? 'Please wait\u2026' : 'Confirm'}
        </Button>
      </Form>
    </Formik>
  );

  return (
    <FeatureToggle feature={CompassFeature.ENHANCED_AUTHENTICATION}>
      <Helmet>
        <title>Set password - CreditMaster3</title>
      </Helmet>
      <LoggedOutWrapper
        backLink={
          <TertiaryLink
            text='Sign in'
            direction={TertiaryLinkDirection.BACKWARDS}
            onClick={() => onBackToLogin()}
          />
        }>
        <StrongerPasswordGuidance
          modalOpen={guidanceModalOpen}
          toggleModalOpen={setGuidanceModalOpen}
          managePasswordBtn={false}
        />
        {serviceErrorMessage && (
          <Message
            mb={4}
            variant='error'
            data-test-id='compass-set-password-service-error'>
            <NovunaHeading as='h3' mb={1}>
              Something went wrong
            </NovunaHeading>
            <Text>{serviceErrorMessage}</Text>
          </Message>
        )}
        <NovunaHeading as='h1' mb={3}>
          Set password
        </NovunaHeading>
        {pwForm}
      </LoggedOutWrapper>
    </FeatureToggle>
  );
};

export default SetNewPassword;
