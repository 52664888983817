import { AxiosError } from 'axios';
import { DefaultSagaParams } from '../sagas';
import { SatisfactionFeedbackPayload } from '../store/customerSatisfaction/actions';
import { CustomerFeedback } from '../store/previousApplicationDetail/actions';

export interface CustomerSatisfactionService {
  submitFeedback: (payload: SatisfactionFeedbackPayload) => Promise<void>;
  fetchFeedback: (
    applicationId: string
  ) => Promise<CustomerFeedback[] | undefined>;
}

export const getCustomerSatisfactionService = ({
  apiClient
}: DefaultSagaParams): CustomerSatisfactionService => ({
  submitFeedback: async ({ token, ...payload }) => {
    try {
      await apiClient.post(`esat/feedback/${token}`, payload);
    } catch (err) {
      const error = err as AxiosError;
      if (error.response) {
        const response = error.response;
        if (response.status >= 400 && response.status < 500) {
          throw Error(response.data.message);
        }
      }
      throw new Error('Customer satisfaction feedback submission failed');
    }
  },
  fetchFeedback: async applicationId => {
    const endpoint = `protected/applications/${applicationId}/customer-feedback`;
    try {
      const response = await apiClient.get<CustomerFeedback[]>(endpoint);

      return response.data;
    } catch (err) {
      throw new Error('Failed to retrieve customer feedback for application');
    }
  }
});
