import {
  Button,
  InlineCheckbox,
  Link,
  List,
  ListItem,
  NovunaHeading
} from 'compass-design';
import { IncompleteFinanceApplication } from 'hitachi-retail-core/build/schemas/financeApplication';
import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { detailsPath } from '../routes';
import ThemedList from './ThemedList';

export interface PrivacyPolicyPropsFromState {
  paperlessConsent: boolean;
}

export interface PrivacyPolicyPropsFromDispatch {
  onApplicationUpdate: (document: IncompleteFinanceApplication) => void;
}

export type PrivacyPolicyProps = PrivacyPolicyPropsFromState &
  PrivacyPolicyPropsFromDispatch;

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = ({
  paperlessConsent,
  onApplicationUpdate
}) => {
  const history = useHistory();
  const [paperlessConsentError, setPaperlessConsentError] = useState(false);

  const paperlessConsentHandlers = useMemo(
    () => ({
      onChange: ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        onApplicationUpdate({ paperlessConsent: target.checked });

        if (paperlessConsentError && target.checked) {
          setPaperlessConsentError(false);
        }
      }
    }),
    [paperlessConsentError, onApplicationUpdate]
  );

  return (
    <>
      <Helmet>
        <title>Privacy Policy - CreditMaster3</title>
      </Helmet>
      <NovunaHeading as='h1' id='privacypolicy-heading' mb={3}>
        Privacy policy
      </NovunaHeading>
      <p>
        The personal information you are providing about yourself and any spouse
        or financial associate will be used by Novuna Consumer Finance in the
        following ways:
      </p>
      <p>We will share your information with:</p>
      <ThemedList
        items={[
          'Fraud prevention and Credit Reference Agencies (CRAs) including public records and shared credit entities'
        ]}
      />
      <p>We will use your information to:</p>
      <ThemedList
        items={[
          'Assess your creditworthiness and affordability',
          'Verify accuracy of the data you have provided',
          'Prevent criminal activity, fraud and money laundering',
          'Manage your account(s)',
          'Trace and recover debts',
          'Ensure any offers provided are appropriate to your circumstances',
          'Improve our products and services'
        ]}
      />
      <p>Other important information you should be aware of:</p>
      <ThemedList
        items={[
          'We will continue to exchange information about you with CRAs while you have a relationship with us',
          'If you borrow and do not repay in full and on time, CRAs will record the outstanding debt',
          'This information may be supplied to other organisations by CRAs',
          'CRAs will place a search footprint on your credit file that may be seen by other lenders'
        ]}
      />
      <p>
        Further details of how your information will be used by us and these
        credit and fraud prevention agencies, the impact of us using and sharing
        your data, and your data protection rights, can be found by visiting
        these sites:
      </p>
      <List fontSize='1'>
        <ListItem>
          <Link
            href='https://www.cifas.org.uk/'
            target='_blank'
            rel='noopener noreferrer'>
            Cifas
          </Link>
        </ListItem>
        <ListItem>
          <Link
            href='https://www.novunapersonalfinance.co.uk/crain'
            target='_blank'
            rel='noopener noreferrer'>
            Novuna Consumer Finance
          </Link>
        </ListItem>
        <ListItem>
          <Link
            href='https://www.transunion.co.uk/'
            target='_blank'
            rel='noopener noreferrer'>
            TransUnion
          </Link>
        </ListItem>
        <ListItem>
          <Link
            href='https://www.equifax.co.uk/'
            target='_blank'
            rel='noopener noreferrer'>
            Equifax
          </Link>
        </ListItem>
        <ListItem>
          <Link
            href='https://www.experian.co.uk/'
            target='_blank'
            rel='noopener noreferrer'>
            Experian
          </Link>
        </ListItem>
      </List>
      <h2>Paperless documents</h2>
      <p>
        Important documents relating to your agreement will be emailed to you at
        the email address provided. These documents may be accessed as either an
        attachment or a link.
      </p>
      <InlineCheckbox
        id='privacypolicy-paperless-consent'
        checked={paperlessConsent}
        error={
          paperlessConsentError
            ? 'Please give consent if you wish to continue'
            : undefined
        }
        tone={paperlessConsentError ? 'negative' : undefined}
        {...paperlessConsentHandlers}>
        I consent to Novuna Consumer Finance sending my documents by email
      </InlineCheckbox>
      <Button
        mt={5}
        id='privacypolicy-submit-button'
        isJumbo
        onClick={() => {
          if (!paperlessConsent) {
            setPaperlessConsentError(true);
            return;
          }

          history.push(detailsPath);
        }}
        data-test-id='compass-privacypolicy-continue'>
        Continue
      </Button>
    </>
  );
};

export default PrivacyPolicy;
