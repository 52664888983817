import { Box, InputWrapper, TextField } from 'compass-design';
import { UserInputWithError } from '../EditLoanCardState/EditLoanCardState';
import isNumber from 'utils/isNumber';
import React, { useState } from 'react';

interface AmendLoanHeaderProps {
  totalCost: UserInputWithError;
  setTotalCost: (totalCostInput: string) => void;
}

export const AmendLoanHeader = ({
  totalCost,
  setTotalCost
}: AmendLoanHeaderProps) => {
  const [totalCostInput, setTotalCostInput] = useState(totalCost.value);

  return (
    <Box mt={3} sx={{ width: ['100%', '50%', '50%'] }}>
      <InputWrapper
        id='total-cost'
        label='Total cost'
        error={totalCost.error || undefined}
        tone={totalCost.error ? 'negative' : 'neutral'}>
        <TextField
          prefix='£'
          placeholder='Enter a new total cost'
          type='total-cost'
          id='total-cost'
          name='Total cost'
          aria-label='total cost'
          tone={totalCost.error ? 'negative' : 'neutral'}
          value={totalCostInput}
          onChange={({ target: { value } }) => {
            const newTotalCost = value;
            if (isNumber(newTotalCost) || newTotalCost === '') {
              setTotalCostInput(newTotalCost);
              setTotalCost(newTotalCost);
            }
          }}
        />
      </InputWrapper>
    </Box>
  );
};
