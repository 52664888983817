import { Message, NovunaHeading, NovunaSearch, Text } from 'compass-design';
import React from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { selectSoftSearchExpiryDate } from 'store/application/selectors';

interface SoftSearchMessageProps {
  hideExpiryDate?: boolean;
}

export const SoftSearchMessageOutcome: React.FC<SoftSearchMessageProps> = ({
  hideExpiryDate = false
}) => {
  const softSearchExpiryDate = useSelector(selectSoftSearchExpiryDate);
  const formattedSoftSearchExpiryDate =
    (softSearchExpiryDate &&
      format(new Date(softSearchExpiryDate), 'do MMMM yyyy')) ??
    'DATE_NOT_FOUND';
  return (
    <Message variant='info' icon={<NovunaSearch />}>
      <NovunaHeading as='h3'>Soft search</NovunaHeading>
      {!hideExpiryDate && (
        <Text mt={1}>
          This soft search check expires on {formattedSoftSearchExpiryDate}
        </Text>
      )}
      <Text mt={hideExpiryDate ? 1 : 0}>
        A soft search has been left on your credit file, however this is not
        visible to other lenders.
      </Text>
    </Message>
  );
};

export const SoftSearchMessageQuote: React.FC = () => (
  <Message variant='info' icon={<NovunaSearch />}>
    <NovunaHeading as='h3'>Soft search</NovunaHeading>

    <Text>
      Checking your eligibility will place a soft search on your credit file
      which is not visible to other lenders.
    </Text>
  </Message>
);
