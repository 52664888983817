import { Box, NovunaCross, Text } from 'compass-design';
import React, { FormEvent } from 'react';
import { NotificationType } from 'store/ui/actions';

export interface NotificationPropsFromState {
  message?: string | JSX.Element;
  type?: string;
  showCloseBtn?: boolean;
  px?: (number | null)[];
  textAlign?: string;
}

export interface NotificationPropsFromDispatch {
  onClose?: () => void;
}

export type NotificationProps = NotificationPropsFromState &
  NotificationPropsFromDispatch;

const getMessageDetailsStyles = (type: string) => {
  switch (type) {
    case NotificationType.SUCCESS:
      return {
        borderColor: '#88cfa3',
        backgroundColor: 'rgba(215, 255, 215, 0.6)'
      };
    case NotificationType.WARNING:
      return {
        borderColor: '#c0bd6a',
        backgroundColor: 'rgba(250, 242, 110, 0.3)'
      };
    case NotificationType.DANGER:
    default:
      return {
        borderColor: '#e09393',
        backgroundColor: 'rgba(218, 94, 94, 0.1)'
      };
  }
};

const Notification: React.FunctionComponent<NotificationProps> = ({
  message,
  type = 'primary',
  onClose,
  children,
  px = [3, null, 6],
  textAlign = 'center',
  showCloseBtn = true
}) =>
  message ? (
    <Box mt={2} mb={4} px={px}>
      <Box
        p={2}
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 40px',
          border: '1px solid',
          borderRadius: '5px',
          textAlign: textAlign as any,
          ...getMessageDetailsStyles(type)
        }}>
        <Box my='auto'>
          {children || <Text id='notification-message'>{message}</Text>}
        </Box>
        {showCloseBtn && (
          <Box
            my='auto'
            sx={{ cursor: 'pointer' }}
            data-test-id='compass-notification-close'
            aria-label='Close'
            onClick={(event: FormEvent) => {
              event.preventDefault();
              onClose && onClose();
            }}>
            <NovunaCross />
          </Box>
        )}
      </Box>
    </Box>
  ) : null;

export default Notification;
