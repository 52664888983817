import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';
import { PgUser } from 'hitachi-retail-core/build/services/pgUsers/pgUsersService';
import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import {
  fetchEnhancedUsersAction,
  createEnhancedUserAction,
  selectedEnhancedUserAction,
  updateEnhancedUserAction,
  resetEnhancedUser,
  setEditUser,
  setSelectedEnhancedUser,
  setSelectedEnhancedBroker,
  setSelectedEnhancedHeadOffice,
  setSelectedEnhancedBranch,
  setDisableViewBranchWarning,
  setBranchSelectorModalIsOpen,
  setBranchSelectorSearchTerm,
  setShowBranchSelectorPage
} from './actions';

export type FetchEnhancedUsersAction = ActionType<
  typeof fetchEnhancedUsersAction
>;
export type CreateEnhancedUserAction = ActionType<
  typeof createEnhancedUserAction
>;
export type SelectedEnhancedUserAction = ActionType<
  typeof selectedEnhancedUserAction
>;
export type UpdateEnhancedUserAction = ActionType<
  typeof updateEnhancedUserAction
>;
export type ResetEnhancedUser = ActionType<typeof resetEnhancedUser>;
export type SetEditUser = ActionType<typeof setEditUser>;
export type SetSelectedEnhancedUser = ActionType<
  typeof setSelectedEnhancedUser
>;
export type SetSelectedEnhancedBroker = ActionType<
  typeof setSelectedEnhancedBroker
>;
export type SetSelectedEnhancedHeadOffice = ActionType<
  typeof setSelectedEnhancedHeadOffice
>;
export type SetSelectedEnhancedBranch = ActionType<
  typeof setSelectedEnhancedBranch
>;
export type SetDisableViewBranchWarning = ActionType<
  typeof setDisableViewBranchWarning
>;
export type SetBranchSelectorModalIsOpen = ActionType<
  typeof setBranchSelectorModalIsOpen
>;
export type SetBranchSelectorSearchTerm = ActionType<
  typeof setBranchSelectorSearchTerm
>;
export type SetShowBranchSelectorPage = ActionType<
  typeof setShowBranchSelectorPage
>;

export interface State {
  loading: boolean;
  editUser: {
    isEditing: boolean;
    isLoading: boolean;
    isCreate?: boolean;
    selectedUser?: PgUser;
    successMessage?: string;
    errorMessage?: string;
  };
  success?: boolean;
  errorMessage?: string;
  usersList?: PgUser[];
  selectedBroker?: Retailer;
  selectedHeadOffice?: Retailer;
  selectedBranch?: Retailer;
  disableViewBranchWarning?: boolean;
  branchSelectorModalIsOpen?: boolean;
  branchSelectorSearchTerm?: string;
  showBranchSelectionPage?: boolean;
}

export const initialState: State = {
  loading: false,
  editUser: {
    isLoading: false,
    isEditing: false
  }
};

export const reducer: Reducer<
  State,
  | FetchEnhancedUsersAction
  | CreateEnhancedUserAction
  | SetEditUser
  | SetSelectedEnhancedUser
  | UpdateEnhancedUserAction
  | ResetEnhancedUser
  | SetSelectedEnhancedBroker
  | SetSelectedEnhancedHeadOffice
  | SetSelectedEnhancedBranch
  | SetDisableViewBranchWarning
  | SetBranchSelectorModalIsOpen
  | SetBranchSelectorSearchTerm
  | SetShowBranchSelectorPage
> = (state = initialState, action) => {
  switch (action.type) {
    case getType(fetchEnhancedUsersAction.request): {
      return { ...state, loading: true };
    }

    case getType(fetchEnhancedUsersAction.success): {
      return {
        ...state,
        loading: false,
        success: true,
        usersList: action.payload
      };
    }

    case getType(fetchEnhancedUsersAction.failure): {
      return {
        ...state,
        errorMessage: action.payload.message,
        loading: false,
        success: false
      };
    }

    case getType(setEditUser): {
      return {
        ...state,
        editUser: {
          isEditing: action.payload.isEditing,
          isCreate: action.payload.isCreate ?? false,
          isLoading: false
        }
      };
    }

    case getType(createEnhancedUserAction.request):
    case getType(updateEnhancedUserAction.request): {
      return {
        ...state,
        editUser: {
          ...state.editUser,
          isLoading: true,
          errorMessage: undefined
        }
      };
    }

    case getType(createEnhancedUserAction.success):
    case getType(updateEnhancedUserAction.success): {
      return {
        ...state,
        editUser: {
          ...state.editUser,
          isLoading: false,
          isEditing: false,
          successMessage: action.payload
        }
      };
    }

    case getType(createEnhancedUserAction.failure):
    case getType(updateEnhancedUserAction.failure): {
      return {
        ...state,
        editUser: {
          ...state.editUser,
          isLoading: false,
          errorMessage: action.payload.message
        }
      };
    }

    case getType(setSelectedEnhancedUser): {
      return {
        ...state,
        editUser: {
          isEditing: true,
          isLoading: false,
          isCreate: false,
          selectedUser: action.payload
        }
      };
    }

    case getType(setSelectedEnhancedBroker): {
      return {
        ...state,
        selectedBroker: action.payload
      };
    }

    case getType(setSelectedEnhancedHeadOffice): {
      return {
        ...state,
        selectedHeadOffice: action.payload
      };
    }

    case getType(setSelectedEnhancedBranch): {
      return {
        ...state,
        selectedBranch: action.payload
      };
    }

    case getType(setDisableViewBranchWarning): {
      return {
        ...state,
        disableViewBranchWarning: action.payload
      };
    }

    case getType(setBranchSelectorModalIsOpen): {
      return {
        ...state,
        branchSelectorModalIsOpen: action.payload
      };
    }

    case getType(setBranchSelectorSearchTerm): {
      return {
        ...state,
        branchSelectorSearchTerm: action.payload,
        showBranchSelectionPage: true
      };
    }

    case getType(setShowBranchSelectorPage): {
      return {
        ...state,
        showBranchSelectionPage: action.payload
      };
    }

    case getType(resetEnhancedUser): {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
