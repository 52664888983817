import { call, put, takeEvery } from 'redux-saga/effects';
import { getType, isOfType } from 'typesafe-actions';
import { ApplicationsService } from '../../services/applicationsService';
import {
  fetchPreviousApplicationById,
  PreviousApplicationDetail
} from '../../store/previousApplicationDetail/actions';
import { PreviousApplicationByIdAction } from '../../store/previousApplicationDetail/reducer';
import { NotificationType, showNotification } from '../../store/ui/actions';

interface GetFetchPreviousApplicationByIdSagaProps {
  applicationsService: ApplicationsService;
}

export const getPreviousApplicationByIdSaga = ({
  applicationsService
}: GetFetchPreviousApplicationByIdSagaProps) =>
  function*(action: PreviousApplicationByIdAction) {
    if (isOfType(getType(fetchPreviousApplicationById.request), action)) {
      const { id } = action.payload;

      try {
        const response: PreviousApplicationDetail = yield call(
          applicationsService.getById,
          { id, isMoPbf: false }
        );
        if (!response.applicationExpiryDate) {
          try {
            response.applicationExpiryDate = yield call(
              applicationsService.submitApplicationExpiryId,
              id,
              response.revisions!
            );
          } catch (err) {
            yield put(fetchPreviousApplicationById.failure(err));
          }
        }
        yield put(fetchPreviousApplicationById.success(response));
      } catch (err) {
        yield put(fetchPreviousApplicationById.failure(err));
        yield put(
          showNotification({
            message: err.message,
            type: NotificationType.DANGER
          })
        );
      }
    }
  };

interface GetPreviousApplicationByIdSagaWatcher {
  applicationsService: ApplicationsService;
}

export const getPreviousApplicationByIdSagaWatcher = ({
  applicationsService
}: GetPreviousApplicationByIdSagaWatcher) =>
  function*() {
    yield takeEvery(
      getType(fetchPreviousApplicationById.request),
      getPreviousApplicationByIdSaga({ applicationsService })
    );
  };
