import { Box, Text } from 'compass-design';
import React from 'react';
import { ApplicantFinancialDetails } from 'hitachi-retail-core/build/schemas/applicantDetails';
import { employmentStatus as employmentStatusEnum } from 'hitachi-retail-core/build/enums';

const getPrettyEmploymentStatus = (status: string) =>
  employmentStatusEnum?.enumNames?.[employmentStatusEnum.enum.indexOf(status)];

const getPrettySpouseEmploymentText = (statusEnum: string) => {
  const status = getPrettyEmploymentStatus(statusEnum)?.toLowerCase();
  return `Spouse or partner is ${
    ['houseperson', 'student'].includes(status as string) ? 'a' : ''
  } ${status}`;
};

const ReadOnlyFinancialDetails: React.FC<ApplicantFinancialDetails> = ({
  employmentStatus,
  incomeValue,
  financialDependants,
  spouseEmploymentStatus
}) => {
  return (
    <Box>
      <Text
        sx={{ fontSize: 2 }}
        data-test-id='summary-review-page-financial-employment'>
        {getPrettyEmploymentStatus(employmentStatus)}
      </Text>
      {spouseEmploymentStatus && (
        <Text
          sx={{ fontSize: 2 }}
          data-test-id='summary-review-page-financial-spouse'>
          {getPrettySpouseEmploymentText(spouseEmploymentStatus)}
        </Text>
      )}
      <Text
        sx={{ fontSize: 2 }}
        data-test-id='summary-review-page-financial-income'>
        £{incomeValue} annual income
      </Text>
      <br />
      <Text
        sx={{ fontSize: 2 }}
        data-test-id='summary-review-page-financial-dependants'>
        {financialDependants === '5' ? '5 or more' : financialDependants}{' '}
        financial dependants
      </Text>
    </Box>
  );
};

export default ReadOnlyFinancialDetails;
