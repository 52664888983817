import { CancellationReason } from 'hitachi-retail-core/build/enums/applicationCancellation';
import {
  ApplicationDetails,
  IncompleteFinanceApplication
} from 'hitachi-retail-core/build/schemas/financeApplication';
import { ReferralNote } from 'hitachi-retail-core/build/schemas/referralNote';
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  CustomerSatisfactionNotes as CustomerSatisfactionNotesApi,
  CustomerSatisfactionItem as CustomerSatisfactionItemApi,
  CustomerSatisfactionItemType as CustomerSatisfactionItemTypeApi
} from 'hitachi-retail-core/build/api/customerSatisfaction';
import { UnsavedReferralNote } from '../../services/applicationsService';
import * as app from './constants';
import { DecisionRevision } from 'hitachi-retail-core';
import { AuditEventDetail } from 'hitachi-retail-core/build/eventEmitters/types';
import { RequestedDecision } from 'hitachi-retail-core/build/database/types';

// Omit attributes that will be transformed to string in the response
export type PreviousApplicationDetail = Omit<
  ApplicationDetails,
  | 'created'
  | 'updated'
  | 'createdBy'
  | 'document'
  | 'softSearchExpiryDate'
  | 'tailoringExpiryDate'
  | 'coolingOffEnd'
  | 'applicationExpiryDate'
  | 'maxInstalmentAmount'
  | 'revisions'
> & {
  created: string;
  createdBy?: string;
  updated: string;
  softSearchExpiryDate: string;
  tailoringExpiryDate: string;
  document: IncompleteFinanceApplication;
  coolingOffEnd?: string;
  applicationExpiryDate: string;
  revisions?: DecisionRevision[];
  maxInstalmentAmount?: string;
  latestDecision?: RequestedDecision;
};

interface FetchPreviousApplicationByIdPayload {
  id: string;
}

interface FetchActiveApplicationByIdPayload {
  id: string;
  redirect?: boolean;
}

interface MarkGoodsAsDeliveredPayload {
  id: string;
}

interface ResendEsatNotePayload {
  id: string;
}

interface CancelApplicationPayload {
  id: string;
  cancellationReason?: CancellationReason;
}

interface RequestPartialSettlementPayload {
  id: string;
  partialSettlementAmount: string;
}

interface FetchApplicationReferralNotesPayload {
  id: string;
}

interface FetchAuditHistoryPayload {
  id: string;
  branchId: string;
}

interface SubmitApplicationReferralNotePayload {
  id: string;
  note: UnsavedReferralNote;
}

export interface FetchCustomerFeedbackById {
  id: string;
}

interface RevertApplicationToVersionPayload {
  id: string;
  versionId: string;
}

export interface CustomerFeedback {
  feedback: string;
  feedbackSubmitted: string;
  satisfied: boolean;
}

export type CustomerSatisfactionNotes = CustomerSatisfactionNotesApi;
export type CustomerSatisfactionItem = CustomerSatisfactionItemApi;
export type CustomerSatisfactionItemType = CustomerSatisfactionItemTypeApi;

export const fetchPreviousApplicationById = createAsyncAction(
  app.FETCH_PREVIOUS_APPLICATION_BY_ID_REQUEST,
  app.FETCH_PREVIOUS_APPLICATION_BY_ID_SUCCESS,
  app.FETCH_PREVIOUS_APPLICATION_BY_ID_FAILURE
)<FetchPreviousApplicationByIdPayload, PreviousApplicationDetail, Error>();

export const markGoodsAsDelivered = createAsyncAction(
  app.MARK_GOODS_AS_DELIVERED_REQUEST,
  app.MARK_GOODS_AS_DELIVERED_SUCCESS,
  app.MARK_GOODS_AS_DELIVERED_FAILURE
)<MarkGoodsAsDeliveredPayload, void, Error>();

export const resendEsatNote = createAsyncAction(
  app.RESEND_ESAT_NOTE_REQUEST,
  app.RESEND_ESAT_NOTE_SUCCESS,
  app.RESEND_ESAT_NOTE_FAILURE
)<ResendEsatNotePayload, void, Error>();

export const cancelApplication = createAsyncAction(
  app.CANCEL_APPLICATION_REQUEST,
  app.CANCEL_APPLICATION_SUCCESS,
  app.CANCEL_APPLICATION_FAILURE
)<CancelApplicationPayload, void, Error>();

export const requestPartialSettlement = createAsyncAction(
  app.PARTIAL_SETTLEMENT_REQUEST,
  app.PARTIAL_SETTLEMENT_SUCCESS,
  app.PARTIAL_SETTLEMENT_FAILURE
)<RequestPartialSettlementPayload, void, Error>();

export const fetchActiveApplication = createAsyncAction(
  app.FETCH_ACTIVE_APPLICATION_BY_ID_REQUEST,
  app.FETCH_ACTIVE_APPLICATION_BY_ID_SUCCESS,
  app.FETCH_ACTIVE_APPLICATION_BY_ID_FAILURE
)<FetchActiveApplicationByIdPayload, PreviousApplicationDetail, Error>();

export const fetchApplicationReferralNotes = createAsyncAction(
  app.FETCH_APPLICATION_REFERRAL_NOTES_REQUEST,
  app.FETCH_APPLICATION_REFERRAL_NOTES_SUCCESS,
  app.FETCH_APPLICATION_REFERRAL_NOTES_FAILURE
)<FetchApplicationReferralNotesPayload, ReferralNote[], Error>();

export const fetchAuditHistory = createAsyncAction(
  app.FETCH_AUDIT_HISTORY_REQUEST,
  app.FETCH_AUDIT_HISTORY_SUCCESS,
  app.FETCH_AUDIT_HISTORY_FAILURE
)<FetchAuditHistoryPayload, AuditEventDetail[], Error>();

export const submitApplicationReferralNote = createAsyncAction(
  app.SUBMIT_APPLICATION_REFERRAL_NOTE_REQUEST,
  app.SUBMIT_APPLICATION_REFERRAL_NOTE_SUCCESS,
  app.SUBMIT_APPLICATION_REFERRAL_NOTE_FAILURE
)<SubmitApplicationReferralNotePayload, void, Error>();

export const fetchCustomerFeedback = createAsyncAction(
  app.FETCH_CUSTOMER_FEEDBACK_REQUEST,
  app.FETCH_CUSTOMER_FEEDBACK_SUCCESS,
  app.FETCH_CUSTOMER_FEEDBACK_FAILURE
)<FetchCustomerFeedbackById, CustomerSatisfactionNotes, Error>();

export const resetApplicationReferralNoteSubmissionStatus = createStandardAction(
  app.RESET_APPLICATION_REFERRAL_NOTE_SUBMISSION_STATUS
)();

export const revertApplicationToVersion = createAsyncAction(
  app.REVERT_APPLICATION_VERSION_REQUEST,
  app.REVERT_APPLICATION_VERSION_SUCCESS,
  app.REVERT_APPLICATION_VERSION_FAILURE
)<RevertApplicationToVersionPayload, void, Error>();
