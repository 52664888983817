import { applicantDetailsFormSchema } from 'form/applicantDetails';
import {
  deliveryAddressTypeValue,
  FormEnum
} from 'hitachi-retail-core/build/enums';

export const goodsDeliveredOnlyToCurrent: FormEnum = {
  enum: [deliveryAddressTypeValue.CurrentAddress],
  enumNames: ['Yes, to current address']
};

const getApplicantDetailsFormSchema = (customerPresent?: boolean) => {
  const overrides = customerPresent
    ? {}
    : {
        goodsDeliveredEnumOverride: goodsDeliveredOnlyToCurrent.enum,
        goodsDeliveredEnumNamesOverride: goodsDeliveredOnlyToCurrent.enumNames
      };
  return applicantDetailsFormSchema(overrides);
};

export default getApplicantDetailsFormSchema;
