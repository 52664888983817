import { ApplicantDetails as ApplicantDetailsInterface } from 'hitachi-retail-core';
import { createApplicantDetailsValidationContext } from 'hitachi-retail-core/build/schemas/applicantDetails';
import { IncompleteFinanceApplication } from 'hitachi-retail-core/build/schemas/financeApplication';
import { UiSchema } from 'react-jsonschema-form';
import {
  SchemaConfig,
  SectionErrors,
  ValidationRetailerParams
} from '../ApplicantDetails';

export interface CompletedSectionMap {
  [key: string]: boolean;
}

const createCompletedSectionMap = (
  formData: IncompleteFinanceApplication,
  schemaSections: string[],
  schemaConfig: SchemaConfig,
  sectionErrors: SectionErrors,
  validationRetailerParams: ValidationRetailerParams
): CompletedSectionMap =>
  schemaSections.reduce((sectionMap, sectionKey) => {
    let sectionComplete = false;
    if (sectionKey in formData) {
      const sectionFormData = formData[sectionKey];
      const sectionSchemaConfig = schemaConfig[sectionKey];
      const sectionSchemaValid = sectionSchemaConfig.isValidSync(
        sectionFormData,
        {
          strict: true,
          context: createApplicantDetailsValidationContext({
            applicantDetails: formData as ApplicantDetailsInterface,
            nonMandatoryEmailAddress:
              validationRetailerParams.nonMandatoryEmailEnabled
          })
        }
      );
      const sectionError = Boolean(sectionErrors[sectionKey]);
      sectionComplete = sectionSchemaValid && !sectionError;
    }

    sectionMap[sectionKey] = sectionComplete;
    return sectionMap;
  }, {});

interface EvaluateFormSectionsParams {
  formData: IncompleteFinanceApplication;
  schemaSections: string[];
  schemaConfig: SchemaConfig;
  sectionErrors?: SectionErrors;
  uiSchema: UiSchema;
  validationRetailerParams: ValidationRetailerParams;
}

const evaluateFormSections = ({
  formData,
  schemaSections,
  schemaConfig,
  sectionErrors = {},
  uiSchema,
  validationRetailerParams
}: EvaluateFormSectionsParams): {
  activeSectionKey: string | null;
  completedSections: CompletedSectionMap;
  allSectionsComplete: boolean;
} => {
  const completedSections = createCompletedSectionMap(
    formData,
    schemaSections,
    schemaConfig,
    sectionErrors,
    validationRetailerParams
  );

  const activeSectionKey =
    schemaSections.find(
      sectionKey =>
        !completedSections[sectionKey] && !uiSchema?.[sectionKey]?.['ui:hidden']
    ) || null;

  const allSectionsComplete = activeSectionKey === null;

  return {
    activeSectionKey,
    completedSections,
    allSectionsComplete
  };
};

export default evaluateFormSections;
