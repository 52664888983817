import React from 'react';
import { TitleFieldProps as OriginalTitleFieldProps } from 'react-jsonschema-form';

type TitleFieldProps = Partial<OriginalTitleFieldProps> &
  Pick<OriginalTitleFieldProps, 'title'>;

const TitleField: React.FunctionComponent<TitleFieldProps> = props => {
  const { id, title } = props;
  return <legend id={id}>{title}</legend>;
};

export default TitleField;
