import {
  Box,
  Button,
  Continue,
  Link,
  NovunaHeading,
  SectionError,
  TertiaryLink,
  TertiaryLinkDirection,
  Text
} from 'compass-design';
import { JourneyType } from 'hitachi-retail-core/build/enums';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import {
  selectApr,
  selectGoodsAmount,
  selectGoodsDescription,
  selectInstalments,
  selectLoanAmount,
  selectRepaymentAmount,
  selectTotalAmountPayable
} from 'hitachi-retail-core/build/selectors';
import { selectIsNonBrokered } from 'hitachi-retail-core/build/selectors/selectIsNonBrokered';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'store';
import { Link as ReactRouterLink } from 'react-router-dom';
import { ServicesContext } from 'shared/App/App';
import FeatureToggle from 'containers/meta/FeatureToggle';
import { generateApplicationPath, routes } from 'routes';
import { RootState } from 'store';
import {
  selectAgreementDuration,
  selectApplicationDocument,
  selectApplicationJourneyType,
  selectApplicationRegulated,
  selectApplicationReturnUrl,
  selectApplicationStatus,
  selectApplicationSupplierOrderReference,
  selectEarlySettlementFee,
  selectFetchApplicationStatus,
  selectIsDeferred
} from 'store/application/selectors';
import { selectEnabledFeatures } from 'store/config/selectors';
import {
  selectFormattedRetailerAddress,
  selectImmediatePayoutEnabled,
  selectRetailerName
} from 'store/retailer/selectors';
import { formatCurrency, formatPercent } from 'utils/formatters';
import {
  CancelApplicationTextButton,
  generateWsRedirectUrl
} from 'applicantApp/components/ExternalRedirectButtons/ExternalRedirectButtons';
import {
  getBasePageName,
  hitachiDialablePhoneNumber,
  hitachiPhoneNumber,
  pageNameSeparator
} from 'applicantApp/config';
import { isTransientState } from 'applicantApp/store/AsyncStatus';
import { ApplicantApplicationProps } from 'applicantApp/pages/ApplicantApplication/ApplicantApplication';
import { getPageName as getPreviousPageName } from 'applicantApp/utils/PCCI';
import NovunaPageLayout from 'applicantApp/components/NovunaPageLayout';
import {
  LoanInformationProps,
  PreContractCreditInformation,
  LoanAdvanceInformation as LoanAdvanceInformationAccordions
} from 'shared/components/LoanInformation';

export const HitachiPhoneNumber: React.FunctionComponent = () => (
  <Link href={`tel:${hitachiDialablePhoneNumber}`}>{hitachiPhoneNumber}</Link>
);

const pageName = 'Review loan information';

const getDraftDownloadHtml = (
  draftDownloadUrl: string | undefined,
  isDraftDownloadError: boolean
) => {
  let Comp;

  if (draftDownloadUrl) {
    Comp = (
      <Text>
        You may also review a{' '}
        <Link href={draftDownloadUrl} data-test-id={'download-draft-link'}>
          draft copy
        </Link>{' '}
        of the agreement first.
      </Text>
    );
  } else if (isDraftDownloadError) {
    Comp = <Text>A draft agreement is not available at this time.</Text>;
  } else
    Comp = (
      <Text>
        If you would like to review a draft copy of the agreement please wait
        while one is prepared for you…
      </Text>
    );

  return (
    <>
      <Text>The next step is to e-sign the credit agreement.</Text>
      {Comp}
    </>
  );
};

const LoanAdvanceInformation: React.FC<ApplicantApplicationProps> = ({
  completeLoanAdvanceInformation,
  startLoanAdvanceInformation,
  id
}) => {
  const services = React.useContext(ServicesContext);
  const documentDownloadService = services.documentDownloadService;

  const features = useSelector(selectEnabledFeatures);
  const featureDownloadDraft = features.has(
    CompassFeature.MAIL_ORDER_DOWNLOAD_DRAFT_AGREEMENT
  );

  const [precontractDownloadUrl, setPrecontractDownloadUrl] = useState<
    string | undefined
  >();
  const [isFetchDownloadError, setIsFetchDownloadError] = useState<boolean>(
    false
  );

  const [draftDownloadUrl, setDraftDownloadUrl] = useState<
    string | undefined
  >();
  const [isDraftDownloadError, setIsDraftDownloadError] = useState<boolean>(
    false
  );

  useEffect(() => {
    const fetchPrecontractDownloadUrl = async (id: string) => {
      try {
        const result = await documentDownloadService?.fetchPrecontractDownloadUrl(
          id
        );
        setPrecontractDownloadUrl(result?.url);
      } catch (err) {
        setIsFetchDownloadError(true);
      }
    };

    if (id) {
      startLoanAdvanceInformation();
      fetchPrecontractDownloadUrl(id);
    }
  }, [id, startLoanAdvanceInformation, documentDownloadService]);

  useEffect(() => {
    const fetchDraftDownloadUrl = async (id: string) => {
      try {
        const result = await documentDownloadService?.fetchDraftDownloadUrl(id);
        setDraftDownloadUrl(result?.url);
      } catch (err) {
        setIsDraftDownloadError(true);
      }
    };

    if (id && featureDownloadDraft) {
      fetchDraftDownloadUrl(id);
    }
  }, [id, documentDownloadService, featureDownloadDraft]);

  const draftDownload = getDraftDownloadHtml(
    draftDownloadUrl,
    isDraftDownloadError
  );

  const downloadReady = precontractDownloadUrl !== undefined;

  const isDeferred = useSelector(selectIsDeferred);
  const earlySettlementFee = useSelector(selectEarlySettlementFee);
  const immediatePayout = useSelector(selectImmediatePayoutEnabled);

  const agreementDuration = useSelector(selectAgreementDuration);
  const document = useSelector(selectApplicationDocument);
  const retailerName = useSelector(selectRetailerName);
  const isRegulated = useSelector(selectApplicationRegulated);
  const returnUrl = useSelector(selectApplicationReturnUrl);
  const supplierOrderReference = useSelector(
    selectApplicationSupplierOrderReference
  );
  const journeyType = useSelector(selectApplicationJourneyType);
  const formattedRetailerAddress = useSelector(selectFormattedRetailerAddress);

  const { decisionResult, processing: decisionLoading } = useSelector(
    (state: RootState) => state.decision
  );

  const applicationStatus = useSelector(selectApplicationStatus);

  const returnUrlStatus =
    decisionResult === ApplicationStatus.decision_refer
      ? 'referred'
      : 'declined';

  if (
    (decisionResult === ApplicationStatus.decision_decline ||
      decisionResult === ApplicationStatus.decision_refer) &&
    journeyType === JourneyType.WebServices &&
    returnUrl
  ) {
    window.location.replace(
      generateWsRedirectUrl(
        returnUrl,
        id,
        returnUrlStatus,
        supplierOrderReference
      ).toString()
    );
  }

  const { processing: signingLoading } = useSelector(
    (state: RootState) => state.sign
  );

  const apr = selectApr(document);
  const loanAmount = selectLoanAmount(document)?.toString() || '';
  const goodsAmount = selectGoodsAmount(document)?.toString() || '';
  const instalments = selectInstalments(document)!;
  const totalAmountPayable = selectTotalAmountPayable(document)!;
  const repaymentAmount = selectRepaymentAmount(document)!;
  const goodsDescription = selectGoodsDescription(document)!;
  const isNonBrokered = selectIsNonBrokered(document);

  const isLoading = signingLoading || decisionLoading;

  const linkProps = {
    to: generateApplicationPath(routes.mailOrder.application.keyLoanFeatures, {
      id
    })
  };

  const documentName = isRegulated
    ? 'Pre-Contract Credit Information'
    : 'Loan Advance Information';

  const previousPageName = getPreviousPageName({ isRegulated });
  const fetchApplicationStatus = useSelector(selectFetchApplicationStatus);

  if (isTransientState(fetchApplicationStatus)) {
    return null;
  }

  const loanInformationProps: LoanInformationProps = {
    intermediaryName: retailerName,
    intermediaryAddress: formattedRetailerAddress,
    goodsDescription,
    loanAmount: formatCurrency(loanAmount),
    repaymentAmount: formatCurrency(String(repaymentAmount)),
    agreementDuration: agreementDuration,
    instalments: instalments,
    goodsAmount: formatCurrency(goodsAmount),
    totalAmountPayable: formatCurrency(String(totalAmountPayable)),
    apr: formatPercent(apr) ?? '',
    earlySettlementFee,
    isDeferred,
    immediatePayout,
    isNonBrokered
  };

  return (
    <NovunaPageLayout
      pageTop={
        // Should this be on the journeyType.WebServices
        <Link
          {...(linkProps as any)}
          sx={{ textDecoration: 'none' }}
          as={ReactRouterLink}
          data-test-id='mo-loan-advance-information-return-link'>
          <TertiaryLink
            direction={TertiaryLinkDirection.BACKWARDS}
            text={previousPageName}
            onClick={() => {
              return;
            }}
          />
        </Link>
      }
      backToStoreStatus='abandoned'>
      <Helmet>
        <title>{[pageName, getBasePageName()].join(pageNameSeparator)}</title>
      </Helmet>
      <NovunaHeading
        as='h1'
        mb={2}
        data-test-id='mo-loan-advance-information-page-heading'>
        {pageName}
      </NovunaHeading>

      {isRegulated ? (
        <PreContractCreditInformation {...loanInformationProps} />
      ) : (
        <LoanAdvanceInformationAccordions {...loanInformationProps} />
      )}

      <Box mt={5}>
        <NovunaHeading as={'h3'} mb={2}>
          Your copy
        </NovunaHeading>
        <Text>
          You can download your {documentName} here. A copy has also been sent
          to you by email.
        </Text>
        {isFetchDownloadError && (
          <SectionError mt={2}>
            The download is not available, refresh the page to try again
          </SectionError>
        )}
        <Button
          mt={4}
          disabled={isFetchDownloadError || !downloadReady}
          as='a'
          {...{ href: precontractDownloadUrl }}
          variant='secondary'
          data-test-id='mo-loan-advance-information-download-button'>
          {downloadReady ? 'Download' : 'Preparing...'}
        </Button>
      </Box>

      <FeatureToggle
        feature={CompassFeature.MAIL_ORDER_DOWNLOAD_DRAFT_AGREEMENT}>
        <Box mt={6} data-test-id={'download-draft-section'}>
          {draftDownload}
        </Box>
      </FeatureToggle>

      <Button
        isJumbo
        onClick={() => {
          completeLoanAdvanceInformation(applicationStatus);
        }}
        data-test-id='mo-loan-advance-information-button'
        disabled={!!isLoading}
        mt={5}>
        {isLoading ? 'Loading...' : 'Continue'} <Continue />
      </Button>

      {returnUrl && (
        <Box mt={5}>
          <CancelApplicationTextButton
            baseUrl={returnUrl}
            applicationId={id}
            supplierOrderReference={supplierOrderReference}
          />
        </Box>
      )}
    </NovunaPageLayout>
  );
};

export default LoanAdvanceInformation;
