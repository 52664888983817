import React from 'react';
import { Box, SelectField, InlineCheckbox, Grid } from 'compass-design';
import UsersSearch from '../UsersSearch';
import { Status } from '../utils';
import { PaginateStateProps } from 'retailerApp/components/Paginate';

const userStatusList = [
  { id: 1, value: 'All users' },
  { id: 2, value: 'Active users' },
  { id: 3, value: 'Deactivated users' }
];

export interface UsersFiltersProps
  extends Omit<PaginateStateProps, 'currentPage'> {
  search: {
    searchTerm: string;
    setSearchTerm: (query: string) => void;
  };
  headOfficeFilter: {
    showFilter: boolean;
    showOnlyHeadOfficeUsers: boolean;
    setHeadOfficeFilter: (showHeadOfficeUsers: boolean) => void;
  };
  setSelectedUserStatus: (arg: any) => void;
}

const UsersFilters = ({
  search,
  headOfficeFilter,
  setSelectedUserStatus,
  selectPage
}: UsersFiltersProps) => (
  <Grid mb={5} columns={[1, headOfficeFilter.showFilter ? 3 : 2]} gap={0}>
    <Box>
      <UsersSearch
        query={search.searchTerm}
        onSearch={query => {
          selectPage(1);
          search.setSearchTerm(query);
        }}
        onClear={() => {
          selectPage(1);
          search.setSearchTerm('');
        }}
      />
    </Box>
    {headOfficeFilter.showFilter && (
      <Box
        mt={[2, null, 0]}
        ml={[0, 2]}
        mb={[2, null, 0]}
        sx={{ display: 'flex', alignItems: 'center' }}>
        <InlineCheckbox
          dataTestId='disable-modal'
          checked={headOfficeFilter.showOnlyHeadOfficeUsers}
          onChange={() => {
            headOfficeFilter.setHeadOfficeFilter(
              !headOfficeFilter.showOnlyHeadOfficeUsers
            );
            selectPage(1);
          }}>
          Head Office users only
        </InlineCheckbox>
      </Box>
    )}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end'
      }}>
      <SelectField
        id='user-status-selector'
        name='user-status-selector'
        data-test-id='user-status-selector'
        defaultValue={'All users'}
        onChange={event => {
          setSelectedUserStatus(event.target.value as Status);
          selectPage(1);
        }}
        labelSx={{ width: '200px' }}>
        {userStatusList.map(({ id, value }) => (
          <option value={value} key={id}>
            {value}
          </option>
        ))}
      </SelectField>
    </Box>
  </Grid>
);

export default UsersFilters;
