import { spawn } from 'redux-saga/effects';
import { DefaultSagaParams } from '../../sagas';
import {
  AddressByPostcodeSagaParams,
  getAddressByPostcodeSagaWatcher
} from '../../sagas/address/saga';
import { getSaveApplicationSagaWatcher } from '../../sagas/saveApplication/saga';
import { ApplicationsService } from '../../services/applicationsService';
import {
  AuthSagaParams,
  getApplicantAuthSagaWatcher
} from './applicantAuth/saga';
import { getApplicantLogoutWatcher } from './applicantLogout/saga';
import {
  getMailOrderConfigSagaWatcher,
  MailOrderConfigSagaParams
} from './config/saga';
import { GetDecisionSagaParams, getDecisionSagaWatcher } from './decision/saga';
import {
  getFetchMailOrderApplicationSagaWatcher,
  MailOrderApplicationSagaParams
} from './fetchMailOrderApplication/saga';
import {
  getSendPrecontractInfoSaga,
  getSendPrecontractInfoWatcher,
  SendPrecontractInfoSagaParams
} from './sendPrecontractInfo/saga';
import { getSignApplicationSagaWatcher } from './signApplication/saga';

export type MailOrderSagaParams = DefaultSagaParams &
  MailOrderConfigSagaParams &
  MailOrderApplicationSagaParams &
  SendPrecontractInfoSagaParams &
  AuthSagaParams &
  GetDecisionSagaParams &
  AddressByPostcodeSagaParams & {
    applicationsService: ApplicationsService;
  };

export const getApplicantRootSaga = ({
  applicationsService,
  applicantConfigService,
  mailOrderApplicationService,
  sendPrecontractInfoService,
  authService,
  addressLookupService,
  decisionService
}: MailOrderSagaParams) =>
  function*() {
    // Config
    yield spawn(getMailOrderConfigSagaWatcher({ applicantConfigService }));

    // Auth
    yield spawn(getApplicantAuthSagaWatcher({ authService }));

    // Application
    yield spawn(
      getFetchMailOrderApplicationSagaWatcher({
        mailOrderApplicationService
      })
    );

    // Save Application
    yield spawn(
      getSaveApplicationSagaWatcher({
        applicationsService: mailOrderApplicationService
      })
    );

    // Signing
    yield spawn(getSignApplicationSagaWatcher({ applicationsService }));

    // Decision
    yield spawn(
      getDecisionSagaWatcher({
        decisionService,
        mailOrderApplicationService
      })
    );

    // Timeout
    yield spawn(getApplicantLogoutWatcher());

    // PAF Lookup
    yield spawn(
      getAddressByPostcodeSagaWatcher({
        addressLookupService
      })
    );

    // Send Precontract Info
    // This does not need spawning as it is managed by the watcher
    const sendPrecontractInfoSaga = getSendPrecontractInfoSaga({
      sendPrecontractInfoService
    });
    yield spawn(getSendPrecontractInfoWatcher({ sendPrecontractInfoSaga }));
  };
