import { Box, Message, NovunaHeading, Text } from 'compass-design';
import React from 'react';

const SummaryReferralCard = () => (
  <Box mt={6} data-test-id='application-summary-referral-card'>
    <Message variant='info' restrictedWidth={false}>
      <NovunaHeading as='h3' mb={1}>
        Referred
      </NovunaHeading>
      <Text>
        This application has been referred to the Underwriting Team for further
        consideration.
      </Text>
      <Text>
        Please wait for an Underwriter to review the application and make a
        decision, they may request further information via an Application Note.
      </Text>
      <Text>
        If there is any information you would like to provide to the
        Underwriting Team in advance of their review you can add a note to this
        application summary.
      </Text>
    </Message>
  </Box>
);

export default SummaryReferralCard;
