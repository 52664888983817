import { Decimal } from 'hitachi-retail-core/build/utils/decimal';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import { getTailoringDetails } from 'hitachi-retail-core/build/services/tailoring/getTailoringDetails';
import {
  FeatureConfig,
  CompassFeature
} from 'hitachi-retail-core/build/features/features';
import { SaveRequest } from 'services/applicationsService';
import { DecisionService } from 'services/decision';
import { softSearchStatuses } from '../sagas/decision/saga';
import getMockApplicationsService from './getMockApplicationsService';
import getMockRetailerService, {
  retailerFlags
} from './getMockRetailerService';
import { savedApplication } from './shared';
import { DateTime } from 'luxon';
import { Logger } from 'hitachi-retail-core/build/utils/logger';
import {
  mockedMailOrderApplication,
  mockedMailOrderApplicationId,
  MockedMailOrderSoftSearchApplicantionIds
} from './getMockMailOrderApplicationService';
import { PreviousApplicationDetail } from 'store/previousApplicationDetail/actions';

const MOSoftSearchApplicantionIdToDecisionMap = new Map<
  string,
  ApplicationStatus
>([
  [
    MockedMailOrderSoftSearchApplicantionIds.ACCEPT,
    ApplicationStatus.decision_quotation_accept
  ],
  [
    MockedMailOrderSoftSearchApplicantionIds.DECLINE,
    ApplicationStatus.decision_quotation_decline
  ],
  [
    MockedMailOrderSoftSearchApplicantionIds.REFER,
    ApplicationStatus.decision_quotation_refer
  ]
]);

const getEnabledFeatures = (): FeatureConfig => {
  const features = window.sessionStorage.getItem('features') || '[]';

  return new Set<CompassFeature>(JSON.parse(features));
};

const logger = {
  trace: (trace: any) => {
    console.log('logger trace: ', trace);
  },
  debug: (debug: any) => {
    console.log('logger debug: ', debug);
  },
  info: (info: string) => {
    console.log(`logger info: ${info}`);
  },
  warn: (warn: string) => {
    console.log(`logger warn: ${warn}`);
  },
  error: (error: string) => {
    console.log(`logger error: ${error}`);
  },
  fatal: (error: string) => {
    console.log(`logger fatal: ${error}`);
  },
  child: () => logger
} as Logger;

const getMockDecisionService = (): DecisionService => ({
  getDecision: async (...args) => {
    console.log('Mock invoked: getDecision with args', args);
    const applicationDetails = window.localStorage.getItem(savedApplication);
    const retailerDetails = await getMockRetailerService().getRetailer();
    const existingApplicationDetails = await getMockApplicationsService().getById(
      { id: 'newApplicationId' }
    );

    let acceptDecision = ApplicationStatus.decision_accept;
    let declineDecision = ApplicationStatus.decision_decline;
    let referDecision = ApplicationStatus.decision_refer;
    let details;

    try {
      details = JSON.parse(applicationDetails as string) as SaveRequest;
    } catch (e) {
      // It will fail if the application is not saved
    }

    const appId = details?.id;
    if (!appId) {
      throw Error('No application id is found.');
    }

    // Handling the Mail Order Journey WITHOUT Soft Search
    if (appId === mockedMailOrderApplicationId) {
      // if it is a PBF application update the decision
      mockedMailOrderApplication.status = ApplicationStatus.decision_accept;
      window.localStorage.setItem(
        savedApplication,
        JSON.stringify(mockedMailOrderApplication)
      );

      return {
        decisionType: ApplicationStatus.decision_accept,
        maxInstalmentAmount: null,
        softSearchExpiryDate: null
      };
    }

    // Handling the Mail Order Journey WITH Soft Search
    // The business cases are different enough to warrant a different "if" statement instead of ternaries.
    const mailOrderSoftSearchAppIds: string[] = Object.values(
      MockedMailOrderSoftSearchApplicantionIds
    );
    if (mailOrderSoftSearchAppIds.includes(appId)) {
      const softSearchExpiryDate = DateTime.local()
        .plus({ days: 30 })
        .toISODate();

      const applicationStatus = MOSoftSearchApplicantionIdToDecisionMap.get(
        appId
      );
      if (!applicationStatus) {
        throw Error(`Unknown application id is provided ${appId}`);
      }

      mockedMailOrderApplication.status = applicationStatus;
      mockedMailOrderApplication.softSearchExpiryDate = softSearchExpiryDate;

      window.localStorage.setItem(
        savedApplication,
        JSON.stringify(mockedMailOrderApplication)
      );

      return {
        decisionType: applicationStatus,
        maxInstalmentAmount: null,
        softSearchExpiryDate: softSearchExpiryDate
      };
    }

    // We are working with "resume application" for application that already has Soft Search status
    if (
      retailerDetails.softSearchEnabled === true &&
      details &&
      details?.id === existingApplicationDetails.id &&
      softSearchStatuses.indexOf(existingApplicationDetails.status) !== -1
    ) {
      acceptDecision = ApplicationStatus.decision_accept;
      declineDecision = ApplicationStatus.decision_decline;
      referDecision = ApplicationStatus.decision_refer;
    } else if (retailerDetails.softSearchEnabled === true) {
      // Manipulate the response based on the number of calls to the decision endpoint within ui.
      const previousCalls = window.localStorage.getItem('getDecisionCalls');
      let callCount = 1;
      if (previousCalls !== null && +previousCalls < 2) {
        callCount = +previousCalls + 1;
      }

      console.log('Soft Search Decision', {
        callCount
      });

      window.localStorage.setItem(
        'getDecisionCalls',
        (callCount as any) as string
      );

      if (callCount === 1) {
        acceptDecision = ApplicationStatus.decision_quotation_accept;
        declineDecision = ApplicationStatus.decision_quotation_decline;
        referDecision = ApplicationStatus.decision_quotation_refer;
      } else {
        acceptDecision = ApplicationStatus.decision_accept;
        declineDecision = ApplicationStatus.decision_decline;
        referDecision = ApplicationStatus.decision_refer;
      }
    }

    let lastName;
    let maxInstalmentAmount = '0';
    let instalmentAmount: Decimal;
    let tailoringOption;
    if (applicationDetails) {
      const details: PreviousApplicationDetail = JSON.parse(applicationDetails);

      lastName = details?.document?.personalDetails?.lastName;
      maxInstalmentAmount =
        details?.document?.financialDetails?.incomeValue?.toString() || '0';

      const numberOfInstalments =
        details?.document?.loanRepayment?.instalments || '0';
      const deposit = details?.document?.loanParameters?.deposit || '0';
      const goodsAmount = details?.document?.loanParameters?.goodsAmount || '0';
      const loanAmount = new Decimal(goodsAmount).minus(deposit);
      instalmentAmount = loanAmount.dividedBy(numberOfInstalments);

      tailoringOption = details?.document?.loanTailoring?.optionSelected;

      if (!instalmentAmount) {
        throw new Error(
          `Could not calculate instalment amount: ${numberOfInstalments}, ${loanAmount}, ${deposit}, ${goodsAmount}`
        );
      }
    }

    console.log(
      `Get Mocked Decision: Last name ${lastName}, income: ${maxInstalmentAmount}, instalment amount: ${instalmentAmount!.toString()}`
    );

    const softSearchExpiryDate = DateTime.local()
      .plus({ days: 30 })
      .toISODate();

    const tailoringExpiryDate = DateTime.local()
      .plus({ days: 30 })
      .toISODate();

    let decision;
    switch (lastName) {
      case 'TTTPD':
        decision = {
          decisionType: declineDecision,
          maxInstalmentAmount: null,
          softSearchExpiryDate,
          tailoringExpiryDate: null
        };
        break;
      case 'TTTPAC':
        decision = {
          decisionType: instalmentAmount!.lessThanOrEqualTo(maxInstalmentAmount)
            ? acceptDecision
            : declineDecision,
          maxInstalmentAmount,
          softSearchExpiryDate,
          tailoringExpiryDate,
          loanTailoring: {
            ...getTailoringDetails({
              applicationStatus: instalmentAmount!.lessThanOrEqualTo(
                maxInstalmentAmount
              )
                ? acceptDecision
                : declineDecision,
              document: details ? details.document : {},
              log: logger,
              maxInstalmentAmount,
              retailerFlags: {
                increaseDepositEnabled:
                  retailerFlags.tailoringIncreaseDepositEnabled,
                increaseLoanEnabled: retailerFlags.tailoringIncreaseLoanEnabled,
                increaseTermEnabled: retailerFlags.tailoringIncreaseTermEnabled,
                reduceLoanEnabled: retailerFlags.tailoringReduceLoanEnabled,
                reduceTermEnabled: retailerFlags.tailoringReduceTermEnabled,
                ibcReduceLoanEnabled:
                  retailerFlags.tailoringIbcReduceLoanEnabled,
                ibcIncreaseDepositEnabled:
                  retailerFlags.tailoringIbcIncreaseDepositEnabled,
                ibcIncreaseLoanEnabled:
                  retailerFlags.tailoringIbcIncreaseLoanEnabled,
                ibcReduceTermEnabled:
                  retailerFlags.tailoringIbcReduceTermEnabled
              },
              enabledFeatures: getEnabledFeatures()
            }),
            optionSelected: tailoringOption
          }
        };
        break;

      case 'TTTPR':
        decision = {
          decisionType: referDecision,
          maxInstalmentAmount: null,
          softSearchExpiryDate,
          tailoringExpiryDate: null
        };
        break;
      case 'TTTPRT':
        decision = {
          decisionType: ApplicationStatus.decision_refer,
          maxInstalmentAmount: null,
          softSearchExpiryDate,
          tailoringExpiryDate: null
        };
        break;
      default:
        decision = {
          decisionType: acceptDecision,
          maxInstalmentAmount,
          softSearchExpiryDate,
          tailoringExpiryDate
        };
        break;
    }
    console.log({ decision });
    return decision;
  }
});

export default getMockDecisionService;
