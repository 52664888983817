/**
 * TODO (Phase 3):
 * After Phase 3 roll-out - remove runtime and test code regarding enabledfeatures and retailerData that has been added on this PR https://bitbucket.org/novuna/compass-ui/pull-requests/1224.
 * Example:
 * 'selectEnabledFeautures' & 'enabledFeatures'
 * 'selectRetailer' & retailerData
 * 'isPhaseThreeEnabledGlobally'
 * All calls to .select() and .next() in saga tests that involve retailer data and enabled features
 */

import { push } from 'connected-react-router';
import { call, put, select, takeLeading } from 'redux-saga/effects';
import { getType, isOfType } from 'typesafe-actions';
import { routes } from '../../routes';
import { ApplicationsService } from '../../services/applicationsService';
import {
  DecisionRequest,
  DecisionService,
  GetDecision
} from '../../services/decision';
import { fetchDecision } from '../../store/decision/actions';
import { Action } from '../../store/decision/reducer';
import { selectApplicationId } from '../../store/save/reducer';
import { getSaveApplicationSaga } from '../saveApplication/saga';
import { selectActiveApplication } from '../../store/application/selectors';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import { ApplicationDetail } from 'components/form/types';
import { LoanTailoring } from 'hitachi-retail-core/build/services/tailoring/types';
import { selectEnabledFeatures } from 'store/config/selectors';
import {
  CompassFeature,
  FeatureConfig
} from 'hitachi-retail-core/build/features/features';
import { selectRetailer } from 'store/retailer/selectors';
import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';
import { IsPhaseThreeEnabledForRetailer } from 'sagas/retailer/saga';
import { fetchActiveApplication } from 'store/previousApplicationDetail/actions';

interface GetDecisionSagaParams {
  decisionService: DecisionService;
  saveApplicationSaga: () => void;
}

interface GetDecisionWatcherSagaParams {
  decisionService: DecisionService;
  applicationsService: ApplicationsService;
}

export const softSearchStatuses = [
  ApplicationStatus.decision_quotation_accept,
  ApplicationStatus.decision_quotation_decline,
  ApplicationStatus.decision_quotation_refer
];

export const getDecisionSaga = ({
  decisionService,
  saveApplicationSaga
}: GetDecisionSagaParams) =>
  function*(action: Action) {
    try {
      yield call(saveApplicationSaga);
    } catch (err) {
      //
    }

    let tailoringOptionSelected;
    let isLoanAmend;
    if (isOfType(getType(fetchDecision.request), action) && action.payload) {
      const { softSearch } = action.payload;
      tailoringOptionSelected = action.payload.tailoringOptionSelected;
      isLoanAmend = action.payload.isLoanAmend;

      const activeApplication: ApplicationDetail = yield select(
        selectActiveApplication
      );

      // Application with already done Soft Search
      if (
        softSearch &&
        softSearchStatuses.indexOf(activeApplication.status) !== -1
      ) {
        yield put(
          fetchDecision.success({
            decisionResponse: activeApplication.status,
            maxInstalmentAmount: String(
              activeApplication?.document?.loanTailoring?.maxInstalmentAmount
            ),
            softSearchExpiryDate: activeApplication?.softSearchExpiryDate,
            loanTailoring: activeApplication?.document
              .loanTailoring as LoanTailoring
          })
        );
        yield put(push(routes.apply.softSearch));
        return;
        // Application Soft Search request
      } else if (softSearch) {
        yield put(push(routes.apply.softSearch));
        // Application Decision request
      } else {
        yield put(push(routes.apply.decision));
      }
    }

    const applicationId: string = yield select(selectApplicationId);
    const featureFlags: FeatureConfig = yield select(selectEnabledFeatures);
    const retailer: Retailer & IsPhaseThreeEnabledForRetailer = yield select(
      selectRetailer
    );

    const isPhaseThreeEnabledGlobally = featureFlags.has(
      CompassFeature.LOAN_QUEUING_PHASE_THREE
    );
    const { isPhaseThreeEnabledForRetailer } = retailer;

    const isPhaseThreeEnabled =
      (isPhaseThreeEnabledGlobally && isPhaseThreeEnabledForRetailer) || false;

    const payload: DecisionRequest = {
      id: applicationId,
      tailoringOptionSelected,
      isLoanAmend
    };

    try {
      const response: GetDecision = yield call(
        decisionService.getDecision,
        payload,
        isPhaseThreeEnabled
      );

      if (response) {
        yield put(
          fetchDecision.success({
            decisionResponse: response.decisionType,
            maxInstalmentAmount: response.maxInstalmentAmount,
            softSearchExpiryDate: response.softSearchExpiryDate,
            tailoringExpiryDate: response.tailoringExpiryDate,
            loanTailoring: response.loanTailoring,
            productConfig: response.productConfig
          })
        );

        // FX-950 - Rehydrate active application state from fetching a decision
        if (isLoanAmend && response?.applicationRehydrate) {
          yield put(
            fetchActiveApplication.success(response.applicationRehydrate)
          );
        }
      } else {
        yield put(
          fetchDecision.failure(
            new Error('Decision API response did not contain expected values')
          )
        );
      }
    } catch (err) {
      yield put(fetchDecision.failure(err));
    }
  };

export const getDecisionSagaWatcher = ({
  decisionService,
  applicationsService
}: GetDecisionWatcherSagaParams) =>
  function*() {
    const saveApplicationSaga = getSaveApplicationSaga({ applicationsService });

    yield takeLeading(
      getType(fetchDecision.request),
      getDecisionSaga({ decisionService, saveApplicationSaga })
    );
  };
