import { Decimal } from 'hitachi-retail-core/build/utils/decimal';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import { ApplicationsService } from 'services/applicationsService';
import {
  FetchPreviousApplicationsResponse,
  FetchSearchPreviousApplicationsResponse
} from 'store/previousApplications/actions';
import {
  acceptApplication,
  declineApplication,
  defaultApplication,
  payoutApplication
} from './data/applications';
import { savedApplication } from './shared';
import { formatStatus } from 'hitachi-retail-core/build/utils/formatStatus';

const applicationStatus = ApplicationStatus.open; // decision_quotation_decline;
const applicantLastName = 'TTTPAC';

const totalCost = '5000';
const deposit = '100';

const returnedApplication = {
  applicationStatus: ApplicationStatus.open,
  lastName: 'TTTPAC',
  totalCost,
  loanAmount: new Decimal(totalCost).minus(deposit).toString(),
  deposit,
  apr: '0',
  repaidOver: 9,
  maxInstalment: 350
};

const generateApplications = (count = 50) => {
  const generateId = () =>
    new Array(8)
      .join()
      .replace(/(.|$)/g, () =>
        // eslint-disable-next-line no-bitwise
        ((Math.random() * 36) | 0).toString(36).toUpperCase()
      )
      .toString();
  const select = (...opts: any[]) =>
    opts[Math.floor(Math.random() * opts.length)];
  const apps = Array(count).fill({});
  return apps.map((_, idx) => ({
    id: generateId(),
    branchId: select('12345678', '99991234', '99999996', '87654321'),
    applicantName: [
      select('Mr', 'Mrs', 'Miss', 'Ms'),
      select('Test', 'Reece', 'Callum', 'Steve', 'Stuart'),
      select(applicantLastName, returnedApplication.lastName)
    ].join(' '),
    dateOfBirth: select('1960-01-01', '1970-01-01', '1980-01-01', '1990-01-01'),
    postcode: 'LS11 9BH',
    status: select(
      applicationStatus,
      ApplicationStatus.open,
      ApplicationStatus.signed,
      ApplicationStatus.cancelled,
      ApplicationStatus.payout_pending,
      ApplicationStatus.pending_esat_note
    ),
    loanAmount: select('1500.00', '1000.00', '500.00'),
    created: [
      select(
        '2024-01-01',
        '2023-01-01',
        '2022-01-01',
        '2021-01-01',
        '2020-01-01'
      ),
      'T',
      select('12:00:00', '15:00:00', '18:00:00', '21:00:00'),
      '.000Z'
    ].join(''),
    orderReference: select(['ORDER-', idx].join(''), undefined),
    ...select(returnedApplication, {}, {}, {}, {}, {}, {}, {}, {}) // reduced chance of hitting override
  }));
};

const applications = generateApplications(5000);

const getMockApplicationsService = (): Pick<
  ApplicationsService,
  | 'signApplication'
  | 'saveApplication'
  | 'getAll'
  | 'getById'
  | 'searchApplications'
> => {
  return {
    signApplication: async (...args) => {
      console.log(
        'Mock invoked: ApplicationService.signApplication with args',
        ...args
      );

      return {
        redirectUrl: 'https://signing_redirect_url.test'
      };
    },
    saveApplication: async payload => {
      console.log('Mock invoked: saveApplication with payload:', payload);
      window.localStorage.setItem(savedApplication, JSON.stringify(payload));
      return 'id';
    },
    getAll: async () => {
      // Reset the decision call count when potentially resuming another application
      window.localStorage.setItem('getDecisionCalls', '0');

      console.log('Mock invoked: getAll');
      return {
        applications: [...applications].slice(0, 100)
      } as FetchPreviousApplicationsResponse;
    },
    searchApplications: async ({ term, limit, offset }) => {
      const partialMatch = (query: string, subject = '') => {
        const normalize = (value: string) =>
          (value || '')
            .toString()
            .trim()
            .split(' ')
            .join('')
            .toUpperCase();
        return normalize(subject).includes(normalize(query));
      };

      const results = applications.reduce<any>((result, application) => {
        if (
          partialMatch(term, application.id) ||
          partialMatch(term, application.branchId) ||
          partialMatch(term, formatStatus(application.status)) ||
          partialMatch(term, application.applicantName ?? '') ||
          partialMatch(term, application.postcode ?? '') ||
          partialMatch(term, application.orderReference ?? '')
        ) {
          result.push(application);
        }
        return result;
      }, []);

      console.log('Mock invoked: searchApplications', term);
      return {
        total_count: results.length,
        applications: [...results].slice(offset, offset + limit)
      } as FetchSearchPreviousApplicationsResponse;
    },
    getById: async ({ id }) => {
      console.log('Mock invoked: getById', id);

      if (id === 'newApplicationId') {
        const application = JSON.parse(
          window.localStorage.getItem(savedApplication) as string
        );
        if (application) return application;
      }

      if (id === 'ACCEPT123') {
        return acceptApplication;
      }

      if (id === 'DECLINE12') {
        return declineApplication;
      }

      if (id === 'PAYOUT123') {
        return payoutApplication;
      }

      return defaultApplication;
    }
  };
};

export default getMockApplicationsService;
