import { Decimal } from 'hitachi-retail-core/build/utils/decimal';
import { Address } from 'hitachi-retail-core';
import { parseDecimal } from 'hitachi-retail-core/build/utils/decimal';
import { format, parseIso } from 'ts-date/locale/en';
import { getAddressLines } from './address';

export const defaultDateFormat = 'D MMM YYYY';
export const dateTimeFormat = defaultDateFormat + ', h:mm a';

export const padString = (
  value: string | number | undefined,
  maxLength: number,
  fillString: string
): string | undefined => {
  if (typeof value !== 'undefined') {
    return String(value).padStart(maxLength, fillString);
  }
  return undefined;
};

export const pluraliseString = (word: string, count: number, suffix = 's') =>
  `${word}${count === 1 ? '' : suffix}`;

export const joinValues = (values: string[], separator = ' '): string =>
  values.filter(part => part).join(separator);

export const formatAddressSingleLine = (
  address: Address,
  separator = ', '
): string => {
  return joinValues(getAddressLines(address), separator);
};

export const formatCurrency = (value: string | Decimal, whole = false) => {
  // Don't parse the value if it's already a decimal
  const number = typeof value === 'string' ? parseDecimal(value) : value;

  if (!number) {
    return '';
  }

  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: whole ? 0 : 2
  }).format(number.toNumber());
};

interface FormatDate {
  value: string;
  showTime?: boolean;
  dateFormatOverride?: string;
}

export const formatDate = ({
  value,
  showTime,
  dateFormatOverride
}: FormatDate): string => {
  const d = parseIso(value);

  if (d === null) {
    return '';
  }

  const dateFormat = dateFormatOverride ?? defaultDateFormat;

  return format(d, showTime ? dateTimeFormat : dateFormat);
};

export const formatNumber = (value: string): string => {
  const numberFormatter = Intl.NumberFormat('en-GB');

  try {
    return numberFormatter.format(parseInt(value, 10));
  } catch {
    return value;
  }
};

export const formatPercent = (value?: Decimal.Value): string | undefined => {
  const parsed = parseDecimal(value);
  if (parsed) {
    return parsed.toDecimalPlaces(2).toString() + '%';
  }
  return;
};

export const formatDecimalPercent = (
  value?: Decimal.Value
): string | undefined => {
  const parsed = parseDecimal(value);
  if (parsed) {
    return (
      parsed
        .toDecimalPlaces(2)
        .times(100)
        .toString() + '%'
    );
  }
  return;
};

export const formatBankSortCode = (value: string): string => {
  const parts = value.match(/^(\d{2})(\d{2})(\d{2})$/);
  if (parts) {
    return parts.slice(1).join('-');
  }

  return value;
};

export const formatKebabCase = (value: string): string =>
  value
    .toLowerCase()
    .split(' ')
    .join('-');
