import {
  Box,
  Button,
  InputWrapper,
  Message,
  NovunaHeading,
  NovunaHome,
  NovunaSpinner,
  PurpleMail,
  TextField
} from 'compass-design';
import { push } from 'connected-react-router';
import { Field, FieldProps, Form, Formik } from 'formik';
import { ApplicationModificationStatus } from 'hitachi-retail-core/build/application/modification';
import { deliveryAddressTypeValue } from 'hitachi-retail-core/build/enums';
import {
  contactDetailsSchema,
  personalDetailsSchema
} from 'hitachi-retail-core/build/schemas/applicantDetails';
import { IncompleteFinanceApplication } from 'hitachi-retail-core/build/schemas/financeApplication';
import { selectEmailAddress } from 'hitachi-retail-core/build/selectors';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { createNewApplication } from 'store/application/actions';
import { selectDirectAppLoggedIn } from 'store/user/selectors';
import { labelPropsFromMeta } from 'utils/labelPropsFromMeta';
import * as yup from 'yup';
import { routes } from '../../routes';
import { showModal } from '../../store/ui/actions';

export interface EndAndSendPropsFromDispatch {
  sendApplication: () => void;
  updateApplication: (
    formData: EndAndSendValues,
    document: IncompleteFinanceApplication,
    modificationStatus: ApplicationModificationStatus
  ) => void;
}

export interface EndAndSendOwnProps {
  onClose: () => void;
}

export interface EndAndSendValues {
  emailAddress: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
}

export type EndAndSendPropsFromState = EndAndSendOwnProps & {
  errorMessage?: string;
  document: IncompleteFinanceApplication;
  applicationId?: string;
  mailorderEnabled: boolean;
  currentState: SendToCustomerState;
  modificationStatus: ApplicationModificationStatus;
  pathname: string;
};

export enum SendToCustomerState {
  EnterDetails = 'ENTER_DETAILS',
  DetailsCaptured = 'DETAILS_CAPTURED',
  Loading = 'LOADING',
  Success = 'SUCCESS',
  Error = 'ERROR'
}

export type EndAndSendProps = EndAndSendPropsFromDispatch &
  EndAndSendPropsFromState;

export const EndAndSendLoading: React.FC = () => (
  <div data-test-id='compass-application-send-to-customer-loading'>
    <NovunaHeading as='h2' mb={2}>
      Sending&hellip;
    </NovunaHeading>
    <NovunaSpinner />
  </div>
);

export const EndAndSendSuccess: React.FC<EndAndSendProps> = ({
  applicationId,
  document
}) => {
  const emailAddress = selectEmailAddress(document);

  return (
    <Message
      variant='success'
      icon={<PurpleMail />}
      data-test-id='compass-application-send-to-customer-success'>
      <NovunaHeading as='h3' mb={1}>
        It&rsquo;s on the way
      </NovunaHeading>
      We have sent the application {applicationId} to
      <br />
      {emailAddress}
    </Message>
  );
};

export const EndAndSendError: React.FC<EndAndSendProps> = ({
  applicationId
}) => (
  <Message
    variant='error'
    mb={5}
    data-test-id='compass-application-send-to-customer-error'>
    <NovunaHeading as='h3' mb={1}>
      Error
    </NovunaHeading>
    We were unable to send the application <b>{applicationId}</b> - please try
    again later.
  </Message>
);

export const EndAndSendContent: React.FC<EndAndSendProps> = props => {
  const { currentState } = props;
  const dispatch = useDispatch();
  const directAppLoggedIn = useSelector(selectDirectAppLoggedIn);

  useEffect(() => {
    if (currentState === SendToCustomerState.Success) {
      // Open the success modal as separate modal to allow custom 'close' action
      dispatch(
        showModal({
          body: <EndAndSendSuccess {...props} />,
          onClose: () => {
            props.onClose();
            if (directAppLoggedIn) {
              dispatch(createNewApplication());
            } else {
              dispatch(push(routes.search.index));
            }
          }
        })
      );
    }
  });

  switch (currentState as SendToCustomerState) {
    case SendToCustomerState.Loading:
      return <EndAndSendLoading />;

    case SendToCustomerState.Success:
      // Moved to separate modal
      return null;

    case SendToCustomerState.EnterDetails:
    case SendToCustomerState.DetailsCaptured:
      return <ApplicantContactDetailsForm {...props} />;

    case SendToCustomerState.Error:
      return <EndAndSendError {...props} />;

    default:
      return null;
  }
};

export const ApplicantContactDetailsForm: React.FC<EndAndSendProps> = ({
  document,
  sendApplication,
  updateApplication,
  onClose,
  modificationStatus,
  pathname
}) => {
  const { contactDetails, personalDetails, deliveryDetails } = document || {};
  const initialValues: EndAndSendValues = {
    emailAddress: contactDetails?.emailAddress ?? '',
    phoneNumber: contactDetails?.phoneNumber ?? '',
    firstName: personalDetails?.firstName ?? '',
    lastName: personalDetails?.lastName ?? ''
  };

  const fieldsDisabled =
    modificationStatus !== ApplicationModificationStatus.YES;

  const showOverrideWarning =
    modificationStatus === ApplicationModificationStatus.YES &&
    pathname !== routes.apply.decision &&
    deliveryDetails?.goodsDelivered !== deliveryAddressTypeValue.CurrentAddress;

  return (
    <>
      <NovunaHeading as='h2' mb={2}>
        Send to customer
      </NovunaHeading>
      <Formik
        initialValues={initialValues}
        validationSchema={getValidationSchema()}
        onSubmit={values => {
          updateApplication(values, document, modificationStatus);
          sendApplication();
        }}
        enableReinitialize>
        <Form noValidate>
          <Field name='emailAddress'>
            {({ field, meta }: FieldProps<string, EndAndSendValues>) => (
              <Box mb={4}>
                <InputWrapper
                  id='email-address'
                  label='Email address'
                  error={meta.error}
                  stacked
                  {...labelPropsFromMeta({ meta }, 'label')}>
                  <TextField
                    {...field}
                    {...labelPropsFromMeta({ meta }, 'input')}
                    id='email-address'
                    type='email'
                    disabled={fieldsDisabled}
                    autoCapitalize='none'
                    autoComplete='email'
                    autoFocus
                  />
                </InputWrapper>
              </Box>
            )}
          </Field>
          <Field name='phoneNumber'>
            {({ field, meta }: FieldProps<string, EndAndSendValues>) => (
              <Box mb={4}>
                <InputWrapper
                  id='phone-number'
                  label='Phone number'
                  error={meta.error}
                  stacked
                  {...labelPropsFromMeta({ meta }, 'label')}>
                  <TextField
                    {...field}
                    {...labelPropsFromMeta({ meta }, 'input')}
                    id='phone-number'
                    type='tel'
                    disabled={fieldsDisabled}
                    autoComplete='tel'
                  />
                </InputWrapper>
              </Box>
            )}
          </Field>
          <Field name='firstName'>
            {({ field, meta }: FieldProps<string, EndAndSendValues>) => (
              <Box mb={4}>
                <InputWrapper
                  id='first-name'
                  label='First name'
                  error={meta.error}
                  stacked
                  {...labelPropsFromMeta({ meta }, 'label')}>
                  <TextField
                    {...field}
                    {...labelPropsFromMeta({ meta }, 'input')}
                    id='first-name'
                    type='text'
                    disabled={fieldsDisabled}
                    autoComplete='given-name'
                  />
                </InputWrapper>
              </Box>
            )}
          </Field>
          <Field name='lastName'>
            {({ field, meta }: FieldProps<string, EndAndSendValues>) => (
              <Box mb={4}>
                <InputWrapper
                  id='last-name'
                  label='Last name'
                  error={meta.error}
                  stacked
                  {...labelPropsFromMeta({ meta }, 'label')}>
                  <TextField
                    {...field}
                    {...labelPropsFromMeta({ meta }, 'input')}
                    id='last-name'
                    type='text'
                    disabled={fieldsDisabled}
                    autoComplete='family-name'
                  />
                </InputWrapper>
              </Box>
            )}
          </Field>
          {showOverrideWarning && (
            <Message
              variant='warning'
              mb={5}
              icon={<NovunaHome />}
              data-test-id='compass-applicant-details-override-warning'>
              <NovunaHeading as='h3' mb={1}>
                Delivery address
              </NovunaHeading>
              If you choose to continue, the delivery address will be updated to
              the customer&rsquo;s home address.
            </Message>
          )}
          <Button
            type='submit'
            isJumbo
            data-test-id='compass-applicant-details-section-submit'>
            Continue
          </Button>
        </Form>
      </Formik>
    </>
  );
};

const getValidationSchema = () => {
  const { firstName, lastName } = personalDetailsSchema.fields;
  const { emailAddress, phoneNumber } = contactDetailsSchema.fields;
  return yup.object<EndAndSendValues>({
    firstName,
    lastName,
    emailAddress,
    phoneNumber
  });
};
