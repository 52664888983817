import { parseDecimal } from 'hitachi-retail-core/build/utils/decimal';
import { createSelector } from 'reselect';
import { ApplicationDetail } from '../../components/form/types';
import { RootState } from '../index';
import {
  selectDeferredProduct as selectDeferredProductFromDocument,
  selectDeferralPeriod as selectDeferralPeriodFromDocument,
  selectInstalments as selectInstalmentsFromDocument,
  selectTotalRepaymentAmount as selectTotalRepaymentAmountFromDocument
} from 'hitachi-retail-core/build/selectors';
import { selectShouldCollectDeposit as selectShouldCollectDepositFromDocument } from 'hitachi-retail-core/build/selectors/selectShouldCollectDeposit';
import { PreviousApplicationDetail } from '../previousApplicationDetail/actions';
import { JourneyType } from 'hitachi-retail-core/build/enums';
import {
  AuditEventDetail,
  AuditEventType
} from 'hitachi-retail-core/build/eventEmitters/types';

export const selectActiveApplication = ({
  application: { activeApplication }
}: RootState): ApplicationDetail => activeApplication;

export const selectCurrentApplicationDetailsFromHistory = (
  state: RootState
): PreviousApplicationDetail | undefined =>
  state.previousApplicationDetail &&
  state.previousApplicationDetail.previousApplication;

export const selectPreviousApplicationAuditHistory = (
  state: RootState
): AuditEventDetail<AuditEventType>[] =>
  state.previousApplicationDetail?.auditHistory ?? [];

export const selectApplicationDocument = createSelector(
  selectActiveApplication,
  applicationDetail => applicationDetail.document
);

export const selectApplicationDetail = createSelector(
  selectActiveApplication,
  applicationDetail => applicationDetail
);

export const selectApplicationStatus = createSelector(
  selectActiveApplication,
  applicationDetail => applicationDetail.status
);

export const selectApplicationSigned = createSelector(
  selectCurrentApplicationDetailsFromHistory,
  previousApplication => previousApplication?.signed
);

export const selectApplicationProduct = createSelector(
  selectApplicationDocument,
  applicationDocument => applicationDocument.product
);

export const selectApplicationRegulated = createSelector(
  selectApplicationProduct,
  product => product?.regulated ?? false
);

export const selectApplicationReturnUrl = createSelector(
  selectApplicationDocument,
  applicationDocument => applicationDocument.returnUrl
);

export const selectApplicationJourneyType = createSelector(
  selectApplicationDocument,
  applicationDocument => applicationDocument.journeyType
);

export const selectIsWebServicesJourney = createSelector(
  selectApplicationJourneyType,
  journeyType => journeyType === JourneyType.WebServices
);

export const selectIsPbfJourney = createSelector(
  selectApplicationDocument,
  applicationDocument => applicationDocument.product?.agreementType === 'PBF'
);

export const selectShowKeyLoanFeaturesWSApps = createSelector(
  selectApplicationRegulated,
  selectApplicationDocument,
  (isRegulated, financeApp) =>
    isRegulated
      ? !financeApp.adequateExplanationShown
      : !financeApp.keyLoanFeaturesShown
);

export const selectApplicationAgreementType = createSelector(
  selectApplicationDocument,
  applicationDocument => applicationDocument.product?.agreementType
);

export const selectApplicationSupplierOrderReference = createSelector(
  selectApplicationDocument,
  applicationDocument => applicationDocument.orderReference
);

export const selectGoodsAmount = createSelector(
  selectActiveApplication,
  activeApplication =>
    parseDecimal(
      activeApplication.document.loanParameters &&
        activeApplication.document.loanParameters.goodsAmount
    )
);

export const selectIsDeferred = createSelector(
  selectApplicationDocument,
  applicationDocument => selectDeferredProductFromDocument(applicationDocument)
);

export const selectDeferralPeriod = createSelector(
  selectApplicationDocument,
  applicationDocument => selectDeferralPeriodFromDocument(applicationDocument)
);

export const selectEarlySettlementFee = createSelector(
  selectApplicationProduct,
  product => !!product?.earlySettlementFee
);

export const selectInstalments = createSelector(
  selectApplicationDocument,
  applicationDocument => selectInstalmentsFromDocument(applicationDocument)
);

export const selectAgreementDuration = createSelector(
  selectInstalments,
  selectDeferralPeriod,
  selectIsDeferred,
  (instalments, deferralPeriod, isDeferred) => {
    if (isDeferred && instalments && deferralPeriod) {
      return instalments + deferralPeriod;
    } else if (!isDeferred && instalments) {
      return instalments;
    }

    return;
  }
);
//
// export const selectOnlineDepositsEnabled = createSelector(
//   selectApplicationDocument,
//   applicationDocument => applicationDocument.isOnlineDepositJourney
// );

export const selectShouldCollectDeposit = createSelector(
  selectApplicationDocument,
  applicationDocument =>
    selectShouldCollectDepositFromDocument(applicationDocument)
);

export const selectFetchApplicationStatus = (state: RootState) =>
  state.application.fetchApplicationStatus;

export const selectBankAccountCheckStatus = (state: RootState) =>
  state.application.bankCheckStatus;

export const selectMustReselectProduct = (state: RootState) =>
  state.application.mustReselectProduct;

export const selectTotalRepaymentAmount = createSelector(
  selectApplicationDocument,
  applicationDocument =>
    selectTotalRepaymentAmountFromDocument(applicationDocument)
);

export const selectApplicationFirstName = createSelector(
  selectApplicationDocument,
  applicationDocument => applicationDocument.personalDetails?.firstName
);

/**
 * When a softsearch decision is made and returned, the soft search expiry date is attached to it.
 * Upon re-entry of the page (page re-fresh) the decision endpoint is not called.
 * Therefore if the "decision.SoftSearchExpiryDate" doesnt exist we're falling back on the activeApplication.softSearchExpiryDate
 * Format from decision: '2013-01-01'
 * Format from activeApplication: '2013-01-01T00:00:00.000Z'
 * Both are correctly parsed by the 'new Date()'
 * @param {RootState} state
 * @returns {string | undefined}
 */
export const selectSoftSearchExpiryDate = (
  state: RootState
): string | undefined => {
  if (state.decision.softSearchExpiryDate) {
    return state.decision.softSearchExpiryDate;
  }
  return state.application.activeApplication.softSearchExpiryDate;
};
