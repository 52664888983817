import { GlobalStyle, novunaTheme, ThemeProvider } from 'compass-design';
import SilkaLight from 'compass-design/assets/fonts/Silka-Light.woff';
import SilkaRegular from 'compass-design/assets/fonts/Silka-Regular.woff';
import SilkaSemiBold from 'compass-design/assets/fonts/Silka-SemiBold.woff';
import CustomerFacingPage from 'components/common/CustomerFacingPage';
import ErrorBoundary from 'components/meta/ErrorBoundary';
import InactiveLogout from 'containers/meta/InactiveLogout';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router';
import LoggedInApp from 'retailerApp/pages/LoggedInApp/LoggedInAppContainer';
import LoggedOutApp from 'retailerApp/pages/LoggedOutApp/LoggedOutApp';
import { routes } from 'routes';

export interface RetailerAppPropsFromDispatch {
  fetchConfig: () => void;
}

export type RetailerAppProps = RetailerAppPropsFromDispatch;

const RetailerApp: React.FC<RetailerAppProps> = ({ fetchConfig }) => {
  useEffect(() => {
    fetchConfig();
  }, [fetchConfig]);

  return (
    <>
      <Helmet>
        <title>CreditMaster3</title>
        <link rel='stylesheet' href='css/switch.css' />
      </Helmet>
      <InactiveLogout />
      <ErrorBoundary>
        <ThemeProvider theme={novunaTheme}>
          <GlobalStyle
            fontFamily='Silka'
            lightFontUrl={SilkaLight}
            regularFontUrl={SilkaRegular}
            boldFontUrl={SilkaSemiBold}
          />
          <Switch>
            <Route
              path={[
                routes.agreement,
                routes.customerSatisfaction.dissatisfied,
                routes.customerSatisfaction.satisfied
              ]}
              exact
              component={CustomerFacingPage}
            />
            <Route
              path={[
                routes.setNewPassword,
                routes.confirmForgotPassword,
                routes.forgotPassword,
                routes.index,
                routes.login,
                routes.directApps.login
              ]}
              exact
              component={LoggedOutApp}
            />
            <Route component={LoggedInApp} />
          </Switch>
        </ThemeProvider>
      </ErrorBoundary>
    </>
  );
};

export default RetailerApp;
