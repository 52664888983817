import { RetailerConfig } from 'hitachi-retail-core/build/api/retailerConfig';
import { DefaultSagaParams } from '../sagas';

export interface RetailerService {
  getRetailer: () => Promise<RetailerConfig>;
  getRetailerByApplicationId: (id: string) => Promise<RetailerConfig>;
  getAllBranches: () => Promise<RetailerConfig[]>;
  getBranchesForProposing: () => Promise<RetailerConfig[]>;
}

export const getRetailerService = ({
  apiClient
}: DefaultSagaParams): RetailerService => ({
  getRetailer: async () => {
    let response;

    try {
      response = await apiClient.get<RetailerConfig>('protected/retailer');
    } catch {
      throw new Error('Retailer service API call failed');
    }

    const retailerConfig = response.data;

    if (retailerConfig) {
      return retailerConfig;
    } else {
      throw new Error('No retailer config in API response');
    }
  },
  getRetailerByApplicationId: async id => {
    let response;

    try {
      response = await apiClient.get<RetailerConfig>(
        `protected/retailer/${id}`
      );
    } catch {
      throw new Error('Retailer service API call failed');
    }

    const retailerConfig = response.data;

    if (retailerConfig) {
      return retailerConfig;
    } else {
      throw new Error('No retailer config in API response');
    }
  },
  getAllBranches: async () => {
    let response;

    try {
      response = await apiClient.get<RetailerConfig[]>(
        `protected/retailer/all-branches`
      );
    } catch (err) {
      throw new Error('Retailer service API call failed');
    }
    const retailerConfigs = response.data;

    if (retailerConfigs && Array.isArray(retailerConfigs)) {
      return retailerConfigs;
    } else {
      throw new Error('No retailer config in API response');
    }
  },
  getBranchesForProposing: async () => {
    let response;

    try {
      response = await apiClient.get<RetailerConfig[]>(
        `protected/retailer/branches-for-proposing`
      );
    } catch (err) {
      throw new Error('Retailer service API call failed');
    }
    const retailerConfigs = response.data;

    if (retailerConfigs && Array.isArray(retailerConfigs)) {
      return retailerConfigs;
    } else {
      throw new Error('No retailer config in API response');
    }
  }
});
