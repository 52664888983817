import {
  Button,
  Modal,
  Text,
  Heading,
  Flex,
  PasswordStrengthBar,
  TextField
} from 'compass-design';
import React, { useEffect, useState } from 'react';
import ButtonGroup from './common/ButtonGroup';
import { routes } from 'routes';
import { useHistory } from 'react-router';
import useWindowDimensions from 'retailerApp/hooks/useWindowDimensions';
import { PasswordStrengthLabel } from './PasswordStrengthLabel';

export interface Email {
  confirmedEmailAddress: string;
}

export interface StrongerPasswordGuidanceProps {
  modalOpen: boolean;
  toggleModalOpen: (isOpen: boolean) => void;
  managePasswordBtn?: boolean;
}

const StrongerPasswordFieldDemo = () => {
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [fakeCharacters, setFakeCharacters] = useState('');

  // Increment the strength
  useEffect(() => {
    const interval = setInterval(() => {
      setPasswordStrength(passwordStrength >= 4 ? 0 : passwordStrength + 1);
      if (passwordStrength >= 4) {
        setFakeCharacters('');
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [passwordStrength]);

  // Fake our typing
  useEffect(() => {
    const interval = setInterval(() => {
      setFakeCharacters('a'.repeat(fakeCharacters.length + 1));
    }, 350);
    return () => clearInterval(interval);
  });

  return (
    <>
      <TextField type='password' value={fakeCharacters} disabled />
      <PasswordStrengthBar strength={passwordStrength || 0} mb={'10px'} />
      <PasswordStrengthLabel strength={passwordStrength || 0} />
    </>
  );
};

export const StrongerPasswordGuidance: React.FC<StrongerPasswordGuidanceProps> = ({
  modalOpen,
  toggleModalOpen,
  managePasswordBtn = true
}) => {
  const { width } = useWindowDimensions();
  const history = useHistory();

  return (
    <div data-test-id='stronger-password-guidance-modal'>
      <Modal
        size={width < 648 ? 'small' : 'medium'}
        title='Guidance on strengthening your password'
        isOpen={modalOpen}
        onSetIsOpen={toggleModalOpen}
        printable={false}>
        <Heading visualSize={1}>What is a good password?</Heading>
        <Text mb={3}>
          A good password is simply one that is hard for a computer to crack.
        </Text>

        <Heading visualSize={1}>
          How do we know if you are choosing a good password?
        </Heading>
        <Text mb={3}>
          <Text mb={2}>
            We use a password strength algorithm to indicate if the password you
            are typing is likely to be a good password.
          </Text>
          <Text mb={2}>
            The strength will be indicated to you by a coloured bar demonstrated
            below that fills from left to right as your password gets stronger.
          </Text>
        </Text>

        <StrongerPasswordFieldDemo />

        <Heading visualSize={1} mt={2}>
          Further guidance on strengthening your password
        </Heading>
        <Text mb={0}>
          <ul>
            <li>Your password must be at least 8 characters long.</li>
            <li>
              Avoid commonly used passwords, or sequences of letters and numbers
              (such as <code>Password1</code> <em>or</em> <code>abcdefg1</code>
              ).
            </li>
            <li>
              Avoid using your personal information in your password, (e.g. your
              name, date of birth, etc.), as this could be known to others.
            </li>
            <li>
              You may consider including a combination of uppercase and
              lowercase letters, numbers and symbols, though this is optional.
            </li>
            <li>
              Your password should be significantly different from your
              previously used passwords.
            </li>
          </ul>
        </Text>

        <Flex sx={{ flex: '1', justifyContent: 'center' }}>
          <ButtonGroup>
            {managePasswordBtn && (
              <Button
                type='button'
                onClick={() => {
                  toggleModalOpen(false);
                  history.push(routes.profile.managePassword);
                }}
                data-test-id='stronger-password-guidance-modal-manage-pw-btn'>
                Manage Password
              </Button>
            )}
            <Button
              type='button'
              onClick={() => toggleModalOpen(false)}
              data-test-id='stronger-password-guidance-modal-close-btn'>
              Close
            </Button>
          </ButtonGroup>
        </Flex>
      </Modal>
    </div>
  );
};
