import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import PrivacyPolicy, {
  PrivacyPolicyPropsFromDispatch,
  PrivacyPolicyPropsFromState
} from '../components/PrivacyPolicy';
import { RootState } from '../store';
import { updateActiveApplication } from '../store/application/actions';
import { selectActiveApplication } from '../store/application/selectors';

export const mapStateToProps = (
  state: RootState
): PrivacyPolicyPropsFromState => {
  const application = selectActiveApplication(state);
  const { paperlessConsent = false } = application.document;

  return {
    paperlessConsent
  };
};

export const mapDispatchToProps = (
  dispatch: Dispatch
): PrivacyPolicyPropsFromDispatch => ({
  onApplicationUpdate: document =>
    dispatch(updateActiveApplication({ document, replaceValues: false }))
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
