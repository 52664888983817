import {
  Box,
  Button,
  Container,
  InlineCheckbox,
  Link,
  Message,
  NovunaHeading,
  ReactModal,
  SoftSearchCard,
  Text,
  useIsModalOpen
} from 'compass-design';
import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectEnabledFeatures } from 'store/config/selectors';
import { fetchDecision } from 'store/decision/actions';
import DownloadLetter, {
  DownloadLetterProps
} from '../DownloadLetter/DownloadLetter';

export interface DecisionDeclineProps {
  isSoftSearch: boolean;
  downloadLetter: DownloadLetterProps;
}

const DecisionDecline: React.FC<DecisionDeclineProps> = ({
  isSoftSearch,
  downloadLetter
}) => {
  const dispatch = useDispatch();
  const enabledFeatures = useSelector(selectEnabledFeatures);
  // This feature will be released at the same time as the "MO_PBF_SOFT_SEARCH" feature.
  const isAppealProcessEnabled = enabledFeatures.has(
    CompassFeature.MO_PBF_SOFT_SEARCH
  );

  const [isModalOpen, setIsOpen] = useIsModalOpen(false);
  const onCloseModal = (isModalOpen: boolean) => {
    setIsChecked(false);
    setIsOpen(isModalOpen);
  };
  const [isChecked, setIsChecked] = useState(false);
  const onCheckboxChange = () => setIsChecked(prevState => !prevState);
  const onConfirm = () => {
    dispatch(fetchDecision.request({ softSearch: false }));
    onCloseModal(false);
  };

  if (isSoftSearch) {
    const confirmationModal = isAppealProcessEnabled && (
      <ReactModal
        isOpen={isModalOpen}
        setIsOpen={onCloseModal}
        title='Appeal decision'
        size='medium'>
        <Container
          px={1}
          data-test-id='softsearch-decision-declined-appeal-container'>
          <Message variant='warning'>
            <NovunaHeading as='h3'>
              Are you sure you wish to proceed?
            </NovunaHeading>
            This will leave a footprint on your customer&rsquo;s credit file
            that is visible to other lenders.
          </Message>
          <Box
            mt={4}
            data-test-id='softsearch-decision-declined-appeal-warning-checkbox'>
            <InlineCheckbox
              checked={isChecked}
              onChange={onCheckboxChange}
              data-test-id='softsearch-decision-declined-appeal-checkbox'>
              <Text sx={{ fontSize: '20px', lineHeight: '24px' }}>
                I wish to proceed with a hard search
              </Text>
            </InlineCheckbox>
          </Box>
          <Button
            mt={4}
            isJumbo
            data-test-id='softsearch-decision-declined-appeal-confirmation-button'
            onClick={onConfirm}
            disabled={!isChecked}>
            Confirm
          </Button>
        </Container>
      </ReactModal>
    );

    return (
      <>
        {confirmationModal}
        <div data-test-id='compass-application-decision-declined-softsearch'>
          <DownloadLetter {...downloadLetter} isSoftSearch />
          <Box mt={6}>
            <SoftSearchCard text='No footprint was left on the customer&rsquo;s credit file.' />
          </Box>
          {isAppealProcessEnabled && (
            <Text
              mt={6}
              data-test-id='softsearch-decision-declined-appeal-text'>
              If you appeal this decision you will need to first{' '}
              <Link
                data-test-id='softsearch-decision-declined-appeal-link'
                onClick={() => setIsOpen(true)}
                sx={{ cursor: 'pointer' }}>
                submit
              </Link>{' '}
              the application for a full credit search. This will leave a
              footprint on the customer&rsquo;s credit file which is visible to
              other lenders. Further information may be required. A final
              decision could take up to 10 working days and there is no
              guarantee of a different outcome.
            </Text>
          )}
        </div>
      </>
    );
  }

  return (
    <div data-test-id='compass-application-decision-declined'>
      <Message variant='info'>
        <NovunaHeading as='h3' id='decision-decline-heading' mb={1}>
          Unsuccessful
        </NovunaHeading>
        <Text>
          Thank you for applying. Unfortunately, after carrying out our checks
          we have declined your application.
        </Text>
      </Message>
      <DownloadLetter {...downloadLetter} mt={4} />
    </div>
  );
};

export default DecisionDecline;
