import { Button, Modal, Retry, Text } from 'compass-design';
import { Container, Grid } from 'theme-ui';
import React from 'react';
import { useCloseRevertModalCallback } from './hooks/callbacks';
import { RevertibleVersionDetails } from 'hitachi-retail-core/build/schemas/financeApplication';
import {
  selectDeposit,
  selectGoodsAmount,
  selectGoodsDescription,
  selectInstalments,
  selectLoanAmount,
  selectRepaymentAmount
} from 'hitachi-retail-core/build/selectors';
import { formatDate } from '../../utils/formatters';
import { formatCurrency } from 'hitachi-retail-core/build/utils/currency';
import { formatStatus } from 'hitachi-retail-core/build/utils/formatStatus';
import { ReadOnlyAddress } from '../common/ReadOnlyAddress';
import { Address } from 'hitachi-retail-core';

export type RevertApplicationModalProps = {
  show: boolean;
  revertApplicationVersionTo: () => void;
  removeModal: () => void;
  revertibleVersionDetails: RevertibleVersionDetails;
};

const getRevertibleVersionData = (
  revertibleVersionDetails: RevertibleVersionDetails
) => {
  const {
    applicantName,
    status,
    document,
    mainAddress,
    updated,
    signedAt
  } = revertibleVersionDetails;

  const { loanRepayment, loanParameters } = document;
  const goodsDescription = selectGoodsDescription(document);
  const totalGoodsAmount = formatCurrency(
    selectGoodsAmount({ loanParameters })
  );
  const totalLoanAmount = formatCurrency(selectLoanAmount(document));
  const depositAmount = formatCurrency(selectDeposit({ loanParameters }));
  const instalments = selectInstalments({ loanRepayment })?.toString();
  const monthlyRepayments = formatCurrency(selectRepaymentAmount(document));
  const created = formatDate({ value: updated, showTime: true });
  const signed = formatDate({ value: signedAt, showTime: true });
  const formattedStatus = formatStatus(status);
  const address = (
    <ReadOnlyAddress fontSize={1} address={mainAddress as Address} />
  );

  return [
    'Name',
    applicantName,
    'Address',
    address,
    'Status',
    formattedStatus,
    'Goods description',
    goodsDescription,
    'Goods amount',
    totalGoodsAmount,
    'Loan amount',
    totalLoanAmount,
    'Deposit amount',
    depositAmount,
    'Repayments',
    instalments,
    'Monthly repayment',
    monthlyRepayments,
    'Signed',
    signed,
    'Created',
    created
  ];
};

const RevertApplicationModal: React.FC<RevertApplicationModalProps> = ({
  show,
  revertApplicationVersionTo,
  removeModal,
  revertibleVersionDetails
}) => {
  const onSetIsOpen = useCloseRevertModalCallback(removeModal);
  const gridData = getRevertibleVersionData(revertibleVersionDetails);

  return (
    <div data-test-id='revert-application-modal'>
      <Modal
        size='medium'
        title='Signed agreement'
        isOpen={show}
        onSetIsOpen={onSetIsOpen}
        printable={false}>
        <Container>
          <Text mb={5} style={{ lineHeight: '1.2' }}>
            The customer has signed an agreement with the following details,
            this will remain valid unless the amended agreement is signed.
          </Text>
          <Grid gap={0} columns={[2, '1fr 2fr']}>
            {gridData.map((item, index) => {
              const topRow = index <= 1;
              const borderTop = topRow ? '2px solid' : undefined;
              const borderTopColor = topRow ? 'monochrome.2' : undefined;
              const isEven = index % 2;
              const paddingLeft = isEven ? 0 : 3;

              return (
                <Text
                  key={index}
                  py={3}
                  pl={paddingLeft}
                  pr={4}
                  sx={{
                    borderTop,
                    borderTopColor,
                    borderBottom: '2px solid',
                    borderBottomColor: 'monochrome.5'
                  }}>
                  {item}
                </Text>
              );
            })}
          </Grid>
          <Text mt={5} style={{ lineHeight: '1.2' }}>
            You can revert to this agreement to allow it to proceed, this will
            cancel the amendment.
          </Text>
          <Button
            isJumbo
            mt={5}
            data-test-id='revert-application-cta'
            variant='secondary'
            onClick={() => revertApplicationVersionTo()}>
            Revert <Retry />
          </Button>
        </Container>
      </Modal>
    </div>
  );
};

export default RevertApplicationModal;
