import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { fetchDeclinedLetterById } from './actions';

export interface State {
  pdfUrl?: string;
  pdfBlob?: Blob;
}

export type FetchDeclinedEmailByIdAction = ActionType<
  typeof fetchDeclinedLetterById
>;

export const initialState: State = {};

export const reducer: Reducer<State, FetchDeclinedEmailByIdAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchDeclinedLetterById.request): {
      return { ...state };
    }
    case getType(fetchDeclinedLetterById.success): {
      const pdfBlob = new Blob([action.payload], { type: 'application/pdf' });
      if (state.pdfUrl) {
        window.URL.revokeObjectURL(state.pdfUrl);
      }
      const pdfUrl = window.URL.createObjectURL(pdfBlob);
      return { ...state, pdfUrl, pdfBlob };
    }
    case getType(fetchDeclinedLetterById.failure): {
      return { ...state };
    }
    default: {
      return state;
    }
  }
};
