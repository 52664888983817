import { RetailerRedirectButton } from 'applicantApp/components/ExternalRedirectButtons/ExternalRedirectButtons';
import NovunaPageLayout from 'applicantApp/components/NovunaPageLayout';
import { Box, NovunaHeading, Text } from 'compass-design';
import React from 'react';
import { useSelector } from 'store';
import {
  selectApplicationReturnUrl,
  selectApplicationSupplierOrderReference
} from 'store/application/selectors';

interface OnlineDepositErrorProps {
  id: string;
  resumeable?: boolean;
}

const OnlineDepositError: React.FC<OnlineDepositErrorProps> = ({
  id,
  resumeable
}) => {
  const returnUrl = useSelector(selectApplicationReturnUrl);
  const supplierOrderReference = useSelector(
    selectApplicationSupplierOrderReference
  );

  return (
    <NovunaPageLayout>
      <NovunaHeading as='h1' mb={4}>
        Payment failed
      </NovunaHeading>
      {resumeable ? (
        <div data-test-id={'online-deposit-error-retryable'}>
          <Text mb={4}>
            We’re sorry, something went wrong with our system and we were unable
            to take payment of your deposit.
          </Text>
          <Text mb={4}>Please retry at a later time.</Text>
        </div>
      ) : (
        <Text data-test-id={'online-deposit-error-non-retryable'} mb={4}>
          Please contact your bank for assistance.
        </Text>
      )}

      <Box mt={5}>
        <RetailerRedirectButton
          novunaDisplay
          baseUrl={returnUrl}
          applicationId={id}
          status='error'
          buttonText='Back to store'
          supplierOrderReference={supplierOrderReference}
        />
      </Box>
    </NovunaPageLayout>
  );
};

export default OnlineDepositError;
