import { NovunaHeading, Text } from 'compass-design';
import ThemedList from 'components/ThemedList';
import React from 'react';

const UseOfBankDetailsStatement: React.FC = () => (
  <>
    <NovunaHeading as='h3' mt={5}>
      Use of bank details
    </NovunaHeading>
    <Text mt={2}>
      These bank details will be used by Novuna Personal Finance for our ID and
      security checks, and if your application is successful they will also be
      used for Direct Debit Payment Instruction.
    </Text>
    <ThemedList
      items={[
        'The bank details provided must be for a UK bank or building society that accepts Direct Debits',
        'You must be the account holder',
        'You must be authorised to make a new Direct Debit instruction without permission from additional account holders'
      ]}
    />
  </>
);

export default UseOfBankDetailsStatement;
