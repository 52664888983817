import {
  Box,
  Button,
  InputWrapper,
  Layout,
  Message,
  NovunaHeading,
  NovunaPBFFooter,
  NovunaPBFHeader,
  NovunaSingleColumnLayout,
  Text,
  TextArea
} from 'compass-design';
import {
  satisfactionFeedbackMaxLength,
  satisfactionFeedbackMinLength,
  satisfactionFeedbackSchema
} from 'hitachi-retail-core/build/schemas/customerSatisfaction';
import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router';

interface CustomerDissatisfiedRouteParams {
  token: string;
}

export interface CustomerDissatisfiedPropsFromState {
  feedbackAlreadySubmitted: boolean;
  feedbackComplete: boolean;
  feedbackError: boolean;
  processing: boolean;
  retailerName: string;
  applicationId?: string;
}

export interface CustomerDissatisfiedPropsFromDispatch {
  submitFeedback: (token: string, feedback: string) => void;
}

export type CustomerDissatisfiedProps = CustomerDissatisfiedPropsFromState &
  CustomerDissatisfiedPropsFromDispatch &
  RouteComponentProps<CustomerDissatisfiedRouteParams>;

const CustomerDissatisfied: React.FunctionComponent<CustomerDissatisfiedProps> = ({
  feedbackAlreadySubmitted,
  feedbackComplete,
  feedbackError,
  processing,
  submitFeedback,
  match: {
    params: { token }
  },
  retailerName,
  applicationId
}) => {
  const [feedback, setFeedback] = useState('');

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (validFeedback(feedback)) {
      submitFeedback(token, feedback);
    }
  };

  // Ensure the error message is in view if it occurs
  const feedbackErrorRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (feedbackError && feedbackErrorRef.current) {
      window.scrollTo(0, feedbackErrorRef.current.offsetTop);
    }
  }, [feedbackError]);

  let MainPage;

  if (feedbackComplete || feedbackAlreadySubmitted) {
    MainPage = (
      <>
        {feedbackComplete && (
          <>
            <NovunaHeading as='h1' mb={3}>
              Thank you
            </NovunaHeading>
            {applicationId && (
              <Text mb={3} sx={{ fontWeight: 'bold' }}>
                {applicationId}
              </Text>
            )}
            <Text>
              Thanks for providing feedback on your purchase
              {retailerName && ` from ${retailerName}`}.
            </Text>
            <br />
            <Text>You may now close this window.</Text>
          </>
        )}
        {feedbackAlreadySubmitted && (
          <>
            <NovunaHeading as='h1' mb={3}>
              Feedback provided
            </NovunaHeading>
            <Text>Feedback has already been provided on this purchase.</Text>
            <br />
            <Text>
              If you have further feedback please contact your retailer.
            </Text>
          </>
        )}
      </>
    );
  } else {
    MainPage = (
      <>
        <NovunaHeading as='h1' mb={3}>
          Leave your feedback
        </NovunaHeading>
        {feedbackError && (
          <Box mb={3}>
            <Message variant='error'>
              <NovunaHeading as='h3' mb={1}>
                Please try again
              </NovunaHeading>
              <Text>
                Unfortunately we had a problem submitting your feedback.
              </Text>
            </Message>
          </Box>
        )}
        {applicationId && (
          <Text mb={3} sx={{ fontWeight: 'bold' }}>
            {applicationId}
          </Text>
        )}
        <Text>
          We&rsquo;re sorry to hear that you&rsquo;re not satisfied with the
          installation of your purchase.
        </Text>
        <br />
        <Text>
          Please help us understand the problem by leaving a message below.
        </Text>
        <form noValidate onSubmit={handleSubmit}>
          <Box mt={5}>
            <InputWrapper
              id='feedback'
              label='Your feedback'
              tone='neutral'
              stacked={true}>
              <TextArea
                tone='neutral'
                disabled={processing}
                minLength={satisfactionFeedbackMinLength}
                maxLength={satisfactionFeedbackMaxLength}
                rows={6}
                value={feedback}
                aria-label='please enter your feedback'
                onChange={e => setFeedback(e.target.value)}
              />
            </InputWrapper>
          </Box>
          <Button
            isJumbo
            mt={4}
            disabled={processing || !validFeedback(feedback)}>
            Submit
          </Button>
        </form>
      </>
    );
  }

  return (
    <>
      <NovunaPBFHeader />
      <Layout>
        <NovunaSingleColumnLayout>
          <Box sx={{ maxWidth: '620px' }} mx='auto'>
            {MainPage}
          </Box>
        </NovunaSingleColumnLayout>
      </Layout>
      <NovunaPBFFooter />
    </>
  );
};

const validFeedback = (value: string) =>
  satisfactionFeedbackSchema.isValidSync({
    feedback: value,
    satisfied: false
  }) === true;

export default CustomerDissatisfied;
