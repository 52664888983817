import {
  Box,
  Message,
  NovunaHeading,
  SoftSearchCardWithExpiry,
  TailoringOptions,
  Text
} from 'compass-design';
import React, { useState } from 'react';
import { useSelector } from 'store';
import CreditSearchMessage from 'retailerApp/components/CreditSearchMessage';
import EditLoanCardState, {
  EditLoanState
} from 'retailerApp/components/EditLoanCardState/EditLoanCardState';
import { AffordabilityDeclineProps } from 'retailerApp/utils/loanDetails/getAffordabilityDecline';
import { selectApplicationDocument } from 'store/application/selectors';
import { formatCurrency } from 'utils/formatters';
import { EditDepositProps } from 'compass-design';
import DownloadLetter, {
  DownloadLetterProps
} from '../DownloadLetter/DownloadLetter';
import getReduceRepaymentsText from './getReduceRepaymentsText';
import MixingDeck, {
  NewOfferComparisonCardProps
} from '../../MixingDeck/MixingDeck';
import EditLoanHeader from 'retailerApp/components/EditLoanCardState/EditLoanHeader';
import { TailoringOptionSelected } from 'hitachi-retail-core/build/services/tailoring/types';
import { isRegulatedApplication } from 'hitachi-retail-core/build/application';
import { selectInterestFreeCreditUnregulatedEndDate } from 'store/config/selectors';
import GoodsDetailsModal from 'retailerApp/components/GoodsDetailsModal';
import { OnContinueParams } from 'retailerApp/components/GoodsDetailsModal/GoodsDetailsModal';
import { RadioOption } from 'retailerApp/utils/loanDetails/getRadioOptions';
import { TailoringOption } from 'compass-design/lib/components/TailoringOptions/TailoringOptions';

export interface SetCostOrDeposit {
  key: 'deposit' | 'totalCost';
  value: string;
}

export interface DecisionAffordabilityDeclineProps {
  isSoftSearch: boolean;
  affordabilityDecline: AffordabilityDeclineProps;
  downloadLetter?: DownloadLetterProps;
  softSearchExpiry?: string;
  editDeposit?: EditDepositProps;
}

const DecisionAffordabilityDecline = ({
  isSoftSearch,
  affordabilityDecline,
  downloadLetter,
  editLoanOffer,
  editLoanState,
  goodsDetailsModalProps,
  softSearchExpiry,
  editDeposit
}: DecisionAffordabilityDeclineProps & EditLoanState) => {
  const [tailoringOption, setTailoringOption] = useState<TailoringOption>(
    affordabilityDecline.preSetTailoringOption
  );
  const document = useSelector(selectApplicationDocument);
  const interestFreeCreditUnregulatedEndDate = useSelector(
    selectInterestFreeCreditUnregulatedEndDate
  );

  const { loanDetails } = affordabilityDecline;

  const radioOptions: RadioOption[] =
    (affordabilityDecline.radioOptions as RadioOption[]) ?? [];

  let newOffer: NewOfferComparisonCardProps;
  let selectTailoringOptionRadio: RadioOption | undefined;

  switch (tailoringOption) {
    case 'term':
      newOffer = loanDetails.increaseTermOffer!;
      selectTailoringOptionRadio = radioOptions.find(
        option => option.tailoringOption === 'term'
      );
      break;
    case 'deposit':
      newOffer = loanDetails.increaseDepositOffer!;
      selectTailoringOptionRadio = radioOptions.find(
        option => option.tailoringOption === 'deposit'
      );
      break;
    case 'reduceLoan':
      newOffer = editLoanOffer!;
      selectTailoringOptionRadio = radioOptions.find(
        option => option.tailoringOption === 'reduceLoan'
      );
      break;
  }

  const AffordabilityDeclinePage = (
    <>
      <Text mb={4} sx={{ fontSize: [1, 2, 2] }}>
        Unfortunately, we would not be able to approve this application with
        monthly repayments of{' '}
        {formatCurrency(loanDetails.originalOffer!.monthlyRepayment!)}.{' '}
        {getReduceRepaymentsText(affordabilityDecline.maxInstalmentAmount)}
      </Text>
      {isSoftSearch && <CreditSearchMessage />}
      {radioOptions.length > 1 && (
        <TailoringOptions
          data-test-id='pbf-tailoring-options'
          radioOptions={
            radioOptions.map(({ tailoringOption, title }) => ({
              tailoringOption,
              text: title
            })) as any[]
          }
          selectedTailoringOption={tailoringOption}
          setTailoringOption={setTailoringOption}
          offerText={newOffer!.optionText}
        />
      )}
      {selectTailoringOptionRadio && (
        <Message variant='info' mb={4}>
          <NovunaHeading as='h3' mb={1}>
            {selectTailoringOptionRadio.message.title}
          </NovunaHeading>
          <Text>{selectTailoringOptionRadio.message.text}</Text>
          {tailoringOption === 'reduceLoan' &&
            editLoanState &&
            loanDetails.reduceLoanOffer && (
              <EditLoanHeader
                {...editLoanState}
                {...loanDetails.reduceLoanOffer}
                isIncreaseLoan={false}
              />
            )}
        </Message>
      )}
      <MixingDeck
        originalOffer={loanDetails.originalOffer!}
        newOffer={newOffer!}
        buttonOnClick={
          tailoringOption === 'reduceLoan' && goodsDetailsModalProps
            ? () => goodsDetailsModalProps.setIsGoodsDetailsModalOpen(true)
            : () =>
                affordabilityDecline.onConfirmTailoringUpdate({
                  document: {
                    ...document,
                    loanRepayment: {
                      ...document.loanRepayment,
                      instalments: newOffer.offer.repaidOver
                    },
                    loanParameters: {
                      ...document.loanParameters,
                      deposit: newOffer.offer.deposit.toFixed(2),
                      goodsAmount: newOffer.offer.totalCost.toFixed(2)
                    },
                    product: {
                      ...document.product,
                      regulated: isRegulatedApplication(
                        {
                          ...document,
                          loanRepayment: {
                            ...document.loanRepayment,
                            instalments: newOffer.offer.repaidOver
                          }
                        },
                        {
                          interestFreeCreditUnregulatedEndDate
                        }
                      )
                    }
                  },
                  tailoringOptionSelected:
                    tailoringOption === 'term'
                      ? TailoringOptionSelected.increaseTerm
                      : TailoringOptionSelected.increaseDeposit
                })
        }
        showOfferText={!affordabilityDecline.radioOptions}
        showPlaceholders={
          tailoringOption === 'reduceLoan' && !!editLoanState?.showPlaceholders
        }
        {...(tailoringOption === 'reduceLoan' &&
          editDeposit && { editDeposit })}
      />
      {goodsDetailsModalProps && (
        <GoodsDetailsModal
          {...goodsDetailsModalProps}
          onContinue={({
            goodsDescription,
            orderReference
          }: OnContinueParams) =>
            affordabilityDecline.onConfirmTailoringUpdate({
              document: {
                ...document,
                loanRepayment: {
                  ...document.loanRepayment,
                  instalments: newOffer.offer.repaidOver
                },
                loanParameters: {
                  ...document.loanParameters,
                  deposit: newOffer.offer.deposit.toFixed(2),
                  goodsAmount: newOffer.offer.totalCost.toFixed(2)
                },
                goodsDescription,
                orderReference
              },
              tailoringOptionSelected: TailoringOptionSelected.reduceLoan
            })
          }
        />
      )}
    </>
  );

  if (isSoftSearch) {
    return (
      <>
        {AffordabilityDeclinePage}
        <Box mt={[0, 4, 4]}>
          <SoftSearchCardWithExpiry softSearchExpiry={softSearchExpiry} />
        </Box>
      </>
    );
  }

  return (
    <>
      {AffordabilityDeclinePage}
      <DownloadLetter {...downloadLetter!} />
    </>
  );
};

export { DecisionAffordabilityDecline as DecisionAffordabilityDeclineWithoutEditLoan };
export default EditLoanCardState(DecisionAffordabilityDecline);
