import { IncompleteFinanceApplication } from 'hitachi-retail-core/build/schemas/financeApplication';
import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';
import { call, put, select, takeLeading } from 'redux-saga/effects';
import { selectProposingOnBehalfOf } from 'store/retailer/selectors';
import { getType } from 'typesafe-actions';
import {
  ApplicationsService,
  SaveRequest
} from '../../services/applicationsService';
import { selectActiveApplication } from '../../store/application/selectors';
import { saveApplication } from '../../store/save/actions';
import { selectApplicationId } from '../../store/save/reducer';

export interface GetSaveApplicationSagaParams {
  applicationsService: Pick<ApplicationsService, 'saveApplication'>;
}

export const getSaveApplicationSaga = ({
  applicationsService
}: GetSaveApplicationSagaParams) =>
  function*() {
    const proposingOnBehalfOf: Retailer | undefined = yield select(
      selectProposingOnBehalfOf
    );

    const activeApplication = yield select(selectActiveApplication);

    try {
      // Check if application has already been saved
      const id = yield select(selectApplicationId);
      const applicationDocument: IncompleteFinanceApplication =
        activeApplication.document;

      const payload: SaveRequest = {
        document: applicationDocument,
        // Only pass ID if it is set
        ...(id && { id }),
        // Only pass proposing on behalf of supplier number if ID is not set...
        ...(!id &&
          proposingOnBehalfOf && {
            proposingOnBehalfOf: proposingOnBehalfOf.supplierNumber
          })
      };

      const response = yield call(applicationsService.saveApplication, payload);

      if (response) {
        yield put(saveApplication.success({ id: response }));
      } else {
        yield put(
          saveApplication.failure(
            new Error(
              'Save Application API response did not contain expected values'
            )
          )
        );
      }
    } catch {
      yield put(
        saveApplication.failure(
          new Error('Failed to call Save Application API')
        )
      );
    }
  };

export const getSaveApplicationSagaWatcher = ({
  applicationsService
}: GetSaveApplicationSagaParams) =>
  function*() {
    yield takeLeading(
      getType(saveApplication.request),
      getSaveApplicationSaga({ applicationsService })
    );
  };
