import {
  novunaCity,
  novunaCounty,
  novunaPhoneNumber,
  novunaPostCode,
  novunaStreetAddress
} from 'applicantApp/config';
import { List, ListItem, NovunaHeading, Text } from 'compass-design';
import React from 'react';
import { useSelector } from 'store';
import { selectAgreementDuration } from 'store/application/selectors';

export const KeyLoanFeatures: React.FC = () => {
  const instalments = useSelector(selectAgreementDuration);

  return (
    <>
      <NovunaHeading id='klf-heading' as='h2' mt={4}>
        Loan Key Features (Unregulated)
      </NovunaHeading>
      <List fontSize='1'>
        <ListItem>
          The agreement is to finance the purchase of the specified goods and/or
          services from us.
        </ListItem>
        <ListItem>
          This loan agreement is provided by Mitsubishi HC Capital UK PLC
          trading as Novuna Consumer Finance, {novunaStreetAddress},{' '}
          {novunaCity}, {novunaCounty}, {novunaPostCode}, {novunaPhoneNumber}.
        </ListItem>
        <ListItem>
          The loan and the goods and services financed by the loan are intended
          to be used by you for your personal use. If you plan to use the goods
          and/or services financed by the loan for business purposes then this
          loan is not suitable for you.
        </ListItem>
        <ListItem>
          All instalments must be repaid within a {instalments} month period
          from the date of the agreement.
        </ListItem>
        <ListItem>
          The loan is interest free and we do not apply any fees or charges
          (other than charges as a result of late payments as detailed in the
          Loan Advance Information document and credit agreement).
        </ListItem>
        <ListItem>
          The loan is unregulated. This means that most of the protections
          provided to consumers under the Consumer Credit Act 1974{' '}
          <Text
            sx={{
              fontWeight: 'bold',
              textDecoration: 'underline',
              display: 'inline'
            }}>
            will not
          </Text>{' '}
          apply to you &ndash; including Sections 75 and 56, which allow
          consumers to bring claims about the goods and services financed to the
          lender in certain circumstances. You will therefore be required to
          make your monthly repayments even if you have a dispute with the
          supplier of the goods/services.
        </ListItem>
        <ListItem>
          You are responsible for paying each instalment by the date due. Late
          payment or failure to repay the balance in full could result in
          adverse consequences for you. As a responsible lender, Novuna is
          obliged to notify credit reference agencies where payments have been
          missed. Missing payments can have a negative impact on your credit
          score and may make obtaining credit more difficult in the future.
        </ListItem>
        <ListItem>
          This explanation is a summary of the key features, benefits and
          limitations of the loan. It does not contain the full terms and
          conditions of the loan. For full terms and conditions you should refer
          to your loan agreement.
        </ListItem>
      </List>
    </>
  );
};
