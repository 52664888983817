import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';
import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { resetStore, ResetStoreAction } from '../actions';
import { FetchActiveApplicationAction } from '../application/reducer';
import {
  fetchActiveApplication,
  PreviousApplicationDetail
} from '../previousApplicationDetail/actions';
import {
  fetchAllBranches,
  fetchBranchesForProposing,
  fetchRetailer,
  FetchRetailerAction,
  fetchRetailerByApplicationId,
  setProposingOnBehalfOf,
  SetProposingOnBehalfOfAction
} from './actions';
import { RootState } from '../index';

export type RetailerByApplicationIdAction = ActionType<
  typeof fetchRetailerByApplicationId
>;
export type FetchAllBranchesAction = ActionType<typeof fetchAllBranches>;
export type FetchBranchesForProposingAction = ActionType<
  typeof fetchBranchesForProposing
>;

export type SupplierNumber = string;

export interface State {
  processing: boolean;
  error: boolean;
  retailer: Retailer;
  retailerLoaded: boolean;
  branches?: Retailer[];
  branchesForProposing?: Retailer[];
  proposingOnBehalfOf?: SupplierNumber;
}

export const initialState: State = {
  error: false,
  processing: false,
  retailer: {
    name: '',
    hasChildBranches: false,
    supplierNumber: '',
    address: {
      town: '',
      postCode: ''
    }
  },
  retailerLoaded: false
};

export const selectBranchNumber = ({
  users: { selectedBranch }
}: RootState) => {
  return selectedBranch?.supplierNumber;
};
export type Action =
  | FetchRetailerAction
  | RetailerByApplicationIdAction
  | FetchAllBranchesAction
  | FetchBranchesForProposingAction
  | SetProposingOnBehalfOfAction
  | FetchActiveApplicationAction
  | ResetStoreAction;

export const reducer: Reducer<State, Action> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchRetailer.request):
    case getType(fetchAllBranches.request):
    case getType(fetchBranchesForProposing.request):
      return { ...state, processing: true, error: false };

    case getType(fetchRetailer.failure):
    case getType(fetchAllBranches.failure):
    case getType(fetchBranchesForProposing.failure):
      return { ...state, processing: false, error: true };

    case getType(fetchRetailer.success):
      return {
        ...state,
        retailerLoaded: true,
        processing: false,
        retailer: {
          ...action.payload
        }
      };

    case getType(fetchAllBranches.success):
      return {
        ...state,
        processing: false,
        branches: action.payload
      };

    case getType(fetchBranchesForProposing.success):
      return {
        ...state,
        processing: false,
        branchesForProposing: action.payload
      };

    case getType(setProposingOnBehalfOf):
      return {
        ...state,
        proposingOnBehalfOf: action.payload.supplierNumber
      };

    case getType(fetchRetailerByApplicationId.request): {
      return {
        ...state,
        processing: true
      };
    }

    case getType(fetchRetailerByApplicationId.success): {
      const proposingOnBehalfOf = getProposingOnBehalfOf(
        action.payload.supplierNumber,
        state.branchesForProposing
      );

      return {
        ...state,
        processing: false,
        proposingOnBehalfOf: proposingOnBehalfOf?.supplierNumber
      };
    }

    case getType(fetchRetailerByApplicationId.failure): {
      return { ...state, processing: false, error: true };
    }

    // Mail order can only fetch the retailer details along with application details
    case getType(fetchActiveApplication.success): {
      const { retailer } = action.payload as PreviousApplicationDetail;

      return {
        ...state,
        retailer: {
          ...state.retailer,
          ...retailer
        }
      };
    }

    case getType(resetStore): {
      return initialState;
    }

    default:
      return state;
  }
};

const getProposingOnBehalfOf = (
  supplierNumber: string,
  branchesForProposing?: Retailer[]
): Retailer | undefined =>
  (branchesForProposing ?? []).find(
    branch => branch.supplierNumber === supplierNumber
  );
