/* eslint-disable */

import { RootState } from 'store';
import { selectSoftSearchEnabled } from 'store/retailer/selectors';
import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';
import { getAgreementType } from 'hitachi-retail-core/build/application';
import { IncompleteFinanceApplication } from 'hitachi-retail-core/build/schemas/financeApplication';

interface IsSoftSearchEnabled {
  state: RootState;
}

const isSoftSearchEnabled = ({ state }: IsSoftSearchEnabled): boolean =>
  selectSoftSearchEnabled(state);

export const isSoftSearchEnabledForAgreement = (
  retailerConfig: Retailer,
  financeApplication: IncompleteFinanceApplication
) => {
  const applicationAgreementType = getAgreementType(financeApplication);

  switch (applicationAgreementType) {
    case 'MAIL_ORDER':
      return retailerConfig.moSoftSearchEnabled;
    case 'PBF':
      return retailerConfig.pbfSoftSearchEnabled;
    default:
      return false;
  }
};

export default isSoftSearchEnabled;
