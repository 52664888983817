import { DefaultSagaParams } from '../sagas';

export interface ApplicationSnapshotService {
  createSnapshot: (id: string) => Promise<void>;
  revertApplication: (id: string) => Promise<void>;
}

export const getApplicationSnapshotService = ({
  apiClient
}: DefaultSagaParams): ApplicationSnapshotService => ({
  createSnapshot: async id => {
    try {
      await apiClient.post(`protected/applications/${id}/snapshot`);
      console.log('Successfully created the application snapshot');
    } catch (err) {
      console.error(`Failed to create the application snapshot`, { err });
    }
  },
  revertApplication: async id => {
    try {
      await apiClient.post(`protected/applications/${id}/revert`);
      console.log('Successfully reverted the application');
    } catch (err) {
      console.error(`Failed to revert the application`, { err });
    }
  }
});
