import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import { UserRole } from 'hitachi-retail-core/build/userPolicy';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { selectEnabledFeatures } from 'store/config/selectors';
import { RootState } from 'store';
import { createNewApplication } from 'store/application/actions';
import { userLogout } from 'store/user/actions';
import {
  selectLoggedIn,
  selectPasswordPrompt,
  selectUsername
} from 'store/user/reducer';
import App, {
  LoggedInAppPropsFromDispatch,
  LoggedInAppPropsFromState
} from './LoggedInApp';

export const mapStateToProps = (
  state: RootState
): LoggedInAppPropsFromState => {
  const loggedIn = selectLoggedIn(state);
  const username = selectUsername(state);
  const promptPasswordUpdate = selectPasswordPrompt(state);
  const enabledFeatures = selectEnabledFeatures(state);
  const userRoles = state.user.userRoles ?? [];

  let showUsers = false;
  let showEnhancedUsers = false;

  const userRolesWhoCanSeeUsers = [
    UserRole.BrokerUser,
    UserRole.GroupUser,
    UserRole.HeadOfficeUser,
    UserRole.BranchManager
  ];

  userRoles.forEach(role => {
    if (userRolesWhoCanSeeUsers.includes(role)) {
      showUsers = true;
    }
    if (
      userRolesWhoCanSeeUsers.includes(role) &&
      enabledFeatures.has(CompassFeature.USER_SEARCH_ENHANCEMENTS)
    ) {
      showEnhancedUsers = true;
    }
  });

  return {
    enabledFeatures,
    loggedIn,
    username,
    promptPasswordUpdate,
    showUsers,
    showEnhancedUsers
  };
};

export const mapDispatchToProps = (
  dispatch: Dispatch
): LoggedInAppPropsFromDispatch => ({
  logoutUser: () => dispatch(userLogout.request()),
  createNewApplication: () => dispatch(createNewApplication())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
