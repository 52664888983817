import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  SEND_APPLICATION_FAILURE,
  SEND_APPLICATION_REQUEST,
  SEND_APPLICATION_RESET,
  SEND_APPLICATION_SUCCESS
} from './constants';

interface SendApplicationError {
  error: Error;
}

export const sendApplication = createAsyncAction(
  SEND_APPLICATION_REQUEST,
  SEND_APPLICATION_SUCCESS,
  SEND_APPLICATION_FAILURE
)<void, void, SendApplicationError>();

export const sendApplicationReset = createStandardAction(
  SEND_APPLICATION_RESET
)();
