import React from 'react';
import { WidgetProps } from 'react-jsonschema-form';

export type HiddenWidgetProps = WidgetProps & {
  testId?: string;
};

const HiddenWidget: React.FunctionComponent<HiddenWidgetProps> = ({
  id,
  value,
  testId
}) => (
  <input
    type='hidden'
    id={id}
    value={typeof value === 'undefined' ? '' : value}
    data-test-id={testId || id}
  />
);

export default HiddenWidget;
