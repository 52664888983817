import React from 'react';
import { Box, Message, NovunaHeading } from 'compass-design';

export const FatalErrorEvent = (): JSX.Element => (
  <Box mt={6} data-test-id='fatal-error-event-message'>
    <Message variant='warning' restrictedWidth={false}>
      <NovunaHeading as='h3' mb={1}>
        Something went wrong
      </NovunaHeading>
      There was a problem when receiving a decision for your application. Please
      contact the Retail Helpline on{' '}
      <a href='tel:03443755515' style={{ color: 'black' }}>
        0344 375 5515
      </a>
      .
    </Message>
  </Box>
);
