import { connect } from 'react-redux';
import { RootState } from 'store';
import { selectRetailerSubdomain } from 'store/config/selectors';
import { sendPasswordResetCode } from 'store/user/actions';
import { Dispatch } from 'redux';
import {
  ResetPasswordFormPropsFromDispatch,
  ResetPasswordFormPropsFromState,
  ResetPasswordFormValues
} from './components/ResetPasswordForm';
import { ResetPassword } from './ResetPassword';

export const mapStateToProps = (
  state: RootState
): ResetPasswordFormPropsFromState => {
  const {
    user: {
      passwordResetCodeProcessing,
      passwordResetCodeSuccess,
      errorMessage
    }
  } = state;
  return {
    retailerName: selectRetailerSubdomain(state),
    processing: passwordResetCodeProcessing,
    errorMessage,
    success: passwordResetCodeSuccess
  };
};

export const mapDispatchToProps = (
  dispatch: Dispatch
): ResetPasswordFormPropsFromDispatch => ({
  onSubmit: (params: ResetPasswordFormValues) =>
    dispatch(sendPasswordResetCode.request(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
