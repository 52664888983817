import { Box, Button, Message, NovunaHeading, Text } from 'compass-design';
import React, { useEffect } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { routes } from '../../../../../routes';
import { connect } from 'react-redux';
import {
  mapStateToProps,
  mapDispatchToProps
} from 'retailerApp/pages/ApplicationDetail/ApplicationDetailContainer';
import { DecisionReasonCode } from 'compass-shared-services/build/decision/types';
import F2FReferralIDVTCard from './F2FReferralIDVTCard';
import F2FReferralOpenBankingCard from './F2FReferralOpenBankingCard';

export interface DecisionReferredProps {
  applicationId: string;
}

interface DecisionReferPropsFromState {
  application: any;
}

interface DecisionReferPropsFromDispatch {
  fetchApplicationById: (id: string) => void;
}

type DecisionReferProps = DecisionReferredProps &
  DecisionReferPropsFromState &
  DecisionReferPropsFromDispatch;

const DecisionRefer = ({
  applicationId,
  application,
  fetchApplicationById
}: DecisionReferProps) => {
  useEffect(() => {
    if (applicationId) {
      fetchApplicationById(applicationId);
    }
  }, [applicationId, fetchApplicationById]);

  const reasonCodes = application?.revisions?.[0]?.reasonCodes;

  const isOpenBankingRefer = reasonCodes?.some((code: DecisionReasonCode) =>
    ['OBR1', 'OBR2', 'OBR3'].includes(code.code)
  );

  const isIDVTRefer = reasonCodes?.some((code: DecisionReasonCode) =>
    ['IDV1', 'IDV2'].includes(code.code)
  );

  const phoneNumber = application?.document.contactDetails?.phoneNumber;

  const emailAddress = application?.document.contactDetails?.emailAddress;

  const sendTextMessage =
    !!phoneNumber &&
    (phoneNumber.startsWith('07') || phoneNumber.startsWith('+447'));

  const isMailOrder = !application?.document.customerPresent;

  const autoNotificationText = (
    <Text mt={4}>
      The customer will be notified directly by email of the referral outcome
      and, if accepted, will be able to complete the application signing
      process. Check the application summary screen to view the final status.
    </Text>
  );
  const referredMessage = (
    <Message variant='info'>
      <NovunaHeading as='h3' id='decision-refer-heading' mb={1}>
        Referred
      </NovunaHeading>
      Your application has been referred to the Underwriting Team for further
      consideration.
    </Message>
  );

  const appSummaryButton = (
    <Box mt={5}>
      <Button
        id='decision-refer-applicationsummary-button'
        isJumbo
        {...({
          to: routes.search.getApplicationById(applicationId)
        } as any)}
        as={ReactRouterLink}
        data-test-id='compass-application-decision-summary-screen'>
        Application summary
      </Button>
    </Box>
  );
  const customerMessage = () => {
    if (
      isIDVTRefer &&
      !isMailOrder &&
      !isOpenBankingRefer &&
      ((!!phoneNumber && sendTextMessage) || !!emailAddress)
    )
      return (
        <>
          <F2FReferralIDVTCard sendTextMessage={sendTextMessage} />
          {autoNotificationText}
          {appSummaryButton}
        </>
      );
    if (isOpenBankingRefer && !isMailOrder && !!emailAddress) {
      return (
        <>
          <F2FReferralOpenBankingCard />
          {autoNotificationText}
          {appSummaryButton}
        </>
      );
    }

    return (
      <div data-test-id='compass-application-decision-referred'>
        {referredMessage}
        <Text mt={4}>
          Please wait for an Underwriter to review the application and make a
          decision, they may request further information via a Note which will
          appear in the application summary.
        </Text>
        <Text mt={4}>
          If there is any information you would like to provide to the
          Underwriting Team in advance of their review you can add a note from
          the application summary screen.
        </Text>
        {autoNotificationText}
        {appSummaryButton}
      </div>
    );
  };

  return customerMessage();
};

export default connect(mapStateToProps, mapDispatchToProps)(DecisionRefer);
