/* eslint-disable react/jsx-pascal-case */
import { Styled, Text } from 'compass-design';
import { Address } from 'hitachi-retail-core';
import { deliveryAddressTypeValue } from 'hitachi-retail-core/build/enums';
import {
  CompassFeature,
  FeatureConfig
} from 'hitachi-retail-core/build/features/features';
import { DeepPartial } from 'hitachi-retail-core/build/utils/deepPartial';
import isEmpty from 'lodash/isEmpty';
import React, { Fragment } from 'react';
import { getAddressLines } from 'utils/address';
import getRowDetailsText, {
  DetailsRowTitle,
  GetRowDetailsText
} from './getRowDetailsText';

export const rows = [
  DetailsRowTitle.STATUS,
  DetailsRowTitle.RETAILER_NAME,
  DetailsRowTitle.RETAILER_NUMBER,
  DetailsRowTitle.CREATED,
  DetailsRowTitle.CREATED_BY,
  DetailsRowTitle.UPDATED,
  DetailsRowTitle.APPLICANT_NAME,
  DetailsRowTitle.ADDRESS_ROW,
  DetailsRowTitle.GOODS_DESCRIPTION,
  DetailsRowTitle.ORDER_REF,
  DetailsRowTitle.GOODS_AMOUNT,
  DetailsRowTitle.DEPOSIT_AMOUNT,
  DetailsRowTitle.LOAN_AMOUNT,
  DetailsRowTitle.INTEREST_PAYABLE,
  DetailsRowTitle.TOTAL_AMOUNT_PAYABLE,
  DetailsRowTitle.PRODUCT,
  DetailsRowTitle.APR,
  DetailsRowTitle.DEFERRAL_PERIOD,
  DetailsRowTitle.INSTALMENTS,
  DetailsRowTitle.MONTHLY_REPAYMENTS,
  DetailsRowTitle.COOLING_OFF_EXPIRY,
  DetailsRowTitle.CUSTOMER_PRESENT
];

export const Details: React.FunctionComponent<Omit<
  GetRowDetailsText,
  'title'
> & { enabledFeatures?: FeatureConfig }> = ({
  application,
  quote,
  enabledFeatures
}) => {
  const {
    DELIVERY_ADDRESS_ROW,
    ADDRESS_ROW,
    PENDING_GOODS_AMOUNT,
    PENDING_DEPOSIT_AMOUNT
  } = DetailsRowTitle;
  const {
    CurrentAddress,
    OtherAddress,
    ClickAndCollect
  } = deliveryAddressTypeValue;

  const rowsCopy = [...rows];
  const isRetailerApplicationHistoryEnabled =
    enabledFeatures &&
    enabledFeatures.has(CompassFeature.RETAILER_APPLICATION_HISTORY);
  if (
    isRetailerApplicationHistoryEnabled &&
    !rowsCopy.includes(DELIVERY_ADDRESS_ROW)
  ) {
    rowsCopy.push(DELIVERY_ADDRESS_ROW);
  }

  // FX-950 - Add pending goods amount if the value is not null
  if (!!quote.pendingGoodsAmount && !rowsCopy.includes(PENDING_GOODS_AMOUNT)) {
    rowsCopy.push(PENDING_GOODS_AMOUNT);
  }
  if (!!quote.pendingDeposit && !rowsCopy.includes(PENDING_DEPOSIT_AMOUNT)) {
    rowsCopy.push(PENDING_DEPOSIT_AMOUNT);
  }

  const { mainAddress } = application;
  const deliveryAddress =
    application.document?.deliveryDetails?.deliveryAddress;
  const goodsDelivered = application.document?.deliveryDetails?.goodsDelivered;

  const mapAddress = (address?: DeepPartial<Address>) => {
    if (!isEmpty(address)) {
      return getAddressLines(address as Address)
        .filter(line => line)
        .map((line, index) => (
          <Fragment key={index}>
            {line}
            <br />
          </Fragment>
        ));
    } else {
      return 'N/A';
    }
  };

  const tbody = rowsCopy.map(title => {
    let rowBody;
    switch (title) {
      case ADDRESS_ROW: {
        rowBody = mapAddress(mainAddress);
        break;
      }
      case DELIVERY_ADDRESS_ROW: {
        if (goodsDelivered === CurrentAddress) {
          rowBody = mapAddress(mainAddress);
        }
        if (
          goodsDelivered === OtherAddress ||
          goodsDelivered === ClickAndCollect
        ) {
          rowBody = mapAddress(deliveryAddress);
        }
        if (goodsDelivered === 'NO') {
          rowBody = 'Delivery not required';
        }
        break;
      }
      default: {
        rowBody = getRowDetailsText({
          title,
          application,
          quote
        });
      }
    }

    return (
      <Styled.tr key={title}>
        <Styled.td>
          <Text sx={{ color: 'monochrome.3' }}>{title}</Text>
        </Styled.td>
        <Styled.td>
          <Text
            sx={{ textAlign: 'right' }}
            id={`application-details-${title.toLowerCase().replace(' ', '')}`}
            data-test-id={title}>
            {rowBody}
          </Text>
        </Styled.td>
      </Styled.tr>
    );
  });

  return (
    <>
      <Styled.table>
        <tbody data-test-id='application-summary-application-details-table'>
          {tbody}
        </tbody>
      </Styled.table>
    </>
  );
};

export default Details;
