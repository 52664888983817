import { Address } from 'hitachi-retail-core';
import { call, put, takeLeading } from 'redux-saga/effects';
import { getType, isOfType } from 'typesafe-actions';
import { getAddresses } from '../../store/address/actions';
import { AddressAction } from '../../store/address/reducer';
import { AddressLookupService } from '../../services/addressLookupService';

export interface AddressByPostcodeSagaParams {
  addressLookupService: AddressLookupService;
}

export const getAddressByPostcodeSagaWatcher = ({
  addressLookupService
}: AddressByPostcodeSagaParams) =>
  function*() {
    yield takeLeading(
      getType(getAddresses.request),
      getAddressByPostcodeSaga({ addressLookupService })
    );
  };

export const getAddressByPostcodeSaga = ({
  addressLookupService
}: AddressByPostcodeSagaParams) =>
  function*(action: AddressAction) {
    if (isOfType(getType(getAddresses.request), action)) {
      const postcode = action.payload.postcode
        .replace(/\s+/g, '')
        .toUpperCase();
      const { meta } = action.payload;
      const { namespace } = meta;

      try {
        const addressResults: Address[] = yield call(
          addressLookupService.getAddresses,
          postcode
        );

        yield put(
          getAddresses.success({
            addressResults,
            meta: { namespace }
          })
        );
      } catch (error) {
        // Get the message from the API response if we can
        const errorMessage = error.message || 'Address service error';

        yield put(
          getAddresses.failure({
            error: new Error(errorMessage),
            meta: { namespace }
          })
        );
      }
    }
  };
