import { LoanAttribute } from 'compass-design';
import { Decimal } from 'hitachi-retail-core/build/utils/decimal';
import { ProductConfig } from 'hitachi-retail-core/build/api/productConfig';
import { EditLoanOffer } from 'hitachi-retail-core/build/services/tailoring/types';
import { LoanComparisonCardPreStringValues } from 'retailerApp/components/MixingDeck/getMixingDeckData';
import { EditLoanCardStateProps } from 'retailerApp/components/EditLoanCardState/EditLoanCardState';
import { formatPercent } from '../../../../utils/formatters';

interface GetReduceLoanDetails {
  reduceLoanOffer: EditLoanOffer;
  productConfig: ProductConfig;
  originalOffer: Omit<LoanComparisonCardPreStringValues, 'updatedItem'>;
}

const getReduceLoanDetails = ({
  reduceLoanOffer,
  productConfig,
  originalOffer
}: GetReduceLoanDetails): EditLoanCardStateProps | undefined => {
  const { offer, offered } = reduceLoanOffer;

  if (!offer || !offered) {
    return;
  }

  const { maximumGoodsAmount, deposit, maximumLoanAmount } = offer;

  const minDepositPercentage = new Decimal(productConfig.minDepositPercentage);

  return {
    updatedItem: LoanAttribute.reduceTotalCost,
    offerText: '',
    deposit: new Decimal(deposit),
    interestRate: originalOffer.interestRate,
    repaidOver: originalOffer.repaidOver,
    maximumLoanAmount: new Decimal(maximumLoanAmount),
    maximumGoodsAmount: new Decimal(maximumGoodsAmount),
    minDepositPercentage: formatPercent(minDepositPercentage.times(100)) || ''
  };
};

export default getReduceLoanDetails;
