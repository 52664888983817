import { Decimal } from 'hitachi-retail-core/build/utils/decimal';
import { renderMessage, validationMessages } from 'hitachi-retail-core';
import {
  getLoanAmount,
  getMinimumLoanAmount
} from 'hitachi-retail-core/build/finance';
import { getLoanParametersSchema } from 'hitachi-retail-core/build/schemas/quote';
import { LoanParameters } from 'retailerApp/utils/loanDetails/getLoanDetails';
import { formatCurrency } from 'utils/formatters';
import isNumber from 'utils/isNumber';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import { UserInputWithError } from '../EditLoanCardState';
import { ProductConfig } from 'hitachi-retail-core/build/api/productConfig';

interface TransformTotalCostError {
  message: string;
  minTotalCost: Decimal;
  maxTotalCost: Decimal;
  maxLoanAmount: Decimal;
  minLoanAmount: Decimal;
  totalCost: string;
  deposit: Decimal;
}

const transformTotalCostError = ({
  message,
  minTotalCost,
  maxTotalCost,
  maxLoanAmount,
  minLoanAmount,
  totalCost,
  deposit
}: TransformTotalCostError): string => {
  if (
    message ===
    renderMessage(validationMessages.INVALID_GOODS_AMOUNT_MINIMUM, {
      minGoodsAmount: formatCurrency(minTotalCost)
    })
  ) {
    return `Must be ${formatCurrency(minTotalCost)} or higher`;
  } else if (
    message ===
    renderMessage(validationMessages.INVALID_GOODS_AMOUNT_MAXIMUM, {
      maxGoodsAmount: formatCurrency(maxTotalCost)
    })
  ) {
    return `Must be ${formatCurrency(maxTotalCost)} or lower`;
  } else if (
    message ===
    renderMessage(validationMessages.INVALID_LOAN_AMOUNT, {
      maxLoanAmount: formatCurrency(maxLoanAmount),
      minLoanAmount: formatCurrency(minLoanAmount)
    })
  ) {
    const loanAmount = getLoanAmount({
      goodsAmount: new Decimal(totalCost),
      deposit: new Decimal(deposit)
    });
    if (maxLoanAmount.lessThan(loanAmount)) {
      return `Must be ${formatCurrency(maxTotalCost)} or lower`;
    }
    return `Must be ${formatCurrency(minTotalCost)} or higher`;
  } else {
    return message;
  }
};

interface TransformDepositError {
  message: string;
  minDeposit: Decimal;
}

const transformDepositError = ({
  message,
  minDeposit
}: TransformDepositError): string => {
  if (
    message ===
    renderMessage(validationMessages.INVALID_DEPOSIT_MINIMUM, {
      minDeposit: formatCurrency(minDeposit)
    })
  ) {
    return `Must be ${formatCurrency(minDeposit)} or higher`;
  } else {
    return message;
  }
};

interface CheckForErrors {
  loanParameters: LoanParameters & {
    maximumGoodsAmount: Decimal;
    maximumLoanAmount: Decimal;
    originalLoanAmount: Decimal;
    originalDeposit: Decimal;
  };
  deposit: UserInputWithError;
  totalCost: UserInputWithError;
  editingDeposit: boolean;
  isIncreaseLoan: boolean;
  setTotalCost: ({ value, error }: UserInputWithError) => void;
  setDeposit: ({ value, error }: UserInputWithError) => void;
  isLoanAmend?: boolean;
  productConfig?: ProductConfig;
}

export const checkForErrors = ({
  loanParameters: {
    maxLoanAmountForProduct,
    minLoanAmountForProduct,
    minDepositAmountForProduct,
    minDepositPercentageForProduct,
    maximumLoanAmount,
    originalLoanAmount
  },
  totalCost,
  deposit,
  isIncreaseLoan,
  setTotalCost,
  setDeposit,
  isLoanAmend,
  productConfig
}: CheckForErrors) => {
  let totalCostError = '';
  let depositError = '';
  let minLoanAmount: Decimal = new Decimal(0);

  if (isLoanAmend) {
    minLoanAmount = new Decimal(minLoanAmountForProduct);
  } else {
    minLoanAmount = getMinimumLoanAmount({
      originalLoanAmount,
      minLoanAmountForProduct,
      isIncreaseLoan
    });
  }
  const nonEmptyDeposit = new Decimal(
    deposit.value === '' ? '0' : deposit.value
  );

  // The minimum goods amount from the Product Config (configured on retailer-by-retailer basis)
  // E.g. A retailer only allows loans that are larger than £500,00.
  const productConfigMinGoodsAmount =
    productConfig?.minGoodsAmount ?? undefined;

  // The minimum goods amount that is calculated for an individual loan.
  const minGoodsAmount = new Decimal(minLoanAmount).plus(nonEmptyDeposit);

  // The minimum goods amount therefore should be the higher of two numbers
  // Weirdly TS type for 'productConfigMinGoodsAmount' is 'Decimal | undefined' however it is actually a string.
  // I've added a check for an undefined value followed by a '.toString()' call to sort the TypeScript errors.
  const minGoodsAmountToCheck =
    productConfigMinGoodsAmount &&
    new Decimal(productConfigMinGoodsAmount.toString())?.greaterThan(
      minGoodsAmount
    )
      ? productConfigMinGoodsAmount
      : minGoodsAmount;

  const maxLoanAmount = new Decimal(maxLoanAmountForProduct).lessThan(
    maximumLoanAmount
  )
    ? maxLoanAmountForProduct
    : maximumLoanAmount;

  const maxGoodsAmount = new Decimal(maxLoanAmount).plus(nonEmptyDeposit);

  let minDeposit = minDepositAmountForProduct;
  if (isNumber(totalCost.value) && isNumber(deposit.value)) {
    const currentDepositPercentage = new Decimal(deposit.value).dividedBy(
      totalCost.value
    );

    if (currentDepositPercentage.lessThan(minDepositPercentageForProduct)) {
      const minValue = minDepositPercentageForProduct.mul(totalCost.value);
      minDeposit = minValue > minDeposit ? minValue : minDeposit;
    }
  }

  const schema = yup.object(
    getLoanParametersSchema({
      minGoodsAmount: minGoodsAmountToCheck,
      minDeposit,
      maxLoanAmount,
      minLoanAmount,
      maxGoodsAmount
    })
  );

  try {
    schema.validateSync(
      { goodsAmount: totalCost.value, deposit: deposit.value },
      { abortEarly: false }
    );
  } catch (errors) {
    if (errors instanceof ValidationError) {
      errors.inner.forEach(({ path, message }: ValidationError) => {
        if (path === 'deposit' && !depositError) {
          depositError = transformDepositError({
            message,
            minDeposit
          });
        } else if (path === 'goodsAmount' && !totalCostError) {
          if (totalCost.value !== '') {
            totalCostError = transformTotalCostError({
              message,
              minTotalCost: minGoodsAmount,
              maxTotalCost: maxGoodsAmount,
              minLoanAmount,
              maxLoanAmount,
              totalCost: totalCost.value,
              deposit: nonEmptyDeposit
            });
          } else {
            totalCostError = message;
          }
        }
      });
    }
  }

  setTotalCost({ ...totalCost, error: totalCostError });
  setDeposit({ ...deposit, error: depositError });
  return totalCostError.length > 0;
};
