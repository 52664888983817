import React from 'react';
import { ObjectFieldTemplateProps } from 'react-jsonschema-form';

const ObjectFieldTemplate: React.FunctionComponent<ObjectFieldTemplateProps> = props => (
  <React.Fragment>
    {props.properties ? props.properties.map(prop => prop.content) : null}
  </React.Fragment>
);

export default ObjectFieldTemplate;
