import { LoanAttribute } from 'compass-design';
import { Decimal } from 'hitachi-retail-core/build/utils/decimal';
import { getTotalAmountPayable } from 'hitachi-retail-core/build/finance';
import { IncreaseDepositOffer } from 'hitachi-retail-core/build/services/tailoring/types';
import { DeepPartial } from 'hitachi-retail-core/build/utils/deepPartial';
import {
  getIncreaseDepositOptionText,
  getIncreaseDepositText,
  LoanComparisonCardPreStringValues
} from 'retailerApp/components/MixingDeck/getMixingDeckData';
import { NewOfferComparisonCardProps } from 'retailerApp/components/MixingDeck/MixingDeck';

interface GetIncreaseDepositDetails {
  originalOffer: Omit<LoanComparisonCardPreStringValues, 'updatedItem'>;
  increaseDepositOffer: DeepPartial<IncreaseDepositOffer>;
}

const getIncreaseDepositDetails = ({
  originalOffer,
  increaseDepositOffer
}: GetIncreaseDepositDetails): NewOfferComparisonCardProps | undefined => {
  const { offer, offered } = increaseDepositOffer;

  if (!offer || !offered) {
    return;
  }

  const { totalRepaymentAmount, minimumDeposit, monthlyRepayment } = offer;

  if (!totalRepaymentAmount || !minimumDeposit || !monthlyRepayment) {
    return;
  }

  const amountBorrowed = originalOffer.totalCost.minus(minimumDeposit);
  const totalRepayable = new Decimal(totalRepaymentAmount);

  const totalAmountPayable = getTotalAmountPayable({
    repaymentAmount: new Decimal(monthlyRepayment!),
    instalments: originalOffer.repaidOver,
    deposit: new Decimal(minimumDeposit)
  });

  const interestPayable = originalOffer.interestRate.isZero()
    ? new Decimal(0)
    : totalRepayable.minus(amountBorrowed);

  return {
    offer: {
      ...originalOffer,
      deposit: new Decimal(minimumDeposit),
      monthlyRepayment: new Decimal(monthlyRepayment),
      totalRepayable,
      totalAmountPayable,
      amountBorrowed,
      interestPayable,
      updatedItem: LoanAttribute.deposit
    },
    offerText: getIncreaseDepositText({
      currentDeposit: originalOffer.deposit,
      minimumDeposit: new Decimal(minimumDeposit)
    }),
    optionText: getIncreaseDepositOptionText({
      currentDeposit: originalOffer.deposit,
      minimumDeposit: new Decimal(minimumDeposit)
    })
  };
};

export default getIncreaseDepositDetails;
