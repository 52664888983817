import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

export type ScrollToTopProps = RouteComponentProps;
export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

class ScrollToTop extends React.Component<ScrollToTopProps> {
  public componentDidUpdate(prevProps: ScrollToTopProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      scrollToTop();
    }
  }

  public render() {
    return this.props.children || null;
  }
}

export default withRouter(ScrollToTop);
