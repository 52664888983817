import React from 'react';
import FeatureToggle from '../containers/meta/FeatureToggle';
import EndAndSendButton from './containers/EndAndSendButton';
import { EndAndSendButtonOwnProps } from './components/EndAndSendButton';
import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import { ReactNode } from 'react';

export type ToggledEndAndSendProps = EndAndSendButtonOwnProps & {
  enabledBy: CompassFeature;
  fallbackComponent?: ReactNode;
};

const ToggledEndAndSend: React.FC<ToggledEndAndSendProps> = ({
  enabledBy,
  fallbackComponent,
  ...buttonProps
}) => (
  <FeatureToggle feature={enabledBy} fallbackComponent={fallbackComponent}>
    {/* This is connected to Redux */}
    <EndAndSendButton {...buttonProps} />
  </FeatureToggle>
);

ToggledEndAndSend.defaultProps = {
  disabled: false
};

export default ToggledEndAndSend;
