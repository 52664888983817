import {
  BankAccountCheckRequest,
  BankAccountCheckResponse
} from 'hitachi-retail-core/build/api/bankCheck';
import { selectBankAccountNumber } from 'hitachi-retail-core/build/selectors/selectBankAccountNumber';
import { selectBankAccountSortCode } from 'hitachi-retail-core/build/selectors/selectBankAccountSortCode';
import { call, put, select, takeLeading } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { ApplicationsService } from '../../services/applicationsService';
import { BankAccountCheckService } from '../../services/bankCheck';
import { bankAccountCheck } from '../../store/application/actions';
import { selectApplicationId } from '../../store/save/reducer';
import { getSaveApplicationSaga } from '../saveApplication/saga';
import { selectApplicationDocument } from '../../store/application/selectors';

export interface BankAccountCheckSagaParams {
  bankAccountCheckService: BankAccountCheckService;
  saveApplicationSaga: () => void;
}

export const getBankAccountCheckSaga = ({
  bankAccountCheckService,
  saveApplicationSaga
}: BankAccountCheckSagaParams) =>
  function*() {
    try {
      yield call(saveApplicationSaga);
    } catch (err) {
      //
    }

    const applicationId = yield select(selectApplicationId);
    const document = yield select(selectApplicationDocument);
    const sortCode = yield selectBankAccountSortCode(document);
    const accountNumber = yield selectBankAccountNumber(document);

    const payload: BankAccountCheckRequest = {
      id: applicationId,
      sortCode,
      accountNumber
    };

    try {
      const response: BankAccountCheckResponse = yield call(
        bankAccountCheckService.updateBankDetails,
        payload
      );
      yield put(bankAccountCheck.success(response));
    } catch (err) {
      yield put(bankAccountCheck.failure(err));
    }

    try {
      yield call(saveApplicationSaga);
    } catch (err) {
      //
    }
  };

export interface BankAccountCheckSagaWatcherParams {
  bankAccountCheckService: BankAccountCheckService;
  applicationsService: Pick<ApplicationsService, 'saveApplication'>;
}

export const getBankAccountCheckSagaWatcher = ({
  bankAccountCheckService,
  applicationsService
}: BankAccountCheckSagaWatcherParams) =>
  function*() {
    const saveApplicationSaga = getSaveApplicationSaga({ applicationsService });
    yield takeLeading(
      getType(bankAccountCheck.request),
      getBankAccountCheckSaga({ bankAccountCheckService, saveApplicationSaga })
    );
  };
