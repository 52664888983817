import { TextField } from 'compass-design';
import React, { Ref } from 'react';
import { EnumOption, WidgetProps } from 'react-jsonschema-form';

export type BaseInputProps = WidgetProps & {
  maxlength?: number;
  name?: string;
  type?: string;
  rawErrors?: string[];
  testId?: string;
  inputRef?: Ref<HTMLInputElement>;
  options: {
    emptyValue?: string;
    enumOptions?: EnumOption[];
    enumDisabled?: string[];
    inputType?: string;
    [name: string]: any;
  };
};

const BaseInput: React.FunctionComponent<BaseInputProps> = props => {
  const {
    value,
    autofocus,
    maxlength,
    readonly,
    onBlur,
    onFocus,
    onChange,
    id,
    options,
    rawErrors,
    inputRef,
    schema,
    label,
    testId,
    ...rest
  } = props;

  const hasError = rawErrors.length > 0;

  return (
    <TextField
      {...rest}
      id={id}
      ref={inputRef}
      autoFocus={autofocus}
      maxLength={maxlength}
      readOnly={readonly}
      value={typeof value === 'undefined' ? '' : value}
      onChange={
        onChange &&
        (({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
          onChange(value === '' ? options.emptyValue : value))
      }
      onBlur={
        onBlur &&
        (({ target: { value } }: React.FocusEvent<HTMLInputElement>) =>
          onBlur(id, value))
      }
      onFocus={
        onFocus &&
        (({ target: { value } }: React.FocusEvent<HTMLInputElement>) =>
          onFocus(id, value))
      }
      tone={hasError ? 'negative' : 'neutral'}
      short={options.shortLength === true}
    />
  );
};

BaseInput.defaultProps = {
  type: 'text',
  rawErrors: []
};

export default BaseInput;
