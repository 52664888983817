import { NovunaHeading } from 'compass-design';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import { IncompleteFinanceApplication } from 'hitachi-retail-core/build/schemas/financeApplication';
import { TailoringOptionSelected } from 'hitachi-retail-core/build/services/tailoring/types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import DecisionDeclinePicker from 'retailerApp/components/DecisionDeclinePicker/DecisionDeclinePicker';
import DecisionLoading from 'retailerApp/components/DecisionLoading';
import DecisionAcceptPicker from 'retailerApp/components/DecisionAcceptPicker/DecisionAcceptPicker';
import { AffordabilityDeclineProps } from 'retailerApp/utils/loanDetails/getAffordabilityDecline';
import { LoanDetails } from 'retailerApp/utils/loanDetails/getLoanDetails';
import DecisionError, {
  DecisionErrorEnum
} from './components/DecisionError/DecisionError';
import DecisionRefer from './components/DecisionRefer/DecisionRefer';
import { AffordabilityAcceptProps } from 'retailerApp/utils/loanDetails/getAffordabilityAccept';

const declinedPdfName = 'declined.pdf';

export interface ApplicationDecisionPropsFromState {
  decisionApplicationStatus?: ApplicationStatus;
  decisionError: DecisionErrorEnum;
  affordabilityDecline?: Pick<
    AffordabilityDeclineProps,
    | 'loanDetails'
    | 'maxInstalmentAmount'
    | 'preSetTailoringOption'
    | 'radioOptions'
  >;
  affordabilityAccept?: Pick<
    AffordabilityAcceptProps,
    'loanDetails' | 'preSetTailoringOption' | 'radioOptions'
  >;
  loanDetails?: LoanDetails;
  pdfUrl?: string;
  pdfBlob?: Blob;
  applicationId: string;
  regulatedApplication?: boolean;
  productIsDeferred?: boolean;
  productEarlySettlementFee?: boolean;
  customerPresent: boolean;
  tailoringExpiryDate?: string;
  isLoanAmend: boolean;
}

export interface ApplicationDecisionPropsFromDispatch {
  onApplicationAbort: () => void;
  onRegulatedContinue: () => void;
  onUnRegulatedContinue: () => void;
  onDecisionRetry: () => void;
  onDownloadDeclinedLetter: (id: string) => void;
  onConfirmTailoringUpdate: ({
    document,
    tailoringOptionSelected
  }: {
    document: IncompleteFinanceApplication;
    tailoringOptionSelected?: TailoringOptionSelected;
  }) => void;
  onAcceptOriginalOffer: ({
    document
  }: {
    document: IncompleteFinanceApplication;
  }) => void;
}

export type ApplicationDecisionProps = ApplicationDecisionPropsFromState &
  ApplicationDecisionPropsFromDispatch;

class ApplicationDecision extends React.Component<ApplicationDecisionProps> {
  public fileDownloadLink: React.RefObject<HTMLAnchorElement>;

  constructor(props: ApplicationDecisionProps) {
    super(props);
    this.fileDownloadLink = React.createRef<HTMLAnchorElement>();
  }

  public componentDidUpdate(prevProps: ApplicationDecisionPropsFromState) {
    if (this.props.pdfUrl !== prevProps.pdfUrl) {
      if (this.fileDownloadLink.current) {
        if (navigator.msSaveOrOpenBlob) {
          // For Internet Explorer
          navigator.msSaveOrOpenBlob(this.props.pdfBlob, declinedPdfName);
        } else {
          this.fileDownloadLink.current.click();
        }
      }
    }
  }

  public render() {
    const {
      decisionApplicationStatus,
      decisionError,
      affordabilityDecline,
      affordabilityAccept,
      pdfUrl,
      applicationId,
      regulatedApplication,
      productIsDeferred,
      productEarlySettlementFee,
      tailoringExpiryDate,
      onApplicationAbort,
      onRegulatedContinue,
      onUnRegulatedContinue,
      onDecisionRetry,
      onDownloadDeclinedLetter,
      onConfirmTailoringUpdate,
      onAcceptOriginalOffer,
      isLoanAmend
    } = this.props;

    const Header = (
      <>
        <Helmet>
          <title>Decision - CreditMaster3</title>
        </Helmet>
        <NovunaHeading as='h1' id='decision-heading' mb={3}>
          Decision
        </NovunaHeading>
      </>
    );

    if (decisionError) {
      return (
        <>
          {Header}
          <DecisionError
            decisionError={decisionError}
            onApplicationAbort={onApplicationAbort}
            onDecisionRetry={onDecisionRetry}
          />
        </>
      );
    }

    const affordabilityDeclineProps = affordabilityDecline
      ? {
          ...affordabilityDecline,
          onConfirmTailoringUpdate
        }
      : undefined;

    const affordabilityAcceptProps = affordabilityAccept
      ? {
          ...affordabilityAccept,
          onConfirmTailoringUpdate,
          onAcceptOriginalOffer
        }
      : undefined;

    const decisionAcceptProps = {
      regulatedApplication,
      productIsDeferred,
      productEarlySettlementFee,
      onRegulatedContinue,
      onUnRegulatedContinue
    };

    let DecisionComponent;

    switch (decisionApplicationStatus) {
      case undefined:
        return <DecisionLoading />;
      case ApplicationStatus.decision_accept:
        DecisionComponent = (
          <DecisionAcceptPicker
            tailoringExpiryDate={tailoringExpiryDate}
            affordabilityAccept={affordabilityAcceptProps}
            decisionAccept={decisionAcceptProps}
            isLoanAmend={isLoanAmend}
          />
        );
        break;
      case ApplicationStatus.decision_decline:
        DecisionComponent = (
          <DecisionDeclinePicker
            affordabilityDecline={affordabilityDeclineProps}
            downloadLetter={{
              applicationId,
              fileDownloadLink: this.fileDownloadLink,
              pdfUrl,
              declinedPdfName,
              onDownloadDeclinedLetter
            }}
            isLoanAmend={isLoanAmend}
          />
        );
        break;
      case ApplicationStatus.decision_refer:
        DecisionComponent = <DecisionRefer applicationId={applicationId} />;
        break;
    }

    return (
      <>
        {Header}
        {DecisionComponent}
      </>
    );
  }
}

export default ApplicationDecision;
