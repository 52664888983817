import { Box, TertiaryLink, TertiaryLinkDirection } from 'compass-design';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedEnhancedBranch,
  setSelectedEnhancedHeadOffice
} from 'store/enhancedUsers/actions';
import {
  selectSelectedEnhancedBranch,
  selectSelectedEnhancedBroker,
  selectSelectedEnhancedHeadOffice
} from 'store/enhancedUsers/selectors';

const BackBreadcrumb = () => {
  const dispatch = useDispatch();
  const selectedBroker = useSelector(selectSelectedEnhancedBroker);
  const selectedHeadOffice = useSelector(selectSelectedEnhancedHeadOffice);
  const selectedBranch = useSelector(selectSelectedEnhancedBranch);

  return (
    <Box sx={{ display: ['block', null, 'flex'] }}>
      {selectedBroker && (selectedHeadOffice || selectedBranch) && (
        <TertiaryLink
          direction={TertiaryLinkDirection.BACKWARDS}
          text={selectedBroker.name}
          onClick={() => {
            if (selectedHeadOffice) {
              dispatch(setSelectedEnhancedHeadOffice(undefined));
            } else if (selectedBranch) {
              dispatch(setSelectedEnhancedBranch(undefined));
            }
          }}
        />
      )}
      {selectedHeadOffice && selectedBranch && (
        <Box ml={'36px'}>
          <TertiaryLink
            direction={TertiaryLinkDirection.BACKWARDS}
            text={selectedHeadOffice.name}
            onClick={() => dispatch(setSelectedEnhancedBranch(undefined))}
          />
        </Box>
      )}
    </Box>
  );
};

export default BackBreadcrumb;
