import { Reducer } from 'redux';
import { ActionType, getType, createStandardAction } from 'typesafe-actions';
import { resetStore, ResetStoreAction } from '../actions';
import { signApplication } from './actions';

// Simple action specific to this store to tell that the request came back but no action is required
/**
 * Simple action specific to this store to tell that the request came back but no action is requird.
 * This action is used for re-decision to reset the "processing" state
 */
const SIGN_APPLICATION_REQUEST_FINISHED = 'SIGN_APPLICATION_REQUEST_FINISHED';
export const signApplicationActionFinished = createStandardAction(
  SIGN_APPLICATION_REQUEST_FINISHED
)();

export type SignApplicationAction = ActionType<typeof signApplication>;
export type SignApplicationActionFinished = ActionType<
  typeof signApplicationActionFinished
>;

/*
Note: errorMessage and processing are both never truthy.
If processing then there is no errorMessage.
If not processing then there may be an errorMessage
 */
export interface State {
  errorMessage?: string;
  processing: boolean;
}

export type Action =
  | SignApplicationAction
  | ResetStoreAction
  | SignApplicationActionFinished;

export const initialState: State = { processing: false };

export const reducer: Reducer<State, Action> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(signApplication.request): {
      return {
        ...initialState,
        processing: true
      };
    }

    case getType(signApplication.failure): {
      return {
        ...state,
        errorMessage: action.payload.message,
        processing: false
      };
    }

    case getType(resetStore) || getType(signApplicationActionFinished): {
      return initialState;
    }

    case getType(signApplicationActionFinished): {
      return initialState;
    }

    default:
      return state;
  }
};
