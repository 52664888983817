import { ScreenReaderLabel } from 'compass-design';
import React from 'react';

interface SwitchProps {
  isChecked: boolean;
  onChange: () => void;
}

const Switch = ({ isChecked, onChange }: SwitchProps) => (
  <label className='toggle-switch'>
    <ScreenReaderLabel label='Toggle to switch between account active and account not active' />
    <input type='checkbox' checked={isChecked} onChange={onChange} />
    <span className='switch' />
  </label>
);

export default Switch;
