import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { resetStore, ResetStoreAction } from '../actions';
import { RootState } from '../index';
import { fetchActiveApplication } from '../previousApplicationDetail/actions';
import { saveApplication, setApplicationId } from './actions';

export type SaveApplicationAction = ActionType<typeof saveApplication>;
export type SetApplicationIdAction = ActionType<typeof setApplicationId>;
export type FetchActiveApplicationAction = ActionType<
  typeof fetchActiveApplication
>;

export interface State {
  applicationId?: string;
  errorMessage?: string;
  saving?: boolean;
}

export const initialState: State = {
  saving: false
};

export const selectApplicationId = ({ save: { applicationId } }: RootState) =>
  applicationId;

export const reducer: Reducer<
  State,
  | SaveApplicationAction
  | ResetStoreAction
  | SetApplicationIdAction
  | FetchActiveApplicationAction
> = (state = initialState, action) => {
  switch (action.type) {
    case getType(saveApplication.request): {
      return {
        ...initialState,
        saving: true,
        applicationId: state.applicationId
      };
    }

    case getType(saveApplication.success):
    case getType(setApplicationId): {
      return { ...initialState, applicationId: action.payload.id };
    }

    case getType(saveApplication.failure): {
      return { ...state, errorMessage: action.payload.message, saving: false };
    }

    case getType(fetchActiveApplication.success): {
      return { ...state, applicationId: action.payload.id };
    }

    case getType(resetStore): {
      return initialState;
    }

    default:
      return state;
  }
};
