import { useFormikContext } from 'formik';
import { useEffect } from 'react';

export const flattenNestedFields = (
  errors: { [key: string]: any },
  prefix = ''
): Array<string> =>
  Object.keys(errors).reduce((res, field) => {
    if (Array.isArray(errors[field])) {
      return res;
    }

    if (typeof errors[field] === 'object' && errors[field] !== null) {
      return [
        ...res,
        ...flattenNestedFields(errors[field], `${prefix}${field}.`)
      ];
    }

    return [...res, prefix + field];
  }, [] as Array<string>);

const ScrollToError = () => {
  const { errors, isSubmitting, isValidating } = useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      const fields = flattenNestedFields(errors);
      if (fields.length > 0) {
        const pos = fields.reduce((min, name) => {
          const labelElement = document.querySelector(`[for|="${name}"]`);
          const element = labelElement ?? document.getElementById(name);

          // store its top if it's the highest up the page
          if (element) {
            const { top } = element.getBoundingClientRect();

            return Math.min(min, top);
          }

          return min;
        }, 0);

        if (pos < 0) {
          window.scrollBy(0, pos - 50);
        }
      }
    }
  }, [errors, isSubmitting, isValidating]);

  return null;
};

export default ScrollToError;
