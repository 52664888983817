import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'store';
import { selectEnabledFeatures } from 'store/config/selectors';
import { getApplicationStartRoute } from '../routes';

const Timeout = () => {
  const enabledFeatures = useSelector(selectEnabledFeatures);

  return (
    <div data-test-id='compass-timeout'>
      <h1>The signing timed out</h1>
      <Link to={getApplicationStartRoute(enabledFeatures)}>Go home</Link>
    </div>
  );
};

export default Timeout;
