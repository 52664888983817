export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const SESSION_CHECK_REQUEST = 'SESSION_CHECK_REQUEST';
export const SESSION_CHECK_SUCCESS = 'SESSION_CHECK_SUCCESS';
export const SESSION_CHECK_FAILURE = 'SESSION_CHECK_FAILURE';

export const SET_PASSWORD_REQUEST = 'SET_PASSWORD_REQUEST';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_FAILURE = 'SET_PASSWORD_FAILURE';
export const SET_PASSWORD_REDIRECT_TO_LOGIN = 'SET_PASSWORD_REDIRECT_TO_LOGIN';

export const SEND_PASSWORD_RESET_CODE_REQUEST =
  'SEND_PASSWORD_RESET_CODE_REQUEST';
export const SEND_PASSWORD_RESET_CODE_SUCCESS =
  'SEND_PASSWORD_RESET_CODE_SUCCESS';
export const SEND_PASSWORD_RESET_CODE_FAILURE =
  'SEND_PASSWORD_RESET_CODE_FAILURE';

export const CONFIRM_FORGOT_PASSWORD_REQUEST =
  'CONFIRM_FORGOT_PASSWORD_REQUEST';
export const CONFIRM_FORGOT_PASSWORD_SUCCESS =
  'CONFIRM_FORGOT_PASSWORD_SUCCESS';
export const CONFIRM_FORGOT_PASSWORD_FAILURE =
  'CONFIRM_FORGOT_PASSWORD_FAILURE';

export const DIRECTAPP_LOGIN_REQUEST = 'DIRECTAPP_LOGIN_REQUEST';
export const DIRECTAPP_LOGIN_SUCCESS = 'DIRECTAPP_LOGIN_SUCCESS';
export const DIRECTAPP_LOGIN_FAILURE = 'DIRECTAPP_LOGIN_FAILURE';

export const USER_POLICY_REQUEST = 'USER_POLICY_REQUEST';
export const USER_POLICY_SUCCESS = 'USER_POLICY_SUCCESS';
export const USER_POLICY_FAILURE = 'USER_POLICY_FAILURE';
