import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { RootState } from '../../../store';
import { resetStore, ResetStoreAction } from '../../../store/actions';
import {
  applicantAuthorization,
  applicantTokenExchange,
  saveCodeVerifier,
  savePreAuthApplicationId,
  savePreAuthPath
} from './actions';
import { AsyncStatus } from '../AsyncStatus';

export interface State {
  accessToken?: string;
  authorizationRequestState: AsyncStatus;
  tokenExchangeState: AsyncStatus;
  preAuthPath?: string | null;
  codeVerifier?: string;
  applicationId?: string;
  id?: string;
}

export type TokenExchangeAction = ActionType<typeof applicantTokenExchange>;

export type ApplicantAuthorizationRequestAction = ActionType<
  typeof applicantAuthorization
>;

export type SavePreAuthPathAction = ActionType<typeof savePreAuthPath>;
export type SavePreAuthApplicationIdAction = ActionType<
  typeof savePreAuthApplicationId
>;
export type SaveCodeVerifierAction = ActionType<typeof saveCodeVerifier>;

export type ApplicantAuthAction =
  | TokenExchangeAction
  | ApplicantAuthorizationRequestAction
  | SavePreAuthPathAction
  | SavePreAuthApplicationIdAction
  | SaveCodeVerifierAction
  | ResetStoreAction;

export const initialState: State = {
  tokenExchangeState: AsyncStatus.Default,
  authorizationRequestState: AsyncStatus.Default
};

export const reducer: Reducer<State, ApplicantAuthAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    // Token Exchange
    case getType(applicantTokenExchange.request): {
      return { ...state, tokenExchangeState: AsyncStatus.Loading };
    }
    case getType(applicantTokenExchange.success): {
      return {
        ...state,
        accessToken: action.payload.accessToken,
        tokenExchangeState: AsyncStatus.Success
      };
    }
    case getType(applicantTokenExchange.failure): {
      return {
        ...state,
        tokenExchangeState: AsyncStatus.Failure
      };
    }
    // Authorization
    case getType(applicantAuthorization.request): {
      return {
        ...state,
        id: action.payload.id,
        authorizationRequestState: AsyncStatus.Loading
      };
    }
    case getType(applicantAuthorization.success): {
      return { ...state, authorizationRequestState: AsyncStatus.Success };
    }
    case getType(applicantAuthorization.failure): {
      return { ...state, authorizationRequestState: AsyncStatus.Failure };
    }
    // Persistance
    case getType(savePreAuthPath): {
      return {
        ...state,
        preAuthPath: action.payload.preAuthPath
      };
    }
    case getType(savePreAuthApplicationId): {
      return {
        ...state,
        applicationId: action.payload.applicationId
      };
    }
    case getType(saveCodeVerifier): {
      return {
        ...state,
        codeVerifier: action.payload.codeVerifier
      };
    }
    // Timeout
    case getType(resetStore): {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export const selectApplicantAccessToken = (state: RootState) =>
  state.applicantAuth.accessToken;

export const selectCodeVerifier = (state: RootState) =>
  state.applicantAuth.codeVerifier;

export const selectPreAuthPath = (state: RootState) =>
  state.applicantAuth.preAuthPath;

export const selectPreAuthApplicationId = (state: RootState) =>
  state.applicantAuth.applicationId;

export const selectTokenExchangeState = (state: RootState) =>
  state.applicantAuth.tokenExchangeState;

export const selectApplicantAuthorizationState = (state: RootState) =>
  state.applicantAuth.authorizationRequestState;
