import { Skeleton } from 'compass-design';
import React, { useEffect, useRef } from 'react';

interface GlobalPayHppBaseProps {
  onComplete: Function;
  onError: Function;
}

interface GlobalPayHppLoadingProps {
  loading: true;
}

interface GlobalPayHppLoadedProps {
  loading: false;
  url: string;
}

type GlobalPayHppProps = GlobalPayHppBaseProps &
  (GlobalPayHppLoadingProps | GlobalPayHppLoadedProps);

const GlobalPayHpp: React.FC<GlobalPayHppProps> = props => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (props.loading) return;

    const { url, onError, onComplete } = props;

    const iframe = iframeRef.current!;
    const listener = (event: MessageEvent) => {
      // We can tell if the events are coming from the HPP if they originate
      // from the globalpayments domain
      if (event.origin !== new URL(url).origin) {
        return;
      }

      let eventData;

      try {
        eventData = JSON.parse(event.data);
      } catch {
        onError(event);
        return;
      }

      // Resize events have a top-level iframe member
      if (eventData.iframe) {
        iframe.style.height = eventData.iframe.height;
        iframe.style.visibility = 'visible';
      } else {
        onComplete(eventData);
      }
    };

    window.addEventListener('message', listener);

    // Ensure we don't trigger a reload when the url hasn't changed
    if (iframe.src !== url) {
      iframe.src = url;
    }

    // Remove the event listener during re-render and unload cleanup phase
    return () => window.removeEventListener('message', listener);
  }, [iframeRef, props]);

  return props.loading ? (
    <Skeleton width='100%' height='450px' />
  ) : (
    <iframe
      // Hide iframe by default
      style={{
        visibility: 'hidden',
        width: '100%'
      }}
      scrolling='no'
      data-test-id='online-deposit-iframe'
      ref={iframeRef}
      title='HPP'
      id='payment-form'
    />
  );
};

export default GlobalPayHpp;
