import classNames from 'classnames';
import FinanceProduct from 'components/form/fields/FinanceProduct';
import GoodsDescription from 'components/form/fields/GoodsDescriptionField';
import LoanParameters from 'components/form/fields/LoanParameters';
import LoanRepayment from 'components/form/fields/LoanRepayment';
import AddressFinder from 'containers/AddressFinder';
import CustomerPresent from 'containers/form/fields/CustomerPresent';
import { transformErrors } from 'form/helpers';
import Form, { FormProps } from 'react-jsonschema-form';
import DescriptionField from './fields/DescriptionField';
import MultiAddress from './fields/MultiAddress';
import ObjectFieldTemplate from './fields/ObjectField';
import TitleField from './fields/TitleField';
import FieldTemplate from './FieldTemplate';
import BaseInput from './widgets/BaseInput';
import DateWidget from './widgets/DateWidget';
import HiddenWidget from './widgets/HiddenWidget';
import RadioWidget from './widgets/RadioWidget';
import React from 'react';

const CompassForm: React.FunctionComponent<FormProps<any>> = props => {
  const customFields = {
    TitleField,
    DescriptionField,
    MultiAddress,
    AddressFinder,
    CustomerPresent,
    FinanceProduct,
    LoanParameters,
    LoanRepayment,
    GoodsDescription
  };

  const customWidgets = {
    BaseInput,
    DateWidget,
    HiddenWidget,
    RadioWidget
  };

  return (
    <Form
      fields={customFields}
      widgets={customWidgets}
      FieldTemplate={FieldTemplate}
      ObjectFieldTemplate={ObjectFieldTemplate}
      noHtml5Validate
      autoComplete='off'
      showErrorList={false}
      transformErrors={transformErrors}
      {...props}
      className={classNames('compass-form', props.className)}
    />
  );
};

export default CompassForm;
