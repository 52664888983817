import {
  GlobalStyle,
  Layout,
  novunaTheme,
  ThemeProvider,
  NovunaPBFHeader,
  NovunaPBFFooter
} from 'compass-design';
import SilkaLight from 'compass-design/assets/fonts/Silka-Light.woff';
import SilkaRegular from 'compass-design/assets/fonts/Silka-Regular.woff';
import SilkaSemiBold from 'compass-design/assets/fonts/Silka-SemiBold.woff';
import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import React, { Fragment, memo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import NoMatch from '../../../components/meta/NoMatch';
import FeatureToggle from '../../../containers/meta/FeatureToggle';
import SigningCompleteLanding from '../SigningCompleteLanding';
import { routes, SearchApplicationByIdRouteProps } from '../../../routes';
import ApplicantLoggedInApp from '../ApplicantLoggedInApp';
import { getBasePageName } from '../../config';
import { AsyncStatus } from 'applicantApp/store/AsyncStatus';
import { fetchMailOrderConfig } from '../../store/config/actions';
import ApplicantLoggedOutApp from '../ApplicantLoggedOutApp';
import Loading from '../../components/Loading';
import Timeout from '../Timeout';
import { selectApplicantConfigStatus } from '../../store/config/reducer';

export type ApplicantAppProps = SearchApplicationByIdRouteProps;

const useFetchApplicantConfig = () => {
  const dispatch = useDispatch();

  const fetchConfigCallback = useCallback(() => {
    dispatch(fetchMailOrderConfig.request());
  }, [dispatch]);

  return fetchConfigCallback;
};

const ApplicantApp: React.FC<ApplicantAppProps> = () => {
  const fetchApplicantConfig = useFetchApplicantConfig();

  const applicantConfigStatus = useSelector(selectApplicantConfigStatus);

  useEffect(() => {
    if (applicantConfigStatus === AsyncStatus.Default) {
      fetchApplicantConfig();
    }
  }, [fetchApplicantConfig, applicantConfigStatus]);

  const body =
    applicantConfigStatus === AsyncStatus.Success ? (
      <FeatureToggle
        feature={CompassFeature.MAIL_ORDER_RESUME_QUOTE}
        fallbackComponent={<NoMatch />}>
        <Switch>
          <Route
            path={routes.mailOrder.signingCompleteLanding}
            component={SigningCompleteLanding}
          />
          <Route
            path={routes.mailOrder.auth}
            exact
            component={ApplicantLoggedOutApp}
          />
          <Route
            path={routes.mailOrder.application.index}
            component={ApplicantLoggedInApp}
          />
          <Route path={routes.mailOrder.timeout} component={Timeout} />
        </Switch>
      </FeatureToggle>
    ) : (
      <Loading />
    );

  return (
    <Fragment>
      <Helmet>
        <title>{getBasePageName()}</title>
      </Helmet>
      <ThemeProvider theme={novunaTheme}>
        <GlobalStyle
          fontFamily='Silka'
          lightFontUrl={SilkaLight}
          regularFontUrl={SilkaRegular}
          boldFontUrl={SilkaSemiBold}
        />
        <NovunaPBFHeader accessibilityBanner />
        <Layout>{body}</Layout>
        <NovunaPBFFooter />
      </ThemeProvider>
    </Fragment>
  );
};

export default memo(ApplicantApp);
