import { DefaultSagaParams } from '../sagas';

export interface ResendEsatEmailService {
  resendEmail: (id: string) => Promise<void>;
}

export const resendEsatEmailService = ({
  apiClient
}: DefaultSagaParams): ResendEsatEmailService => ({
  resendEmail: async id => {
    try {
      await apiClient.post(`protected/applications/${id}/esat`);
    } catch (err) {
      throw new Error('Failed to resend customer satisfaction note');
    }
  }
});
