import { replace } from 'connected-react-router';
import { call, put, select, spawn, takeLatest } from 'redux-saga/effects';
import { getType, isOfType } from 'typesafe-actions';
import { AuthService, OpenIDConfiguration } from '../../services/auth';
import {
  applicantAuthorization,
  applicantTokenExchange,
  saveCodeVerifier,
  savePreAuthApplicationId,
  savePreAuthPath
} from './actions';
import {
  ApplicantAuthorizationRequestAction,
  selectPreAuthApplicationId,
  selectPreAuthPath,
  TokenExchangeAction
} from './reducer';
import pkceChallenge from 'pkce-challenge';
import {
  selectApplicantAuthClientId,
  selectApplicantAuthBaseUrl
} from '../config/reducer';
import { fetchActiveApplication } from '../../../store/previousApplicationDetail/actions';

export interface AuthSagaParams {
  authService: AuthService;
}

// Token Exchange
export const getTokenExchangeWorker = ({ authService }: AuthSagaParams) =>
  function* tokenExchangeWorker(action: TokenExchangeAction) {
    if (isOfType(getType(applicantTokenExchange.request), action)) {
      try {
        const { token_endpoint } = yield call(openIdConfigSaga, {
          authService
        });

        if (!token_endpoint) {
          throw new Error('Failed to access auth service');
        }

        const accessToken: string = yield call(authService.tokenExchange, {
          tokenEndpoint: token_endpoint,
          code: action.payload.authorizationCode,
          codeVerifier: action.payload.codeVerifier
        });

        yield put(applicantTokenExchange.success({ accessToken }));

        const path = yield select(selectPreAuthPath);
        let applicationId = yield select(selectPreAuthApplicationId);
        if (!path && action.payload.authState) {
          const requestAuthState = JSON.parse(
            Buffer.from(action.payload.authState, 'base64').toString()
          );

          if (!requestAuthState.applicationId) {
            throw Error('invalid state; application id has not been set');
          }

          applicationId = requestAuthState.applicationId;
        }

        // reset the pre auth path
        yield put(savePreAuthPath({ preAuthPath: null }));

        // Prioritize stored pre auth path to allow to resume journey after authentication
        if (!path) {
          yield put(
            fetchActiveApplication.request({
              id: applicationId,
              redirect: true
            })
          );
          return;
        }

        yield put(replace(path));
      } catch (err) {
        yield put(applicantTokenExchange.failure(err));
      }
    }
  };

export const getTokenExchangeWatcher = ({ authService }: AuthSagaParams) =>
  function* tokenExchangeWatcher() {
    yield takeLatest(
      getType(applicantTokenExchange.request),
      getTokenExchangeWorker({ authService })
    );
  };

// Authorization Request
export const clientRedirect = (url: string) => {
  window.location.href = url;
};

export const getAuthorizationSagaWorker = ({
  authService
}: {
  authService: AuthService;
}) => {
  return function* authorizationSagaWorker(
    action: ApplicantAuthorizationRequestAction
  ) {
    if (isOfType(getType(applicantAuthorization.request), action)) {
      try {
        const { authorization_endpoint } = yield call(openIdConfigSaga, {
          authService
        });
        if (!authorization_endpoint) {
          throw new Error('Failed to access auth service');
        }

        const clientId = yield select(selectApplicantAuthClientId);

        const { id } = action.payload;
        const authorizationUrl = new URL(authorization_endpoint);
        const challenge = pkceChallenge();

        yield put(saveCodeVerifier({ codeVerifier: challenge.code_verifier }));

        // Set off an action to store the code verifier (challenge.code_verifier)
        authorizationUrl.searchParams.set('response_type', 'code');
        authorizationUrl.searchParams.set('login_hint', id);
        authorizationUrl.searchParams.set(
          'redirect_uri',
          `${window.location.origin}/auth`
        );
        authorizationUrl.searchParams.set(
          'code_challenge',
          challenge.code_challenge
        );
        authorizationUrl.searchParams.set('client_id', clientId);

        yield put(savePreAuthApplicationId({ applicationId: id }));
        yield call(clientRedirect, authorizationUrl.toString());
      } catch (err) {
        yield put(applicantAuthorization.failure(err));
      }
    }
  };
};

export const getAuthorizationWatcher = ({
  authService
}: {
  authService: AuthService;
}) => {
  return function* authorizationWatcher() {
    yield takeLatest(
      getType(applicantAuthorization.request),
      getAuthorizationSagaWorker({ authService })
    );
  };
};

export const getApplicantAuthSagaWatcher = ({ authService }: AuthSagaParams) =>
  function*() {
    yield spawn(getAuthorizationWatcher({ authService }));
    yield spawn(getTokenExchangeWatcher({ authService }));
  };

// OpenID config saga
export function* openIdConfigSaga({
  authService
}: {
  authService: AuthService;
}) {
  const baseUrl: string = yield select(selectApplicantAuthBaseUrl);

  if (!baseUrl) {
    throw new Error('Failed to access auth service');
  }
  const config: OpenIDConfiguration = yield call(
    authService.getAuthorizationEndpoint,
    baseUrl
  );

  return config;
}
