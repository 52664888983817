import NovunaPageLayout from 'applicantApp/components/NovunaPageLayout';
import { ApplicantApplicationProps } from 'applicantApp/pages/ApplicantApplication/ApplicantApplication';
import { RetailerRedirectButton } from 'applicantApp/components/ExternalRedirectButtons/ExternalRedirectButtons';
import PbfTailoringOptions from 'applicantApp/pages/DecisionOutcome/components/PbfTailoringOptions';
import { NovunaHeading, Text } from 'compass-design';
import { FinanceApplication } from 'hitachi-retail-core';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import React from 'react';
import { useSelector } from 'react-redux';
import getAffordabilityDecline from 'retailerApp/utils/loanDetails/getAffordabilityDecline';
import { getLoanDetails } from 'retailerApp/utils/loanDetails/getLoanDetails';
import {
  selectApplicationDocument,
  selectIsPbfJourney
} from 'store/application/selectors';
import { SoftSearchMessageOutcome } from '../shared';

const SoftSearchDecline: React.FC<Omit<
  ApplicantApplicationProps,
  'decisionPollInterval' | 'isDecisionLoading'
>> = ({ enabledFeatures, onConfirmTailoringUpdate, id }) => {
  const isPbf = useSelector(selectIsPbfJourney);
  const financeApplication = useSelector(
    selectApplicationDocument
  ) as FinanceApplication;

  const maxInstalmentAmount =
    financeApplication.loanTailoring?.maxInstalmentAmount;

  const loanDetails = getLoanDetails({
    document: financeApplication,
    isSoftSearch: true,
    enabledFeatures
  });

  const affordabilityDecline = getAffordabilityDecline({
    decisionApplicationStatus: ApplicationStatus.decision_quotation_decline,
    maxInstalmentAmount,
    enabledFeatures,
    loanDetails,
    agreementType: financeApplication.product.agreementType
  });

  const affordabilityDeclineProps = affordabilityDecline
    ? {
        ...affordabilityDecline,
        onConfirmTailoringUpdate
      }
    : undefined;

  const isPbfTailoringOffered =
    enabledFeatures.has(CompassFeature.PBF_TAILORING) &&
    isPbf &&
    maxInstalmentAmount &&
    affordabilityDeclineProps;

  const returnUrl = financeApplication.returnUrl;
  const supplierOrderReference = financeApplication.orderReference;

  return (
    <NovunaPageLayout
      childrenContainerStyles={{ height: '100%' }}
      backToStoreStatus='soft_search_declined'>
      <SoftSearchMessageOutcome hideExpiryDate={true} />

      <NovunaHeading
        as='h1'
        data-test-id='mo-and-pbf-softsearch-decline-page-heading'
        my={3}>
        Soft search check
      </NovunaHeading>

      {isPbfTailoringOffered ? (
        <PbfTailoringOptions
          originalApplication={financeApplication}
          maxInstalmentAmount={maxInstalmentAmount!}
          affordabilityTailoring={affordabilityDeclineProps!}
          applicationId={id}
          isSoftSearch={true}
        />
      ) : (
        <>
          <Text>
            Thank you for applying. Unfortunately your application would be
            declined.
          </Text>

          <Text mt={4}>
            A letter that gives you more information on your application and how
            we came to our decision has been sent to you by email.
          </Text>

          {returnUrl ? (
            <>
              <Text my={6} sx={{ fontSize: 2 }}>
                Please return to the store to continue shopping.
              </Text>

              <RetailerRedirectButton
                novunaDisplay
                baseUrl={returnUrl}
                applicationId={id}
                status='soft_search_declined'
                buttonText='Continue shopping'
                supplierOrderReference={supplierOrderReference}
              />
            </>
          ) : (
            <Text mt={4}>
              You can leave this website or close your web browser to exit the
              application process.
            </Text>
          )}
        </>
      )}
    </NovunaPageLayout>
  );
};

export default SoftSearchDecline;
