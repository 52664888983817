import React from 'react';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import DecisionError from '../DecisionOutcome/components/DecisionError';
import { Text } from 'compass-design';
import SoftSearchRefer from './components/SoftSearchRefer';
import SoftSearchAccept from './components/SoftSearchAccept';
import SoftSearchDecline from './components/SoftSearchDecline';
import { useDispatch } from 'react-redux';
import { fetchDecision } from 'store/decision/actions';
import { PayloadAction } from 'typesafe-actions/dist/type-helpers';
import { ApplicantApplicationProps } from 'applicantApp/pages/ApplicantApplication/ApplicantApplication';
import ApplicationStatusFetch from '../DecisionOutcome/components/ApplicationStatusFetch';
import { setDecisionError } from 'retailerApp/pages/ApplicationDecision/components/DecisionError/DecisionError';

export interface ConvertSoftSearchToDecisionProps {
  convertSoftSearchOutcomeToDecisionFunction: () => PayloadAction<
    'FETCH_DECISION_REQUEST',
    void | any
  >;
}

const SoftSearchOutcome: React.FC<ApplicantApplicationProps> = ({
  decisionErrorMessage,
  activeApplication,
  id,
  retailer,
  decisionPollInterval,
  isDecisionLoading,
  ...props
}) => {
  const {
    decision_quotation_accept,
    decision_quotation_refer,
    decision_quotation_decline
  } = ApplicationStatus;

  const dispatch = useDispatch();
  const convertSoftSearchOutcomeToDecision = () =>
    dispatch(fetchDecision.request({ id }));

  if (decisionErrorMessage) {
    return (
      <DecisionError
        applicationId={id}
        retailerName={retailer.name}
        decisionError={setDecisionError(decisionErrorMessage)}
      />
    );
  }

  if (
    activeApplication.status === ApplicationStatus.open ||
    isDecisionLoading
  ) {
    return <ApplicationStatusFetch />;
  }

  switch (activeApplication.status) {
    case decision_quotation_accept:
      return (
        <SoftSearchAccept
          {...props}
          id={id}
          tailoringReduceTermEnabled={retailer.tailoringReduceTermEnabled}
          tailoringIbcReduceTermEnabled={retailer.tailoringIbcReduceTermEnabled}
          convertSoftSearchOutcomeToDecisionFunction={
            convertSoftSearchOutcomeToDecision
          }
        />
      );
    case decision_quotation_refer:
      return (
        <SoftSearchRefer
          convertSoftSearchOutcomeToDecisionFunction={
            convertSoftSearchOutcomeToDecision
          }
        />
      );
    case decision_quotation_decline:
      return (
        <SoftSearchDecline
          {...props}
          activeApplication={activeApplication}
          retailer={retailer}
          id={id}
        />
      );
    default:
      return <Text>UNEXPECTED STATUS: {activeApplication.status}</Text>;
  }
};

export default SoftSearchOutcome;
