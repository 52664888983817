import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import { createSelector } from 'reselect';
import { RootState } from '..';
import { formatAddressSingleLine } from '../../utils/formatters';
import { selectApplicationStatus } from '../application/selectors';

export const selectRetailerLoaded = (state: RootState) =>
  state.retailer.retailerLoaded;

export const selectRetailer = (state: RootState) => state.retailer.retailer;

export const selectProcessing = (state: RootState) => state.retailer.processing;

export const selectError = (state: RootState) => state.retailer.error;

export const selectAllBranches = (state: RootState) => state.retailer.branches;

export const selectBranchesForProposing = (state: RootState) =>
  state.retailer.branchesForProposing;

export const selectProposingOnBehalfOf = createSelector(
  selectBranchesForProposing,
  (state: RootState) => state.retailer.proposingOnBehalfOf,
  (branchesForProposing, proposingOnBehalfOf) =>
    (branchesForProposing ?? []).find(
      branch => branch.supplierNumber === proposingOnBehalfOf
    )
);

export const selectRetailerForApplication = createSelector(
  selectProposingOnBehalfOf,
  selectRetailer,
  (proposingOnBehalfOf, retailer) => proposingOnBehalfOf ?? retailer
);

export const selectRetailerName = createSelector(
  selectRetailer,
  retailer => retailer.name
);

export const selectRetailerAddress = createSelector(
  selectRetailer,
  retailer => retailer.address
);

export const selectFormattedRetailerAddress = createSelector(
  selectRetailerAddress,
  retailerAddress => formatAddressSingleLine(retailerAddress)
);

export const selectMailOrderEnabled = createSelector(
  selectRetailer,
  retailer => retailer.mailorderEnabled === true
);

export const selectMailOrderSoftSearchEnabled = createSelector(
  selectRetailer,
  retailer => retailer.moSoftSearchEnabled === true
);

export const selectFace2FaceSoftSearchEnabled = createSelector(
  selectRetailer,
  retailer => retailer.softSearchEnabled === true
);

export const selectSendToCustomerAllowedOnQuote = createSelector(
  selectRetailer,
  selectApplicationStatus,
  (retailer, applicationStatus) =>
    retailer.mailorderEnabled === true &&
    applicationStatus === ApplicationStatus.open
);

export const selectEsatNoteEnabled = createSelector(
  selectRetailer,
  retailer => retailer.esatNoteEnabled === true
);

export const selectProposeApplicationsEnabled = createSelector(
  selectRetailer,
  retailer => retailer.proposeApplicationsEnabled === true
);

export const selectNonMandatoryEmailEnabled = createSelector(
  selectRetailer,
  retailer => retailer.nonMandatoryEmailEnabled === true
);

export const selectSoftSearchEnabled = createSelector(
  selectRetailer,
  retailer => retailer.softSearchEnabled === true
);

export const selectImmediatePayoutEnabled = createSelector(
  selectRetailer,
  retailer => retailer.immediatePayoutEnabled === true
);

export const selectPartialSettlementsEnabled = createSelector(
  selectRetailer,
  retailer => retailer.partialSettlementsEnabled === true
);

export const selectSupplierNumber = createSelector(
  selectRetailer,
  retailer => retailer.supplierNumber
);
