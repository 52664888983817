import { Button, Message, RedAlert } from 'compass-design';
import { SectionErrors } from 'components/applicantDetails/ApplicantDetails';
import sectionSummaryDefinitions from 'components/applicantDetails/schema/sectionSummaryDefinitions';
import ApplicationSectionSummary from 'components/ApplicationSectionSummary';
import CompassForm from 'components/form/CompassForm';
import { ApplicantDetails as ApplicantDetailsInterface } from 'hitachi-retail-core';
import { IncompleteFinanceApplication } from 'hitachi-retail-core/build/schemas/financeApplication';
import { JSONSchema6 } from 'json-schema';
import React from 'react';
import {
  ChangeEvent,
  FormValidation,
  SubmitEvent,
  UiSchema
} from 'react-jsonschema-form';
import { JsonFormData } from '../../../form/types';

export interface SectionContentProps {
  sectionKey: string;
  sectionSchema: JSONSchema6;
  sectionActive: boolean;
  sectionComplete: boolean;
  sectionErrors: SectionErrors;
  formData: IncompleteFinanceApplication;
  formSchema: JSONSchema6;
  uiSchema: UiSchema;
  processing: boolean;
  validateFormSection: (
    formData: ApplicantDetailsInterface,
    errors: FormValidation
  ) => FormValidation;
  handleFormDataUpdate: (formData: JsonFormData) => void;
  handleFormSectionSubmit: (formData: JsonFormData) => void;
}

const SectionContent: React.FC<SectionContentProps> = ({
  sectionKey,
  sectionSchema,
  sectionActive,
  sectionComplete,
  sectionErrors,
  formData,
  formSchema,
  uiSchema,
  processing,
  validateFormSection,
  handleFormDataUpdate,
  handleFormSectionSubmit
}) => {
  const sectionError = sectionErrors[sectionKey];

  if (sectionActive) {
    const sectionFormSchema = {
      ...formSchema,
      properties: {
        [sectionKey]: sectionSchema
      }
    };

    return (
      <>
        {sectionError && (
          <Message
            variant='error'
            mb={5}
            icon={<RedAlert />}
            data-test-id={`compass-applicant-details-section-error-${sectionKey}`}>
            {sectionError}
          </Message>
        )}
        <CompassForm
          idPrefix='applicant'
          className={sectionKey}
          schema={sectionFormSchema}
          uiSchema={uiSchema}
          formData={formData}
          validate={validateFormSection}
          onChange={({ formData: eventFormData }: ChangeEvent<JsonFormData>) =>
            handleFormDataUpdate(eventFormData)
          }
          onSubmit={({ formData: eventFormData }: SubmitEvent<JsonFormData>) =>
            handleFormSectionSubmit(eventFormData)
          }>
          <Button
            isJumbo
            id='applicant-section-submit-button'
            disabled={processing}
            data-test-id='compass-applicant-details-section-submit'>
            Continue
          </Button>
        </CompassForm>
      </>
    );
  }

  return (
    <>
      {sectionComplete && (
        <div
          id={`applicant-section-summary-${sectionKey}`}
          className='compass-form-section-summary'
          data-test-id={`compass-form-section-summary-${sectionKey}`}>
          <ApplicationSectionSummary
            definitions={sectionSummaryDefinitions}
            sectionKey={sectionKey}
            applicationData={formData}
            schema={formSchema}
          />
        </div>
      )}
    </>
  );
};

export default SectionContent;
