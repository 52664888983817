import { OnlineDepositsService } from '../services/onlineDepositsService';

const getMockOnlineDepositsService = (): OnlineDepositsService => ({
  initiateDeposit: async () => {
    console.log('Mocks: initiateDeposit called');
    return `https://jfy1xaoyv1.execute-api.eu-west-2.amazonaws.com/test/globalpay/card?torandomise=${new Date().getTime()}`;
  },

  depositPaymentComplete: async () => {
    console.log('Mocks: depositPaymentComplete called');
    // return { status: 'Error', retryPayment: true }; //uncomment for system failure errors
    // return { status: 'Failure', retryPayment: true }; //uncomment for retryable failure scenarios
    // return { status: 'Failure', retryPayment: false }; //uncomment for non retryable failure scenarios
    return { status: 'Paid' };
  }
});

export default getMockOnlineDepositsService;
