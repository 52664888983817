import {
  Box,
  Button,
  InlineCheckbox,
  NovunaHeading,
  PrivacyPolicyCard,
  Text
} from 'compass-design';
import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Link as ReactRouterLink } from 'react-router-dom';
import { generateApplicationPath, routes } from 'routes';
import { updateActiveApplication } from 'store/application/actions';
import { selectFetchApplicationStatus } from 'store/application/selectors';
import { ApplicantApplicationProps } from 'applicantApp/pages/ApplicantApplication/ApplicantApplication';
import { getBasePageName, pageNameSeparator } from '../../config';
import { AsyncStatus } from '../../store/AsyncStatus';
import NovunaPageLayout from '../NovunaPageLayout';
import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import { SoftSearchMessageQuote } from 'applicantApp/pages/SoftSearchOutcome/components/shared/SoftSearchMessages';
import { isSoftSearchEnabledForAgreement } from 'utils/isSoftSearchEnabled/isSoftSearchEnabled';

const ApplicationStatus: React.FunctionComponent<ApplicantApplicationProps> = ({
  id,
  activeApplication,
  retailer,
  enabledFeatures
}) => {
  const dispatch = useDispatch();
  const fetchApplicationStatus = useSelector(selectFetchApplicationStatus);
  const isSoftSearch =
    isSoftSearchEnabledForAgreement(retailer, activeApplication.document) &&
    enabledFeatures.has(CompassFeature.MO_PBF_SOFT_SEARCH);
  const pageName = 'Apply for your loan';
  const document = activeApplication?.document || {};
  const [paperlessConsentError, setPaperlessConsentError] = useState(false);
  let MainPage;

  const paperlessConsentHandlers = useMemo(
    () => ({
      onChange: ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
          updateActiveApplication({
            document: { paperlessConsent: target.checked },
            replaceValues: false
          })
        );

        if (paperlessConsentError && target.checked) {
          setPaperlessConsentError(false);
        }
      }
    }),
    [paperlessConsentError, dispatch]
  );
  const handleContinue = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (!document.paperlessConsent) {
      event.preventDefault();
      setPaperlessConsentError(true);
    }
  };

  if (fetchApplicationStatus === AsyncStatus.Success) {
    const paperlessContentText =
      'Important documents relating to your agreement will be emailed to you at the email address provided. These documents may be accessed as either an attachment or a link.';

    const NovunaPaperless = (
      <Box>
        <Text>Paperless documents</Text>
        <Text sx={{ color: 'monochrome.3' }}>{paperlessContentText}</Text>
        <Box mt={2} data-test-id='paperless-consent-checkbox'>
          <InlineCheckbox
            id='paperless-consent-checkbox-control'
            checked={!!document.paperlessConsent}
            error={
              paperlessConsentError
                ? 'Please give consent if you wish to continue'
                : undefined
            }
            tone={paperlessConsentError ? 'negative' : undefined}
            {...paperlessConsentHandlers}>
            I consent to Novuna Personal Finance sending my documents by email
          </InlineCheckbox>
        </Box>
      </Box>
    );

    MainPage = (
      <>
        <Helmet>{[pageName, getBasePageName()].join(pageNameSeparator)}</Helmet>
        <div data-test-id='compass-applicant-app-data'>
          {isSoftSearch && <SoftSearchMessageQuote />}
          <NovunaHeading
            as='h1'
            mt={4}
            mb={2}
            data-test-id='mo-apply-for-loan-page-heading'>
            Quote summary
          </NovunaHeading>

          <Text sx={{ fontSize: 2 }}>
            Before continuing check the details of your purchase and loan
            application are correct.
          </Text>

          <Box py={4}>
            <PrivacyPolicyCard
              fullPolicyUrl={'https://www.hitachicapital.co.uk/crain/'}
              isHardSearch={!isSoftSearch}
            />
          </Box>

          {/* Paperless Consent */}
          {NovunaPaperless}
          <Button
            isJumbo
            mt={6}
            {...({
              to: generateApplicationPath(
                routes.mailOrder.application.details,
                {
                  id
                }
              )
            } as any)}
            as={ReactRouterLink}
            onClick={handleContinue}
            data-test-id='mo-resume-application-start-button'>
            Start application
          </Button>
        </div>
      </>
    );
  } else {
    MainPage = <div data-test-id='compass-applicant-app-loading' />;
  }

  return (
    <NovunaPageLayout backToStoreStatus='abandoned'>
      {MainPage}
    </NovunaPageLayout>
  );
};

export default ApplicationStatus;
