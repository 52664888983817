import { IovationDevice } from 'hitachi-retail-core/build/schemas/applicantDetails';

interface Igloo {
  getBlackbox: () => { blackbox: string; finished: boolean };
}

export interface IovationWindow {
  IGLOO: Igloo;
}

export const getIovationFingerprint = (): IovationDevice | void => {
  const IGLOO = ((window as unknown) as IovationWindow)?.IGLOO;

  if (!IGLOO?.getBlackbox) return;

  const { blackbox, finished } = IGLOO.getBlackbox();

  if (!finished) return;

  return { blackbox };
};
