import {
  Button,
  NovunaHeading,
  Message,
  ReactModal,
  Box,
  PurpleMail
} from 'compass-design';
import React from 'react';

export interface RequestPaymentModalProps {
  modalOpen: boolean;
  toggleModalOpen: (isOpen: boolean) => void;
  onSubmit: (onSubmit: any) => void;
  esatNoteEnabled?: boolean;
}

export const RequestPaymentModal: React.FC<RequestPaymentModalProps> = ({
  modalOpen,
  toggleModalOpen,
  onSubmit,
  esatNoteEnabled
}) => {
  return (
    <ReactModal
      title='Request Payment'
      size='medium'
      isOpen={modalOpen}
      setIsOpen={toggleModalOpen}
      backgroundClick={false}>
      <Message variant='warning'>
        <NovunaHeading as='h3'>
          Are you sure you want to request payment?
        </NovunaHeading>
        This cannot be undone.
      </Message>

      {esatNoteEnabled && (
        <Box mt={4}>
          <Message variant='info' icon={<PurpleMail />}>
            A feedback request will be sent to the customer by email. Your
            payment request will be actioned once they have confirmed they are
            satisfied with the completed order.
          </Message>
        </Box>
      )}

      <Button
        type='submit'
        mt={[5, null, 6]}
        isJumbo
        data-test-id='request-payment-modal-confirm-btn'
        onClick={onSubmit}>
        Confirm
      </Button>
    </ReactModal>
  );
};
