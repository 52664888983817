import { Box, NovunaInputHeader, ScreenReaderLabel } from 'compass-design';
import {
  Address,
  addressFromDateSchema,
  AddressWithDate,
  validationMessages
} from 'hitachi-retail-core';
import {
  diffBetweenMonthYear,
  nowToMonthYear
} from 'hitachi-retail-core/build/utils';
import { JSONSchema6 } from 'json-schema';
import React, { RefObject, useRef } from 'react';
import { FieldProps, FormValidation } from 'react-jsonschema-form';
import {
  asNumber,
  getDefaultRegistry,
  resolveSchema,
  toIdSchema
} from 'react-jsonschema-form/lib/utils';
import AddressFinder from '../../../containers/AddressFinder';
import { getFieldError as getFieldErrorHelper } from '../../../form/helpers';
import { getAddressLines, getBlankAddress } from '../../../utils/address';
import { isTwoDigitValue } from '../../../utils/dates';
import InputField from '../fields/InputField';

type PartialAddressWithDate = Partial<AddressWithDate>;

export interface MultiAddressState {
  addressEntries: PartialAddressWithDate[];
}

export interface AddressEntryEvaluationConfig {
  maxEntryCount: number;
  monthsRequired: number;
}

export type MultiAddressProps = FieldProps<
  PartialAddressWithDate[] | undefined
>;

const multiAddressPart = {
  Address: 'address',
  FromMonth: 'fromMonth',
  FromYear: 'fromYear'
};

class MultiAddress extends React.Component<
  MultiAddressProps,
  MultiAddressState
> {
  constructor(props: MultiAddressProps) {
    super(props);
    const { uiSchema } = props;

    if (!('ui:maxAddressCount' in uiSchema)) {
      throw new Error("'ui:maxAddressCount' not set in uiSchema");
    }

    if (!('ui:monthsAddressesRequired' in uiSchema)) {
      throw new Error("'ui:monthsAddressesRequired' not set in uiSchema");
    }

    const addressEntries = props.formData || [getBlankAddressWithDateEntry()];
    this.state = { addressEntries };
  }

  public handleAddressChange = (entryIndex: number, address: Address) => {
    const { addressEntries } = this.state;
    addressEntries[entryIndex].address = address;

    this.setState({ addressEntries });
    this.props.onChange(addressEntries);
  };

  public handleDateChange = (
    entryIndex: number,
    property: string,
    value: string
  ) => {
    const { uiSchema } = this.props;
    let { addressEntries } = this.state;
    const currentEntry = addressEntries[entryIndex];
    currentEntry[property] = asNumber(value);

    if (addressFromDateSchema.isValidSync(currentEntry)) {
      addressEntries = evaluateAddressEntries(addressEntries, {
        maxEntryCount: uiSchema['ui:maxAddressCount'],
        monthsRequired: uiSchema['ui:monthsAddressesRequired']
      });
    }

    this.setState({ addressEntries }, () =>
      this.props.onChange(addressEntries)
    );
  };

  public render() {
    const {
      schema,
      idPrefix,
      errorSchema,
      idSchema,
      uiSchema,
      rawErrors = [],
      registry = getDefaultRegistry()
    } = this.props;
    const { addressEntries } = this.state;
    const { definitions } = registry;
    const testIdPrefix = idSchema.$id;

    const title = uiSchema['ui:title'] || schema.title;
    const description = uiSchema['ui:description'] || schema.description;
    const itemsSchema = resolveSchema(schema.items as JSONSchema6, definitions);
    const unresolvedAddressSchema = itemsSchema.properties!
      .address as JSONSchema6;
    // Address schema is a reference to a definition, so needs resolving
    const addressSchema = resolveSchema(unresolvedAddressSchema, definitions);

    return (
      <div className='compass-field'>
        {title && <h2>{title}</h2>}
        {description && <p>{description}</p>}
        {addressEntries.map((entryFormData, index) => {
          const entryId = `${idSchema.$id}_entry${index}`;
          const entryIdSchema = toIdSchema(
            itemsSchema,
            entryId,
            definitions,
            entryFormData,
            idPrefix
          );
          const entryTestIdPrefix = `${testIdPrefix}_entry${index}`;

          return (
            <AddressFinderWithDate
              key={index}
              {...this.props}
              formData={entryFormData}
              previousAddress={index > 0}
              previousAddressLine={getPrimaryAddressLine(
                addressEntries,
                index - 1
              )}
              addressSchema={addressSchema}
              idSchema={entryIdSchema}
              addressError={getFieldError(
                multiAddressPart.Address,
                rawErrors,
                validationMessages.REQUIRED_ADDRESS,
                errorSchema[index]
              )}
              addressErrorSchema={
                errorSchema[index] && errorSchema[index].address
              }
              fromMonthError={getFieldError(
                multiAddressPart.FromMonth,
                rawErrors,
                validationMessages.REQUIRED_FIELD,
                errorSchema[index]
              )}
              fromYearError={getFieldError(
                multiAddressPart.FromYear,
                rawErrors,
                validationMessages.REQUIRED_FIELD,
                errorSchema[index]
              )}
              testIdPrefix={entryTestIdPrefix}
              onAddressChange={address =>
                this.handleAddressChange(index, address)
              }
              onMonthChange={value =>
                this.handleDateChange(index, multiAddressPart.FromMonth, value)
              }
              onYearChange={value =>
                this.handleDateChange(index, multiAddressPart.FromYear, value)
              }
            />
          );
        })}
      </div>
    );
  }
}

type AddressFinderWithDateProps = FieldProps & {
  previousAddress: boolean;
  previousAddressLine?: string;
  addressSchema: JSONSchema6;
  addressErrorSchema: FormValidation;
  addressError?: string;
  fromMonthError?: string;
  fromYearError?: string;
  testIdPrefix: string;
  onAddressChange: (address: Address) => void;
  onMonthChange: (value: string) => void;
  onYearChange: (value: string) => void;
};

export const AddressFinderWithDate: React.FunctionComponent<AddressFinderWithDateProps> = ({
  formData,
  previousAddress,
  previousAddressLine,
  addressSchema,
  addressErrorSchema,
  addressError,
  fromMonthError,
  fromYearError,
  testIdPrefix,
  onAddressChange,
  onMonthChange,
  onYearChange,
  schema,
  idSchema,
  uiSchema,
  ...restProps
}) => {
  const fromYearInputRef: RefObject<HTMLInputElement> = useRef(null);
  const fromMonthInputId = `${testIdPrefix}_${multiAddressPart.FromMonth}`;
  const fromYearInputId = `${testIdPrefix}_${multiAddressPart.FromYear}`;

  return (
    <div>
      {previousAddress ? <h2>Address before {previousAddressLine}</h2> : null}
      <div>
        <NovunaInputHeader
          title='At address since'
          hint='The month and year you moved in'
        />
        <Box mt={-2}>
          <Box sx={{ display: 'inline-block', width: '149px' }}>
            <ScreenReaderLabel id={fromMonthInputId} label='from month' />
            <InputField
              placeholder='MM'
              maxLength={2}
              required
              fieldName={multiAddressPart.FromMonth}
              fieldValue={formData.fromMonth}
              fieldError={fromMonthError}
              testIdPrefix={testIdPrefix}
              inputId={fromMonthInputId}
              onChange={({ target: { value } }) => {
                if (isTwoDigitValue(value) && fromYearInputRef.current) {
                  fromYearInputRef.current.focus();
                }
                onMonthChange(value);
              }}
            />
          </Box>
          <Box ml={2} sx={{ display: 'inline-block', width: '149px' }}>
            <ScreenReaderLabel id={fromYearInputId} label='from year' />
            <InputField
              inputRef={fromYearInputRef}
              placeholder='YYYY'
              maxLength={4}
              required
              fieldName={multiAddressPart.FromYear}
              fieldValue={formData.fromYear}
              fieldError={fromYearError}
              testIdPrefix={testIdPrefix}
              inputId={fromYearInputId}
              onChange={({ target: { value } }) => onYearChange(value)}
            />
          </Box>
        </Box>
      </div>
      <AddressFinder
        {...restProps}
        uiSchema={uiSchema}
        formData={formData.address}
        idSchema={idSchema}
        schema={addressSchema}
        errorSchema={addressErrorSchema}
        addressError={addressError}
        onChange={(address: Address) => onAddressChange(address)}
        testIdPrefix={testIdPrefix}
      />
    </div>
  );
};

export const getBlankAddressWithDateEntry = (): PartialAddressWithDate => ({
  address: getBlankAddress() as Address,
  fromMonth: undefined,
  fromYear: undefined
});

export const getPrimaryAddressLine = (
  addressEntries: PartialAddressWithDate[],
  entryIndex: number
): string => {
  const address =
    addressEntries[entryIndex] && addressEntries[entryIndex].address;
  if (address) {
    return getAddressLines(address)[0];
  }
  return '';
};

export const evaluateAddressEntries = (
  addressEntries: PartialAddressWithDate[],
  config: AddressEntryEvaluationConfig
) => {
  const currentEntryCount = addressEntries.length;
  let requiredEntryCount = 1;
  let monthsCaptured = 0;
  addressEntries.forEach(entry => {
    if (addressFromDateSchema.isValidSync(entry)) {
      const monthDiffResult = diffBetweenMonthYear(
        {
          year: entry.fromYear,
          month: entry.fromMonth
        },
        nowToMonthYear()
      );

      if (monthDiffResult > monthsCaptured) {
        monthsCaptured = monthDiffResult;
      }
      if (
        monthsCaptured < config.monthsRequired &&
        requiredEntryCount < config.maxEntryCount
      ) {
        requiredEntryCount++;
      }
    }
  });
  const updatedAddressEntries = [...addressEntries];
  if (currentEntryCount < requiredEntryCount) {
    updatedAddressEntries.push(getBlankAddressWithDateEntry());
  } else if (currentEntryCount > requiredEntryCount) {
    updatedAddressEntries.pop();
  }
  return updatedAddressEntries;
};

const getFieldError = (
  fieldName: string,
  rawErrors: string[],
  blankErrorMessage: string,
  errorSchema?: FormValidation
): string | undefined => {
  const schemaError =
    errorSchema && getFieldErrorHelper(fieldName, errorSchema);
  if (schemaError) {
    return schemaError;
  } else if (blankEntryError(rawErrors)) {
    return blankErrorMessage;
  }
  return;
};

const blankEntryError = (rawErrors: string[]): boolean =>
  rawErrors.includes(validationMessages.REQUIRED_ADDRESS);

export default MultiAddress;
