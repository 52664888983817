import React, { useState } from 'react';

interface GetNumberOfPages {
  totalNumberOfItems: number;
  numberOfItemsPerPage?: number;
}

const defaultNumberOfItemsPerPage = 11;

export const getNumberOfPages = ({
  totalNumberOfItems,
  numberOfItemsPerPage
}: GetNumberOfPages) => {
  const itemsPerPage = numberOfItemsPerPage ?? defaultNumberOfItemsPerPage;
  return totalNumberOfItems === 0
    ? 1
    : Math.ceil(totalNumberOfItems / itemsPerPage);
};

interface GetPaginatedItems {
  currentPage: number;
  data: any[];
}

export const getPaginatedItems = ({ currentPage, data }: GetPaginatedItems) =>
  data.slice(
    (currentPage - 1) * defaultNumberOfItemsPerPage,
    currentPage * defaultNumberOfItemsPerPage
  );

export interface PaginateStateProps {
  currentPage: number;
  selectPage: (selectedPage: number) => void;
}

const PaginateState = (
  WrappedComponent: React.ComponentType<any & PaginateStateProps>
) => {
  const PaginateWithState = (props: any) => {
    const [currentPage, setCurrentPage] = useState(1);

    return (
      <WrappedComponent
        {...props}
        currentPage={currentPage}
        selectPage={setCurrentPage}
      />
    );
  };

  return PaginateWithState;
};

export default PaginateState;
