import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import { DocumentIdentifier } from 'hitachi-retail-core/build/enums/documentDownloadConfirmation';
import { ReferralNote } from 'hitachi-retail-core/build/schemas/referralNote';
import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';
import {
  selectApr,
  selectDeferralPeriod,
  selectDeposit,
  selectGoodsAmount,
  selectPendingGoodsAmount,
  selectGoodsDescription,
  selectInstalments,
  selectLoanAmount,
  selectPendingDeposit
} from 'hitachi-retail-core/build/selectors';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { fetchAgreementUrl } from 'store/agreement/actions';
import { selectAgreementUrl } from 'store/agreement/selectors';
import { fetchRetailerByApplicationId } from 'store/retailer/actions';
import { documentDownloadConfirmation } from 'store/documentDownloadConfirmation/actions';
import { FeatureConfig } from 'hitachi-retail-core/build/features/features';
import {
  amendApplication,
  resumeApplication,
  createNewApplication
} from 'store/application/actions';
import {
  getRepaymentAmount,
  getTotalAmountPayable
} from 'hitachi-retail-core/build/finance';
import ApplicationDetail, {
  ApplicationDetailPropsFromDispatch,
  ApplicationDetailPropsFromState
} from './ApplicationDetail';
import { RootState } from '../../../store';
import { fetchDeclinedLetterById } from '../../../store/declinedEmail/actions';
import {
  cancelApplication,
  CustomerSatisfactionNotes,
  fetchApplicationReferralNotes,
  fetchAuditHistory,
  fetchCustomerFeedback,
  fetchPreviousApplicationById,
  markGoodsAsDelivered,
  PreviousApplicationDetail,
  requestPartialSettlement,
  resendEsatNote,
  resetApplicationReferralNoteSubmissionStatus,
  revertApplicationToVersion,
  submitApplicationReferralNote
} from '../../../store/previousApplicationDetail/actions';
import {
  selectCustomerFeedback,
  selectReferralNotes
} from '../../../store/previousApplicationDetail/selectors';
import {
  selectEsatNoteEnabled,
  selectPartialSettlementsEnabled,
  selectProposeApplicationsEnabled
} from '../../../store/retailer/selectors';
import { QuoteDetailProps } from './components/Details/getRowDetailsText';
import { resetLoanAmend } from 'store/applicationAmend/actions';

export const mapStateToProps = (
  state: RootState
): ApplicationDetailPropsFromState => {
  const { previousApplicationDetail, declinedEmail, config, retailer } = state;
  const {
    loading,
    revertLoading,
    referralNoteSubmissionStatus
  } = previousApplicationDetail;
  let previousApplication: PreviousApplicationDetail | undefined;
  let referralNotes: ReferralNote[] | undefined;
  let customerFeedback: CustomerSatisfactionNotes | undefined;
  let quote: QuoteDetailProps | undefined;
  let declinedLetterUrl: string | undefined;
  let requireCancellationReason: boolean | undefined;
  let enabledFeatures: FeatureConfig | undefined;
  let retailerFlags:
    | Pick<
        Retailer,
        | 'esatNoteEnabled'
        | 'proposeApplicationsEnabled'
        | 'partialSettlementsEnabled'
      >
    | undefined;

  if (previousApplicationDetail) {
    previousApplication = previousApplicationDetail.previousApplication;
    referralNotes = selectReferralNotes(previousApplicationDetail);
    customerFeedback = selectCustomerFeedback(previousApplicationDetail);
  }

  if (previousApplication) {
    const applicationDocument = previousApplication.document;
    const goodsAmount = selectGoodsAmount(applicationDocument);
    const pendingGoodsAmount = selectPendingGoodsAmount(applicationDocument);
    const pendingDeposit = selectPendingDeposit(applicationDocument);
    const deposit = selectDeposit(applicationDocument);
    const loanAmount = selectLoanAmount(applicationDocument);
    const instalments = selectInstalments(applicationDocument);
    const goodsDescription = selectGoodsDescription(applicationDocument);
    const apr = selectApr(applicationDocument);
    const deferralPeriod = selectDeferralPeriod(applicationDocument);
    const { orderReference } = applicationDocument;

    let totalAmountPayable;
    if (instalments && deposit && loanAmount && apr && goodsAmount) {
      const repaymentAmount = getRepaymentAmount({
        apr,
        instalments,
        loanAmount,
        deferralPeriod
      });

      if (repaymentAmount) {
        totalAmountPayable = getTotalAmountPayable({
          deposit,
          instalments,
          repaymentAmount
        });
      }
    }

    quote = {
      goodsAmount,
      pendingGoodsAmount,
      orderReference,
      deposit,
      pendingDeposit,
      loanAmount,
      instalments,
      goodsDescription,
      totalAmountPayable,
      apr
    };

    requireCancellationReason =
      previousApplication.status === ApplicationStatus.paid ||
      previousApplication.document.isOnlineDepositJourney;
  }

  const agreementUrl = selectAgreementUrl(state);

  if (retailer) {
    retailerFlags = {
      esatNoteEnabled: selectEsatNoteEnabled(state),
      proposeApplicationsEnabled: selectProposeApplicationsEnabled(state),
      partialSettlementsEnabled: selectPartialSettlementsEnabled(state)
    };
  }

  if (declinedEmail) {
    declinedLetterUrl = declinedEmail.pdfUrl;
  }

  if (config) {
    enabledFeatures = config.enabledFeatures;
  }

  return {
    application: previousApplication,
    agreementUrl,
    referralNotes,
    referralNoteSubmissionStatus,
    quote,
    loading,
    revertLoading,
    declinedLetterUrl,
    requireCancellationReason,
    enabledFeatures,
    customerFeedback,
    retailerFlags
  };
};

export const mapDispatchToProps = (
  dispatch: Dispatch
): ApplicationDetailPropsFromDispatch => ({
  fetchApplicationById: id => {
    dispatch(fetchPreviousApplicationById.request({ id }));
  },
  fetchAgreementUrl: id => {
    dispatch(
      fetchAgreementUrl.request({ applicationId: id, forceDownload: false })
    );
  },
  requestPayment: id => {
    dispatch(markGoodsAsDelivered.request({ id }));
  },
  resendEsatNote: id => {
    dispatch(resendEsatNote.request({ id }));
  },
  cancelApplication: (id, cancellationReason) => {
    dispatch(cancelApplication.request({ id, cancellationReason }));
  },
  requestPartialSettlement: (id, partialSettlementAmount) => {
    dispatch(requestPartialSettlement.request({ id, partialSettlementAmount }));
  },
  resumeApplication: () => dispatch(resumeApplication()),
  amendApplication: () => dispatch(amendApplication()),
  downloadDeclinedLetter: (id: string) => {
    dispatch(fetchDeclinedLetterById.request({ id }));
  },
  fetchApplicationReferralNotes: id => {
    dispatch(fetchApplicationReferralNotes.request({ id }));
  },
  fetchAuditHistory: (id, branchId) => {
    dispatch(fetchAuditHistory.request({ id, branchId }));
  },
  submitApplicationReferralNote: (id, note) => {
    dispatch(submitApplicationReferralNote.request({ id, note }));
  },
  fetchCustomerFeedback: id => {
    dispatch(fetchCustomerFeedback.request({ id }));
  },
  resetApplicationReferralNoteSubmissionStatus: () =>
    dispatch(resetApplicationReferralNoteSubmissionStatus()),
  resetLoanAmendStatus: () => dispatch(resetLoanAmend()),
  revertApplicationVersionTo: (id, versionId) => {
    if (id && versionId) {
      dispatch(revertApplicationToVersion.request({ id, versionId }));
    }
  },
  createNewApplication: () => dispatch(createNewApplication()),
  onCreditAgreementDownload: applicationId =>
    dispatch(
      documentDownloadConfirmation.request({
        applicationId,
        documentIdentifier: DocumentIdentifier.CreditAgreement
      })
    ),
  fetchRetailerByApplicationId: applicationId =>
    dispatch(fetchRetailerByApplicationId.request({ id: applicationId }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationDetail);
