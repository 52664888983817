import React from 'react';
import { NovunaHeading } from 'compass-design';

interface PageTitleProps {
  dataTestId?: string;
}

const PageTitle: React.FC<PageTitleProps> = ({ dataTestId, children }) => (
  <NovunaHeading as='h1' mb={2} data-test-id={dataTestId}>
    {children}
  </NovunaHeading>
);

export default PageTitle;
