import { FeatureConfig } from 'hitachi-retail-core/build/features/features';
import { matchPath } from 'react-router';
import { RoutePredicate, routes } from '../routes';

export interface PageDetail {
  title: string;
  route?: string;
  feature?: string;
}

export const pageDetails: PageDetail[] = [
  { title: 'Quote', route: routes.apply.quote },
  { title: 'Amend Loan', route: routes.apply.loanAmend },
  { title: 'Privacy', route: routes.apply.privacyPolicy },
  { title: 'Customer details', route: routes.apply.details },
  {
    title: 'Soft search',
    route: routes.apply.softSearch
  },
  { title: 'Decision', route: routes.apply.decision },
  {
    title: 'Loan information',
    route: routes.apply.loanAdvanceInformation
  },
  {
    title: 'Loan information',
    route: routes.apply.preContractCreditInformation
  },
  {
    title: 'Sign'
  },
  {
    title: 'Complete',
    route: routes.apply.complete
  }
];

export const getPageDetails = (
  enabledFeatures: Set<string>,
  excludedRoutePredicate?: RoutePredicate
) =>
  pageDetails
    .filter(page => {
      const { feature, route } = page;
      const featureDisabled = Boolean(feature && !enabledFeatures.has(feature));
      let routeExcluded = false;
      if (excludedRoutePredicate && route) {
        routeExcluded = excludedRoutePredicate(route);
      }
      return !featureDisabled && !routeExcluded;
    })
    .map(({ title, route }) => ({ title, route }));

export const getPageTitles = (
  enabledFeatures: FeatureConfig,
  excludedRoutePredicate?: RoutePredicate
) =>
  getPageDetails(enabledFeatures, excludedRoutePredicate).map(
    page => page.title
  );

export const getPageRoutes = (
  enabledFeatures: FeatureConfig,
  excludedRoutePredicate?: RoutePredicate
) =>
  getPageDetails(enabledFeatures, excludedRoutePredicate).map(
    page => page.route
  );

export const getPageRouteIndex = (
  pagePath: string,
  enabledFeatures: FeatureConfig,
  excludedRoutePredicate?: RoutePredicate
) =>
  getPageRoutes(enabledFeatures, excludedRoutePredicate).findIndex(
    path => matchPath(pagePath, { path, exact: true }) !== null
  );
