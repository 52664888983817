import React from 'react';
import {
  AccordionContent,
  AccordionGroup,
  AccordionGroupEntry,
  Box,
  Link,
  List,
  ListItem,
  NovunaHeading,
  Text
} from 'compass-design';
import { LoanInformationProps } from './LoanInformationProps';

const howAndWhenCreditWouldBeProvidedText = (
  immediatePayout: boolean,
  isNonBrokered: boolean
) => {
  if (immediatePayout || isNonBrokered) {
    return 'We will pay the total amount of credit to the supplier of the goods/services on the date of the credit agreement.';
  }
  return 'We will pay the total amount of credit to the supplier of the goods/services once they have been supplied to you.';
};

const creditDurationAgreementText = (
  productIsDeferred: boolean,
  agreementDuration: number
) => {
  if (productIsDeferred) {
    return `The period starting on the date of the agreement and ending on the date which is ${agreementDuration} months from the date of supply (and, if applicable, installation) of the goods/services`;
  }
  return `${agreementDuration} months starting on the date of the agreement`;
};

export const PreContractCreditInformation: React.FC<LoanInformationProps> = ({
  intermediaryName,
  intermediaryAddress,
  goodsDescription,
  loanAmount,
  repaymentAmount,
  agreementDuration,
  instalments,
  goodsAmount,
  totalAmountPayable,
  apr,
  earlySettlementFee,
  isDeferred,
  immediatePayout,
  isNonBrokered
}) => (
  <AccordionGroup
    headingAs='h2'
    label='Pre-contract credit information'
    testIdPrefix='pcci'>
    <AccordionGroupEntry
      sectionNumber={1}
      title='Contact details'
      description={`For the creditor${!isNonBrokered ? ' and supplier' : ''}`}
      headingVariant='tertiary'
      breakAfter={true}>
      <Box mb={4}>
        <NovunaHeading as='h3' mb={2}>
          Creditor
        </NovunaHeading>
        <Text>
          Mitsubishi HC Capital UK PLC trading as Novuna Personal Finance, (
          <b>Novuna Personal Finance</b>, <b>we</b>, or <b>us</b>)
        </Text>
      </Box>
      <AccordionContent
        title='Address'
        description='2 Apex View, Leeds, West Yorkshire LS11 9BH'
      />
      <AccordionContent
        title='Telephone number(s)'
        description='0344 375 5500'
      />
      {intermediaryName && !isNonBrokered && (
        <Box mb={4}>
          <NovunaHeading data-test-id='credit-intermediary' as='h3' mb={2}>
            Credit intermediary address
          </NovunaHeading>
          <Text>The supplier being, {intermediaryName}</Text>
          {intermediaryAddress && <Text>{intermediaryAddress}</Text>}
        </Box>
      )}
    </AccordionGroupEntry>

    <AccordionGroupEntry
      sectionNumber={2}
      title='Key features'
      description='For the credit product including total amount and duration of the agreement'
      headingVariant='tertiary'
      breakAfter={true}>
      <AccordionContent
        title='The type of credit'
        description='Fixed Sum Loan Agreement'
      />
      <AccordionContent
        title='The total amount of credit'
        hint='This means the amount of credit to be provided under the proposed credit agreement or the credit limit.'
        description={loanAmount}
        descriptionTestId='compass-pcci-loan-amount'
      />
      <AccordionContent
        title='How and when credit would be provided'
        description={howAndWhenCreditWouldBeProvidedText(
          !!immediatePayout,
          !!isNonBrokered
        )}
        descriptionTestId='compass-how-and-when-credit-would-be-provided'
      />
      {agreementDuration && (
        <AccordionContent
          title='The duration of the credit agreement'
          description={creditDurationAgreementText(
            isDeferred,
            agreementDuration
          )}
          descriptionTestId='compass-pcci-agreement-duration'
        />
      )}
      {instalments && repaymentAmount && (
        <AccordionContent
          title='Repayments'
          description={`${instalments} consecutive monthly payments of ${repaymentAmount}`}
        />
      )}
      <AccordionContent
        title='The total amount you will have to pay'
        hint='This means the amount you have borrowed plus interest and other costs.'
        description={totalAmountPayable}
        descriptionTestId='compass-pcci-total-amount'
      />

      {goodsAmount && (
        <Text sx={{ fontWeight: 'bold' }} mb={3}>
          The proposed credit will be linked to the supply of specific goods
          provision of a service
        </Text>
      )}
      {goodsAmount && goodsDescription && (
        <AccordionContent
          title='Description of goods/services'
          description={`${goodsDescription} ${goodsAmount}`}
          descriptionTestId='compass-pcci-goods-description'
        />
      )}
      {goodsAmount && (
        <AccordionContent
          title='Cash price'
          description={`Total cash price ${goodsAmount}`}
          descriptionTestId='compass-pcci-total-cost'
        />
      )}
    </AccordionGroupEntry>

    <AccordionGroupEntry
      sectionNumber={3}
      title='Cost of the credit'
      description='Including interest rate and guidance on late or missed payments'
      headingVariant='tertiary'
      breakAfter={true}>
      {apr && (
        <Box mb={4}>
          <NovunaHeading as='h3' mb={2}>
            The rates of interest which apply to the credit agreement
          </NovunaHeading>
          <Text data-test-id='compass-pcci-interest-rate'>
            {apr} per annum (fixed).
          </Text>
          {isDeferred && earlySettlementFee && (
            <Text>
              0% per annum will be charged if you repay the credit in full prior
              to your first monthly payment falling due, but you will have to
              pay an administration charge if you settle in full before your
              first payment is due. Interest will be charged at 0% per annum on
              any amounts which you repay prior to your first monthly payment
              falling due.
            </Text>
          )}
          {isDeferred && !earlySettlementFee && (
            <Text>
              0% per annum will be charged if you repay the credit in full prior
              to your first monthly payment falling due. Interest will be
              charged at 0% per annum on any amounts which you repay prior to
              your first monthly payment falling due.
            </Text>
          )}
        </Box>
      )}
      {apr && (
        <AccordionContent
          title='Annual Percentage Rate of Charge (APR)'
          hint='This is the total cost expressed as an annual percentage of the total amount of credit. The APR is there to help you compare different offers.'
          description={`${apr} (fixed)`}
          descriptionTestId='compass-pcci-apr'
        />
      )}
      {isDeferred && earlySettlementFee ? (
        <AccordionContent
          title='Any other costs deriving from the credit agreement'
          description='The administration fee for early settlement is the lesser of either the interest accrued during the deferral period or £29'
          descriptionTestId='compass-related-costs'
        />
      ) : (
        <AccordionContent
          title='Any other costs deriving from the credit agreement.'
          description='£0'
          descriptionTestId='compass-related-costs'
        />
      )}

      <Box mb={4}>
        <NovunaHeading as='h3' mb={2}>
          Costs in the case of late payments
        </NovunaHeading>
        <Text>
          Charges will be payable under this agreement in the following events:
        </Text>
        <List fontSize='1'>
          <ListItem>Recalled Direct Debit Charge; &pound;22</ListItem>
          <ListItem>Returned or Recalled Cheques; &pound;25</ListItem>
          <ListItem>Issue of a Default Notice; &pound;25</ListItem>
          <ListItem>
            Letters sent to you as a result of a breach of your credit
            agreement; &pound;25 (but no such charge shall be made in respect of
            any such letters sent to you after a Default Notice has been issued,
            until there are no outstanding arrears on your account, when the
            charge shall become chargeable again Notice is issued).
          </ListItem>
        </List>
        <Text>
          Charges may also be payable for reasons stated in clauses (7a) and
          (8b) of the Terms of Lending of the agreement. We may vary the charges
          payable under this agreement by giving you reasonable notice to
          reflect any variation to the cost and expenses to us in carrying out
          these activities.
        </Text>
      </Box>

      <AccordionContent
        title='Consequences of missing payments'
        description='Missing payments could have severe consequences and may result in legal proceedings being taken against you. This could include obtaining a Court Order for a charge on your home. We will add details of your default to your record with the credit reference agencies, which could also make it more difficult or more expensive for you to obtain credit.'
      />
    </AccordionGroupEntry>

    <AccordionGroupEntry
      sectionNumber={4}
      title='Legal aspects'
      description='Other important legal aspects regarding your rights'
      headingVariant='tertiary'
      breakAfter={true}>
      <AccordionContent
        title='Right of withdrawal'
        description='You will have the right to withdraw from the agreement without giving any reason, for a period of 14 days beginning on the day after the day on which you receive a copy of the executed agreement.'
      />
      <AccordionContent
        title='Early repayment'
        description='You may repay the credit early at any time in full or part.'
      />
      <AccordionContent
        title='Consultation with a Credit Reference Agency'
        description='If we decide not to proceed with your credit application on the basis of information obtained from our credit reference agency, we will notify you of our decision and we will inform you that it has been reached on the basis of information held by our credit reference agency and we will provide you with details of which credit reference agency we used.'
      />
      <AccordionContent
        title='Right to a draft credit agreement'
        description='You have a right upon request to obtain a copy of the draft agreement free of charge. In the event your application is declined we will not provide you with a copy of the agreement.'
      />
    </AccordionGroupEntry>

    <AccordionGroupEntry
      sectionNumber={5}
      title='Additional information'
      description='In the case of distance of financial services'
      headingVariant='tertiary'
      breakAfter={true}>
      <Text mb={3} sx={{ fontWeight: 'bold' }}>
        (a) Concerning the creditor
      </Text>
      <AccordionContent
        title='Registration number'
        description='Our Financial Services Register number is 704348'
      />
      <AccordionContent
        title='The supervisory authority'
        description='The Financial Conduct Authority'
      />
      <Text mb={3} sx={{ fontWeight: 'bold' }}>
        (b) Concerning the credit agreement
      </Text>
      <AccordionContent
        title='The law taken by the creditor as a basis for the establishment of relations with you before the conclusion of the credit agreement'
        description='The law of the country in which you are domiciled, being England, Wales, Scotland or Northern Ireland.'
      />
      <AccordionContent
        title='The law applicable to the credit agreement and/or the competent court'
        description='Our relations with you are based on the law of the country in the United Kingdom where you are domiciled, being England, Wales, Scotland or Northern Ireland and disputes may be referred to the courts of that country.'
      />
      <AccordionContent
        title='Language to be used in connection with the credit agreement'
        description='The contractual terms and conditions of the credit agreement and the information contained on this page will be supplied to you in English. Unless you and we agree otherwise all communications relating to the credit agreement will be in English.'
      />
      <Text mb={3} sx={{ fontWeight: 'bold' }}>
        (c) Concerning redress
      </Text>
      <AccordionContent
        title='Access to out-of-court complaint and redress mechanism'
        description='We operate a complaint handling procedure.'
      />
      <Text mt={2} mb={4}>
        We operate our complaint handling procedure in line with the rules and
        guidance of the Financial Conduct Authority.
      </Text>

      <Text mb={4}>
        If you have a complaint about this agreement you can contact us in any
        of the following ways:
      </Text>

      <Box mb={4}>
        <Text>Email us at:</Text>
        <Link
          href='complaintsteam@novunapersonalfinance.co.uk'
          sx={{ color: 'secondaryPurple' }}>
          complaintsteam@novunapersonalfinance.co.uk
        </Link>
      </Box>

      <Box mb={4}>
        <Text>Call us on:</Text>
        <Link href='0344 375 5500' sx={{ color: 'secondaryPurple' }}>
          0344 375 5500
        </Link>
      </Box>

      <Text>Or write to us at </Text>
      <Text>Novuna Personal Finance,</Text>
      <Text>2 Apex View, </Text>
      <Text>Leeds,</Text>
      <Text mb={4}>LS11 9BH. </Text>

      <Text mb={4}>
        If our complaints process has not resolved it to your satisfaction then
        you have a right to complain to the Financial Ombudsman Service. You can
        write to the{' '}
      </Text>

      <Text>Financial Ombudsman Service, </Text>
      <Text>Exchange Tower, </Text>
      <Text>London,</Text>
      <Text mb={4}>E14 9SR </Text>

      <Text>
        or visit their website at{' '}
        <Link
          sx={{ color: 'secondaryPurple' }}
          href='www.financial-ombudsman.org.uk'>
          www.financial-ombudsman.org.uk
        </Link>
      </Text>
    </AccordionGroupEntry>
  </AccordionGroup>
);
