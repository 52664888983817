import {
  Button,
  NovunaHeading,
  Box,
  Message,
  ReactModal,
  InputWrapper,
  TextField
} from 'compass-design';
import React from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';

export interface IncomeValidationValues {
  incomeValue: number;
}
export interface IncomeValidationModalProps {
  modalOpen: boolean;
  toggleModalOpen: (isOpen: boolean) => void;
  originalIncomeValue: number;
  onSubmit: (confirmedIncomeValue: number) => void;
}

export const IncomeValidationModal: React.FC<IncomeValidationModalProps> = ({
  modalOpen,
  toggleModalOpen,
  originalIncomeValue,
  onSubmit
}) => {
  const initialValues: IncomeValidationValues = {
    incomeValue: originalIncomeValue
  };

  return (
    <ReactModal
      title='Before you continue'
      size='medium'
      isOpen={modalOpen}
      setIsOpen={toggleModalOpen}>
      <Message variant='warning'>
        <NovunaHeading as='h3'>Check annual income</NovunaHeading>
        Please check that you have entered your annual income correctly before
        continuing.
      </Message>

      <Formik
        initialValues={initialValues}
        onSubmit={({ incomeValue }) => onSubmit(incomeValue)}>
        <Form noValidate className='income-confirmation-modal'>
          <Box mt={4}>
            <Field name='incomeValue'>
              {({
                field,
                form,
                meta
              }: FieldProps<number, IncomeValidationValues>) => (
                <InputWrapper
                  id='income-value'
                  label='Annual income'
                  description='The amount you earn in one year, before tax and deductions'
                  error={meta.error}
                  tone={meta.touched && meta.error ? 'negative' : 'neutral'}>
                  <TextField
                    short
                    {...field}
                    id='income-value'
                    type='text'
                    autoCapitalize='none'
                    tone={meta.touched && meta.error ? 'negative' : 'neutral'}
                    onChange={({
                      target: { value }
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      form.setFieldValue('incomeValue', parseInt(value));
                    }}
                  />
                </InputWrapper>
              )}
            </Field>
          </Box>
          <Button
            type='submit'
            mt={[5, null, 6]}
            isJumbo
            data-test-id='income-validation-modal-continue-btn'>
            Continue
          </Button>
        </Form>
      </Formik>
    </ReactModal>
  );
};
