import { Box } from 'compass-design';
import { Address, AddressWithDate } from 'hitachi-retail-core';
import { IncompleteFinanceApplication } from 'hitachi-retail-core/build/schemas/financeApplication';
import { JSONSchema6 } from 'json-schema';
import React, { Fragment } from 'react';
import {
  getSchemaDisplayValue,
  getSchemaPropertyFormData,
  getSchemaSectionProperties
} from '../form/schema';
import { getAddressLines } from '../utils/address';
import { yearMonthDiffString } from '../utils/dates';
import {
  formatBankSortCode,
  formatCurrency,
  formatDate,
  formatNumber,
  joinValues
} from '../utils/formatters';

export interface SectionSummaryDefinition {
  title?: string;
  properties: string | string[];
  format?: string;
}

export interface SectionSummaryDefinitions {
  [key: string]: SectionSummaryDefinition[];
}

export interface ApplicationSectionSummaryProps {
  definitions: SectionSummaryDefinitions;
  sectionKey: string;
  applicationData: IncompleteFinanceApplication;
  schema: JSONSchema6;
}

const ApplicationSectionSummary: React.FC<ApplicationSectionSummaryProps> = ({
  definitions,
  sectionKey,
  applicationData,
  schema
}) => {
  const sectionDefinitions = definitions[sectionKey];

  const entries = sectionDefinitions.reduce<JSX.Element[]>(
    (entries, definition, index) => {
      const { value } = getDefinitionEntryValues(
        sectionKey,
        applicationData,
        definition,
        schema
      );

      if (value) {
        entries.push(
          <Fragment key={index}>
            {value}
            <br />
          </Fragment>
        );
      }

      return entries;
    },
    []
  );

  return entries.length > 0 ? (
    <Box
      p='12px'
      sx={{
        backgroundColor: 'form.input.disabled.background',
        border: '2px solid',
        borderColor: 'monochrome.5',
        borderRadius: 'panel'
      }}>
      {entries}
    </Box>
  ) : null;
};

const getDefinitionEntryValues = (
  sectionKey: string,
  applicationData: IncompleteFinanceApplication,
  definition: SectionSummaryDefinition,
  schema: JSONSchema6
): {
  title?: string;
  value: JSX.Element | string;
} => {
  let title = definition.title;
  let value: any;

  // Multiple properties to be combined in some way
  if (Array.isArray(definition.properties)) {
    const valueArray = definition.properties.map((property: string) =>
      getSchemaDisplayValue(property, sectionKey, schema, applicationData)
    );
    // Only current use case is to join values with space separator
    value = joinValues(valueArray, ' ');
  }
  // Single property
  else {
    const propertyKey = definition.properties;
    const schemaSectionProperties = getSchemaSectionProperties(
      sectionKey,
      schema,
      applicationData
    );
    const propertySchema =
      schemaSectionProperties &&
      (schemaSectionProperties[propertyKey] as JSONSchema6);

    if (!title && propertySchema?.title) {
      title = propertySchema.title;
    }

    value = getSchemaDisplayValue(
      propertyKey,
      sectionKey,
      schema,
      applicationData
    );

    if (definition.format) {
      value = getFormattedDisplayValue(
        value,
        definition.format,
        propertyKey,
        sectionKey,
        applicationData
      );
    }
  }

  return { title, value };
};

const getFormattedDisplayValue = (
  inputValue: string,
  format: string,
  propertyKey: string,
  sectionKey: string,
  applicationData: IncompleteFinanceApplication
): JSX.Element | string => {
  const propertyData = getSchemaPropertyFormData(
    propertyKey,
    sectionKey,
    applicationData
  );

  switch (format) {
    case 'bankSortCode':
      return formatBankSortCode(inputValue);
    case 'currency':
      return formatCurrency(inputValue);
    case 'currencyWhole':
      return formatCurrency(inputValue, true);
    case 'number':
      return formatNumber(inputValue);
    case 'date':
      return formatDate({ value: inputValue });
    case 'address':
      if (propertyData) {
        return renderAddressSummary(propertyData);
      }
      return inputValue;
    case 'currentAddress':
      if (propertyData && propertyData[0] && propertyData[0].address) {
        return renderAddressSummary(propertyData[0].address);
      }
      return inputValue;
    case 'timeAtCurrentAddress':
      if (propertyData && propertyData[0]) {
        return renderTimeAtAddressSummary(propertyData[0]);
      }
      return inputValue;
    default:
      return inputValue;
  }
};

const renderAddressSummary = (address: Address): JSX.Element => (
  <span>
    {getAddressLines(address)
      .filter(line => line)
      .map((line, index) => (
        <Fragment key={index}>
          {line}
          <br />
        </Fragment>
      ))}
  </span>
);

const renderTimeAtAddressSummary = (addressWithDate: AddressWithDate): string =>
  yearMonthDiffString(addressWithDate.fromYear, addressWithDate.fromMonth);

export default ApplicationSectionSummary;
