import React from 'react';
import { Route, RouteChildrenProps, Switch } from 'react-router';
import { routes } from '../../../routes';
import AuthCallback from '../AuthCallBack';
import { useDispatch } from 'react-redux';
import { resetStore } from '../../../store/actions';
import querystring from 'querystring';

const ApplicantLoggedOutApp: React.FC<RouteChildrenProps> = ({ location }) => {
  const dispatch = useDispatch();
  const { state: authState } = querystring.parse(
    location.search.replace(/^\?/, '')
  );

  // reset store for PBF flow
  if (authState) {
    dispatch(resetStore());
  }

  return (
    <Switch>
      <Route path={routes.mailOrder.auth} component={AuthCallback} />
    </Switch>
  );
};

export default ApplicantLoggedOutApp;
