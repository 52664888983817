import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { closeModal, showModal } from '../../store/ui/actions';
import {
  EndAndSendButton,
  EndAndSendButtonPropsFromDispatch
} from '../components/EndAndSendButton';
import { sendApplicationReset } from '../store/actions';

export const mapDispatchToProps = (
  dispatch: Dispatch
): EndAndSendButtonPropsFromDispatch => ({
  showModal: config => {
    // Clear down any previous state
    dispatch(sendApplicationReset());
    dispatch(showModal(config));
  },
  closeModal: () => {
    dispatch(closeModal());
  }
});

export default connect(null, mapDispatchToProps)(EndAndSendButton);
