import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';
import { User } from 'hitachi-retail-core/build/services/users/usersService';
import { Reducer } from 'redux';
import { UserFormData } from 'retailerApp/utils/editingUsers';
import { ActionType, getType } from 'typesafe-actions';
import {
  createUserAction,
  lookupUserAction,
  resetUser,
  selectedUserAction,
  setSelectedBranch,
  updateUserAction
} from './actions';

export type CreateUserAction = ActionType<typeof createUserAction>;
export type SelectedUserAction = ActionType<typeof selectedUserAction>;
export type UpdateUserAction = ActionType<typeof updateUserAction>;
export type LookupUserAction = ActionType<typeof lookupUserAction>;
export type ResetUser = ActionType<typeof resetUser>;
export type SetSelectedBranch = ActionType<typeof setSelectedBranch>;

export interface UserLookupState {
  loading: boolean;
  result?: User[];
  success?: boolean;
  errorMessage?: string;
}

export interface State {
  loading: boolean;
  success?: boolean;
  errorMessage?: string;
  editUser: {
    user?: UserFormData;
  };
  userLookup: UserLookupState;
  selectedBranch?: Retailer;
}

export const initialState: State = {
  loading: false,
  editUser: {},
  userLookup: {
    loading: false
  }
};

export const reducer: Reducer<
  State,
  | CreateUserAction
  | UpdateUserAction
  | SelectedUserAction
  | ResetUser
  | LookupUserAction
  | SetSelectedBranch
> = (state = initialState, action) => {
  switch (action.type) {
    case getType(updateUserAction.request):
    case getType(createUserAction.request): {
      return { ...state, loading: true };
    }

    case getType(updateUserAction.success):
    case getType(createUserAction.success): {
      return {
        ...state,
        loading: false,
        success: true
      };
    }

    case getType(updateUserAction.failure): {
      return {
        ...state,
        loading: false,
        success: false
      };
    }

    case getType(createUserAction.failure): {
      return {
        ...state,
        errorMessage: action.payload.message,
        loading: false,
        success: false
      };
    }

    case getType(selectedUserAction.success): {
      return {
        ...state,
        editUser: {
          user: action.payload
        }
      };
    }

    case getType(lookupUserAction.request): {
      return { ...state, userLookup: { loading: true } };
    }

    case getType(lookupUserAction.success): {
      return {
        ...state,
        userLookup: {
          loading: false,
          success: true,
          result: action.payload
        }
      };
    }

    case getType(lookupUserAction.failure): {
      return {
        ...state,
        userLookup: {
          errorMessage: action.payload.message,
          loading: false,
          success: false
        }
      };
    }

    case getType(resetUser): {
      return {
        ...state,
        editUser: {},
        userLookup: { loading: false }
      };
    }

    case getType(setSelectedBranch): {
      return {
        ...state,
        selectedBranch: action.payload.selectedBranch
      };
    }

    default: {
      return state;
    }
  }
};
