import { push } from 'connected-react-router';
import { put, select, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { routes } from '../../routes';
import { RootState } from '../../store';
import {
  resumeApplication,
  updateActiveApplication,
  resetActiveApplicationStore
} from '../../store/application/actions';
import { setApplicationId } from '../../store/save/actions';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';

export const selectPreviousApplicationDocument = (state: RootState) =>
  state.previousApplicationDetail.previousApplication &&
  state.previousApplicationDetail.previousApplication.document;

export const selectPreviousApplicationSoftSearchExpiry = (state: RootState) =>
  state.previousApplicationDetail.previousApplication &&
  state.previousApplicationDetail.previousApplication.softSearchExpiryDate;

export const selectPreviousApplicationStatus = (state: RootState) =>
  state.previousApplicationDetail.previousApplication &&
  state.previousApplicationDetail.previousApplication.status;

export const selectPreviousApplicationId = (state: RootState) =>
  state.previousApplicationDetail.previousApplication &&
  state.previousApplicationDetail.previousApplication.id;

export const selectPreviousApplicationApplicationExpiry = (state: RootState) =>
  state.previousApplicationDetail.previousApplication &&
  state.previousApplicationDetail.previousApplication.applicationExpiryDate;

export const selectPreviousMaxInstalmentAmount = (state: RootState) =>
  state.previousApplicationDetail.previousApplication &&
  state.previousApplicationDetail.previousApplication.maxInstalmentAmount;

export function* resumeApplicationSaga() {
  const applicationDocument = yield select(selectPreviousApplicationDocument);
  const id = yield select(selectPreviousApplicationId);

  if (applicationDocument && id) {
    const softSearchExpiryDate = yield select(
      selectPreviousApplicationSoftSearchExpiry
    );
    const applicationStatus: ApplicationStatus | undefined = yield select(
      selectPreviousApplicationStatus
    );
    const applicationExpiryDate = yield select(
      selectPreviousApplicationApplicationExpiry
    );

    const maxInstalmentAmount = yield select(selectPreviousMaxInstalmentAmount);

    yield put(resetActiveApplicationStore());

    yield put(
      updateActiveApplication({
        document: applicationDocument,
        status: applicationStatus,
        softSearchExpiryDate,
        applicationExpiryDate,
        maxInstalmentAmount
      })
    );
    yield put(setApplicationId({ id }));
    yield put(push(routes.apply.quote));
  }
}

export function* resumeApplicationSagaWatcher() {
  yield takeEvery(getType(resumeApplication), resumeApplicationSaga);
}
