import React from 'react';
import { Text, Box, Button, NovunaHeading, SummaryRow } from 'compass-design';
import { formatCurrency, formatPercent } from 'utils/formatters';
import CreditSearchMessage from 'retailerApp/components/CreditSearchMessage';
import { LoanComparisonCardPreStringValues } from 'retailerApp/components/MixingDeck/getMixingDeckData';

export interface SoftAcceptOrReferProps {
  originalOffer: Omit<LoanComparisonCardPreStringValues, 'updatedItem'>;
  applicationId: string;
  deferralPeriod: number;
  isRefer?: boolean;
  onClickProceedToDecision: () => void;
}

const SoftAcceptOrRefer = ({
  originalOffer,
  isRefer,
  applicationId,
  deferralPeriod,
  onClickProceedToDecision
}: SoftAcceptOrReferProps) => (
  <>
    {isRefer ? (
      <>
        <Text mb={4} sx={{ fontSize: 2 }} data-test-id='soft-refer-text'>
          The application must be submitted for a full credit search and
          reviewed in detail before a decision can be made.
        </Text>

        <Text mb={4} sx={{ fontSize: 2 }}>
          Novuna Consumer Finance will contact the customer with the outcome
          after it has been reviewed.
        </Text>
      </>
    ) : (
      <>
        <Text mb={4} sx={{ fontSize: 2 }} data-test-id='soft-accept-text'>
          Good news, this application would be accepted.
        </Text>
      </>
    )}

    <CreditSearchMessage />

    <>
      <NovunaHeading as='h2' mb={2}>
        Loan application
      </NovunaHeading>
      <Text sx={{ color: 'monochrome.1', fontWeight: 'bold' }}>
        {applicationId}
      </Text>

      <Box mt={3} mb={6}>
        <SummaryRow
          label='Total cost'
          data-test-id='soft-accept-goods-amount'
          value={formatCurrency(originalOffer?.totalCost)}
          isWhite
        />
        <SummaryRow
          label='Deposit'
          data-test-id='soft-accept-deposit'
          value={formatCurrency(originalOffer.deposit)}
        />
        <SummaryRow
          label='Amount borrowed'
          data-test-id='soft-accept-amount-borrowed'
          value={formatCurrency(originalOffer.amountBorrowed)}
          isWhite
        />
        <SummaryRow
          label='Repaid over'
          data-test-id='soft-accept-repayments'
          value={`${originalOffer.repaidOver} Months`}
        />
        <SummaryRow
          label='Deferred for'
          data-test-id='soft-accept-repayments'
          value={`${deferralPeriod} Months`}
          isWhite
        />
        <SummaryRow
          label='Interest rate'
          value={formatPercent(originalOffer.interestRate) ?? ''}
        />
        <SummaryRow
          label='Representative APR'
          value={`${formatPercent(originalOffer.representativeAPR)} APR`}
          isWhite
        />
        <SummaryRow
          label='Interest payable'
          value={formatCurrency(originalOffer.interestPayable)}
        />
        <SummaryRow
          label='Total payable'
          data-test-id='soft-accept-total-payable'
          value={formatCurrency(originalOffer.totalAmountPayable)}
          isWhite
        />
        <SummaryRow
          label='Monthly repayment'
          data-test-id='soft-accept-monthly-repayment'
          value={formatCurrency(originalOffer.monthlyRepayment)}
        />

        <Button isJumbo mt={5} onClick={onClickProceedToDecision}>
          Confirm and apply
        </Button>
      </Box>
    </>
  </>
);

export default SoftAcceptOrRefer;
