import React from 'react';
import {
  Box,
  Table,
  TableRow,
  Text,
  NovunaArrowRight,
  Badge,
  Pagination,
  TextButton
} from 'compass-design';
import { PgUser } from 'hitachi-retail-core/build/services/pgUsers/pgUsersService';
import { formatDate } from 'utils/formatters';
import {
  getNumberOfPages,
  getPaginatedItems,
  PaginateStateProps
} from 'retailerApp/components/Paginate/Paginate';
import { coerceDateToString, trim } from '../utils';

export interface UsersTableProps extends PaginateStateProps {
  users: PgUser[];
  currentSupplierId: string;
  showSupplierNoLink: boolean;
  showTable: boolean;
  selectUser: (user: PgUser) => void;
  selectBranchId: (selectedBranchId: string) => void;
}

const UsersTable = ({
  users,
  currentPage,
  currentSupplierId,
  showSupplierNoLink,
  selectPage,
  selectUser,
  selectBranchId
}: UsersTableProps) => (
  <Box>
    <Box sx={{ width: '100%', overflowX: 'auto' }} mb={5}>
      <Table
        sx={{
          minWidth: '1000px'
        }}
        columnNames={[
          'Username',
          'Forename',
          'Surname',
          'Email',
          'Role',
          'Supplier',
          'Created',
          'Updated',
          'Status',
          ''
        ]}
        data-test-id='compass-users-list'>
        {getPaginatedItems({ data: users, currentPage }).map(user => {
          const {
            username,
            email,
            firstName,
            lastName,
            created,
            updated,
            enabled,
            role,
            defaultBranch
          } = user;
          return (
            <TableRow key={username} data-test-id={username}>
              <Text>{trim(username)}</Text>
              <Text>{trim(firstName)}</Text>
              <Text>{trim(lastName)}</Text>
              <Text
                sx={{
                  minWidth: '100px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>
                {email}
              </Text>
              <Text sx={{ minWidth: '100px' }}>{role}</Text>
              {showSupplierNoLink && currentSupplierId !== defaultBranch ? (
                <TextButton
                  sx={{
                    cursor: 'pointer',
                    color: 'secondaryPurple',
                    margin: 0,
                    minWidth: 0,
                    appearance: 'none',
                    display: 'flex',
                    textAlign: 'center',
                    border: 0,
                    borderRadius: '4px',
                    backgroundColor: 'transparent',
                    textDecoration: 'underline',
                    padding: 0
                  }}
                  onClick={() => {
                    selectBranchId(defaultBranch);
                  }}>
                  {defaultBranch}
                </TextButton>
              ) : (
                <Text>{defaultBranch}</Text>
              )}
              <Text>
                {created &&
                  formatDate({
                    value: coerceDateToString(created),
                    dateFormatOverride: 'DD/MM/YY'
                  })}
              </Text>
              <Text>
                {updated &&
                  formatDate({
                    value: coerceDateToString(updated),
                    dateFormatOverride: 'DD/MM/YY'
                  })}
              </Text>
              <Badge
                variant='primary'
                ml={2}
                mt='auto'
                sx={{
                  backgroundColor: enabled
                    ? 'rgba(215, 255, 215, 0.6)'
                    : 'rgba(218, 218, 218, 0.6)',
                  width: enabled ? '45px' : '76px',
                  borderRadius: '6px'
                }}>
                {enabled ? 'Active' : 'Deactivated'}
              </Badge>
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => selectUser(user)}
                data-test-id={`${username}-user-select`}>
                <NovunaArrowRight />
              </Box>
            </TableRow>
          );
        })}
      </Table>
    </Box>
    <Pagination
      currentPage={currentPage}
      selectPage={(selectedPage: number) => {
        selectPage(selectedPage);
      }}
      totalNumberOfPages={getNumberOfPages({
        totalNumberOfItems: users.length
      })}
    />
  </Box>
);

export default UsersTable;
