import { IncompleteFinanceApplication } from 'hitachi-retail-core/build/schemas/financeApplication';
import {
  selectEmailAddress,
  selectPhoneNumber
} from 'hitachi-retail-core/build/selectors';
import { connect, MapStateToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../store';
import { updateActiveApplication } from '../../store/application/actions';
import { selectApplicationId } from '../../store/save/reducer';
import {
  EndAndSendContent,
  EndAndSendOwnProps,
  EndAndSendPropsFromDispatch,
  EndAndSendPropsFromState,
  SendToCustomerState
} from '../components/EndAndSend';
import { sendApplication } from '../store/actions';
import { EndAndSendState } from '../store/reducer';
import { deliveryAddressTypeValue } from 'hitachi-retail-core/build/enums';
import { ApplicationModificationStatus } from 'hitachi-retail-core/build/application/modification';

export const mapStateToProps: MapStateToProps<
  EndAndSendPropsFromState,
  EndAndSendOwnProps,
  RootState
> = (state: RootState, ownProps) => {
  const {
    endAndSend: { errorMessage, currentState },
    application: {
      activeApplication: { document, modificationStatus }
    },
    retailer: { retailer: retailerConfig },
    router: {
      location: { pathname }
    }
  } = state;

  const mailorderEnabled =
    (retailerConfig && retailerConfig.mailorderEnabled) === true;

  const simpleStateMap = new Map<EndAndSendState, SendToCustomerState>([
    [EndAndSendState.Error, SendToCustomerState.Error],
    [EndAndSendState.Loading, SendToCustomerState.Loading],
    [EndAndSendState.SentToCustomer, SendToCustomerState.Success]
  ]);

  const emailAddress = selectEmailAddress(document);
  const phoneNumber = selectPhoneNumber(document);
  const firstName = document?.personalDetails?.firstName;
  const lastName = document?.personalDetails?.lastName;
  let sendToCustomerState: SendToCustomerState;
  sendToCustomerState =
    simpleStateMap.get(currentState) || SendToCustomerState.EnterDetails;

  if (
    sendToCustomerState === SendToCustomerState.EnterDetails &&
    firstName &&
    lastName &&
    emailAddress &&
    phoneNumber
  ) {
    sendToCustomerState = SendToCustomerState.DetailsCaptured;
  }

  const applicationId = selectApplicationId(state);

  return {
    errorMessage,
    currentState: sendToCustomerState,
    document,
    mailorderEnabled,
    applicationId,
    modificationStatus,
    pathname,
    ...ownProps
  };
};

export const mapDispatchToProps = (
  dispatch: Dispatch
): EndAndSendPropsFromDispatch => ({
  sendApplication: () => dispatch(sendApplication.request()),
  updateApplication: (formData, document, modificationStatus) => {
    // We must not override anything in the application document after the decision
    if (modificationStatus !== ApplicationModificationStatus.YES) {
      dispatch(
        updateActiveApplication({
          document
        })
      );

      return;
    }

    const { firstName, lastName, emailAddress, phoneNumber } = formData;
    // Override the form data as required for Decision
    const customerPresent = false;
    const goodsDelivered = deliveryAddressTypeValue.CurrentAddress;
    const updates: IncompleteFinanceApplication = {
      customerPresent,
      personalDetails: {
        firstName,
        lastName
      },
      contactDetails: {
        emailAddress,
        phoneNumber
      },
      deliveryDetails: {
        goodsDelivered
      }
    };

    dispatch(
      updateActiveApplication({
        document: updates
      })
    );
  }
});

export const EndAndSend = connect(
  mapStateToProps,
  mapDispatchToProps
)(EndAndSendContent);
