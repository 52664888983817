import { DefaultSagaParams } from '../../sagas';

export interface SessionCheckService {
  checkSession: (id: string) => Promise<void>;
}

export const getSessionCheckService = ({
  apiClient
}: DefaultSagaParams): SessionCheckService => ({
  checkSession: async (id: string): Promise<void> => {
    try {
      await apiClient.get(`mo/application/${id}/session`);
    } catch (err) {
      throw new Error(`Failed to check session: ${err}`);
    }
  }
});
