import {
  AdvancedRadioButtonGroup,
  Box,
  Button,
  InputWrapper,
  Message,
  NovunaHeading,
  ReactModal,
  ReadOnlyWrapper,
  TextField,
  Text
} from 'compass-design';
import { Field, FieldProps, Form, Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getInitialUserValues,
  UserFormData,
  validateUser
} from 'retailerApp/utils/editingUsers';
import {
  createEnhancedUserAction,
  setEditUser,
  updateEnhancedUserAction
} from 'store/enhancedUsers/actions';
import {
  selectEditUser,
  selectSelectedEnhancedBranch,
  selectSelectedEnhancedBroker,
  selectSelectedEnhancedHeadOffice,
  selectSelectedEnhancedUser
} from 'store/enhancedUsers/selectors';
import { selectUserRoles } from 'store/user/selectors';
import { labelPropsFromMeta } from 'utils/labelPropsFromMeta';
import {
  getAdvancedRadioOptionsForUser,
  getDefaultUserRole,
  getRetailer
} from '../utils';
import Switch from './Switch';

const UserModal = () => {
  const dispatch = useDispatch();
  const { isCreate, isLoading, isEditing, errorMessage } = useSelector(
    selectEditUser
  );
  const selectedUser = useSelector(selectSelectedEnhancedUser);
  const userRoles = useSelector(selectUserRoles) ?? [];
  const selectedBroker = useSelector(selectSelectedEnhancedBroker);
  const selectedHeadOffice = useSelector(selectSelectedEnhancedHeadOffice);
  const selectedBranch = useSelector(selectSelectedEnhancedBranch);

  const retailer = getRetailer({
    selectedBroker,
    selectedHeadOffice,
    selectedBranch
  });

  if (!retailer) {
    return null;
  }
  const defaultRole = getDefaultUserRole({
    userRoles,
    selectedBroker,
    selectedBranch,
    selectedHeadOffice
  });

  const initialValues = getInitialUserValues({
    isCreate: !!isCreate,
    defaultRole,
    retailer,
    userToEdit: selectedUser ?? undefined
  });

  const modalType = isCreate ? 'New' : 'Edit';

  const bottomHeight = errorMessage ? '229px' : '116px';

  return (
    <ReactModal
      isOpen={isEditing && !!retailer}
      setIsOpen={() => dispatch(setEditUser({ isEditing: false }))}
      size='medium'
      title={`${modalType} user`}
      noScroll>
      <Box sx={{ paddingLeft: '2px' }}>
        <Formik
          initialValues={{
            ...initialValues,
            enabled: initialValues.enabled ? 'true' : 'false',
            isCreate
          }}
          enableReinitialize
          onSubmit={values => {
            const user = {
              email: values.email,
              familyName: values.familyName,
              givenName: values.givenName,
              username: values.username,
              enabled: values.enabled === 'true',
              retailerName: initialValues.retailerName || '',
              defaultBranch: initialValues.defaultBranch,
              groups: [values.groups]
            };

            if (isCreate) {
              dispatch(createEnhancedUserAction.request(user));
            } else {
              dispatch(
                updateEnhancedUserAction.request({
                  ...user,
                  username: `${initialValues.retailerName}/${values.username}`
                })
              );
            }
          }}
          validate={values => {
            return validateUser({
              ...values
            } as any);
          }}>
          <Form key={`${modalType}-user`} name={`${modalType}-user`}>
            <Box>
              <Box
                sx={{
                  overflowY: 'auto',
                  maxHeight: `calc(100vh - calc(101px + ${bottomHeight}))`
                }}>
                <Field name='enabled' type='checkbox'>
                  {({ form, field }: FieldProps<string, UserFormData>) => (
                    <Box
                      mt={3}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}>
                      <Text
                        sx={{ color: 'monochrome.1', fontWeight: 'heading' }}>
                        Account active
                      </Text>
                      <Switch
                        onChange={() =>
                          form.setFieldValue(
                            'enabled',
                            field.value === 'true' ? 'false' : 'true'
                          )
                        }
                        isChecked={field.value === 'true'}
                      />
                    </Box>
                  )}
                </Field>

                <Box mt={4}>
                  <ReadOnlyWrapper label='Retailer'>
                    <Box
                      sx={{
                        flex: '0 1 auto',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}>
                      {initialValues.retailerName} (
                      {initialValues.defaultBranch})
                    </Box>
                  </ReadOnlyWrapper>
                </Box>

                <Field name='givenName'>
                  {({ field, meta }: FieldProps<string, UserFormData>) => (
                    <Box mt={4}>
                      <InputWrapper
                        id='givenName'
                        label='Forename'
                        error={meta.error}
                        {...labelPropsFromMeta({ meta }, 'label')}>
                        <TextField
                          {...labelPropsFromMeta({ meta }, 'input')}
                          type='text'
                          {...field}
                          id='givenName'
                        />
                      </InputWrapper>
                    </Box>
                  )}
                </Field>

                <Field name='familyName'>
                  {({ field, meta }: FieldProps<string, UserFormData>) => (
                    <Box mt={4}>
                      <InputWrapper
                        id='familyName'
                        label='Surname'
                        error={meta.error}
                        {...labelPropsFromMeta({ meta }, 'label')}>
                        <TextField
                          {...labelPropsFromMeta({ meta }, 'input')}
                          type='text'
                          {...field}
                          id='familyName'
                        />
                      </InputWrapper>
                    </Box>
                  )}
                </Field>

                <Field name='email'>
                  {({ field, meta }: FieldProps<string, UserFormData>) => (
                    <Box mt={4}>
                      <InputWrapper
                        id='email'
                        label='Email'
                        error={meta.error}
                        {...labelPropsFromMeta({ meta }, 'label')}>
                        <TextField
                          {...labelPropsFromMeta({ meta }, 'input')}
                          type='text'
                          {...field}
                          id='email'
                        />
                      </InputWrapper>
                    </Box>
                  )}
                </Field>

                <Field name='username'>
                  {({ field, meta }: FieldProps<string, UserFormData>) => (
                    <Box mt={4}>
                      {!isCreate && (
                        <ReadOnlyWrapper label='Username'>
                          {field.value}
                        </ReadOnlyWrapper>
                      )}

                      {isCreate && (
                        <InputWrapper
                          id='username'
                          label='Username'
                          error={meta.error}
                          {...labelPropsFromMeta({ meta }, 'label')}>
                          <TextField
                            short
                            {...labelPropsFromMeta({ meta }, 'input')}
                            type='text'
                            {...field}
                            id='username'
                          />
                        </InputWrapper>
                      )}
                    </Box>
                  )}
                </Field>

                <Field name='groups'>
                  {({
                    meta,
                    form,
                    field
                  }: FieldProps<string, UserFormData>) => (
                    <Box mt={4}>
                      <InputWrapper
                        id='groups'
                        label='Role'
                        error={meta.error}
                        {...labelPropsFromMeta({ meta }, 'label')}>
                        <AdvancedRadioButtonGroup
                          options={getAdvancedRadioOptionsForUser({
                            userRoles,
                            selectedBranch,
                            selectedHeadOffice,
                            selectedBroker,
                            isEditingBranchUser:
                              !isCreate &&
                              !!selectedHeadOffice &&
                              retailer.supplierNumber !==
                                initialValues?.defaultBranch
                          })}
                          selectedId={field.value}
                          onClick={id => form.setFieldValue('groups', id)}
                          twoColumnLayout
                        />
                      </InputWrapper>
                    </Box>
                  )}
                </Field>
              </Box>
              <Box sx={{ height: bottomHeight }}>
                {errorMessage && (
                  <Box mt={4}>
                    <Message variant='error'>
                      <NovunaHeading as='h3' mb={1}>
                        There was an issue {isCreate ? 'creating' : 'editing'}{' '}
                        the user
                      </NovunaHeading>
                      <Text>Please check their details and try again.</Text>
                    </Message>
                  </Box>
                )}

                <Button
                  isJumbo
                  type='submit'
                  mt={5}
                  data-test-id='edit-user-button'
                  disabled={!!isLoading}>
                  {isCreate ? 'Create user' : 'Update'}
                </Button>
              </Box>
            </Box>
          </Form>
        </Formik>
      </Box>
    </ReactModal>
  );
};

export default UserModal;
