import {
  NovunaHeading,
  NovunaSingleColumnLayout,
  TertiaryLink,
  TertiaryLinkDirection
} from 'compass-design';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'store';
import { selectEnabledFeatures } from 'store/config/selectors';
import { getApplicationStartRoute } from '../../routes';

const NoMatch = () => {
  const enabledFeatures = useSelector(selectEnabledFeatures);
  const history = useHistory();

  return (
    <NovunaSingleColumnLayout
      pageTop={
        <TertiaryLink
          text='Go home'
          direction={TertiaryLinkDirection.BACKWARDS}
          onClick={() => {
            history.push(getApplicationStartRoute(enabledFeatures));
          }}
        />
      }>
      <NovunaHeading as='h1' data-test-id='compass-route-not-found'>
        Page not found
      </NovunaHeading>
    </NovunaSingleColumnLayout>
  );
};

export default NoMatch;
