import { Box, Text } from 'compass-design';
import React from 'react';

export const DemoWatermark = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#000000',
        opacity: '10%',
        position: 'fixed',
        top: '0',
        left: '0',
        width: '200px',
        height: '200px',
        clipPath: 'polygon(0 0, 0% 100%, 100% 0);',
        justifyContent: 'center'
      }}>
      <Text
        sx={{
          padding: '15px 0',
          color: 'white',
          fontSize: '45px',
          fontWeight: 'bold',
          transform: 'rotate(-45deg)'
        }}>
        DEMO
      </Text>
    </Box>
  );
};
