import { Box, Message, NovunaHeading, Text } from 'compass-design';
import { Decimal } from 'hitachi-retail-core/build/utils/decimal';
import { Address } from 'hitachi-retail-core';
import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import { formatCurrency } from 'hitachi-retail-core/build/utils/currency';
import React, { useEffect, useState } from 'react';
import DocumentDownload from 'retailerApp/components/DocumentDownload';
import SigningHandoff from 'retailerApp/components/SigningHandoff/SigningHandoff';
import { PreContractCreditInformation } from 'shared/components/LoanInformation';
import { formatAddressSingleLine, formatPercent } from 'utils/formatters';
import FeatureToggle from '../../../containers/meta/FeatureToggle';

export interface PreContractCreditInformationPagePropsFromState {
  shouldDownloadDocument: boolean;
  applicationId?: string;
  documentDownloadUrl?: string;
  intermediaryName: string;
  intermediaryAddress: Address;
  agreementDuration: number;
  goodsDescription: string;
  goodsAmount: Decimal;
  loanAmount: Decimal;
  repaymentAmount: Decimal;
  instalments: number;
  totalAmountPayable: Decimal;
  apr: Decimal;
  productIsDeferred?: boolean;
  productEarlySettlementFee?: boolean;
  immediatePayoutEnabled: boolean;
}

export interface PreContractCreditInformationPagePropsFromDispatch {
  fetchDocumentDownloadUrl: (applicationId: string) => void;
  onDocumentDownload: (applicationId: string) => void;
}

export type PreContractCreditInformationPageProps = PreContractCreditInformationPagePropsFromState &
  PreContractCreditInformationPagePropsFromDispatch;

const PreContractCreditInformationPage: React.FunctionComponent<PreContractCreditInformationPageProps> = (
  props: PreContractCreditInformationPageProps
) => {
  const {
    shouldDownloadDocument,
    documentDownloadUrl,
    applicationId,
    fetchDocumentDownloadUrl,
    onDocumentDownload
  } = props;

  const [documentDownloaded, setDocumentDownloaded] = useState(false);
  const [proceedToSigningAttempted, setProceedToSigningAttempted] = useState(
    false
  );

  useEffect(() => {
    if (shouldDownloadDocument && applicationId) {
      fetchDocumentDownloadUrl(applicationId);
    }
  }, [applicationId, shouldDownloadDocument, fetchDocumentDownloadUrl]);

  return (
    <>
      <NovunaHeading as='h1' id='loaninformation-heading' mb={2}>
        Loan information
      </NovunaHeading>
      <PreContractCreditInformation
        intermediaryName={props.intermediaryName}
        intermediaryAddress={formatAddressSingleLine(props.intermediaryAddress)}
        goodsDescription={props.goodsDescription}
        loanAmount={formatCurrency(props.loanAmount)}
        repaymentAmount={formatCurrency(props.repaymentAmount)}
        agreementDuration={props.agreementDuration}
        instalments={props.instalments}
        goodsAmount={formatCurrency(props.goodsAmount)}
        totalAmountPayable={formatCurrency(props.totalAmountPayable)}
        apr={formatPercent(props.apr) ?? ''}
        earlySettlementFee={!!props.productEarlySettlementFee}
        isDeferred={!!props.productIsDeferred}
        immediatePayout={props.immediatePayoutEnabled}
      />
      {shouldDownloadDocument && (
        <Box mt={5} mb={2}>
          <DocumentDownload
            dataTestId='pre-contract-information-download-button'
            heading='Customer copy'
            body='The customer must be provided with a printed copy of this information.'
            buttonText='Print information'
            documentUrl={documentDownloadUrl ?? '#'}
            canDownload={Boolean(documentDownloadUrl)}
            onDownloadClick={() => {
              setDocumentDownloaded(true);
              applicationId && onDocumentDownload(applicationId);
            }}
          />
        </Box>
      )}
      {!documentDownloaded && proceedToSigningAttempted && (
        <Message mt={6} variant='warning'>
          <NovunaHeading as='h3' mb={1}>
            Please print a copy
          </NovunaHeading>
          <Text>
            The customer must be provided with a printed copy of the
            pre-contract credit information
          </Text>
        </Message>
      )}
      <FeatureToggle feature={CompassFeature.E_SIGNING}>
        <Box mt={6}>
          <SigningHandoff
            canStartSigning={() => {
              if (shouldDownloadDocument && !documentDownloaded) {
                setProceedToSigningAttempted(true);
                return false;
              }
              return true;
            }}
          />
        </Box>
      </FeatureToggle>
    </>
  );
};

export default PreContractCreditInformationPage;
