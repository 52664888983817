import { Box, Button } from 'compass-design';
import { FormikProps } from 'formik';
import React from 'react';
import ScrollToError from 'components/meta/ScrollToError';
import { BackToStoreTextButton } from 'applicantApp/components/ExternalRedirectButtons/ExternalRedirectButtons';

interface NavigationButtonProps<T> {
  formikBag: FormikProps<T>;
  applicationId: string;
  returnUrl?: string;
  supplierOrderReference?: string;
}

export const getNavigationButtons = <T,>({
  applicationId,
  returnUrl,
  supplierOrderReference
}: NavigationButtonProps<T>) => (
  <>
    <ScrollToError />
    <Box>
      <Button isJumbo type='submit' mt={5} data-test-id='continue-button'>
        Continue
      </Button>
    </Box>
    {returnUrl && (
      <BackToStoreTextButton
        baseUrl={returnUrl}
        status='abandoned'
        applicationId={applicationId}
        supplierOrderReference={supplierOrderReference}
        buttonText='Back to store'
        modalText={
          'This application will be abandoned. Your credit history will not be affected.'
        }
      />
    )}
  </>
);
