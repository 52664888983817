import { DefaultSagaParams } from '../sagas';

export interface PreContractCreditInfoService {
  fetchPreContractDownloadUrl: (params: {
    applicationId: string;
    forceDownload?: boolean;
  }) => Promise<string>;
}

export const getPreContractCreditInfoService = ({
  apiClient
}: DefaultSagaParams): PreContractCreditInfoService => ({
  fetchPreContractDownloadUrl: async ({ applicationId, forceDownload }) => {
    try {
      const {
        data
      } = await apiClient.post(
        `protected/applications/${applicationId}/pre-contract-info`,
        { forceDownload }
      );
      return data;
    } catch (err) {
      throw new Error(
        `Failed to download pre-contract credit information: ${err}`
      );
    }
  }
});

export const getMockPreContractCreditInfoService = (
  overrides: any = {}
): PreContractCreditInfoService => ({
  fetchPreContractDownloadUrl: jest.fn(),
  ...overrides
});
