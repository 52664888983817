import React from 'react';
import {
  Box,
  NovunaArrowRight,
  NovunaHeading,
  NovunaSingleColumnLayout,
  Table,
  TableRow,
  Text
} from 'compass-design';
import { useDispatch, useSelector } from 'react-redux';
import { selectBranchSelectorSearchTerm } from 'store/enhancedUsers/selectors';
import { selectAllBranches } from 'store/retailer/selectors';
import { BranchSelectorButton } from '../BranchSelectorModal';
import { filterBranches } from '../utils';
import {
  setSelectedEnhancedHeadOffice,
  setShowBranchSelectorPage,
  setSelectedEnhancedBranch
} from 'store/enhancedUsers/actions';

const BranchSelection = () => {
  const dispatch = useDispatch();
  const branches = useSelector(selectAllBranches);
  const searchTerm = useSelector(selectBranchSelectorSearchTerm);

  return (
    <NovunaSingleColumnLayout>
      <NovunaHeading mb={5} as='h2'>
        Search results ({searchTerm})
      </NovunaHeading>
      <Box
        mb={5}
        sx={{
          display: ['block', null, null, 'flex'],
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
        <NovunaHeading as='h1'>Broker users</NovunaHeading>
        <BranchSelectorButton />
      </Box>
      <Box sx={{ width: '100%', overflowX: 'auto' }} mb={5}>
        <Table
          sx={{
            minWidth: '1000px',
            // The line below is to make the 'Trading Name' title (the 2nd column name) match the min-width of the 'names' table column.
            '& div:nth-Child(2)': { minWidth: '300px' }
          }}
          columnNames={['Supplier', 'Trading name', 'Location', 'Postcode', '']}
          data-test-id='compass-users-list'>
          {filterBranches({ branches, searchTerm }).map(branch => {
            const { name, supplierNumber, address } = branch;
            return (
              <TableRow
                key={supplierNumber}
                data-test-id={supplierNumber}
                onClick={() => {
                  if (branch.isHeadOffice) {
                    dispatch(setSelectedEnhancedHeadOffice(branch));
                  } else {
                    dispatch(setSelectedEnhancedBranch(branch));
                  }

                  dispatch(setShowBranchSelectorPage(false));
                }}>
                <Text>{supplierNumber}</Text>
                <Text sx={{ minWidth: '300px' }}>{name}</Text>
                <Text>{address.town}</Text>
                <Text>{address.postCode}</Text>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box>
                    <Text
                      sx={{
                        color: 'secondaryPurple',
                        fontWeight: 'heading',
                        fontSize: 2
                      }}>
                      {branch.isHeadOffice ? 'Head Office' : ''}
                    </Text>
                  </Box>
                  <NovunaArrowRight />
                </Box>
              </TableRow>
            );
          })}
        </Table>
      </Box>
    </NovunaSingleColumnLayout>
  );
};

export default BranchSelection;
