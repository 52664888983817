// User Create
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

// User Lookup
export const LOOKUP_USER_REQUEST = 'LOOKUP_USER_REQUEST';
export const LOOKUP_USER_SUCCESS = 'LOOKUP_USER_SUCCESS';
export const LOOKUP_USER_FAILURE = 'LOOKUP_USER_FAILURE';

// Set selected user
export const SELECTED_USER_REQUEST = 'SELECTED_USER_REQUEST';
export const SELECTED_USER_SUCCESS = 'SELECTED_USER_SUCCESS';
export const SELECTED_USER_FAILURE = 'SELECTED_USER_FAILURE';

// Update user
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const RESET_USER = 'RESET_USER';

export const SET_SELECTED_BRANCH = 'SET_SELECTED_BRANCH';
