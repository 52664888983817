import { Box, Button, Text } from 'compass-design';
import React from 'react';
import { CustomerSatisfactionItem } from 'store/previousApplicationDetail/actions';
import CustomerFeedbackTable from '../CustomerFeedbackTable';

export interface SummaryCustomerFeedbackProps {
  isPendingEsatNote: boolean;
  loading: boolean;
  notes?: CustomerSatisfactionItem[];
  applicationId: string;
  resendEsatNote: (id: string) => void;
  isEsatNoteEnabled?: boolean;
}

const SummaryCustomerFeedback = ({
  isPendingEsatNote,
  loading,
  notes,
  applicationId,
  resendEsatNote,
  isEsatNoteEnabled
}: SummaryCustomerFeedbackProps) => (
  <>
    <Box
      mt={2}
      sx={{
        display: ['block', null, null, 'flex'],
        justifyContent: 'space-between',
        alignItems: 'flex-end'
      }}>
      <Text sx={{ maxWidth: '620px' }}>
        A feedback request has been sent to the customer, a reminder will
        automatically send 48 hours later if they have not responded.{' '}
        {isEsatNoteEnabled && <>You can also manually re-send the request.</>}
      </Text>
      {isEsatNoteEnabled && isPendingEsatNote && (
        <Box mt={[3, null, null, 0]}>
          <Button
            isJumbo
            variant='secondary'
            disabled={loading}
            onClick={() => resendEsatNote(applicationId)}
            data-test-id='compass-resend-esat-note'>
            Re-send satisfaction note
          </Button>
        </Box>
      )}
    </Box>
    <Box mt={4}>
      <CustomerFeedbackTable notes={notes} />
    </Box>
  </>
);

export default SummaryCustomerFeedback;
