import { call, put, takeEvery } from 'redux-saga/effects';
import { getType, isOfType } from 'typesafe-actions';
import { UsersService } from 'services/users';
import { PgUser } from 'hitachi-retail-core/build/services/pgUsers/pgUsersService';
import {
  createEnhancedUserAction,
  fetchEnhancedUsersAction,
  updateEnhancedUserAction
} from 'store/enhancedUsers/actions';
import {
  CreateEnhancedUserAction,
  FetchEnhancedUsersAction,
  UpdateEnhancedUserAction
} from 'store/enhancedUsers/reducer';
import formatCognitoUsername from 'utils/formatCognitoUsername';

interface EnhancedUsersSagaProps {
  usersService: UsersService;
}

export const getPgUsersSaga = ({ usersService }: EnhancedUsersSagaProps) =>
  function*(action: FetchEnhancedUsersAction) {
    if (isOfType(getType(fetchEnhancedUsersAction.request), action)) {
      const branchId = action.payload;

      if (!branchId) {
        throw new Error('Could not find branch ID');
      }

      try {
        const result: PgUser[] = yield call(usersService.getPgUsers, branchId);
        yield put(fetchEnhancedUsersAction.success(result));
      } catch (err) {
        yield put(fetchEnhancedUsersAction.failure(err));
      }
    }
  };

export const getCreateEnhancedUserSagaWatcher = ({
  usersService
}: EnhancedUsersSagaProps) =>
  function*() {
    yield takeEvery(
      getType(createEnhancedUserAction.request),
      getCreateEnhancedUserSaga({ usersService })
    );
  };

export const getCreateEnhancedUserSaga = ({
  usersService
}: EnhancedUsersSagaProps) =>
  function*(action: CreateEnhancedUserAction) {
    if (isOfType(getType(createEnhancedUserAction.request), action)) {
      const createUserData = action.payload;
      try {
        yield call(usersService.createUser, createUserData);
        yield put(
          createEnhancedUserAction.success(
            `${createUserData.username} has been successfully added`
          )
        );
        yield put(
          fetchEnhancedUsersAction.request(createUserData.defaultBranch)
        );
      } catch (err) {
        yield put(createEnhancedUserAction.failure(err));
      }
    }
  };

export const getPgUsersSagaWatcher = ({
  usersService
}: EnhancedUsersSagaProps) =>
  function*() {
    yield takeEvery(
      getType(fetchEnhancedUsersAction.request),
      getPgUsersSaga({ usersService })
    );
  };

export const getUpdateEnhancedUserSaga = ({
  usersService
}: EnhancedUsersSagaProps) =>
  function*(action: UpdateEnhancedUserAction) {
    if (isOfType(getType(updateEnhancedUserAction.request), action)) {
      const updateUserData = action.payload;
      try {
        yield call(usersService.updateUser, updateUserData);
        yield put(
          updateEnhancedUserAction.success(
            `${formatCognitoUsername(
              updateUserData.username
            )} has been successfully updated`
          )
        );
        yield put(
          fetchEnhancedUsersAction.request(updateUserData.defaultBranch)
        );
      } catch (err) {
        yield put(updateEnhancedUserAction.failure(err));
      }
    }
  };

export const getUpdateEnhancedUserSagaWatcher = ({
  usersService
}: EnhancedUsersSagaProps) =>
  function*() {
    yield takeEvery(
      getType(updateEnhancedUserAction.request),
      getUpdateEnhancedUserSaga({ usersService })
    );
  };
