/**
 * TODO (Phase 3):
 * After Phase 3 roll-out - remove runtime and test code regarding enabledfeatures and retailerData that has been added on this PR https://bitbucket.org/novuna/compass-ui/pull-requests/1224.
 * Example:
 * 'selectEnabledFeautures' & 'enabledFeatures'
 * 'selectRetailer' & retailerData
 * 'isPhaseThreeEnabledGlobally'
 * All calls to .select() and .next() in saga tests that involve retailer data and enabled features
 */
import { put, takeLeading, call, select } from 'redux-saga/effects';
import { IncompleteFinanceApplication } from 'hitachi-retail-core/build/schemas/financeApplication';
import { getType } from 'typesafe-actions';
import {
  DecisionRequest,
  DecisionService,
  GetDecision
} from '../../../services/decision';
import { fetchDecision } from '../../../store/decision/actions';
import { FetchDecisionAction } from '../../../store/decision/reducer';
import {
  ApplicationsService,
  SaveRequest
} from '../../../services/applicationsService';
import { selectActiveApplication } from '../../../store/application/selectors';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import { getIovationFingerprint } from 'applicantApp/utils/getIovationFingerPrint';
import { selectEnabledFeatures } from '../../../store/config/selectors';
import {
  CompassFeature,
  FeatureConfig
} from 'hitachi-retail-core/build/features/features';
import { ApplicationDetail } from 'components/form/types';
import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';
import { IsPhaseThreeEnabledForRetailer } from '../../../sagas/retailer/saga';
import { selectRetailer } from '../../../store/retailer/selectors';
import { fetchActiveApplication } from 'store/previousApplicationDetail/actions';
import { signApplication } from 'store/sign/actions';

export interface GetDecisionSagaParams {
  decisionService: DecisionService;
  mailOrderApplicationService: Pick<ApplicationsService, 'saveApplication'>;
}

export const getDecisionSaga = ({
  decisionService,
  mailOrderApplicationService
}: GetDecisionSagaParams) =>
  function*(action: FetchDecisionAction) {
    if (action.type === getType(fetchDecision.request) && action.payload) {
      const { id, notes, tailoringOptionSelected } = action.payload;

      if (!id) {
        yield put(
          fetchDecision.failure(
            new Error('Decision API request does not contain expected values')
          )
        );
        return;
      }

      const activeApplication: ApplicationDetail = yield select(
        selectActiveApplication
      );
      const retailer: Retailer & IsPhaseThreeEnabledForRetailer = yield select(
        selectRetailer
      );

      const featureFlags: FeatureConfig = yield select(selectEnabledFeatures);
      const isPhaseThreeEnabledGlobally = featureFlags.has(
        CompassFeature.LOAN_QUEUING_PHASE_THREE
      );
      const { isPhaseThreeEnabledForRetailer } = retailer;

      const isPhaseThreeEnabled =
        isPhaseThreeEnabledForRetailer && isPhaseThreeEnabledGlobally;

      // Save the application
      try {
        const applicationDocument: IncompleteFinanceApplication =
          activeApplication.document;

        const payload: SaveRequest = {
          document: applicationDocument,
          ...(id && { id }) // Only pass ID if it is set
        };
        yield call(mailOrderApplicationService.saveApplication, payload);
      } catch {
        // We don't need to act here
      }

      // get device fingerprint and add to decision payload
      const blackbox = getIovationFingerprint();

      // When the application is saved, fetch the decision
      const payload: DecisionRequest = {
        id,
        notes,
        tailoringOptionSelected,
        ...blackbox
      };

      try {
        const response: GetDecision = yield call(
          decisionService.getDecision,
          payload,
          isPhaseThreeEnabled
        );

        if (response) {
          yield put(
            fetchDecision.success({
              decisionResponse: response.decisionType,
              maxInstalmentAmount:
                response.loanTailoring?.maxInstalmentAmount ?? null,
              loanTailoring: response.loanTailoring,
              productConfig: response.productConfig,
              tailoringExpiryDate: response.tailoringExpiryDate,
              softSearchExpiryDate: response?.softSearchExpiryDate ?? null
            })
          );
          if (response.decisionType === ApplicationStatus.decision_refer) {
            yield put(fetchActiveApplication.request({ id }));
          }
          if (
            response.decisionType === ApplicationStatus.decision_accept &&
            action.payload.signUponAccept
          ) {
            yield put(
              signApplication.request({
                id
              })
            );
          }
        } else {
          yield put(
            fetchDecision.failure(
              new Error('Decision API response did not contain expected values')
            )
          );
        }
      } catch (err) {
        yield put(fetchDecision.failure(err as Error));
      }
    }
  };

export const getDecisionSagaWatcher = ({
  decisionService,
  mailOrderApplicationService
}: GetDecisionSagaParams) =>
  function*() {
    yield takeLeading(
      getType(fetchDecision.request),
      getDecisionSaga({
        decisionService,
        mailOrderApplicationService
      })
    );
  };
