import { ReactNode } from 'react';
import {
  getIncreaseDepositOfferTextForRadioButton,
  getIncreaseLoanOfferTextForRadioButton,
  getIncreaseTermOfferTextForRadioButton,
  getReduceLoanOfferTextForRadioButton,
  getReduceTermOfferTextForRadioButton
} from 'retailerApp/components/MixingDeck/getMixingDeckData';
import { formatCurrency } from 'utils/formatters';
import { LoanDetails } from '../getLoanDetails';
import { TailoringOption } from 'compass-design/lib/components/TailoringOptions/TailoringOptions';

export interface GetRadioOptions {
  loanDetails: LoanDetails;
}

export interface RadioOption {
  tailoringOption: TailoringOption;
  text: ReactNode;
  title: string;
  message: {
    title: string;
    text: string;
  };
}

const getRadioOptions = ({ loanDetails }: GetRadioOptions): RadioOption[] => {
  const radioOptions: RadioOption[] = [];

  if (loanDetails.increaseTermOffer) {
    const minimumTerm = loanDetails.increaseTermOffer!.offer.repaidOver;
    const currentTerm = loanDetails.originalOffer!.repaidOver;
    const monthlyPayment = loanDetails.increaseTermOffer!.offer
      .monthlyRepayment;

    radioOptions.push({
      tailoringOption: 'term',
      title: 'Increase term',
      text: getIncreaseTermOfferTextForRadioButton({
        minimumTerm,
        currentTerm: loanDetails.originalOffer!.repaidOver
      }),
      message: {
        title: `Increase term from ${currentTerm} to ${minimumTerm} months`,
        text: `This is the next term available that reduces monthly payments to less than ${formatCurrency(
          monthlyPayment
        )}`
      }
    });
  }

  if (loanDetails.increaseDepositOffer) {
    const currentDeposit = formatCurrency(loanDetails.originalOffer!.deposit);
    const minimumDeposit = formatCurrency(
      loanDetails.increaseDepositOffer!.offer.deposit
    );
    const monthlyPayment = formatCurrency(
      loanDetails.increaseDepositOffer!.offer.monthlyRepayment
    );

    radioOptions.push({
      tailoringOption: 'deposit',
      title: 'Increase deposit',
      text: getIncreaseDepositOfferTextForRadioButton({
        currentDeposit: loanDetails.originalOffer!.deposit,
        minimumDeposit: loanDetails.increaseDepositOffer!.offer.deposit
      }),
      message: {
        title: `Increase deposit from ${currentDeposit} to ${minimumDeposit}`,
        text: `This reduces your monthly repayments to ${monthlyPayment}. Cash or debit card accepted.`
      }
    });
  }

  const reduceLoanMaximumGoodsAmount =
    loanDetails.reduceLoanOffer?.maximumGoodsAmount;

  if (loanDetails.reduceLoanOffer && !!reduceLoanMaximumGoodsAmount) {
    const formattedMaximumGoodsAmount = formatCurrency(
      reduceLoanMaximumGoodsAmount
    );
    const deposit = formatCurrency(loanDetails.reduceLoanOffer!.deposit);
    const maximumLoanAmount = formatCurrency(
      loanDetails.reduceLoanOffer!.maximumLoanAmount
    );

    radioOptions.push({
      tailoringOption: 'reduceLoan',
      title: 'Reduce total cost',
      text: getReduceLoanOfferTextForRadioButton({
        maximumGoodsAmount: reduceLoanMaximumGoodsAmount
      }),
      message: {
        title: `Purchase goods up to ${formattedMaximumGoodsAmount}`,
        text: `Spend up to ${formattedMaximumGoodsAmount} by borrowing up to ${maximumLoanAmount} with a deposit of ${deposit}`
      }
    });
  }

  const increaseLoanMaximumGoodsAmount =
    loanDetails.increaseLoanOffer?.maximumGoodsAmount;

  if (loanDetails.increaseLoanOffer && !!increaseLoanMaximumGoodsAmount) {
    const formattedMaximumGoodsAmount = formatCurrency(
      increaseLoanMaximumGoodsAmount
    );
    const deposit = formatCurrency(loanDetails.increaseLoanOffer!.deposit);
    const maximumLoanAmount = formatCurrency(
      loanDetails.increaseLoanOffer!.maximumLoanAmount
    );

    radioOptions.push({
      tailoringOption: 'increaseLoan',
      title: 'Increase total cost',
      text: getIncreaseLoanOfferTextForRadioButton({
        maximumGoodsAmount: increaseLoanMaximumGoodsAmount
      }),
      message: {
        title: `Borrow more`,
        text: `Spend up to ${formattedMaximumGoodsAmount} by borrowing up to ${maximumLoanAmount} with a deposit of ${deposit}`
      }
    });
  }

  if (loanDetails.reduceTermOffer) {
    const minimumTerm = loanDetails.reduceTermOffer!.offer.repaidOver;
    const currentTerm = loanDetails.originalOffer!.repaidOver;
    const monthlyPayment = loanDetails.reduceTermOffer!.offer.monthlyRepayment;

    radioOptions.push({
      tailoringOption: 'reduceTerm',
      title: 'Reduce term',
      text: getReduceTermOfferTextForRadioButton({
        minimumTerm,
        currentTerm: loanDetails.originalOffer!.repaidOver
      }),
      message: {
        title: `Reduce term from ${currentTerm} to ${minimumTerm} months`,
        text: `This will increase the monthly payments to ${formatCurrency(
          monthlyPayment
        )}`
      }
    });
  }

  return radioOptions;
};

export default getRadioOptions;
