import { call, put, takeEvery } from 'redux-saga/effects';
import { SearchApplicationsAction } from 'store/previousApplications/reducer';
import { NotificationType, showNotification } from 'store/ui/actions';
import { getType, isOfType } from 'typesafe-actions';
import { ApplicationsService } from '../../services/applicationsService';
import {
  fetchPreviousApplications,
  FetchPreviousApplicationsResponse,
  FetchSearchPreviousApplicationsResponse,
  searchApplicationsAction
} from '../../store/previousApplications/actions';

interface GetPreviousApplicationsSagaWatcher {
  applicationsService: ApplicationsService;
}

interface GetPreviousApplicationsSagaParams {
  applicationsService: ApplicationsService;
}

export const getPreviousApplicationsSaga = ({
  applicationsService
}: GetPreviousApplicationsSagaParams) =>
  function*() {
    try {
      const response: FetchPreviousApplicationsResponse = yield call(
        applicationsService.getAll
      );

      if (response) {
        yield put(fetchPreviousApplications.success(response));
      } else {
        yield put(
          fetchPreviousApplications.failure(
            new Error(
              'Applications API response did not contain expected values'
            )
          )
        );
      }
    } catch (err) {
      yield put(
        fetchPreviousApplications.failure(
          new Error('Previous applications request failed')
        )
      );
    }
  };

export const searchApplicationsSaga = ({
  applicationsService
}: GetPreviousApplicationsSagaParams) =>
  function*(action: SearchApplicationsAction) {
    if (isOfType(getType(searchApplicationsAction.request), action)) {
      const { term, offset, limit } = action.payload;
      try {
        const response: FetchSearchPreviousApplicationsResponse = yield call(
          applicationsService.searchApplications,
          { term, offset, limit }
        );

        yield put(searchApplicationsAction.success(response));
      } catch (err) {
        yield put(searchApplicationsAction.failure(err));
        yield put(
          showNotification({
            message: err.message,
            type: NotificationType.DANGER
          })
        );
      }
    }
  };

export const getPreviousApplicationsSagaWatcher = ({
  applicationsService
}: GetPreviousApplicationsSagaWatcher) =>
  function*() {
    yield takeEvery(
      getType(fetchPreviousApplications.request),
      getPreviousApplicationsSaga({ applicationsService })
    );

    yield takeEvery(
      getType(searchApplicationsAction.request),
      searchApplicationsSaga({ applicationsService })
    );
  };
