import { Message, NovunaHeading, Text } from 'compass-design';
import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import React from 'react';
import {
  selectApplicationReturnUrl,
  selectApplicationSupplierOrderReference
} from 'store/application/selectors';
import { RetailerRedirectButton } from 'applicantApp/components/ExternalRedirectButtons/ExternalRedirectButtons';
import { useSelector } from 'store';
import { ApplicantApplicationProps } from 'applicantApp/pages/ApplicantApplication/ApplicantApplication';
import PbfTailoringOptions from '../PbfTailoringOptions';
import { FinanceApplication } from 'hitachi-retail-core/build/schemas/financeApplication';
import { LoanDetails } from 'retailerApp/utils/loanDetails/getLoanDetails';
import getAffordabilityDecline from 'retailerApp/utils/loanDetails/getAffordabilityDecline';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import NovunaPageLayout from 'applicantApp/components/NovunaPageLayout';

export interface DecisionDeclineProps {
  id: string;
  isPbf: boolean;
  financeApplication: FinanceApplication;
  loanDetails: LoanDetails;
  maxInstalmentAmount: string | undefined;
  status: ApplicationStatus;
}

const DecisionDecline: React.FC<DecisionDeclineProps &
  Pick<
    ApplicantApplicationProps,
    'onConfirmTailoringUpdate' | 'enabledFeatures'
  >> = ({
  id,
  isPbf,
  financeApplication,
  loanDetails,
  maxInstalmentAmount,
  status,
  enabledFeatures,
  onConfirmTailoringUpdate
}) => {
  const returnUrl = useSelector(selectApplicationReturnUrl);
  const supplierOrderReference = useSelector(
    selectApplicationSupplierOrderReference
  );

  const affordabilityDecline = getAffordabilityDecline({
    decisionApplicationStatus: status,
    maxInstalmentAmount,
    enabledFeatures,
    loanDetails,
    agreementType: financeApplication.product.agreementType
  });

  const affordabilityDeclineProps = affordabilityDecline
    ? {
        ...affordabilityDecline,
        onConfirmTailoringUpdate
      }
    : undefined;

  return (
    <NovunaPageLayout backToStoreStatus='declined'>
      <NovunaHeading
        as='h1'
        data-test-id='mo-decision-decline-page-heading'
        mb={3}>
        Application decision
      </NovunaHeading>

      {enabledFeatures.has(CompassFeature.PBF_TAILORING) &&
      isPbf &&
      maxInstalmentAmount &&
      affordabilityDeclineProps ? (
        <PbfTailoringOptions
          data-test-id={'pbf-tailoring-options-on-decline'}
          originalApplication={financeApplication}
          maxInstalmentAmount={maxInstalmentAmount}
          affordabilityTailoring={affordabilityDeclineProps!}
          applicationId={id}
        />
      ) : (
        <>
          <Message variant='info'>
            <NovunaHeading as='h3' mb={1}>
              Unsuccessful
            </NovunaHeading>

            <Text data-test-id='mo-decision-decline-text'>
              Thank you for applying. Unfortunately, after carrying out our
              checks we have declined your application.
            </Text>
          </Message>

          <NovunaHeading mt={3} as='h2' mb={1}>
            About the decision
          </NovunaHeading>
          <Text mb={3}>
            A letter that gives you more information on your application and how
            we came to our decision has been sent to you by email.
          </Text>
          {returnUrl ? (
            <>
              <Text my={6} sx={{ fontSize: 2 }}>
                Please return to the store to continue shopping.
              </Text>

              <RetailerRedirectButton
                novunaDisplay
                baseUrl={returnUrl}
                applicationId={id}
                status='declined'
                buttonText='Continue shopping'
                supplierOrderReference={supplierOrderReference}
              />
            </>
          ) : (
            <Text mt={6} sx={{ fontSize: 2 }}>
              You can leave this website or close your web browser to exit the
              application process.
            </Text>
          )}
        </>
      )}
    </NovunaPageLayout>
  );
};

export default DecisionDecline;
