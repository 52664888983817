import { createAsyncAction } from 'typesafe-actions';
import { VerificationDetails } from '../../components/agreement/Agreement';
import * as app from './constants';

export interface FetchAgreementUrlPayload {
  applicationId: string;
  forceDownload?: boolean;
}

export interface FetchAgreementUrlResponse {
  url: string;
}

export interface FetchAgreementBySigningIdPayload {
  signingId: string;
  verificationDetails: VerificationDetails;
}

export interface FetchAgreementBySigningIdError {
  credentialsWrong: boolean;
}

export const fetchAgreementUrl = createAsyncAction(
  app.FETCH_AGREEMENT_URL_REQUEST,
  app.FETCH_AGREEMENT_URL_SUCCESS,
  app.FETCH_AGREEMENT_URL_FAILURE
)<FetchAgreementUrlPayload, FetchAgreementUrlResponse, Error>();

export const fetchAgreementBySigningId = createAsyncAction(
  app.FETCH_AGREEMENT_BY_SIGNING_ID,
  app.FETCH_AGREEMENT_BY_SIGNING_ID_SUCCESS,
  app.FETCH_AGREEMENT_BY_SIGNING_ID_FAILURE
)<FetchAgreementBySigningIdPayload, string, FetchAgreementBySigningIdError>();
