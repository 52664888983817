import {
  Box,
  Button,
  Message,
  NovunaHeading,
  SectionError,
  SelectField,
  TertiaryLink,
  TertiaryLinkDirection,
  Text
} from 'compass-design';
import {
  Address,
  addressSchema as addressValidationSchema,
  normalisePostcode,
  postCodeRequiredStringSchema as postcodeValidationSchema,
  validationMessages
} from 'hitachi-retail-core';
import { JSONSchema6 } from 'json-schema';
import React from 'react';
import { FieldProps, FormValidation } from 'react-jsonschema-form';
import { ValidationError } from 'yup';
import { getFieldError } from '../../../form/helpers';
import { getAddressLines, getBlankAddress } from '../../../utils/address';
import { formatAddressSingleLine } from '../../../utils/formatters';
import InputField from '../fields/InputField';

export interface AddressFinderCustomProps {
  addressError?: string;
  testIdPrefix?: string;
}

export interface AddressFinderPropsFromState {
  addressResults?: Address[];
  processing?: boolean;
  serviceErrorMessage?: string;
}

export interface AddressFinderPropsFromDispatch {
  findByPostcode: (postcode: string) => void;
}

export interface AddressFinderState {
  displayAddressResults: boolean;
  manualEntry: boolean;
  validAddressCaptured: boolean;
  enteredPostcode?: string;
  enteredPostcodeError?: string;
}

export type AddressFinderProps = AddressFinderCustomProps &
  AddressFinderPropsFromState &
  AddressFinderPropsFromDispatch &
  FieldProps<Address | undefined>;

class AddressFinder extends React.Component<
  AddressFinderProps,
  AddressFinderState
> {
  public static defaultProps = {
    processing: false
  };

  constructor(props: AddressFinderProps) {
    super(props);

    this.state = {
      displayAddressResults: false,
      manualEntry: false,
      validAddressCaptured: addressValidationSchema.isValidSync(props.formData)
    };
  }

  public handleFindAddress = (event: React.FormEvent) => {
    event.preventDefault();
    const { enteredPostcode = '' } = this.state;

    const postcodeValue = enteredPostcode.trim();
    try {
      postcodeValidationSchema.validateSync(postcodeValue);
    } catch (error) {
      if (error instanceof ValidationError) {
        this.setState({
          enteredPostcodeError: error.message
        });
        return;
      }
    }

    const normalisedPostcode = normalisePostcode(postcodeValue, true);
    if (normalisedPostcode) {
      this.setState(
        {
          displayAddressResults: true,
          enteredPostcodeError: ''
        },
        () => this.props.findByPostcode(normalisedPostcode)
      );
    }
  };

  public handleEnteredPostcodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { validAddressCaptured } = this.state;
    let postcodeValue = event.target.value.toUpperCase();

    const isValid = postcodeValidationSchema.isValidSync(postcodeValue);
    if (isValid) {
      postcodeValue = normalisePostcode(postcodeValue);
    }

    if (!validAddressCaptured) {
      this.props.onChange(
        updateAddress(
          this.props.formData as Address,
          'postCode',
          isValid ? postcodeValue : ''
        )
      );
    }
    this.setState({ enteredPostcode: postcodeValue });
  };

  public handleSelectAddressResult = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const index = parseInt(event.target.value, 10);
    const isValidIndex = !Number.isNaN(index) && index >= 0;
    if (isValidIndex && Array.isArray(this.props.addressResults)) {
      const selectedAddressResult = this.props.addressResults[index];
      // Map empty string fields to undefined as required by react-jsonschema-form
      const selectedAddress = Object.assign(
        {},
        ...Object.keys(selectedAddressResult).map(fieldName => ({
          [fieldName]: selectedAddressResult[fieldName] || undefined
        }))
      );

      this.setState({
        validAddressCaptured: addressValidationSchema.isValidSync(
          selectedAddress
        )
      });
      this.props.onChange(selectedAddress);
    }
  };

  public handleManualAddressChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const target = event.target;
    this.props.onChange(
      updateAddress(this.props.formData as Address, target.name, target.value)
    );
  };

  public handleResetAddressFinder = (event: React.MouseEvent) => {
    event.preventDefault();

    this.setState({
      displayAddressResults: false,
      validAddressCaptured: false
    });
    this.props.onChange(getBlankAddress());
  };

  public handleToggleManualEntry = (event: React.MouseEvent) => {
    event.preventDefault();
    const { manualEntry } = this.state;

    this.setState({
      manualEntry: !manualEntry
    });
  };

  public render() {
    const {
      addressError,
      addressResults,
      serviceErrorMessage,
      idSchema,
      errorSchema,
      formData: capturedAddress,
      schema,
      uiSchema,
      autofocus,
      processing
    } = this.props;
    const {
      displayAddressResults,
      manualEntry,
      validAddressCaptured
    } = this.state;

    const description = uiSchema['ui:description'] || schema.description;
    const addressSchema = schema.properties || {};
    const testIdPrefix = this.props.testIdPrefix || idSchema.$id;

    let errorMessage = null;
    const addressErrorString =
      addressError ||
      (checkBlankAddressError(errorSchema) &&
        validationMessages.REQUIRED_ADDRESS);
    if (addressErrorString) {
      errorMessage = <SectionError>{addressErrorString}</SectionError>;
    }

    let addressFinder = null;
    if (!validAddressCaptured && !manualEntry) {
      addressFinder = (
        <>
          <InputField
            label={uiSchema['ui:postcodeLabel'] ?? 'Postcode'}
            required={true}
            fieldName='enteredPostcode'
            fieldHelp='UK and BFPO addresses only'
            fieldValue={this.state.enteredPostcode}
            fieldError={this.state.enteredPostcodeError}
            testIdPrefix={testIdPrefix}
            inputId={`${testIdPrefix}_enteredPostcode`}
            disabled={processing}
            autofocus={autofocus}
            shortLength
            onChange={this.handleEnteredPostcodeChange}
          />
          <Button
            variant='secondary'
            type='submit'
            disabled={processing}
            data-test-id={testIdPrefix + '_submit'}
            onClick={this.handleFindAddress}>
            {processing ? 'Please wait\u2026' : 'Find address'}
          </Button>
        </>
      );
    }

    const resetAddressFinder = (
      <Box mt={2}>
        <TertiaryLink
          text='Find a different address'
          direction={TertiaryLinkDirection.FORWARDS}
          onClick={this.handleResetAddressFinder}
        />
      </Box>
    );

    let addressFinderResult = null;
    if (displayAddressResults && !validAddressCaptured && !manualEntry) {
      const hasZeroAddressResults = Boolean(
        addressResults && addressResults.length === 0
      );
      const hasAddressResults = Boolean(
        addressResults && addressResults.length > 0
      );
      if (hasAddressResults) {
        addressFinderResult = (
          <Box mt={4}>
            <SelectField
              onChange={this.handleSelectAddressResult}
              data-test-id={testIdPrefix + '_results'}>
              <option value=''>Select address</option>
              {(addressResults || []).map((address, i) => (
                <option key={i} value={i}>
                  {formatAddressSingleLine(address)}
                </option>
              ))}
            </SelectField>
          </Box>
        );
      } else if (hasZeroAddressResults) {
        addressFinderResult = (
          <Message
            variant='error'
            mt={4}
            mb={3}
            data-test-id={testIdPrefix + '_zeroResults'}>
            <NovunaHeading as='h3' mb={1}>
              Address not found
            </NovunaHeading>
            <Text>
              We&rsquo;re unable to find your address. Please try entering it
              manually.
            </Text>
          </Message>
        );
      } else if (serviceErrorMessage) {
        addressFinderResult = (
          <>
            <Message
              variant='error'
              mt={4}
              mb={3}
              data-test-id={testIdPrefix + '_error'}>
              <NovunaHeading as='h3' mb={1}>
                Something went wrong
              </NovunaHeading>
              <Text>
                Sorry, an error with our system means we&rsquo;re unable to find
                your address. Please try entering it manually.
              </Text>
            </Message>
            {resetAddressFinder}
          </>
        );
      }
    }

    let addressFinderSelectedAddress = null;
    if (validAddressCaptured && !manualEntry) {
      addressFinderSelectedAddress = (
        <>
          {capturedAddress && (
            <Box
              as='address'
              mt={2}
              sx={{ fontStyle: 'normal' }}
              data-test-id={testIdPrefix + '_address'}>
              {getAddressLines(capturedAddress).map((line, index) => (
                <div key={index}>{line}</div>
              ))}
            </Box>
          )}
          {resetAddressFinder}
        </>
      );
    }

    const toggleManualEntry = (
      <>
        {manualEntry ? (
          <Box mt={2} mb={3}>
            <TertiaryLink
              text='Find address by postcode'
              direction={TertiaryLinkDirection.BACKWARDS}
              onClick={this.handleToggleManualEntry}
            />
          </Box>
        ) : (
          <Box mt={2} mb={3}>
            <TertiaryLink
              text={
                validAddressCaptured
                  ? 'Update address manually'
                  : 'Enter address manually'
              }
              direction={TertiaryLinkDirection.FORWARDS}
              onClick={this.handleToggleManualEntry}
              dataTestId={testIdPrefix + '_manualEntry'}
            />
          </Box>
        )}
      </>
    );

    let manualEntryFields = null;
    if (manualEntry) {
      manualEntryFields = [];
      const requiredFields = schema.required || [];
      Object.keys(addressSchema).forEach(fieldName => {
        const fieldSchema = addressSchema[fieldName] as JSONSchema6;
        manualEntryFields.push(
          <InputField
            key={fieldName}
            label={fieldSchema.title || ''}
            required={requiredFields.indexOf(fieldName) > -1}
            fieldName={fieldName}
            fieldValue={(capturedAddress && capturedAddress[fieldName]) || ''}
            fieldError={getFieldError(fieldName, errorSchema)}
            testIdPrefix={testIdPrefix}
            inputId={`${idSchema.$id}_${fieldName}`}
            shortLength={['houseNumber', 'postCode'].includes(fieldName)}
            onChange={this.handleManualAddressChange}
          />
        );
      });
    }

    return (
      <div data-test-id={testIdPrefix}>
        {description && <p>{description}</p>}
        {errorMessage}
        {addressFinder}
        {addressFinderResult}
        {addressFinderSelectedAddress}
        {toggleManualEntry}
        {manualEntryFields}
      </div>
    );
  }
}

const updateAddress = (
  current: Address,
  property: string,
  value: string
): Address => {
  if (property === 'postCode' && value) {
    value = normalisePostcode(value);
  }

  const updatedAddress: Address = {
    ...current,
    // A field is considered empty by react-jsonschema-form when its value is undefined
    [property]: value || undefined
  };
  return updatedAddress;
};

const checkBlankAddressError = (errorSchema: FormValidation): boolean =>
  errorSchema &&
  Object.keys(errorSchema).some(property => {
    const entry = errorSchema[property];
    return '__errors' in entry && entry.__errors.length > 0;
  });

export default AddressFinder;
