import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { sendApplication, sendApplicationReset } from './actions';

export type SendApplicationAction = ActionType<typeof sendApplication>;
export type SendApplicationResetAction = ActionType<
  typeof sendApplicationReset
>;

export interface State {
  errorMessage?: string;
  currentState: EndAndSendState;
}

export enum EndAndSendState {
  Default = 'DEFAUlT',
  Loading = 'LOADING',
  SentToCustomer = 'SENT_TO_CUSTOMER',
  Error = 'ERROR'
}

type Action = SendApplicationAction | SendApplicationResetAction;

export const initialState: State = { currentState: EndAndSendState.Default };

export const reducer: Reducer<State, Action> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(sendApplication.request):
      return { ...initialState, currentState: EndAndSendState.Loading };

    case getType(sendApplication.success):
      return { ...initialState, currentState: EndAndSendState.SentToCustomer };

    case getType(sendApplication.failure): {
      const errorMessage = action.payload.error.message;
      return {
        ...initialState,
        errorMessage,
        currentState: EndAndSendState.Error
      };
    }

    case getType(sendApplicationReset):
      return {
        ...initialState
      };

    default:
      return state;
  }
};
