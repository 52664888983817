/** @jsx jsx */
import {
  Button,
  jsx,
  NovunaHeading,
  TertiaryLink,
  TertiaryLinkDirection
} from 'compass-design';
import { selectDeferredProduct } from 'hitachi-retail-core/build/selectors';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'store';
import {
  selectApplicationDocument,
  selectEarlySettlementFee,
  selectFetchApplicationStatus,
  selectApplicationRegulated,
  selectApplicationReturnUrl,
  selectApplicationSupplierOrderReference,
  selectAgreementDuration
} from 'store/application/selectors';
import { getBasePageName, pageNameSeparator } from 'applicantApp/config';
import { AsyncStatus } from 'applicantApp/store/AsyncStatus';
import { ApplicantApplicationProps } from 'applicantApp/pages/ApplicantApplication/ApplicantApplication';
import { CancelApplicationTextButton } from 'applicantApp/components/ExternalRedirectButtons/ExternalRedirectButtons';
import NovunaPageLayout from 'applicantApp/components/NovunaPageLayout';
import { useHistory } from 'react-router';
import {
  RegulatedList,
  RegulatedText
} from './components/keyLoanFeaturesText/regulatedText';
import {
  UnregulatedList,
  UnregulatedText
} from './components/keyLoanFeaturesText/unregulatedText';
import { getPageHeading, getPageName } from 'applicantApp/utils/PCCI';

const LoanKeyFeatures: React.FC<ApplicantApplicationProps> = ({
  completeKeyLoanFeatures,
  id
}) => {
  const history = useHistory();
  const fetchApplicationStatus = useSelector(selectFetchApplicationStatus);
  const document = useSelector(selectApplicationDocument);
  const isDeferred = selectDeferredProduct(document);
  const earlySettlementFee = useSelector(selectEarlySettlementFee);
  const isRegulated = useSelector(selectApplicationRegulated);
  const instalments = useSelector(selectAgreementDuration);
  const pageName = getPageName({ isRegulated });
  const pageHeading = getPageHeading({ isRegulated });
  const returnUrl = useSelector(selectApplicationReturnUrl);
  const supplierOrderReference = useSelector(
    selectApplicationSupplierOrderReference
  );

  if (fetchApplicationStatus !== AsyncStatus.Success) {
    return null;
  }

  const MainPage = (
    <div data-test-id='compass-applicant-pre-sign'>
      <Helmet>
        <title>{[pageName, getBasePageName()].join(pageNameSeparator)}</title>
      </Helmet>

      <NovunaHeading
        as='h1'
        mb={2}
        data-test-id='mo-key-loan-information-header'>
        {pageHeading}
      </NovunaHeading>

      {isRegulated ? <RegulatedText /> : <UnregulatedText />}

      {isRegulated ? (
        <RegulatedList
          earlySettlementFee={earlySettlementFee}
          isDeferred={isDeferred}
        />
      ) : (
        <UnregulatedList instalments={instalments} />
      )}

      <div>
        <Button
          isJumbo
          mt={6}
          mb={6}
          onClick={completeKeyLoanFeatures}
          data-test-id='mo-key-loan-information-button'>
          Continue
        </Button>
      </div>

      {returnUrl && (
        <CancelApplicationTextButton
          baseUrl={returnUrl}
          applicationId={id}
          supplierOrderReference={supplierOrderReference}
        />
      )}
    </div>
  );

  return (
    <NovunaPageLayout
      pageTop={
        <TertiaryLink
          direction={TertiaryLinkDirection.BACKWARDS}
          text='Application decision'
          onClick={() =>
            history.push(
              history.location.pathname.replace(
                'signing/key-loan-features',
                'decision'
              )
            )
          }
        />
      }>
      {MainPage}
    </NovunaPageLayout>
  );
};

export default LoanKeyFeatures;
