import {
  AdvancedRadioButtonGroup,
  AdvancedRadioButtonOption,
  SectionError
} from 'compass-design';
import { FinanceApplication } from 'hitachi-retail-core';
import { ServiceTypeIdentifier } from 'hitachi-retail-core/build/application/financeProduct';
import { DeepPartial } from 'hitachi-retail-core/build/utils/deepPartial';
import { JSONSchema6 } from 'json-schema';
import React from 'react';
import { FieldProps } from 'react-jsonschema-form';
import { getFieldError } from '../../../form/helpers';
import { ProductOption } from '../../../form/quote';
import { formatPercent } from '../../../utils/formatters';
import { QuoteFormContext } from '../../Quote';
import FieldTemplate from '../FieldTemplate';
import { Decimal } from 'hitachi-retail-core/build/utils/decimal';

type FinanceProductFields = DeepPartial<FinanceApplication['product']>;
export type FinanceProductFieldProps = FieldProps<FinanceProductFields>;

export type FinanceProductProps = FinanceProductFieldProps;

const fieldNames = {
  Product: 'product'
};

export const FinanceProduct: React.FunctionComponent<FinanceProductProps> = ({
  formContext,
  formData,
  schema,
  errorSchema,
  idSchema: { $id },
  disabled
}) => {
  const {
    productOptions,
    mustReselectProduct,
    onSelectProductOption
  } = formContext as QuoteFormContext;
  const productSchema = schema.properties![fieldNames.Product] as JSONSchema6;
  const productError = getFieldError('serviceType', errorSchema);

  const handleSelectProductOption = (index: number) => {
    const selectedOption = productOptions![index];
    if (!selectedOption.selected) {
      onSelectProductOption(selectedOption);
    }
  };

  if (!productOptions || productOptions.length === 0) {
    return null;
  }

  const selectedValue = productOptions?.findIndex(
    productOption =>
      productOption.serviceType === formData.serviceType &&
      productOption.apr &&
      formData.apr &&
      new Decimal(productOption.apr).equals(new Decimal(formData.apr))
  );

  return (
    <FieldTemplate
      id={$id}
      label='Finance product'
      required={true}
      hidden={false}
      disabled={disabled}
      displayLabel={true}
      rawErrors={[productError]}
      description={null}
      rawDescription=''
      help={null}
      schema={productSchema}>
      <>
        {mustReselectProduct && (
          <SectionError my={3}>
            The previously selected finance product or repayment term is no
            longer available. Please choose an alternative.
          </SectionError>
        )}
        <AdvancedRadioButtonGroup
          options={productOptions.map(
            (
              { serviceType, apr, label, selectable }: ProductOption,
              index: number
            ): AdvancedRadioButtonOption => {
              const transformedServiceType =
                transformServiceTypeLabel.get(serviceType) ?? serviceType;

              return {
                id: String(index),
                title: transformedServiceType,
                text: label ?? 'Unknown product',
                subText: formatPercent(apr) + ' APR',
                disabled: disabled || !selectable,
                inputId: `${$id}_${transformedServiceType.toLowerCase()}_apr${apr}`
              };
            }
          )}
          selectedId={selectedValue >= 0 ? String(selectedValue) : undefined}
          onClick={id => handleSelectProductOption(parseInt(id, 10))}
          twoColumnLayout
        />
      </>
    </FieldTemplate>
  );
};

export const transformServiceTypeLabel = new Map<ServiceTypeIdentifier, string>(
  [
    ['IF', 'IFC'],
    ['IB', 'IBC']
  ]
);

export default FinanceProduct;
