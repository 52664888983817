import { ProgressBar } from 'compass-design';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PageDetail } from '../../config/pageTitles';

export interface ProgressPropsFromState {
  pageConfig: PageDetail[];
  page: number;
}

export type ProgressProps = ProgressPropsFromState;

const Progress: React.FunctionComponent<ProgressProps> = ({
  pageConfig,
  page
}) => {
  const history = useHistory();

  return (
    <ProgressBar
      sections={pageConfig.map(({ title }) => title)}
      currentSectionIndex={page}
      onClick={target => {
        const entry = pageConfig.find(({ title }) => title === target);
        if (entry?.route) {
          history.push(entry.route);
        }
      }}
    />
  );
};

export default Progress;
