import { AxiosInstance } from 'axios';
import {
  connectRouter,
  routerMiddleware as createRouterMiddleware
} from 'connected-react-router';
import { useSelector as realUseSelector } from 'react-redux';
import { createBrowserHistory, History } from 'history';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createActionEnhancerMiddleware from 'redux-action-enhancer';
import { persistReducer, persistStore } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import createSagaMiddleware from 'redux-saga';
import { ActionType, StateType } from 'typesafe-actions';
import { getMailOrderAddressLookupService } from '../applicantApp/services/addressLookupService';
import { AuthService, getAuthService } from '../applicantApp/services/auth';
import {
  ApplicantConfigService,
  getApplicantConfigService
} from '../applicantApp/services/config';
import {
  getSendPrecontractInfoService,
  SendPrecontractInfoService
} from '../applicantApp/services/sendPrecontractInfoService';
import {
  reducer as applicantAuth,
  State as AuthState
} from '../applicantApp/store/applicantAuth/reducer';
import { reducer as changeFormStep } from '../applicantApp/store/changeFormStep/reducer';
import { reducer as applicantConfig } from '../applicantApp/store/config/reducer';
import { reducer as sendPrecontractInfo } from '../applicantApp/store/sendPrecontractInfo/reducer';
import { reducer as sendLoanAdvanceInfo } from './sendLoanAdvanceInformation/reducer';
import { AppDomainConfig } from '../components/common/useAppDomainConfig';
import { reducer as endAndSend } from '../endAndSend/store/reducer';
import { getRootSaga } from '../sagas';
import {
  AddressLookupService,
  getAddressLookupService
} from '../services/addressLookupService';
import { getApiClient } from '../services/api';
import {
  ApplicationsService,
  getApplicationsService
} from '../services/applicationsService';
import {
  BankAccountCheckService,
  getBankAccountCheckService,
  getBankCheckEndpoints
} from '../services/bankCheck';
import { ConfigService, getConfigService } from '../services/config';
import { DecisionService, getDecisionService } from '../services/decision';
import {
  getMailOrderApplicationService,
  MailOrderApplicationService
} from '../services/mailOrderApplicationService';
import { getProductService, ProductService } from '../services/product';
import { getRetailerService, RetailerService } from '../services/retailer';
import {
  getRetailerConfigService,
  RetailerConfigService
} from '../services/retailerConfig';
import { getUserService, UserService } from '../services/user';
import { resetStore } from './actions';
import { reducer as address } from './address/reducer';
import { reducer as agreement } from './agreement/reducer';
import { reducer as application } from './application/reducer';
import { reducer as config } from './config/reducer';
import { reducer as customerSatisfaction } from './customerSatisfaction/reducer';
import { reducer as decision } from './decision/reducer';
import { reducer as declinedEmail } from './declinedEmail/reducer';
import { featureFlagEnhancer } from './featureFlagEnhancer';
import { reducer as goodsList } from './goodsList/reducer';
import { reducer as previousApplicationDetail } from './previousApplicationDetail/reducer';
import { reducer as previousApplications } from './previousApplications/reducer';
import { reducer as products } from './product/reducer';
import { reducer as retailer } from './retailer/reducer';
import { reducer as retailerConfig } from './retailerConfig/reducer';
import { reducer as save } from './save/reducer';
import { reducer as sign } from './sign/reducer';
import { reducer as signingRedirects } from './signingRedirects/reducer';
import { closeNotification } from './ui/actions';
import { notificationDisplayed, reducer as ui } from './ui/reducer';
import { reducer as user } from './user/reducer';
import { reducer as preContractCreditInfo } from './preContractCreditInfo/reducer';
import { reducer as users } from './users/reducer';
import { reducer as enhancedUsers } from './enhancedUsers/reducer';
import { reducer as loanAmend } from './applicationAmend/reducer';
import {
  getSendLoanAdvanceInfoService,
  SendLoanAdvanceInfoService
} from '../services/sendLoanAdvanceInfoService';
import {
  DocumentDownloadConfirmationService,
  getDocumentDownloadConfirmationService
} from '../services/documentDownloadConfirmationService';
import { getUsersService, UsersService } from '../services/users';
import {
  getGoodsListService,
  GoodsListService
} from 'services/goodsList/goodsListService';

export enum AppType {
  Applicant = 'APPLICANT',
  Retailer = 'RETAILER'
}

interface GetStoreInput {
  overrideApiClient?: AxiosInstance;
  overrideApplicationsService?: ApplicationsService;
  overrideConfigService?: ConfigService;
  overrideApplicantConfigService?: ApplicantConfigService;
  overrideMailOrderApplicationService?: MailOrderApplicationService;
  overrideSendPrecontractInfoService?: SendPrecontractInfoService;
  overrideSendLoanAdvanceInfoService?: SendLoanAdvanceInfoService;
  overrideBankAccountCheckService?: BankAccountCheckService;
  overrideAuthService?: AuthService;
  overrideAddressLookupService?: AddressLookupService;
  overrideDecisionService?: DecisionService;
  overrideUserService?: UserService;
  overrideRetailerService?: RetailerService;
  overrideProductService?: ProductService;
  overrideRetailerConfigService?: RetailerConfigService;
  overrideDocumentDownloadConfirmationService?: DocumentDownloadConfirmationService;
  overrideUsersService?: UsersService;
  overrideGoodsListService?: GoodsListService;
  // We can't reference RootState here as RootState is defined by
  // the return type of getStore (circular reference).
  // This parameter is only provided to allow the injection of test data
  initialState?: any;
  history?: History;
  appType: AppType;
  appDomainConfig?: AppDomainConfig;
}

const getBaseDomain = ({ baseUrl }: { baseUrl: string }) => {
  let protocol = '';

  if (!/^((http|https):\/\/)/.test(baseUrl)) {
    protocol = 'https://';
  }

  const url = new URL(`${protocol}${baseUrl}`);
  return url.host;
};

export const getAppType = (href: string, config: AppDomainConfig): AppType => {
  if (
    process.env.NODE_ENV !== 'production' &&
    process.env.REACT_APP_HITACHI_APP_TYPE
  ) {
    return process.env.REACT_APP_HITACHI_APP_TYPE as AppType;
  }

  const currentDomain = getBaseDomain({ baseUrl: href });

  switch (currentDomain) {
    case getBaseDomain({ baseUrl: config.applicantDomain }):
      return AppType.Applicant;
    case getBaseDomain({ baseUrl: config.retailerDomain }):
      return AppType.Retailer;
  }

  return AppType.Retailer;
};

export const getStore = ({
  overrideApiClient,
  overrideApplicationsService,
  overrideConfigService,
  overrideApplicantConfigService,
  overrideMailOrderApplicationService,
  overrideSendPrecontractInfoService,
  overrideSendLoanAdvanceInfoService,
  overrideBankAccountCheckService,
  overrideAuthService,
  overrideAddressLookupService,
  overrideDecisionService,
  overrideUserService,
  overrideRetailerService,
  overrideProductService,
  overrideRetailerConfigService,
  overrideDocumentDownloadConfirmationService,
  overrideUsersService,
  overrideGoodsListService,
  initialState,
  history: historyOverride,
  appType,
  appDomainConfig
}: GetStoreInput) => {
  const isMailOrderApp = appType === AppType.Applicant;
  const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const sagaMiddleware = createSagaMiddleware();
  const history = historyOverride || createBrowserHistory();
  const routerMiddleware = createRouterMiddleware(history);
  const persistRootConfig = {
    key: 'root',
    storage: storageSession
  };
  const persistBucketConfig = {
    key: 'bucket',
    storage: storageSession
  };
  const persistedApplicantAuth = persistReducer(
    // Loading state is ephemeral - should not be persisted
    {
      ...persistRootConfig,
      blacklist: ['authorizationRequestState', 'tokenExchangeState'] as Array<
        keyof AuthState
      >
    },
    applicantAuth
  );
  const signingRedirectsStore = persistReducer(
    persistBucketConfig,
    signingRedirects
  );

  const createRootReducer = (hist: History) =>
    combineReducers({
      router: connectRouter(hist),
      address,
      application,
      save,
      products,
      retailer,
      user,
      decision,
      goodsList,
      sign,
      previousApplications,
      previousApplicationDetail,
      ui,
      agreement,
      retailerConfig,
      declinedEmail,
      endAndSend,
      applicantConfig,
      config,
      customerSatisfaction,
      preContractCreditInfo,
      applicantAuth: persistedApplicantAuth,
      signingRedirects: signingRedirectsStore,
      sendPrecontractInfo,
      sendLoanAdvanceInfo,
      changeFormStep,
      users,
      enhancedUsers,
      loanAmend
    });

  const tempStore = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(
      applyMiddleware(
        // createLogger({ stateTransformer: () => null }),
        routerMiddleware,
        sagaMiddleware,
        createActionEnhancerMiddleware(() => [featureFlagEnhancer])
      )
    )
  );

  // Handler for acting on route changes
  history.listen(() => {
    const state = tempStore.getState();
    if (notificationDisplayed(state)) {
      tempStore.dispatch(closeNotification());
    }
  });

  const persistor = persistStore(tempStore);
  const apiClient =
    overrideApiClient ||
    getApiClient({
      isMailOrderApp,
      store: tempStore,
      dispatch: tempStore.dispatch
    });

  // Services
  // TODO: Extract this higher up to remove dependency on API client
  // This will allow us to test the whole app with only a thin layer
  // of external dependencies mocked out.
  const applicationsService =
    overrideApplicationsService || getApplicationsService({ apiClient });

  const configService =
    overrideConfigService || getConfigService({ apiClient });

  const applicantConfigService =
    overrideApplicantConfigService || getApplicantConfigService({ apiClient });

  const mailOrderApplicationService =
    overrideMailOrderApplicationService ||
    getMailOrderApplicationService({ apiClient });

  const sendPrecontractInfoService =
    overrideSendPrecontractInfoService ||
    getSendPrecontractInfoService({
      apiClient
    });

  const sendLoanAdvanceInfoService =
    overrideSendLoanAdvanceInfoService ||
    getSendLoanAdvanceInfoService({
      apiClient
    });

  const bankAccountCheckService =
    overrideBankAccountCheckService ||
    getBankAccountCheckService({
      apiClient,
      bankCheckEndpoints: getBankCheckEndpoints(appType)
    });

  const authService = overrideAuthService || getAuthService({ apiClient });

  const addressLookupService =
    overrideAddressLookupService || getAddressLookupService({ apiClient });

  const mailOrderAddressLookupService =
    overrideAddressLookupService ||
    getMailOrderAddressLookupService({ apiClient });

  const decisionService =
    overrideDecisionService ||
    getDecisionService({ apiClient, applicationsService, appDomainConfig });

  const mailOrderDecisionService =
    overrideDecisionService ||
    getDecisionService({
      apiClient,
      isMoPbf: true,
      applicationsService,
      appDomainConfig
    });

  const userService = overrideUserService || getUserService({ apiClient });

  const retailerService =
    overrideRetailerService || getRetailerService({ apiClient });

  const productService =
    overrideProductService || getProductService({ apiClient });

  const retailerConfigService =
    overrideRetailerConfigService || getRetailerConfigService({ apiClient });

  const documentDownloadConfirmationService =
    overrideDocumentDownloadConfirmationService ||
    getDocumentDownloadConfirmationService({ apiClient });

  const usersService = overrideUsersService || getUsersService({ apiClient });

  const goodsListService =
    overrideGoodsListService || getGoodsListService({ apiClient });

  const rootSaga = getRootSaga({
    apiClient,
    applicationsService,
    configService,
    applicantConfigService,
    mailOrderApplicationService,
    isMailOrderApp,
    sendPrecontractInfoService,
    sendLoanAdvanceInfoService,
    authService,
    bankAccountCheckService,
    addressLookupService,
    mailOrderAddressLookupService,
    decisionService,
    mailOrderDecisionService,
    userService,
    retailerService,
    productService,
    retailerConfigService,
    documentDownloadConfirmationService,
    usersService,
    goodsListService
  });
  sagaMiddleware.run(rootSaga);

  return { persistor, store: tempStore, history, apiClient };
};

export type ResetStoreAction = ActionType<typeof resetStore>;

export type Store = ReturnType<typeof getStore>['store'];

export type RootState = StateType<Store['getState']>;

export const useSelector = <TSelected = unknown>(
  selector: (state: RootState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean
) => {
  return realUseSelector(selector, equalityFn);
};
