import { Address } from 'hitachi-retail-core';
import { Reducer } from 'redux';
import { ActionType, getType, createStandardAction } from 'typesafe-actions';
import { resetStore, ResetStoreAction } from '../actions';
import { getAddresses } from './actions';

export const RESET_POSTCODE_LOOKUP = 'RESET_POSTCODE_LOOKUP';
export const resetPostcodeLookup = createStandardAction(
  RESET_POSTCODE_LOOKUP
)();

export type ResetPostcodeLookupAction = ActionType<typeof resetPostcodeLookup>;
export type AddressAction = ActionType<typeof getAddresses>;

export interface AddressState {
  addressResults?: Address[];
  errorMessage?: string;
  processing?: boolean;
}

export interface State {
  [namespace: string]: AddressState | undefined;
}

export const initialState: State = {};

export const reducer: Reducer<
  State,
  AddressAction | ResetStoreAction | ResetPostcodeLookupAction
> = (state = initialState, action) => {
  switch (action.type) {
    case getType(getAddresses.request): {
      // Either namespace or index
      const { namespace } = action.payload.meta;

      return {
        ...state,
        [namespace]: { processing: true }
      };
    }

    case getType(getAddresses.failure): {
      // Either namespace or index
      const { namespace } = action.payload.meta;

      return {
        ...state,
        [namespace]: {
          errorMessage:
            action.payload.error.message ||
            'An error occurred. Please try again later.',
          processing: false
        }
      };
    }

    case getType(getAddresses.success): {
      // Either namespace or index
      const {
        addressResults,
        meta: { namespace }
      } = action.payload;

      const asNumber = (value: string) => parseInt(value, 10);

      const sortedAddressResults = addressResults.sort((a, b) => {
        if (a.houseNumber && b.houseNumber) {
          return Math.sign(asNumber(a.houseNumber) - asNumber(b.houseNumber));
        } else if (a.houseNumber && b.houseName) {
          return 1;
        } else if (a.houseName && b.houseNumber) {
          return -1;
        } else if (a.houseName && b.houseName) {
          return a.houseName < b.houseName ? -1 : 1;
        }
        return 0;
      });

      return {
        ...state,
        [namespace]: {
          addressResults: sortedAddressResults,
          processing: false
        }
      };
    }

    case getType(resetPostcodeLookup):
    case getType(resetStore): {
      return initialState;
    }

    default:
      return state;
  }
};
