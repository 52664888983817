import { Address } from 'hitachi-retail-core';
import { DefaultSagaParams } from '../sagas';

export interface AddressLookupService {
  getAddresses: (postcode: string) => Promise<Address[]>;
}

export const getAddressLookupService = ({
  apiClient
}: DefaultSagaParams): AddressLookupService => ({
  getAddresses: async (postcode: string): Promise<Address[]> => {
    const response = await apiClient.get<Address[]>(
      `protected/address/${postcode}`
    );

    return response.data;
  }
});
