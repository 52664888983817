import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { resetStore, ResetStoreAction } from '../actions';
import { fetchDecision } from './actions';

export type FetchDecisionAction = ActionType<typeof fetchDecision>;

export interface State {
  errorMessage?: string;
  decisionResult?: string;
  maxInstalmentAmount?: string | null;
  softSearchExpiryDate?: string | null;
  tailoringExpiryDate?: string | null;
  processing?: boolean;
}

export type Action = FetchDecisionAction | ResetStoreAction;

export const initialState: State = {};

export const reducer: Reducer<State, Action> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchDecision.success): {
      return {
        ...state,
        decisionResult: action.payload.decisionResponse,
        maxInstalmentAmount: action.payload.maxInstalmentAmount,
        softSearchExpiryDate: action.payload.softSearchExpiryDate,
        tailoringExpiryDate: action.payload.tailoringExpiryDate,
        processing: false
      };
    }

    case getType(fetchDecision.failure): {
      return {
        ...state,
        errorMessage: action.payload.message,
        processing: false
      };
    }

    case getType(fetchDecision.request): {
      return {
        ...initialState,
        /**
         * When a 'Soft Search' status (REFER, ACCEPT, DECLINE) is converted to a 'Hard Search' the Redux slice that holds the date is wiped out.
         * This results in the 'DATE_NOT_FOUND' string being shown in the <SoftSearchMessagesOutcome /> component.
         * To fix this we are preserving the 'softSearchExpiryDate' value during this transition (only if it exists)
         * For more information view https://iw-novuna.atlassian.net/browse/FX-555
         */
        ...(state.softSearchExpiryDate != null
          ? { softSearchExpiryDate: state.softSearchExpiryDate }
          : {}),
        processing: true
      };
    }

    case getType(resetStore): {
      return initialState;
    }

    default:
      return state;
  }
};
