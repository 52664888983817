import { AxiosInstance } from 'axios';
import {
  BankAccountCheckRequest,
  BankAccountCheckResponse
} from 'hitachi-retail-core/build/api/bankCheck';
import { AppType } from '../store';

export interface BasicBankCheckRequest {
  sortCode: string;
  accountNumber: string;
}

export type BasicBankCheckResponse = Pick<BankAccountCheckResponse, 'valid'>;

export interface BankAccountCheckService {
  validateBankDetails: (
    payload: BasicBankCheckRequest
  ) => Promise<BasicBankCheckResponse>;
  updateBankDetails: (
    payload: BankAccountCheckRequest
  ) => Promise<BankAccountCheckResponse>;
}

export interface BankCheckEndpoints {
  validateBankDetails: string;
  updateBankDetails: string;
}

export interface GetBankCheckServiceParams {
  apiClient: AxiosInstance;
  bankCheckEndpoints: BankCheckEndpoints;
}
const retailEndpoints: BankCheckEndpoints = {
  updateBankDetails: 'protected/bank-details-check',
  validateBankDetails: '/mo/basic-bank-check' // Not used in retail
};
const mailOrderEndpoints: BankCheckEndpoints = {
  updateBankDetails: '/mo/bank-check',
  validateBankDetails: '/mo/basic-bank-check'
};

export const getBankCheckEndpoints = (appType: AppType): BankCheckEndpoints => {
  return appType === AppType.Applicant ? mailOrderEndpoints : retailEndpoints;
};

export const getBankAccountCheckService = ({
  apiClient,
  bankCheckEndpoints
}: GetBankCheckServiceParams): BankAccountCheckService => ({
  validateBankDetails: async payload => {
    try {
      const response = await apiClient.put<BasicBankCheckResponse>(
        bankCheckEndpoints.validateBankDetails,
        payload
      );

      return response.data;
    } catch (err) {
      if (err.code === 'ECONNABORTED') {
        throw new Error('timed_out');
      }
      throw new Error('Bank details check failed');
    }
  },
  updateBankDetails: async payload => {
    try {
      const response = await apiClient.put<BankAccountCheckResponse>(
        bankCheckEndpoints.updateBankDetails,
        payload
      );

      return response.data;
    } catch (err) {
      if (err.code === 'ECONNABORTED') {
        throw new Error('timed_out');
      }
      throw new Error('Bank details check failed');
    }
  }
});
