import { useCallback } from 'react';

type Test = (open: boolean) => void;

export const useCloseRevertModalCallback = (removeModal: () => void): Test => {
  return useCallback(
    (open: boolean) => {
      if (!open) {
        removeModal();
      }
    },
    [removeModal]
  );
};
