import {
  Box,
  Button,
  Text,
  NovunaHeading,
  Message,
  NovunaTick
} from 'compass-design';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { routes, generateApplicationPath } from 'routes';
import { useSelector } from 'store';
import {
  selectIsWebServicesJourney,
  selectShowKeyLoanFeaturesWSApps,
  selectShouldCollectDeposit
} from 'store/application/selectors';
import NovunaPageLayout from 'applicantApp/components/NovunaPageLayout';
import { QuoteSummary } from 'components/QuoteSummary';
import { ApplicantApplicationProps } from 'applicantApp/pages/ApplicantApplication/ApplicantApplication';
import { FinanceApplication } from 'hitachi-retail-core';
import { LoanDetails } from 'retailerApp/utils/loanDetails/getLoanDetails';
import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import getAffordabilityAccept from 'retailerApp/utils/loanDetails/getAffordabilityAccept';
import PbfTailoringOptions from '../PbfTailoringOptions';
import { getIsTailoringExpired } from 'utils/expiryDate';

export interface DecisionAcceptProps {
  id: string;
  financeApplication: FinanceApplication;
  loanDetails: LoanDetails;
  maxInstalmentAmount: string | undefined;
  tailoringReduceTermEnabled?: boolean;
  tailoringIbcReduceTermEnabled?: boolean;
  tailoringExpiryDate?: string | null;
}

const DecisionAccept: React.FC<DecisionAcceptProps &
  Pick<
    ApplicantApplicationProps,
    'onConfirmTailoringUpdate' | 'enabledFeatures' | 'onAcceptOriginalOffer'
  >> = ({
  id,
  financeApplication,
  loanDetails,
  maxInstalmentAmount,
  tailoringReduceTermEnabled,
  tailoringIbcReduceTermEnabled,
  enabledFeatures,
  tailoringExpiryDate,
  onConfirmTailoringUpdate,
  onAcceptOriginalOffer
}) => {
  const webServicesJourney = useSelector(selectIsWebServicesJourney);
  const isShowKeyLoanFeatures = useSelector(selectShowKeyLoanFeaturesWSApps);
  const isOnlineAndHasDeposit = useSelector(selectShouldCollectDeposit);

  let pattern: string;
  if (isOnlineAndHasDeposit) {
    pattern = routes.mailOrder.application.onlineDeposit;
  } else if (webServicesJourney && !isShowKeyLoanFeatures) {
    pattern = routes.mailOrder.application.loanInformation;
  } else {
    pattern = routes.mailOrder.application.keyLoanFeatures;
  }

  const affordabilityAccept = getAffordabilityAccept({
    tailoringFeatureAffordabilityAccept: enabledFeatures.has(
      CompassFeature.TAILORING_AFFORDABILITY_ACCEPT_FEATURE
    ),
    tailoringReduceTermEnabled,
    tailoringIbcReduceTermEnabled,
    loanDetails,
    isPbfOrMo: true
  });

  const affordabilityAcceptProps = affordabilityAccept
    ? {
        ...affordabilityAccept,
        onConfirmTailoringUpdate,
        onAcceptOriginalOffer
      }
    : undefined;

  return (
    <NovunaPageLayout>
      <NovunaHeading
        as='h1'
        data-test-id='mo-decision-accept-page-heading'
        mb={3}>
        Application decision
      </NovunaHeading>
      {maxInstalmentAmount &&
      affordabilityAcceptProps &&
      !loanDetails.tailoringOptionSelected &&
      !getIsTailoringExpired({ tailoringExpiryDate }) ? (
        <PbfTailoringOptions
          data-test-id={'pbf-tailoring-options-on-accept'}
          originalApplication={financeApplication}
          maxInstalmentAmount={maxInstalmentAmount!}
          affordabilityTailoring={affordabilityAcceptProps!}
          onAcceptOriginalOffer={
            affordabilityAcceptProps?.onAcceptOriginalOffer
          }
          applicationId={id}
        />
      ) : (
        <>
          <Message variant='success' icon={<NovunaTick />}>
            <NovunaHeading as='h3' mb={1}>
              Accepted
            </NovunaHeading>
            <Text data-test-id='decision-accept-text'>
              Great news, your application has been approved!
            </Text>
          </Message>
          <Box mt={4} sx={{ display: ['block', null, null, 'none'] }}>
            <QuoteSummary />
          </Box>
          {isOnlineAndHasDeposit ? (
            <>
              <Text my={4}>
                You need to pay the deposit then electronically sign the
                agreement to accept the terms and conditions.
              </Text>
              <NovunaHeading as='h2' mb={2}>
                Deposit payment
              </NovunaHeading>
              <Text>
                Novuna Personal Finance collects your deposit on behalf of the
                retailer. Upon payment to us, your deposit will become the legal
                property of the retailer.
              </Text>
            </>
          ) : (
            <Text mt={4}>
              You need to electronically sign the agreement to accept the terms
              and conditions.
            </Text>
          )}
          <Button
            mt={5}
            {...({
              to: generateApplicationPath(pattern, {
                id
              })
            } as any)}
            as={ReactRouterLink}
            isJumbo
            data-test-id='mo-decision-accept-button'>
            Continue
          </Button>
        </>
      )}
    </NovunaPageLayout>
  );
};

export default DecisionAccept;
