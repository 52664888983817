import { UserPolicyResponse } from 'hitachi-retail-core/build/api/userPolicy';
import { UserRole } from 'hitachi-retail-core/build/userPolicy';
import { DefaultSagaParams } from '../sagas';

interface UserLoginParams {
  retailerName: string;
  username: string;
  password: string;
}

export interface UserSetPasswordParams {
  retailerName: string;
  username: string;
  password: string;
  session: string;
  givenName: string;
  familyName: string;
}

export interface SessionCheckApiResponse {
  username: string;
  groups?: UserRole[];
}

export interface SessionCheckResult {
  success: boolean;
  data?: SessionCheckApiResponse;
}

export type UserLogin = (params: UserLoginParams) => Promise<unknown>;
export type UserLogout = () => Promise<unknown>;
export type UserSessionCheck = () => Promise<SessionCheckResult>;
export type UserSetPassword = (
  params: UserSetPasswordParams
) => Promise<unknown>;
export type UserGetPolicy = () => Promise<UserPolicyResponse>;

export interface UserService {
  login: UserLogin;
  logout: UserLogout;
  sessionCheck: UserSessionCheck;
  setPassword: UserSetPassword;
  getUserPolicy: UserGetPolicy;
}

export const getUserService = ({
  apiClient
}: DefaultSagaParams): UserService => ({
  login: async ({ retailerName, username, password }) => {
    try {
      const res = await apiClient.post('login', {
        password,
        retailerName,
        username
      });

      const acceptStatus = [200, 204];
      if (!res || !acceptStatus.includes(res.status)) {
        throw Error;
      }

      return res;
    } catch (error) {
      if (error.response) {
        const response = error.response;
        if (response.status >= 400 && response.status < 500) {
          throw Error(response.data.message);
        }
      }
      throw Error(
        'Something went wrong. Please check your details and try again.'
      );
    }
  },
  logout: async () => {
    try {
      return await apiClient.post('protected/logout');
    } catch (error) {
      throw Error('CreditMaster3 logout error. Please try again later.');
    }
  },
  sessionCheck: async () => {
    try {
      const response = await apiClient.get<SessionCheckApiResponse>(
        'protected/session-check'
      );
      return {
        success: response.status === 200,
        data: response.data
      };
    } catch {
      return {
        success: false
      };
    }
  },
  setPassword: async params => {
    try {
      return await apiClient.post('new-password-challenge', params);
    } catch (error) {
      if (error.response) {
        const response = error.response;
        if (response.status >= 400 && response.status < 500) {
          throw Error(response.data.message);
        }
      }

      throw Error('Failed to set password. Please try again later.');
    }
  },
  getUserPolicy: async () => {
    try {
      const response = await apiClient.get<UserPolicyResponse>(
        'protected/user/policy'
      );
      return response.data;
    } catch (error) {
      throw Error('Failed to get user policy');
    }
  }
});
