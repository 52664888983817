import {
  Box,
  Flex,
  InputWrapper,
  TertiaryLink,
  TertiaryLinkDirection,
  Text,
  TextField
} from 'compass-design';
import { Field, FieldProps } from 'formik';
import React, { Fragment } from 'react';
import { getTone } from 'utils/getTone';
import { labelPropsFromMeta } from 'utils/labelPropsFromMeta';
import { DetailsCaptureValues } from '../../schema';
import { AddressInputWrapperProps } from '../AddressInputWrapper/AddressInputWrapper';

export type AddressInputProps = Pick<
  AddressInputWrapperProps,
  'formNamespace' | 'addressIndex' | 'setEditAddress'
>;

const AddressInput: React.FC<AddressInputProps> = ({
  formNamespace,
  addressIndex,
  setEditAddress
}) => {
  return (
    <Fragment>
      <Text sx={{ fontSize: '3', fontWeight: 'lighter' }}>Address:</Text>
      <Field name={`${formNamespace}.flatName`}>
        {({ field, meta }: FieldProps<string, DetailsCaptureValues>) => (
          <Box mt={4}>
            <InputWrapper
              id={`${formNamespace}.flatName`}
              label='Flat name/number'
              error={meta.error}
              {...labelPropsFromMeta({ meta }, 'label')}>
              <TextField
                {...labelPropsFromMeta({ meta }, 'input')}
                type='text'
                {...field}
                id={`${formNamespace}.flatName`}
                tone={getTone(meta)}
              />
            </InputWrapper>
          </Box>
        )}
      </Field>

      <Field name={`${formNamespace}.houseName`}>
        {({ field, meta }: FieldProps<string, DetailsCaptureValues>) => (
          <Box mt={4}>
            <InputWrapper
              id={`${formNamespace}.houseName`}
              label='House name'
              description='Optional'
              error={meta.error}
              {...labelPropsFromMeta({ meta }, 'label')}>
              <TextField
                {...labelPropsFromMeta({ meta }, 'input')}
                type='text'
                {...field}
                id={`${formNamespace}.houseName`}
                tone={getTone(meta)}
              />
            </InputWrapper>
          </Box>
        )}
      </Field>

      <Field name={`${formNamespace}.houseNumber`}>
        {({ field, meta }: FieldProps<string, DetailsCaptureValues>) => (
          <Box mt={4}>
            <InputWrapper
              id={`${formNamespace}.houseNumber`}
              label='House number'
              error={meta.error}
              {...labelPropsFromMeta({ meta }, 'label')}>
              <TextField
                {...labelPropsFromMeta({ meta }, 'input')}
                type='text'
                {...field}
                id={`${formNamespace}.houseNumber`}
                tone={getTone(meta)}
              />
            </InputWrapper>
          </Box>
        )}
      </Field>

      <Field name={`${formNamespace}.street`}>
        {({ field, meta }: FieldProps<string, DetailsCaptureValues>) => (
          <Box mt={4}>
            <InputWrapper
              id={`${formNamespace}.street`}
              label='Street'
              error={meta.error}
              {...labelPropsFromMeta({ meta }, 'label')}>
              <TextField
                {...labelPropsFromMeta({ meta }, 'input')}
                type='text'
                {...field}
                id={`${formNamespace}.street`}
                tone={getTone(meta)}
              />
            </InputWrapper>
          </Box>
        )}
      </Field>

      <Field name={`${formNamespace}.town`}>
        {({ field, meta }: FieldProps<string, DetailsCaptureValues>) => (
          <Box mt={4}>
            <InputWrapper
              id={`${formNamespace}.town`}
              label='Town/City'
              error={meta.error}
              {...labelPropsFromMeta({ meta }, 'label')}>
              <TextField
                {...labelPropsFromMeta({ meta }, 'input')}
                type='text'
                {...field}
                id={`${formNamespace}.town`}
                tone={getTone(meta)}
              />
            </InputWrapper>
          </Box>
        )}
      </Field>

      <Field name={`${formNamespace}.county`}>
        {({ field, meta }: FieldProps<string, DetailsCaptureValues>) => (
          <Box mt={4}>
            <InputWrapper
              id={`${formNamespace}.county`}
              label='County'
              description='Optional'
              error={meta.error}
              {...labelPropsFromMeta({ meta }, 'label')}>
              <TextField
                {...labelPropsFromMeta({ meta }, 'input')}
                type='text'
                {...field}
                id={`${formNamespace}.county`}
              />
            </InputWrapper>
          </Box>
        )}
      </Field>

      <Field name={`${formNamespace}.postCode`}>
        {({ field, meta }: FieldProps<string, DetailsCaptureValues>) => (
          <Box mt={4}>
            <InputWrapper
              id={`${formNamespace}.postCode`}
              label='Postcode'
              error={meta.error}
              {...labelPropsFromMeta({ meta }, 'label')}>
              <Flex>
                <TextField
                  {...labelPropsFromMeta({ meta }, 'input')}
                  type='text'
                  {...field}
                  id={`${formNamespace}.postCode`}
                  flexSx={{ flex: '3' }}
                  short
                  tone={getTone(meta)}
                />
              </Flex>
            </InputWrapper>
          </Box>
        )}
      </Field>
      <Box mt={4}>
        <TertiaryLink
          direction={TertiaryLinkDirection.FORWARDS}
          text='Look for another address'
          dataTestId={`address-${addressIndex}-lookup`}
          onClick={() => {
            setEditAddress(true);
          }}
        />
      </Box>
    </Fragment>
  );
};

export default AddressInput;
