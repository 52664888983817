import { AxiosRequestConfig } from 'axios';
import { DecisionRevision, validationMessages } from 'hitachi-retail-core';
import { CancellationReason } from 'hitachi-retail-core/build/enums/applicationCancellation';
import { DecisionErrorMessages } from 'hitachi-retail-core/build/enums/decision';
import { AuditEventDetail } from 'hitachi-retail-core/build/eventEmitters/types';
import { IncompleteFinanceApplication } from 'hitachi-retail-core/build/schemas/financeApplication';
import { ReferralNote } from 'hitachi-retail-core/build/schemas/referralNote';
import qs from 'qs';
import { partialSettlementFailedMessage } from 'sagas/updateApplication/saga';
import { SupplierNumber } from 'store/retailer/reducer';
import { DefaultSagaParams } from '../sagas';
import { PreviousApplicationDetail } from '../store/previousApplicationDetail/actions';
import {
  FetchPreviousApplicationsResponse,
  FetchSearchPreviousApplicationsResponse
} from '../store/previousApplications/actions';
import { SignApplicationResponse } from '../store/sign/actions';

export interface ApplicationsService {
  getAll: () => Promise<FetchPreviousApplicationsResponse>;
  searchApplications: ({
    term,
    offset,
    limit
  }: {
    term: string;
    offset: number;
    limit: number;
  }) => Promise<FetchSearchPreviousApplicationsResponse>;
  getById: (params: {
    id: string;
    isMoPbf?: boolean;
  }) => Promise<PreviousApplicationDetail>;
  signApplication: (
    id: string,
    isMoPbf: boolean
  ) => Promise<SignApplicationResponse>;
  markGoodsAsDelivered: (id: string) => Promise<boolean>;
  cancelApplication: (
    id: string,
    cancellationReason?: CancellationReason
  ) => Promise<boolean>;
  requestPartialSettlement: (
    id: string,
    partialSettlementAmount: string
  ) => Promise<boolean>;
  saveApplication: (payload: SaveRequest) => Promise<string>;
  sendToCustomer: (id: string) => Promise<void>;
  getReferralNotes: (id: string) => Promise<ReferralNote[]>;
  submitReferralNote: (payload: SubmitReferralNoteRequest) => Promise<void>;
  revertToVersion: (id: string, versionId: string) => Promise<void>;
  submitApplicationExpiryId: (
    id: string,
    revisions: DecisionRevision[]
  ) => Promise<string>;
  getAuditHistory: (
    id: string,
    branchId: string
  ) => Promise<AuditEventDetail[]>;
}

export interface SaveResponse {
  id?: string;
}

export interface SaveRequest {
  id?: string;
  proposingOnBehalfOf?: SupplierNumber;
  document: IncompleteFinanceApplication;
}

export type UnsavedReferralNote = Pick<ReferralNote, 'username' | 'message'>;

export interface SubmitReferralNoteRequest {
  id: string;
  note: UnsavedReferralNote;
}

export const decisionHasExpiredOrMismatched = (errorMessage: string) => {
  const expiredOrMismatch: string[] = [
    DecisionErrorMessages.Expired,
    DecisionErrorMessages.Mismatch
  ];
  return expiredOrMismatch.includes(errorMessage);
};

export const getApplicationsService = ({
  apiClient
}: DefaultSagaParams): ApplicationsService => ({
  getAll: async () => {
    try {
      const response = await apiClient.get<FetchPreviousApplicationsResponse>(
        'protected/applications'
      );

      return response.data;
    } catch {
      throw new Error('Failed to retrieve applications');
    }
  },

  searchApplications: async ({ term, offset, limit }) => {
    try {
      const response = await apiClient.get<FetchPreviousApplicationsResponse>(
        'protected/applications/search',
        {
          params: {
            term,
            offset,
            limit
          }
        }
      );
      return response.data;
    } catch (err) {
      if (err?.response?.data === 'Too many results, please refine search') {
        throw new Error(validationMessages.MAX_RESULTS_EXCEEDED);
      }
      throw new Error('Failed to retrieve applications');
    }
  },

  getById: async ({ id, isMoPbf = false }) => {
    try {
      const getByIdEndpoint = isMoPbf
        ? `mo/application/${id}`
        : `protected/applications/${id}`;

      const response = await apiClient.get<PreviousApplicationDetail>(
        getByIdEndpoint
      );

      return response.data;
    } catch {
      throw new Error('Failed to retrieve application by ID');
    }
  },

  saveApplication: async payload => {
    let response;

    try {
      response = await apiClient.post<SaveResponse>(
        '/protected/applications',
        payload
      );
    } catch {
      throw new Error('API call failed');
    }
    const appId = response.data && response.data.id;
    if (!appId) {
      throw new Error('No application ID returned in API response');
    }

    return appId;
  },

  signApplication: async (id, isMo) => {
    try {
      const endpoint = isMo
        ? `mo/application/${id}/sign`
        : `protected/applications/${id}/sign`;

      const requestConfig: AxiosRequestConfig = isMo
        ? {
            validateStatus: status => {
              return status === 409 || (status >= 200 && status < 400);
            }
          }
        : {};

      const response = await apiClient.put<SignApplicationResponse>(
        endpoint,
        null,
        requestConfig
      );

      // Request config interprets the 409 error as "success"
      if (response.status === 409) {
        const { data } = response;
        const { errors } = data || {};

        if (
          errors &&
          errors.length > 0 &&
          decisionHasExpiredOrMismatched(errors[0])
        ) {
          throw new Error(errors[0]);
        }

        throw new Error('Request failed with status code 409');
      }

      return response.data;
    } catch (error) {
      if (decisionHasExpiredOrMismatched(error.message)) {
        throw error;
      }

      throw new Error('Failed to sign application');
    }
  },

  markGoodsAsDelivered: async id => {
    try {
      await apiClient.put(`protected/applications/${id}/delivered`);

      return true;
    } catch {
      throw new Error('Failed to mark goods as delivered by application ID');
    }
  },

  cancelApplication: async (id, cancellationReason) => {
    try {
      const payload = cancellationReason && {
        cancellationReason
      };
      await apiClient.put(`protected/applications/${id}/cancel`, payload);

      return true;
    } catch {
      throw new Error('Failed to cancel application by ID');
    }
  },

  requestPartialSettlement: async (id, partialSettlementAmount) => {
    try {
      const payload = partialSettlementAmount && {
        partialSettlementAmount
      };
      await apiClient.post(
        `protected/applications/${id}/partial-settlement`,
        payload
      );

      return true;
    } catch {
      throw new Error(partialSettlementFailedMessage);
    }
  },

  sendToCustomer: async id => {
    try {
      await apiClient.post(`protected/applications/${id}/send`);
    } catch {
      throw new Error('Failed to send application to customer');
    }
  },

  getReferralNotes: async id => {
    try {
      const response = await apiClient.get<ReferralNote[]>(
        `protected/applications/${id}/referralNotes`
      );
      return response.data;
    } catch {
      throw new Error('Failed to retrieve referral notes for application');
    }
  },

  submitReferralNote: async ({ id, note }) => {
    try {
      await apiClient.post(`protected/applications/${id}/referralNotes`, note);
    } catch {
      throw new Error('Failed to add referral note for application');
    }
  },

  revertToVersion: async (id, versionId) => {
    try {
      await apiClient.post(
        `protected/applications/${id}/versions/${versionId}/revert`
      );
    } catch {
      throw new Error('The agreement could not be reverted, please try again.');
    }
  },

  submitApplicationExpiryId: async (id, revisions) => {
    try {
      const response = await apiClient.post<string>(
        `protected/applications/${id}/application-expiry-date`,
        { revisions }
      );
      return response.data;
    } catch {
      throw new Error('Failed to submit new application expiry date');
    }
  },

  getAuditHistory: async (id, branchId) => {
    if (!branchId || branchId.length === 0) {
      throw new Error('Please provide the branch');
    }

    try {
      const response = await apiClient.get<AuditEventDetail[]>(
        `protected/applications/${id}/audit-history`,
        {
          params: {
            branchId
          },
          paramsSerializer: params => {
            return qs.stringify(params);
          }
        }
      );
      return response.data;
    } catch {
      throw new Error('Failed to retrieve audit history');
    }
  }
});
