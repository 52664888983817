import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { RootState } from '../../../store';
import { gotoFormStep, GotoFormStepAction } from './actions';

export interface State {
  currentStepIndex: number;
}

const initialState: State = { currentStepIndex: 0 };

export const reducer: Reducer<State, GotoFormStepAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(gotoFormStep): {
      return { ...state, currentStepIndex: action.payload };
    }
  }

  return state;
};

export const selectCurrentStepIndex = (state: RootState) =>
  state.changeFormStep.currentStepIndex;
