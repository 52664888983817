/**
 * TODO (Phase 3):
 * After Phase 3 roll-out - remove runtime and test code regarding enabledfeatures and retailerData that has been added on this PR https://bitbucket.org/novuna/compass-ui/pull-requests/1224.
 * Example:
 * 'selectEnabledFeautures' & 'enabledFeatures'
 * 'selectRetailer' & retailerData
 * 'isPhaseThreeEnabled'
 * All calls to .select() and .next() in saga tests that involve retailer data and enabled features
 */
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { getType, isOfType } from 'typesafe-actions';
import { ApplicationsService } from '../../services/applicationsService';
import {
  cancelApplication,
  fetchPreviousApplicationById,
  markGoodsAsDelivered,
  requestPartialSettlement,
  revertApplicationToVersion
} from '../../store/previousApplicationDetail/actions';
import {
  CancelApplicationAction,
  MarkGoodsAsDeliveredAction,
  RequestPartialSettlementAction,
  RevertApplicationToVersionAction
} from '../../store/previousApplicationDetail/reducer';
import { NotificationType, showNotification } from '../../store/ui/actions';
import { getPreviousApplicationByIdSaga } from '../previousApplicationById/saga';
import { DecisionRequest, DecisionService } from '../../services/decision';
import {
  CompassFeature,
  FeatureConfig
} from 'hitachi-retail-core/build/features/features';
import { selectEnabledFeatures } from 'store/config/selectors';
import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';
import { IsPhaseThreeEnabledForRetailer } from 'sagas/retailer/saga';
import { selectRetailer } from 'store/retailer/selectors';

export const markDeliveredSuccessMessage = 'Payment requested successfully';
export const markDeliveredFailedMessage = 'Failed to request payment';
export const cancelledSuccessMessage = 'Application successfully cancelled';
export const partialSettlementSuccessMessage =
  'Partial settlement requested successfully';
export const partialSettlementFailedMessage =
  'Failed to request partial settlement';

interface GetMarkGoodsAsDeliveredSagaParams {
  applicationsService: ApplicationsService;
  fetchPreviousApplicationByIdSaga: any;
}

interface GetRequestPartialSettlementSagaParams {
  applicationsService: ApplicationsService;
}

interface GetRevertApplicationToVersionSaga {
  decisionService: DecisionService;
  applicationsService: ApplicationsService;
  fetchPreviousApplicationByIdSaga: any;
}

export const getMarkGoodsAsDeliveredSaga = ({
  applicationsService,
  fetchPreviousApplicationByIdSaga
}: GetMarkGoodsAsDeliveredSagaParams) =>
  function*(action: MarkGoodsAsDeliveredAction) {
    if (isOfType(getType(markGoodsAsDelivered.request), action)) {
      const { id } = action.payload;

      try {
        yield call(applicationsService.markGoodsAsDelivered, id);
      } catch (err) {
        yield put(markGoodsAsDelivered.failure(err));
        yield put(
          showNotification({
            message: markDeliveredFailedMessage,
            type: NotificationType.DANGER
          })
        );
        return;
      }

      try {
        const fetchApplicationAction = fetchPreviousApplicationById.request({
          id
        });
        yield call(fetchPreviousApplicationByIdSaga, fetchApplicationAction);
      } catch (err) {
        yield put(
          showNotification({
            message: err.message,
            type: NotificationType.DANGER
          })
        );
        return;
      }

      yield put(markGoodsAsDelivered.success());
      yield put(
        showNotification({
          message: markDeliveredSuccessMessage,
          type: NotificationType.SUCCESS
        })
      );
    }
  };

export const getCancelApplicationSaga = ({
  applicationsService,
  fetchPreviousApplicationByIdSaga
}: GetMarkGoodsAsDeliveredSagaParams) =>
  function*(action: CancelApplicationAction) {
    if (isOfType(getType(cancelApplication.request), action)) {
      const { id, cancellationReason } = action.payload;

      try {
        yield call(
          applicationsService.cancelApplication,
          id,
          cancellationReason
        );
      } catch (err) {
        yield put(cancelApplication.failure(err));
        yield put(
          showNotification({
            message: err.message,
            type: NotificationType.DANGER
          })
        );
        return;
      }

      try {
        const fetchApplicationAction = fetchPreviousApplicationById.request({
          id
        });
        yield call(fetchPreviousApplicationByIdSaga, fetchApplicationAction);
      } catch (err) {
        yield put(
          showNotification({
            message: err.message,
            type: NotificationType.DANGER
          })
        );
        return;
      }

      yield put(cancelApplication.success());
      yield put(
        showNotification({
          message: cancelledSuccessMessage,
          type: NotificationType.SUCCESS
        })
      );
    }
  };

export const getRequestPartialSettlementSaga = ({
  applicationsService
}: GetRequestPartialSettlementSagaParams) =>
  function*(action: RequestPartialSettlementAction) {
    if (isOfType(getType(requestPartialSettlement.request), action)) {
      const { id, partialSettlementAmount } = action.payload;

      try {
        yield call(
          applicationsService.requestPartialSettlement,
          id,
          partialSettlementAmount
        );
      } catch (err) {
        yield put(requestPartialSettlement.failure(err));
        yield put(
          showNotification({
            message: partialSettlementFailedMessage,
            type: NotificationType.DANGER
          })
        );
        return;
      }

      yield put(requestPartialSettlement.success());
      yield put(
        showNotification({
          message: partialSettlementSuccessMessage,
          type: NotificationType.SUCCESS
        })
      );
    }
  };

export const getRevertApplicationToVersionSaga = ({
  applicationsService,
  fetchPreviousApplicationByIdSaga,
  decisionService
}: GetRevertApplicationToVersionSaga) =>
  function*(action: RevertApplicationToVersionAction) {
    if (isOfType(getType(revertApplicationToVersion.request), action)) {
      try {
        const { id, versionId } = action.payload;

        const retailer: Retailer &
          IsPhaseThreeEnabledForRetailer = yield select(selectRetailer);

        const featureFlags: FeatureConfig = yield select(selectEnabledFeatures);
        const isPhaseThreeEnabledGlobally = featureFlags.has(
          CompassFeature.LOAN_QUEUING_PHASE_THREE
        );
        const { isPhaseThreeEnabledForRetailer } = retailer;

        const isPhaseThreeEnabled =
          (isPhaseThreeEnabledForRetailer && isPhaseThreeEnabledGlobally) ||
          false;

        // Revert the application
        yield call(applicationsService.revertToVersion, id, versionId);
        // Re-decision
        try {
          const decisionPayload: DecisionRequest = {
            id,
            revertApplicationDecision: true
          };
          yield call(
            decisionService.getDecision,
            decisionPayload,
            isPhaseThreeEnabled
          );
        } catch (err) {
          // Decision should not fail
        }
        // Fetch the application details via the saga.
        const fetchApplicationAction = fetchPreviousApplicationById.request({
          id
        });
        yield call(fetchPreviousApplicationByIdSaga, fetchApplicationAction);
        yield put(
          showNotification({
            message:
              'Agreement reverted - The amend has been deleted and the agreement has been reverted to the previous signed version.',
            type: NotificationType.SUCCESS
          })
        );
        yield put(revertApplicationToVersion.success());
        return;
      } catch (err) {
        yield put(revertApplicationToVersion.failure(err));
        yield put(
          showNotification({
            message: err.message,
            type: NotificationType.DANGER
          })
        );
        return;
      }
    }
  };

interface GetUpdateApplicationWatcherParams {
  decisionService: DecisionService;
  applicationsService: ApplicationsService;
}

export const getUpdateApplicationWatcher = ({
  applicationsService,
  decisionService
}: GetUpdateApplicationWatcherParams) =>
  function*() {
    const fetchPreviousApplicationByIdSaga = getPreviousApplicationByIdSaga({
      applicationsService
    });

    yield all([
      takeEvery(
        getType(markGoodsAsDelivered.request),
        getMarkGoodsAsDeliveredSaga({
          applicationsService,
          fetchPreviousApplicationByIdSaga
        })
      ),
      takeEvery(
        getType(cancelApplication.request),
        getCancelApplicationSaga({
          applicationsService,
          fetchPreviousApplicationByIdSaga
        })
      ),
      takeEvery(
        getType(revertApplicationToVersion.request),
        getRevertApplicationToVersionSaga({
          applicationsService,
          fetchPreviousApplicationByIdSaga,
          decisionService
        })
      ),
      takeEvery(
        getType(requestPartialSettlement.request),
        getRequestPartialSettlementSaga({
          applicationsService
        })
      )
    ]);
  };
