import React from 'react';
import { Box, Text } from 'compass-design';
import { BankAccountDetails } from 'hitachi-retail-core/build/schemas/bankDetails';

const ReadOnlyBankDetails: React.FC<BankAccountDetails> = ({
  name,
  sortCode,
  accountNumber
}) => {
  return (
    <Box>
      <Text sx={{ fontSize: 2 }} data-test-id='summary-review-page-bank-name'>
        {name}
      </Text>
      <Text sx={{ fontSize: 2 }} data-test-id='summary-review-page-bank-sort'>
        {sortCode}
      </Text>
      <Text
        sx={{ fontSize: 2 }}
        data-test-id='summary-review-page-bank-account'>
        {accountNumber}
      </Text>
    </Box>
  );
};

export default ReadOnlyBankDetails;
