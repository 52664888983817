import React from 'react';
import { Box, Message, NovunaHeading, Text } from 'compass-design';
import { PurpleMail } from 'smallMessageSvg';

const F2FReferralOpenBankingCard = () => (
  <Box mt={6} data-test-id='referral-update-card'>
    <Message variant='info'>
      <NovunaHeading as='h3' mb={1}>
        Referred
      </NovunaHeading>
      <Text mb={2}>
        Your application has been referred to the Underwriting Team, but we need
        some additional information from the customer before we can make a
        decision.
      </Text>
    </Message>
    <Message
      variant='info'
      mt={4}
      style={{ alignItems: 'center' }}
      icon={
        <PurpleMail
          width={32}
          height={32}
          viewBox='0 0 64 64'
          style={{ minHeight: '100%' }}
        />
      }>
      <NovunaHeading as='h3' mb={1}>
        Action required!
      </NovunaHeading>
      <Text mb={2}>
        We will shortly send the customer an email explaining how they can
        easily provide the information we need by consenting to an Open Banking
        request. This might take a few minutes. Please ask them to check their
        inbox.
      </Text>
    </Message>
  </Box>
);

export default F2FReferralOpenBankingCard;
