import React from 'react';
import {
  addEventsHandler,
  cancelTimer,
  removeEventsHandler,
  startTimer
} from './windowEvents';

export interface InactiveLogoutPropsFromState {
  loggedIn: boolean;
}

export interface InactiveLogoutPropsFromDispatch {
  onTimeout: () => void;
}

export type InactiveLogoutProps = InactiveLogoutPropsFromState &
  InactiveLogoutPropsFromDispatch;

export interface InactiveLogoutState {
  timeoutId: number;
}

const timeoutInMilliseconds = 20 * 60 * 1000; // 20 minute idle time
const eventsDetected = ['mousedown', 'keypress'];

export class InactiveLogout extends React.Component<
  InactiveLogoutProps,
  InactiveLogoutState
> {
  public state = {
    timeoutId: 0
  };

  public componentDidUpdate(prevProps: InactiveLogoutPropsFromState) {
    // Handle loggedIn status changing
    if (this.props.loggedIn !== prevProps.loggedIn) {
      if (this.props.loggedIn) {
        this.startMonitoring();
      } else {
        this.stopMonitoring();
      }
    }
  }

  public componentWillUnmount() {
    this.stopMonitoring();
  }

  public handleEventOccurred = () => {
    cancelTimer(this.state.timeoutId);
    this.startTimer();
  };

  public startTimer() {
    const timeoutId = startTimer(timeoutInMilliseconds, () =>
      this.props.onTimeout()
    );
    this.setState({ timeoutId });
  }

  public startMonitoring() {
    addEventsHandler(eventsDetected, this.handleEventOccurred);
    this.startTimer();
  }

  public stopMonitoring() {
    if (this.state.timeoutId) {
      removeEventsHandler(eventsDetected, this.handleEventOccurred);
      cancelTimer(this.state.timeoutId);
      this.setState({ timeoutId: 0 });
    }
  }

  public render() {
    return null;
  }
}
