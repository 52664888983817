import { call, put, select, takeLeading } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { SendPrecontractInfoService } from '../../services/sendPrecontractInfoService';
import { sendPrecontractInfo, SendPrecontractInfoAction } from './actions';
import { selectEmailSentAutomatically } from './reducer';

export interface SendPrecontractInfoSagaParams {
  sendPrecontractInfoService: SendPrecontractInfoService;
}

export const getSendPrecontractInfoSaga = ({
  sendPrecontractInfoService
}: SendPrecontractInfoSagaParams) =>
  function*(action: SendPrecontractInfoAction) {
    if (action.type !== getType(sendPrecontractInfo.request)) {
      return;
    }

    const { id } = action.payload;

    const alreadySent: boolean = yield select(selectEmailSentAutomatically);

    try {
      if (!alreadySent) {
        yield call(sendPrecontractInfoService.sendPrecontractInfo, id);
        yield put(sendPrecontractInfo.success());
      }
    } catch (err) {
      yield put(sendPrecontractInfo.failure(err));
    }
  };

type SendPrecontractInfoSaga = ReturnType<typeof getSendPrecontractInfoSaga>;

export const getSendPrecontractInfoWatcher = ({
  sendPrecontractInfoSaga
}: {
  sendPrecontractInfoSaga: SendPrecontractInfoSaga;
}) =>
  function*() {
    yield takeLeading(
      getType(sendPrecontractInfo.request),
      sendPrecontractInfoSaga
    );
  };
