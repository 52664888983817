import {
  Box,
  Text,
  NovunaHeading,
  ReadOnlyWrapper,
  TertiaryLink,
  TertiaryLinkDirection,
  Message
} from 'compass-design';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import { useSelector, useDispatch } from 'react-redux';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store';
import OnlineDepositError from './components/OnlineDepositError/OnlineDepositError';
import OnlineDepositPaid, {
  OnlineDepositProps
} from './components/OnlineDepositPaid/OnlineDepositPaid';
import { updateActiveApplication } from 'store/application/actions';
import { ServicesContext } from '../../../shared/App/App';
import { DepositCompleteResponse } from '../../../services/onlineDepositsService';
import {
  selectApplicationDocument,
  selectApplicationReturnUrl,
  selectApplicationSupplierOrderReference
} from 'store/application/selectors';
import { selectDeposit } from 'hitachi-retail-core/build/selectors';
import { formatCurrency } from 'utils/formatters';
import { CancelApplicationTextButton } from 'applicantApp/components/ExternalRedirectButtons/ExternalRedirectButtons';
import { useInterval } from '../../components/hooks/useInterval';
import NovunaPageLayout from 'applicantApp/components/NovunaPageLayout';
import GlobalPayHpp from './components/GlobalPayHpp/GlobalPayHpp';

type HppUrlState =
  | { loading: false; url: string }
  | { loading: true; url?: string };

const OnlineDeposit: React.FC<OnlineDepositProps> = ({
  id,
  sessionCheckInterval = 30000
}) => {
  const history = useHistory();
  const [hppUrl, setHppUrl] = useState<HppUrlState>({ loading: true });
  const [depositCompleteResponse, setDepositCompleteResponse] = useState<
    DepositCompleteResponse
  >();
  const [depositError, setDepositError] = useState<boolean>(false);
  const [displayRetryError, setDisplayRetryError] = useState<boolean>(false);
  const { onlineDepositsService, sessionCheckService } = useContext(
    ServicesContext
  );
  const dispatch = useDispatch();

  const application = useSelector(
    (state: RootState) => state.application.activeApplication
  );

  const onError = useCallback(() => setDepositError(true), []);

  const document = useSelector(selectApplicationDocument);
  const deposit = selectDeposit(document)?.toString() || '';
  const returnUrl = useSelector(selectApplicationReturnUrl);
  const supplierOrderReference = useSelector(
    selectApplicationSupplierOrderReference
  );

  const onComplete = useCallback(
    async event => {
      try {
        const response = await onlineDepositsService.depositPaymentComplete(
          id,
          event
        );

        if (response.status === 'Paid') {
          dispatch(
            updateActiveApplication({
              status: ApplicationStatus.deposit_paid
            })
          );
        }

        setDepositCompleteResponse(response);
        setDisplayRetryError(
          yes => yes || (response.status === 'Failure' && response.retryPayment)
        );
        setDepositError(response.status === 'Error');
      } catch (error) {
        setDepositError(true);
      }
    },
    [onlineDepositsService, id, dispatch]
  );

  const fetch = async () => {
    await sessionCheckService.checkSession(id);
  };
  useInterval(fetch, sessionCheckInterval);

  useEffect(() => {
    const retryableFailure = () => {
      return (
        depositCompleteResponse?.status === 'Failure' &&
        depositCompleteResponse.retryPayment
      );
    };

    const shouldInitiatePayment = () => {
      return (
        ![
          ApplicationStatus.deposit_paid,
          ApplicationStatus.deposit_unsuccessful
        ].includes(application.status) && !hppUrl.url
      );
    };

    if (shouldInitiatePayment() || retryableFailure()) {
      setHppUrl(state => (state.loading ? state : { ...state, loading: true }));
      setDepositCompleteResponse(undefined);

      // We should receive a URL from our backend that we can use
      onlineDepositsService
        .initiateDeposit(id)
        .then(url => setHppUrl({ loading: false, url }))
        .catch(() => setDepositError(true));
    }
  }, [onlineDepositsService, id, application, hppUrl, depositCompleteResponse]);

  const depositStr = formatCurrency(deposit);

  switch (application.status) {
    case ApplicationStatus.deposit_unsuccessful:
      return <OnlineDepositError id={id} />;
    case ApplicationStatus.deposit_paid:
      return <OnlineDepositPaid id={id} />;
  }

  if (depositError) {
    return <OnlineDepositError id={id} resumeable />;
  }

  switch (depositCompleteResponse?.status) {
    case 'Paid':
      return <OnlineDepositPaid id={id} />;
    case 'Error':
      return <OnlineDepositError id={id} resumeable />;
    case 'Failure':
      if (!depositCompleteResponse.retryPayment) {
        return <OnlineDepositError id={id} />;
      }
  }

  return (
    <NovunaPageLayout
      pageTop={
        <TertiaryLink
          direction={TertiaryLinkDirection.BACKWARDS}
          text='Application decision'
          onClick={() => {
            history.goBack();
          }}
        />
      }>
      <NovunaHeading as='h1' mb={2} data-test-id='online-deposit-page-heading'>
        Deposit
      </NovunaHeading>
      <Box mb={4}>
        <ReadOnlyWrapper
          label='Amount due'
          description='Payment card must be registered in your name to the home address used
          earlier in this application.'>
          <Text sx={{ fontWeight: 'bold' }} aria-labelledby='deposit'>
            {depositStr}
          </Text>
        </ReadOnlyWrapper>
      </Box>

      {displayRetryError && (
        <Box mb={4}>
          <Message variant='error'>
            <NovunaHeading as='h3' mb={1}>
              Payment failed
            </NovunaHeading>
            <Text>
              Please check your card details, try a different payment method or
              contact your bank for assistance.
            </Text>
          </Message>
        </Box>
      )}

      <GlobalPayHpp {...hppUrl} onComplete={onComplete} onError={onError} />

      <Box mt={5}>
        <CancelApplicationTextButton
          baseUrl={returnUrl}
          applicationId={id}
          supplierOrderReference={supplierOrderReference}
        />
      </Box>
    </NovunaPageLayout>
  );
};

export default OnlineDeposit;
