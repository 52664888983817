import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { fetchMailOrderConfig } from './actions';
import { RootState } from '../../../store';
import { ApplicantConfig } from 'hitachi-retail-core';
import { AsyncStatus } from '../AsyncStatus';

export type State = ApplicantConfig & { status: AsyncStatus };

export type FetchMailOrderConfigAction = ActionType<
  typeof fetchMailOrderConfig
>;

const initialState: State = {
  applicantAuthBaseUrl: '',
  applicantAuthClientId: '',
  decisionPollInterval: '',
  status: AsyncStatus.Default
};

export const reducer: Reducer<State, FetchMailOrderConfigAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchMailOrderConfig.request): {
      return {
        ...initialState,
        status: AsyncStatus.Loading
      };
    }

    case getType(fetchMailOrderConfig.success): {
      const data = action.payload;

      return {
        ...initialState,
        ...data,
        status: AsyncStatus.Success
      };
    }

    default: {
      return state;
    }
  }
};

export const selectApplicantAuthClientId = (state: RootState) =>
  state.applicantConfig.applicantAuthClientId;

export const selectApplicantAuthBaseUrl = (state: RootState) =>
  state.applicantConfig.applicantAuthBaseUrl;

export const selectApplicantConfigStatus = (config: RootState) =>
  config.applicantConfig.status;
