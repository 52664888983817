import { ApplicationModificationStatus } from 'hitachi-retail-core/build/application/modification';

const isSectionEditable = (
  currentSectionKey: string,
  editableSections: string[],
  applicationModificationStatus: ApplicationModificationStatus
): boolean => {
  if (
    applicationModificationStatus === ApplicationModificationStatus.NO ||
    applicationModificationStatus === ApplicationModificationStatus.READ_ONLY
  ) {
    return false;
  } else if (
    applicationModificationStatus === ApplicationModificationStatus.YES
  ) {
    return true;
  }

  return (
    applicationModificationStatus ===
      ApplicationModificationStatus.CONDITIONAL &&
    editableSections.includes(currentSectionKey)
  );
};

export default isSectionEditable;
