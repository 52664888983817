// Fetch enhanced users
export const FETCH_ENHANCED_USERS_REQUEST = 'FETCH_ENHANCED_USERS_REQUEST';
export const FETCH_ENHANCED_USERS_SUCCESS = 'FETCH_ENHANCED_USERS_SUCCESS';
export const FETCH_ENHANCED_USERS_FAILURE = 'FETCH_ENHANCED_USERS_FAILURE';

// Enhanced User Create
export const CREATE_ENHANCED_USER_REQUEST = 'CREATE_ENHANCED_USER_REQUEST';
export const CREATE_ENHANCED_USER_SUCCESS = 'CREATE_ENHANCED_USER_SUCCESS';
export const CREATE_ENHANCED_USER_FAILURE = 'CREATE_ENHANCED_USER_FAILURE';

// Enhanced User Lookup
export const LOOKUP_ENHANCED_USER_REQUEST = 'LOOKUP_ENHANCED_USER_REQUEST';
export const LOOKUP_ENHANCED_USER_SUCCESS = 'LOOKUP_ENHANCED_USER_SUCCESS';
export const LOOKUP_ENHANCED_USER_FAILURE = 'LOOKUP_ENHANCED_USER_FAILURE';

// Enhanced Set selected user
export const SELECTED_ENHANCED_USER_REQUEST = 'SELECTED_ENHANCED_USER_REQUEST';
export const SELECTED_ENHANCED_USER_SUCCESS = 'SELECTED_ENHANCED_USER_SUCCESS';
export const SELECTED_ENHANCED_USER_FAILURE = 'SELECTED_ENHANCED_USER_FAILURE';

// Enhanced Update user
export const UPDATE_ENHANCED_USER_REQUEST = 'UPDATE_ENHANCED_USER_REQUEST';
export const UPDATE_ENHANCED_USER_SUCCESS = 'UPDATE_ENHANCED_USER_SUCCESS';
export const UPDATE_ENHANCED_USER_FAILURE = 'UPDATE_ENHANCED_USER_FAILURE';

export const RESET_ENHANCED_USER = 'RESET_ENHANCED_USER';
export const SET_EDIT_USER = 'SET_EDIT_USER';
export const SET_SELECTED_ENHANCED_USER = 'SET_SELECTED_ENHANCED_USER';

export const SET_SELECTED_ENHANCED_BROKER = 'SET_SELECTED_ENHANCED_BROKER';
export const SET_SELECTED_ENHANCED_HEAD_OFFICE =
  'SET_SELECTED_ENHANCED_HEAD_OFFICE';
export const SET_SELECTED_ENHANCED_BRANCH = 'SET_SELECTED_ENHANCED_BRANCH';
export const SET_DISABLE_VIEW_BRANCH_WARNING =
  'SET_DISABLE_VIEW_BRANCH_WARNING';

// Broker
export const SET_BRANCH_SELECTOR_MODAL_IS_OPEN =
  'SET_BRANCH_SELECTOR_MODAL_IS_OPEN';
export const SET_BRANCH_SELECTOR_SEARCH_TERM =
  'SET_BRANCH_SELECTOR_SEARCH_TERM';
export const SET_SHOW_BRANCH_SELECTION_PAGE = 'SET_SHOW_BRANCH_SELECTION_PAGE';
