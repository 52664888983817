import { Box, Flex, Text, NovunaHeading } from 'compass-design';
import {
  ApplicantDetailsState,
  SchemaSectionVars
} from 'components/applicantDetails/ApplicantDetails';
import React from 'react';

export type NextStepsProps = Pick<
  ApplicantDetailsState,
  'activeSectionKey' | 'schemaSections'
> & {
  getSchemaSectionVars: (sectionKey: string) => SchemaSectionVars;
};

const NextSteps: React.FC<NextStepsProps> = ({
  activeSectionKey,
  schemaSections,
  getSchemaSectionVars
}) => {
  const getFollowingSections = (sectionKey: string) => {
    const index = schemaSections.indexOf(sectionKey);
    if (index < 0) {
      return [];
    }

    return schemaSections
      .slice(index + 1)
      .map(key => getSchemaSectionVars(key))
      .filter(
        ({ sectionEditable, sectionUiSchema }) =>
          sectionUiSchema['ui:hidden'] !== true && sectionEditable
      );
  };

  if (!activeSectionKey) {
    return null;
  }

  const followingSections = getFollowingSections(activeSectionKey);
  if (followingSections.length === 0) {
    return null;
  }
  const nextSection = followingSections[0];

  return (
    <Box mt={6}>
      <Text sx={{ fontSize: '0.875rem' }}>Next</Text>
      <NovunaHeading as='h3' id='applicant-section-next' mt={1}>
        {nextSection.sectionSchema.title}
      </NovunaHeading>
      <Flex
        mt={1}
        sx={{
          alignItems: 'stretch',
          color: 'monochrome.3',
          '::before': {
            content: '" "',
            display: 'block',
            marginRight: 3,
            width: '4px',
            borderRadius: '4px',
            backgroundColor: 'tints.2'
          }
        }}>
        <div>
          {getFollowingSections(nextSection.sectionKey).map(
            ({ sectionKey, sectionSchema: { title } }) => (
              <div key={sectionKey}>{title}</div>
            )
          )}
        </div>
      </Flex>
    </Box>
  );
};

export default NextSteps;
