import React from 'react';
import { AuthScreen } from 'compass-design';

const Loading = () => (
  <div data-test-id='compass-applicant-app-loading'>
    <AuthScreen loading={true} />
  </div>
);

export default Loading;
