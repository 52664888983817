import { RetailerConfig } from 'hitachi-retail-core/build/api/retailerConfig';
import { RetailerService } from 'services/retailer';

export const retailerFlags = {
  mailorderEnabled: true,
  automaticSettlementEnabled: false,
  earlySettlementFee: false,
  esatNoteEnabled: false,
  processStatus: true,
  isHeadOffice: true,
  tailoringIncreaseTermEnabled: true,
  tailoringIncreaseDepositEnabled: true,
  tailoringReduceLoanEnabled: true,
  tailoringIncreaseLoanEnabled: true,
  tailoringReduceTermEnabled: false,
  nonMandatoryEmailEnabled: false,
  softSearchEnabled: false,
  immediatePayoutEnabled: false,
  tailoringIbcReduceLoanEnabled: false,
  tailoringIbcIncreaseDepositEnabled: false,
  tailoringIbcIncreaseLoanEnabled: true,
  tailoringIbcReduceTermEnabled: true,
  partialSettlementsEnabled: true,
  moSoftSearchEnabled: false,
  pbfSoftSearchEnabled: false
};

const loanDetails = {
  minLoanAmount: '250.00',
  maxLoanAmount: '25000.00',
  minDepositAmount: '50.00',
  minDepositPercentage: '0.00',
  minGoodsAmount: '250.00',
  instalments: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  apr: '0',
  serviceType: 'IF',
  deferralPeriod: []
};

const address = {
  supplierNumber: '99991234',
  phoneNumber: '12312 3123',
  buildingName: 'Building',
  buildingNo: '40',
  streetName: 'Scott Green',
  town: 'Leeds',
  county: 'West Yorkshire',
  postcode: 'LS10 1GA'
};

const defaultRetailer = {
  name: 'retailer one',
  retailerNormalisedName: 'retailerOne',
  goodsClass: [18],
  ...loanDetails,
  ...address,
  ...retailerFlags
};

export const branches = [
  {
    ...defaultRetailer,
    name: 'retailer two',
    retailerNormalisedName: 'retailerTwo',
    supplierNumber: '99991235',
    isHeadOffice: true,
    hasChildBranches: true
  },
  {
    ...defaultRetailer,
    name: 'retailer three',
    retailerNormalisedName: 'retailerThree',
    supplierNumber: '99991236',
    isHeadOffice: false,
    hasChildBranches: false
  },
  {
    ...defaultRetailer,
    name: 'retailer four',
    retailerNormalisedName: 'retailerFour',
    supplierNumber: '99991237',
    isHeadOffice: false,
    hasChildBranches: false
  },
  {
    ...defaultRetailer,
    name: 'retailer five',
    retailerNormalisedName: 'retailerFive',
    supplierNumber: '99991238',
    isHeadOffice: true,
    hasChildBranches: false
  }
];

const getMockRetailerService = (): RetailerService => ({
  getRetailer: async () => {
    console.log('Mock invoked: getMockRetailerService getRetailer');
    return {
      name: 'Mail Order Retailer',
      retailerNormalisedName: 'mailorderretailer',
      hasChildBranches: true,
      goodsClass: [18],
      ...loanDetails,
      ...address,
      ...retailerFlags
    };
  },
  getRetailerByApplicationId: async (id: string) => {
    console.log(
      'Mock invoked: getMockRetailerService getRetailerByApplicationId'
    );
    return {
      name: 'Mail Order Retailer',
      hasChildBranches: true,
      goodsClass: [18],
      ...loanDetails,
      ...address,
      ...retailerFlags
    };
  },
  getAllBranches: async () => {
    console.log('Mock invoked: getMockRetailerService getAllBranches');
    const hasBranches = window.sessionStorage.getItem('hasBranches');
    return hasBranches === 'true' ? branches : [];
  },
  getBranchesForProposing: async () => getCannedBranchesForProposing()
});

export const getCannedBranchesForProposing = () => [
  getCannedRetailer({
    supplierNumber: '03751290',
    name: 'Cheriton Dental Practice'
  }),
  getCannedRetailer({
    supplierNumber: '03751322',
    name: 'Camberwell Dental Practice'
  }),
  getCannedRetailer({
    supplierNumber: '03751351',
    name: 'Mr Christopher Javier Navarro'
  }),
  getCannedRetailer({
    supplierNumber: '03751367',
    name: '57 FG Limited'
  }),
  getCannedRetailer({
    supplierNumber: '03751377',
    name: 'Smilepod Ltd'
  }),
  getCannedRetailer({
    supplierNumber: '03751421',
    name: 'Oakmead Dental Care'
  }),
  getCannedRetailer({
    supplierNumber: '03751427',
    name: 'Buckden Dental Clinic'
  }),
  getCannedRetailer({
    supplierNumber: '03751465',
    name: 'Maria White Dental Practice'
  }),
  getCannedRetailer({
    supplierNumber: '03751476',
    name: 'Dental Solutions'
  }),
  getCannedRetailer({
    supplierNumber: '03751482',
    name: 'Smiles & More'
  }),
  getCannedRetailer({
    supplierNumber: '03751492',
    name: 'Atlantic Clinic Ltd'
  }),
  getCannedRetailer({
    supplierNumber: '03751494',
    name: 'Cudworth Dental Surgery'
  }),
  getCannedRetailer({
    supplierNumber: '03751497',
    name: 'Burgess Hill Dental Care Limited'
  }),
  getCannedRetailer({
    supplierNumber: '03751498',
    name: 'Surrey Docks Dental Practice'
  }),
  getCannedRetailer({
    supplierNumber: '03751519',
    name: 'Complete Care Dental Clinic'
  }),
  getCannedRetailer({
    supplierNumber: '03751537',
    name: 'R A Raeburn Dental Surgery'
  }),
  getCannedRetailer({
    supplierNumber: '03751542',
    name: 'Thornhill Dental Practice'
  }),
  getCannedRetailer({
    supplierNumber: '03751548',
    name: 'Dove Dental & Wellbeing Spa'
  }),
  getCannedRetailer({
    supplierNumber: '03751549',
    name: 'Beynons Dental Surgery'
  }),
  getCannedRetailer({
    supplierNumber: '03751550',
    name: 'Rosslynn House Dental Practice'
  }),
  getCannedRetailer({
    supplierNumber: '03751552',
    name: 'Ferry Dental Centre'
  })
];

export const getCannedRetailer = (overrides: any = {}): RetailerConfig => ({
  name: 'A Branch',
  supplierNumber: '12345678',
  goodsClass: [18],
  minLoanAmount: '250.00',
  maxLoanAmount: '25000.00',
  minDepositAmount: '0.00',
  minDepositPercentage: '0',
  minGoodsAmount: '250.00',
  instalments: [48],
  phoneNumber: '01452 526028',
  buildingName: 'null',
  buildingNo: '16',
  streetName: 'Union Road',
  town: 'Crediton',
  county: 'Devon',
  postcode: 'EX17 3AW',
  apr: '9.9',
  serviceType: 'BNPL',
  deferralPeriod: [12],
  mailorderEnabled: false,
  automaticSettlementEnabled: false,
  nonMandatoryEmailEnabled: false,
  earlySettlementFee: true,
  esatNoteEnabled: false,
  processStatus: true,
  isHeadOffice: true,
  tailoringIncreaseTermEnabled: false,
  tailoringIncreaseDepositEnabled: false,
  tailoringIncreaseLoanEnabled: false,
  subChannelMailOrder: true,
  subChannelOnTrade: true,
  subChannelPbf: true,
  softSearchEnabled: false,
  indicativeSoftSearchEnabled: false,
  onlineDepositsEnabled: true,
  ...overrides
});

export default getMockRetailerService;
