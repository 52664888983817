import {
  Box,
  Button,
  NovunaHeading,
  SoftSearchCard,
  Text
} from 'compass-design';
import { Decimal } from 'hitachi-retail-core/build/utils/decimal';
import { getHighestAcceptedLoanAmount } from 'hitachi-retail-core/build/utils/applicant';
import { ProductConfig } from 'hitachi-retail-core/build/api/productConfig';
import {
  ApplicationModificationStatus,
  getConditionalModifiableFieldsForRetailJourney
} from 'hitachi-retail-core/build/application/modification';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import {
  CompassFeature,
  FeatureConfig
} from 'hitachi-retail-core/build/features/features';
import {
  createQuoteConfig,
  createQuoteValidationContext,
  getQuoteSchema,
  IncompleteQuote
} from 'hitachi-retail-core/build/schemas/quote';
import { JSONSchema6 } from 'json-schema';
import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  ChangeEvent,
  FormSubmit,
  FormValidation,
  UiSchema
} from 'react-jsonschema-form';
import { useSelector } from 'store';
import { selectInterestFreeCreditUnregulatedEndDate } from 'store/config/selectors';
import {
  selectFetchingProducts,
  selectProductsFetched
} from 'store/product/selectors';
import {
  selectProposingOnBehalfOf,
  selectRetailerLoaded,
  selectMailOrderSoftSearchEnabled,
  selectFace2FaceSoftSearchEnabled
} from 'store/retailer/selectors';
import EndAndSend from '../endAndSend';
import { schemaValidate, validateFormDataHelper } from '../form/helpers';
import {
  getSelectedProductOption,
  ProductOption,
  quoteSchema
} from '../form/quote';
import { PreviousApplicationDetail } from '../store/previousApplicationDetail/actions';
import ApplyFromHome from './ApplyFromHome';
import ButtonGroup from './common/ButtonGroup';
import CompassForm from './form/CompassForm';
import { ApplicationDetail, JsonFormData } from './form/types';

export interface QuotePropsFromState {
  application: ApplicationDetail;
  availableInstalments: number[];
  mailOrderEnabled: boolean;
  productConfig?: ProductConfig;
  expiredProductConfig: boolean;
  productOptions?: ProductOption[];
  mustReselectProduct: boolean;
  enabledFeatures: FeatureConfig;
  applicationDetailsFromHistory?: PreviousApplicationDetail;
  maxInstalmentAmount?: string | null;
  applicationExpiryDate?: string | null;
}

export interface QuotePropsFromDispatch {
  onChange: (
    formData: JsonFormData,
    productOptions: ProductOption[],
    interestFreeCreditUnregulatedEndDate?: Date
  ) => void;
  getProductsForRetailer: (supplierNumber?: string) => void;
  onSelectProductOption: (product: ProductOption) => void;
  onSubmit: (formData: JsonFormData) => void;
  clearSelectedProduct: () => void;
  resetFetchedGoodsList: () => void;
}

type FormError = { stack: string };

export const loanAmendApplicationStatuses = [
  ApplicationStatus.signed,
  ApplicationStatus.decision_accept
];

export type QuoteProps = QuotePropsFromState & QuotePropsFromDispatch;

export type QuoteFormContext = Pick<
  QuoteProps,
  | 'application'
  | 'availableInstalments'
  | 'productOptions'
  | 'mustReselectProduct'
  | 'onSelectProductOption'
  | 'applicationDetailsFromHistory'
>;

const Quote: React.FunctionComponent<QuoteProps> = ({
  application,
  productConfig,
  expiredProductConfig,
  productOptions,
  mustReselectProduct,
  availableInstalments,
  mailOrderEnabled,
  enabledFeatures,
  applicationDetailsFromHistory,
  maxInstalmentAmount,
  getProductsForRetailer,
  onChange,
  onSelectProductOption,
  onSubmit,
  clearSelectedProduct,
  resetFetchedGoodsList
}) => {
  let isLoanAmendAfterSigning = false;
  const formData = application.document;
  const applicationStatus = application.status;
  const formContext: QuoteFormContext = {
    application,
    availableInstalments,
    productOptions,
    mustReselectProduct,
    onSelectProductOption
  };

  const proposingOnBehalfOf = useSelector(selectProposingOnBehalfOf);
  const retailerLoaded = useSelector(selectRetailerLoaded);
  const fetchingProducts = useSelector(selectFetchingProducts);
  const productsFetched = useSelector(selectProductsFetched);
  const interestFreeCreditUnregulatedEndDate = useSelector(
    selectInterestFreeCreditUnregulatedEndDate
  );
  const isMoSoftSearchEnabled = useSelector(selectMailOrderSoftSearchEnabled);
  const isFace2FaceSoftSearchEnabled = useSelector(
    selectFace2FaceSoftSearchEnabled
  );

  useEffect(() => {
    return () => {
      resetFetchedGoodsList();
    };
  }, [resetFetchedGoodsList]);

  useEffect(() => {
    if (retailerLoaded && !fetchingProducts && !productsFetched) {
      getProductsForRetailer(proposingOnBehalfOf?.supplierNumber);
    }
  }, [
    retailerLoaded,
    proposingOnBehalfOf,
    fetchingProducts,
    productsFetched,
    getProductsForRetailer
  ]);

  // Auto select single product option
  useEffect(() => {
    if (productOptions?.length === 1) {
      const onlyOption = productOptions[0];
      if (!onlyOption.selected && onlyOption.selectable) {
        onSelectProductOption(onlyOption);
      }
    }
  }, [productOptions, onSelectProductOption]);

  useEffect(() => {
    if (applicationStatus === ApplicationStatus.open && expiredProductConfig) {
      clearSelectedProduct();
    }
  }, [applicationStatus, expiredProductConfig, clearSelectedProduct]);

  const correctLoanAmendStatus = loanAmendApplicationStatuses.includes(
    applicationStatus
  );

  if (correctLoanAmendStatus && !formData.isNonBrokered) {
    isLoanAmendAfterSigning = true;
  }

  if (
    applicationDetailsFromHistory &&
    applicationDetailsFromHistory.revertibleVersion !== undefined
  ) {
    isLoanAmendAfterSigning = true;
  }

  const previousLoanAmount = isLoanAmendAfterSigning
    ? getHighestAcceptedLoanAmount(applicationDetailsFromHistory?.revisions)
    : undefined;

  const hasValidQuote = useMemo(() => {
    const quoteConfig = createQuoteConfig({
      values: formData,
      productConfig,
      isLoanAmendAfterSigning,
      previousLoanAmount
    });
    const quoteSchema = getQuoteSchema(quoteConfig);
    return schemaValidate(
      formData as IncompleteQuote,
      quoteSchema,
      createQuoteValidationContext(formData as IncompleteQuote)
    );
  }, [formData, productConfig, isLoanAmendAfterSigning, previousLoanAmount]);

  const modifiableFields = getConditionalModifiableFieldsForRetailJourney(
    applicationStatus
  );

  const onError = (errors: FormError[]) => {
    const errorExists = errors?.length > 0;
    if (!errorExists) {
      return;
    }
    // There is no elegant way of grabbing the DOM elements using React Refs, therefore document object is used
    const goodsDescriptionLabel =
      document.getElementById('goodsDescription_label') ||
      document.getElementById('quote_goodsDescription_goodsDescription_label');
    const goodsAmountLabel = document.getElementById(
      'quote_loanParameters_goodsAmount_label'
    );
    const depositLabel = document.getElementById(
      'quote_loanParameters_deposit_label'
    );
    const productLabel = document.getElementById('quote_product_label');
    const instalmentsLabel = document.getElementById(
      'quote_loanRepayment_instalments_label'
    );

    let elementToScrollTo = null;
    switch (errors[0].stack) {
      case 'goodsDescription: This is a required field': {
        elementToScrollTo = goodsDescriptionLabel;
        break;
      }
      case 'goodsAmount: Goods amount should be no greater than the maximum goods amount of £999,999.99':
      case 'goodsAmount: This is a required field': {
        elementToScrollTo = goodsAmountLabel;
        break;
      }
      case 'deposit: This is a required field':
      case 'deposit: Deposit should be no greater than the maximum deposit of £999,999.99':
      case 'deposit: Deposit must be less than the cost of the item': {
        elementToScrollTo = depositLabel;
        break;
      }
      case 'serviceType: This is a required field':
      case 'apr: This is a required field': {
        elementToScrollTo = productLabel;
        break;
      }
      case 'instalments: This is a required field': {
        elementToScrollTo = instalmentsLabel;
        break;
      }
      default:
        break;
    }
    elementToScrollTo?.scrollIntoView({ behavior: 'smooth' });
  };

  const isMailOrderAvailable =
    mailOrderEnabled &&
    enabledFeatures.has(CompassFeature.MAIL_ORDER_SAVE_QUOTE);

  const isMailOrderSoftSearchAvailable =
    isMoSoftSearchEnabled &&
    enabledFeatures.has(CompassFeature.MO_PBF_SOFT_SEARCH);

  const shouldRenderMoButtonWithoutMessage =
    isMailOrderAvailable && isMailOrderSoftSearchAvailable;

  const shouldRenderMoButtonWithMessage =
    isMailOrderAvailable && !isMailOrderSoftSearchAvailable;

  const ButtonWithMessage = (
    <ApplyFromHome
      mailOrderEnabled={mailOrderEnabled}
      disabled={!hasValidQuote}
      id='quote-sendtocustomer-button'
    />
  );

  const ButtonWithoutMessage = (
    <EndAndSend
      enabledBy={CompassFeature.MAIL_ORDER_SAVE_QUOTE}
      disabled={!hasValidQuote}
      mailOrderEnabled={mailOrderEnabled}
      id='quote-sendtocustomer-softsearch-button'
    />
  );

  return (
    <>
      <Helmet>
        <title>Quote - CreditMaster3</title>
      </Helmet>
      <NovunaHeading as='h1' id='quote-heading' mb={3}>
        Quote
      </NovunaHeading>
      {isFace2FaceSoftSearchEnabled && (
        <Box mb={5}>
          <SoftSearchCard text='The application will be checked for approval without leaving a footprint on the customer&rsquo;s credit file until they choose to apply in full. Soft search check expires after 30 days.' />
        </Box>
      )}
      {productOptions && productOptions.length > 0 ? (
        <>
          <CompassForm
            idPrefix='quote'
            schema={
              quoteSchema(
                getSelectedProductOption(productOptions)?.deferralPeriod
              ) as JSONSchema6
            }
            formData={formData}
            uiSchema={getUiSchema({
              editableFields: modifiableFields,
              applicationModificationStatus: application.modificationStatus
            })}
            validate={getFormValidate({
              productConfig,
              isLoanAmendAfterSigning,
              previousLoanAmount,
              maxInstalmentAmount
            })}
            formContext={formContext}
            onChange={({ formData }: ChangeEvent<JsonFormData>) =>
              onChange(
                formData,
                productOptions,
                interestFreeCreditUnregulatedEndDate
              )
            }
            onSubmit={({ formData }: FormSubmit<JsonFormData>) =>
              onSubmit(formData)
            }
            onError={onError}>
            <ButtonGroup mt={5}>
              <Button
                id='quote-submit-button'
                data-test-id='compass-quote-form-submit'
                isJumbo>
                Continue
              </Button>
              {shouldRenderMoButtonWithoutMessage && ButtonWithoutMessage}
              {/**
               * Some retailers may not be aware that Novuna offers Soft Search.
               * Therefore if F2F Soft Search is disabled then the warning message for MO should NOT be shown, thus falling back to the regular button
               * Hence the second condition is added. (FX-542).
               */}
              {shouldRenderMoButtonWithMessage &&
                !isFace2FaceSoftSearchEnabled &&
                ButtonWithoutMessage}
            </ButtonGroup>
            {/**
             * Button group addds margin to the buttons, thus the button with message is rendered outside ButtonGroup
             * If the ButtonWithMessage is rendered inside the <ButtonGroup /> it'll cause misalignment issues.
             */}
            {shouldRenderMoButtonWithMessage &&
              isFace2FaceSoftSearchEnabled &&
              ButtonWithMessage}
          </CompassForm>
        </>
      ) : (
        <>
          {!productsFetched || fetchingProducts ? (
            <Text my={4} sx={{ fontSize: 2 }}>
              Loading products&hellip;
            </Text>
          ) : (
            <>
              <Text mt={4} sx={{ fontSize: 2 }}>
                We were unable to find any products for the quotation.
              </Text>
              <Text mt={2} mb={4} sx={{ fontSize: 2 }}>
                Please contact the Retail Helpline on 0344 375 5515.
              </Text>
            </>
          )}
        </>
      )}
    </>
  );
};

interface GetUISchemaParams {
  editableFields: string[];
  applicationModificationStatus: ApplicationModificationStatus;
}

export const getUiSchema = ({
  editableFields,
  applicationModificationStatus
}: GetUISchemaParams): UiSchema => {
  const allEditable =
    applicationModificationStatus === ApplicationModificationStatus.YES;
  let goodsDescriptionEditable = allEditable;
  let orderReferenceEditable = allEditable;
  let loanParametersEditable = allEditable;
  let loanRepaymentEditable = allEditable;
  let productEditable = allEditable;

  if (
    applicationModificationStatus === ApplicationModificationStatus.CONDITIONAL
  ) {
    goodsDescriptionEditable = editableFields.includes('goodsDescription');
    orderReferenceEditable = editableFields.includes('orderReference');
    loanParametersEditable = editableFields.includes('loanParameters');
    loanRepaymentEditable = editableFields.includes('loanRepayment');
    productEditable = editableFields.includes('product');
  }
  const goodsDescriptionAutoFocus = goodsDescriptionEditable;

  return {
    goodsDescription: {
      'ui:autofocus': goodsDescriptionAutoFocus,
      'ui:disabled': !goodsDescriptionEditable,
      'ui:field': 'GoodsDescription'
    },
    orderReference: {
      'ui:disabled': !orderReferenceEditable,
      'ui:shortLength': true
    },
    loanParameters: {
      'ui:autofocus': !goodsDescriptionAutoFocus,
      'ui:disabled': !loanParametersEditable,
      'ui:field': 'LoanParameters'
    },
    product: {
      'ui:field': 'FinanceProduct',
      'ui:disabled': !productEditable
    },
    loanRepayment: {
      'ui:disabled': !loanRepaymentEditable,
      'ui:field': 'LoanRepayment'
    }
  };
};

export const getFormValidate = ({
  productConfig,
  isLoanAmendAfterSigning,
  previousLoanAmount,
  maxInstalmentAmount
}: {
  productConfig?: ProductConfig;
  isLoanAmendAfterSigning: boolean;
  previousLoanAmount?: Decimal | null;
  maxInstalmentAmount?: string | null;
}) => (values: IncompleteQuote, errors: FormValidation): FormValidation => {
  const newErrors = { ...errors };
  const quoteConfig = createQuoteConfig({
    values,
    productConfig,
    isLoanAmendAfterSigning,
    previousLoanAmount
  });

  validateFormDataHelper(
    values,
    newErrors,
    getQuoteSchema(quoteConfig),
    createQuoteValidationContext(values)
  );

  return newErrors;
};

export default Quote;
