import { Box } from 'compass-design';
import React from 'react';
import { BoxProps } from 'theme-ui';

const ButtonGroup = React.forwardRef<
  HTMLDivElement,
  React.PropsWithoutRef<BoxProps>
>(({ children, ...rest }, ref) => {
  const buttons = React.Children.toArray(children);
  return (
    <Box {...rest} ref={ref}>
      {buttons[0]}
      {buttons.slice(1).map((button, index) => (
        <Box
          key={index}
          sx={{
            display: ['block', 'inline-block'],
            mt: [4, 0],
            ml: [null, 4]
          }}>
          {button}
        </Box>
      ))}
    </Box>
  );
});

ButtonGroup.displayName = 'ButtonGroup';

export default ButtonGroup;
