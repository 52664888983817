import React from 'react';
import {
  Box,
  NovunaCM3LoginDesktopLogo,
  NovunaCM3StackedLogo,
  NovunaCM3PowersHighStreetLogo,
  NovunaCM3TomorrowTogetherLogo,
  Flex
} from 'compass-design';

export const LoggedOutWrapper: React.FC<{
  backLink?: React.ReactNode;
}> = ({ children, backLink }) => {
  const Section = (
    <WhiteLoggedOutContainer backLink={backLink}>
      {children}
    </WhiteLoggedOutContainer>
  );

  return (
    <>
      <Box
        sx={{
          height: '100vh',
          display: ['block', null, null, 'grid'],
          gridTemplateColumns: '1fr 1fr'
        }}>
        <Flex
          my={5}
          mx={5}
          sx={{
            display: [null, null, null, 'none'],
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <Box
            sx={{
              display: [null, null, null, 'none'],
              marginX: 'auto',
              width: '100%',
              maxWidth: '470px'
            }}>
            <NovunaCM3StackedLogo />
          </Box>
        </Flex>
        <Box my={[0, null, null, 'auto']}>{Section}</Box>
        <Box
          sx={{
            display: ['none', null, null, 'block'],
            backgroundColor: 'primaryPurple'
          }}>
          <Box
            py='60px'
            ml={[6, null, null, null, '128px']}
            sx={{
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}>
            <Box>
              <NovunaCM3LoginDesktopLogo />
            </Box>
            <Box>
              <NovunaCM3PowersHighStreetLogo />
            </Box>
            <Box ml='auto' mr='60px'>
              <NovunaCM3TomorrowTogetherLogo />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const WhiteLoggedOutContainer: React.FC<{
  backLink?: React.ReactNode;
}> = ({ children, backLink }) => (
  <Box mx={[2, 'auto']} sx={{ maxWidth: '483px' }}>
    <Box>{backLink}</Box>
    <Box
      my={backLink ? 3 : 2}
      p={[3, null, null, 6]}
      sx={{ backgroundColor: 'white' }}>
      {children}
    </Box>
  </Box>
);
