import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';

interface ContextualPageDataProps {
  retailer?: Retailer | null;
  username?: string | null;
  subdomain?: string | null;
}

const getContextualPageData = ({
  retailer,
  username,
  subdomain
}: ContextualPageDataProps): string[] => {
  return [
    retailer?.name || null,
    retailer?.retailerNormalisedName || null,
    username || null,
    subdomain || null,
    'novuna',
    'compass',
    'retail',
    'creditmaster',
    'consumer',
    'finance',
    'payments',
    'system',
    'loan'
  ].filter(input => !!input) as string[];
};

export { getContextualPageData };
