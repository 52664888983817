import { PbfRadioOption } from 'compass-design';
import { TailoringOption } from 'compass-design/lib/components/TailoringOptions/TailoringOptions';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import {
  CompassFeature,
  FeatureConfig
} from 'hitachi-retail-core/build/features/features';
import { IncompleteFinanceApplication } from 'hitachi-retail-core/build/schemas/financeApplication';
import { AgreementType } from 'hitachi-retail-core/build/schemas/financeProduct';
import { TailoringOptionSelected } from 'hitachi-retail-core/build/services/tailoring/types';
import isNumber from '../../../../utils/isNumber';
import { LoanDetails } from '../getLoanDetails';
import getPbfRadioOptions from '../getPbfRadioOptions';
import getRadioOptions, { RadioOption } from '../getRadioOptions';

export interface GetAffordabilityDecline {
  decisionApplicationStatus?: ApplicationStatus;
  maxInstalmentAmount?: string | null;
  enabledFeatures: FeatureConfig;
  loanDetails?: LoanDetails;
  agreementType?: AgreementType;
}

export interface AffordabilityDeclineProps {
  maxInstalmentAmount: string;
  loanDetails: LoanDetails;
  onConfirmTailoringUpdate: ({
    document,
    tailoringOptionSelected
  }: {
    document: IncompleteFinanceApplication;
    tailoringOptionSelected: TailoringOptionSelected;
  }) => void;
  preSetTailoringOption: TailoringOption;
  radioOptions?: RadioOption[] | PbfRadioOption[];
}

export default ({
  decisionApplicationStatus,
  maxInstalmentAmount,
  enabledFeatures,
  loanDetails,
  agreementType
}: GetAffordabilityDecline):
  | Pick<
      AffordabilityDeclineProps,
      | 'loanDetails'
      | 'maxInstalmentAmount'
      | 'preSetTailoringOption'
      | 'radioOptions'
    >
  | undefined => {
  if (
    !isNumber(maxInstalmentAmount) ||
    !(
      decisionApplicationStatus === ApplicationStatus.decision_decline ||
      decisionApplicationStatus === ApplicationStatus.decision_quotation_decline
    ) ||
    !enabledFeatures.has(
      CompassFeature.TAILORING_AFFORDABILITY_DECLINE_FEATURE
    ) ||
    !loanDetails
  ) {
    return;
  }

  const tailoringOptions = [];

  if (loanDetails.increaseTermOffer)
    tailoringOptions.push('term' as TailoringOption);
  if (loanDetails.increaseDepositOffer)
    tailoringOptions.push('deposit' as TailoringOption);
  if (loanDetails.reduceLoanOffer)
    tailoringOptions.push('reduceLoan' as TailoringOption);

  const radioOptions =
    agreementType === 'PBF'
      ? getPbfRadioOptions({ loanDetails })
      : getRadioOptions({ loanDetails });

  if (radioOptions.length === 0) {
    return;
  }

  return {
    loanDetails: loanDetails!,
    maxInstalmentAmount: maxInstalmentAmount!,
    preSetTailoringOption: tailoringOptions[0] as TailoringOption,
    radioOptions
  };
};
