import React, { useEffect, ReactElement } from 'react';
import { AsyncStatus } from '../../applicantApp/store/AsyncStatus';

export interface ConfigPropsFromState {
  configStatus: AsyncStatus;
  children?: ReactElement;
}

export interface ConfigPropsFromDispatch {
  fetchConfig: () => void;
}

export type ConfigProps = ConfigPropsFromState & ConfigPropsFromDispatch;

const Config: React.FC<ConfigProps> = ({
  fetchConfig,
  configStatus,
  children
}) => {
  useEffect(() => {
    fetchConfig();
  }, [fetchConfig]);

  if (
    configStatus === AsyncStatus.Default ||
    configStatus === AsyncStatus.Loading
  ) {
    // TODO: This should be universal spinner for every application and team
    return <div data-test-id='compass-applicant-app-loading'></div>;
  }

  return children || null;
};

export default Config;
