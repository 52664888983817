import { DocumentDownloadService } from 'applicantApp/services/documentDownloadService';
import sleep from './sleep';

const getMockDocumentDownloadService = (): DocumentDownloadService => ({
  fetchPrecontractDownloadUrl: async (...args: any[]) => {
    console.log(
      'Mock invoked: fetchPrecontractDownloadUrl with args:',
      ...args
    );
    // Wait so we can see the loading state
    await sleep(3000);
    return { url: 'https://cataas.com/cat/cool/says/success!' };
    // throw new Error(`that didn't work`);
  },
  fetchDraftDownloadUrl: async (...args: any[]) => {
    console.log('Mock invoked: fetchDraftDownloadUrl with args:', ...args);
    // Wait so we can see the loading state
    await sleep(3000);
    return { url: 'https://cataas.com/cat/hot/says/success!' };
    // throw new Error(`that didn't work`);
  }
});

export default getMockDocumentDownloadService;
