export const CREATE_NEW_APPLICATION = 'CREATE_NEW_APPLICATION';

export const UPDATE_ACTIVE_APPLICATION = 'UPDATE_ACTIVE_APPLICATION';

export const RESUME_APPLICATION = 'RESUME_APPLICATION';

export const AMEND_APPLICATION = 'AMEND_APPLICATION';

export const CLEAR_SELECTED_PRODUCT = 'CLEAR_SELECTED_PRODUCT';

export const SELECT_APPLICATION_PRODUCT_OPTION =
  'SELECT_APPLICATION_PRODUCT_OPTION';

export const SELECT_APPLICATION_PRODUCT_CONFIG =
  'SELECT_APPLICATION_PRODUCT_CONFIG';

export const SET_DOCUMENT_DOWNLOAD_TIMESTAMP =
  'SET_DOCUMENT_DOWNLOAD_TIMESTAMP';

export const BANK_ACCOUNT_CHECK_REQUEST = 'BANK_ACCOUNT_CHECK_REQUEST';
export const BANK_ACCOUNT_CHECK_SUCCESS = 'BANK_ACCOUNT_CHECK_SUCCESS';
export const BANK_ACCOUNT_CHECK_FAILURE = 'BANK_ACCOUNT_CHECK_FAILURE';
export const CLEAR_BANK_ACCOUNT_DETAILS = 'CLEAR_BANK_ACCOUNT_DETAILS';
