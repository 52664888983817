import React from 'react';
import DecisionAffordabilityDecline from './DecisionAffordabilityDecline';
import { AffordabilityDeclineProps } from 'retailerApp/utils/loanDetails/getAffordabilityDecline';
import DecisionDecline from './DecisionDecline';
import { DownloadLetterProps } from './DownloadLetter/DownloadLetter';
import { DecisionAffordabilityDeclineWithoutEditLoan } from './DecisionAffordabilityDecline/DecisionAffordabilityDecline';
import { useSelector } from 'react-redux';
import { selectDeferralPeriod } from 'store/application/selectors';

interface DecisionDeclinePickerProps {
  isSoftSearch?: boolean;
  affordabilityDecline?: AffordabilityDeclineProps;
  downloadLetter: DownloadLetterProps;
  softSearchExpiry?: string;
  isLoanAmend: boolean;
}

const DecisionDeclinePicker = ({
  isSoftSearch,
  affordabilityDecline,
  downloadLetter,
  softSearchExpiry,
  isLoanAmend
}: DecisionDeclinePickerProps) => {
  const deferralPeriod = useSelector(selectDeferralPeriod);
  if (affordabilityDecline && !isLoanAmend) {
    if (affordabilityDecline.loanDetails.reduceLoanOffer) {
      return (
        <DecisionAffordabilityDecline
          isSoftSearch={!!isSoftSearch}
          affordabilityDecline={affordabilityDecline!}
          loanDetails={affordabilityDecline.loanDetails}
          downloadLetter={downloadLetter}
          softSearchExpiry={softSearchExpiry}
          editLoanProps={affordabilityDecline.loanDetails.reduceLoanOffer}
          deferralPeriod={deferralPeriod}
        />
      );
    }
    return (
      <DecisionAffordabilityDeclineWithoutEditLoan
        isSoftSearch={!!isSoftSearch}
        affordabilityDecline={affordabilityDecline!}
        downloadLetter={downloadLetter}
        softSearchExpiry={softSearchExpiry}
      />
    );
  }

  return (
    <DecisionDecline
      isSoftSearch={!!isSoftSearch}
      downloadLetter={downloadLetter}
    />
  );
};

export default DecisionDeclinePicker;
