import { Tone } from 'compass-design';
import { FieldProps } from 'formik';

type FieldMeta = Pick<FieldProps<string, any>, 'meta'>;
type MetaType = 'label' | 'input';

const toneFromMeta = (
  { meta }: FieldMeta,
  type: MetaType
): Tone | undefined => {
  if (!meta.touched) {
    return 'neutral';
  }

  if (meta.error) {
    return 'negative';
  }

  // Empty error string supplied for bank check failure
  // Just want the text field to be negative, not the wrapper
  if (meta.error === '' && type === 'input') {
    return 'negative';
  }
  // In this case, also need to set the wrapper to not be positive
  if (meta.error === '') {
    return 'neutral';
  }

  if (type === 'label') {
    return 'positive';
  }

  return;
};

interface LabelPropsFromMeta {
  tone?: Tone;
  error?: string;
}

export const labelPropsFromMeta = (
  fieldMeta: FieldMeta,
  type: MetaType
): LabelPropsFromMeta => {
  return { tone: toneFromMeta(fieldMeta, type) };
};
