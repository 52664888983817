import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import React from 'react';
import { Route, Switch } from 'react-router';
import FeatureToggle from '../../../containers/meta/FeatureToggle';
import ProtectedRoute from '../../../containers/meta/ProtectedRoute';
import { routes } from '../../../routes';
import ConfirmForgotPassword from '../ConfirmForgotPassword/ConfirmForgotPasswordContainer';
import DirectAppLogin from '../DirectAppLogin/DirectAppLoginContainer';
import Login from '../Login/LoginContainer';
import ResetPassword from '../ResetPassword/ResetPasswordContainer';
import SetNewPassword from '../SetNewPassword/SetNewPasswordContainer';
import { useSelector } from 'react-redux';
import { selectEnabledFeatures } from 'store/config/selectors';
import SetNewStrongerPassword from '../SetNewPassword/SetNewStrongerPasswordContainer';
import ConfirmForgotStrongerPassword from '../ConfirmForgotPassword/ConfirmForgotStrongerPasswordContainer';

// TODO Remove ternaries once Stronger password is rolled out.

const LoggedOutApp: React.FC = () => {
  const enabledFeatures = useSelector(selectEnabledFeatures);
  const isStrongerPasswordEnabled = enabledFeatures.has(
    CompassFeature.STRONGER_PASSWORD_POLICY
  );
  return (
    <>
      <Switch>
        <Route
          path={routes.confirmForgotPassword}
          component={
            isStrongerPasswordEnabled
              ? ConfirmForgotStrongerPassword
              : ConfirmForgotPassword
          }
        />
        <Route path={routes.forgotPassword} component={ResetPassword} />
        <Route
          path={routes.setNewPassword}
          component={
            isStrongerPasswordEnabled ? SetNewStrongerPassword : SetNewPassword
          }
        />
        <ProtectedRoute exact path={routes.index} component={Login} />
        <Route exact path={routes.login} component={Login} />
        <FeatureToggle
          feature={CompassFeature.DIRECT_APPS}
          fallbackComponent={<ProtectedRoute />}>
          <Route path={routes.directApps.login} component={DirectAppLogin} />
        </FeatureToggle>
      </Switch>
    </>
  );
};

export default LoggedOutApp;
