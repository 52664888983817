import React from 'react';
import { Text } from 'compass-design';

const SoftReferTimeout = () => (
  <div data-test-id='compass-application-decision-referred'>
    <Text sx={{ fontWeight: 'bold', fontSize: 3 }} mb={4}>
      Trying to complete your check&hellip;
    </Text>
    <Text sx={{ fontSize: 2 }}>
      We are working on your soft search check, but it is taking longer than
      expected.
    </Text>
    <Text sx={{ fontSize: 2 }}>
      Please check the application later for an update, you can find it via the
      search.
    </Text>
  </div>
);

export default SoftReferTimeout;
