import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { applicantAuthorization } from '../../store/applicantAuth/actions';

export const useAuthorization = ({ id }: { id?: string }) => {
  const dispatch = useDispatch();

  return useCallback(() => {
    if (!id) {
      return;
    }

    dispatch(applicantAuthorization.request({ id }));
  }, [dispatch, id]);
};
