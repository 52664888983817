import { RetailEnv } from './envs';

export const getGoogleAnalyticsTrackingId = (
  env: RetailEnv
): string | undefined => {
  switch (env) {
    case RetailEnv.staging:
      return 'GTM-N3BWQBXB';
    case RetailEnv.prod:
      return 'GTM-5QPN7MBV';
    default:
      return undefined;
  }
};
