import { ActionType, createAsyncAction } from 'typesafe-actions';

export const SEND_PRECONTRACT_INFO_REQUEST = 'SEND_PRECONTRACT_INFO_REQUEST';
export const SEND_PRECONTRACT_INFO_SUCCESS = 'SEND_PRECONTRACT_INFO_SUCCESS';
export const SEND_PRECONTRACT_INFO_FAILURE = 'SEND_PRECONTRACT_INFO_FAILURE';

interface SendPrecontractInfoRequestPayload {
  id: string;
}

export const sendPrecontractInfo = createAsyncAction(
  SEND_PRECONTRACT_INFO_REQUEST,
  SEND_PRECONTRACT_INFO_SUCCESS,
  SEND_PRECONTRACT_INFO_FAILURE
)<SendPrecontractInfoRequestPayload, void, Error>();

export type SendPrecontractInfoAction = ActionType<typeof sendPrecontractInfo>;
