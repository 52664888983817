import React, { ReactNode } from 'react';
import { AsyncStatus } from '../../applicantApp/store/AsyncStatus';

export interface FeatureTogglePropsFromState {
  featureIsEnabled: boolean;
  fallbackComponent?: ReactNode;
  configStatus: AsyncStatus;
}

export type FeatureToggleProps = FeatureTogglePropsFromState;

const FeatureToggle: React.FunctionComponent<FeatureToggleProps> = ({
  featureIsEnabled,
  children,
  fallbackComponent,
  configStatus
}) => {
  if ([AsyncStatus.Default, AsyncStatus.Loading].includes(configStatus)) {
    return <>{null}</>;
  }

  if (featureIsEnabled) {
    return <>{children}</>;
  }

  return <>{fallbackComponent}</>;
};

export default FeatureToggle;
