export const addEventsHandler = (
  eventNames: string[],
  listener: EventListener
): void => {
  eventNames.forEach(event => document.addEventListener(event, listener));
};

export const removeEventsHandler = (
  eventNames: string[],
  listener: EventListener
): void => {
  eventNames.forEach(event => document.removeEventListener(event, listener));
};

export const startTimer = (
  milliseconds: number,
  triggered: () => void
): number => {
  return window.setTimeout(triggered, milliseconds);
};

export const cancelTimer = (timerId: number): void => {
  window.clearTimeout(timerId);
};
