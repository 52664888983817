import { Box, InputWrapper, TextField, Button, Search } from 'compass-design';
import { Field, FieldProps, Formik } from 'formik';
import React, { Fragment, useCallback } from 'react';
import { labelPropsFromMeta } from 'utils/labelPropsFromMeta';
import { AddressState } from 'store/address/reducer';
import { getAddresses } from 'store/address/actions';
import { useDispatch } from 'react-redux';
import { mainAddressDetailsSchema } from '../AddressDetails';
import * as yup from 'yup';
import { AddressWrapperProps } from '../AddressWrapper/AddressWrapper';

export type AddressLookupProps = Pick<AddressWrapperProps, 'getFieldMeta'> & {
  onSubmit: (value: string) => void;
  addressIndex: number;
  formNamespace: string;
  lookup?: AddressState;
};

interface PostcodeLookupForm {
  postCodeLookup: string;
}

export const postcodeLookupValidate = async (values: PostcodeLookupForm) => {
  const postCodeLookUpSchema = yup.reach(
    mainAddressDetailsSchema,
    'mainAddressDetails.mainAddress[0].address.postCode'
  );

  try {
    await postCodeLookUpSchema.validate(values.postCodeLookup);
  } catch (err) {
    return {
      postCodeLookup: err.message
    };
  }

  return {};
};

const AddressLookup: React.FC<AddressLookupProps> = props => {
  const {
    addressIndex,
    lookup,
    formNamespace,
    onSubmit: onSubmitFromProps,
    getFieldMeta
  } = props;

  const fieldName = `${formNamespace}.postCode`;
  const postCodeMeta = getFieldMeta<string>(fieldName);

  const dispatch = useDispatch();
  const findByPostcode = useCallback(
    (postcode: string) => {
      onSubmitFromProps(postcode);
      dispatch(
        getAddresses.request({
          postcode,
          meta: { namespace: String(addressIndex) }
        })
      );
    },
    [dispatch, addressIndex, onSubmitFromProps]
  );

  return (
    <Formik
      initialValues={{
        postCodeLookup: ''
      }}
      onSubmit={() => {
        return;
      }}
      validate={postcodeLookupValidate}>
      <Field name='postCodeLookup'>
        {({
          field,
          meta: postCodeLookupMeta
        }: FieldProps<string, PostcodeLookupForm>) => {
          const meta = postCodeLookupMeta.error
            ? postCodeLookupMeta
            : postCodeMeta;

          return (
            <Fragment>
              <Box mt={4}>
                <InputWrapper
                  id='postCodeLookup'
                  label='Postcode'
                  description='UK and BFPO addresses only'
                  error={meta.error}
                  {...labelPropsFromMeta(
                    {
                      meta
                    },
                    'label'
                  )}>
                  {/* Added in an empty span to force it scroll to the validation errors that are not apart of the 
                   parent formikBag */}
                  <span id={fieldName}></span>
                  <TextField
                    {...labelPropsFromMeta(
                      {
                        meta
                      },
                      'input'
                    )}
                    type='text'
                    {...field}
                    id='postCodeLookup'
                    data-test-id='postCode'
                    flexSx={{
                      maxWidth: '17rem'
                    }}
                  />
                </InputWrapper>
              </Box>
              <Box mt={4}>
                <Button
                  data-test-id='find-address-button'
                  variant='secondary'
                  onClick={e => {
                    e.preventDefault();
                    if (postCodeLookupMeta.error || !field.value) {
                      return;
                    }

                    findByPostcode(field.value);
                  }}>
                  {lookup?.processing ? 'Processing' : 'Find Address'}
                  <Search />
                </Button>
              </Box>
            </Fragment>
          );
        }}
      </Field>
    </Formik>
  );
};

export default AddressLookup;
