const formatCognitoUsername = (cognitoUsername: string) => {
  const arrayElements = cognitoUsername.split('/');
  if (arrayElements.length > 1) {
    // Only remove the first element of the array if we have multiple
    arrayElements.shift();
  }

  return arrayElements.join('/');
};

export default formatCognitoUsername;
