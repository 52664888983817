import { DefaultSagaParams } from '../sagas';
import { User } from 'hitachi-retail-core/build/services/users/usersService';
import { UserRole } from 'hitachi-retail-core/build/userPolicy';
import { PgUser } from 'hitachi-retail-core/build/services/pgUsers/pgUsersService';
import { UserApiData } from 'retailerApp/utils/editingUsers';
import qs from 'qs';

interface SearchUsersParams {
  familyName?: string;
  branch?: string;
}

export interface UsersService {
  createUser: (params: UserApiData) => Promise<void>;
  searchUsers: (params: SearchUsersParams) => Promise<User[]>;
  getPgUsers: (branch: string) => Promise<PgUser[]>;
  getUserRoles: (username: string) => Promise<UserRole[]>;
  updateUser: (userApiData: UserApiData) => Promise<void>;
}

export const getUsersService = ({
  apiClient
}: DefaultSagaParams): UsersService => ({
  createUser: async UserApiData => {
    try {
      await apiClient.post('protected/users', {
        user: UserApiData
      });
    } catch ({ response }) {
      if (
        response?.data?.error &&
        response.status >= 400 &&
        response.status < 500
      ) {
        throw Error(`Failed to create the user: ${response.data.error}`);
      }
      throw Error('Failed to create the user. Please try again later.');
    }
  },
  searchUsers: async search => {
    if (!search.familyName || search.familyName.length === 0) {
      throw new Error('Please provide the search string');
    }

    if (!search.branch || search.branch.length === 0) {
      throw new Error('Please provide the branch');
    }

    try {
      const { data } = await apiClient.get('protected/users', {
        params: {
          searchForFamilyName: search.familyName,
          branch: search.branch
        }
      });

      return data;
    } catch ({ response }) {
      throw Error('Failed to search for the user. Please try again later.');
    }
  },
  getPgUsers: async branch => {
    if (!branch || branch.length === 0) {
      throw new Error('Please provide the branch');
    }
    try {
      const { data } = await apiClient.get('protected/enhancedUsers', {
        params: {
          branch
        },
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      });

      return data;
    } catch ({ response }) {
      throw Error('Failed to get users. Please try again later.');
    }
  },
  getUserRoles: async username => {
    if (username.length === 0) {
      throw new Error('Please provide a valid username');
    }

    try {
      const { data } = await apiClient.post('protected/users/roles', {
        username
      });

      return data;
    } catch ({ response }) {
      throw Error('Failed to find the user. Please try again later.');
    }
  },
  updateUser: async UserApiData => {
    try {
      await apiClient.put('protected/users', {
        user: UserApiData
      });
    } catch ({ response }) {
      if (
        response?.data?.error &&
        response.status >= 400 &&
        response.status < 500
      ) {
        throw Error(`Failed to update the user: ${response.data.error}`);
      }
      throw Error('Failed to update the user. Please try again later.');
    }
  }
});
