import React from 'react';
import { Table, TableRow, Text } from 'compass-design';
import { CustomerSatisfactionItem } from '../../../../../store/previousApplicationDetail/actions';

interface CustomerFeedbackProps {
  notes?: CustomerSatisfactionItem[];
}

const CustomerFeedbackTable: React.FC<CustomerFeedbackProps> = props => (
  <Table
    // Empty column for design
    data-test-id='application-summary-customer-feedback-table'
    columnNames={['Note', '', 'Added']}
    emptyTableMessage='No feedback yet'>
    {props.notes &&
      props.notes.length > 0 &&
      props.notes.map((note, idx) => (
        <TableRow key={idx}>
          <Text>{note.message}</Text>
          {/* Empty column for design */}
          <Text />
          <Text>{note.created}</Text>
        </TableRow>
      ))}
  </Table>
);

export default CustomerFeedbackTable;
