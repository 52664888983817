import {
  Button,
  NovunaHeading,
  Box,
  Message,
  ReactModal,
  InputWrapper,
  TextField
} from 'compass-design';
import React from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import * as yup from 'yup';
import { validationMessages } from 'hitachi-retail-core';

export interface Email {
  confirmedEmailAddress: string;
}

export interface EmailConfirmationModalProps {
  modalOpen: boolean;
  toggleModalOpen: (isOpen: boolean) => void;
  onSubmit: (confirmedEmailAddress: string) => void;
  originalEmail?: string;
}

export const EmailConfirmationModal: React.FC<EmailConfirmationModalProps> = ({
  modalOpen,
  toggleModalOpen,
  onSubmit,
  originalEmail = ''
}) => {
  const initialValues: Email = {
    confirmedEmailAddress: ''
  };

  return (
    <ReactModal
      title='Confirm contact email address'
      size='medium'
      isOpen={modalOpen}
      setIsOpen={toggleModalOpen}
      backgroundClick={false}>
      <Message variant='warning'>
        <NovunaHeading as='h3'>
          Please re-enter and confirm the email address with your customer
        </NovunaHeading>
        This is used to send important information regarding the loan to your
        customer so please check that the address is correct.
      </Message>

      <Formik
        initialValues={initialValues}
        onSubmit={({ confirmedEmailAddress }) =>
          onSubmit(confirmedEmailAddress)
        }
        validationSchema={getValidationSchema(originalEmail)}
        validateOnChange={false}>
        <Form noValidate className='email-confirmation-modal'>
          <Box mt={4}>
            <Field name='confirmedEmailAddress'>
              {({ field, form, meta }: FieldProps<string, Email>) => (
                <InputWrapper
                  id='confirmedEmailAddress'
                  label='Email address'
                  error={meta.error}
                  tone={meta.touched && meta.error ? 'negative' : 'neutral'}>
                  <TextField
                    onPaste={e => e.preventDefault()}
                    short
                    {...field}
                    data-test-id='confirmed-email-address-input'
                    id='confirmedEmailAddress'
                    type='text'
                    autoCapitalize='none'
                    tone={meta.touched && meta.error ? 'negative' : 'neutral'}
                    onChange={({
                      target: { value }
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      form.setFieldValue('confirmedEmailAddress', value);
                    }}
                  />
                </InputWrapper>
              )}
            </Field>
          </Box>
          <Button
            type='submit'
            mt={[5, null, 6]}
            isJumbo
            data-test-id='email-confirmation-modal-continue-btn'>
            Continue
          </Button>
        </Form>
      </Formik>
    </ReactModal>
  );
};

const getValidationSchema = (originalEmail: string) => {
  return yup.object<Email>({
    confirmedEmailAddress: yup
      .string()
      .oneOf(
        [originalEmail, null],
        'Email addresses do not match. Please ensure the previously submitted email is correct'
      )
      .max(60, validationMessages.MAX_STRING_LENGTH_EXCEEDED)
      .email(validationMessages.INVALID_EMAIL_ADDRESS)
      .required('Email address must be confirmed')
  });
};
