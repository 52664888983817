import { Box, TertiaryLink, TertiaryLinkDirection } from 'compass-design';
import { Address } from 'hitachi-retail-core';
import React, { Fragment, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store';
import { AddressState } from 'store/address/reducer';
import AddressLookup from '../AddressLookup';
import AddressSelect from '../AddressSelect';
import { AddressWrapperProps } from '../AddressWrapper/AddressWrapper';

type AddressLookupWrapperOwnProps = Pick<
  AddressWrapperProps,
  'setFieldValue' | 'getFieldMeta'
> & {
  addressIndex: number;
  setManualEntry: (value: boolean) => void;
  onInputConfirm: () => void;
  formNamespace: string;
};

export type AddressLookupWrapperProps = AddressLookupWrapperOwnProps & {
  lookup?: AddressState;
};

const AddressLookupWrapper: React.FC<AddressLookupWrapperProps> = props => {
  const {
    addressIndex,
    lookup,
    setManualEntry,
    onInputConfirm,
    setFieldValue,
    formNamespace
  } = props;

  const handleAddressSelected = useCallback(
    (selectedAddress: Address): void => {
      Object.keys(selectedAddress).forEach(fieldName => {
        setFieldValue(
          `${formNamespace}.${fieldName}`,
          selectedAddress[fieldName],
          false
        );
      });
      return onInputConfirm();
    },
    [onInputConfirm, setFieldValue, formNamespace]
  );

  const [postcode, setPostcode] = useState<string>('');

  let component = (
    <AddressLookup {...props} onSubmit={value => setPostcode(value)} />
  );

  if (
    postcode &&
    postcode.length > 0 &&
    lookup?.addressResults &&
    lookup?.addressResults.length > 0
  ) {
    component = (
      <AddressSelect
        {...props}
        postcode={postcode}
        lookup={lookup}
        onChange={handleAddressSelected}
      />
    );
  }

  if (
    (lookup?.addressResults?.length === 0 && lookup?.processing === false) ||
    lookup?.errorMessage
  ) {
    component = (
      <>
        <AddressSelect
          {...props}
          postcode={postcode}
          lookup={lookup}
          onChange={handleAddressSelected}
          pafError='No address found'
        />
      </>
    );
  }
  return (
    <Fragment>
      {component}
      <Box mt={4}>
        <TertiaryLink
          direction={TertiaryLinkDirection.FORWARDS}
          text='Enter address manually'
          dataTestId={`manual-entry-address-button-${addressIndex}`}
          onClick={() => {
            setManualEntry(true);
          }}
        />
      </Box>
    </Fragment>
  );
};

export default connect(
  (
    state: RootState,
    ownProps: AddressLookupWrapperOwnProps
  ): AddressLookupWrapperProps => ({
    lookup: state.address?.[ownProps.addressIndex],
    ...ownProps
  }),
  null
)(AddressLookupWrapper);
