import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { fetchConfig } from '../config/actions';
import { ActionType } from 'typesafe-actions';
import { AsyncStatus } from '../../applicantApp/store/AsyncStatus';
import { FeatureConfig } from 'hitachi-retail-core/build/features/features';

export interface State {
  enabledFeatures: FeatureConfig;
  interestFreeCreditUnregulatedEndDate?: string;
  status: AsyncStatus;
}

const initialState: State = {
  enabledFeatures: new Set(),
  status: AsyncStatus.Default
};

export type FetchConfigAction = ActionType<typeof fetchConfig>;

type Action = FetchConfigAction;

export const reducer: Reducer<State, Action> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchConfig.request):
      return {
        ...initialState,
        status: AsyncStatus.Loading
      };
    case getType(fetchConfig.success): {
      const { features, interestFreeCreditUnregulatedEndDate } = action.payload;
      const enabledFeatures = new Set(features);

      return {
        enabledFeatures,
        interestFreeCreditUnregulatedEndDate,
        status: AsyncStatus.Success
      };
    }
    case getType(fetchConfig.failure): {
      return {
        ...initialState,
        status: AsyncStatus.Failure
      };
    }
    default: {
      return state;
    }
  }
};
