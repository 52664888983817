import { Box, LoanComparisonCard } from 'compass-design';
import React from 'react';
import { EditDepositProps } from 'compass-design';
import {
  convertLoanComparisonCardProps,
  LoanComparisonCardPreStringValues
} from './getMixingDeckData';

export interface NewOfferComparisonCardProps {
  offer: LoanComparisonCardPreStringValues;
  offerText: string;
  optionText?: string;
}

interface MixingDeckProps {
  isSoftSearch?: boolean;
  originalOffer: Omit<LoanComparisonCardPreStringValues, 'updatedItem'>;
  newOffer: NewOfferComparisonCardProps;
  showOfferText: boolean;
  showPlaceholders: boolean;
  buttonOnClick?: () => void;
  buttonOriginalOfferOnClick?: () => void;
  editDeposit?: EditDepositProps;
  depositError?: string;
  isDesktopView?: boolean;
  disabled?: boolean;
  hideApplicationText?: boolean;
}

export const PbfMixingDeck = ({
  isSoftSearch = false,
  originalOffer,
  newOffer,
  showPlaceholders,
  buttonOnClick,
  buttonOriginalOfferOnClick,
  editDeposit,
  disabled,
  hideApplicationText = true
}: MixingDeckProps) => (
  <Box
    sx={{
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: 4,
      maxWidth: '620px',
      backgroundColor: 'white'
    }}>
    <LoanComparisonCard
      data-test-id={'loan-comparison-card-original'}
      {...convertLoanComparisonCardProps({
        ...originalOffer,
        updatedItem: newOffer.offer.updatedItem
      })}
      disabled={!buttonOriginalOfferOnClick}
      updatedItem={newOffer.offer.updatedItem}
      buttonOnClick={buttonOriginalOfferOnClick}
      {...(editDeposit && { editDeposit })}
      isPbfTailoring={true}
      totalAmountPayableUpdated={
        !originalOffer.totalAmountPayable.equals(
          newOffer.offer.totalAmountPayable
        )
      }
      isSoftSearch={isSoftSearch}
      hideApplicationText={hideApplicationText}
    />
    <LoanComparisonCard
      data-test-id={'loan-comparison-card-updated'}
      {...convertLoanComparisonCardProps(newOffer.offer)}
      isUpdatedOffer
      updatedItem={newOffer.offer.updatedItem}
      showPlaceholders={showPlaceholders}
      buttonOnClick={buttonOnClick}
      {...(editDeposit && { editDeposit })}
      isPbfTailoring={true}
      disabled={disabled}
      totalAmountPayableUpdated={
        !originalOffer.totalAmountPayable.equals(
          newOffer.offer.totalAmountPayable
        )
      }
      isSoftSearch={isSoftSearch}
      hideApplicationText={hideApplicationText}
    />
  </Box>
);

const MixingDeck = ({
  originalOffer,
  newOffer,
  showPlaceholders,
  buttonOnClick,
  buttonOriginalOfferOnClick,
  editDeposit,
  depositError,
  disabled
}: MixingDeckProps) => {
  const originalCard = (
    <LoanComparisonCard
      data-test-id='loan-comparison-card-original'
      {...convertLoanComparisonCardProps({
        ...originalOffer,
        updatedItem: newOffer.offer.updatedItem
      })}
      disabled={!buttonOriginalOfferOnClick}
      updatedItem={newOffer.offer.updatedItem}
      buttonOnClick={buttonOriginalOfferOnClick}
      {...(editDeposit && { editDeposit })}
      depositError={depositError}
      totalAmountPayableUpdated={
        !originalOffer.totalAmountPayable.equals(
          newOffer.offer.totalAmountPayable
        )
      }
    />
  );

  const updatedCard = (
    <LoanComparisonCard
      data-test-id='loan-comparison-card-updated'
      {...convertLoanComparisonCardProps({
        ...originalOffer,
        ...newOffer.offer
      })}
      isUpdatedOffer
      updatedItem={newOffer.offer.updatedItem}
      disabled={disabled}
      showPlaceholders={showPlaceholders}
      buttonOnClick={buttonOnClick}
      {...(editDeposit && { editDeposit })}
      depositError={depositError}
      totalAmountPayableUpdated={
        !originalOffer.totalAmountPayable.equals(
          newOffer.offer.totalAmountPayable
        )
      }
    />
  );

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: 4,
        maxWidth: '620px',
        backgroundColor: 'white'
      }}>
      {originalCard}
      {updatedCard}
    </Box>
  );
};

export default MixingDeck;
