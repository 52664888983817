import { Box, Text } from 'compass-design';
import React from 'react';
import { ApplicantContactDetails } from 'hitachi-retail-core/build/schemas/applicantDetails';
import { MarketingConsent } from 'hitachi-retail-core/build/schemas/financeApplication';

interface ReadOnlyContactDetailsInput {
  contactDetails: ApplicantContactDetails;
  marketingConsent: MarketingConsent | undefined;
}

const ReadOnlyContactDetails: React.FC<ReadOnlyContactDetailsInput> = ({
  contactDetails,
  marketingConsent
}) => {
  const { emailAddress, phoneNumber } = contactDetails;

  let marketingOptionText: string | undefined;

  if (marketingConsent?.emailOptIn) {
    marketingOptionText = `${
      marketingConsent?.postOptIn ? 'email & post' : 'email'
    }`;
  } else if (marketingConsent?.postOptIn) {
    marketingOptionText = 'post';
  }

  return (
    <Box>
      <Text
        sx={{
          fontSize: 2,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        }}
        data-test_id='summary-review-page-contact-email'>
        {emailAddress}
      </Text>{' '}
      <Text
        sx={{ fontSize: 2 }}
        data-test_id='summary-review-page-contact-phone'>
        {phoneNumber}
      </Text>
      <br />
      {marketingConsent && (
        <Text
          data-test-id='pbf-summary-marketing-consent-text'
          sx={{ fontSize: 2 }}>
          {marketingOptionText
            ? `Special offers by ${marketingOptionText}`
            : 'Not signed up for special offers'}
        </Text>
      )}
    </Box>
  );
};

export default ReadOnlyContactDetails;
