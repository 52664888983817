import { Flex, Text, NovunaHeading, NovunaSpinner } from 'compass-design';
import React from 'react';
import NovunaPageLayout from 'applicantApp/components/NovunaPageLayout';

const ApplicationStatusFetch: React.FC = () => {
  return (
    <NovunaPageLayout>
      <NovunaHeading as='h1' mb={4}>
        Application decision
      </NovunaHeading>
      <Flex mb={4} sx={{ alignItems: 'center' }}>
        <NovunaSpinner />
        <Text
          ml={3}
          sx={{
            fontSize: 3,
            color: 'secondaryPurple',
            fontWeight: 'heading'
          }}>
          Hold on…
        </Text>
      </Flex>
      <Text mb={4}>
        We are submitting your application for a decision, sometimes this can
        take a few minutes.
      </Text>
      <Text>Please don’t refresh or close the browser.</Text>
    </NovunaPageLayout>
  );
};

export default ApplicationStatusFetch;
