import { push } from 'connected-react-router';
import { LoginResponseCode } from 'hitachi-retail-core/build/enums';
import {
  CompassFeature,
  FeatureConfig
} from 'hitachi-retail-core/build/features/features';
import { UserRole } from 'hitachi-retail-core/build/userPolicy';
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery
} from 'redux-saga/effects';
import { selectEnabledFeatures } from 'store/config/selectors';
import { getType, isOfType } from 'typesafe-actions';
import { routes } from '../../routes';
import { SessionCheckResult, UserService } from '../../services/user';
import { resetStore } from '../../store/actions';
import {
  fetchAllBranches,
  fetchBranchesForProposing,
  fetchRetailer
} from '../../store/retailer/actions';
import {
  directAppUserLogin,
  sessionCheck,
  userLogin,
  userLogout,
  userPolicy,
  userSetPassword
} from '../../store/user/actions';
import {
  UserLoginAction,
  UserSetPasswordAction
} from '../../store/user/reducer';

interface UserSagaFlowParams {
  userService: UserService;
}

export const getLoginFlow = ({ userService }: UserSagaFlowParams) =>
  function*() {
    while (true) {
      const action: UserLoginAction = yield take(getType(userLogin.request));

      if (isOfType(getType(userLogin.request), action)) {
        try {
          const response = yield call(userService.login, action.payload);

          /*
           * New password challenge response is returned after successful first login
           * in this case we need to check the response for challenge and redirect accordingly
           * redirection will be done in the container
           */
          if (
            response?.data?.meta?.code ===
            LoginResponseCode.NewPasswordChallenge
          ) {
            const { familyName, givenName, session, code } = response.data.meta;

            yield put(
              userLogin.success({
                username: action.payload.username,
                retailerName: action.payload.retailerName,
                familyName,
                givenName,
                session,
                code
              })
            );
          } else {
            const enabledFeatures = yield select(selectEnabledFeatures) ||
              new Set();

            yield put(
              userLogin.success({
                username: action.payload.username,
                ...(!enabledFeatures.has(
                  CompassFeature.STRONGER_PASSWORD_POLICY
                )
                  ? {}
                  : {
                      promptPasswordUpdate:
                        response?.data?.promptPasswordUpdate || false
                    })
              })
            );
            yield put(fetchRetailer.request());
            yield put(fetchBranchesForProposing.request());

            if (enabledFeatures.has(CompassFeature.CM3_USER_MANAGEMENT)) {
              yield put(fetchAllBranches.request());
              yield put(userPolicy.request());
            }
          }
        } catch (error) {
          yield put(userLogin.failure(error));
        }
      } else {
        yield put(userLogin.failure(new Error('Login failed')));
      }
    }
  };

export const getLogoutFlow = ({ userService }: UserSagaFlowParams) =>
  function*() {
    while (true) {
      yield take(getType(userLogout.request));

      try {
        yield call(userService.logout);
        // Clear Redux state
        yield put(resetStore());

        // Update store with logged out state
        yield put(userLogout.success());

        // Redirect user to home page
        yield put(push(routes.login));
      } catch (error) {
        yield put(userLogout.failure(error));
      }
    }
  };

export const getSessionCheckFlow = ({ userService }: UserSagaFlowParams) =>
  function*() {
    while (true) {
      yield take(getType(sessionCheck.request));
      try {
        const { success, data }: SessionCheckResult = yield call(
          userService.sessionCheck
        );
        if (success && data) {
          const userRoles = data.groups ?? [];

          const directAppLogin = data.groups?.includes(UserRole.DirectApps);

          if (directAppLogin) {
            yield put(
              directAppUserLogin.success({ username: data.username, userRoles })
            );
          } else {
            yield put(
              userLogin.success({ username: data.username, userRoles })
            );
          }

          yield put(fetchRetailer.request());
          yield put(fetchBranchesForProposing.request());

          const enabledFeatures: FeatureConfig = yield select(
            selectEnabledFeatures
          );
          if (
            enabledFeatures.has(CompassFeature.CM3_USER_MANAGEMENT) &&
            !directAppLogin
          ) {
            yield put(fetchAllBranches.request());
            yield put(userPolicy.request());
          }
        } else {
          yield put(userLogout.success());
        }
      } catch (error) {
        yield put(sessionCheck.failure(error));
        return;
      }

      yield put(sessionCheck.success());
    }
  };

export const getSetPasswordFlow = ({ userService }: UserSagaFlowParams) =>
  function*() {
    while (true) {
      const action: UserSetPasswordAction = yield take(
        getType(userSetPassword.request)
      );

      if (isOfType(getType(userSetPassword.request), action)) {
        try {
          yield call(userService.setPassword, action.payload);
          yield put(userSetPassword.success({}));
        } catch (error) {
          yield put(userSetPassword.failure(error));
        }
      } else {
        yield put(userSetPassword.failure(new Error('Set password failed')));
      }
    }
  };

interface GetUserSagaWatcher {
  userService: UserService;
}

export const getUserSagaWatcher = ({ userService }: GetUserSagaWatcher) =>
  function*() {
    yield all([
      fork(getSessionCheckFlow({ userService })),
      fork(getLoginFlow({ userService })),
      fork(getLogoutFlow({ userService })),
      fork(getSetPasswordFlow({ userService }))
    ]);
  };

export const getUserPolicySagaWatcher = ({ userService }: GetUserSagaWatcher) =>
  function*() {
    yield takeEvery(
      getType(userPolicy.request),
      getUserPolicySaga({ userService })
    );
  };

export const getUserPolicySaga = ({ userService }: GetUserSagaWatcher) =>
  function*() {
    try {
      const response = yield call(userService.getUserPolicy);
      yield put(userPolicy.success(response));
    } catch (error) {
      yield put(userPolicy.failure(error));
    }
  };
