import NovunaPageLayout from 'applicantApp/components/NovunaPageLayout';
import {
  Box,
  Message,
  NovunaDocument,
  NovunaHeading,
  Text
} from 'compass-design';
import { JourneyType } from 'hitachi-retail-core/build/enums';
import React from 'react';
import { useSelector } from 'store';
import {
  selectApplicationJourneyType,
  selectApplicationReturnUrl,
  selectApplicationSupplierOrderReference,
  selectFetchApplicationStatus
} from 'store/application/selectors';
import {
  selectEsatNoteEnabled,
  selectRetailerName
} from 'store/retailer/selectors';
import {
  generateWsRedirectUrl,
  RetailerRedirectButton
} from 'applicantApp/components/ExternalRedirectButtons/ExternalRedirectButtons';
import Loading from 'applicantApp/components/Loading';
import { AsyncStatus } from '../../store/AsyncStatus';
import { ApplicantApplicationProps } from 'applicantApp/pages/ApplicantApplication/ApplicantApplication';

const PostSign: React.FunctionComponent<ApplicantApplicationProps> = ({
  id
}) => {
  const retailerName = useSelector(selectRetailerName);
  const esatNoteEnabled = useSelector(selectEsatNoteEnabled);
  const returnUrl = useSelector(selectApplicationReturnUrl);
  const supplierOrderReference = useSelector(
    selectApplicationSupplierOrderReference
  );
  const fetchApplicationStatus = useSelector(selectFetchApplicationStatus);
  const journeyType = useSelector(selectApplicationJourneyType);

  if (fetchApplicationStatus !== AsyncStatus.Success) {
    return <Loading />;
  }

  if (journeyType === JourneyType.WebServices && returnUrl) {
    window.location.replace(
      generateWsRedirectUrl(
        returnUrl,
        id,
        'accepted',
        supplierOrderReference
      ).toString()
    );
    return null;
  }

  const whatHappensNext = [
    {
      heading: 'Delivery',
      text: `${retailerName ??
        'The retailer'} will arrange the delivery and/or installation of your purchase`
    },
    esatNoteEnabled
      ? {
          heading: 'Are you satisfied?',
          text: `We'll send you a satisfaction note by email. Please let us know that you're satisfied with your purchase`
        }
      : {},
    {
      heading: 'Payments',
      text:
        'Your Direct Debit payments will begin, we’ll write to you to tell you when'
    },
    {
      heading: 'Manage online',
      text: `We'll contact you with instructions on how to access your loan information`
    }
  ];

  const filteredWhatHappensNext = whatHappensNext.filter(
    ({ heading, text }) => heading && text
  );

  return (
    <NovunaPageLayout>
      <NovunaHeading
        as='h1'
        mb={2}
        data-test-id={
          returnUrl
            ? 'pbf-loan-application-complete-page-heading'
            : 'mo-loan-application-complete-page-heading'
        }>
        {returnUrl ? 'Almost done' : 'Application complete'}
      </NovunaHeading>
      <Text
        mb='12px'
        data-test-id={
          returnUrl
            ? 'pbf-loan-application-complete-text'
            : 'mo-loan-application-complete-text'
        }>
        Your loan agreement is signed{!returnUrl && ' and complete'}.
      </Text>
      {id && (
        <Text mb={3} sx={{ fontWeight: 'bold' }}>
          {id}
        </Text>
      )}
      <Message variant='info' icon={<NovunaDocument />}>
        <NovunaHeading
          as='h3'
          mb={1}
          data-test-id={'application-complete-your-agreement-heading'}>
          Your agreement
        </NovunaHeading>
        <Text>
          A downloadable copy of your loan agreement will be emailed to you
          shortly.
        </Text>
      </Message>
      <NovunaHeading as='h2' mt={4} mb={3}>
        What happens next?
      </NovunaHeading>
      {returnUrl ? (
        <>
          <NovunaHeading as='h3' mb={2}>
            Complete the application
          </NovunaHeading>
          <Text>
            To complete the application process, you must now return to the
            store.
          </Text>
          <Box mt={5}>
            <RetailerRedirectButton
              novunaDisplay
              baseUrl={returnUrl}
              applicationId={id}
              status='accepted'
              buttonText='Continue to store'
              supplierOrderReference={supplierOrderReference}
            />
          </Box>
        </>
      ) : (
        <>
          {filteredWhatHappensNext.map(({ heading, text }, i) => (
            <Box key={i} mb={i === filteredWhatHappensNext.length - 1 ? 0 : 4}>
              <NovunaHeading
                as='h3'
                mb={2}
                data-test-id={'what-happens-next-'.concat(i.toString())}>
                {heading}
              </NovunaHeading>
              <Text>{text}</Text>
            </Box>
          ))}
        </>
      )}
    </NovunaPageLayout>
  );
};

export default PostSign;
