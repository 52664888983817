import { createAsyncAction } from 'typesafe-actions';
import * as app from './constants';

export interface SatisfactionFeedbackPayload {
  token: string;
  satisfied: boolean;
  feedback?: string;
}

export const satisfactionFeedback = createAsyncAction(
  app.SATISFACTION_FEEDBACK_REQUEST,
  app.SATISFACTION_FEEDBACK_SUCCESS,
  app.SATISFACTION_FEEDBACK_FAILURE
)<SatisfactionFeedbackPayload, void, Error>();
