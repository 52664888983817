import { call, put, takeEvery } from 'redux-saga/effects';
import { getType, isOfType } from 'typesafe-actions';
import {
  DeclinedLetterService,
  getDeclinedLetterService
} from '../../services/declinedLetterService';
import { fetchDeclinedLetterById } from '../../store/declinedEmail/actions';
import { FetchDeclinedEmailByIdAction } from '../../store/declinedEmail/reducer';
import { NotificationType, showNotification } from '../../store/ui/actions';
import { DefaultSagaParams } from '../index';

interface GetDownloadDeclinedEmailByIdSagaParams {
  declinedLetterService: DeclinedLetterService;
}

export const getDownloadDeclinedEmailByIdSaga = ({
  declinedLetterService
}: GetDownloadDeclinedEmailByIdSagaParams) =>
  function*(action: FetchDeclinedEmailByIdAction) {
    if (isOfType(getType(fetchDeclinedLetterById.request), action)) {
      const { id } = action.payload;
      try {
        const response: string = yield call(
          declinedLetterService.getDeclinedLetter,
          id
        );
        yield put(fetchDeclinedLetterById.success(response));
      } catch (err) {
        yield put(fetchDeclinedLetterById.failure(err));
        yield put(
          showNotification({
            message: err.message,
            type: NotificationType.DANGER
          })
        );
      }
    }
  };

export const getDownloadDeclinedEmailByIdSagaWatcher = ({
  apiClient
}: DefaultSagaParams) =>
  function*() {
    const declinedLetterService = getDeclinedLetterService({ apiClient });

    yield takeEvery(
      getType(fetchDeclinedLetterById.request),
      getDownloadDeclinedEmailByIdSaga({ declinedLetterService })
    );
  };
