import { DefaultSagaParams } from '../../sagas';
import { ApplicantConfig } from 'hitachi-retail-core';

export interface ApplicantConfigService {
  getConfig: () => Promise<ApplicantConfig>;
}

export const getApplicantConfigService = ({
  apiClient
}: DefaultSagaParams): ApplicantConfigService => ({
  getConfig: async (): Promise<ApplicantConfig> => {
    const response = await apiClient.get<ApplicantConfig>('mo/config');

    return { ...response.data };
  }
});
