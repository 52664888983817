import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';
import { MailOrderApplicationService } from 'services/mailOrderApplicationService';
import { PreviousApplicationDetail } from 'store/previousApplicationDetail/actions';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import {
  employmentStatusValue,
  HitachiProduct,
  maritalStatusValue,
  titleValue
} from 'hitachi-retail-core/build/enums';
import { validFinanceApplication } from 'hitachi-retail-core/build/test/financeApplicationTestData';
import { savedApplication } from './shared';

type MockTailoringIncreaseType = 'deposit' | 'term' | 'both' | 'none';
const mockTailoringIncreaseType: MockTailoringIncreaseType = 'none';
const isMockIncreasedTerm = (increaseType: MockTailoringIncreaseType) =>
  increaseType === 'term' || increaseType === 'both';
const isMockIncreasedDeposit = (increaseType: MockTailoringIncreaseType) =>
  increaseType === 'deposit' || increaseType === 'both';

const retailer: Retailer = {
  name: 'test',
  supplierNumber: '12345678',
  hasChildBranches: false,
  address: {
    houseName: 'MO Building Name',
    houseNumber: 'MO Building No',
    street: 'MO Street Name',
    town: 'MO Town',
    county: 'MO County',
    postCode: 'MO Postcode'
  },
  mailorderEnabled: true,
  esatNoteEnabled: false,
  onlineDepositsEnabled: true,
  tailoringIncreaseTermEnabled: isMockIncreasedTerm(mockTailoringIncreaseType),
  tailoringIncreaseDepositEnabled: isMockIncreasedDeposit(
    mockTailoringIncreaseType
  ),
  tailoringReduceLoanEnabled: false
};

export const mockedMailOrderApplicationId = 'TESTAUTH00001';
export enum MockedMailOrderSoftSearchApplicantionIds {
  ACCEPT = `SS_TESTAUTH00001_ACCEPT`,
  DECLINE = `SS_TESTAUTH00001_DECLINE`,
  REFER = `SS_TESTAUTH00001_REFER`
}
export const mockedMailOrderSoftSearchApplicationId = `MO_${mockedMailOrderApplicationId}`;
export const mockedMailOrderApplication: PreviousApplicationDetail = {
  id: mockedMailOrderApplicationId,
  status: ApplicationStatus.open,
  created: '11-11-2011',
  updated: '11-11-2011',
  softSearchExpiryDate: '',
  tailoringExpiryDate: '',
  applicationExpiryDate: '',
  document: {
    ...validFinanceApplication,
    returnUrl: 'http://example.com',
    product: {
      serviceType: HitachiProduct.InterestBearingCredit,
      apr: '3.2',
      agreementType: 'PBF',
      productConfig: {
        id: 70,
        apr: '0',
        rpm: '0',
        name: 'Interest free credit',
        endDate: '2020-10-25T23:59:59.000Z',
        startDate: '2019-10-26T00:00:00.000Z',
        instalments: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        serviceType: 'IF',
        maxLoanAmount: '25000',
        minLoanAmount: '250',
        deferralPeriod: [],
        minGoodsAmount: '250',
        minDepositAmount: '0',
        earlySettlementFee: false,
        minDepositPercentage: '0'
      }
    },
    personalDetails: {
      ...validFinanceApplication.personalDetails,
      title: titleValue.Dr,
      maritalStatus: maritalStatusValue.Cohabiting
    },
    financialDetails: {
      ...validFinanceApplication.financialDetails,
      employmentStatus: employmentStatusValue.Student,
      spouseEmploymentStatus: employmentStatusValue.Employed,
      financialDependants: '3',
      incomeValue: 10000
    },
    isOnlineDepositJourney: true,
    paperlessConsent: true,
    loanTailoring: {
      increaseDeposit: {
        offered: isMockIncreasedDeposit(mockTailoringIncreaseType),
        offer: {
          minimumDeposit: '2',
          maximumLoanAmount: '50',
          totalRepaymentAmount: '150',
          monthlyRepayment: '25'
        }
      },
      increaseTerm: {
        offered: isMockIncreasedTerm(mockTailoringIncreaseType),
        offer: {
          minimumTerm: 24,
          availableInstalments: [1],
          monthlyRepayment: '75',
          totalRepaymentAmount: '1000'
        }
      },
      reduceLoan: {
        offered: false,
        offer: {
          deposit: '1000',
          maximumLoanAmount: '1100',
          maximumGoodsAmount: '2100',
          maximumMonthlyRepayment: '100'
        }
      },
      maxInstalmentAmount: '200.00'
    }
  },
  canResume: true,
  canCancel: true,
  canView: true,
  canMarkAsDelivered: true,
  canDownloadDeclineLetter: true,
  canDownloadSignedAgreement: false,
  retailer,
  branchCreatedBy: '',
  partialSettlementRequests: null
};

const getMockMailOrderApplicationService = (): MailOrderApplicationService => {
  window.localStorage.setItem(savedApplication, '');
  return {
    getById: async () => {
      console.log('Mock invoked: mailOrderApplicationService.getById');

      const localApp = window.localStorage.getItem(savedApplication);

      return localApp
        ? (JSON.parse(localApp) as PreviousApplicationDetail)
        : mockedMailOrderApplication;
    },
    saveApplication: async payload => {
      console.log(
        'getMockMailOrderApplicationService: saveApplication with payload:',
        payload
      );
      window.localStorage.setItem(savedApplication, JSON.stringify(payload));
      return 'id';
    }
  };
};

export default getMockMailOrderApplicationService;
