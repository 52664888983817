import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import * as app from './constants';

interface SaveApplicationResult {
  id: string;
}

export const saveApplication = createAsyncAction(
  app.SAVE_APPLICATION_REQUEST,
  app.SAVE_APPLICATION_SUCCESS,
  app.SAVE_APPLICATION_FAILURE
)<void, SaveApplicationResult, Error>();

export const setApplicationId = createStandardAction(app.SET_APPLICATION_ID)<
  SaveApplicationResult
>();
