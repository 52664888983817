import { connect } from 'react-redux';
import { RootState } from '../../../store';
import SigningCompleteLanding, {
  SigningCompleteLandingPropsFromDispatch,
  SigningCompleteLandingPropsFromState
} from './SigningCompleteLanding';
import { Dispatch } from 'redux';
import { signingRedirect } from '../../../store/signingRedirects/actions';

export const mapStateToProps = ({
  signingRedirects: { signingCompletePath }
}: RootState): SigningCompleteLandingPropsFromState => ({
  signingRedirects: {
    signingCompletePath
  }
});

export const mapDispatchToProps = (
  dispatch: Dispatch
): SigningCompleteLandingPropsFromDispatch => ({
  signingRedirect: ({ signingRedirects }) =>
    dispatch(signingRedirect({ signingRedirects }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SigningCompleteLanding);
