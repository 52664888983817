import { call, put, takeEvery } from 'redux-saga/effects';
import { getType, isOfType } from 'typesafe-actions';
import { ApplicantConfigService } from '../../services/config';
import { fetchMailOrderConfig } from './actions';
import { FetchMailOrderConfigAction } from './reducer';

interface GetFetchMailOrderConfigSagaParams {
  applicantConfigService: ApplicantConfigService;
}

export const getMailOrderConfigSaga = ({
  applicantConfigService
}: GetFetchMailOrderConfigSagaParams) =>
  function*(action: FetchMailOrderConfigAction) {
    if (isOfType(getType(fetchMailOrderConfig.request), action)) {
      try {
        const response = yield call(applicantConfigService.getConfig);
        yield put(fetchMailOrderConfig.success(response));
      } catch (err) {
        yield put(fetchMailOrderConfig.failure(err));
      }
    }
  };

export interface MailOrderConfigSagaParams {
  applicantConfigService: ApplicantConfigService;
}

export const getMailOrderConfigSagaWatcher = ({
  applicantConfigService
}: MailOrderConfigSagaParams) =>
  function*() {
    yield takeEvery(
      getType(fetchMailOrderConfig.request),
      getMailOrderConfigSaga({ applicantConfigService })
    );
  };
