import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Prompt, {
  PromptPropsFromDispatch,
  PromptPropsFromState
} from '../components/Prompt';
import { RootState } from '../store';
import { closePrompt } from '../store/ui/actions';

export const mapStateToProps = ({
  ui: { prompt = {} }
}: RootState): PromptPropsFromState => ({
  title: prompt.title,
  message: prompt.message,
  onCancel: prompt.onCancel
    ? prompt.onCancel
    : () => {
        /* fallback function */
      },
  onConfirm: prompt.onConfirm
    ? prompt.onConfirm
    : () => {
        /* fallback function */
      }
});

export const mapDispatchToProps = (
  dispatch: Dispatch
): PromptPropsFromDispatch => ({
  onClose: () => dispatch(closePrompt())
});

export default connect(mapStateToProps, mapDispatchToProps)(Prompt);
