import { Message, NovunaHeading } from 'compass-design';
import ToggledEndAndSend from 'endAndSend';
import { EndAndSendButtonOwnProps } from 'endAndSend/components/EndAndSendButton';
import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import React from 'react';

export type ApplyFromHomeProps = EndAndSendButtonOwnProps;

const ApplyFromHome = ({ ...buttonProps }: ApplyFromHomeProps) => (
  <>
    <Message variant='info' mt={6} mb={4}>
      <NovunaHeading as='h3' mb={1}>
        Apply from home
      </NovunaHeading>
      Soft search is unavailable for customers completing their application at
      home.
      <br />A full credit search will be carried out before the application can
      be approved.
    </Message>
    <ToggledEndAndSend
      enabledBy={CompassFeature.MAIL_ORDER_SAVE_QUOTE}
      {...buttonProps}
    />
  </>
);

export default ApplyFromHome;
