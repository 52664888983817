import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  InactiveLogout,
  InactiveLogoutPropsFromDispatch,
  InactiveLogoutPropsFromState
} from '../../components/meta/InactiveLogout';
import { RootState } from '../../store';
import { userLogout } from '../../store/user/actions';

export const mapStateToProps = ({
  user: { loggedIn }
}: RootState): InactiveLogoutPropsFromState => ({
  loggedIn: loggedIn === true
});

export const mapDispatchToProps = (
  dispatch: Dispatch
): InactiveLogoutPropsFromDispatch => ({
  onTimeout: () => {
    dispatch(userLogout.request());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(InactiveLogout);
