import { call, put, takeEvery } from 'redux-saga/effects';
import { getType, isOfType } from 'typesafe-actions';
import { ProductService } from '../../services/product';
import {
  fetchProductsForRetailer,
  ProductsForRetailerAction
} from '../../store/product/actions';

interface GetProductSagaParams {
  productService: ProductService;
}

export const getFetchProductsForRetailer = ({
  productService
}: GetProductSagaParams) =>
  function*(action: ProductsForRetailerAction) {
    if (isOfType(getType(fetchProductsForRetailer.request), action)) {
      let supplierNumber;
      if (action.payload) {
        supplierNumber = action.payload.supplierNumber;
      }

      try {
        const response = yield call(
          productService.getProductsForRetailer,
          supplierNumber
        );
        yield put(fetchProductsForRetailer.success(response));
      } catch (error) {
        yield put(fetchProductsForRetailer.failure(error));
      }
    }
  };

export const getProductSagaWatcher = ({
  productService
}: GetProductSagaParams) =>
  function*() {
    yield takeEvery(
      getType(fetchProductsForRetailer.request),
      getFetchProductsForRetailer({ productService })
    );
  };
