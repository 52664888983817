import {
  Box,
  InputWrapper,
  NovunaHeading,
  NovunaSingleColumnLayout,
  NovunaSpinner,
  RadioGroup,
  TertiaryLink,
  TertiaryLinkDirection
} from 'compass-design';
import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';
import { User } from 'hitachi-retail-core/build/services/users/usersService';
import { UserRole } from 'hitachi-retail-core/build/userPolicy';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Authorize from 'retailerApp/components/Authorize';
import { UserApiData, UserFormData } from 'retailerApp/utils/editingUsers';
import BranchSelector from './components/BranchSelector';
import CreateAndEditUser from './components/CreateAndEditUser';
import HeadOfficeUserBanner from './components/HeadOfficeUserBanner';

export interface UsersPropsFromState {
  loading: boolean;
  userRoles: UserRole[];
  branches?: Retailer[];
  selectedBranch?: Retailer;
  retailer: Retailer;
  trimmedUsername?: string;
  userToEdit?: UserFormData;
  loggedInUsername: string;
  hasNotification: boolean;
}

export interface UsersPropsFromDispatch {
  selectBranch: (selectedBranch?: Retailer) => void;
  createUser: (values: UserApiData) => void;
  handleUserSelected: (user: User) => void;
  updateUser: (user: UserApiData) => void;
  resetUser: () => void;
  clearNotifications: (hasNotifications: boolean) => void;
}

export type UsersProps = UsersPropsFromState &
  UsersPropsFromDispatch & { handleClearingNotifications: () => void };

const Users = ({
  loading,
  loggedInUsername,
  retailer,
  userRoles,
  branches,
  selectedBranch,
  trimmedUsername,
  userToEdit,
  selectBranch,
  createUser,
  updateUser,
  handleUserSelected,
  resetUser,
  handleClearingNotifications
}: UsersProps) => {
  const [isEdit, setIsEdit] = useState(false);

  const numberOfBranches = branches?.length ?? 0;

  const showBranchSelector: boolean =
    [
      UserRole.BrokerUser,
      UserRole.GroupUser,
      UserRole.HeadOfficeUser
    ].some(role => userRoles.includes(role)) && numberOfBranches > 0;

  /*
   * Head office users can only be managed, if there are child branches and the logged in branch is also the
   * selected branch or the selected branch is a head office with child branches (brokers / groups).
   */
  const selectedIsHeadOfficeWithBranches =
    (selectedBranch?.isHeadOffice && selectedBranch?.hasChildBranches) ?? false;

  const manageHeadOfficeUsers: boolean =
    showBranchSelector &&
    (retailer.supplierNumber === selectedBranch?.supplierNumber ||
      selectedIsHeadOfficeWithBranches);

  useEffect(() => {
    resetUser();
  }, [isEdit, resetUser]);

  useEffect(() => {
    if (
      !showBranchSelector &&
      !selectedBranch &&
      retailer.name.length > 0 &&
      !!branches
    ) {
      selectBranch(retailer);
    }
  }, [selectBranch, selectedBranch, showBranchSelector, retailer, branches]);

  let UserScreen;

  if (
    !selectedBranch ||
    selectedBranch.name.length === 0 ||
    selectedBranch.supplierNumber.length === 0
  ) {
    if (showBranchSelector && branches) {
      UserScreen = (
        <BranchSelector
          branches={branches}
          selectBranch={selectBranch}
          headOfficeRetailer={retailer}
          userRoles={userRoles}
        />
      );
    } else {
      UserScreen = (
        <NovunaSingleColumnLayout>
          <NovunaSpinner />
        </NovunaSingleColumnLayout>
      );
    }
  } else {
    UserScreen = (
      <>
        <NovunaSingleColumnLayout
          pageTop={
            <>
              {showBranchSelector && (
                <HeadOfficeUserBanner
                  username={loggedInUsername}
                  headOfficeRetailer={retailer}
                  selectedBranch={selectedBranch}
                />
              )}
              {showBranchSelector && (
                <Box mt={3}>
                  <TertiaryLink
                    direction={TertiaryLinkDirection.BACKWARDS}
                    text='Select branch'
                    dataTestId='select-branch-button'
                    onClick={() => {
                      selectBranch(undefined);
                      setIsEdit(false);
                    }}
                  />
                </Box>
              )}
            </>
          }>
          <>
            <NovunaHeading as='h1' mb={3}>
              Users
            </NovunaHeading>
            <Authorize allow={['user:create', 'user:update']}>
              <Box>
                <form key='user-function' action='#' name='user-function'>
                  <InputWrapper id='options' label='What do you need to do?'>
                    <RadioGroup
                      name='options'
                      options={[
                        {
                          labelSx: { alignItems: 'normal' },
                          label: 'Create new user',
                          checked: !isEdit,
                          value: 'create'
                        },
                        {
                          labelSx: { alignItems: 'normal' },
                          label: 'Edit user',
                          checked: isEdit,
                          value: 'edit'
                        }
                      ]}
                      onChange={event => {
                        handleClearingNotifications();
                        setIsEdit(event.target.value === 'edit');
                      }}
                    />
                  </InputWrapper>
                </form>
              </Box>
              <CreateAndEditUser
                isCreate={!isEdit}
                manageHeadOfficeUsers={manageHeadOfficeUsers}
                selectedBranch={selectedBranch}
                userToEdit={userToEdit}
                trimmedUsername={trimmedUsername}
                handleOnSubmit={isEdit ? updateUser : createUser}
                handleUserSelected={handleUserSelected}
                handleClearingNotifications={handleClearingNotifications}
                loading={loading}
              />
            </Authorize>
          </>
        </NovunaSingleColumnLayout>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Users - CreditMaster3</title>
      </Helmet>
      {UserScreen}
    </>
  );
};

export default Users;
