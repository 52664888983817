import { Address } from 'hitachi-retail-core';
import { DefaultSagaParams } from '../../sagas';
import { AddressLookupService } from '../../services/addressLookupService';

export const getMailOrderAddressLookupService = ({
  apiClient
}: DefaultSagaParams): AddressLookupService => ({
  getAddresses: async (postcode: string): Promise<Address[]> => {
    const response = await apiClient.get<Address[]>(`mo/address/${postcode}`);

    return response.data;
  }
});
