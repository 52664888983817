import { Box, NovunaHeading } from 'compass-design';
import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';
import { User } from 'hitachi-retail-core/build/services/users/usersService';
import { UserRole } from 'hitachi-retail-core/build/userPolicy';
import React from 'react';
import Authorize from 'retailerApp/components/Authorize';
import { UserApiData, UserFormData } from 'retailerApp/utils/editingUsers';
import UserForm from '../UserForm';
import UserLookup from '../UserLookup';

interface GetInitialValues {
  userToEdit?: UserFormData;
  isCreate: boolean;
  isHeadOffice: boolean;
}

const getInitialValues = ({
  userToEdit,
  isCreate,
  isHeadOffice
}: GetInitialValues) => {
  const initialValues = {
    enabled: true,
    retailerName: '',
    givenName: '',
    familyName: '',
    email: '',
    username: '',
    defaultBranch: '',
    groups: isHeadOffice ? UserRole.HeadOfficeUser : UserRole.BranchUser
  };

  return !isCreate && userToEdit
    ? { ...initialValues, ...userToEdit }
    : initialValues;
};

export interface CreateAndEditUserProps {
  isCreate: boolean;
  manageHeadOfficeUsers: boolean;
  selectedBranch: Retailer;
  userToEdit?: UserFormData;
  trimmedUsername?: string;
  loading: boolean;
  handleOnSubmit: (values: UserApiData) => void;
  handleUserSelected: (user: User) => void;
  handleClearingNotifications: () => void;
}

const CreateAndEditUser: React.FC<CreateAndEditUserProps> = ({
  isCreate,
  manageHeadOfficeUsers,
  selectedBranch,
  userToEdit,
  trimmedUsername,
  loading,
  handleOnSubmit,
  handleUserSelected,
  handleClearingNotifications
}) => (
  <Box sx={{ maxWidth: '640px' }}>
    {!isCreate && (
      <Box mt={5}>
        <UserLookup
          onSelectUser={handleUserSelected}
          handleClearingNotifications={handleClearingNotifications}
        />
      </Box>
    )}
    <NovunaHeading as='h2' mt={isCreate ? 5 : 0} mb={3}>
      {isCreate ? 'Create new' : 'Edit user'}
    </NovunaHeading>
    <Authorize allow={isCreate ? 'user:create' : 'user:update'}>
      <UserForm
        isCreate={isCreate}
        manageHeadOfficeUsers={manageHeadOfficeUsers}
        retailer={{
          name: selectedBranch.name,
          normalisedName: selectedBranch.retailerNormalisedName,
          branchId: selectedBranch.supplierNumber
        }}
        initialValues={getInitialValues({
          isCreate,
          isHeadOffice: manageHeadOfficeUsers,
          userToEdit
        })}
        handleOnSubmit={handleOnSubmit}
        trimmedUsername={trimmedUsername}
        handleClearingNotifications={handleClearingNotifications}
        loading={loading}
      />
    </Authorize>
  </Box>
);

export default CreateAndEditUser;
