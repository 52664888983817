// Get by ID
export const FETCH_PREVIOUS_APPLICATION_BY_ID_REQUEST =
  'FETCH_PREVIOUS_APPLICATION_BY_ID_REQUEST';
export const FETCH_PREVIOUS_APPLICATION_BY_ID_SUCCESS =
  'FETCH_PREVIOUS_APPLICATION_BY_ID_SUCCESS';
export const FETCH_PREVIOUS_APPLICATION_BY_ID_FAILURE =
  'FETCH_PREVIOUS_APPLICATION_BY_ID_FAILURE';

// Mark goods as delivered
export const MARK_GOODS_AS_DELIVERED_REQUEST =
  'MARK_GOODS_AS_DELIVERED_REQUEST';
export const MARK_GOODS_AS_DELIVERED_SUCCESS =
  'MARK_GOODS_AS_DELIVERED_SUCCESS';
export const MARK_GOODS_AS_DELIVERED_FAILURE =
  'MARK_GOODS_AS_DELIVERED_FAILURE';

// Resend Esat Note
export const RESEND_ESAT_NOTE_REQUEST = 'RESEND_ESAT_NOTE_REQUEST';
export const RESEND_ESAT_NOTE_SUCCESS = 'RESEND_ESAT_NOTE_SUCCESS';
export const RESEND_ESAT_NOTE_FAILURE = 'RESEND_ESAT_NOTE_FAILURE';

// Cancel application
export const CANCEL_APPLICATION_REQUEST = 'CANCEL_APPLICATION_REQUEST';
export const CANCEL_APPLICATION_SUCCESS = 'CANCEL_APPLICATION_SUCCESS';
export const CANCEL_APPLICATION_FAILURE = 'CANCEL_APPLICATION_FAILURE';

// Request partial settlement
export const PARTIAL_SETTLEMENT_REQUEST = 'PARTIAL_SETTLEMENT_REQUEST';
export const PARTIAL_SETTLEMENT_SUCCESS = 'PARTIAL_SETTLEMENT_SUCCESS';
export const PARTIAL_SETTLEMENT_FAILURE = 'PARTIAL_SETTLEMENT_FAILURE';

// Fetch active application
export const FETCH_ACTIVE_APPLICATION_BY_ID_REQUEST =
  'FETCH_ACTIVE_APPLICATION_BY_ID_REQUEST';
export const FETCH_ACTIVE_APPLICATION_BY_ID_SUCCESS =
  'FETCH_ACTIVE_APPLICATION_BY_ID_SUCCESS';
export const FETCH_ACTIVE_APPLICATION_BY_ID_FAILURE =
  'FETCH_ACTIVE_APPLICATION_BY_ID_FAILURE';

// Fetch referral notes
export const FETCH_APPLICATION_REFERRAL_NOTES_REQUEST =
  'FETCH_APPLICATION_REFERRAL_NOTES_REQUEST';
export const FETCH_APPLICATION_REFERRAL_NOTES_SUCCESS =
  'FETCH_APPLICATION_REFERRAL_NOTES_SUCCESS';
export const FETCH_APPLICATION_REFERRAL_NOTES_FAILURE =
  'FETCH_APPLICATION_REFERRAL_NOTES_FAILURE';

// Fetch audit history
export const FETCH_AUDIT_HISTORY_REQUEST = 'FETCH_AUDIT_HISTORY_REQUEST';
export const FETCH_AUDIT_HISTORY_SUCCESS = 'FETCH_AUDIT_HISTORY_SUCCESS';
export const FETCH_AUDIT_HISTORY_FAILURE = 'FETCH_AUDIT_HISTORY_FAILURE';

// Submit referral note
export const SUBMIT_APPLICATION_REFERRAL_NOTE_REQUEST =
  'SUBMIT_APPLICATION_REFERRAL_NOTE_REQUEST';
export const SUBMIT_APPLICATION_REFERRAL_NOTE_SUCCESS =
  'SUBMIT_APPLICATION_REFERRAL_NOTE_SUCCESS';
export const SUBMIT_APPLICATION_REFERRAL_NOTE_FAILURE =
  'SUBMIT_APPLICATION_REFERRAL_NOTE_FAILURE';

// Fetch customer feedback
export const FETCH_CUSTOMER_FEEDBACK_REQUEST =
  'FETCH_CUSTOMER_FEEDBACK_REQUEST';
export const FETCH_CUSTOMER_FEEDBACK_SUCCESS =
  'FETCH_CUSTOMER_FEEDBACK_SUCCESS';
export const FETCH_CUSTOMER_FEEDBACK_FAILURE =
  'FETCH_CUSTOMER_FEEDBACK_FAILURE';

export const RESET_APPLICATION_REFERRAL_NOTE_SUBMISSION_STATUS =
  'RESET_APPLICATION_REFERRAL_NOTE_SUBMISSION_STATUS';

export const REVERT_APPLICATION_VERSION_REQUEST =
  'REVERT_APPLICATION_VERSION_REQUEST';
export const REVERT_APPLICATION_VERSION_SUCCESS =
  'REVERT_APPLICATION_VERSION_SUCCESS';
export const REVERT_APPLICATION_VERSION_FAILURE =
  'REVERT_APPLICATION_VERSION_FAILURE';
