import React, { useState } from 'react';
import { Flex, Link } from 'compass-design';
import { StrongerPasswordGuidance } from './StrongerPasswordGuidance';
import { routes } from 'routes';

const PromptPasswordUpdate = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const linkProps = {
    color: 'black',
    sx: {
      fontWeight: 'bold',
      '&:hover': { color: 'black', opacity: '0.9' }
    }
  };

  return (
    <>
      <StrongerPasswordGuidance
        modalOpen={modalOpen}
        toggleModalOpen={isOpen => setModalOpen(isOpen)}
      />
      <Flex
        data-test-id='compass-prompt-password-update'
        bg={'rgba(250,242,110,0.3)'}
        p={'0.25em 0.5em'}
        sx={{
          justifyContent: 'center',
          textAlign: 'center',
          borderBottom: '2px solid rgb(211, 210, 181)'
        }}>
        <span>
          Our system recognised your password doesn&apos;t adhere to our updated
          password guidelines. You can read the guidance&nbsp;
          <Link
            href={'#'}
            onClick={e => {
              e.preventDefault();
              setModalOpen(true);
            }}
            {...linkProps}>
            here
          </Link>
          &nbsp;and change your password&nbsp;
          <Link href={routes.profile.managePassword} {...linkProps}>
            here
          </Link>
          .
        </span>
      </Flex>
    </>
  );
};

export default PromptPasswordUpdate;
