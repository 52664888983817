import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { saveSigningCompletePath, signingRedirect } from './actions';

export interface State {
  signingCompletePath?: string;
}

export type SaveSigningCompletePathAction = ActionType<
  typeof saveSigningCompletePath
>;

export type SigningRedirectAction = ActionType<typeof signingRedirect>;

export const initialState: State = {};

export const reducer: Reducer<State, SaveSigningCompletePathAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(saveSigningCompletePath): {
      return {
        ...state,
        signingCompletePath: action.payload.signingCompletePath
      };
    }
    default: {
      return state;
    }
  }
};
