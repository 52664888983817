import React from 'react';
import { Box, Button, NovunaHeading, Text } from 'compass-design';

const GuidanceDetails = ({ guidanceModalToggle }) => (
  <>
    <NovunaHeading as='h3' mb={2}>
      Guidance
    </NovunaHeading>

    <Text>
      Please refer to our stronger password guidance when setting your password
      to ensure that your account remains secure.
    </Text>

    <Box mt={3}>
      <Button variant='primary' onClick={() => guidanceModalToggle(true)}>
        View Guidance
      </Button>
    </Box>
  </>
);

export default GuidanceDetails;
