import deepmerge from 'deepmerge';
import { isRegulatedApplication } from 'hitachi-retail-core/build/application';
import {
  ApplicationModificationStatus,
  getApplicationModificationStatus
} from 'hitachi-retail-core/build/application/modification';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import { IncompleteFinanceApplication } from 'hitachi-retail-core/build/schemas/financeApplication';
import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { AsyncStatus } from '../../applicantApp/store/AsyncStatus';
import { ApplicationDetail } from '../../components/form/types';
import { resetStore, ResetStoreAction } from '../actions';
import { fetchDecision } from '../decision/actions';
import { FetchDecisionAction } from '../decision/reducer';
import { getEnabledFeatures } from '../featureFlagEnhancer';
import {
  fetchActiveApplication,
  PreviousApplicationDetail
} from '../previousApplicationDetail/actions';
import { ProductsForRetailerAction } from '../product/actions';
import { FetchRetailerAction } from '../retailer/actions';
import {
  bankAccountCheck,
  selectApplicationProductConfig,
  selectApplicationProductOption,
  updateActiveApplication,
  resetActiveApplicationStore,
  ResetActiveApplicationStoreAction,
  clearSelectedProduct,
  ClearSelectedProductAction
} from './actions';
import { validationMessages } from 'hitachi-retail-core/build/application/messages';

export type UpdateActiveApplicationAction = ActionType<
  typeof updateActiveApplication
>;
export type BankAccountCheckAction = ActionType<typeof bankAccountCheck>;
export type SelectApplicationProductOptionAction = ActionType<
  typeof selectApplicationProductOption
>;
export type SelectApplicationProductConfigAction = ActionType<
  typeof selectApplicationProductConfig
>;
export type FetchActiveApplicationAction = ActionType<
  typeof fetchActiveApplication
>;

export interface State {
  activeApplication: ApplicationDetail;
  errorMessage?: string;
  fetchApplicationStatus: AsyncStatus;
  bankCheckStatus: AsyncStatus;
  mustReselectProduct: boolean;
  successfulBankCheck?: boolean;
}

export type Action =
  | FetchRetailerAction
  | ProductsForRetailerAction
  | UpdateActiveApplicationAction
  | SelectApplicationProductOptionAction
  | SelectApplicationProductConfigAction
  | ClearSelectedProductAction
  | BankAccountCheckAction
  | FetchActiveApplicationAction
  | ResetStoreAction
  | FetchDecisionAction
  | ResetActiveApplicationStoreAction;

export const initialState: State = {
  activeApplication: {
    status: ApplicationStatus.open,
    modificationStatus: ApplicationModificationStatus.YES,
    document: {}
  },
  fetchApplicationStatus: AsyncStatus.Default,
  bankCheckStatus: AsyncStatus.Default,
  mustReselectProduct: false
};

export const reducer: Reducer<State, Action> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchActiveApplication.request): {
      return {
        ...initialState,
        fetchApplicationStatus: AsyncStatus.Loading
      };
    }
    case getType(fetchActiveApplication.failure): {
      return {
        ...initialState,
        fetchApplicationStatus: AsyncStatus.Failure
      };
    }
    case getType(fetchActiveApplication.success): {
      const {
        document,
        tailoringExpiryDate,
        softSearchExpiryDate,
        signed,
        revisions
      } = action.payload as PreviousApplicationDetail;
      const status = action.payload.status as ApplicationStatus;
      return {
        ...state,
        activeApplication: {
          status,
          modificationStatus: getApplicationModificationStatus(
            status,
            getEnabledFeatures(action)
          ),
          document,
          tailoringExpiryDate,
          softSearchExpiryDate,
          signed,
          revisions
        },
        fetchApplicationStatus: AsyncStatus.Success
      };
    }

    case getType(updateActiveApplication): {
      const { activeApplication } = state;
      const {
        document,
        status,
        replaceValues,
        softSearchExpiryDate,
        applicationExpiryDate,
        maxInstalmentAmount
      } = action.payload;

      let updatedDocument = activeApplication.document;
      if (document) {
        updatedDocument = updateApplicationDocument(
          state.activeApplication.document,
          document,
          replaceValues === true
        );
      }

      let modificationStatus: ApplicationModificationStatus | undefined;
      if (status) {
        modificationStatus = getApplicationModificationStatus(
          status,
          getEnabledFeatures(action)
        );
      }

      return {
        ...state,
        activeApplication: {
          status: status || activeApplication.status,
          modificationStatus:
            modificationStatus || activeApplication.modificationStatus,
          document: updatedDocument,
          softSearchExpiryDate:
            softSearchExpiryDate ?? activeApplication?.softSearchExpiryDate,
          applicationExpiryDate:
            applicationExpiryDate ?? activeApplication.applicationExpiryDate,
          maxInstalmentAmount:
            maxInstalmentAmount ?? activeApplication.maxInstalmentAmount,
          revisions: activeApplication.revisions
        }
      };
    }

    case getType(clearSelectedProduct): {
      const { document } = state.activeApplication;

      const updatedDocument = updateApplicationDocument(
        document,
        {
          product: {},
          loanRepayment: {
            deferralPeriod: null
          }
        },
        true
      );

      return {
        ...state,
        activeApplication: {
          ...state.activeApplication,
          document: updatedDocument
        },
        mustReselectProduct: true
      };
    }

    case getType(selectApplicationProductOption): {
      const { document, modificationStatus } = state.activeApplication;
      const { productOption } = action.payload;
      const { apr, serviceType } = productOption;

      let instalments = document.loanRepayment?.instalments;
      let deferralPeriod = document.loanRepayment?.deferralPeriod;
      if (modificationStatus === ApplicationModificationStatus.YES) {
        // Force reselection on product option change
        deferralPeriod = null;
        instalments = undefined;
      }

      const updatedDocument = updateApplicationDocument(
        document,
        {
          product: {
            serviceType,
            apr
          },
          loanRepayment: {
            ...document.loanRepayment,
            deferralPeriod,
            instalments
          }
        },
        true
      );

      return {
        ...state,
        activeApplication: {
          ...state.activeApplication,
          document: updatedDocument
        },
        mustReselectProduct: false
      };
    }

    case getType(selectApplicationProductConfig): {
      const { document } = state.activeApplication;
      const {
        productConfig,
        interestFreeCreditUnregulatedEndDate
      } = action.payload;
      const {
        serviceType,
        apr,
        earlySettlementFee,
        minDepositPercentage,
        minDepositAmount,
        minGoodsAmount,
        minLoanAmount,
        maxLoanAmount,
        startDate,
        endDate
      } = productConfig;

      const updatedDocument = updateApplicationDocument(
        document,
        {
          product: {
            serviceType,
            apr,
            regulated: isRegulatedApplication(document, {
              interestFreeCreditUnregulatedEndDate
            }),
            earlySettlementFee,
            productConfig: {
              ...productConfig,
              // Don't store complex types in Redux
              minDepositPercentage: minDepositPercentage.toString(),
              minDepositAmount: minDepositAmount.toString(),
              minGoodsAmount: minGoodsAmount.toString(),
              minLoanAmount: minLoanAmount.toString(),
              maxLoanAmount: maxLoanAmount.toString(),
              startDate: startDate.toISOString(),
              endDate: endDate.toISOString()
            }
          }
        },
        true
      );

      return {
        ...state,
        activeApplication: {
          ...state.activeApplication,
          document: updatedDocument
        }
      };
    }

    case getType(bankAccountCheck.request):
      return {
        ...state,
        errorMessage: undefined,
        bankCheckStatus: AsyncStatus.Loading
      };

    case getType(bankAccountCheck.success): {
      let errorMessage: string | undefined;
      if (action.payload.valid === false) {
        errorMessage = validationMessages.INVALID_BANK_ACCOUNT;
      }

      let document = state.activeApplication.document;
      if (action.payload.valid) {
        document = updateApplicationDocument(
          document,
          {
            bankAccountDetails: action.payload.bankDetails
          },
          false // Merge values with existing
        );
      }

      return {
        ...state,
        activeApplication: {
          ...state.activeApplication,
          document
        },
        errorMessage,
        successfulBankCheck: action.payload.valid === true,
        bankCheckStatus: errorMessage
          ? AsyncStatus.Failure
          : AsyncStatus.Success
      };
    }

    case getType(bankAccountCheck.failure): {
      const document = state.activeApplication.document;

      // FX-1021 - Get rid of hanging bank details due to failure
      const {
        name = undefined,
        sortCode = undefined,
        accountNumber = undefined
      } = state.activeApplication.document.bankAccountDetails || {};
      return {
        ...state,
        activeApplication: {
          ...state.activeApplication,
          document: {
            ...document,
            bankAccountDetails: {
              name,
              sortCode,
              accountNumber
            }
          }
        },
        errorMessage: action.payload.message,
        bankCheckStatus: AsyncStatus.Failure
      };
    }

    case getType(fetchDecision.success): {
      const {
        decisionResponse: status,
        loanTailoring,
        productConfig
      } = action.payload;
      return {
        ...state,
        activeApplication: {
          ...state.activeApplication,
          document: {
            ...state.activeApplication.document,
            loanTailoring,
            product: {
              ...state.activeApplication.document.product,
              productConfig:
                productConfig ??
                state.activeApplication.document.product?.productConfig
            }
          },
          status,
          modificationStatus: getApplicationModificationStatus(
            status,
            getEnabledFeatures(action)
          )
        }
      };
    }

    case getType(resetActiveApplicationStore):
    case getType(resetStore): {
      return initialState;
    }

    default:
      return state;
  }
};

export const updateApplicationDocument = (
  currentDocument: IncompleteFinanceApplication,
  documentUpdates: IncompleteFinanceApplication,
  replaceValues: boolean
): IncompleteFinanceApplication =>
  deepmerge(currentDocument, documentUpdates, {
    // Some values can be replaced rather than merged
    customMerge: () => {
      if (replaceValues) {
        return (_: any, actionValues: any) => actionValues;
      }
      return;
    },
    // Replace whole arrays in state with corresponding value in action payload
    arrayMerge: (_: any[], actionArray: any[]) => actionArray
  });
