import { Box, Message, NovunaHeading, Text } from 'compass-design';
import React from 'react';

const LoanAmendInProgress = () => (
  <Box mt={6} data-test-id='loan-amend-in-progress'>
    <Message variant='info' restrictedWidth={false}>
      <NovunaHeading as='h3' mb={1}>
        Loan amend in progress
      </NovunaHeading>
      <Text>
        This application has been amended and the goods amount hasn&apos;t yet
        updated. You&apos;ll be able to resume or amend this application once we
        have confirmation of the updated goods amount.
      </Text>
    </Message>
  </Box>
);

export default LoanAmendInProgress;
