import { VerificationDetails } from '../components/agreement/Agreement';
import { DefaultSagaParams } from '../sagas';
import { FetchAgreementUrlResponse } from '../store/agreement/actions';

export interface AgreementService {
  fetchAgreementUrl: (params: {
    applicationId: string;
    forceDownload?: boolean;
  }) => Promise<FetchAgreementUrlResponse>;
  downloadAgreementBySigningId: (params: {
    signingId: string;
    verificationDetails: VerificationDetails;
  }) => Promise<string>;
}

export class AgreementServiceError extends Error {
  credentialsWrong: boolean;

  constructor(message: string, credentialsWrong: boolean) {
    super(message);
    this.credentialsWrong = credentialsWrong;
  }
}

export enum AgreementServiceErrorMessage {
  CREDIT_AGREEMENT_NOT_READY = 'Credit agreement not ready to be downloaded',
  CREDIT_AGREEMENT_DOWNLOAD_FAILED = 'Failed to download credit agreement'
}

export const getAgreementService = ({
  apiClient
}: DefaultSagaParams): AgreementService => ({
  fetchAgreementUrl: async ({ applicationId, forceDownload }) => {
    try {
      const {
        data
      } = await apiClient.post(
        `protected/applications/${applicationId}/credit-agreement`,
        { forceDownload }
      );

      return data;
    } catch (err) {
      if (err.response.status === 409) {
        throw new Error(
          AgreementServiceErrorMessage.CREDIT_AGREEMENT_NOT_READY
        );
      }
      throw new Error(
        AgreementServiceErrorMessage.CREDIT_AGREEMENT_DOWNLOAD_FAILED
      );
    }
  },
  downloadAgreementBySigningId: async ({
    signingId,
    verificationDetails
  }): Promise<string> => {
    try {
      const { data } = await apiClient.post(
        'download',
        {
          signingId,
          verificationDetails
        },
        {
          headers: { Accept: 'application/pdf' },
          responseType: 'arraybuffer'
        }
      );
      return data;
    } catch (err) {
      const credentialsWrong = err.response.status === 404;
      throw new AgreementServiceError(
        'Cannot download agreement',
        credentialsWrong
      );
    }
  }
});
