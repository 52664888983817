import { Box, Message, NovunaHeading, Text } from 'compass-design';
import React from 'react';

const PendingPayoutCard = () => (
  <Box mt={6} data-test-id='application-summary-pending-payout-card'>
    <Message variant='warning' restrictedWidth={false}>
      <NovunaHeading as='h3' mb={1}>
        Payout pending
      </NovunaHeading>
      <Text>
        This application cannot be cancelled when the status is payout_pending.
      </Text>
      <Text>
        The application status will shortly change to paid at which point you
        will be able to cancel, so please try later.
      </Text>
    </Message>
  </Box>
);

export default PendingPayoutCard;
