import { CustomerSatisfactionErrorMessages } from 'hitachi-retail-core/build/enums/customerSatisfaction';
import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { resetStore, ResetStoreAction } from '../actions';
import { satisfactionFeedback } from './actions';

export type SatisfactionFeedbackAction = ActionType<
  typeof satisfactionFeedback
>;

interface SatisfactionFeedback {
  complete: boolean;
  sent: boolean;
  error: boolean;
  alreadySubmitted: boolean;
}

export interface State {
  feedback: SatisfactionFeedback;
  processing: boolean;
}

type Action = SatisfactionFeedbackAction | ResetStoreAction;

export const initialState: State = {
  feedback: {
    alreadySubmitted: false,
    complete: false,
    sent: false,
    error: false
  },
  processing: false
};

export const reducer: Reducer<State, Action> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(satisfactionFeedback.request): {
      const feedback: SatisfactionFeedback = {
        ...state.feedback,
        sent: true,
        error: false
      };

      return {
        ...state,
        feedback,
        processing: true
      };
    }

    case getType(satisfactionFeedback.success): {
      const feedback: SatisfactionFeedback = {
        ...state.feedback,
        complete: true
      };

      return {
        ...state,
        feedback,
        processing: false
      };
    }

    case getType(satisfactionFeedback.failure): {
      const { message } = action.payload;
      const feedback: SatisfactionFeedback = {
        ...state.feedback,
        alreadySubmitted:
          message ===
          CustomerSatisfactionErrorMessages.FeedbackAlreadySubmitted,
        error: true
      };

      return {
        ...state,
        feedback,
        processing: false
      };
    }

    case getType(resetStore):
      return initialState;

    default:
      return state;
  }
};
