import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import PreviousApplications, {
  PreviousApplicationsPropsFromDispatch,
  PreviousApplicationsPropsFromState
} from '../../components/search/PreviousApplications';
import { RootState } from '../../store';
import { closeNotification } from 'store/ui/actions';
import {
  SearchApplicationsActionProps,
  fetchPreviousApplications,
  resetSearchApplicationsAction,
  searchApplicationsAction
} from '../../store/previousApplications/actions';

export const mapStateToProps = ({
  previousApplications: { loading, previousApplications, totalCount },
  router: { location },
  ui: { notification }
}: RootState): PreviousApplicationsPropsFromState => ({
  applications: previousApplications ?? [],
  totalCount: totalCount ?? previousApplications?.length ?? 0,
  isLoading: loading,
  location,
  hasNotification: Boolean(notification?.message)
});

export const mapDispatchToProps = (
  dispatch: Dispatch
): PreviousApplicationsPropsFromDispatch => ({
  loadApplications: () => {
    dispatch(fetchPreviousApplications.request());
  },
  searchApplications: ({
    term,
    limit,
    offset
  }: SearchApplicationsActionProps) => {
    dispatch(searchApplicationsAction.request({ term, limit, offset }));
  },
  resetApplicationStore: () => {
    dispatch(resetSearchApplicationsAction());
  },
  clearNotifications: hasNotifications => {
    if (hasNotifications) {
      dispatch(closeNotification());
    }
  }
});

export const mergeProps = (
  state: PreviousApplicationsPropsFromState,
  dispatch: PreviousApplicationsPropsFromDispatch
) => ({ ...state, ...dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(PreviousApplications);
