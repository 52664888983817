import { call, put, takeEvery } from 'redux-saga/effects';
import { getType, isOfType } from 'typesafe-actions';
import {
  ResendEsatEmailService,
  resendEsatEmailService
} from '../../services/resendEsatEmailService';
import { resendEsatNote } from '../../store/previousApplicationDetail/actions';
import { ResendEsatNoteAction } from '../../store/previousApplicationDetail/reducer';
import { NotificationType, showNotification } from '../../store/ui/actions';
import { DefaultSagaParams } from '../index';

interface ResendEsatNoteEmailSagaParams {
  esatEmailService: ResendEsatEmailService;
}

export const resendEsatNoteEmailSaga = ({
  esatEmailService
}: ResendEsatNoteEmailSagaParams) =>
  function*(action: ResendEsatNoteAction) {
    if (isOfType(getType(resendEsatNote.request), action)) {
      const { id } = action.payload;
      try {
        yield call(esatEmailService.resendEmail, id);
        yield put(resendEsatNote.success());
        yield put(
          showNotification({
            message: 'Satisfaction note re-sent.',
            type: NotificationType.SUCCESS
          })
        );
      } catch (err) {
        yield put(resendEsatNote.failure(err));
        yield put(
          showNotification({
            message: err.message,
            type: NotificationType.DANGER
          })
        );
      }
    }
  };

export const resendEsatNoteEmailSagaWatcher = ({
  apiClient
}: DefaultSagaParams) =>
  function*() {
    const esatEmailService = resendEsatEmailService({ apiClient });

    yield takeEvery(
      getType(resendEsatNote.request),
      resendEsatNoteEmailSaga({ esatEmailService })
    );
  };
