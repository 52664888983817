import {
  Box,
  Button,
  Message,
  NovunaHeading,
  NovunaTextButton,
  Text
} from 'compass-design';
import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import { RevertibleVersionDetails } from 'hitachi-retail-core/build/schemas/financeApplication';
import React, { useCallback, useState } from 'react';
import RevertApplicationModal from '../../../../../components/search/RevertApplicationModal';
import FeatureToggle from '../../../../../containers/meta/FeatureToggle';
import { PreviousApplicationDetail } from '../../../../../store/previousApplicationDetail/actions';

export interface LoanAmendCardProps {
  application?: PreviousApplicationDetail;
  revertApplicationVersionTo: (id: string, versionId?: string) => void;
  resumeApplication: () => void;
}

const LoanAmendedCard: React.FC<LoanAmendCardProps> = ({
  application,
  revertApplicationVersionTo,
  resumeApplication
}) => {
  const [revertOpen, setRevertOpen] = useState(false);
  const removeModal = useCallback(() => setRevertOpen(false), [setRevertOpen]);

  if (!application) {
    return null;
  }

  const { id, revertibleVersion, canResume } = application;
  const canRevert = !!revertibleVersion?.id;

  if (!canRevert) {
    return null;
  }

  return (
    <FeatureToggle feature={CompassFeature.LOAN_AMEND_AFTER_SIGNING}>
      <Message
        variant='info'
        restrictedWidth={false}
        data-test-id='application-amended-card'>
        <NovunaHeading as='h2' mb={1}>
          Loan amended
        </NovunaHeading>
        <Text>
          The details of this loan have been amended since it was last signed.
          If the customer wants to proceed with the amendment they can do this
          by signing the new agreement.
        </Text>
        <Text mt={2}>
          If the amendment is no longer required, you can revert to the{' '}
          <NovunaTextButton
            data-test-id='revert-application-cta'
            onClick={event => {
              event.preventDefault();
              setRevertOpen(true);
            }}>
            previous signed agreement
          </NovunaTextButton>
          .
        </Text>
        <Box mt={4}>
          {canResume ? (
            <Button
              isJumbo
              data-test-id='compass-sign-amendment'
              onClick={event => {
                event.preventDefault();
                resumeApplication();
              }}>
              Sign agreement
            </Button>
          ) : null}
        </Box>
      </Message>
      <RevertApplicationModal
        show={revertOpen}
        revertibleVersionDetails={revertibleVersion as RevertibleVersionDetails}
        revertApplicationVersionTo={() => {
          revertApplicationVersionTo(id, revertibleVersion?.id);
          setRevertOpen(false);
        }}
        removeModal={removeModal}
      />
    </FeatureToggle>
  );
};

export default LoanAmendedCard;
