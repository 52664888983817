import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ProtectedRoute, {
  ProtectedRoutePropsFromDispatch,
  ProtectedRoutePropsFromState
} from '../../components/meta/ProtectedRoute';
import { RootState } from '../../store';
import { sessionCheck } from '../../store/user/actions';

export const mapStateToProps = (
  state: RootState
): ProtectedRoutePropsFromState => ({
  isLoggedIn: state.user.loggedIn,
  isDirectAppLoggedIn: state.user.directAppLoggedIn,
  sessionCheckLoading: state.user.sessionCheckLoading,
  enabledFeatures: state.config.enabledFeatures
});

export const mapDispatchToProps = (
  dispatch: Dispatch
): ProtectedRoutePropsFromDispatch => ({
  getLoginStatus: () => dispatch(sessionCheck.request())
});

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
