import { Decimal } from 'hitachi-retail-core/build/utils/decimal';
import { DeepPartial } from 'hitachi-retail-core/build/utils/deepPartial';
import { PreviousApplicationDetail } from 'store/previousApplicationDetail/actions';
import { formatStatus } from 'hitachi-retail-core/build/utils/formatStatus';
import { formatCurrency, formatDate, formatPercent } from 'utils/formatters';
import { getInterestPayable } from 'hitachi-retail-core/build/finance';
import { selectRepaymentAmount } from 'hitachi-retail-core/build/selectors';
import { FinanceApplication } from 'hitachi-retail-core';
import { transformServiceTypeDescription } from 'hitachi-retail-core/build/application/financeProduct';

export enum DetailsRowTitle {
  STATUS = 'Status',
  RETAILER_NAME = 'Retailer',
  RETAILER_NUMBER = 'Supplier',
  CREATED = 'Created',
  CREATED_BY = 'Created by',
  UPDATED = 'Updated',
  APPLICANT_NAME = 'Name',
  ADDRESS_ROW = 'Address',
  GOODS_DESCRIPTION = 'Goods description',
  ORDER_REF = 'Order reference',
  GOODS_AMOUNT = 'Goods amount',
  PENDING_GOODS_AMOUNT = 'Pending goods amount',
  DEPOSIT_AMOUNT = 'Deposit amount',
  PENDING_DEPOSIT_AMOUNT = 'Pending deposit amount',
  LOAN_AMOUNT = 'Loan amount',
  INTEREST_PAYABLE = 'Interest payable',
  TOTAL_AMOUNT_PAYABLE = 'Total amount payable',
  PRODUCT = 'Product',
  APR = 'APR',
  DEFERRAL_PERIOD = 'Deferral period',
  INSTALMENTS = 'Repayments',
  MONTHLY_REPAYMENTS = 'Monthly repayments',
  COOLING_OFF_EXPIRY = 'Cooling off expiry',
  CUSTOMER_PRESENT = 'Was customer present?',
  DELIVERY_ADDRESS_ROW = 'Delivery address'
}

export interface QuoteDetailProps {
  deposit?: Decimal;
  pendingDeposit?: Decimal;
  goodsAmount?: Decimal;
  pendingGoodsAmount?: Decimal;
  instalments?: number;
  loanAmount?: Decimal;
  goodsDescription?: string;
  orderReference?: string;
  totalAmountPayable?: Decimal;
  apr?: Decimal;
}

export interface GetRowDetailsText {
  title: DetailsRowTitle;
  application: DeepPartial<PreviousApplicationDetail>;
  quote: QuoteDetailProps;
}

const getRowDetailsText = ({
  title,
  application: {
    status,
    retailer,
    createdBy,
    created,
    updated,
    applicantName,
    document,
    coolingOffEnd
  },
  quote: {
    goodsDescription,
    orderReference,
    goodsAmount,
    pendingGoodsAmount,
    deposit,
    pendingDeposit,
    loanAmount,
    totalAmountPayable,
    apr,
    instalments
  }
}: GetRowDetailsText): string => {
  switch (title) {
    case DetailsRowTitle.STATUS:
      return formatStatus(status) || 'N/A';
    case DetailsRowTitle.RETAILER_NAME:
      return retailer?.name || 'N/A';
    case DetailsRowTitle.RETAILER_NUMBER:
      return retailer?.supplierNumber || 'N/A';
    case DetailsRowTitle.CREATED:
      return (
        (created && formatDate({ value: created || '', showTime: true })) ||
        'N/A'
      );
    case DetailsRowTitle.CREATED_BY:
      return createdBy || 'N/A';
    case DetailsRowTitle.UPDATED:
      return (
        (updated && formatDate({ value: updated, showTime: true })) || 'N/A'
      );
    case DetailsRowTitle.APPLICANT_NAME:
      return applicantName || 'N/A';
    case DetailsRowTitle.GOODS_DESCRIPTION:
      return goodsDescription || 'N/A';
    case DetailsRowTitle.ORDER_REF:
      return orderReference ?? 'N/A';
    case DetailsRowTitle.GOODS_AMOUNT:
      return (goodsAmount && formatCurrency(goodsAmount)) || 'N/A';
    case DetailsRowTitle.PENDING_GOODS_AMOUNT:
      return (
        (pendingGoodsAmount && formatCurrency(pendingGoodsAmount)) || 'N/A'
      );
    case DetailsRowTitle.DEPOSIT_AMOUNT:
      return (deposit && formatCurrency(deposit)) || 'N/A';
    case DetailsRowTitle.PENDING_DEPOSIT_AMOUNT:
      return (pendingDeposit && formatCurrency(pendingDeposit)) || 'N/A';
    case DetailsRowTitle.LOAN_AMOUNT:
      return (loanAmount && formatCurrency(loanAmount)) || 'N/A';
    case DetailsRowTitle.INTEREST_PAYABLE: {
      if (!goodsAmount || !totalAmountPayable) {
        return 'N/A';
      }

      const interestPayable = getInterestPayable({
        goodsAmount,
        totalAmountPayable
      });

      return formatCurrency(interestPayable);
    }
    case DetailsRowTitle.TOTAL_AMOUNT_PAYABLE:
      return (
        (totalAmountPayable && formatCurrency(totalAmountPayable)) || 'N/A'
      );
    case DetailsRowTitle.PRODUCT:
      return (
        (document?.product?.serviceType &&
          transformServiceTypeDescription.get(document.product.serviceType)) ||
        'N/A'
      );
    case DetailsRowTitle.APR:
      return (apr && formatPercent(apr)) || 'N/A';
    case DetailsRowTitle.DEFERRAL_PERIOD: {
      const period = document?.loanRepayment?.deferralPeriod;

      return (period && `${period} month${period < 2 ? '' : 's'}`) || 'N/A';
    }
    case DetailsRowTitle.INSTALMENTS:
      return instalments ? `${instalments} months` : 'N/A';
    case DetailsRowTitle.MONTHLY_REPAYMENTS: {
      let repaymentAmount: Decimal | undefined;
      try {
        repaymentAmount = selectRepaymentAmount(
          document as DeepPartial<FinanceApplication>
        );
      } catch {
        return 'N/A';
      }

      return repaymentAmount
        ? `${instalments} x ${formatCurrency(repaymentAmount)}`
        : 'N/A';
    }
    case DetailsRowTitle.COOLING_OFF_EXPIRY:
      return coolingOffEnd
        ? formatDate({ value: coolingOffEnd, showTime: true })
        : 'N/A';
    case DetailsRowTitle.CUSTOMER_PRESENT: {
      return document?.customerPresent ? 'Yes' : 'No';
    }
    default:
      return 'N/A';
  }
};

export default getRowDetailsText;
