import { DefaultSagaParams } from '../sagas';
import { AppConfig } from 'hitachi-retail-core';

export interface ConfigService {
  getConfig: () => Promise<AppConfig>;
}

export const getConfigService = ({
  apiClient
}: DefaultSagaParams): ConfigService => ({
  getConfig: async (): Promise<AppConfig> => {
    const response = await apiClient.get<AppConfig>('app-config');

    return response.data;
  }
});
