import { AxiosInstance } from 'axios';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory, History } from 'history';
import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import React from 'react';
import ReactGA from 'react-ga4';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Persistor } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import ApplicantApp from '../../applicantApp/pages/ApplicantApp';
import Config from '../../containers/common/Config';
import { routes } from '../../routes';
import {
  BankAccountCheckService,
  getBankAccountCheckService,
  getBankCheckEndpoints
} from '../../services/bankCheck';
import { AppType, getAppType, getStore, Store } from '../../store';
import {
  getMockAddressLookupService,
  getMockApplicantConfigService,
  getMockApplicationSnapshotService,
  getMockApplicationsService,
  getMockAuthService,
  getMockBankAccountCheckService,
  getMockConfigService,
  getMockDecisionService,
  getMockDocumentDownloadService,
  getMockMailOrderApplicationService,
  getMockProductService,
  getMockRetailerConfigService,
  getMockRetailerService,
  getMockGoodsListService,
  getMockSendPrecontractInfoService,
  getMockUserService,
  getMockUsersService,
  getMockSessionCheckService
} from '../../tests/mocks';
import ScrollToTop from '../../components/meta/ScrollToTop';
import {
  AppDomainConfig,
  useAppDomainConfig
} from '../../components/common/useAppDomainConfig';
import { ApplicationsService } from '../../services/applicationsService';
import {
  DocumentDownloadService,
  getDocumentDownloadService
} from '../../applicantApp/services/documentDownloadService';
import {
  ApplicationSnapshotService,
  getApplicationSnapshotService
} from '../../services/applicationSnapshotService';
import RetailerApp from 'retailerApp/pages/RetailerApp';
import {
  getOnlineDepositsService,
  OnlineDepositsService
} from '../../services/onlineDepositsService';
import getMockOnlineDepositsService from '../../tests/getMockOnlineDepositService';
import {
  getSessionCheckService,
  SessionCheckService
} from '../../applicantApp/services/sessionCheckService';
import { DemoWatermark } from 'shared/components/LoanInformation/DemoWatermark';
import { getGoogleAnalyticsTrackingId } from 'utils/google';
import { RetailEnv } from 'utils/envs';

interface EnhancedWrapperProps {
  appType: AppType;
  appDomainConfig?: AppDomainConfig;
}

export const useMockDependencies =
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_USE_MOCKS === 'true';

if (useMockDependencies) {
  console.log('Using mocked dependencies due to env var configuration');
}

export interface ContextServices {
  bankAccountCheckService: BankAccountCheckService;
  documentDownloadService: DocumentDownloadService;
  applicationSnapshotService: ApplicationSnapshotService;
  onlineDepositsService: OnlineDepositsService;
  sessionCheckService: SessionCheckService;
}

const notInitialised = () => {
  throw Error('not initialised');
};

export const ServicesContext = React.createContext<ContextServices>({
  applicationSnapshotService: {
    createSnapshot: notInitialised,
    revertApplication: notInitialised
  },
  bankAccountCheckService: {
    updateBankDetails: notInitialised,
    validateBankDetails: notInitialised
  },
  documentDownloadService: {
    fetchDraftDownloadUrl: notInitialised,
    fetchPrecontractDownloadUrl: notInitialised
  },
  onlineDepositsService: {
    depositPaymentComplete: notInitialised,
    initiateDeposit: notInitialised
  },
  sessionCheckService: {
    checkSession: notInitialised
  }
});

interface GetContextServicesInput {
  useMockDependencies: boolean;
  appType: AppType;
  apiClient: AxiosInstance;
}

const getContextServices = ({
  useMockDependencies,
  appType,
  apiClient
}: GetContextServicesInput): ContextServices => {
  const bankCheckEndpoints = getBankCheckEndpoints(appType);
  const bankAccountCheckService = useMockDependencies
    ? getMockBankAccountCheckService()
    : getBankAccountCheckService({ apiClient, bankCheckEndpoints });

  const documentDownloadService = useMockDependencies
    ? getMockDocumentDownloadService()
    : getDocumentDownloadService({ baseUrl: '/mo/application', apiClient });

  const applicationSnapshotService = useMockDependencies
    ? getMockApplicationSnapshotService()
    : getApplicationSnapshotService({ apiClient });

  const onlineDepositsService = useMockDependencies
    ? getMockOnlineDepositsService()
    : getOnlineDepositsService(apiClient);

  const sessionCheckService = useMockDependencies
    ? getMockSessionCheckService()
    : getSessionCheckService({ apiClient });

  return {
    bankAccountCheckService,
    documentDownloadService,
    applicationSnapshotService,
    onlineDepositsService,
    sessionCheckService
  };
};

const EnhancedWrapper: React.FC<EnhancedWrapperProps> = ({
  children,
  appType,
  appDomainConfig
}) => {
  const {
    store,
    history,
    persistor,
    apiClient
  }: WithProvidersOptions = getStore({
    appType,
    appDomainConfig,
    history: createBrowserHistory(),
    ...(useMockDependencies
      ? {
          overrideAuthService: getMockAuthService(),
          overrideMailOrderApplicationService: getMockMailOrderApplicationService(),
          overrideSendPrecontractInfoService: getMockSendPrecontractInfoService(),
          overrideDocumentDownloadService: getMockDocumentDownloadService(),
          overrideBankAccountCheckService: getMockBankAccountCheckService(),
          overrideConfigService: getMockConfigService(),
          overrideAddressLookupService: getMockAddressLookupService(),
          overrideDecisionService: getMockDecisionService(),
          overrideUserService: getMockUserService(),
          overrideRetailerService: getMockRetailerService(),
          overrideProductService: getMockProductService(),
          overrideRetailerConfigService: getMockRetailerConfigService(),
          overrideApplicationsService: getMockApplicationsService() as ApplicationsService,
          overrideApplicantConfigService: getMockApplicantConfigService({
            features: [
              CompassFeature.MAIL_ORDER_RESUME_QUOTE,
              CompassFeature.MAIL_ORDER_SAVE_QUOTE,
              CompassFeature.MAIL_ORDER_SEND_DECISION_ACCEPT,
              CompassFeature.MO_194,
              CompassFeature.TAILORING_AFFORDABILITY_DECLINE_FEATURE,
              CompassFeature.ONLINE_DEPOSITS
            ]
          }),
          overrideUsersService: getMockUsersService(),
          overrideGoodsListService: getMockGoodsListService()
        }
      : {})
  });

  const contextServices = getContextServices({
    useMockDependencies,
    appType,
    apiClient
  });

  return (
    <ServicesContext.Provider value={contextServices}>
      <Provider store={store}>
        <PersistGate loading={true} persistor={persistor}>
          <ConnectedRouter history={history}>
            <Config>
              <ScrollToTop>
                <HelmetProvider>{children}</HelmetProvider>
              </ScrollToTop>
            </Config>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </ServicesContext.Provider>
  );
};

export const App: React.FC = () => {
  const appDomainConfig = useAppDomainConfig();
  const appType = appDomainConfig
    ? getAppType(window.location.href, appDomainConfig)
    : null;

  // Google Analytics
  const gaTrackingId = getGoogleAnalyticsTrackingId(
    appDomainConfig?.currentEnv as RetailEnv
  );
  if (gaTrackingId) {
    ReactGA.initialize(gaTrackingId);
  }

  return (
    appType && (
      <>
        <EnhancedWrapper appType={appType} appDomainConfig={appDomainConfig}>
          <DisconnectedApp appType={appType} />
        </EnhancedWrapper>
        {appDomainConfig?.currentEnv === RetailEnv.demo && <DemoWatermark />}
      </>
    )
  );
};

export interface DisconnectedAppProps {
  appType: AppType;
}

export const DisconnectedApp: React.FC<DisconnectedAppProps> = ({
  appType
}) => {
  const isMailOrderApp = appType === AppType.Applicant;

  const app = isMailOrderApp ? (
    <Route path={routes.mailOrder.index} component={ApplicantApp} />
  ) : (
    <Route path={routes.index} component={RetailerApp} />
  );

  return <Switch>{app}</Switch>;
};

interface WithProvidersOptions {
  store: Store;
  persistor: Persistor;
  history: History;
  apiClient: AxiosInstance;
}
