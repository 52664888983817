import {
  Box,
  Button,
  InputWrapper,
  Message,
  NovunaHeading,
  NovunaSpinner,
  TertiaryLink,
  TertiaryLinkDirection,
  Text,
  TextField,
  PasswordStrengthBar,
  Link,
  InfoOutline
} from 'compass-design';
import { validationMessages } from 'hitachi-retail-core';
import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import { confirmPasswordResetBodySchema } from 'hitachi-retail-core/build/schemas/confirmForgotPassword';
import { parse } from 'querystring';
import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { RouteChildrenProps, useHistory } from 'react-router';
import { LoggedOutWrapper } from 'retailerApp/components/LoggedOutWrapper';
import * as yup from 'yup';
import { AsyncStatus } from '../../../applicantApp/store/AsyncStatus';
import FeatureToggle from '../../../containers/meta/FeatureToggle';
import { routes } from '../../../routes';
import { checkPasswordValidity } from 'hitachi-retail-core/build/utils/password';
import { getContextualPageData } from '../Profile/ManagePassword/utils';
import { useSelector } from 'react-redux';
import { selectRetailer } from 'store/retailer/selectors';
import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';
import { selectRetailerSubdomain } from 'store/config/selectors';
import { StrongerPasswordGuidance } from 'components/StrongerPasswordGuidance';
import { PasswordStrengthLabel } from 'components/PasswordStrengthLabel';

export interface ConfirmForgotPasswordPropsFromState {
  retailerName: string;
  configStatus: AsyncStatus;
  errorMessage?: string;
  confirmForgotPasswordLoading: boolean;
}

export interface ConfirmForgotPasswordInput {
  retailerName: string;
  username: string;
  newPassword: string;
  verificationCode: string;
}

export interface ConfirmForgotPasswordPropsFromDispatch {
  confirmForgotPassword: (input: ConfirmForgotPasswordInput) => void;
}

export type ConfirmForgotPasswordProps = RouteChildrenProps &
  ConfirmForgotPasswordPropsFromState &
  ConfirmForgotPasswordPropsFromDispatch;

const Wrapper: React.FC = ({ children }) => {
  const history = useHistory();

  return (
    <LoggedOutWrapper
      backLink={
        <TertiaryLink
          direction={TertiaryLinkDirection.BACKWARDS}
          onClick={() => history.push(routes.login)}
          text='Sign in'
        />
      }>
      <Helmet>
        <title>Forgot password - CreditMaster3</title>
      </Helmet>
      <section
        className='compass-login-form'
        data-test-id='compass-confirm-forgot-password-wrapper'>
        {children}
      </section>
    </LoggedOutWrapper>
  );
};

export interface ConfirmForgotPasswordFormValues {
  retailerName: string;
  username: string;
  verificationCode: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

const parseSearchParam = (param: string | string[]) => {
  if (typeof param === 'string') {
    return param;
  }
  if (Array.isArray(param) && param.length > 0) {
    return param[0];
  }
  return '';
};

export const ConfirmForgotPassword: React.FC<ConfirmForgotPasswordProps> = ({
  location,
  retailerName: subdomainRetailerName,
  configStatus,
  confirmForgotPassword,
  errorMessage,
  confirmForgotPasswordLoading
}) => {
  const history = useHistory();
  const retailer = useSelector(selectRetailer);
  const subdomain = useSelector(selectRetailerSubdomain);
  const searchParams = parse(location.search.replace(/^\?/, ''));
  const { username: searchUsername, code: searchCode } = searchParams;

  const [formValues, setFormValues] = useState<ConfirmForgotPasswordFormValues>(
    {
      retailerName: '',
      username: parseSearchParam(searchUsername || ''),
      verificationCode: parseSearchParam(searchCode || ''),
      newPassword: '',
      newPasswordConfirmation: ''
    }
  );

  const [guidanceModalOpen, setGuidanceModalOpen] = useState(false);
  const [retailerError, setRetailerError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [verificationCodeError, setVerificationCodeError] = useState('');
  const [passwordConfirmationError, setPasswordConfirmationError] = useState(
    ''
  );
  const [newPasswordError, setNewPasswordError] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(true);

  const clearErrors = () => {
    setRetailerError('');
    setUsernameError('');
    setVerificationCodeError('');
    setNewPasswordError('');
    setPasswordConfirmationError('');
  };
  const passwordInfo = checkPasswordValidity({
    password: formValues.newPassword,
    inputs: getContextualPageData({
      retailer: retailer || ({ name: formValues?.retailerName } as Retailer),
      username: formValues?.username,
      subdomain
    })
  });

  const handleInputChange = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const retailerValue =
        target.name === 'retailer' ? target.value : formValues.retailerName;
      const usernameValue =
        target.name === 'username' ? target.value : formValues.username;
      const verificationCodeValue =
        target.name === 'verification-code'
          ? target.value
          : formValues.verificationCode;
      const newPasswordValue =
        target.name === 'password' ? target.value : formValues.newPassword;
      const passwordConfirmationValue =
        target.name === 'password-confirmation'
          ? target.value
          : formValues.newPasswordConfirmation;

      setFormValues({
        ...formValues,
        retailerName: retailerValue,
        username: usernameValue,
        verificationCode: verificationCodeValue,
        newPassword: newPasswordValue,
        newPasswordConfirmation: passwordConfirmationValue
      });

      clearErrors();
      if (
        passwordInfo.isValid &&
        newPasswordValue === passwordConfirmationValue
      ) {
        setDisableSubmit(false);
      }

      if (
        !passwordInfo.isValid &&
        newPasswordValue !== passwordConfirmationValue
      ) {
        setDisableSubmit(true);
      }
      if (
        passwordInfo.isValid &&
        passwordConfirmationValue.length > 0 &&
        newPasswordValue !== passwordConfirmationValue
      ) {
        setPasswordConfirmationError(
          validationMessages.INVALID_CONFIRMATION_PASSWORD_VALUE
        );
        setDisableSubmit(true);
      }
    },
    [formValues, passwordInfo.isValid]
  );

  const errorMessageString = passwordInfo.messages
    ? passwordInfo.messages
        .slice(0, 2)
        .map((message: string, index: number, arr: string[]) => {
          if (index < arr.length - 1) {
            return `${message}\n`;
          } else {
            return message;
          }
        })
        .join('')
    : '';

  const handleFormSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      clearErrors();

      const { newPasswordConfirmation: confirmation, ...payload } = formValues;

      const mergedPayload = {
        ...payload,
        ...(subdomainRetailerName
          ? { retailerName: subdomainRetailerName }
          : {})
      };

      try {
        confirmPasswordResetBodySchema.validateSync(mergedPayload, {
          abortEarly: false
        });

        confirmForgotPassword(mergedPayload);
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          const validationError = err as yup.ValidationError;
          const errors = validationError.inner;

          errors.forEach(error => {
            switch (error.path) {
              case 'retailerName':
                setRetailerError(error.message);
                break;
              case 'username':
                setUsernameError(error.message);
                break;
              case 'verificationCode':
                setVerificationCodeError(error.message);
                break;
              case 'newPassword':
                setNewPasswordError(error.message);
                break;
            }
          });
        }
      }
    },
    [formValues, confirmForgotPassword, subdomainRetailerName]
  );

  const {
    retailerName,
    username,
    verificationCode,
    newPassword,
    newPasswordConfirmation
  } = formValues;

  if (
    configStatus === AsyncStatus.Default ||
    configStatus === AsyncStatus.Loading
  ) {
    return (
      <Wrapper>
        <NovunaSpinner />
      </Wrapper>
    );
  }

  return (
    <LoggedOutWrapper
      backLink={
        <TertiaryLink
          direction={TertiaryLinkDirection.BACKWARDS}
          onClick={() => history.push(routes.login)}
          text='Sign in'
        />
      }>
      <StrongerPasswordGuidance
        modalOpen={guidanceModalOpen}
        toggleModalOpen={setGuidanceModalOpen}
        managePasswordBtn={false}
      />
      <FeatureToggle feature={CompassFeature.ENHANCED_AUTHENTICATION}>
        {errorMessage && (
          <Message
            mb={4}
            variant='error'
            data-test-id='compass-confirm-reset-service-error'>
            <NovunaHeading as='h3' mb={1}>
              Something went wrong
            </NovunaHeading>
            <Text>Please check your details and try again.</Text>
          </Message>
        )}
        <NovunaHeading
          as='h1'
          mb={3}
          data-test-id='compass-confirm-reset-title'>
          Forgot password
        </NovunaHeading>
        <form noValidate onSubmit={handleFormSubmit}>
          {!searchCode && (
            <Text mb={3} data-test-id='compass-confirm-reset-email-message'>
              We have sent a password reset code by email. Enter it below to
              reset your password.
            </Text>
          )}
          {!subdomainRetailerName && (
            <Box mb={3} data-test-id='compass-confirm-reset-retailer'>
              <InputWrapper
                id='confirm-reset-retailer'
                label='Retailer name'
                error={retailerError}
                tone={retailerError ? 'negative' : 'neutral'}>
                <TextField
                  id='confirm-reset-retailer'
                  name='retailer'
                  type='text'
                  required
                  autoCapitalize='none'
                  data-test-id='compass-confirm-reset-retailer-control'
                  value={retailerName}
                  onChange={handleInputChange}
                  tone={retailerError ? 'negative' : 'neutral'}
                />
              </InputWrapper>
            </Box>
          )}
          {!searchUsername && (
            <Box mb={3} data-test-id='compass-confirm-reset-username'>
              <InputWrapper
                id='confirm-reset-username'
                label='Username'
                error={usernameError}
                tone={usernameError ? 'negative' : 'neutral'}>
                <TextField
                  id='confirm-reset-username'
                  name='username'
                  type='text'
                  required
                  autoCapitalize='none'
                  data-test-id='compass-confirm-reset-username-control'
                  value={username}
                  onChange={handleInputChange}
                  tone={usernameError ? 'negative' : 'neutral'}
                />
              </InputWrapper>
            </Box>
          )}
          {!searchCode && (
            <Box mb={3} data-test-id='compass-confirm-reset-verification-code'>
              <InputWrapper
                id='confirm-reset-verification-code'
                label='Verification code'
                error={verificationCodeError}
                tone={verificationCodeError ? 'negative' : 'neutral'}>
                <TextField
                  id='confirm-reset-verification-code'
                  name='verification-code'
                  type='text'
                  required
                  autoCapitalize='none'
                  data-test-id='compass-confirm-reset-verification-code-control'
                  value={verificationCode}
                  onChange={handleInputChange}
                  tone={verificationCodeError ? 'negative' : 'neutral'}
                />
              </InputWrapper>
            </Box>
          )}
          <Box mb={3} data-test-id='compass-confirm-reset-new-password'>
            <InputWrapper
              id='confirm-reset-new-password'
              label={
                ((
                  <>
                    New password
                    <Link
                      href='#'
                      sx={{
                        ml: '0.5em',
                        lineHeight: '1.2em',
                        '>svg': { verticalAlign: 'middle' }
                      }}
                      onClick={() => setGuidanceModalOpen(true)}>
                      <InfoOutline
                        size={16}
                        sx={{ margin: '0', padding: '0' }}
                      />
                    </Link>
                  </>
                ) as unknown) as string
              }
              error={
                formValues.newPassword.length > 7
                  ? errorMessageString
                  : undefined
              }
              tone={
                formValues.newPassword.length > 7 && !!errorMessageString
                  ? 'negative'
                  : 'neutral'
              }>
              <TextField
                id='confirm-reset-new-password'
                name='password'
                autoComplete='new-password'
                type='password'
                required
                autoCapitalize='none'
                data-test-id='compass-confirm-reset-new-password-control'
                value={newPassword}
                onChange={handleInputChange}
                tone={newPasswordError ? 'negative' : 'neutral'}
              />
            </InputWrapper>
          </Box>
          <PasswordStrengthBar strength={passwordInfo.score || 0} />
          <PasswordStrengthLabel strength={passwordInfo.score || 0} />
          <Box
            mb={3}
            mt={2}
            data-test-id='compass-confirm-reset-new-password-confirmation'>
            <InputWrapper
              id='confirm-reset-new-password-confirmation'
              label='Confirm new password'
              error={passwordConfirmationError}
              tone={passwordConfirmationError ? 'negative' : 'neutral'}>
              <TextField
                id='confirm-reset-new-password-confirmation'
                name='password-confirmation'
                autoComplete='new-password'
                type='password'
                required
                autoCapitalize='none'
                data-test-id='compass-confirm-reset-new-password-confirmation-control'
                value={newPasswordConfirmation}
                onChange={handleInputChange}
                tone={passwordConfirmationError ? 'negative' : 'neutral'}
                disabled={!passwordInfo.isValid}
              />
            </InputWrapper>
          </Box>

          <Button
            isJumbo
            disabled={confirmForgotPasswordLoading || disableSubmit}
            data-test-id='compass-confirm-reset-password-submit'>
            Confirm
          </Button>
        </form>
      </FeatureToggle>
    </LoggedOutWrapper>
  );
};

export default ConfirmForgotPassword;
