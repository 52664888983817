import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { ApplicationsService } from '../../services/applicationsService';
import { selectApplicationId } from '../../store/save/reducer';
import {
  signApplication,
  SignApplicationResponse
} from '../../store/sign/actions';
import { saveSigningCompletePath } from '../../store/signingRedirects/actions';
import { generateApplicationPath, routes } from '../../routes';

interface GetSignApplicationSagaParams {
  applicationsService: ApplicationsService;
}

export const generateRedirectUrl = (redirectUrl: string) => {
  const url = new URL(redirectUrl);

  url.searchParams.set('sourceUrl', window.location.href);

  return url.toString();
};

export const signApplicationRedirect = (redirectUrl: string) => {
  window.location.href = generateRedirectUrl(redirectUrl);
};

export const getSignApplicationSaga = ({
  applicationsService
}: GetSignApplicationSagaParams) =>
  function*() {
    const applicationId = yield select(selectApplicationId);

    const signingCompletePath = generateApplicationPath(routes.apply.complete, {
      applicationId
    });

    try {
      const response: SignApplicationResponse = yield call(
        applicationsService.signApplication,
        applicationId,
        false
      );

      if (response) {
        yield put(
          saveSigningCompletePath({
            signingCompletePath
          })
        );
        yield put(signApplication.success());
        yield call(signApplicationRedirect, response.redirectUrl);
      } else {
        yield put(
          signApplication.failure(
            new Error(
              'Sign application API response did not contain expected values'
            )
          )
        );
      }
    } catch (err) {
      yield put(
        signApplication.failure(new Error('Sign application request failed'))
      );
    }
  };

export const getSignApplicationSagaWatcher = ({
  applicationsService
}: GetSignApplicationSagaParams) =>
  function*() {
    yield takeEvery(
      getType(signApplication.request),
      getSignApplicationSaga({ applicationsService })
    );
  };
