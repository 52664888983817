import { ProductConfig } from 'hitachi-retail-core/build/api/productConfig';
import { ActionType, createAsyncAction } from 'typesafe-actions';
import { SupplierNumber } from '../retailer/reducer';
import * as product from './constants';

export interface FetchProductsForRetailerPayload {
  supplierNumber?: SupplierNumber;
}

export const fetchProductsForRetailer = createAsyncAction(
  product.FETCH_PRODUCTS_FOR_RETAILER_REQUEST,
  product.FETCH_PRODUCTS_FOR_RETAILER_SUCCESS,
  product.FETCH_PRODUCTS_FOR_RETAILER_FAILURE
)<FetchProductsForRetailerPayload | void, ProductConfig[], Error>();

export type ProductsForRetailerAction = ActionType<
  typeof fetchProductsForRetailer
>;
