import React from 'react';
import { SelectField, Label } from 'compass-design';
import { GoodsListItem } from 'hitachi-retail-core';

interface GoodsListDropdownProps {
  goodsList: GoodsListItem[];
  goodsDescription: string;
  disabled?: boolean;
  error?: string;
  bold?: boolean;
  setGoodsDetails: (value: string) => void;
}

const GoodsListDropdown = ({
  goodsList,
  goodsDescription,
  disabled = false,
  error,
  bold,
  setGoodsDetails
}: GoodsListDropdownProps) => (
  <>
    <Label
      mb={2}
      htmlFor='goods-description-selector'
      id='goodsDescription_label'
      sx={{ fontSize: 2, fontWeight: bold ? 'bold' : 'normal' }}>
      Goods description
    </Label>
    <SelectField
      id='goods-description-selector'
      name='goods-description-selector'
      data-test-id='goods-description-selector'
      defaultValue={goodsDescription}
      onChange={event => setGoodsDetails(event.target.value)}
      labelSx={{
        color:
          !goodsDescription || goodsDescription === '' ? '#737373' : '#1a1a1a'
      }}
      tone={error ? 'negative' : 'neutral'}
      disabled={disabled}
      aria-labelledby='goodsDescription_label'>
      <option value=''>Select goods</option>
      {goodsList.map(({ id, value }) => (
        <option value={value} key={id}>
          {value}
        </option>
      ))}
    </SelectField>
  </>
);

export default GoodsListDropdown;
