import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'store';
import { fetchRetailerConfig } from 'store/retailerConfig/actions';
import RetailerApp, { RetailerAppPropsFromDispatch } from './RetailerApp';

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (
  dispatch: Dispatch
): RetailerAppPropsFromDispatch => ({
  fetchConfig: () => dispatch(fetchRetailerConfig.request())
});

export default connect(mapStateToProps, mapDispatchToProps)(RetailerApp);
