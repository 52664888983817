import React from 'react';
import { Helmet } from 'react-helmet-async';
import {
  ResetPasswordForm,
  ResetPasswordFormPropsFromDispatch,
  ResetPasswordFormPropsFromState
} from './components/ResetPasswordForm';

export type ResetPasswordProps = ResetPasswordFormPropsFromState &
  ResetPasswordFormPropsFromDispatch;

export const ResetPassword: React.FunctionComponent<ResetPasswordProps> = ({
  retailerName,
  processing,
  errorMessage,
  success,
  onSubmit
}) => {
  return (
    <>
      <Helmet>
        <title data-test-id='reset-password-head'>
          Reset Password - CreditMaster3
        </title>
      </Helmet>
      <ResetPasswordForm
        data-test-id='reset-password-form'
        retailerName={retailerName}
        processing={processing}
        errorMessage={errorMessage}
        success={success}
        onSubmit={onSubmit}
      />
    </>
  );
};
