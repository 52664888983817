const isNumber = (number: any) => {
  if (typeof number !== 'string') {
    return false;
  }
  if (number.length === 0) {
    return null;
  }
  return number.match(/^[\d]*(\.\d{0,2})?$/);
};

export default isNumber;
