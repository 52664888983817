import { call, put, select, takeLeading } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { SendLoanAdvanceInfoService } from '../../services/sendLoanAdvanceInfoService';
import {
  sendLoanAdvanceInfo,
  SendLoanAdvanceInfoAction
} from '../../store/sendLoanAdvanceInformation/actions';
import { selectEmailSentAutomatically } from '../../store/sendLoanAdvanceInformation/reducer';

export interface SendLoanAdvanceInfoSagaParams {
  sendLoanAdvanceInfoService: SendLoanAdvanceInfoService;
}

export const getSendLoanAdvanceInfoSaga = ({
  sendLoanAdvanceInfoService
}: SendLoanAdvanceInfoSagaParams) =>
  function*(action: SendLoanAdvanceInfoAction) {
    if (action.type !== getType(sendLoanAdvanceInfo.request)) {
      return;
    }

    const { id } = action.payload;

    const alreadySent: boolean = yield select(selectEmailSentAutomatically);

    try {
      if (!alreadySent) {
        yield call(sendLoanAdvanceInfoService.sendLoanAdvanceInfo, id);
        yield put(sendLoanAdvanceInfo.success());
      }
    } catch (err) {
      yield put(sendLoanAdvanceInfo.failure(err));
    }
  };

export type SendLoanAdvanceInfoSaga = ReturnType<
  typeof getSendLoanAdvanceInfoSaga
>;

export const getSendLoanAdvanceInfoSagaWatcher = ({
  sendLoanAdvanceInfoSaga
}: {
  sendLoanAdvanceInfoSaga: SendLoanAdvanceInfoSaga;
}) =>
  function*() {
    yield takeLeading(
      getType(sendLoanAdvanceInfo.request),
      sendLoanAdvanceInfoSaga
    );
  };
