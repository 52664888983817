import React from 'react';
import { LoanDetails } from '../../utils/loanDetails/getLoanDetails';
import { getIsTailoringExpired } from '../../../utils/expiryDate';
import DecisionAccept, {
  DecisionAcceptProps
} from '../../pages/ApplicationDecision/components/DecisionAccept/DecisionAccept';
import IncreaseLoan, {
  ReduceTerm
} from './DecisionAffordabilityAccept/DecisionAffordabilityAccept';
import { EditLoanCardStateProps } from '../EditLoanCardState/EditLoanCardState';
import { NewOfferComparisonCardProps } from '../MixingDeck/MixingDeck';
import { AffordabilityAcceptProps } from 'retailerApp/utils/loanDetails/getAffordabilityAccept';

interface DecisionAcceptPickerProps {
  tailoringExpiryDate: string | undefined;
  affordabilityAccept: AffordabilityAcceptProps | undefined;
  decisionAccept: DecisionAcceptProps;
  isLoanAmend: boolean;
}

interface TailoringOffers {
  increaseLoan?: EditLoanCardStateProps;
  reduceTerm?: NewOfferComparisonCardProps;
}

const getTailoringOffers = (
  loanDetails: LoanDetails | undefined,
  tailoringExpiryDate: string | undefined,
  isLoanAmend: boolean
): TailoringOffers | undefined => {
  if (
    loanDetails &&
    (loanDetails.increaseLoanOffer || loanDetails.reduceTermOffer) &&
    loanDetails.originalOffer &&
    !loanDetails.tailoringOptionSelected &&
    !isLoanAmend &&
    !getIsTailoringExpired({
      tailoringExpiryDate
    })
  ) {
    return {
      increaseLoan: loanDetails.increaseLoanOffer,
      reduceTerm: loanDetails.reduceTermOffer
    };
  }
  return;
};

const DecisionAcceptPicker = ({
  tailoringExpiryDate,
  affordabilityAccept,
  decisionAccept,
  isLoanAmend
}: DecisionAcceptPickerProps) => {
  const tailoringOffers = getTailoringOffers(
    affordabilityAccept?.loanDetails,
    tailoringExpiryDate,
    isLoanAmend
  );

  let DecisionComponent;

  if (tailoringOffers) {
    const { loanDetails } = affordabilityAccept!;
    DecisionComponent = tailoringOffers.increaseLoan ? (
      <IncreaseLoan
        loanDetails={loanDetails}
        tailoringExpiryDate={tailoringExpiryDate}
        goodsDescription={loanDetails?.goodsDescription}
        editLoanProps={loanDetails?.increaseLoanOffer}
        deferralPeriod={loanDetails?.deferralPeriod}
        affordabilityAcceptProps={affordabilityAccept}
        isIncreaseLoan
      />
    ) : (
      <ReduceTerm
        tailoringExpiryDate={tailoringExpiryDate}
        affordabilityAcceptProps={affordabilityAccept!}
      />
    );
  } else {
    DecisionComponent = <DecisionAccept decisionAcceptProps={decisionAccept} />;
  }
  return DecisionComponent;
};

export default DecisionAcceptPicker;
