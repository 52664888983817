import {
  ApplicantFinancialDetails,
  ApplicantPersonalDetails,
  spouseEmploymentStatusRequired
} from 'hitachi-retail-core/build/schemas/applicantDetails';
import { IncompleteFinanceApplication } from 'hitachi-retail-core/build/schemas/financeApplication';
import { UiSchema } from 'react-jsonschema-form';

const financialDetailsUpdate = (
  formData: IncompleteFinanceApplication,
  uiSchema: UiSchema
) => {
  const { personalDetails, financialDetails } = formData;

  if (personalDetails && financialDetails) {
    const { maritalStatus } = personalDetails as ApplicantPersonalDetails;
    const { employmentStatus } = financialDetails as ApplicantFinancialDetails;
    if (spouseEmploymentStatusRequired(employmentStatus, maritalStatus)) {
      uiSchema.financialDetails.spouseEmploymentStatus['ui:widget'] = 'radio';
    } else {
      uiSchema.financialDetails.spouseEmploymentStatus['ui:widget'] = 'hidden';
      financialDetails.spouseEmploymentStatus = undefined;
    }
  }
};

export default financialDetailsUpdate;
