import { DefaultSagaParams } from '../sagas';
import { ApplicationsService, SaveResponse } from './applicationsService';
import { PreviousApplicationDetail } from '../store/previousApplicationDetail/actions';

export type MailOrderApplicationService = Pick<
  ApplicationsService,
  'getById' | 'saveApplication'
>;

export const getMailOrderApplicationService = ({
  apiClient
}: DefaultSagaParams): MailOrderApplicationService => ({
  getById: async ({ id }): Promise<PreviousApplicationDetail> => {
    try {
      const { data } = await apiClient.get(`/mo/application/${id}`);

      return data;
    } catch (err) {
      throw new Error('Cannot retrieve application');
    }
  },
  saveApplication: async payload => {
    let response;
    try {
      response = await apiClient.post<SaveResponse>(
        `/mo/application/${payload.id}`,
        payload
      );
    } catch {
      throw new Error('API call failed');
    }
    const appId = response.data && response.data.id;
    if (!appId) {
      throw new Error('No application ID returned in API response');
    }

    return appId;
  }
});
