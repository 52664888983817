import { createAsyncAction } from 'typesafe-actions';
import {
  FETCH_MAIL_ORDER_CONFIG_FAILURE,
  FETCH_MAIL_ORDER_CONFIG_REQUEST,
  FETCH_MAIL_ORDER_CONFIG_SUCCESS
} from './constants';
import { ApplicantConfig } from 'hitachi-retail-core';

export const fetchMailOrderConfig = createAsyncAction(
  FETCH_MAIL_ORDER_CONFIG_REQUEST,
  FETCH_MAIL_ORDER_CONFIG_SUCCESS,
  FETCH_MAIL_ORDER_CONFIG_FAILURE
)<void, ApplicantConfig, Error>();
