import { FieldProps } from 'react-jsonschema-form';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AddressFinder, {
  AddressFinderPropsFromDispatch,
  AddressFinderPropsFromState
} from '../components/form/fields/AddressFinder';
import { RootState } from '../store';
import { getAddresses } from '../store/address/actions';

const getStateNamespace = (props: FieldProps) => {
  return props.idSchema.$id;
};

export const mapStateToProps = (
  state: RootState,
  ownProps: FieldProps
): AddressFinderPropsFromState => {
  const namespace = getStateNamespace(ownProps);
  const namespaceState = state.address && state.address[namespace];

  return namespaceState
    ? {
        addressResults: namespaceState.addressResults,
        serviceErrorMessage: namespaceState.errorMessage,
        processing: namespaceState.processing
      }
    : {};
};

export const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: FieldProps
): AddressFinderPropsFromDispatch => ({
  findByPostcode: (postcode: string) =>
    dispatch(
      getAddresses.request({
        postcode,
        meta: { namespace: getStateNamespace(ownProps) }
      })
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressFinder);
