import {
  AdvancedRadioButtonGroup,
  Box,
  InputWrapper,
  RadioGroup,
  RadioGroupOption
} from 'compass-design';
import { Decimal } from 'hitachi-retail-core/build/utils/decimal';
import { FinanceApplication } from 'hitachi-retail-core';
import {
  getInterestPayable,
  getLoanAmount,
  getRepaymentAmount,
  getTotalAmountPayable
} from 'hitachi-retail-core/build/finance';
import {
  selectApr,
  selectDeferralPeriod,
  selectDeposit,
  selectGoodsAmount
} from 'hitachi-retail-core/build/selectors';
import { formatCurrency } from 'hitachi-retail-core/build/utils/currency';
import { DeepPartial } from 'hitachi-retail-core/build/utils/deepPartial';
import { JSONSchema6 } from 'json-schema';
import React, { useEffect, useMemo } from 'react';
import { FieldProps, FormValidation, IdSchema } from 'react-jsonschema-form';
import { getFieldError } from '../../../form/helpers';
import { pluraliseString } from '../../../utils/formatters';
import { QuoteFormContext } from '../../Quote';

type LoanRepaymentFields = DeepPartial<FinanceApplication['loanRepayment']>;

export type LoanRepaymentProps = FieldProps<LoanRepaymentFields>;

const fieldNames = {
  DeferralPeriod: 'deferralPeriod',
  Instalments: 'instalments'
};

export const LoanRepayment: React.FunctionComponent<LoanRepaymentProps> = ({
  formContext,
  formData,
  idSchema,
  errorSchema,
  schema,
  disabled,
  onChange
}) => {
  const { application, availableInstalments } = formContext as QuoteFormContext;

  const deferralPeriodSchema = schema.properties![
    fieldNames.DeferralPeriod
  ] as JSONSchema6;
  const deferralPeriodEnum = deferralPeriodSchema?.enum;
  const deferralPeriodValue = formData[fieldNames.DeferralPeriod];

  const instalmentsSchema = schema.properties![
    fieldNames.Instalments
  ] as JSONSchema6;
  const instalmentsValue = formData[fieldNames.Instalments];

  // Auto select single deferral period and/or instalment option
  useEffect(() => {
    let updated = false;
    const updatedFormData = { ...formData };

    if (deferralPeriodEnum?.length === 1 && !deferralPeriodValue) {
      updatedFormData[fieldNames.DeferralPeriod] = deferralPeriodEnum[0];
      updated = true;
    }

    if (availableInstalments.length === 1 && !instalmentsValue) {
      updatedFormData[fieldNames.Instalments] = availableInstalments[0];
      updated = true;
    }

    if (updated) {
      onChange(updatedFormData);
    }
  }, [
    formData,
    deferralPeriodValue,
    deferralPeriodEnum,
    instalmentsValue,
    availableInstalments,
    onChange
  ]);

  const updateFieldValue = (key: string, value: any) => {
    onChange({
      ...formData,
      [key]: value || undefined
    });
  };

  const deferralPeriodRadioOptions = (deferralPeriodEnum ?? []).reduce<
    RadioGroupOption[]
  >((options, value) => {
    const id = idSchema[fieldNames.DeferralPeriod].$id;
    if (typeof value === 'number') {
      options.push({
        value: String(value),
        label: `${value} ${pluraliseString('month', value)}`,
        checked: value === deferralPeriodValue,
        inputId: `${id}_${String(value)}`
      });
    }
    return options;
  }, []);

  const deferralPeriodError = getFieldError(
    fieldNames.DeferralPeriod,
    errorSchema
  );

  return (
    <>
      {deferralPeriodRadioOptions.length > 0 && (
        <Box mb={4}>
          <InputWrapper
            id={idSchema[fieldNames.DeferralPeriod].$id}
            label='Defer payment for'
            error={deferralPeriodError}
            tone={deferralPeriodError ? 'negative' : 'neutral'}>
            <RadioGroup
              name={fieldNames.DeferralPeriod}
              options={deferralPeriodRadioOptions}
              disabled={disabled}
              onChange={({ target: { value } }) =>
                updateFieldValue(fieldNames.DeferralPeriod, Number(value))
              }
            />
          </InputWrapper>
        </Box>
      )}
      <RepaymentOptions
        name={fieldNames.Instalments}
        value={instalmentsValue}
        disabled={disabled}
        errorSchema={errorSchema}
        idSchema={idSchema}
        schema={instalmentsSchema}
        onChange={value => updateFieldValue(fieldNames.Instalments, value)}
        application={application}
        availableInstalments={availableInstalments}
      />
    </>
  );
};

type RepaymentOptionsProps = {
  name: string;
  value: any;
  disabled: boolean;
  errorSchema: FormValidation;
  idSchema: IdSchema;
  schema: JSONSchema6;
  onChange: (value: any) => void;
} & Pick<QuoteFormContext, 'application' | 'availableInstalments'>;

const RepaymentOptions: React.FunctionComponent<RepaymentOptionsProps> = ({
  name,
  value,
  disabled,
  errorSchema,
  idSchema,
  schema,
  onChange,
  application,
  availableInstalments
}) => {
  const repaymentOptions = useMemo(
    () =>
      calculateRepaymentOptions({
        availableInstalments,
        application
      }),
    [availableInstalments, application]
  );

  if (repaymentOptions.length < 1) {
    return null;
  }

  const id = idSchema[name].$id;
  const fieldError = getFieldError(name, errorSchema);

  const tone = fieldError ? 'negative' : 'neutral';
  const getRepaymentOption = (instalments: number) =>
    repaymentOptions.find(option => option.instalments === instalments);

  return (
    <Box mb={4}>
      <InputWrapper
        id={id}
        label={schema.title!}
        error={fieldError || undefined}
        tone={tone}>
        <AdvancedRadioButtonGroup
          options={repaymentOptions.map(
            ({
              instalments,
              repaymentAmount,
              interestPayable,
              totalAmountPayable
            }) => {
              let repaymentText = `Repay ${formatCurrency(totalAmountPayable)}`;
              if (interestPayable.isZero()) {
                repaymentText += ' interest free';
              } else {
                repaymentText += ` inc. ${formatCurrency(
                  interestPayable
                )} interest`;
              }

              return {
                id: String(instalments),
                title: `${formatCurrency(repaymentAmount)}/Month`,
                text: instalments + pluraliseString(' Month', instalments),
                subText: repaymentText,
                inputId: `${id}_${instalments}`,
                disabled
              };
            }
          )}
          selectedId={String(value)}
          onClick={instalments => {
            const selectedOption = getRepaymentOption(Number(instalments));
            if (!disabled && selectedOption) {
              const selected = selectedOption?.instalments === value;
              !selected && onChange(selectedOption.instalments);
            }
          }}
        />
      </InputWrapper>
    </Box>
  );
};

type CalculateRepaymentOptions = Pick<
  QuoteFormContext,
  'application' | 'availableInstalments'
>;

interface RepaymentOption {
  instalments: number;
  repaymentAmount: Decimal;
  interestPayable: Decimal;
  totalAmountPayable: Decimal;
}

const calculateRepaymentOptions = ({
  application,
  availableInstalments
}: CalculateRepaymentOptions) => {
  const apr = selectApr(application.document);
  const goodsAmount = selectGoodsAmount(application.document);
  const deposit = selectDeposit(application.document);
  const deferralPeriod = selectDeferralPeriod(application.document);

  if (
    availableInstalments.length < 1 ||
    !apr ||
    !goodsAmount ||
    !deposit ||
    // Null deferral period value is acceptable
    deferralPeriod === undefined
  ) {
    return [];
  }

  const loanAmount = getLoanAmount({
    deposit,
    goodsAmount
  });

  return availableInstalments.map<RepaymentOption>(instalments => {
    const repaymentAmount = getRepaymentAmount({
      instalments,
      loanAmount,
      apr,
      deferralPeriod
    });
    const totalAmountPayable = getTotalAmountPayable({
      instalments,
      deposit,
      repaymentAmount
    });
    const interestPayable = getInterestPayable({
      totalAmountPayable,
      goodsAmount
    });

    return {
      instalments,
      repaymentAmount,
      interestPayable,
      totalAmountPayable
    };
  });
};

export default LoanRepayment;
