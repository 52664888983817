import React from 'react';
import { Route, Switch } from 'react-router';
import Agreement from '../../containers/agreement/Agreement';
import CustomerDissatisfied from '../../containers/CustomerDissatisfied';
import CustomerSatisfied from '../../containers/CustomerSatisfied';
import { routes } from '../../routes';

const CustomerFacingPage: React.FC = () => (
  <Switch>
    <Route path={routes.agreement} component={Agreement} />
    <Route
      path={routes.customerSatisfaction.dissatisfied}
      component={CustomerDissatisfied}
    />
    <Route
      path={routes.customerSatisfaction.satisfied}
      component={CustomerSatisfied}
    />
  </Switch>
);

export default CustomerFacingPage;
