import {
  Button,
  Box,
  ReactModal,
  InputWrapper,
  TextField
} from 'compass-design';
import React from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import * as yup from 'yup';
import { validationMessages } from 'hitachi-retail-core';
import { Decimal } from 'hitachi-retail-core/build/utils/decimal';
import isNumber from 'utils/isNumber';

export interface PartialSettlement {
  partialSettlementAmount: string;
}

export interface PartialSettlementsModalProps {
  modalOpen: boolean;
  loanAmount: Decimal;
  toggleModalOpen: (isOpen: boolean) => void;
  partialSettlementModalAction: (partialSettlementAmount: string) => void;
}

export const PartialSettlementsModal: React.FC<PartialSettlementsModalProps> = ({
  modalOpen,
  loanAmount,
  toggleModalOpen,
  partialSettlementModalAction
}) => {
  const initialValues: PartialSettlement = {
    partialSettlementAmount: ''
  };

  return (
    <ReactModal
      title='Request partial settlement'
      size='medium'
      isOpen={modalOpen}
      setIsOpen={toggleModalOpen}
      backgroundClick={false}>
      <Formik
        initialValues={initialValues}
        onSubmit={({ partialSettlementAmount }) =>
          partialSettlementModalAction(partialSettlementAmount)
        }
        validationSchema={getValidationSchema(loanAmount)}
        validateOnChange={false}>
        <Form noValidate>
          <Box mt={4}>
            <Field name='partialSettlementAmount'>
              {({
                field,
                form,
                meta
              }: FieldProps<string, PartialSettlement>) => (
                <InputWrapper
                  id='partialSettlementAmount'
                  label='Amount to settle, subject to approval'
                  error={meta.error}
                  tone={meta.touched && meta.error ? 'negative' : 'neutral'}>
                  <TextField
                    short
                    {...field}
                    data-test-id='partial-settlement-input'
                    id='partialSettlementAmount'
                    type='number'
                    step='0.01'
                    autoCapitalize='none'
                    autoFocus
                    tone={meta.touched && meta.error ? 'negative' : 'neutral'}
                    onChange={({
                      target: { value }
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      form.setFieldValue('partialSettlementAmount', value);
                    }}
                  />
                </InputWrapper>
              )}
            </Field>
          </Box>
          <Button
            type='submit'
            mt={[5, null, 6]}
            isJumbo
            data-test-id='partial-settlement-submit-btn'>
            Submit
          </Button>
        </Form>
      </Formik>
    </ReactModal>
  );
};

const getValidationSchema = (loanAmount: Decimal) => {
  return yup.object<PartialSettlement>({
    partialSettlementAmount: yup
      .string()
      .max(8, validationMessages.MAX_STRING_LENGTH_EXCEEDED)
      .required(validationMessages.REQUIRED_FIELD)
      .matches(/^[0-9]*(\.[0-9]{0,2})?$/, {
        message:
          'Please enter a value containing a number up to two decimal places',
        excludeEmptyString: true
      })
      .test({
        name: 'Maximum amount that can be entered',
        message: 'Amount to settle must be less than the loan amount',
        test: function(value: string) {
          if (isNumber(value)) {
            return loanAmount.greaterThanOrEqualTo(new Decimal(value));
          }

          return false;
        }
      })
  });
};
