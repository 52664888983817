import { AuditEventDetail } from 'hitachi-retail-core/build/eventEmitters/types';
import { ReferralNote } from 'hitachi-retail-core/build/schemas/referralNote';
import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { ResetStoreAction } from '..';
import { resetStore } from '../actions';
import { AsyncStatus } from '../AsyncStatus';
import {
  cancelApplication,
  fetchApplicationReferralNotes,
  fetchPreviousApplicationById,
  markGoodsAsDelivered,
  PreviousApplicationDetail,
  resetApplicationReferralNoteSubmissionStatus,
  submitApplicationReferralNote,
  fetchCustomerFeedback,
  CustomerSatisfactionNotes,
  resendEsatNote,
  revertApplicationToVersion,
  requestPartialSettlement,
  fetchAuditHistory
} from './actions';

export type PreviousApplicationByIdAction = ActionType<
  typeof fetchPreviousApplicationById
>;

export type MarkGoodsAsDeliveredAction = ActionType<
  typeof markGoodsAsDelivered
>;

export type CancelApplicationAction = ActionType<typeof cancelApplication>;

export type RequestPartialSettlementAction = ActionType<
  typeof requestPartialSettlement
>;

export type ResendEsatNoteAction = ActionType<typeof resendEsatNote>;

export type FetchApplicationReferralNotesAction = ActionType<
  typeof fetchApplicationReferralNotes
>;

export type FetchAuditHistoryAction = ActionType<typeof fetchAuditHistory>;

export type SubmitApplicationReferralNoteAction = ActionType<
  typeof submitApplicationReferralNote
>;

export type FetchCustomerFeedbackAction = ActionType<
  typeof fetchCustomerFeedback
>;

export type ResetApplicationReferralNoteSubmissionStatus = ActionType<
  typeof resetApplicationReferralNoteSubmissionStatus
>;

export type RevertApplicationToVersionAction = ActionType<
  typeof revertApplicationToVersion
>;

type ApplicationDetailAction =
  | PreviousApplicationByIdAction
  | MarkGoodsAsDeliveredAction
  | ResetStoreAction
  | CancelApplicationAction
  | FetchApplicationReferralNotesAction
  | SubmitApplicationReferralNoteAction
  | FetchCustomerFeedbackAction
  | ResetApplicationReferralNoteSubmissionStatus
  | ResendEsatNoteAction
  | RequestPartialSettlementAction
  | RevertApplicationToVersionAction
  | FetchAuditHistoryAction;

export interface State {
  loading: boolean;
  revertLoading: boolean;
  errorMessage?: string;
  successMessage?: string;
  previousApplication?: PreviousApplicationDetail;
  referralNotes?: ReferralNote[];
  referralNoteSubmissionStatus?: AsyncStatus;
  customerFeedback?: CustomerSatisfactionNotes;
  auditHistory?: AuditEventDetail[];
}

export const initialState: State = { loading: false, revertLoading: false };

export const reducer: Reducer<State, ApplicationDetailAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchPreviousApplicationById.request): {
      return { ...state, loading: true };
    }

    case getType(fetchPreviousApplicationById.success): {
      return {
        ...state,
        loading: false,
        previousApplication: action.payload
      };
    }

    case getType(fetchPreviousApplicationById.failure): {
      return { ...state };
    }

    case getType(resendEsatNote.request):
    case getType(markGoodsAsDelivered.request):
    case getType(cancelApplication.request):
    case getType(requestPartialSettlement.request): {
      return { ...state, loading: true };
    }

    case getType(resendEsatNote.success):
    case getType(resendEsatNote.failure):
    case getType(markGoodsAsDelivered.success):
    case getType(markGoodsAsDelivered.failure):
    case getType(cancelApplication.success):
    case getType(cancelApplication.failure):
    case getType(requestPartialSettlement.success):
    case getType(requestPartialSettlement.failure): {
      return { ...state, loading: false };
    }

    case getType(revertApplicationToVersion.request): {
      return { ...state, revertLoading: true };
    }

    case getType(revertApplicationToVersion.success):
    case getType(revertApplicationToVersion.failure): {
      return { ...state, revertLoading: false };
    }

    case getType(fetchApplicationReferralNotes.request): {
      return { ...state, loading: true };
    }

    case getType(fetchApplicationReferralNotes.success): {
      return {
        ...state,
        loading: false,
        referralNotes: action.payload
      };
    }

    case getType(fetchApplicationReferralNotes.failure): {
      return { ...state, referralNotes: [], loading: false };
    }

    case getType(submitApplicationReferralNote.request):
      return {
        ...state,
        referralNoteSubmissionStatus: AsyncStatus.Loading
      };

    case getType(submitApplicationReferralNote.success):
      return {
        ...state,
        referralNoteSubmissionStatus: AsyncStatus.Success
      };

    case getType(submitApplicationReferralNote.failure):
      return {
        ...state,
        referralNoteSubmissionStatus: AsyncStatus.Failure
      };

    case getType(resetApplicationReferralNoteSubmissionStatus):
      return {
        ...state,
        referralNoteSubmissionStatus: AsyncStatus.Default
      };

    case getType(fetchCustomerFeedback.request): {
      return { ...state, loading: true };
    }

    case getType(fetchCustomerFeedback.success): {
      return {
        ...state,
        loading: false,
        customerFeedback: action.payload
      };
    }

    case getType(fetchCustomerFeedback.failure): {
      return { ...state, customerFeedback: undefined, loading: false };
    }

    case getType(fetchAuditHistory.request): {
      return { ...state, loading: true };
    }

    case getType(fetchAuditHistory.success): {
      return {
        ...state,
        loading: false,
        auditHistory: action.payload
      };
    }

    case getType(fetchAuditHistory.failure): {
      return { ...state, auditHistory: [], loading: false };
    }

    case getType(resetStore): {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
