import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { fetchRetailerConfig } from './actions';
import { RootState } from '../index';
import { AsyncStatus } from '../../applicantApp/store/AsyncStatus';

export interface State {
  errorMessage?: string;
  currentDomain: string;
  baseDomain: string;
  status: AsyncStatus;
}

export type FetchRetailerConfigAction = ActionType<typeof fetchRetailerConfig>;

export const initialState: State = {
  currentDomain: '',
  baseDomain: '',
  status: AsyncStatus.Default
};

export const reducer: Reducer<State, FetchRetailerConfigAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchRetailerConfig.success): {
      const { currentDomain, baseDomain } = action.payload;

      return {
        ...initialState,
        currentDomain,
        baseDomain,
        status: AsyncStatus.Success
      };
    }

    case getType(fetchRetailerConfig.request): {
      return {
        ...initialState,
        status: AsyncStatus.Loading
      };
    }

    default: {
      return state;
    }
  }
};

export const selectConfigStatus = (state: RootState): AsyncStatus =>
  state.retailerConfig.status;
