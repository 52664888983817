import { Button } from 'compass-design';
import React, { FormEvent } from 'react';

export interface PromptPropsFromState {
  title?: string;
  message?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export interface PromptPropsFromDispatch {
  onClose: () => void;
}

export type PromptProps = PromptPropsFromState & PromptPropsFromDispatch;

class Prompt extends React.Component<PromptProps> {
  public wrapper: React.RefObject<HTMLDivElement>;

  constructor(props: any) {
    super(props);

    this.handleClick = this.handleClick.bind(this);

    this.wrapper = React.createRef<HTMLDivElement>();
  }

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  public handleClick(e: Event) {
    const { props, wrapper } = this;
    const { onClose } = props;
    const target = e.target as HTMLElement;

    if (
      wrapper &&
      wrapper.current &&
      wrapper.current.className === target.className
    ) {
      return onClose();
    }
  }

  public render() {
    const { title, message, onCancel, onClose, onConfirm } = this.props;

    if (!message) {
      return null;
    }

    return (
      <div className='compass-prompt uk-modal uk-open' ref={this.wrapper}>
        <div className='uk-modal-dialog uk-modal-body'>
          <h2 className='uk-modal-title'>{title || 'Please confirm'}</h2>
          <p>{message}</p>
          <p className='uk-text-right' data-test-id='compass-prompt-message'>
            <button
              className='uk-button uk-button-default uk-modal-close'
              data-test-id='compass-prompt-cancel'
              type='button'
              onClick={(event: FormEvent) => {
                event.preventDefault();
                onCancel();
                onClose();
              }}>
              Back
            </button>
            <Button
              onClick={(event: FormEvent) => {
                event.preventDefault();
                onConfirm();
                onClose();
              }}
              data-test-id='compass-prompt-confirm'>
              Confirm
            </Button>
          </p>
        </div>
      </div>
    );
  }
}

export default Prompt;
