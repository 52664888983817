import React from 'react';
import {
  AccordionContent,
  AccordionGroup,
  AccordionGroupEntry,
  Box,
  Link,
  List,
  ListItem,
  NovunaHeading,
  Text
} from 'compass-design';
import { LoanInformationProps } from './LoanInformationProps';

const getHowWhenCreditProvidedText = (immediatePayout: boolean) => {
  if (immediatePayout) {
    return 'We will pay the total amount of credit to the supplier of the goods/services on the date of the credit agreement.';
  }
  return 'We will pay the total amount of credit to the supplier of the goods/services as soon as the goods/services are supplied.';
};

const UnregulatedAgreementText = (
  <Text>
    This loan agreement is{' '}
    <Text
      sx={{
        fontWeight: 'bold',
        textDecoration: 'underline',
        display: 'inline'
      }}>
      NOT
    </Text>{' '}
    regulated by the Consumer Credit Act 1974 (“
    <Text
      sx={{
        fontWeight: 'bold',
        display: 'inline'
      }}>
      CCA
    </Text>
    ”). This means that most of the protections provided to consumers under the
    CCA{' '}
    <Text
      sx={{
        fontWeight: 'bold',
        textDecoration: 'underline',
        display: 'inline'
      }}>
      will not
    </Text>{' '}
    apply to you – including Sections 75 and 56, which allow consumers to bring
    claims about the goods and services financed to the lender in certain
    circumstances. You will therefore be required to make your monthly
    repayments even if you have a dispute with the supplier of the
    goods/services. There is also no out of court redress system available for
    this agreement and the Financial Ombudsman Service does not cover this
    agreement
  </Text>
);

export const LoanAdvanceInformation: React.FC<LoanInformationProps> = ({
  intermediaryName,
  intermediaryAddress,
  loanAmount,
  isDeferred,
  agreementDuration,
  instalments,
  repaymentAmount,
  totalAmountPayable,
  goodsDescription,
  goodsAmount,
  apr,
  earlySettlementFee,
  immediatePayout
}) => (
  <>
    <AccordionGroup headingAs='h2' label='Loan advance information'>
      <AccordionGroupEntry
        sectionNumber={1}
        title='Contact details'
        description='For the creditor and supplier'
        headingVariant='tertiary'
        breakAfter={true}>
        <AccordionContent
          title='Creditor'
          description='Mitsubishi HC Capital UK PLC trading as Novuna Personal Finance, (we, or us).'
        />
        <AccordionContent
          title='Address'
          description='2 Apex View, Leeds, West Yorkshire LS11 9BH.'
        />
        <AccordionContent
          title='Telephone number(s)'
          description='0344 375 5500'
        />
        {intermediaryName && (
          <Box mb={4}>
            <NovunaHeading as='h3' mb={2}>
              Credit intermediary address
            </NovunaHeading>
            <Text>The supplier being, {intermediaryName}</Text>
            {intermediaryAddress && <Text>{intermediaryAddress}</Text>}
          </Box>
        )}
      </AccordionGroupEntry>

      <AccordionGroupEntry
        sectionNumber={2}
        title='Key features'
        description='For the credit product including total amount and duration of the agreement'
        headingVariant='tertiary'
        breakAfter={true}>
        <AccordionContent
          title='Type of credit'
          description='Fixed sum loan agreement'
        />
        <AccordionContent
          title='The total amount of credit'
          description={loanAmount}
          hint='This means the amount of credit to be provided under the proposed credit agreement or the credit limit.'
        />
        <AccordionContent
          title='How and when credit would be provided'
          description={getHowWhenCreditProvidedText(!!immediatePayout)}
        />
        <AccordionContent
          title='The duration of the credit agreement'
          descriptionTestId='pcci-duration-header'
          description={
            isDeferred
              ? `The period starting on the date of the agreement and ending on the
                      date which is ${agreementDuration} months from the date of supply
                      (and, if applicable, installation) of the goods/services`
              : `${agreementDuration} months`
          }
        />
        <AccordionContent
          title='Repayments'
          description={`${instalments} consecutive monthly payments of ${repaymentAmount}`}
        />
        <AccordionContent
          title='The total amount you will have to pay'
          hint='This means the amount you have borrowed plus interest and other costs.'
          description={totalAmountPayable}
        />
        <Text mb={3} sx={{ fontWeight: 'bold' }}>
          The proposed credit will be linked to the supply of specific goods or
          the provision of a service.
        </Text>
        <AccordionContent
          title='Description of goods/services'
          description={`${goodsDescription}: ${goodsAmount}`}
        />
        <AccordionContent
          title='Cash price'
          description={`Total cash price: ${goodsAmount}`}
        />
        <Box mb={4}>
          <NovunaHeading as='h3' mb={2}>
            Unregulated agreement
          </NovunaHeading>
          {UnregulatedAgreementText}
        </Box>
      </AccordionGroupEntry>

      <AccordionGroupEntry
        sectionNumber={3}
        title='Cost of the credit'
        description='Including interest rate and guidance on late or missed payments'
        headingVariant='tertiary'
        breakAfter={true}>
        <AccordionContent
          title='The rates of interest which apply to the credit agreement'
          description={
            isDeferred
              ? `${apr} per annum will be charged if you repay
            the credit in full prior to your first monthly payment falling
            due
            ${earlySettlementFee && (
              <>
                , but you will have to pay an administration charge if you
                settle in full before your first payment is due
              </>
            )}
            . Interest will be charged at 0% per annum on any amounts
            which you repay prior to your first monthly payment falling
            due.`
              : `${apr} per annum (fixed)`
          }
          descriptionTestId='pcci-interest-rate-header'
        />
        <AccordionContent
          title='Any other costs deriving from the credit agreement'
          description={'£0'}
          descriptionTestId='pcci-other-costs-heading'
        />
        <Box mb={4}>
          <NovunaHeading as='h3' mb={2}>
            Costs in the case of late payments.
          </NovunaHeading>
          <Text mb={3}>
            Charges will be payable under this agreement in the following
            events:
          </Text>
          <List fontSize='1'>
            <ListItem>Recalled Direct Debit Charge; £22</ListItem>
            <ListItem>Returned or Recalled cheques; £25</ListItem>
            <ListItem>Issue of a Notice of Non-Payment; £25</ListItem>
            <ListItem>
              Letters sent to you as a result of a breach of your credit
              agreement; £25 (but no such charge shall be made in respect of any
              such letters sent to you after a Notice of Non-Payment has been
              issued, until there are no outstanding arrears on your account,
              when the charge shall become chargeable again until any further
              Notice of Non-Payment is issued)
            </ListItem>
          </List>
          <Text>
            Charges may also be payable for reasons stated in clauses 6a) and
            7b) of the Terms of Lending of the agreement. We may vary the
            charges payable under this agreement by giving you reasonable notice
            to reflect any variation to the cost and expenses to us in carrying
            out these activities.
          </Text>
        </Box>
        <AccordionContent
          title='Consequences of missing payments'
          description='Missing payments could have severe consequences and may result in legal proceedings being taken against you. This could include obtaining a Court Order for a charge on your home. We will add details of your default to your record with the credit reference agencies which could also make it more difficult or more expensive for you to obtain credit.'
        />
      </AccordionGroupEntry>

      <AccordionGroupEntry
        sectionNumber={4}
        title='Legal aspects'
        description='Other important legal aspects regarding your rights'
        headingVariant='tertiary'
        breakAfter={true}>
        <AccordionContent
          title={'Cancelling the agreement'}
          description={
            'You have the right to cancel the agreement, without giving any reason, for a period of 14 days beginning on the day on which you receive a copy of the agreement.'
          }
        />
        <AccordionContent
          title='Early repayment'
          description={
            'You may repay the credit early at any time in full or part.'
          }
        />
        <AccordionContent
          title='Consultation with a Credit Reference Agency'
          description='If we decide not to proceed with your credit application on the basis of information obtained from our credit reference agency, we will notify you of our decision and we will inform you that it has been reached on the basis of information held by our credit reference agency and we will provide you with details of which credit reference agency we used.'
        />
      </AccordionGroupEntry>

      <AccordionGroupEntry
        sectionNumber={5}
        title='Additional information'
        description='In the case of distance marketing of financial services'
        headingVariant='tertiary'
        breakAfter={true}>
        <AccordionContent
          title='The law taken by the creditor as a basis for the establishment of relations with you before the conclusion of the credit agreement'
          description='The law of the country in which you are domiciled, being England, Wales, Scotland or Northern Ireland.'
        />
        <AccordionContent
          title='The law applicable to the credit agreement and/or the competent court'
          description='Our relations with you are based on the law of the country in the United Kingdom where you are domiciled, being England, Wales, Scotland or Northern Ireland and disputes may be referred to the courts of that country.'
        />
        <AccordionContent
          title='Language to be used in connection with the credit agreement'
          description='The contractual terms and conditions of the credit agreement and the information contained on this page will be supplied to you in English. Unless you and we agree otherwise all communications relating to the credit agreement will be in English.'
        />
        <AccordionContent
          title='Access to out-of-court complaint and redress mechanism'
          description='We operate a complaint handling procedure.'
        />
        <Text mt={5}>
          If you have a complaint about this agreement you can telephone us on{' '}
          <Link href='0344 375 5500' sx={{ color: 'secondaryPurple' }}>
            0344 375 5500
          </Link>
        </Text>
        <br />
        <Text>
          Email us at:{' '}
          <Link
            href='complaintsteam@novunapersonalfinance.co.uk'
            sx={{ color: 'secondaryPurple' }}>
            complaintsteam@novunapersonalfinance.co.uk
          </Link>
        </Text>
        <br />
        <Text>Or write to us at </Text>
        <Text>Novuna Personal Finance,</Text>
        <Text>2 Apex View, </Text>
        <Text>Leeds,</Text>
        <Text>LS11 9BH. </Text>
        <br />
        <Text>
          There is no out of court redress system available for this agreement
          and the Financial Ombudsman Service does not cover this agreement.
        </Text>
      </AccordionGroupEntry>
    </AccordionGroup>
  </>
);
