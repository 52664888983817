import React from 'react';
import { Redirect } from 'react-router-dom';
import { routes } from 'routes';

const redirectToLogin = (props: any) => {
  return (
    <Redirect
      to={{
        pathname: routes.login,
        state: {
          referrer: props.location
        }
      }}
    />
  );
};

export default redirectToLogin;
