import { GoodsListItem } from 'hitachi-retail-core';
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import * as goodsList from './constants';

export const getGoodsList = createAsyncAction(
  goodsList.GET_GOODS_LIST_REQUEST,
  goodsList.GET_GOODS_LIST_SUCCESS,
  goodsList.GET_GOODS_LIST_FAILURE
)<{ id: string }, { goodsList: GoodsListItem[] }, Error>();

export const resetFetchedGoodsList = createStandardAction(
  goodsList.RESET_FETCHED_GOODS_LIST
)();
