import { FeatureConfig } from 'hitachi-retail-core/build/features/features';
import { ActionEnhancerByType, Enhancement } from 'redux-action-enhancer';
import { UPDATE_ACTIVE_APPLICATION } from './application/constants';
import { Action } from './application/reducer';
import { State as ConfigState } from './config/reducer';
import { selectEnabledFeatures } from './config/selectors';
import { FETCH_DECISION_SUCCESS } from './decision/constants';
import { RootState } from './index';
import { FETCH_ACTIVE_APPLICATION_BY_ID_SUCCESS } from './previousApplicationDetail/constants';

export interface FeatureFlagEnhancement extends Enhancement {
  enabledFeatures: FeatureConfig;
}

type StateWithConfig = {
  config: ConfigState;
};

export const getEnabledFeatures = (action: Action): FeatureConfig =>
  (action as any).enabledFeatures ?? new Set<string>();

// Note: populates the specified actions with the feature flags, not their payloads.
export const featureFlagEnhancer: ActionEnhancerByType<
  FeatureFlagEnhancement,
  StateWithConfig
> = {
  actionTypes: [
    FETCH_ACTIVE_APPLICATION_BY_ID_SUCCESS,
    UPDATE_ACTIVE_APPLICATION,
    FETCH_DECISION_SUCCESS
  ],
  mapState: state => ({
    enabledFeatures: selectEnabledFeatures(state as RootState)
  })
};
