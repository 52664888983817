import { call, delay, put, takeEvery } from 'redux-saga/effects';
import { getType, isOfType } from 'typesafe-actions';
import {
  AgreementService,
  AgreementServiceErrorMessage,
  getAgreementService
} from '../../services/agreementService';
import {
  fetchAgreementUrl,
  fetchAgreementBySigningId
} from '../../store/agreement/actions';
import {
  FetchAgreementUrlAction,
  FetchAgreementBySigningIdAction
} from '../../store/agreement/reducer';
import { DefaultSagaParams } from '../index';

interface GetDownloadAgreementSagaProps {
  agreementService: AgreementService;
  maxRetries?: number;
  retryDelay?: number;
}

export const getFetchAgreementUrlSaga = ({
  agreementService,
  maxRetries = 5,
  retryDelay = 5000
}: GetDownloadAgreementSagaProps) =>
  function*(action: FetchAgreementUrlAction) {
    if (isOfType(getType(fetchAgreementUrl.request), action)) {
      const { applicationId, forceDownload } = action.payload;
      let tries = 0;
      while (tries <= maxRetries) {
        tries++;
        try {
          const response = yield call(agreementService.fetchAgreementUrl, {
            applicationId,
            forceDownload: forceDownload ?? true
          });
          yield put(fetchAgreementUrl.success(response));
          break;
        } catch (err) {
          const shouldRetry =
            tries < maxRetries &&
            err.message ===
              AgreementServiceErrorMessage.CREDIT_AGREEMENT_NOT_READY;
          if (shouldRetry) {
            yield delay(retryDelay);
          } else {
            yield put(fetchAgreementUrl.failure(err));
            break;
          }
        }
      }
    }
  };

export const getDownloadAgreementBySigningIdSaga = ({
  agreementService
}: GetDownloadAgreementSagaProps) =>
  function*(action: FetchAgreementBySigningIdAction) {
    if (isOfType(getType(fetchAgreementBySigningId.request), action)) {
      const { signingId, verificationDetails } = action.payload;
      try {
        const response: string = yield call(
          agreementService.downloadAgreementBySigningId,
          { signingId, verificationDetails }
        );
        yield put(fetchAgreementBySigningId.success(response));
      } catch (err) {
        yield put(
          fetchAgreementBySigningId.failure({
            credentialsWrong: err.credentialsWrong === true
          })
        );
      }
    }
  };

export const getDownloadAgreementSagaWatcher = ({
  apiClient
}: DefaultSagaParams) =>
  function*() {
    const agreementService = getAgreementService({ apiClient });

    yield takeEvery(
      getType(fetchAgreementUrl.request),
      getFetchAgreementUrlSaga({ agreementService })
    );

    yield takeEvery(
      getType(fetchAgreementBySigningId.request),
      getDownloadAgreementBySigningIdSaga({ agreementService })
    );
  };
