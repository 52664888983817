import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { RootState } from '..';
import { resetStore, ResetStoreAction } from '../actions';
import {
  closeModal,
  closeNotification,
  closePrompt,
  disablePageBlock,
  enablePageBlock,
  showModal,
  showNotification,
  showPrompt,
  UIModal,
  UINotification,
  UIPrompt
} from './actions';

export type EnablePageBlockAction = ActionType<typeof enablePageBlock>;
export type DisablePageBlockAction = ActionType<typeof disablePageBlock>;
export type ShowNotificationAction = ActionType<typeof showNotification>;
export type CloseNotificationAction = ActionType<typeof closeNotification>;
export type ShowPromptAction = ActionType<typeof showPrompt>;
export type ClosePromptAction = ActionType<typeof closePrompt>;
export type ShowModalAction = ActionType<typeof showModal>;
export type CloseModalAction = ActionType<typeof closeModal>;

export interface State {
  pageBlockEnabled?: boolean;
  notification?: UINotification;
  prompt?: UIPrompt;
  modal?: UIModal;
}

export const initialState: State = {};

export type Action =
  | EnablePageBlockAction
  | DisablePageBlockAction
  | ShowNotificationAction
  | CloseNotificationAction
  | ShowPromptAction
  | ClosePromptAction
  | ResetStoreAction
  | ShowModalAction
  | CloseModalAction;

export const reducer: Reducer<State, Action> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(enablePageBlock): {
      return { ...state, pageBlockEnabled: true };
    }
    case getType(disablePageBlock): {
      return { ...state, pageBlockEnabled: false };
    }
    case getType(showNotification): {
      return { ...state, notification: { ...action.payload } };
    }
    case getType(closeNotification): {
      return { ...state, notification: {} };
    }
    case getType(showPrompt): {
      return { ...state, prompt: { ...action.payload } };
    }
    case getType(closePrompt): {
      return { ...state, prompt: {} };
    }
    case getType(showModal): {
      return { ...state, modal: { ...action.payload } };
    }
    case getType(closeModal): {
      return { ...state, modal: {} };
    }
    case getType(resetStore):
      return initialState;
    default:
      return state;
  }
};

export const notificationDisplayed = ({ ui: { notification } }: RootState) =>
  Boolean(notification && notification.message);
