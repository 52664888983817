import { Box, Message, NovunaHeading, Text } from 'compass-design';
import React from 'react';

const PrintAgreementExpiredMessage = () => (
  <Box mt={6} data-test-id='agreement-expired-message'>
    <Message variant='info' restrictedWidth={false}>
      <NovunaHeading as='h3' mb={1}>
        Credit Agreement
      </NovunaHeading>
      <Text>
        The link to the signed agreement for this application is no longer
        available.
      </Text>
      <Text>If you need a copy contact Retail Helpline on 0344 375 5515.</Text>
    </Message>
  </Box>
);

export default PrintAgreementExpiredMessage;
