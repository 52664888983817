import { Box, Button, Message, NovunaHeading, Text } from 'compass-design';
import React from 'react';
import { getExpiryDate } from 'utils/expiryDate';

export interface SoftSearchExpiredCardProps {
  softSearchExpiryDate: string;
  createNewApplication: () => void;
}

const SoftSearchExpiredCard: React.FC<SoftSearchExpiredCardProps> = ({
  softSearchExpiryDate,
  createNewApplication
}) => (
  <Box mt={6} data-test-id='application-amended-card'>
    <Message variant='info' restrictedWidth={false}>
      <NovunaHeading as='h3' id='application-softsearchexpired-heading' mb={1}>
        Application check expired
      </NovunaHeading>
      <Text>
        This soft search application check is no longer valid (expired on{' '}
        {getExpiryDate(softSearchExpiryDate)}), if you wish to proceed you will
        need to create a new application.
      </Text>
      <Button
        isJumbo
        mt={4}
        variant='primary'
        data-test-id='compass-new-application'
        onClick={createNewApplication}>
        New application
      </Button>
    </Message>
  </Box>
);

export default SoftSearchExpiredCard;
