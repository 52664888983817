import React, { useState } from 'react';
import { Box, Button, InlineCheckbox, ReactModal, Text } from 'compass-design';

interface BranchWarningModalProps {
  retailerName?: string;
  supplierId?: string;
  setIsOpen: () => void;
  onContinue: (shouldDisableFutureMessage: boolean) => void;
}

const BranchWarningModal = ({
  retailerName,
  supplierId,
  setIsOpen,
  onContinue
}: BranchWarningModalProps) => {
  const [disableFutureMessages, setDisableFutureMessages] = useState(false);

  return (
    <ReactModal
      isOpen={!!retailerName}
      setIsOpen={setIsOpen}
      size='medium'
      title='View branch'>
      <Text mb={5}>
        You are about to view the users for {retailerName} ({supplierId}).
        <br />
        Please confirm that you wish to proceed.
      </Text>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Button
          variant='primary'
          isJumbo
          onClick={() => {
            onContinue(disableFutureMessages);
          }}
          mr={5}>
          Continue
        </Button>
        <InlineCheckbox
          dataTestId='disable-modal'
          checked={disableFutureMessages}
          onChange={() => setDisableFutureMessages(!disableFutureMessages)}>
          Don’t display this again
        </InlineCheckbox>
      </Box>
    </ReactModal>
  );
};

export default BranchWarningModal;
