import { FinanceApplication } from 'hitachi-retail-core';
import React, { useEffect } from 'react';
import { FieldProps } from 'react-jsonschema-form';
import { DeepPartial } from 'hitachi-retail-core/build/utils/deepPartial';
import InputField from '../InputField';
import { Flex, Box, Spinner } from 'compass-design';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectProposingOnBehalfOf,
  selectRetailer
} from 'store/retailer/selectors';
import { Text } from 'compass-design';
import {
  selectGoodsList,
  selectHasFetchedGoodsList,
  selectProcessing
} from 'store/goodsList/selectors';
import { getGoodsList } from 'store/goodsList/actions';
import { selectEnabledFeatures } from 'store/config/selectors';
import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import formatGoodsList from 'utils/formatGoodsList';
import GoodsListDropdown from 'retailerApp/components/GoodsListDropdown';

type GoodsDescriptionType = DeepPartial<FinanceApplication['goodsDescription']>;

export type GoodsDescriptionFieldProps = FieldProps<GoodsDescriptionType>;

export type GoodsDescriptionProps = GoodsDescriptionFieldProps;

const fieldNames = {
  GoodsDescription: 'goodsDescription'
};

export const GoodsDescription: React.FunctionComponent<GoodsDescriptionProps> = ({
  formData,
  rawErrors,
  autofocus,
  onChange,
  disabled,
  idSchema: { $id }
}) => {
  let goodsList = useSelector(selectGoodsList);
  const processing = useSelector(selectProcessing);
  const fetched = useSelector(selectHasFetchedGoodsList);
  const { supplierNumber } = useSelector(selectRetailer);
  const enabledFeatures = useSelector(selectEnabledFeatures);
  const proposingOnBehalfOf = useSelector(selectProposingOnBehalfOf);

  const dispatch = useDispatch();

  goodsList = formatGoodsList({ goodsList, goodsDescription: formData });

  const error = Array.isArray(rawErrors) && rawErrors[0];

  const handleChange = (value: any) => {
    if (value === '') {
      value = undefined;
    }

    const updatedFormData: Partial<GoodsDescriptionType> = value;

    onChange(updatedFormData);
  };

  useEffect(() => {
    if (
      !processing &&
      !fetched &&
      enabledFeatures.has(CompassFeature.GOODS_LIST)
    ) {
      const id = proposingOnBehalfOf?.supplierNumber ?? supplierNumber;
      dispatch(getGoodsList.request({ id }));
    }
  }, [
    supplierNumber,
    proposingOnBehalfOf,
    processing,
    fetched,
    enabledFeatures,
    dispatch
  ]);

  if (processing) {
    return (
      <Flex mb={4} sx={{ alignItems: 'center' }}>
        <Box sx={{ width: '40px', height: '40px' }}>
          <Spinner data-test-id='loading-spinner' />
        </Box>
        <Text ml={3} sx={{ fontSize: 2, fontWeight: 'bold' }}>
          Loading goods description…
        </Text>
      </Flex>
    );
  }

  if (goodsList.length === 0) {
    return (
      <InputField
        label='Goods description'
        required
        autofocus={autofocus}
        disabled={disabled}
        fieldName={fieldNames.GoodsDescription}
        inputType='string'
        fieldValue={formData}
        fieldError={error}
        testIdPrefix={$id}
        inputId={`${$id}_${fieldNames.GoodsDescription}`}
        onChange={({ target }) => handleChange(target.value)}
        shortLength
      />
    );
  }

  return (
    <Box mb={4}>
      <GoodsListDropdown
        goodsDescription={formData}
        goodsList={goodsList}
        setGoodsDetails={handleChange}
        disabled={disabled}
        error={error}
        bold
      />
      {error && (
        <div
          className='compass-field-error-message'
          data-test-id='goods_description_error'>
          {error}
        </div>
      )}
    </Box>
  );
};

export default GoodsDescription;
