import { connect, MapStateToProps } from 'react-redux';
import CustomerPresent, {
  CustomerPresentFieldProps,
  CustomerPresentPropsFromState
} from '../../../components/form/fields/CustomerPresent';
import { RootState } from '../../../store';

export const mapStateToProps: MapStateToProps<
  CustomerPresentPropsFromState,
  CustomerPresentFieldProps,
  RootState
> = state => {
  const {
    retailer: { retailer: retailerConfig }
  } = state;

  const mailorderEnabled =
    (retailerConfig && retailerConfig.mailorderEnabled) === true;

  return {
    mailorderEnabled
  };
};

export default connect(mapStateToProps)(CustomerPresent);
