import { createAsyncAction } from 'typesafe-actions';
import * as app from './constants';

export interface SignApplicationResponse {
  redirectUrl: string;
  error?: string;
  errors?: string[];
}

interface SignApplicationRequestPayload {
  id: string;
}

export const signApplication = createAsyncAction(
  app.SIGN_APPLICATION_REQUEST,
  app.SIGN_APPLICATION_SUCCESS,
  app.SIGN_APPLICATION_FAILURE
)<SignApplicationRequestPayload | void, void, Error>();
