import { ProductConfig } from 'hitachi-retail-core/build/api/productConfig';
import {
  ServiceTypeIdentifier,
  transformServiceTypeDescription
} from 'hitachi-retail-core/build/application/financeProduct';
import { Reducer } from 'redux';
import {
  resumeApplication,
  ResumeApplicationAction
} from 'store/application/actions';
import {
  setProposingOnBehalfOf,
  SetProposingOnBehalfOfAction
} from 'store/retailer/actions';
import { getType } from 'typesafe-actions';
import { resetStore, ResetStoreAction } from '../actions';
import { fetchProductsForRetailer, ProductsForRetailerAction } from './actions';

export interface State {
  fetching: boolean;
  fetched: boolean;
  error: boolean;
  products: ProductConfig[];
}

export const initialState: State = {
  fetching: false,
  fetched: false,
  error: false,
  products: []
};

export const reducer: Reducer<
  State,
  | ProductsForRetailerAction
  | SetProposingOnBehalfOfAction
  | ResumeApplicationAction
  | ResetStoreAction
> = (state = initialState, action) => {
  switch (action.type) {
    case getType(fetchProductsForRetailer.request):
      return {
        ...initialState,
        fetching: true
      };

    case getType(fetchProductsForRetailer.success):
      return {
        ...state,
        fetching: false,
        fetched: true,
        products: action.payload.map((product: ProductConfig) => ({
          ...product,
          name: transformServiceTypeDescription.get(
            product.serviceType as ServiceTypeIdentifier
          )
        }))
      };

    case getType(fetchProductsForRetailer.failure):
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: true
      };

    // Clear products if switching retailer
    case getType(setProposingOnBehalfOf):
    case getType(resumeApplication):
    case getType(resetStore):
      return initialState;

    default:
      return state;
  }
};
