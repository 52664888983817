import { createAsyncAction } from 'typesafe-actions';
import * as app from './constants';
import { RetailerConfig } from 'hitachi-retail-core';

export interface GetRetailerConfigLocalPayload {
  currentDomain: string;
}

export type GetRetailerConfigSuccessPayload = RetailerConfig &
  GetRetailerConfigLocalPayload;

export const fetchRetailerConfig = createAsyncAction(
  app.GET_RETAILER_CONFIG_REQUEST,
  app.GET_RETAILER_CONFIG_SUCCESS,
  app.GET_RETAILER_CONFIG_FAILURE
)<void, GetRetailerConfigSuccessPayload, Error>();
