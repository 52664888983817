import React from 'react';
import { useSelector } from 'store';
import { NovunaHeading, Box, Text, Button } from 'compass-design';
import { QuoteSummary } from 'components/QuoteSummary';
import NovunaPageLayout from 'applicantApp/components/NovunaPageLayout';
import { ConvertSoftSearchToDecisionProps } from '../../SoftSearchOutcome';
import { FinanceApplication } from 'hitachi-retail-core/build/schemas/financeApplication';
import { getLoanDetails } from 'retailerApp/utils/loanDetails/getLoanDetails';
import { ApplicantApplicationProps } from 'applicantApp/pages/ApplicantApplication/ApplicantApplication';
import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import getAffordabilityAccept from 'retailerApp/utils/loanDetails/getAffordabilityAccept';
import { getIsTailoringExpired } from 'utils/expiryDate';
import PbfTailoringOptions from 'applicantApp/pages/DecisionOutcome/components/PbfTailoringOptions';
import { selectApplicationDocument } from 'store/application/selectors';
import { SoftSearchMessageOutcome } from '../shared';

export interface SoftSearchAcceptChainProps {
  id: string;
  tailoringReduceTermEnabled?: boolean;
  tailoringIbcReduceTermEnabled?: boolean;
  tailoringExpiryDate?: string | null;
}

type SoftSearchAcceptProps = Omit<
  ApplicantApplicationProps &
    SoftSearchAcceptChainProps &
    ConvertSoftSearchToDecisionProps,
  | 'activeApplication'
  | 'retailer'
  | 'decisionPollInterval'
  | 'isDecisionLoading'
>;

const SoftSearchAccept: React.FC<SoftSearchAcceptProps> = ({
  id,
  tailoringReduceTermEnabled,
  tailoringIbcReduceTermEnabled,
  enabledFeatures,
  tailoringExpiryDate,
  onConfirmTailoringUpdate,
  onAcceptOriginalOffer,
  convertSoftSearchOutcomeToDecisionFunction
}) => {
  const financeApplication = useSelector(
    selectApplicationDocument
  ) as FinanceApplication;

  const loanDetails = getLoanDetails({
    document: financeApplication,
    isSoftSearch: true,
    enabledFeatures
  });

  const affordabilityAccept = getAffordabilityAccept({
    tailoringFeatureAffordabilityAccept: enabledFeatures.has(
      CompassFeature.TAILORING_AFFORDABILITY_ACCEPT_FEATURE
    ),
    tailoringReduceTermEnabled,
    tailoringIbcReduceTermEnabled,
    loanDetails,
    isPbfOrMo: true
  });

  const maxInstalmentAmount =
    financeApplication.loanTailoring?.maxInstalmentAmount;

  const isSoftSearch = loanDetails?.isSoftSearch ?? false;

  const affordabilityAcceptProps = affordabilityAccept
    ? {
        ...affordabilityAccept,
        onConfirmTailoringUpdate,
        onAcceptOriginalOffer
      }
    : undefined;

  return (
    <NovunaPageLayout childrenContainerStyles={{ height: '100%' }}>
      <SoftSearchMessageOutcome />

      <NovunaHeading
        as='h1'
        data-test-id='mo-softsearch-accept-page-heading'
        mt={4}
        mb={3}>
        Soft Search check
      </NovunaHeading>
      <NovunaHeading
        as='h2'
        mb={2}
        data-test-id={'mo-softsearch-default-accept-page'}>
        Good news
      </NovunaHeading>

      {maxInstalmentAmount &&
      affordabilityAcceptProps &&
      !loanDetails?.tailoringOptionSelected &&
      !getIsTailoringExpired({ tailoringExpiryDate }) ? (
        <PbfTailoringOptions
          isSoftSearch={isSoftSearch}
          data-test-id={'pbf-tailoring-options-on-accept'}
          originalApplication={financeApplication}
          maxInstalmentAmount={maxInstalmentAmount!}
          affordabilityTailoring={affordabilityAcceptProps!}
          onAcceptOriginalOffer={
            affordabilityAcceptProps?.onAcceptOriginalOffer
          }
          applicationId={id}
          hideApplicationText={false}
        />
      ) : (
        <>
          <Text my={4} mt={1}>
            Your application will be accepted!
          </Text>
          <Text my={4} sx={{ fontWeight: 'bold' }}>
            A full credit search will be performed when you apply, leaving a
            footprint on your credit file that is visible to other lenders.
          </Text>
          <Box mt={4} sx={{ display: ['block', null, null, 'none'] }}>
            <QuoteSummary />
          </Box>
          <Button
            mt={2}
            isJumbo
            onClick={convertSoftSearchOutcomeToDecisionFunction}
            data-test-id='mo-softsearch-decision-accept-button'>
            Apply now
          </Button>
        </>
      )}
    </NovunaPageLayout>
  );
};

export default SoftSearchAccept;
