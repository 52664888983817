import { push } from 'connected-react-router';
import { call, put, takeLeading } from 'redux-saga/effects';
import { getPasswordService, PasswordService } from 'services/passwordService';
import { getType } from 'typesafe-actions';
import { routes } from '../../routes';
import {
  confirmForgotPassword,
  sendPasswordResetCode
} from '../../store/user/actions';
import {
  ConfirmForgotPasswordAction,
  SendPasswordResetCodeAction
} from '../../store/user/reducer';
import { DefaultSagaParams } from '../index';

interface GetConfirmForgotPasswordSagaParams {
  passwordService: PasswordService;
}

export const getSendPasswordResetCodeSaga = ({
  passwordService
}: GetConfirmForgotPasswordSagaParams) =>
  function*(action: SendPasswordResetCodeAction) {
    if (action.type === 'SEND_PASSWORD_RESET_CODE_REQUEST') {
      try {
        yield call(passwordService.sendPasswordResetCode, action.payload);
        yield put(sendPasswordResetCode.success());
      } catch (err) {
        yield put(sendPasswordResetCode.failure(err));
      }
    }
  };

export const getConfirmForgotPasswordSaga = ({
  passwordService
}: GetConfirmForgotPasswordSagaParams) =>
  function*(action: ConfirmForgotPasswordAction) {
    if (action.type === 'CONFIRM_FORGOT_PASSWORD_REQUEST') {
      try {
        yield call(passwordService.confirmForgotPassword, action.payload);

        yield put(confirmForgotPassword.success());
        yield put(push(routes.login));
      } catch (err) {
        yield put(confirmForgotPassword.failure(err));
      }
    }
  };

export const getSendPasswordResetCodeSagaWatcher = ({
  apiClient
}: DefaultSagaParams) =>
  function*() {
    const passwordService = getPasswordService({
      apiClient
    });

    yield takeLeading(
      getType(sendPasswordResetCode.request),
      getSendPasswordResetCodeSaga({ passwordService })
    );
  };

export const getConfirmForgotPasswordSagaWatcher = ({
  apiClient
}: DefaultSagaParams) =>
  function*() {
    const passwordService = getPasswordService({
      apiClient
    });

    yield takeLeading(
      getType(confirmForgotPassword.request),
      getConfirmForgotPasswordSaga({ passwordService })
    );
  };
