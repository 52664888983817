import { ActionType, createStandardAction } from 'typesafe-actions';
import * as applicationAmend from './constants';

export type SetLoanAmendAction = ActionType<typeof setLoanAmend>;
export type ResetLoanAmendAction = ActionType<typeof resetLoanAmend>;

export const setLoanAmend = createStandardAction(
  applicationAmend.IS_LOAN_AMEND
)();

export const resetLoanAmend = createStandardAction(
  applicationAmend.RESET_LOAN_AMEND
)();
