import {
  Button,
  InputWrapper,
  Message,
  NovunaHeading,
  TertiaryLink,
  TertiaryLinkDirection,
  Text,
  TextField
} from 'compass-design';
import { Field, FieldProps, Form, Formik } from 'formik';
import { validationMessages } from 'hitachi-retail-core/build/application/messages';
import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router';
import { LoggedOutWrapper } from 'retailerApp/components/LoggedOutWrapper';
import * as yup from 'yup';
import FeatureToggle from '../../../containers/meta/FeatureToggle';
import { routes } from '../../../routes';

interface SetNewPasswordValues {
  retailerName: string;
  username: string;
  password: string;
  session: string;
  givenName: string;
  familyName: string;
}

export interface SetNewPasswordPropsFromState {
  errorMessage?: string;
  username?: string;
  retailerName?: string;
  familyName?: string;
  givenName?: string;
  code?: string;
  session?: string;
  setPasswordProcessing: boolean;
  setPasswordSuccess: boolean;
}

export interface SetNewPasswordPropsFromDispatch {
  onSubmit: (values: SetNewPasswordValues) => void;
  onBackToLogin: () => void;
}

export type SetNewPasswordProps = SetNewPasswordPropsFromState &
  SetNewPasswordPropsFromDispatch;

export interface SetNewPasswordState {
  password: string;
  passwordError?: string;
}

// TODO Remove this component once Stronger password is rolled out.

export const SetNewPassword = ({
  username,
  retailerName,
  familyName,
  givenName,
  session,
  errorMessage: serviceErrorMessage,
  setPasswordProcessing,
  setPasswordSuccess,
  onSubmit,
  onBackToLogin
}: SetNewPasswordProps) => {
  if (!session) {
    return <Redirect to={{ pathname: routes.login }} />;
  }

  if (!setPasswordProcessing && setPasswordSuccess) {
    return <Redirect to={{ pathname: routes.index }} />;
  }

  const initialValues: SetNewPasswordValues = {
    retailerName: retailerName ?? '',
    username: username ?? '',
    password: '',
    familyName: familyName ?? '',
    givenName: givenName ?? '',
    session: session ?? ''
  };

  return (
    <FeatureToggle feature={CompassFeature.ENHANCED_AUTHENTICATION}>
      <Helmet>
        <title>Set password - CreditMaster3</title>
      </Helmet>
      <LoggedOutWrapper
        backLink={
          <TertiaryLink
            text='Sign in'
            direction={TertiaryLinkDirection.BACKWARDS}
            onClick={() => onBackToLogin()}
          />
        }>
        {serviceErrorMessage && (
          <Message
            mb={4}
            variant='error'
            data-test-id='compass-set-password-service-error'>
            <NovunaHeading as='h3' mb={1}>
              Something went wrong
            </NovunaHeading>
            <Text>{serviceErrorMessage}</Text>
          </Message>
        )}
        <NovunaHeading as='h1' mb={3}>
          Set password
        </NovunaHeading>
        <Text mb={4}>
          You have successfully logged in for the first time. Please set your
          password.
        </Text>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={values => onSubmit(values)}
          enableReinitialize>
          <Form noValidate>
            <Field name='password'>
              {({ field, meta }: FieldProps<string, SetNewPasswordValues>) => (
                <InputWrapper
                  id='set-password'
                  label='Password'
                  error={meta.error}
                  tone={meta.touched && meta.error ? 'negative' : 'neutral'}>
                  <TextField
                    id='set-password'
                    type='password'
                    data-test-id='compass-set-password-control'
                    tone={meta.touched && meta.error ? 'negative' : 'neutral'}
                    {...field}
                  />
                </InputWrapper>
              )}
            </Field>
            <Button
              mt={5}
              disabled={setPasswordProcessing}
              data-test-id='compass-set-password-submit'
              isJumbo>
              {setPasswordProcessing ? 'Please wait\u2026' : 'Confirm'}
            </Button>
          </Form>
        </Formik>
      </LoggedOutWrapper>
    </FeatureToggle>
  );
};

const validationSchema = yup.object<Pick<SetNewPasswordValues, 'password'>>({
  password: yup.string().required(validationMessages.REQUIRED_FIELD)
});

export default SetNewPassword;
