import { SigningCallbackStatuses } from 'hitachi-retail-core/build/enums';
import { push } from 'connected-react-router';
import { put, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import {
  generateApplicationPath,
  MailOrderApplicationPathParams,
  routes
} from '../../routes';
import { signingRedirect } from '../../store/signingRedirects/actions';
import { SigningRedirectAction } from '../../store/signingRedirects/reducer';

export const getSigningRedirectSaga = () =>
  function*(action: SigningRedirectAction) {
    if (action.type === getType(signingRedirect)) {
      const {
        signingRedirects: { signingCompletePath }
      } = action.payload;
      const searchParams = new URLSearchParams(window.location.search);

      const applicationId = searchParams.get('applicationId');
      const status = searchParams.get('status');

      if (!applicationId) {
        throw new Error(
          'Signing callback did not contain applicationId parameter'
        );
      }

      if (!status) {
        throw new Error('Signing callback did not contain status parameter');
      }

      switch (status) {
        case SigningCallbackStatuses.Complete:
          if (signingCompletePath) {
            yield put(push(signingCompletePath));
          }
          break;
        case SigningCallbackStatuses.Decline:
          yield put(
            push(
              generateApplicationPath<MailOrderApplicationPathParams>(
                routes.mailOrder.application.loanInformation,
                { id: applicationId }
              )
            )
          );
          break;
        case SigningCallbackStatuses.Timeout:
          yield put(
            push(
              generateApplicationPath<MailOrderApplicationPathParams>(
                routes.mailOrder.application.keyLoanFeatures,
                { id: applicationId }
              )
            )
          );
          break;
        default:
          return;
      }
    }
  };

export const getSigningRedirectSagaWatcher = () =>
  function*() {
    yield takeEvery(getType(signingRedirect), getSigningRedirectSaga());
  };
