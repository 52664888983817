import React, { useCallback, useState } from 'react';
import { Box, InputWrapper, RadioGroup, TextField } from 'compass-design';
import {
  Field,
  FieldProps,
  Form,
  FormikProps,
  validateYupSchema,
  yupToFormErrors,
  Formik
} from 'formik';
import {
  employmentStatus,
  financialDependants
} from 'hitachi-retail-core/build/enums';
import {
  financialDetailsSchema as baseFinancialDetailsSchema,
  spouseEmploymentStatusRequired
} from 'hitachi-retail-core/build/schemas/applicantDetails';
import * as yup from 'yup';
import { enumToRadioOptions, parseNumericInput } from '../helpers';
import { labelPropsFromMeta } from 'utils/labelPropsFromMeta';
import { Step, StepComponentProps } from '../MultiStepForm';
import { DetailsCaptureValues } from '../schema';
import { getNavigationButtons } from '../navigationButtons';
import { getTone } from 'utils/getTone';
import IncomeConfirmationModal from '../IncomeConfirmationModal';

export const financialDetailsSchema = yup.object({
  financialDetails: baseFinancialDetailsSchema
});

const validate = async (values: DetailsCaptureValues) => {
  try {
    await validateYupSchema<DetailsCaptureValues>(
      values,
      financialDetailsSchema,
      undefined,
      {
        maritalStatus: values.personalDetails.maritalStatus
      }
    );
  } catch (err) {
    return yupToFormErrors(err);
  }
  return {};
};

const FinancialDetails: React.FC<StepComponentProps<DetailsCaptureValues>> = ({
  initialValues,
  returnUrl,
  supplierOrderReference,
  applicationId,
  multiStepSubmit
}) => {
  const [isConfirmModalVisible, setConfirmModalVisible] = useState(false);

  const [hasModalDisplayed, setHasModalDisplayed] = useState(false);

  const openModal = useCallback(() => {
    setConfirmModalVisible(true);

    setHasModalDisplayed(true);
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, formikBag) => {
        values.financialDetails.incomeValue < 7500 && !hasModalDisplayed
          ? openModal()
          : multiStepSubmit(values, formikBag);
      }}
      validate={values => validate(values as any)}>
      {(formikBag: FormikProps<DetailsCaptureValues>) => (
        <>
          <Form>
            <Field name={`financialDetails.employmentStatus`}>
              {({
                field,
                meta,
                form
              }: FieldProps<string, DetailsCaptureValues>) => {
                const handleChange = (e: React.ChangeEvent<any>) => {
                  formikBag.handleChange(e);

                  if (
                    !spouseEmploymentStatusRequired(
                      e.target.value,
                      formikBag.values.personalDetails.maritalStatus
                    )
                  ) {
                    form.setFieldValue(
                      'financialDetails.spouseEmploymentStatus',
                      ''
                    );
                  }
                };
                return (
                  <Box>
                    <InputWrapper
                      id='financialDetails.employmentStatus'
                      label='Employment status'
                      error={meta.error}
                      {...labelPropsFromMeta({ meta }, 'label')}>
                      <RadioGroup
                        name='financialDetails.employmentStatus'
                        options={enumToRadioOptions(
                          employmentStatus,
                          field.value,
                          'financialDetails.employmentStatus'
                        )}
                        onChange={handleChange}
                      />
                    </InputWrapper>
                  </Box>
                );
              }}
            </Field>
            {spouseEmploymentStatusRequired(
              formikBag.values.financialDetails.employmentStatus,
              formikBag.values.personalDetails.maritalStatus
            ) ? (
              <Field name={`financialDetails.spouseEmploymentStatus`}>
                {({
                  field,
                  meta
                }: FieldProps<string, DetailsCaptureValues>) => (
                  <Box mt={4}>
                    <InputWrapper
                      id='financialDetails.spouseEmploymentStatus'
                      label='Spouse or partner employment status'
                      error={meta.error}
                      {...labelPropsFromMeta({ meta }, 'label')}>
                      <RadioGroup
                        name='financialDetails.spouseEmploymentStatus'
                        options={enumToRadioOptions(
                          employmentStatus,
                          field.value,
                          'financialDetails.spouseEmploymentStatus'
                        )}
                        onChange={formikBag.handleChange}
                      />
                    </InputWrapper>
                  </Box>
                )}
              </Field>
            ) : null}

            <Field name={`financialDetails.incomeValue`}>
              {({
                field,
                meta,
                form
              }: FieldProps<string, DetailsCaptureValues>) => (
                <Box mt={4}>
                  <InputWrapper
                    id='financialDetails.incomeValue'
                    label='Annual income'
                    description='The amount you earn in one year, before tax and deductions'
                    error={meta.error}
                    tone={getTone(meta)}
                    {...labelPropsFromMeta({ meta }, 'label')}>
                    <TextField
                      short
                      {...labelPropsFromMeta({ meta }, 'input')}
                      type='tel'
                      {...field}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        form.setFieldValue(
                          'financialDetails.incomeValue',
                          parseNumericInput(e.target.value)
                        );
                      }}
                      id='financialDetails.incomeValue'
                      tone={getTone(meta)}
                    />
                  </InputWrapper>
                </Box>
              )}
            </Field>

            <Field name={`financialDetails.financialDependants`}>
              {({ field, meta }: FieldProps<string, DetailsCaptureValues>) => (
                <Box mt={4}>
                  <InputWrapper
                    id='financialDetails.financialDependants'
                    label='Financial dependants'
                    description='Under the age of 18'
                    error={meta.error}
                    {...labelPropsFromMeta({ meta }, 'label')}>
                    <RadioGroup
                      name='financialDetails.financialDependants'
                      options={enumToRadioOptions(
                        financialDependants,
                        field.value,
                        'financialDetails.financialDependants'
                      )}
                      onChange={formikBag.handleChange}
                    />
                  </InputWrapper>
                </Box>
              )}
            </Field>
            {getNavigationButtons({
              formikBag,
              applicationId,
              returnUrl,
              supplierOrderReference
            })}
          </Form>
          <IncomeConfirmationModal
            data-test-id='income-confirmation-modal'
            show={isConfirmModalVisible}
            onSetIsOpen={setConfirmModalVisible}
          />
        </>
      )}
    </Formik>
  );
};

export const financialDetailsStep: Step<DetailsCaptureValues> = {
  component: FinancialDetails,
  title: 'Financial details',
  description: 'About your financial situation'
};
