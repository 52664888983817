import { Button, NovunaHeading, SectionError, Text } from 'compass-design';
import React from 'react';

export interface DocumentDownloadProps {
  documentUrl: string;
  heading: string;
  body: string;
  dataTestId: string;
  buttonText: string;
  canDownload: boolean;
  errorMessage?: string;
  forceDownload?: boolean;
  onDownloadClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const DocumentDownload: React.FC<DocumentDownloadProps> = ({
  documentUrl,
  heading,
  body,
  dataTestId,
  buttonText,
  canDownload,
  errorMessage,
  forceDownload = false,
  onDownloadClick
}) => (
  <div>
    <NovunaHeading as='h2' mb={2}>
      {heading}
    </NovunaHeading>
    <Text mb={5}>{body}</Text>
    {errorMessage && <SectionError mt={3}>{errorMessage}</SectionError>}
    <Button
      variant='secondary'
      disabled={!canDownload}
      {...(canDownload && {
        as: 'a',
        href: documentUrl,
        target: '_blank',
        download: forceDownload
      })}
      data-test-id={dataTestId}
      onClick={onDownloadClick}>
      {canDownload ? buttonText : 'Preparing\u2026'}
    </Button>
  </div>
);

export default DocumentDownload;
