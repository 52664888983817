import { ApplicantApplicationProps } from 'applicantApp/pages/ApplicantApplication/ApplicantApplication';
import React from 'react';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import DecisionAccept from './components/DecisionAccept';
import ApplicationStatusFetch from './components/ApplicationStatusFetch';
import { Text } from 'compass-design';
import DecisionDecline from './components/DecisionDecline';
import DecisionRefer from './components/DecisionRefer';
import DecisionError from './components/DecisionError';
import { JourneyType } from 'hitachi-retail-core/build/enums';
import { generateWsRedirectUrl } from 'applicantApp/components/ExternalRedirectButtons/ExternalRedirectButtons';
import { useSelector } from 'react-redux';
import {
  selectApplicationDocument,
  selectIsPbfJourney
} from 'store/application/selectors';
import { FinanceApplication } from 'hitachi-retail-core';
import { getLoanDetails } from '../../../retailerApp/utils/loanDetails/getLoanDetails';
import { setDecisionError } from '../../../retailerApp/pages/ApplicationDecision/components/DecisionError/DecisionError';
import { selectIsNonBrokered } from 'hitachi-retail-core/build/selectors/selectIsNonBrokered';

const DecisionOutcome: React.FC<ApplicantApplicationProps> = ({
  activeApplication,
  id,
  decisionErrorMessage,
  retailer,
  enabledFeatures,
  tailoringExpiryDate,
  onConfirmTailoringUpdate,
  onAcceptOriginalOffer,
  isDecisionLoading
}) => {
  let component;
  const isPbf = useSelector(selectIsPbfJourney);
  const financeApplication = useSelector(
    selectApplicationDocument
  ) as FinanceApplication;
  const maxInstalmentAmount =
    financeApplication.loanTailoring?.maxInstalmentAmount;

  const isNonBrokered = selectIsNonBrokered(financeApplication);

  const loanDetails = getLoanDetails({
    document: financeApplication,
    isSoftSearch: false,
    enabledFeatures
  });

  if (decisionErrorMessage) {
    const decisionError = setDecisionError(decisionErrorMessage);
    return (
      <DecisionError
        applicationId={id}
        retailerName={retailer.name}
        decisionError={decisionError}
        isNonBrokered={isNonBrokered}
      />
    );
  }

  if (
    activeApplication.status === ApplicationStatus.open ||
    isDecisionLoading
  ) {
    return <ApplicationStatusFetch />;
  }

  const returnUrlStatus =
    activeApplication.status === ApplicationStatus.decision_refer
      ? 'referred'
      : 'declined';

  const returnUrl = activeApplication.document.returnUrl;
  const journeyType = activeApplication.document.journeyType;

  if (
    activeApplication.status !== ApplicationStatus.decision_accept &&
    journeyType === JourneyType.WebServices &&
    returnUrl
  ) {
    window.location.replace(
      generateWsRedirectUrl(
        returnUrl,
        id,
        returnUrlStatus,
        activeApplication.document.orderReference
      ).toString()
    );
  }

  switch (activeApplication.status) {
    case ApplicationStatus.decision_accept:
    case ApplicationStatus.deposit_paid:
    case ApplicationStatus.deposit_attempted:
      component = (
        <DecisionAccept
          id={id}
          financeApplication={financeApplication}
          loanDetails={loanDetails!}
          maxInstalmentAmount={maxInstalmentAmount}
          enabledFeatures={enabledFeatures}
          tailoringReduceTermEnabled={retailer.tailoringReduceTermEnabled}
          tailoringIbcReduceTermEnabled={retailer.tailoringIbcReduceTermEnabled}
          tailoringExpiryDate={tailoringExpiryDate}
          onConfirmTailoringUpdate={onConfirmTailoringUpdate}
          onAcceptOriginalOffer={onAcceptOriginalOffer}
        />
      );
      break;
    case ApplicationStatus.decision_decline:
      component = (
        <DecisionDecline
          id={id}
          isPbf={isPbf}
          financeApplication={financeApplication}
          loanDetails={loanDetails!}
          maxInstalmentAmount={maxInstalmentAmount}
          status={activeApplication.status}
          enabledFeatures={enabledFeatures}
          onConfirmTailoringUpdate={onConfirmTailoringUpdate}
        />
      );
      break;
    case ApplicationStatus.decision_refer:
      component = <DecisionRefer applicationId={id} />;
      break;
    default:
      component = <Text>UNEXPECTED STATUS: {activeApplication.status}</Text>;
  }

  return component;
};

export default DecisionOutcome;
