import { push } from 'connected-react-router';
import {
  connect,
  MapDispatchToPropsFunction,
  MapStateToProps
} from 'react-redux';
import { generateApplicationPath, routes } from '../../../routes';
import { RootState } from '../../../store';
import { updateActiveApplication } from '../../../store/application/actions';
import { fetchDecision } from '../../../store/decision/actions';
import { saveApplication } from '../../../store/save/actions';
import { ApplicationIdRouteProps } from '../../components/ApplicationRouteProps/ApplicationRouteProps';
import { gotoFormStep } from '../../store/changeFormStep/actions';
import { sendPrecontractInfo } from '../../store/sendPrecontractInfo/actions';
import {
  ApplicantApplication,
  ApplicantApplicationPropsFromDispatch,
  ApplicantApplicationPropsFromState
} from './ApplicantApplication';
import { DetailsCaptureValues } from 'applicantApp/pages/Details/components/schema';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import { signApplication } from 'store/sign/actions';

export type ApplicantApplicationOwnProps = ApplicationIdRouteProps;

export const mapStateToProps: MapStateToProps<
  ApplicantApplicationPropsFromState,
  ApplicantApplicationOwnProps,
  RootState
> = (
  {
    application: { activeApplication, fetchApplicationStatus },
    retailer: { retailer },
    applicantConfig: { decisionPollInterval },
    decision: { errorMessage, tailoringExpiryDate, processing },
    config: { enabledFeatures }
  },
  { match }
) => ({
  activeApplication,
  retailer,
  fetchApplicationStatus,
  id: match?.params.id ?? '',
  decisionPollInterval,
  decisionErrorMessage: errorMessage,
  enabledFeatures,
  tailoringExpiryDate:
    activeApplication.tailoringExpiryDate ?? tailoringExpiryDate,
  isDecisionLoading: processing ?? false
});

export const mapDispatchToProps: MapDispatchToPropsFunction<
  ApplicantApplicationPropsFromDispatch,
  ApplicantApplicationOwnProps
> = (dispatch, { match }) => {
  const id = match?.params.id;

  const pushRoute = (route: string) => {
    if (id) {
      dispatch(push(generateApplicationPath(route, { id })));
    }
  };

  return {
    completeKeyLoanFeatures: () => {
      pushRoute(routes.mailOrder.application.loanInformation);
    },
    completeLoanAdvanceInformation: () => {
      if (id) {
        dispatch(signApplication.request({ id }));
      }
    },
    completeSigning: () => {
      pushRoute(routes.mailOrder.application.postSign);
    },
    startLoanAdvanceInformation: () => {
      if (id) {
        dispatch(sendPrecontractInfo.request({ id }));
      }
    },
    onSave: (formData: DetailsCaptureValues) => {
      dispatch(
        updateActiveApplication({ document: formData, replaceValues: true })
      );
      dispatch(saveApplication.request());
    },
    onDetailsCaptureComplete: () => {
      pushRoute(routes.mailOrder.application.summary);
    },
    onSummaryComplete: () => {
      dispatch(
        fetchDecision.request({
          id: id
        })
      );
      pushRoute(routes.mailOrder.application.decisionOutcome);
    },
    gotoFormStep: (stepIndex: number) => {
      dispatch(gotoFormStep(stepIndex));
    },
    onConfirmTailoringUpdate: async ({ document, tailoringOptionSelected }) => {
      dispatch(
        updateActiveApplication({
          document,
          replaceValues: true,
          status: ApplicationStatus.open
        })
      );
      dispatch(fetchDecision.request({ id, tailoringOptionSelected }));
    },
    onAcceptOriginalOffer: async ({ document, tailoringOptionSelected }) => {
      dispatch(
        updateActiveApplication({
          document,
          replaceValues: true,
          status: ApplicationStatus.open
        })
      );
      dispatch(fetchDecision.request({ id, tailoringOptionSelected }));
    }
  };
};

export const connectApplicantApplication = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connectApplicantApplication(ApplicantApplication);
