import { replace } from 'connected-react-router';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getType, isOfType } from 'typesafe-actions';
import {
  generateApplicationPath,
  MailOrderApplicationPathParams,
  routes
} from '../../../routes';
import {
  fetchActiveApplication,
  PreviousApplicationDetail
} from '../../../store/previousApplicationDetail/actions';
import { FetchActiveApplicationAction } from '../../../store/save/reducer';
import { MailOrderApplicationService } from '../../../services/mailOrderApplicationService';

export interface MailOrderApplicationSagaParams {
  mailOrderApplicationService: Pick<
    MailOrderApplicationService,
    'getById' | 'saveApplication'
  >;
}

export const getFetchApplicationByIdSaga = ({
  mailOrderApplicationService
}: MailOrderApplicationSagaParams) =>
  function*(action: FetchActiveApplicationAction) {
    if (!isOfType(getType(fetchActiveApplication.request), action)) {
      return;
    }

    const { id, redirect } = action.payload;

    try {
      const response: PreviousApplicationDetail = yield call(
        mailOrderApplicationService.getById,
        { id, isMoPbf: true }
      );
      yield put(fetchActiveApplication.success(response));

      if (!redirect) {
        return;
      }

      yield put(
        replace(
          generateApplicationPath<MailOrderApplicationPathParams>(
            routes.mailOrder.application.index,
            { id }
          )
        )
      );
    } catch (err) {
      yield put(fetchActiveApplication.failure(err));
    }
  };

export const getFetchMailOrderApplicationSagaWatcher = ({
  mailOrderApplicationService
}: MailOrderApplicationSagaParams) =>
  function*() {
    yield takeEvery(
      getType(fetchActiveApplication.request),
      getFetchApplicationByIdSaga({ mailOrderApplicationService })
    );
  };
