import { Decimal } from 'hitachi-retail-core/build/utils/decimal';
import { IncompleteFinanceApplication } from 'hitachi-retail-core/build/schemas/financeApplication';
import {
  selectApr,
  selectDeposit,
  selectFinancialProduct,
  selectGoodsAmount,
  selectGoodsDescription,
  selectInstalments,
  selectLoanAmount,
  selectProductInstalments,
  selectProductMinimumLoanAmount,
  selectProductMaximumLoanAmount,
  selectProductConfig,
  selectRepaymentAmount,
  selectLoanTailoring,
  selectCreditCardDeposit,
  selectDeferralPeriod
} from 'hitachi-retail-core/build/selectors';
import getIncreaseDepositDetails from '../getIncreaseDepositDetails';
import getIncreaseTermDetails from '../getIncreaseTermDetails';
import getReduceLoanDetails from '../getReduceLoanDetails';
import getIncreaseLoanDetails from '../getIncreaseLoanDetails';
import getReduceTermDetails from '../getReduceTermDetails';
import { parseDecimal } from 'hitachi-retail-core/build/utils/decimal';
import { NewOfferComparisonCardProps } from 'retailerApp/components/MixingDeck/MixingDeck';
import { LoanComparisonCardPreStringValues } from 'retailerApp/components/MixingDeck/getMixingDeckData';
import {
  EditLoanOffer,
  TailoringOptionSelected
} from 'hitachi-retail-core/build/services/tailoring/types';
import {
  getInterestPayable,
  getTotalAmountPayable,
  getTotalRepaymentAmount
} from 'hitachi-retail-core/build/finance';
import { EditLoanCardStateProps } from 'retailerApp/components/EditLoanCardState/EditLoanCardState';
import {
  CompassFeature,
  FeatureConfig
} from 'hitachi-retail-core/build/features/features';

interface GetLoanDetails {
  isSoftSearch: boolean;
  document: IncompleteFinanceApplication;
  enabledFeatures: FeatureConfig;
}

export interface LoanParameters {
  minLoanAmountForProduct: Decimal;
  maxLoanAmountForProduct: Decimal;
  minDepositAmountForProduct: Decimal;
  minDepositPercentageForProduct: Decimal;
}

export interface LoanDetails {
  isSoftSearch: boolean;
  originalOffer?: Omit<LoanComparisonCardPreStringValues, 'updatedItem'>;
  increaseTermOffer?: NewOfferComparisonCardProps;
  increaseDepositOffer?: NewOfferComparisonCardProps;
  reduceLoanOffer?: EditLoanCardStateProps;
  increaseLoanOffer?: EditLoanCardStateProps;
  reduceTermOffer?: NewOfferComparisonCardProps;
  goodsDescription?: string;
  applicationRef?: string;
  loanParameters: LoanParameters;
  creditCardDeposit: boolean;
  tailoringOptionSelected?: TailoringOptionSelected;
  deferralPeriod?: number | null;
}

export const getLoanDetails = ({
  document,
  isSoftSearch,
  enabledFeatures
}: GetLoanDetails): LoanDetails | undefined => {
  const loanAmount = selectLoanAmount(document);
  const deposit = selectDeposit(document);
  const goodsAmount = selectGoodsAmount(document);
  const repaidOver = selectInstalments(document);
  const apr = selectApr(document);
  const productConfig = selectProductConfig(document);
  const instalments = selectProductInstalments(document);
  const productType = selectFinancialProduct(document);
  const minLoanAmountForProduct = parseDecimal(
    selectProductMinimumLoanAmount(document)
  );
  const maxLoanAmountForProduct =
    parseDecimal(selectProductMaximumLoanAmount(document)) ?? undefined;
  const monthlyRepaymentAmount = selectRepaymentAmount(document);
  const loanTailoring = selectLoanTailoring(document);
  const creditCardDeposit = !!selectCreditCardDeposit(document);
  const deferralPeriod = selectDeferralPeriod(document);

  if (
    !loanAmount ||
    !deposit ||
    !goodsAmount ||
    !repaidOver ||
    !apr ||
    !productConfig ||
    !instalments?.length ||
    !productType ||
    !minLoanAmountForProduct ||
    !maxLoanAmountForProduct ||
    !monthlyRepaymentAmount
  ) {
    return;
  }

  const totalRepayable = getTotalRepaymentAmount({
    instalments: repaidOver,
    repaymentAmount: monthlyRepaymentAmount
  });

  const totalAmountPayable = getTotalAmountPayable({
    instalments: repaidOver,
    deposit,
    repaymentAmount: monthlyRepaymentAmount
  });

  const originalOffer = {
    amountBorrowed: loanAmount,
    deposit: deposit,
    interestPayable: getInterestPayable({
      goodsAmount,
      totalAmountPayable
    }),
    interestRate: apr,
    monthlyRepayment: monthlyRepaymentAmount,
    repaidOver,
    representativeAPR: apr,
    totalCost: goodsAmount,
    totalRepayable,
    totalAmountPayable,
    isSoftSearch,
    deferralPeriod
  };

  const affordabilityAcceptFlag = enabledFeatures.has(
    CompassFeature.TAILORING_AFFORDABILITY_ACCEPT_FEATURE
  );

  const affordabilityDeclineFlag = enabledFeatures.has(
    CompassFeature.TAILORING_AFFORDABILITY_DECLINE_FEATURE
  );

  return {
    isSoftSearch,
    originalOffer,
    increaseTermOffer:
      loanTailoring?.increaseTerm && affordabilityDeclineFlag
        ? getIncreaseTermDetails({
            originalOffer,
            increaseTermOffer: loanTailoring.increaseTerm
          })
        : undefined,
    increaseDepositOffer:
      loanTailoring?.increaseDeposit && affordabilityDeclineFlag
        ? getIncreaseDepositDetails({
            originalOffer,
            increaseDepositOffer: loanTailoring.increaseDeposit
          })
        : undefined,
    reduceLoanOffer:
      loanTailoring?.reduceLoan && affordabilityDeclineFlag
        ? getReduceLoanDetails({
            originalOffer,
            productConfig,
            reduceLoanOffer: loanTailoring.reduceLoan as EditLoanOffer
          })
        : undefined,
    increaseLoanOffer:
      loanTailoring?.increaseLoan && affordabilityAcceptFlag
        ? getIncreaseLoanDetails({
            originalOffer,
            productConfig,
            increaseLoanOffer: loanTailoring.increaseLoan as EditLoanOffer
          })
        : undefined,
    reduceTermOffer:
      loanTailoring?.reduceTerm && affordabilityAcceptFlag
        ? getReduceTermDetails({
            originalOffer,
            reduceTermOffer: loanTailoring.reduceTerm
          })
        : undefined,
    goodsDescription: selectGoodsDescription(document),
    applicationRef: document.orderReference,
    loanParameters: {
      minLoanAmountForProduct,
      maxLoanAmountForProduct,
      minDepositAmountForProduct:
        parseDecimal(productConfig?.minDepositAmount) ?? new Decimal(0),
      minDepositPercentageForProduct:
        parseDecimal(productConfig?.minDepositPercentage) ?? new Decimal(0)
    },
    creditCardDeposit,
    tailoringOptionSelected: loanTailoring?.optionSelected,
    deferralPeriod
  };
};
