import {
  Box,
  InputWrapper,
  TextField,
  Text,
  Message,
  NovunaHeading,
  PurpleMail,
  InlineCheckbox
} from 'compass-design';
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikProps,
  validateYupSchema,
  yupToFormErrors
} from 'formik';
import { contactDetailsSchema as baseContactDetailsSchema } from 'hitachi-retail-core/build/schemas/applicantDetails';
import React from 'react';
import * as yup from 'yup';
import { getNavigationButtons } from '../navigationButtons';
import { Step, StepComponentProps } from '../MultiStepForm';
import { DetailsCaptureValues } from '../schema';
import { labelPropsFromMeta } from 'utils/labelPropsFromMeta';
import { getTone } from 'utils/getTone';

const contactDetailsSchema = yup.object({
  contactDetails: baseContactDetailsSchema
});

const validate = async (values: DetailsCaptureValues) => {
  try {
    await validateYupSchema<DetailsCaptureValues>(values, contactDetailsSchema);
  } catch (err) {
    return yupToFormErrors(err);
  }
  return {};
};

const MarketingPreferences: React.FC = () => (
  <Box mt={4}>
    <Message variant='info' icon={<PurpleMail />}>
      <Box>
        <NovunaHeading as='h3' mb={2}>
          Sign up for special discounts and lots more!
        </NovunaHeading>
        <Text mb={3}>
          We would love to stay in touch and let you know about products and
          special offers from Novuna. {"Don't"} miss out! Tick the box to let us
          know how you want to hear from us.
        </Text>
        <Text mb={2} sx={{ fontWeight: 'bold' }}>
          Please contact me by
        </Text>
        <Field name={'marketingConsent.emailOptIn'}>
          {({
            field,
            meta,
            form
          }: FieldProps<string, DetailsCaptureValues>) => {
            const emailOptInOnChangeHandler = (
              e: React.ChangeEvent<HTMLInputElement>
            ) => {
              form.setFieldValue(
                'marketingConsent.emailOptIn',
                e.target.checked
              );
              form.setFieldValue(
                'marketingConsent.lastUpdated',
                new Date().toISOString()
              );
            };
            return (
              <Box mb={3}>
                <InlineCheckbox
                  data-test-id='pbf-email-marketing-consent'
                  id='marketingConsent.emailOptIn'
                  {...labelPropsFromMeta({ meta }, 'input')}
                  checked={!!field.value || false}
                  onChange={emailOptInOnChangeHandler}>
                  Email
                </InlineCheckbox>
              </Box>
            );
          }}
        </Field>
        <Field name={'marketingConsent.postOptIn'}>
          {({
            field,
            meta,
            form
          }: FieldProps<string, DetailsCaptureValues>) => {
            const postOptInOnChangeHandler = (
              e: React.ChangeEvent<HTMLInputElement>
            ) => {
              form.setFieldValue(
                'marketingConsent.postOptIn',
                e.target.checked
              );
              form.setFieldValue(
                'marketingConsent.lastUpdated',
                new Date().toISOString()
              );
            };
            return (
              <InlineCheckbox
                data-test-id='pbf-post-marketing-consent'
                id='marketingConsent.postOptIn'
                onClick={() => {}}
                {...labelPropsFromMeta({ meta }, 'input')}
                checked={!!field.value || false}
                onChange={postOptInOnChangeHandler}>
                Post
              </InlineCheckbox>
            );
          }}
        </Field>
        {/* </ThemeProvider> */}
        <Text sx={{ fontSize: '14px' }} mt={3}>
          Novuna Personal Finance is a brand of Mitsubishi HC Capital UK PLC.
          Other brands under which we trade include Novuna Consumer Finance,
          Novuna Vehicle Solutions, Novuna Business Finance and Novuna Business
          Cash Flow.
        </Text>
      </Box>
    </Message>
  </Box>
);

const ContactDetails: React.FC<StepComponentProps<DetailsCaptureValues>> = ({
  initialValues,
  returnUrl,
  supplierOrderReference,
  applicationId,
  multiStepSubmit
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={(values, formikBag) => {
      multiStepSubmit(values, formikBag);
    }}
    validate={values => validate(values as any)}>
    {(formikBag: FormikProps<DetailsCaptureValues>) => (
      <Form>
        <Field name={`contactDetails.emailAddress`}>
          {({ field, meta }: FieldProps<string, DetailsCaptureValues>) => (
            <Box mt={5}>
              <InputWrapper
                id='contactDetails.emailAddress'
                label='Email address'
                error={meta.error}
                tone={getTone(meta)}
                {...labelPropsFromMeta({ meta }, 'label')}>
                <TextField
                  {...labelPropsFromMeta({ meta }, 'input')}
                  type='text'
                  {...field}
                  id='contactDetails.emailAddress'
                  tone={getTone(meta)}
                />
              </InputWrapper>
            </Box>
          )}
        </Field>

        <Field name={`contactDetails.phoneNumber`}>
          {({ field, meta }: FieldProps<string, DetailsCaptureValues>) => (
            <Box mt={5}>
              <InputWrapper
                id='contactDetails.phoneNumber'
                label='Phone number'
                error={meta.error}
                {...labelPropsFromMeta({ meta }, 'label')}>
                <TextField
                  short
                  {...labelPropsFromMeta({ meta }, 'input')}
                  type='tel'
                  {...field}
                  id='contactDetails.phoneNumber'
                  tone={getTone(meta)}
                />
              </InputWrapper>
            </Box>
          )}
        </Field>
        <MarketingPreferences />
        {getNavigationButtons({
          formikBag,
          applicationId,
          returnUrl,
          supplierOrderReference
        })}
      </Form>
    )}
  </Formik>
);

export const contactDetailsStep: Step<DetailsCaptureValues> = {
  component: ContactDetails,
  title: 'Contact details',
  description:
    'Please ensure these details are correct, as they will be used to confirm your identity when accessing the application.',
  shouldDisplay: ({ returnUrl }) => !!returnUrl
};
