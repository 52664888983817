import React from 'react';
import { Box, Message, NovunaHeading } from 'compass-design';

export const SystemErrorEvent = (): JSX.Element => (
  <Box mt={6} data-test-id='system-error-event-message'>
    <Message variant='info' restrictedWidth={false}>
      <NovunaHeading as='h3' mb={1}>
        Decision pending
      </NovunaHeading>
      The decision for this application is taking longer than expected. We will
      update the application as soon as the decision becomes available.
    </Message>
  </Box>
);
