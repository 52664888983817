import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Agreement, {
  PropsFromDispatch,
  PropsFromState
} from '../../components/agreement/Agreement';
import { RootState } from '../../store';
import { fetchAgreementBySigningId } from '../../store/agreement/actions';

export const mapStateToProps = (state: RootState): PropsFromState => {
  const {
    agreement: { pdfUrl, error, credentialsError, pdfBlob, fetching },
    router: {
      location: { search }
    }
  } = state;
  const params = new URLSearchParams(search);
  return {
    signingId: params.get('id') || '',
    pdfUrl,
    error,
    credentialsError,
    pdfBlob,
    fetching
  };
};

export const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  downloadFromServer: (signingId, verificationDetails) => {
    dispatch(
      fetchAgreementBySigningId.request({ signingId, verificationDetails })
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Agreement);
