import React from 'react';
import {
  Box,
  Button,
  Message,
  NovunaHeading,
  ReadOnlyWrapper,
  TertiaryLink,
  TertiaryLinkDirection,
  Text
} from 'compass-design';
import { useHistory, Link as ReactRouterLink } from 'react-router-dom';
import { generateApplicationPath, routes } from '../../../../../routes';
import { useSelector } from 'store';
import {
  selectApplicationDocument,
  selectApplicationReturnUrl,
  selectApplicationSupplierOrderReference
} from 'store/application/selectors';
import { selectDeposit } from 'hitachi-retail-core/build/selectors';
import { formatCurrency } from 'utils/formatters';
import { CancelApplicationTextButton } from 'applicantApp/components/ExternalRedirectButtons/ExternalRedirectButtons';
import NovunaPageLayout from 'applicantApp/components/NovunaPageLayout';

export interface OnlineDepositProps {
  id: string;
  sessionCheckInterval?: number;
}

const OnlineDepositPaid: React.FC<OnlineDepositProps> = ({ id }) => {
  const history = useHistory();

  const document = useSelector(selectApplicationDocument);
  const deposit = selectDeposit(document)?.toString() || '';
  const returnUrl = useSelector(selectApplicationReturnUrl);
  const supplierOrderReference = useSelector(
    selectApplicationSupplierOrderReference
  );
  const depositStr = formatCurrency(deposit);

  return (
    <NovunaPageLayout
      pageTop={
        <TertiaryLink
          direction={TertiaryLinkDirection.BACKWARDS}
          text='Application decision'
          onClick={() => {
            history.goBack();
          }}
        />
      }>
      <NovunaHeading
        as='h1'
        mb={2}
        data-test-id='online-deposit-paid-page-heading'>
        Deposit
      </NovunaHeading>
      <ReadOnlyWrapper
        label='Amount due'
        description='Payment card must be registered in your name to the home address used
          earlier in this application.'>
        <Text sx={{ fontWeight: 'bold' }} aria-labelledby='deposit'>
          {depositStr}
        </Text>
      </ReadOnlyWrapper>
      <Box mt={4} mb={5}>
        <Message variant='success'>
          <NovunaHeading as='h3' mb={1}>
            Payment received
          </NovunaHeading>
          <Text data-test-id='online-deposit-payment-received'>
            You must now electronically sign the agreement to accept the terms
            and conditions.
          </Text>
        </Message>
      </Box>

      <Button
        {...({
          to: generateApplicationPath(
            routes.mailOrder.application.keyLoanFeatures,
            {
              id
            }
          )
        } as any)}
        as={ReactRouterLink}
        data-test-id='online-deposit-paid-page-continue-button'
        isJumbo>
        Continue
      </Button>

      <Text
        sx={{ fontSize: 1 }}
        mt={6}
        data-test-id='online-deposit-paid-page-receipt'>
        A copy of your receipt has been emailed to you.
      </Text>

      <Box mt={5}>
        <CancelApplicationTextButton
          baseUrl={returnUrl}
          applicationId={id}
          supplierOrderReference={supplierOrderReference}
        />
      </Box>
    </NovunaPageLayout>
  );
};

export default OnlineDepositPaid;
