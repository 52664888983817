import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'store';
import { fetchDecision } from 'store/decision/actions';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import { getLoanDetails } from 'retailerApp/utils/loanDetails/getLoanDetails';
import getAffordabilityDecline from 'retailerApp/utils/loanDetails/getAffordabilityDecline';
import getAffordabilityAccept from 'retailerApp/utils/loanDetails/getAffordabilityAccept';

import { fetchDeclinedLetterById } from 'store/declinedEmail/actions';
import ApplicationSoftDecision, {
  ApplicationSoftDecisionPropsFromState,
  ApplicationSoftDecisionPropsFromDispatch
} from './ApplicationSoftDecision';
import {
  createNewApplication,
  updateActiveApplication
} from '../../../store/application/actions';
import { CompassFeature } from 'hitachi-retail-core/build/features/features';

export const mapStateToProps = ({
  decision: {
    decisionResult,
    errorMessage,
    maxInstalmentAmount,
    softSearchExpiryDate
  },
  declinedEmail: { pdfUrl, pdfBlob },
  config: { enabledFeatures },
  application: {
    activeApplication: { document }
  },
  save: { applicationId },
  retailer: {
    retailer: {
      tailoringIncreaseLoanEnabled,
      tailoringIbcIncreaseLoanEnabled,
      tailoringReduceTermEnabled,
      tailoringIbcReduceTermEnabled
    }
  },
  loanAmend: { isLoanAmend }
}: RootState): ApplicationSoftDecisionPropsFromState => {
  const loanDetails = getLoanDetails({
    document,
    isSoftSearch: true,
    enabledFeatures
  });

  const decisionApplicationStatus = !decisionResult
    ? undefined
    : (decisionResult as ApplicationStatus);

  const affordabilityDecline = getAffordabilityDecline({
    decisionApplicationStatus,
    maxInstalmentAmount,
    enabledFeatures,
    loanDetails
  });

  const tailoringFeatureAffordabilityAccept = enabledFeatures.has(
    CompassFeature.TAILORING_AFFORDABILITY_ACCEPT_FEATURE
  );

  const affordabilityAccept = getAffordabilityAccept({
    tailoringFeatureAffordabilityAccept,
    tailoringIncreaseLoanEnabled: !!tailoringIncreaseLoanEnabled,
    tailoringIbcIncreaseLoanEnabled: !!tailoringIbcIncreaseLoanEnabled,
    tailoringReduceTermEnabled: !!tailoringReduceTermEnabled,
    tailoringIbcReduceTermEnabled: !!tailoringIbcReduceTermEnabled,
    loanDetails
  });

  return {
    pdfUrl,
    pdfBlob,
    applicationId: applicationId || '',
    affordabilityDecline,
    affordabilityAccept,
    decisionApplicationStatus: decisionResult as ApplicationStatus,
    // TODO: Research error message options and designs
    decisionErrorMessage: errorMessage,
    loanDetails,
    softSearchExpiryDate: softSearchExpiryDate || '',
    document,
    isLoanAmend
  };
};

export const mapDispatchToProps = (
  dispatch: Dispatch
): ApplicationSoftDecisionPropsFromDispatch => ({
  onDownloadDeclinedLetter: (id: string) => {
    dispatch(fetchDeclinedLetterById.request({ id }));
  },
  onProceedToDecision: ({ document, tailoringOptionSelected }) => {
    dispatch(updateActiveApplication({ document, replaceValues: true }));
    dispatch(
      fetchDecision.request({ softSearch: false, tailoringOptionSelected })
    );
  },
  onApplicationAbort: () => dispatch(createNewApplication()),
  onDecisionRetry: () => dispatch(fetchDecision.request())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationSoftDecision);
