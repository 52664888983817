import React, { Fragment } from 'react';
import { push } from 'connected-react-router';
import {
  Button,
  Text,
  Box,
  ReadOnlyWrapper,
  TextButton,
  TertiaryLink,
  TertiaryLinkDirection
} from 'compass-design';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicantApplicationProps } from 'applicantApp/pages/ApplicantApplication/ApplicantApplication';
import { selectApplicationReturnUrl } from 'store/application/selectors';
import { generateApplicationPath, routes } from 'routes';
import ReadOnlyPersonalDetails from '../ReadOnlyPersonalDetail';
import ReadOnlyContactDetails from '../ReadOnlyContactDetails';
import { steps, toFormValues } from 'applicantApp/pages/Details';
import ReadOnlyFinancialDetails from '../ReadOnlyFinancialDetails';
import ReadOnlyBankDetails from '../ReadOnlyBankDetails';
import ReadOnlyAddressesSummary from '../ReadOnlyAddressesSummary';
import { contactDetailsStep } from 'applicantApp/pages/Details/components/ContactDetails';
import { personalDetailsStep } from 'applicantApp/pages/Details/components/PersonalDetails';
import { mainAddressDetailsStep } from 'applicantApp/pages/Details/components/Address/AddressDetails';
import { financialDetailsStep } from 'applicantApp/pages/Details/components/FinancialDetails';
import { bankDetailsStep } from 'applicantApp/pages/Details/components/BankDetails';
import { Step } from 'applicantApp/pages/Details/components/MultiStepForm';
import { DetailsCaptureValues } from 'applicantApp/pages/Details/components/schema';
import NovunaPageLayout from '../NovunaPageLayout';
import PageTitle from '../PageTitle';
import { CompassFeature } from 'hitachi-retail-core/build/features/features';
import { SoftSearchMessageQuote } from 'applicantApp/pages/SoftSearchOutcome/components/shared/SoftSearchMessages';
import { isSoftSearchEnabledForAgreement } from 'utils/isSoftSearchEnabled/isSoftSearchEnabled';

const ApplicationReview: React.FC<ApplicantApplicationProps> = ({
  id,
  activeApplication,
  onSummaryComplete,
  gotoFormStep,
  retailer,
  enabledFeatures
}) => {
  const dispatch = useDispatch();

  const returnUrl = useSelector(selectApplicationReturnUrl);

  const application = toFormValues(activeApplication.document);

  const isSoftSearch =
    isSoftSearchEnabledForAgreement(retailer, activeApplication.document) &&
    enabledFeatures.has(CompassFeature.MO_PBF_SOFT_SEARCH);

  const gotoDetails = () =>
    dispatch(
      push(
        generateApplicationPath(routes.mailOrder.application.details, { id })
      )
    );

  const goToDetailsStep = (detailsStep: Step<DetailsCaptureValues>) => {
    gotoFormStep(steps.indexOf(detailsStep));
    gotoDetails();
  };

  const createEditButton = (
    dataTestId: string,
    detailsStep: Step<DetailsCaptureValues>
  ) => (
    <TertiaryLink
      data-test-id={`${dataTestId}`}
      direction={TertiaryLinkDirection.FORWARDS}
      onClick={() => goToDetailsStep(detailsStep)}
      text='Edit'
    />
  );

  return (
    <NovunaPageLayout
      backToStoreStatus='abandoned'
      pageTop={
        <TertiaryLink
          direction={TertiaryLinkDirection.BACKWARDS}
          text='Bank details'
          onClick={() => gotoDetails()}
        />
      }>
      <Fragment>
        <PageTitle dataTestId='summary-review-page-heading'>Review</PageTitle>
        <Text sx={{ fontSize: 2 }}>
          Confirm that we have the correct information.
        </Text>
        <Box data-test-id='summary-review-page-personal' my={5}>
          <ReadOnlyWrapper
            label={personalDetailsStep.title as string}
            action={createEditButton(
              'summary-review-page-personal-edit',
              personalDetailsStep
            )}>
            <ReadOnlyPersonalDetails {...application.personalDetails} />
          </ReadOnlyWrapper>
        </Box>
        <Box data-test-id='summary-review-page-contact' my={5}>
          <ReadOnlyWrapper
            label={contactDetailsStep.title as string}
            action={
              returnUrl ? (
                createEditButton(
                  'summary-review-page-contact-edit',
                  contactDetailsStep
                )
              ) : (
                <TextButton sx={{ visibility: 'hidden' }}>Edit</TextButton>
              )
            }>
            <ReadOnlyContactDetails
              contactDetails={application.contactDetails}
              marketingConsent={application.marketingConsent}
            />
          </ReadOnlyWrapper>
        </Box>
        <Box data-test-id='summary-review-page-address' my={5}>
          <ReadOnlyWrapper
            label={mainAddressDetailsStep.title as string}
            action={createEditButton(
              'summary-review-page-address-edit',
              mainAddressDetailsStep
            )}>
            <ReadOnlyAddressesSummary {...application.mainAddressDetails} />
          </ReadOnlyWrapper>
        </Box>
        <Box data-test-id='summary-review-page-financial' my={5}>
          <ReadOnlyWrapper
            label={financialDetailsStep.title as string}
            action={createEditButton(
              'summary-review-page-financial-edit',
              financialDetailsStep
            )}>
            <ReadOnlyFinancialDetails {...application.financialDetails} />
          </ReadOnlyWrapper>
        </Box>
        <Box data-test-id='summary-review-page-bank' my={5}>
          <ReadOnlyWrapper
            label={bankDetailsStep.title as string}
            action={createEditButton(
              'summary-review-page-bank-edit',
              bankDetailsStep
            )}>
            <ReadOnlyBankDetails {...application.bankAccountDetails} />
          </ReadOnlyWrapper>
        </Box>
        {isSoftSearch && <SoftSearchMessageQuote />}
        <Box mt={5}>
          <Button
            isJumbo
            data-test-id='confirm-apply-application'
            type='submit'
            onClick={onSummaryComplete}>
            {isSoftSearch ? 'Check your eligibility' : 'Confirm and apply'}
          </Button>
        </Box>
      </Fragment>
    </NovunaPageLayout>
  );
};

export default ApplicationReview;
