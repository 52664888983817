import {
  AccordionGroup,
  AccordionGroupEntry,
  Box,
  NovunaHeading,
  Text
} from 'compass-design';
import {
  CancellationReason,
  cancellationReasonOptions
} from 'hitachi-retail-core/build/enums/applicationCancellation';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import {
  CompassFeature,
  FeatureConfig
} from 'hitachi-retail-core/build/features/features';
import { isProposeApplicationsEnabled as shouldProposeApplicationsEnabled } from 'hitachi-retail-core/build/repositories/retailDataAccessor';
import { ReferralNote } from 'hitachi-retail-core/build/schemas/referralNote';
import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import DecisionLoading from 'retailerApp/components/DecisionLoading';
import { SearchApplicationByIdRouteProps } from 'routes';
import { UnsavedReferralNote } from 'services/applicationsService';
import { AsyncStatus } from 'store/AsyncStatus';
import {
  CustomerSatisfactionNotes,
  PreviousApplicationDetail
} from 'store/previousApplicationDetail/actions';
import { getIsSoftSearchExpired } from 'utils/expiryDate';
import ApplicationActions from './components/ApplicationActions';
import ApplicationHistory from './components/ApplicationHistory/ApplicationHistory';
import CancelApplicationConfirmation from './components/CancelApplicationConfirmation';
import { renderDecisionEventMessage } from './components/DecisionEvents';
import Details from './components/Details/Details';
import { QuoteDetailProps } from './components/Details/getRowDetailsText';
import LoanAmendedCard from './components/LoanAmendedCard';
import PendingPayoutCard from './components/PendingPayoutCard';
import SoftSearchExpiredCard from './components/SoftSearchExpiredCard';
import SummaryApplicationNotes from './components/SummaryApplicationNotes';
import SummaryCustomerFeedback from './components/SummaryCustomerFeedback';
import SummaryReferralCard from './components/SummaryReferralCard';
import SummaryNonSlaReferralCard from './components/SummaryReferralCard/SummaryNonSlaReferralCard';
import LoanAmendInProgress from './components/LoanAmendInProgress/LoanAmendInProgress';

export interface ApplicationDetailPropsFromState {
  application?: PreviousApplicationDetail;
  agreementUrl?: string;
  quote?: QuoteDetailProps;
  loading: boolean;
  revertLoading: boolean;
  declinedLetterUrl?: string;
  requireCancellationReason?: boolean;
  enabledFeatures?: FeatureConfig;
  referralNotes?: ReferralNote[];
  referralNoteSubmissionStatus?: AsyncStatus;
  customerFeedback?: CustomerSatisfactionNotes;
  retailerFlags?: Pick<
    Retailer,
    | 'esatNoteEnabled'
    | 'proposeApplicationsEnabled'
    | 'partialSettlementsEnabled'
  >;
}

export interface ApplicationDetailPropsFromDispatch {
  fetchApplicationById: (id: string) => void;
  fetchAgreementUrl: (id: string) => void;
  requestPayment: (id: string) => void;
  resumeApplication: () => void;
  amendApplication: () => void;
  cancelApplication: (
    id: string,
    cancellationReason?: CancellationReason
  ) => void;
  requestPartialSettlement: (
    id: string,
    partialSettlementAmount: string
  ) => void;
  downloadDeclinedLetter: (id: string) => void;
  fetchApplicationReferralNotes: (id: string) => void;
  fetchAuditHistory: (id: string, branchId: string) => void;
  submitApplicationReferralNote: (
    id: string,
    note: UnsavedReferralNote
  ) => void;
  fetchCustomerFeedback: (id: string) => void;
  resendEsatNote: (id: string) => void;
  resetApplicationReferralNoteSubmissionStatus: () => void;
  resetLoanAmendStatus: () => void;
  revertApplicationVersionTo: (id: string, versionId?: string) => void;
  onCreditAgreementDownload: (applicationId: string) => void;
  createNewApplication: () => void;
  fetchRetailerByApplicationId: (id: string) => void;
}

export type ApplicationDetailProps = SearchApplicationByIdRouteProps &
  ApplicationDetailPropsFromState &
  ApplicationDetailPropsFromDispatch;

export const ApplicationDetail: React.FunctionComponent<ApplicationDetailProps> = ({
  match,
  application,
  agreementUrl,
  quote,
  loading,
  revertLoading,
  declinedLetterUrl,
  requireCancellationReason,
  enabledFeatures,
  referralNotes,
  referralNoteSubmissionStatus,
  customerFeedback,
  retailerFlags,
  fetchApplicationById,
  fetchAgreementUrl,
  amendApplication,
  resumeApplication,
  cancelApplication,
  requestPartialSettlement,
  requestPayment,
  downloadDeclinedLetter,
  fetchApplicationReferralNotes,
  submitApplicationReferralNote,
  fetchCustomerFeedback,
  resetApplicationReferralNoteSubmissionStatus,
  resetLoanAmendStatus,
  resendEsatNote,
  revertApplicationVersionTo,
  onCreditAgreementDownload,
  createNewApplication,
  fetchRetailerByApplicationId,
  fetchAuditHistory
}) => {
  const [
    cancelApplicationConfirmation,
    showCancelApplicationConfirmation
  ] = useState(false);

  const [noteModalOpen, setNoteModalOpen] = useState(false);

  const id = match?.params.id;
  const applicationStatus = application && application.status;
  const isPendingPayout =
    applicationStatus === ApplicationStatus.payout_pending;
  const isDecisionReferred =
    applicationStatus === ApplicationStatus.decision_refer;
  const isDecisionSlaCompliant =
    isDecisionReferred && application?.sla === true;

  const isNonBrokered = application && application.document.isNonBrokered;

  const isPartialSettlementsEnabled =
    (retailerFlags && retailerFlags.partialSettlementsEnabled) || false;
  const canPartiallySettle =
    isPartialSettlementsEnabled && applicationStatus === ApplicationStatus.paid;

  const canDownloadSignedAgreement =
    application?.canDownloadSignedAgreement === true;
  const canAmend =
    (applicationStatus === ApplicationStatus.decision_accept ||
      applicationStatus === ApplicationStatus.signed) &&
    !isNonBrokered;
  const isSoftSearchExpired = getIsSoftSearchExpired({
    softSearchExpiryDate: application?.softSearchExpiryDate,
    applicationStatus
  });

  const isEsatNoteEnabled = retailerFlags && retailerFlags.esatNoteEnabled;
  const declinedLetterDownloadLink = useRef<HTMLAnchorElement>(null);
  const branchId = application?.retailer?.supplierNumber;

  const isGlobalUserProfilesEnabled = enabledFeatures?.has(
    CompassFeature.GLOBAL_USER_PROFILES
  );

  // FX-950
  const hasPendingLoanAmend =
    quote?.pendingGoodsAmount || quote?.pendingDeposit;

  const isApplicationHistoryEnabled = enabledFeatures?.has(
    CompassFeature.RETAILER_APPLICATION_HISTORY
  );

  const isProposeApplicationsEnabled = shouldProposeApplicationsEnabled(
    isGlobalUserProfilesEnabled,
    retailerFlags?.proposeApplicationsEnabled === true
  );

  const loanAmount = quote?.loanAmount;

  const submitNote = (
    applicationId: string,
    note: UnsavedReferralNote
  ): void => {
    const sanitisedNote: UnsavedReferralNote = {
      username: note.username.trim(),
      message: note.message.trim()
    };
    submitApplicationReferralNote(applicationId, sanitisedNote);
  };

  // Get the application if an ID is passed
  useEffect(() => {
    if (id) {
      resetApplicationReferralNoteSubmissionStatus();
      resetLoanAmendStatus();
      fetchApplicationById(id);

      if (canDownloadSignedAgreement) {
        fetchAgreementUrl(id);
      }
    }
  }, [
    id,
    canDownloadSignedAgreement,
    fetchApplicationById,
    fetchAgreementUrl,
    resetApplicationReferralNoteSubmissionStatus,
    resetLoanAmendStatus
  ]);

  useEffect(() => {
    if (id) {
      fetchApplicationReferralNotes(id);
    }
  }, [id, fetchApplicationReferralNotes]);

  useEffect(() => {
    if (id && branchId) {
      fetchAuditHistory(id, branchId);
    }
  }, [id, branchId, fetchAuditHistory]);

  useEffect(() => {
    if (id) {
      fetchCustomerFeedback(id);
    }
  }, [id, fetchCustomerFeedback]);
  const isCustomerFeedbackPresent = (customerFeedback?.notes.length ?? 0) > 0;

  // Close modal if referral note was successfully submitted
  useEffect(() => {
    if (referralNoteSubmissionStatus === AsyncStatus.Success) {
      setNoteModalOpen(false);
    }
  }, [referralNoteSubmissionStatus, setNoteModalOpen]);

  useEffect(() => {
    declinedLetterDownloadLink.current &&
      declinedLetterDownloadLink.current.click();
  }, [declinedLetterUrl]);

  useEffect(() => {
    if (id && isProposeApplicationsEnabled) {
      fetchRetailerByApplicationId(id);
    }
  }, [id, fetchRetailerByApplicationId, isProposeApplicationsEnabled]);

  if (!application) {
    return null;
  }

  const pageContent = isApplicationHistoryEnabled ? (
    <>
      <AccordionGroup headingAs='h2'>
        <AccordionGroupEntry
          sectionNumber={1}
          title='Application details'
          headingVariant='tertiary'
          description=''
          initiallyOpen={true}>
          <Details
            application={application}
            quote={quote || {}}
            enabledFeatures={enabledFeatures}
          />
        </AccordionGroupEntry>

        <AccordionGroupEntry
          sectionNumber={2}
          title='Application notes'
          headingVariant='tertiary'
          description=''>
          <SummaryApplicationNotes
            isDecisionReferred={isDecisionReferred}
            referralNoteSubmissionStatus={referralNoteSubmissionStatus}
            noteModalOpen={noteModalOpen}
            applicationId={application.id}
            referralNotes={referralNotes}
            setNoteModalOpen={setNoteModalOpen}
            resetApplicationReferralNoteSubmissionStatus={
              resetApplicationReferralNoteSubmissionStatus
            }
            submitNote={submitNote}
          />
        </AccordionGroupEntry>

        <AccordionGroupEntry
          sectionNumber={3}
          title='Application history'
          headingVariant='tertiary'
          description=''>
          <ApplicationHistory />
        </AccordionGroupEntry>

        {(isEsatNoteEnabled || isCustomerFeedbackPresent) && (
          <>
            <AccordionGroupEntry
              sectionNumber={4}
              title='Customer feedback'
              headingVariant='tertiary'
              description=''>
              <SummaryCustomerFeedback
                isPendingEsatNote={
                  applicationStatus === ApplicationStatus.pending_esat_note
                }
                loading={loading}
                applicationId={application.id}
                resendEsatNote={resendEsatNote}
                notes={customerFeedback?.notes}
                isEsatNoteEnabled={isEsatNoteEnabled}
              />
            </AccordionGroupEntry>
          </>
        )}
      </AccordionGroup>
    </>
  ) : (
    <>
      <Box mt={4}>
        <NovunaHeading
          id='application-details-heading'
          as='h2'
          mb={3}
          data-test-id='application-summary-application-details-header'>
          Application details
        </NovunaHeading>
        <Details
          application={application}
          quote={quote || {}}
          enabledFeatures={enabledFeatures}
        />
      </Box>

      <Box mt={6}>
        <NovunaHeading
          as='h2'
          data-test-id='application-summary-application-notes-header'>
          Application notes
        </NovunaHeading>
        <SummaryApplicationNotes
          isDecisionReferred={isDecisionReferred}
          referralNoteSubmissionStatus={referralNoteSubmissionStatus}
          noteModalOpen={noteModalOpen}
          applicationId={application.id}
          referralNotes={referralNotes}
          setNoteModalOpen={setNoteModalOpen}
          resetApplicationReferralNoteSubmissionStatus={
            resetApplicationReferralNoteSubmissionStatus
          }
          submitNote={submitNote}
        />
      </Box>

      {(isEsatNoteEnabled || isCustomerFeedbackPresent) && (
        <Box mt={6}>
          <NovunaHeading
            as='h2'
            data-test-id='application-summary-customer-feedback-header'>
            Customer feedback
          </NovunaHeading>
          <SummaryCustomerFeedback
            isPendingEsatNote={
              applicationStatus === ApplicationStatus.pending_esat_note
            }
            loading={loading}
            applicationId={application.id}
            resendEsatNote={resendEsatNote}
            notes={customerFeedback?.notes}
            isEsatNoteEnabled={isEsatNoteEnabled}
          />
        </Box>
      )}
    </>
  );

  return (
    <>
      <Helmet>
        <title>{`Application ${application.id} - CreditMaster3`}</title>
      </Helmet>
      <div data-test-id='application-summary-header'>
        <>
          <NovunaHeading as='h1' id='application-summary-heading'>
            Application summary
          </NovunaHeading>
          <Text id='application-summary-id' mt={3} sx={{ fontWeight: 'bold' }}>
            {application.id}
          </Text>
        </>
      </div>
      <Box mt={3}>
        <ApplicationActions
          {...application}
          canResume={
            application.canResume &&
            !isSoftSearchExpired &&
            !hasPendingLoanAmend
          }
          canAmend={canAmend && !hasPendingLoanAmend}
          // Partial rename of action; 'Mark as delivered' may be fully
          // deprecated at some point
          // See: https://iw-hitachi.atlassian.net/browse/COM-1296
          canRequestPayment={application.canMarkAsDelivered}
          canPartiallySettle={canPartiallySettle}
          canDownloadSignedAgreement={canDownloadSignedAgreement}
          signedAgreementUrl={agreementUrl}
          // If status is pending_payout, we want to always display the cancel
          // button, but canCancel prop will disable it
          forceDisplayCancel={isPendingPayout}
          loading={loading}
          requestPayment={requestPayment}
          resumeApplication={resumeApplication}
          amendApplication={amendApplication}
          cancelApplication={() => showCancelApplicationConfirmation(true)}
          requestPartialSettlement={requestPartialSettlement}
          onCreditAgreementDownload={onCreditAgreementDownload}
          downloadDeclineLetter={downloadDeclinedLetter}
          declinedLetterUrl={declinedLetterUrl}
          declinedLetterDownloadLink={declinedLetterDownloadLink}
          loanAmount={loanAmount}
          esatNoteEnabled={isEsatNoteEnabled}
        />
      </Box>
      {isSoftSearchExpired && (
        <SoftSearchExpiredCard
          createNewApplication={createNewApplication}
          softSearchExpiryDate={application.softSearchExpiryDate}
        />
      )}
      {cancelApplicationConfirmation && (
        <CancelApplicationConfirmation
          cancellationReasonOptions={
            (requireCancellationReason && cancellationReasonOptions) ||
            undefined
          }
          onConfirm={cancellationReason =>
            cancelApplication(application.id, cancellationReason)
          }
          onClose={() => showCancelApplicationConfirmation(false)}
        />
      )}
      {isPendingPayout && <PendingPayoutCard />}
      {renderDecisionEventMessage({
        latestDecision: application.latestDecision
      })}
      {isDecisionReferred && isDecisionSlaCompliant && <SummaryReferralCard />}
      {isDecisionReferred && !isDecisionSlaCompliant && (
        <SummaryNonSlaReferralCard />
      )}
      {hasPendingLoanAmend && <LoanAmendInProgress />}
      <Box mt={4}>
        <LoanAmendedCard
          application={application}
          revertApplicationVersionTo={revertApplicationVersionTo}
          resumeApplication={resumeApplication}
        />
      </Box>
      {revertLoading && <DecisionLoading />}
      {pageContent}
    </>
  );
};

export default ApplicationDetail;
