import {
  Text,
  NovunaHeading,
  Box,
  Message,
  NovunaSingleColumnLayout
} from 'compass-design';
import React from 'react';
import { useSelector } from 'store';
import {
  selectApplicationReturnUrl,
  selectApplicationSupplierOrderReference
} from 'store/application/selectors';
import { RetailerRedirectButton } from 'applicantApp/components/ExternalRedirectButtons/ExternalRedirectButtons';
import { DecisionErrorEnum } from 'retailerApp/pages/ApplicationDecision/components/DecisionError/DecisionError';

export interface DecisionErrorProps {
  applicationId: string;
  retailerName: string;
  decisionError: DecisionErrorEnum;
  isNonBrokered?: boolean;
}

const DecisionError: React.FC<DecisionErrorProps> = ({
  applicationId,
  retailerName,
  decisionError,
  isNonBrokered
}) => {
  const returnUrl = useSelector(selectApplicationReturnUrl);
  const supplierOrderReference = useSelector(
    selectApplicationSupplierOrderReference
  );

  const decisionNotReturnedInTime =
    decisionError === DecisionErrorEnum.decisionNotReturnedInTime;

  const decisionErrorHeader = decisionNotReturnedInTime
    ? "Sorry, it's taking longer than usual"
    : 'Something went wrong';

  const decisionErrorMessage = decisionNotReturnedInTime
    ? 'We are working on getting you a decision and will notify you by email as soon as we have it.'
    : isNonBrokered
    ? `There was a problem submitting your application. Please contact our helpline on 0344 375 5515 and quote application reference ${applicationId}.`
    : `There was a problem submitting your application. Please contact ${retailerName} and quote application reference ${applicationId}.`;

  const redirectStatus = decisionNotReturnedInTime ? 'referred' : 'error';

  return (
    <NovunaSingleColumnLayout>
      <Box mx='auto' sx={{ maxWidth: '640px' }}>
        <NovunaHeading
          as='h1'
          mb={3}
          data-test-id='mo-decision-error-page-heading'>
          Application decision
        </NovunaHeading>
        <Box mb={3}>
          <Message variant='error'>
            <NovunaHeading
              data-test-id='mo-decision-error-page-novunaheading'
              as='h3'
              mb={1}>
              {decisionErrorHeader}
            </NovunaHeading>
            <Text data-test-id='mo-decision-error-page-content'>
              {decisionErrorMessage}
            </Text>
          </Message>
        </Box>

        {/** Only PBF will have 'returnUrl' */}
        {returnUrl && (
          <>
            <Text my={6}>Please return to the store to continue shopping.</Text>

            <RetailerRedirectButton
              novunaDisplay
              baseUrl={returnUrl}
              applicationId={applicationId}
              status={redirectStatus}
              buttonText='Continue shopping'
              supplierOrderReference={supplierOrderReference}
            />
          </>
        )}
      </Box>
    </NovunaSingleColumnLayout>
  );
};

export default DecisionError;
