import {
  CompassFeature,
  FeatureConfig
} from 'hitachi-retail-core/build/features/features';
import { RouteChildrenProps, generatePath } from 'react-router';

export type RoutePredicate = (route: string) => boolean;

const getApplicationRoutes = (base: string) => ({
  index: base,
  retailerSelection: `${base}/retailer-selection`,
  quote: `${base}/quote`,
  privacyPolicy: `${base}/privacy-policy`,
  details: `${base}/details/:applicationId?`,
  softSearch: `${base}/application-check`,
  loanAmend: `${base}/loan-amend`,
  decision: `${base}/decision`,
  preContractCreditInformation: `${base}/pre-contract-credit-information`,
  loanAdvanceInformation: `${base}/loan-advance-information`,
  complete: `${base}/complete/:applicationId`
});

export const getApplicationStartRoute = (enabledFeatures: FeatureConfig) => {
  if (enabledFeatures.has(CompassFeature.GLOBAL_USER_PROFILES)) {
    return routes.apply.retailerSelection;
  }
  return routes.apply.quote;
};

const getCustomerSatisfactionRoutes = (base: string) => ({
  dissatisfied: `${base}/dissatisfied/:token`,
  satisfied: `${base}/satisfied/:token`
});

interface PathParams {
  [paramName: string]: string | number | boolean | undefined;
}

export interface MailOrderApplicationPathParams extends PathParams {
  id: string;
}

export const generateApplicationPath = <T extends PathParams>(
  pattern: string,
  params: T
) => generatePath(pattern, params);

const getMailOrderApplicationRoutes = (base: string) => ({
  index: `${base}`,
  complete: `${base}/complete`,
  keyLoanFeatures: `${base}/signing/key-loan-features`,
  onlineDeposit: `${base}/deposit`,
  loanInformation: `${base}/signing/loan-information`,
  sign: `${base}/signing/sign`,
  postSign: `${base}/signing/complete`,
  quote: `${base}/quote`,
  json: `${base}/json`,
  details: `${base}/details`,
  summary: `${base}/summary`,
  decisionOutcome: `${base}/decision`,
  softSearchOutcome: `${base}/soft-search-decision`
});

const getMailOrderRoutes = () => ({
  index: '/',
  auth: '/auth',
  signingCompleteLanding: '/signing-callback',
  application: getMailOrderApplicationRoutes('/application/:id'),
  timeout: '/timeout'
});

const getSearchRoutes = (base: string) => ({
  index: base,
  applicationById: `${base}/:id`,
  getApplicationById: (id: string) => `${base}/${id}`
});

const getUsersRoutes = (base: string) => ({
  index: base
});

const getProfileRoutes = (base: string) => ({
  index: base,
  managePassword: `${base}/manage-password`
});

const getDirectAppRoutes = () => ({
  login: '/direct-apps/:token?',
  ...getApplicationRoutes('/apply'),
  detailsPath,
  timeout: '/timeout',
  error: '/error',
  applicationError: '/application-error'
});

// id param from path
export type SearchApplicationByIdRouteProps = RouteChildrenProps<{
  id: string;
}>;

export const detailsPath = '/apply/details';

export const routes = {
  index: '/',
  login: '/login',
  confirmForgotPassword: '/confirmForgotPassword',
  forgotPassword: '/forgotPassword',
  customerSatisfaction: getCustomerSatisfactionRoutes('/customer-satisfaction'),
  setNewPassword: '/new-password',
  error: '/error',
  applicationError: '/application-error',
  agreement: '/agreement',
  apply: getApplicationRoutes('/apply'),
  search: getSearchRoutes('/search'),
  timeout: '/timeout',
  mailOrder: getMailOrderRoutes(),
  directApps: getDirectAppRoutes(),
  users: getUsersRoutes('/users'),
  profile: getProfileRoutes('/profile')
};
