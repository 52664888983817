import { goodsDetailsSchemaConfig } from 'hitachi-retail-core/build/schemas/quote';
import { Dispatch } from 'react';
import { ValidationError } from 'yup';
import * as yup from 'yup';

export type ValidateGoodsDetailsProps = {
  goodsDescription: string;
  orderReference: string;
  setGoodsDescriptionError: Dispatch<any>;
  setOrderReferenceError: Dispatch<any>;
};

const schema = yup.object(goodsDetailsSchemaConfig);

export const validateGoodsDetails = ({
  goodsDescription,
  orderReference,
  setGoodsDescriptionError,
  setOrderReferenceError
}: ValidateGoodsDetailsProps) => {
  let goodsDescriptionError = '';
  let orderReferenceError = '';
  try {
    schema.validateSync(
      { goodsDescription, orderReference },
      { abortEarly: false }
    );
  } catch (errors) {
    if (errors instanceof ValidationError) {
      errors.inner.forEach(({ path, message }: ValidationError) => {
        if (path === 'goodsDescription') {
          goodsDescriptionError = message;
        } else {
          orderReferenceError = message;
        }
      });
    }
  }
  setGoodsDescriptionError(goodsDescriptionError);
  setOrderReferenceError(orderReferenceError);
};
