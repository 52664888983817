import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { RootState } from '..';
import { resetStore, ResetStoreAction } from '../actions';
import {
  resumeApplication,
  ResumeApplicationAction
} from '../application/actions';
import { sendLoanAdvanceInfo } from './actions';

export interface State {
  emailSentAutomatically: boolean;
}

export type SendPrecontractInfoAction =
  | ActionType<typeof sendLoanAdvanceInfo>
  | ResumeApplicationAction
  | ResetStoreAction;

const initialState: State = { emailSentAutomatically: false };

export const reducer: Reducer<State, SendPrecontractInfoAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(sendLoanAdvanceInfo.success): {
      return { ...state, emailSentAutomatically: true };
    }
    case getType(resumeApplication):
    case getType(resetStore): {
      return initialState;
    }
  }

  return state;
};

export const selectEmailSentAutomatically = (state: RootState) =>
  state.sendLoanAdvanceInfo.emailSentAutomatically;
