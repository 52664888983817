import {
  Box,
  Button,
  InputWrapper,
  ReactModal,
  TextArea,
  TextField
} from 'compass-design';
import { Field, FieldMetaProps, FieldProps, Form, Formik } from 'formik';
import { validationMessages } from 'hitachi-retail-core';
import React from 'react';
import Notification from 'retailerApp/components/Notification/Notification';
import { UnsavedReferralNote } from 'services/applicationsService';
import { AsyncStatus } from 'store/AsyncStatus';
import * as yup from 'yup';

export interface NoteModalProps {
  modalOpen: boolean;
  submissionStatus?: AsyncStatus;
  toggleModalOpen: (isOpen: boolean) => void;
  submitNote: (note: UnsavedReferralNote) => void;
}

export const NoteModal: React.FC<NoteModalProps> = ({
  modalOpen,
  submissionStatus,
  toggleModalOpen,
  submitNote
}) => {
  const processing = submissionStatus === AsyncStatus.Loading;

  const initialValues: UnsavedReferralNote = {
    username: '',
    message: ''
  };

  const fieldTone = ({ error, touched }: FieldMetaProps<string>) =>
    touched && error ? 'negative' : 'neutral';

  return (
    <ReactModal
      title='Add a note'
      size='medium'
      isOpen={modalOpen}
      setIsOpen={toggleModalOpen}>
      {submissionStatus === AsyncStatus.Failure && (
        <Notification
          message='Application note could not be saved. Please try again'
          type='danger'
        />
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={values => submitNote(values)}>
        <Form noValidate>
          <Field name='username'>
            {({ field, meta }: FieldProps<string, UnsavedReferralNote>) => (
              <Box mb={5} data-test-id='note-modal-username'>
                <InputWrapper
                  id='note-username'
                  label='Please enter your name'
                  tone={fieldTone(meta)}
                  error={meta.error}
                  stacked>
                  <TextField
                    {...field}
                    tone={fieldTone(meta)}
                    description='In case the Underwriting Team need to speak to you'
                    disabled={processing}
                    data-test-id='note-modal-username-field'
                  />
                </InputWrapper>
              </Box>
            )}
          </Field>
          <Field name='message'>
            {({ field, meta }: FieldProps<string, UnsavedReferralNote>) => (
              <Box mb={5} data-test-id='note-modal-message'>
                <InputWrapper
                  id='note-message'
                  label='Note'
                  tone={fieldTone(meta)}
                  error={meta.error}
                  stacked>
                  <TextArea
                    {...field}
                    tone={fieldTone(meta)}
                    rows={4}
                    disabled={processing}
                    data-test-id='note-modal-message-field'
                  />
                </InputWrapper>
              </Box>
            )}
          </Field>
          <Button
            isJumbo
            className='uk-button uk-button-primary uk-button-small'
            disabled={processing}
            data-test-id='note-modal-add-note'>
            Add note
          </Button>
        </Form>
      </Formik>
    </ReactModal>
  );
};

export const validationSchema = yup.object<UnsavedReferralNote>({
  username: yup
    .string()
    .transform((value, _) => value.trim())
    .required(validationMessages.REQUIRED_FIELD)
    .max(100, validationMessages.MAX_STRING_LENGTH_EXCEEDED),
  message: yup
    .string()
    .transform((value, _) => value.trim())
    .required(validationMessages.REQUIRED_FIELD)
    .max(300, validationMessages.MAX_STRING_LENGTH_EXCEEDED)
});

export default NoteModal;
