import { Address } from 'hitachi-retail-core/build/schemas/address';

const BranchLocationGenerator = (address: Address): string => {
  const { town, postCode } = address;

  return town.length > 12
    ? `${town.slice(0, 11)}... ${postCode.toLocaleUpperCase()}`
    : `${town}, ${postCode.toLocaleUpperCase()}`;
};

export default BranchLocationGenerator;
