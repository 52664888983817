import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  ConfirmForgotPasswordPropsFromDispatch,
  ConfirmForgotPasswordPropsFromState
} from './ConfirmForgotPassword';
import { RootState } from '../../../store';
import { selectRetailerSubdomain } from '../../../store/config/selectors';
import { confirmForgotPassword } from '../../../store/user/actions';
import { selectConfigStatus } from '../../../store/retailerConfig/reducer';
import ConfirmForgotStrongerPassword from './ConfirmForgotStrongerPassword';

export const mapStateToProps = (
  state: RootState
): ConfirmForgotPasswordPropsFromState => ({
  retailerName: selectRetailerSubdomain(state),
  configStatus: selectConfigStatus(state),
  errorMessage: selectErrorMessage(state),
  confirmForgotPasswordLoading: selectConfirmForgotPasswordLoading(state)
});

const selectErrorMessage = (state: RootState) => state.user.errorMessage;

const selectConfirmForgotPasswordLoading = (state: RootState) =>
  state.user.confirmForgotPasswordLoading;

export const mapDispatchToProps = (
  dispatch: Dispatch
): ConfirmForgotPasswordPropsFromDispatch => ({
  confirmForgotPassword: input => {
    dispatch(confirmForgotPassword.request(input));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmForgotStrongerPassword);
