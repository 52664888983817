import { NovunaSingleColumnLayout, NovunaSpinner } from 'compass-design';
import { Retailer } from 'hitachi-retail-core/build/schemas/retailer';
import { PgUser } from 'hitachi-retail-core/build/services/pgUsers/pgUsersService';
import { UserRole } from 'hitachi-retail-core/build/userPolicy';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaginateStateProps } from 'retailerApp/components/Paginate';
import PaginateState from 'retailerApp/components/Paginate/Paginate';
import {
  fetchEnhancedUsersAction,
  setDisableViewBranchWarning,
  setSelectedEnhancedBranch,
  setSelectedEnhancedBroker,
  setSelectedEnhancedHeadOffice,
  setSelectedEnhancedUser
} from 'store/enhancedUsers/actions';
import {
  selectDisableViewBranchWarning,
  selectSelectedEnhancedBranch,
  selectEnhancedUsers,
  selectSelectedEnhancedBroker,
  selectSelectedEnhancedHeadOffice,
  selectShowBranchSelectionPage
} from 'store/enhancedUsers/selectors';
import {
  selectAllBranches,
  selectRetailer,
  selectRetailerLoaded
} from 'store/retailer/selectors';
import { selectUserRoles } from 'store/user/selectors';
import BranchSelection from './components/BranchSelection';
import BranchSelectorModal from './components/BranchSelectorModal';
import BranchWarningModal from './components/BranchWarningModal';
import EnhancedUserPage from './components/EnhancedUserPage';
import UsersModal from './components/UserModal';
import { filterUsers, getPageTitle, Status } from './components/utils';

const EnhancedUsers = ({ currentPage, selectPage }: PaginateStateProps) => {
  const dispatch = useDispatch();
  const [branchWarning, setBranchWarning] = useState<Retailer | undefined>(
    undefined
  );
  const [showOnlyHeadOfficeUsers, setShowOnlyHeadOfficeUsers] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUserStatus, setSelectedUserStatus] = useState<Status>(
    'All users'
  );

  const setUserToEdit = (user: PgUser) => {
    dispatch(setSelectedEnhancedUser(user));
  };
  const resetFilters = () => {
    setSearchTerm('');
    setShowOnlyHeadOfficeUsers(false);
    setSelectedUserStatus('All users');
  };

  const disableViewBranchWarning = useSelector(selectDisableViewBranchWarning);

  const setSelectedBranchId = (branchId?: string) => {
    let branch: Retailer | undefined;
    if (branchId) {
      branch = branches?.find(branch => branch.supplierNumber === branchId);
    }

    if (!disableViewBranchWarning && branch) {
      setBranchWarning(branch);
    } else {
      dispatch(setSelectedEnhancedBranch(branch));
      resetFilters();
    }
  };

  const retailer = useSelector(selectRetailer);
  const branches = useSelector(selectAllBranches);
  const retailerLoaded = useSelector(selectRetailerLoaded);
  const { loading, errorMessage, usersList } = useSelector(selectEnhancedUsers);
  const userRoles = useSelector(selectUserRoles) ?? [];
  const showBranchSelectionPage = useSelector(selectShowBranchSelectionPage);

  const selectedBroker = useSelector(selectSelectedEnhancedBroker);
  const selectedHeadOffice = useSelector(selectSelectedEnhancedHeadOffice);
  const selectedBranch = useSelector(selectSelectedEnhancedBranch);

  const isBrokerOrGroupUser =
    userRoles.includes(UserRole.BrokerUser) ||
    userRoles.includes(UserRole.GroupUser);
  const isHeadOfficeUser = userRoles.includes(UserRole.HeadOfficeUser);

  useEffect(() => {
    if (
      retailer.name.length &&
      !selectedBroker &&
      !selectedHeadOffice &&
      !selectedBranch
    ) {
      if (isBrokerOrGroupUser) {
        dispatch(setSelectedEnhancedBroker(retailer));
      } else if (isHeadOfficeUser) {
        dispatch(setSelectedEnhancedHeadOffice(retailer));
      } else {
        dispatch(setSelectedEnhancedBranch(retailer));
      }
    }
  }, [
    retailer,
    selectedBroker,
    selectedHeadOffice,
    selectedBranch,
    isBrokerOrGroupUser,
    isHeadOfficeUser,
    dispatch
  ]);

  let supplierNumber: string | undefined;

  if (selectedBranch) {
    supplierNumber = selectedBranch.supplierNumber;
  } else if (selectedHeadOffice) {
    supplierNumber = selectedHeadOffice.supplierNumber;
  } else if (selectedBroker) {
    supplierNumber = selectedBroker.supplierNumber;
  }

  useEffect(() => {
    if (supplierNumber && !showBranchSelectionPage) {
      dispatch(fetchEnhancedUsersAction.request(supplierNumber));
    }
  }, [
    dispatch,
    supplierNumber,
    showBranchSelectionPage,
    selectedBranch,
    branches,
    retailerLoaded
  ]);

  if (
    loading ||
    !retailerLoaded ||
    (!selectedBranch && !selectedHeadOffice && !selectedBroker)
  )
    return (
      <NovunaSingleColumnLayout>
        <NovunaSpinner />
      </NovunaSingleColumnLayout>
    );

  if (errorMessage)
    return (
      <NovunaSingleColumnLayout>
        <div>{errorMessage}</div>
      </NovunaSingleColumnLayout>
    );

  if (showBranchSelectionPage) {
    return (
      <>
        <BranchSelection />
        <BranchSelectorModal />
      </>
    );
  }

  return (
    <>
      <EnhancedUserPage
        pageTitle={getPageTitle({
          selectedBranch,
          selectedHeadOffice,
          selectedBroker,
          userRoles
        })}
        retailerName={selectedBranch ? selectedBranch.name : retailer.name}
        filters={{
          headOfficeFilter: {
            showFilter:
              !!selectedHeadOffice?.hasChildBranches && !selectedBranch,
            showOnlyHeadOfficeUsers,
            setHeadOfficeFilter: (showHeadOfficeUsers: boolean) =>
              setShowOnlyHeadOfficeUsers(showHeadOfficeUsers)
          },
          search: {
            searchTerm,
            setSearchTerm
          },
          setSelectedUserStatus,
          selectPage
        }}
        usersTable={{
          showTable: !!usersList,
          currentSupplierId: supplierNumber ?? '',
          users: filterUsers({
            usersList: usersList ?? [],
            selectedUserStatus,
            showOnlyHeadOfficeUsers,
            searchTerm
          }),
          showSupplierNoLink:
            !!selectedHeadOffice?.hasChildBranches && !selectedBranch,
          currentPage,
          selectUser: setUserToEdit,
          selectPage,
          selectBranchId: branchId => setSelectedBranchId(branchId)
        }}
      />
      <UsersModal />
      <BranchWarningModal
        retailerName={branchWarning?.name}
        supplierId={branchWarning?.supplierNumber}
        setIsOpen={() => setBranchWarning(undefined)}
        onContinue={shouldDisableFutureMessage => {
          if (shouldDisableFutureMessage) {
            dispatch(setDisableViewBranchWarning(shouldDisableFutureMessage));
          }

          dispatch(setSelectedEnhancedBranch(branchWarning));
          resetFilters();
          setBranchWarning(undefined);
        }}
      />
      <BranchSelectorModal />
    </>
  );
};

export default PaginateState(EnhancedUsers);
