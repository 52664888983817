import {
  selectApr,
  selectGoodsAmount,
  selectGoodsDescription,
  selectInstalments,
  selectLoanAmount,
  selectRepaymentAmount,
  selectTotalAmountPayable
} from 'hitachi-retail-core/build/selectors';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { formatAddressSingleLine } from 'utils/formatters';
import LoanAdvanceInformation, {
  LoanAdvanceInformationPropsFromState,
  LoanAdvanceInformationPropsFromDispatch
} from './LoanAdvanceInformation';
import { RootState } from '../../../store';
import {
  selectAgreementDuration,
  selectApplicationDocument,
  selectApplicationProduct,
  selectIsDeferred
} from '../../../store/application/selectors';
import { selectRetailerForApplication } from '../../../store/retailer/selectors';
import { selectApplicationId } from '../../../store/save/reducer';
import { sendLoanAdvanceInfo } from '../../../store/sendLoanAdvanceInformation/actions';

export const mapStateToProps = (
  state: RootState
): LoanAdvanceInformationPropsFromState => {
  const retailer = selectRetailerForApplication(state);
  const retailerName = retailer.name;
  const formattedRetailerAddress = formatAddressSingleLine(retailer.address);
  const immediatePayout = retailer.immediatePayoutEnabled === true;

  const document = selectApplicationDocument(state);
  const product = selectApplicationProduct(state);
  const earlySettlementFee = product && !!product.earlySettlementFee;
  const agreementDuration = selectAgreementDuration(state)!;

  const isDeferred = selectIsDeferred(state);
  const apr = selectApr(document)!;

  const loanAmount = selectLoanAmount(document)!;
  const goodsAmount = selectGoodsAmount(document)!;
  const instalments = selectInstalments(document)!;
  const totalAmountPayable = selectTotalAmountPayable(document)!;
  const repaymentAmount = selectRepaymentAmount(document)!;
  const goodsDescription = selectGoodsDescription(document)!;
  const applicationId = selectApplicationId(state) || '';

  return {
    applicationId,
    isDeferred,
    earlySettlementFee,
    immediatePayout,
    agreementDuration,
    retailerName,
    loanAmount,
    goodsAmount,
    apr,
    instalments,
    totalAmountPayable,
    goodsDescription,
    repaymentAmount,
    formattedRetailerAddress
  };
};

export const mapDispatchToProps = (
  dispatch: Dispatch
): LoanAdvanceInformationPropsFromDispatch => ({
  startLoanAdvanceInformation: (id: string) => {
    if (id) {
      dispatch(sendLoanAdvanceInfo.request({ id }));
    }
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoanAdvanceInformation);
