import {
  Button,
  Container,
  ReactModal,
  Message,
  Box,
  InputWrapper,
  TextField,
  NovunaHeading
} from 'compass-design';
import React from 'react';
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikProps,
  useFormikContext,
  validateYupSchema,
  yupToFormErrors
} from 'formik';
import { DetailsCaptureValues } from 'applicantApp/pages/Details/components/schema';
import { getTone } from 'utils/getTone';
import { labelPropsFromMeta } from 'utils/labelPropsFromMeta';
import { parseNumericInput } from 'applicantApp/pages/Details/components/helpers';
import { financialDetailsSchema } from 'applicantApp/pages/Details/components/FinancialDetails';

const IncomeConfirmationModal = ({
  show,
  onSetIsOpen
}: {
  show: boolean;
  onSetIsOpen: (isOpen: boolean) => void;
}) => {
  const { submitForm, setFieldValue, values } = useFormikContext<
    DetailsCaptureValues
  >();

  const validate = async (values: DetailsCaptureValues) => {
    try {
      await validateYupSchema<DetailsCaptureValues>(
        values,
        financialDetailsSchema
      );
    } catch (err) {
      return yupToFormErrors(err);
    }
    return {};
  };

  return (
    <ReactModal
      title='Before you continue'
      size='medium'
      isOpen={show}
      setIsOpen={onSetIsOpen}>
      <Container>
        <Message variant='warning'>
          <NovunaHeading as='h3'>Check annual income</NovunaHeading>
          Please check that you have entered your annual income correctly before
          continuing.
        </Message>
      </Container>
      <Formik
        initialValues={values}
        onSubmit={submitForm}
        validate={values => validate(values as any)}>
        {(formikBag: FormikProps<DetailsCaptureValues>) => (
          <Form>
            <Field name={`financialDetails.incomeValue`}>
              {({
                field,
                meta,
                form
              }: FieldProps<string, DetailsCaptureValues>) => (
                <Box mt={4}>
                  <InputWrapper
                    id='financialDetails.incomeValue'
                    label='Annual income'
                    description='The amount you earn in one year, before tax and deductions'
                    error={meta.error}
                    tone={getTone(meta)}
                    {...labelPropsFromMeta({ meta }, 'label')}>
                    <TextField
                      short
                      {...labelPropsFromMeta({ meta }, 'input')}
                      type='tel'
                      {...field}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        form.setFieldValue(
                          'financialDetails.incomeValue',
                          parseNumericInput(e.target.value)
                        );
                        setFieldValue(
                          'financialDetails.incomeValue',
                          parseNumericInput(e.target.value)
                        );
                      }}
                      id='financialDetails.incomeValue'
                      tone={getTone(meta)}
                    />
                  </InputWrapper>
                </Box>
              )}
            </Field>
          </Form>
        )}
      </Formik>
      <Container mt={4}>
        <Button
          mt={4}
          isJumbo
          data-test-id='mo-confirm-update-application-button'
          onClick={submitForm}>
          Continue
        </Button>
      </Container>
    </ReactModal>
  );
};

export default IncomeConfirmationModal;
