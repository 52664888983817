import { UsersService } from '../services/users';
import { UserRole } from 'hitachi-retail-core/build/userPolicy';
import { PgUser } from 'hitachi-retail-core/build/services/pgUsers/pgUsersService';
import { branches } from './getMockRetailerService';

const defaultBranch = '99991234';

export const names = [
  {
    firstName: 'Liam',
    lastName: 'Elsher',
    role: UserRole.BranchUser,
    branch: defaultBranch
  },
  {
    firstName: 'Olivia',
    lastName: 'Solace',
    role: UserRole.BranchUser,
    branch: branches[0].supplierNumber
  },
  {
    firstName: 'Noah',
    lastName: 'Levine',
    role: UserRole.BranchUser,
    branch: branches[0].supplierNumber
  },
  {
    firstName: 'Emma',
    lastName: 'Thatcher',
    role: UserRole.BranchUser,
    branch: branches[0].supplierNumber
  },
  {
    firstName: 'Oliver',
    lastName: 'Raven',
    role: UserRole.BranchUser,
    branch: defaultBranch
  },
  {
    firstName: 'Ava',
    lastName: 'Bardot',
    role: UserRole.BranchUser,
    branch: defaultBranch
  },
  {
    firstName: 'Elijah',
    lastName: 'Hansley',
    role: UserRole.BranchUser,
    branch: branches[1].supplierNumber
  },
  {
    firstName: 'Charlotte',
    lastName: 'Cromwell',
    role: UserRole.BranchUser,
    branch: branches[1].supplierNumber
  },
  {
    firstName: 'William',
    lastName: 'Ashley',
    role: UserRole.BranchUser,
    branch: branches[1].supplierNumber
  },
  {
    firstName: 'Sophia',
    lastName: 'Monroe',
    role: UserRole.BranchUser,
    branch: branches[1].supplierNumber
  },
  {
    firstName: 'James',
    lastName: 'West',
    role: UserRole.BranchUser,
    branch: defaultBranch
  },
  {
    firstName: 'Amelia',
    lastName: 'Langley',
    role: UserRole.BranchUser,
    branch: defaultBranch
  },
  {
    firstName: 'Benjamin',
    lastName: 'Daughtler',
    role: UserRole.BranchUser,
    branch: defaultBranch
  },
  {
    firstName: 'Isabella',
    lastName: 'Madison',
    role: UserRole.BranchAdmin,
    branch: defaultBranch
  },
  {
    firstName: 'Lucas',
    lastName: 'Marley',
    role: UserRole.BranchAdmin,
    branch: defaultBranch
  },
  {
    firstName: 'Mia',
    lastName: 'Ellis',
    role: UserRole.BranchAdmin,
    branch: defaultBranch
  },
  {
    firstName: 'Henry',
    lastName: 'Hope',
    role: UserRole.BranchAdmin,
    branch: defaultBranch
  },
  {
    firstName: 'Evelyn',
    lastName: 'Cassidy',
    role: UserRole.BranchAdmin,
    branch: defaultBranch
  },
  {
    firstName: 'Alexander',
    lastName: 'Lopez',
    role: UserRole.BranchUser,
    branch: branches[2].supplierNumber
  },
  {
    firstName: 'Harper',
    lastName: 'Jenkins',
    role: UserRole.BranchUser,
    branch: branches[2].supplierNumber
  },
  {
    firstName: 'Fred',
    lastName: 'Poverly',
    role: UserRole.BranchUser,
    branch: branches[2].supplierNumber
  },
  {
    firstName: 'Mary',
    lastName: 'McKenna',
    role: UserRole.BranchUser,
    branch: defaultBranch
  },
  {
    firstName: 'Robert',
    lastName: 'Gonzales',
    role: UserRole.BranchUser,
    branch: defaultBranch
  },
  {
    firstName: 'Patricia',
    lastName: 'Collymore',
    role: UserRole.BranchUser,
    branch: defaultBranch
  },
  {
    firstName: 'Jennifer',
    lastName: 'Verlice',
    role: UserRole.BranchUser,
    branch: defaultBranch
  },
  {
    firstName: 'Michael',
    lastName: 'Adler',
    role: UserRole.BranchManager,
    branch: defaultBranch
  },
  {
    firstName: 'Linda',
    lastName: 'Huxley-Ledger',
    role: UserRole.BranchManager,
    branch: defaultBranch
  },
  {
    firstName: 'William',
    lastName: 'Hayes',
    role: UserRole.BranchManager,
    branch: defaultBranch
  },
  {
    firstName: 'Elizabeth',
    lastName: 'Ford',
    role: UserRole.BranchManager,
    branch: defaultBranch
  },
  {
    firstName: 'David',
    lastName: 'Ford',
    role: UserRole.BranchUser,
    branch: defaultBranch
  },
  {
    firstName: 'Barbara',
    lastName: 'Finnegan',
    role: UserRole.BranchUser,
    branch: defaultBranch
  },
  {
    firstName: 'Richard',
    lastName: 'Finnegan',
    role: UserRole.HeadOfficeUser,
    branch: defaultBranch
  }
];

export const testUser1 = {
  username: 'Flinny',
  firstName: 'Fred',
  lastName: 'Flinstone',
  email: 'email@company.co.uk',
  created: new Date(12853913.809),
  updated: new Date(1602853713.809),
  role: UserRole.BranchUser,
  defaultBranch: '12345678',
  enabled: true
};

export const testUser2 = {
  username: 'JohnDoe',
  firstName: 'John',
  lastName: 'Doe',
  email: 'email@company.co.uk',
  created: new Date(12853913.809),
  updated: new Date(1602853713.809),
  role: UserRole.BranchManager,
  defaultBranch: '12345678',
  enabled: true
};

export const testUser3 = {
  username: 'JackEvans',
  firstName: 'Jack',
  lastName: 'Evans',
  email: 'email@company.co.uk',
  created: new Date(12853913.809),
  updated: new Date(1602853713.809),
  role: UserRole.BranchManager,
  defaultBranch: '87654321',
  enabled: false
};

export const testUser4 = {
  username: 'JaneSmith',
  firstName: 'Jane',
  lastName: 'Smith',
  email: 'email@company.co.uk',
  created: new Date(12853913.809),
  updated: new Date(1602853713.809),
  role: UserRole.BranchAdmin,
  defaultBranch: '12345678',
  enabled: true
};

export const testUsers: PgUser[] = [testUser1, testUser2, testUser3, testUser4];

const getMockUsersService = (): UsersService => ({
  createUser: async (...args) => {
    console.log('Mock invoked: getUsersService createUser with:', ...args);
    return;
  },
  searchUsers: async (...args) => {
    console.log('Mock invoked: getUsersService searchUsers with:', ...args);
    return [
      {
        givenName: 'givenName',
        familyName: 'familyName',
        username: 'mailorderretailer/username',
        email: 'username@email.com',
        enabled: true,
        status: 'CONFIRMED',
        defaultBranch: '10241851',
        isDirectAppUser: false
      },
      {
        givenName: 'givenName1',
        familyName: 'familyName1',
        username: 'mailorderretailer/username1',
        email: 'username1@email.com',
        enabled: false,
        status: 'CONFIRMED',
        defaultBranch: '10241851',
        isDirectAppUser: false
      },
      {
        givenName: 'givenName2',
        familyName: 'familyName2',
        username: 'mailorderretailer/username2',
        email: 'username2@email.com',
        enabled: true,
        status: 'CONFIRMED',
        defaultBranch: '10241851',
        isDirectAppUser: false
      }
    ];
  },
  getUserRoles: async username => {
    console.log('Mock invoked: getUsersService > getUserRoles with:', username);

    const roles = {
      'mailorderretailer/username': [UserRole.BranchUser],
      'mailorderretailer/username1': [UserRole.BranchManager],
      'mailorderretailer/username2': [UserRole.BranchUser]
    };

    return roles[username];
  },
  updateUser: async (...args) => {
    console.log('Mock invoked: getUsersService updateUser with:', ...args);
    return;
  },
  getPgUsers: async branch => {
    console.log('Mock invoked: getUsersService getUsers with:', branch);

    return names.map(({ firstName, lastName, role, branch }) => {
      return {
        id: `branch1/user${firstName}`,
        username: firstName.toLowerCase() + lastName.toLowerCase(),
        email: `${firstName + lastName}@infinityworks.com`,
        enabled: Math.random() < 0.5,
        status: 'CONFIRMED',
        defaultBranch: branch,
        firstName,
        lastName,
        created: '2021-11-30T15:11:30.555Z',
        updated: '2022-01-10T17:11:34.992Z',
        retailerName: 'branch1',
        role
      };
    });
  }
});

export default getMockUsersService;
