import { call, put, takeLeading, takeEvery } from 'redux-saga/effects';
import { getType, isOfType } from 'typesafe-actions';
import {
  CustomerSatisfactionService,
  getCustomerSatisfactionService
} from '../../services/customerSatisfactionService';
import { satisfactionFeedback } from '../../store/customerSatisfaction/actions';
import { SatisfactionFeedbackAction } from '../../store/customerSatisfaction/reducer';
import { DefaultSagaParams } from '../index';
import { FetchCustomerFeedbackAction } from '../../store/previousApplicationDetail/reducer';
import {
  fetchCustomerFeedback,
  CustomerSatisfactionNotes
} from '../../store/previousApplicationDetail/actions';
import { showNotification, NotificationType } from '../../store/ui/actions';

interface CustomerSatisfactionSagaParams {
  customerSatisfactionService: CustomerSatisfactionService;
}

export const getSatisfactionFeedbackSaga = ({
  customerSatisfactionService
}: CustomerSatisfactionSagaParams) =>
  function*(action: SatisfactionFeedbackAction) {
    if (isOfType(getType(satisfactionFeedback.request), action)) {
      try {
        yield call(customerSatisfactionService.submitFeedback, action.payload);

        yield put(satisfactionFeedback.success());
      } catch (err) {
        yield put(satisfactionFeedback.failure(err));
      }
    }
  };

export const getFetchCustomerFeedbackSaga = ({
  customerSatisfactionService
}: CustomerSatisfactionSagaParams) =>
  function*(action: FetchCustomerFeedbackAction) {
    if (isOfType(getType(fetchCustomerFeedback.request), action)) {
      const { id } = action.payload;
      try {
        const response: CustomerSatisfactionNotes = yield call(
          customerSatisfactionService.fetchFeedback,
          id
        );

        yield put(fetchCustomerFeedback.success(response));
      } catch (err) {
        yield put(fetchCustomerFeedback.failure(err));
        yield put(
          showNotification({
            message: err.message,
            type: NotificationType.DANGER
          })
        );
      }
    }
  };

export const getCustomerSatisfactionSagaWatcher = ({
  apiClient
}: DefaultSagaParams) =>
  function*() {
    const customerSatisfactionService = getCustomerSatisfactionService({
      apiClient
    });

    yield takeEvery(
      getType(fetchCustomerFeedback.request),
      getFetchCustomerFeedbackSaga({ customerSatisfactionService })
    );

    yield takeLeading(
      getType(satisfactionFeedback.request),
      getSatisfactionFeedbackSaga({ customerSatisfactionService })
    );
  };
