import NovunaPageLayout from 'applicantApp/components/NovunaPageLayout';
import { Message, NovunaHeading, Text } from 'compass-design';
import React from 'react';
import { useSelector } from 'store';
import {
  selectApplicationDetail,
  selectApplicationReturnUrl,
  selectApplicationSupplierOrderReference
} from 'store/application/selectors';
import { RetailerRedirectButton } from 'applicantApp/components/ExternalRedirectButtons/ExternalRedirectButtons';
import MailOrderPbfReferralIDVTCard from './MailOrderPbfReferralIDVTCard';
import MailOrderPBFReferralOpenBankingCard from './MailOrderPBFReferralOpenBankingCard';

export interface DecisionReferProps {
  applicationId: string;
}

const DecisionRefer: React.FC<DecisionReferProps> = ({ applicationId }) => {
  const activeApplication = useSelector(selectApplicationDetail);

  const returnUrl = useSelector(selectApplicationReturnUrl);
  const supplierOrderReference = useSelector(
    selectApplicationSupplierOrderReference
  );

  const reasonCodes = activeApplication?.revisions?.[0]?.reasonCodes;

  const isOpenBankingRefer = reasonCodes?.some(code =>
    ['OBR1', 'OBR2', 'OBR3'].includes(code.code)
  );

  const isIDVTRefer = reasonCodes?.some(code =>
    ['IDV1', 'IDV2'].includes(code.code)
  );

  const phoneNumber = activeApplication?.document.contactDetails?.phoneNumber;

  const emailAddress = activeApplication?.document.contactDetails?.emailAddress;

  const sendTextMessage =
    !!phoneNumber &&
    (phoneNumber.startsWith('07') || phoneNumber.startsWith('+447'));

  const customerMessage = () => {
    if (
      isIDVTRefer &&
      !isOpenBankingRefer &&
      ((!!phoneNumber && sendTextMessage) || !!emailAddress)
    ) {
      return <MailOrderPbfReferralIDVTCard sendTextMessage={sendTextMessage} />;
    }
    if (isOpenBankingRefer && !!emailAddress) {
      return <MailOrderPBFReferralOpenBankingCard />;
    }

    return (
      <Message variant='info'>
        <NovunaHeading as='h3' mb={1}>
          Referred
        </NovunaHeading>
        <Text>
          The Underwriting Team are looking at your application. You will be
          contacted when it has been reviewed.
        </Text>
      </Message>
    );
  };

  return (
    <NovunaPageLayout backToStoreStatus='referred'>
      <NovunaHeading
        as='h1'
        data-test-id='mo-decision-refer-page-heading'
        mb={3}>
        Application decision
      </NovunaHeading>
      {customerMessage()}
      {returnUrl ? (
        <>
          <Text my={6}>Please return to the store to continue shopping.</Text>

          <RetailerRedirectButton
            novunaDisplay
            baseUrl={returnUrl}
            applicationId={applicationId}
            status='referred'
            buttonText='Continue shopping'
            supplierOrderReference={supplierOrderReference}
          />
        </>
      ) : (
        <Text mt={6}>
          You can leave this website or close your web browser to exit the
          application process.
        </Text>
      )}
    </NovunaPageLayout>
  );
};

export default DecisionRefer;
