import { Box } from 'compass-design';
import React from 'react';

const PageBlock: React.FC = ({ children }) => (
  <Box
    id='modal-wrapper'
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(26,26,26,0.64)',
      zIndex: 10
    }}>
    <Box
      id='modal'
      sx={{
        position: 'relative',
        display: 'block !important',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 'calc(100% - 32px)',
        maxWidth: '720px',
        maxHeight: '100vh',
        backgroundColor: 'white',
        borderRadius: '16px',
        margin: 'auto',
        marginBottom: 3,
        padding: 6
      }}>
      <Box
        id='modal-content'
        sx={{
          '::-webkit-scrollbar': {
            display: 'none'
          },
          overflowY: 'auto'
        }}>
        {children}
      </Box>
    </Box>
  </Box>
);

export default PageBlock;
