import React, { useEffect } from 'react';
import { Box, ReactModal, Text, Button } from 'compass-design';
import GoodsInputSection from './components/GoodsInputSection';
import { GoodsListItem } from 'hitachi-retail-core';
import { resetFetchedGoodsList } from 'store/goodsList/actions';
import { useDispatch } from 'react-redux';
import GoodsListDropdown from '../GoodsListDropdown';

export enum GoodsDetailsKey {
  GOODS_DESCRIPTION = 'goodsDescription',
  ORDER_REF = 'orderRef'
}

export type SetGoodsDetailsParams = {
  key: GoodsDetailsKey;
  value: string;
};

export interface OnContinueParams {
  goodsDescription?: string;
  orderReference?: string;
}

export type GoodsDetailsModalProps = {
  goodsDescription: string;
  orderReference: string;
  isGoodsDetailsModalOpen: boolean;
  setIsGoodsDetailsModalOpen: (isOpen: boolean) => void;
  setGoodsDetails: ({ key, value }: SetGoodsDetailsParams) => void;
  onContinue: ({ goodsDescription, orderReference }: OnContinueParams) => void;
  goodsDescriptionError: string;
  orderReferenceError: string;
  disableContinueButton?: boolean;
  isSoftSearch: boolean;
  goodsList: GoodsListItem[];
};

export const GoodsDetailsModal: React.FC<GoodsDetailsModalProps> = ({
  goodsDescription,
  orderReference,
  setGoodsDetails,
  isGoodsDetailsModalOpen,
  setIsGoodsDetailsModalOpen,
  onContinue,
  goodsDescriptionError,
  orderReferenceError,
  disableContinueButton,
  isSoftSearch,
  goodsList
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetFetchedGoodsList());
    };
  }, [dispatch]);

  return (
    <ReactModal
      title='Purchase details'
      size='small-medium'
      isOpen={isGoodsDetailsModalOpen}
      setIsOpen={setIsGoodsDetailsModalOpen}>
      <Box>
        <Text mb={4} sx={{ fontSize: 2 }}>
          Please confirm that the details below are correct.
        </Text>
        {goodsList.length === 0 ? (
          <GoodsInputSection
            title='Goods description'
            value={goodsDescription}
            inputKey={GoodsDetailsKey.GOODS_DESCRIPTION}
            setGoodsDetails={setGoodsDetails}
            errorMessage={goodsDescriptionError}
          />
        ) : (
          <Box mb={5}>
            <GoodsListDropdown
              goodsDescription={goodsDescription}
              goodsList={goodsList}
              bold
              setGoodsDetails={(value: string) =>
                setGoodsDetails({
                  key: GoodsDetailsKey.GOODS_DESCRIPTION,
                  value
                })
              }
            />
          </Box>
        )}
        <GoodsInputSection
          title='Order reference'
          value={orderReference}
          inputKey={GoodsDetailsKey.ORDER_REF}
          setGoodsDetails={setGoodsDetails}
          errorMessage={orderReferenceError}
        />
        <Button
          isJumbo
          onClick={() => {
            dispatch(resetFetchedGoodsList());
            onContinue({ goodsDescription, orderReference });
          }}
          disabled={!!disableContinueButton}>
          {isSoftSearch ? 'Apply' : 'Continue'}
        </Button>
      </Box>
    </ReactModal>
  );
};

export default GoodsDetailsModal;
