import { InputWrapper, novunaTheme } from 'compass-design';
import React from 'react';
import { FieldTemplateProps as OriginalFieldTemplateProps } from 'react-jsonschema-form';
import { Box } from 'theme-ui';

type FieldTemplateProps = Pick<
  OriginalFieldTemplateProps,
  | 'id'
  | 'label'
  | 'displayLabel'
  | 'help'
  | 'hidden'
  | 'required'
  | 'disabled'
  | 'description'
  | 'rawDescription'
  | 'rawErrors'
  | 'schema'
  | 'children'
>;

const FieldTemplate: React.FunctionComponent<FieldTemplateProps> = props => {
  const {
    label,
    help,
    hidden,
    rawErrors,
    rawDescription,
    required,
    children
  } = props;
  if (hidden || passThroughField(props)) {
    return children;
  }

  const id = getId(props);
  const fieldError = Array.isArray(rawErrors) && rawErrors[0];

  const tone = fieldError ? 'negative' : 'neutral';
  // Theme not in context due to some react-jsonscheme-form weirdness, so
  // need to manually extract space value from the theme
  return (
    <Box mb={novunaTheme.space[4]}>
      <InputWrapper
        id={id}
        label={label}
        error={fieldError || undefined}
        tone={tone}
        description={
          rawDescription || (required === false ? 'Optional' : undefined)
        }>
        {children}
        {help}
      </InputWrapper>
    </Box>
  );
};

const passThroughField = ({
  id,
  displayLabel,
  label,
  schema: { type }
}: FieldTemplateProps) => {
  const rootField = id === 'compass';

  if (rootField || !type || Array.isArray(type) || !displayLabel || !label) {
    return true;
  }
  return false;
};

const getId = ({ id, schema: { format } }: FieldTemplateProps) => {
  switch (format) {
    case 'date':
      return `${id}_day`;
    default:
      return id;
  }
};

export default FieldTemplate;
