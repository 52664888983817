import { Text } from 'compass-design';
import React from 'react';

interface PasswordStrengthLabelProps {
  strength: number;
  labels?: string[];
}

const DEFAULT_PASSWORD_STRENGTH_LABELS = ['too weak', 'weak', 'good', 'strong'];

export const PasswordStrengthLabel = ({
  strength,
  labels = DEFAULT_PASSWORD_STRENGTH_LABELS
}: PasswordStrengthLabelProps) => {
  const label = () => {
    switch (strength) {
      case 1:
      case 2:
      case 3:
      case 4:
        return labels[strength - 1];
      default:
        return '';
    }
  };

  return (
    <Text
      sx={{
        textAlign: 'right',
        fontWeight: 'bold',
        fontSize: '0.9em',
        lineHeight: '0.5em'
      }}
      aria-label={`Password strength is ${label()}`}>
      {label()}
    </Text>
  );
};
