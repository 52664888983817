import { Box, InlineCheckbox, InputWrapper } from 'compass-design';
import { FinanceApplication } from 'hitachi-retail-core';
import { ApplicationStatus } from 'hitachi-retail-core/build/enums/applicationStatus';
import { parseDecimal } from 'hitachi-retail-core/build/utils/decimal';
import { update } from 'lodash';
import React, { useEffect } from 'react';
import { FieldProps } from 'react-jsonschema-form';
import { useSelector } from 'store';
import { getFieldError } from '../../../form/helpers';
import { selectApplicationStatus } from '../../../store/application/selectors';
import InputField from './InputField';

type LoanParametersType = Partial<FinanceApplication['loanParameters']>;

export type LoanParametersFieldProps = FieldProps<LoanParametersType>;
export type LoanParametersProps = LoanParametersFieldProps;

const fieldNames = {
  CreditCardDeposit: 'creditCardDeposit',
  Deposit: 'deposit',
  GoodsAmount: 'goodsAmount'
};

export const LoanParameters: React.FunctionComponent<LoanParametersProps> = ({
  formData,
  errorSchema,
  disabled,
  autofocus,
  onChange,
  idSchema: { $id }
}) => {
  const applicationStatus = useSelector(selectApplicationStatus);
  const testIdPrefix = $id;
  const captureCreditCardDeposit =
    parseDecimal(formData.deposit)?.greaterThan(0) || false;

  // Update credit card deposit property based on deposit
  const updateCreditCardDeposit = (formData: LoanParametersType) => {
    update(
      formData,
      fieldNames.CreditCardDeposit,
      (value: boolean | undefined) => {
        // Default to false if non-zero deposit but checkbox has not been touched
        return value === undefined ? false : value;
      }
    );
  };

  const handleChange = (fieldName: string, value: any) => {
    if (value === '') {
      value = undefined;
    }

    const updatedFormData: Partial<LoanParametersType> = {
      ...formData,
      [fieldName]: value
    };

    updateCreditCardDeposit(updatedFormData);
    onChange(updatedFormData);
  };

  // Update credit card deposit property for existing applications
  useEffect(() => {
    if (captureCreditCardDeposit) {
      updateCreditCardDeposit(formData);
    }
  }, [captureCreditCardDeposit, formData]);

  return (
    <>
      <InputField
        label='Total cost of goods'
        fieldHelp='Cash price of goods or services'
        required={true}
        autofocus={autofocus}
        disabled={disabled}
        fieldName={fieldNames.GoodsAmount}
        inputType='string'
        fieldValue={formData.goodsAmount}
        fieldError={getFieldError(fieldNames.GoodsAmount, errorSchema)}
        testIdPrefix={testIdPrefix}
        inputId={`${$id}_${fieldNames.GoodsAmount}`}
        onChange={({ target }) =>
          handleChange(fieldNames.GoodsAmount, target.value)
        }
        shortLength
      />
      <InputField
        label='Deposit'
        required={true}
        disabled={disabled}
        fieldName={fieldNames.Deposit}
        inputType='string'
        fieldValue={formData.deposit}
        fieldError={getFieldError(fieldNames.Deposit, errorSchema)}
        testIdPrefix={testIdPrefix}
        inputId={`${$id}_${fieldNames.Deposit}`}
        onChange={({ target }) =>
          handleChange(fieldNames.Deposit, target.value)
        }
        onFocus={event => event.target.select()}
        shortLength
      />
      {captureCreditCardDeposit && (
        <Box mb={4}>
          <InputWrapper id={`${$id}_${fieldNames.CreditCardDeposit}`}>
            <InlineCheckbox
              id={`${$id}_${fieldNames.CreditCardDeposit}`}
              checked={formData.creditCardDeposit}
              disabled={applicationStatus !== ApplicationStatus.open}
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(fieldNames.CreditCardDeposit, target.checked)
              }
              data-test-id='credit-card-deposit'>
              The deposit will be paid by credit card
            </InlineCheckbox>
          </InputWrapper>
        </Box>
      )}
    </>
  );
};

export default LoanParameters;
