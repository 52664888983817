import { useState } from 'react';
import validateGoodsDetails from 'retailerApp/components/EditLoanCardState/validateGoodsDetails';
import {
  GoodsDetailsKey,
  SetGoodsDetailsParams
} from 'retailerApp/components/GoodsDetailsModal/GoodsDetailsModal';
import { LoanDetails } from 'retailerApp/utils/loanDetails/getLoanDetails';

const useSetGoodsDetails = (loanDetails: LoanDetails) => {
  const [goodsDescription, setGoodsDescription] = useState(
    loanDetails.goodsDescription ?? ''
  );

  const [orderReference, setOrderReference] = useState(
    loanDetails.applicationRef ?? ''
  );
  const [goodsDescriptionError, setGoodsDescriptionError] = useState('');
  const [orderReferenceError, setOrderReferenceError] = useState('');

  const resetState = (loanDetails: LoanDetails) => {
    setGoodsDescription(loanDetails.goodsDescription ?? '');
    setOrderReference(loanDetails.applicationRef ?? '');
    setGoodsDescriptionError('');
    setOrderReferenceError('');
  };

  const setGoodsDetails = ({ key, value }: SetGoodsDetailsParams) => {
    const goodsDetails = {
      goodsDescription,
      orderReference
    };
    if (key === GoodsDetailsKey.GOODS_DESCRIPTION) {
      setGoodsDescription(value);
      goodsDetails.goodsDescription = value;
    }
    if (key === GoodsDetailsKey.ORDER_REF) {
      setOrderReference(value);
      goodsDetails.orderReference = value;
    }

    validateGoodsDetails({
      ...goodsDetails,
      setGoodsDescriptionError,
      setOrderReferenceError
    });
  };

  return {
    goodsDescription,
    setGoodsDescription,
    orderReference,
    setOrderReference,
    goodsDescriptionError,
    orderReferenceError,
    resetState,
    setGoodsDetails
  };
};

export default useSetGoodsDetails;
