import React, { useState } from 'react';
import {
  NovunaSingleColumnLayout,
  NovunaSpinner,
  NovunaTwoColumnLayout
} from 'compass-design';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { selectRetailerLoaded } from 'store/retailer/selectors';
import { StrongerPasswordGuidance } from 'components/StrongerPasswordGuidance';
import GuidanceDetails from './GuidanceDetails';
import UpdatePassword from './UpdatePassword/UpdatePasswordContainer';

const ManagePassword = ({ onSubmit, onBackToLogin }: any) => {
  const [modalOpen, setModalOpen] = useState(false);
  const retailerLoaded = useSelector(selectRetailerLoaded);

  if (!retailerLoaded) {
    return (
      <NovunaSingleColumnLayout>
        <NovunaSpinner />
      </NovunaSingleColumnLayout>
    );
  }

  return (
    <>
      <Helmet>
        <title>Manage Password - CreditMaster3</title>
      </Helmet>
      <NovunaTwoColumnLayout
        sidebar={<GuidanceDetails guidanceModalToggle={setModalOpen} />}>
        <StrongerPasswordGuidance
          modalOpen={modalOpen}
          toggleModalOpen={setModalOpen}
        />
        <UpdatePassword onSubmit={onSubmit} onBackToLogin={onBackToLogin} />
      </NovunaTwoColumnLayout>
    </>
  );
};

export default ManagePassword;
